import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withStyles
} from '@material-ui/core';

import ResponsiveDialog from "../../components/ResponsiveDialog";
import FauxTable from "../../components/FauxTable";
import NumberFormatter from '../../utils/NumberFormatter';

const styles = theme => ({
	numberColumn: {
		textAlign: "end"
	}
});

class AssociatedDigitalPropertyInfo extends Component {
	constructor(props, context) {
		super(props, context);
		this.getColumns = this.getColumns.bind(this);
	}

	getColumns() {
		let columns = [],
			{classes, data} = this.props;

		if (data && data.length) {
			let col1 = Object.keys(data[0])[0],
				col2 = Object.keys(data[0])[1];

			columns.push({
				id: col1,
				label: `${col1}s`
			}, {
				id: col2,
				label: `${col2}s`,
				headingClassName: classes.numberColumn,
				dataClassName: classes.numberColumn,
				formatter: d => {
					return NumberFormatter.largeNumberFormat(d);
				}
			});
		}

		return columns;
	}

	render() {
		let {open, title, closeHandler, data, fidoError} = this.props,
			columns = this.getColumns(),
			columnSizing = [
				{xs: 8, sm: 9, md: 8, lg: 8, xl: 8},
				{xs: 4, sm: 3, md: 4, lg: 4, xl: 4}
			],
			content = (
				<FauxTable
					data={data}
					columns={columns}
					columnSizing={columnSizing}
					elevation={0}
					infiniteScroll={false}
					fidoError={fidoError}
					isLoading={data && data.length === 0} />
			);

		return (
			<ResponsiveDialog
				fullWidth
				maxWidth="xs"
				open={open}
				title={title}
				content={content}
				closeHandler={closeHandler} />
		);
	}
}

AssociatedDigitalPropertyInfo.defaultProps = {
	open: false
};

AssociatedDigitalPropertyInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	closeHandler: PropTypes.func.isRequired,
	title: PropTypes.any,
	open: PropTypes.bool,
	data: PropTypes.array,
	fidoError: PropTypes.bool
};

export default withStyles(styles)(AssociatedDigitalPropertyInfo);