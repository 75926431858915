import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import Constants from "../utils/Constants";
import PhoneValidator from './PhoneValidator';
import StandardFormLayout from './StandardFormLayout';
import Menu from './Menu';
import DialogSlideTransition from "./DialogSlideTransition";

import {
    Grid,
    Button,
    MenuItem,
    Dialog,
    DialogContent,
    DialogContentText,
    withMobileDialog,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    withStyles,
    withTheme,
} from '@material-ui/core';

const styles = theme => ({
	root: {
		[theme.breakpoints.up('sm')]: {
			paddingTop: theme.spacing(4)
		}
	},

	recipientGrid: {
		marginTop: theme.spacing(1),
		paddingLeft: theme.spacing(1.5),
		background: theme.palette.grey[200]
	},

	formContentText: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(9)
		}
	},

	dialogPaper: {
		[theme.breakpoints.up('md')]: {
			maxWidth: 500
		}
	},

	fieldCol: {
		flex: 1,
		paddingLeft: theme.spacing(1.5)
	},

	h1Override: {
		fontWeight: 500
	}
});

class ShareActivityForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null,
			recipients: [],
			message: '',
			isFormValid: false
		};

		this.keylength = 0;

		this.menuClick = this.menuClick.bind(this);
		this.menuClose = this.menuClose.bind(this);
		this.addRecipient = this.addRecipient.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		//this.checkValidation = this.checkValidation.bind(this);
		this.removeRecipient = this.removeRecipient.bind(this);
	}

	/*checkValidation(isValid) {
		if (this.vForm) {
			this.setState({isFormValid: this.vForm.isFormValid()});
		}
	}*/

	menuClick(event) {
		this.setState({ anchorEl: event.target });
	}

	menuClose(event) {
		this.setState({ anchorEl: null });
	}

	addRecipient(event, type) {
		let recipients = this.state.recipients;
		let keylength = ++this.keylength;
		let key = '';

		((type === 'email') ? (
			key = 'email-'+keylength
		) : (
			key = 'phone-'+keylength
		));

		this.setState({ [key]: '' }, function() {
			recipients.push(
				{type: type, key: key}
			);

			//this.setState({recipients: recipients, isFormValid: false});
			this.setState({recipients: recipients, isFormValid: true});
		});

		this.menuClose(event);
	}

	removeRecipient(key) {
		let newState,
			recipients = this.state.recipients;

		recipients.splice(recipients.indexOf(recipients.find(item => item.key === key)), 1);
		newState = {recipients: recipients, [key]: null};

		if (recipients.length === 0) {
			newState.isFormValid = false;
		}

		this.setState(newState);
	}

	handleInputChange(event) {
		let target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleFormSubmit(event) {
		let form = event.target;
		let values = {};
		Object.keys(form.elements).map(e => {
			// Don't send if element not named
			if(form.elements[e].nodeName && form.elements[e].name) {
				values[form.elements[e].name] = form.elements[e].value;
			}

			return e;
		});

		this.props.handleSubmit(this.props.activityId, values);
	}

	handleFormClose(event) {
		// Reset form state
		let recipients = this.state.recipients;
		recipients.map((recipient) => {
			return (
				this.setState({[recipient.key]: null})
			);
		});

		this.setState({
			anchorEl: null,
			recipients: [],
			message: '',
			isFormValid: false
		});

		this.props.handleClose(event);
	}

	render() {
		let {open, classes, fullScreen, theme} = this.props;
		let {anchorEl, recipients} = this.state;

		return (
			<Dialog
				open={open}
				onClose={this.handleFormClose}
				fullScreen={fullScreen}
				maxWidth={false}
				classes={{paper: classes.dialogPaper}}
				aria-labelledby="form-dialog-title"
				TransitionComponent={DialogSlideTransition} >
				<Media query={{minWidth: theme.breakpoints.values.md}}>
					{matches =>
						matches ? (null) : (
							<AppBar className={classes.appBar}>
								<Toolbar>
									<IconButton
										aria-label="close dialog"
										onClick={this.handleFormClose}>
										<FontAwesomeIcon icon={["far", "times"]} title="close dialog" />
									</IconButton>
									<Typography className={classes.h1Override} variant="h1" color="inherit">Share Activity</Typography>
								</Toolbar>
							</AppBar>
						)
					}
				</Media>
				<DialogContent>
					<DialogContentText className={classes.formContentText}>
						A direct link to this activity will be sent to each recipient, along with any optional message you include. The link expires 30 days after the original post was published.
					</DialogContentText>
					<ValidatorForm
						//ref={(vForm) => {this.vForm = vForm;}}
						debounceTime={500}
						onSubmit={this.handleFormSubmit}
						onError={errors => console.error(errors)}>
						<Grid className={classes.recipientGrid} container justify="space-between" alignItems="center" spacing={1}>
							<Grid item>
								<Typography variant="h6">Recipients</Typography>
							</Grid>
							<Grid item>
								<Button color="primary" onClick={this.menuClick}>
									<Media query={{minWidth: theme.breakpoints.values.sm}}>
										{matches => matches ? `+ Add Recipient` : `+ Add`}
									</Media>
								</Button>
							</Grid>
						</Grid>
						<Menu id="long-menu"
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={this.menuClose}>
							<MenuItem key="email" onClick={(event) => this.addRecipient(event, 'email')}>
								by Email
							</MenuItem>
							<MenuItem key="phone" onClick={(event) => this.addRecipient(event, 'phone')}>
								by Text
							</MenuItem>
						</Menu>
						<StandardFormLayout>
							{/*validatorListener={this.checkValidation}*/}
							{recipients.map((recipient, idx) =>
								(
									<Grid container justify="space-between" alignItems="flex-end" key={recipient.key} name={recipient.key}>
										<Grid item className={classes.fieldCol}>
											{recipient.type === 'email' ? (
												<TextValidator fullWidth
													id="email"
													label="Email"
													helperText="Email address to share with"
													name={recipient.key}
													value={this['state'][recipient.key]}
													margin="normal"
													validators={['required', 'isEmail']}
													errorMessages={[Constants.ERROR_MESSAGES['required'], Constants.ERROR_MESSAGES['isEmail']]}
													onChange={this.handleInputChange} />
											) : (
												<PhoneValidator fullWidth
													id="phone"
													label="Mobile Phone"
													helperText="Phone number to share with"
													name={recipient.key}
													value={this['state'][recipient.key]}
													margin="normal"
													validators={['required', Constants.ERROR_VALIDATORS['isPhoneWithMask']]}
													errorMessages={[Constants.ERROR_MESSAGES['required'], Constants.ERROR_MESSAGES['isPhone']]}
													onChange={this.handleInputChange} />
											)}
										</Grid>
										<Grid item>
											<IconButton onClick={() => {this.removeRecipient(recipient.key);}}>
												<FontAwesomeIcon icon={["fal", "times"]} title="Close dialog" />
											</IconButton>
										</Grid>
									</Grid>
								)
							)}
							<TextValidator fullWidth
								id="message"
								label="Message (Optional)"
								helperText="Enter an optional message"
								name="message"
								margin="normal"
								value={this.state.message}
								onChange={this.handleInputChange}
							/>
						</StandardFormLayout>
						<Grid container justify="center" spacing={8}>
							<Media
								query={{minWidth: theme.breakpoints.values.md}}
								render={() =>
									<Grid item>
										<Button onClick={this.handleFormClose}>Cancel</Button>
									</Grid>
								}>
							</Media>
							<Grid item>
								<Button type="submit" variant="contained" color="primary" disabled={!this.state.isFormValid}>
									Share Activity
								</Button>
							</Grid>
						</Grid>
					</ValidatorForm>
				</DialogContent>
			</Dialog>
		);
	}
}

ShareActivityForm.propTypes = {
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	handleClose: PropTypes.func,
	activityId: PropTypes.number,
	open: PropTypes.bool,
	fullScreen: PropTypes.bool,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles)(withMobileDialog()(withTheme(ShareActivityForm)));
