import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as diff from 'diff';
import { withStyles } from '@material-ui/core';

const greenColor = "#3c756c";
const styles = theme => ({
	wrapper: {
		whiteSpace: "pre-line"
	},

	added: {
		color: greenColor,
		backgroundColor: "#e4f0f1"
	},

	removed: {
		color: greenColor,
		textDecoration: "line-through"
	}
});

/************************************************************************/
/* VERY IMPORTANT
/*    SINCE THIS COMPONENT USES dangerouslySetInnerHTML MAKE SURE THE
/*    PATCH THAT'S SENT IN IS EITHER SAFE AND / OR SANITIZED
/************************************************************************/

const patchLengthTreshold = 5000;
class Diff extends Component {
	// https://github.com/kpdecker/jsdiff
	static TYPES = {
		CHARS: "diffChars",
		WORDS: "diffWords",
		WORDS_WITH_SPACE: "diffWordsWithSpace",
		LINES: "diffLines",
		TRIMMED_LINES: "diffTrimmedLines",
		SENTENCES: "diffSentences"
	}

	constructor(props, context) {
		super(props, context);

		this.state = {
			diff: null
		};
	}

	componentDidMount() {
		let {patch, hunkSeparator, diffType} = this.props,
			patchResults = this.patchGenesis(diff.parsePatch(patch), hunkSeparator)[0];

		if (patch.length > patchLengthTreshold) {
			diffType = Diff.TYPES.LINES;
		}

		// to fix multiple image and spaces issues
		if (diffType === Diff.TYPES.CHARS || diffType === Diff.TYPES.WORDS || diffType === Diff.TYPES.WORDS_WITH_SPACE) {
			this.setState({diff: this.ourCharOrWordDiff(patchResults.pre, patchResults.post)});
		} else {
			this.setState({diff: diff[diffType](patchResults.pre, patchResults.post)});
		}
	}

	patchGenesis(patchObjects, hunkSeparator) {
		let results = patchObjects.map(file => {
			let {hunks} = file,
				pre = [],
				post = [];

			hunks.map(hunk => {
				hunk.lines.map(line => {
					let i = line.slice(0, 1),
						txt = line.slice(1);

					if(i === '-') {
						pre.push(txt);
					} else if(i === '+') {
						post.push(txt);
					} else {
						pre.push(txt);
						post.push(txt);
					}

					return null;
				});

				pre.push(hunkSeparator);
				post.push(hunkSeparator);

				return null;
			});

			return {
				pre: pre.join("\n"),
				post: post.join("\n")
			};
		});

		return results;
	}

	ourCharOrWordDiff(pre, post) {
		const randHash = () => Math.random().toString(36).substring(2);

		let result,
			hashes = {},
			imgRx = /(<img[^>]*>)/g,
			everything = pre.concat(post),
			{diffType} = this.props;

		/* eslint-disable no-cond-assign */
		// create random words for each unique image in either pre or post
		while(result = imgRx.exec(everything)) {
			if(result && result.length) {
				if(!(result[1] in hashes)) {
					hashes[result[1]] = randHash();
				}
			}
		}
		/* eslint-disable no-cond-assign */

		// replace each img with a random hash word
		Object.keys(hashes).forEach(tag => {
			pre = pre.replace(tag, hashes[tag]);
			post = post.replace(tag, hashes[tag]);
		});

		result = diff[diffType](pre, post);

		Object.keys(hashes).forEach(tag => {
			result.forEach(item => {
				item.value = item.value.replace(hashes[tag], tag);
			});
		});

		return result;
	}

	render() {
		let color,
			{diff} = this.state,
			{classes, wordBreak} = this.props;

		return (
			<div className={classes.wrapper} style={wordBreak ? {wordBreak: wordBreak} : null}>
				{diff ? diff.map((part, idx) => {
					// normal color for common parts
					// green for additions, red for deletions
					color = part.added ? classes.added : part.removed ? classes.removed : null;
					return <span key={idx} className={color} dangerouslySetInnerHTML={{__html: part.value}}></span>;
				}) : null}
			</div>
		);
	}
}

Diff.defaultProps = {
	diffType: Diff.TYPES.WORDS_WITH_SPACE,
	hunkSeparator: Array(45).fill("&#183; ").join("")
};

Diff.propTypes = {
	classes: PropTypes.object.isRequired,
	patch: PropTypes.string,
	hunkSeparator: PropTypes.string,
	diffType: PropTypes.string,
	wordBreak: PropTypes.string
};

export default withStyles(styles)(Diff);