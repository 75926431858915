import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AWS from "../utils/AWS";
import Constants from "../utils/Constants";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

/**
 * DeconstructActivity normalizes activity data into props used by activity component
 */
class DeconstructActivity extends Component {
	render() {
		let {activity} = this.props;
		let normalizedProps = {};

		if(activity) {
			normalizedProps = {
				activityId: activity.id,
				accounts: activity.accounts,
				comments: this.getComments(activity),
				title: this.getTitle(activity),
				content: activity.content || '',
				associations: activity.associations,
				authorName: this.getAuthorName(activity),
				authorLink: this.getAuthorLink(activity),
				authorImage: (activity.author && activity.author.image) || '',
				authorDesc: (activity.author && activity.author.description) || '',
				authorLoc: (activity.author && activity.author.location) || '',
				authorFollowers: (activity.author && activity.author.followers) || 0,
				authorFollowing: (activity.author && activity.author.friends) || 0,
				extraHeaders: this.getExtraHeaders(activity), // currently used for email and chat
				tip: Object.assign({}, activity.tip),
				time: activity.published_at,
				// distance: activity.distance,
				postLink: activity.link,
				sourceId: activity.source.id,
				sourceName: activity.source.name,
				subSourceId: activity.subSource, // gsuit comments (so far)
				sourceHandle: this.getSourceHandle(activity) || '',
				sourceIcon: this.getSourceIcon(activity),
				videoUrl: AWS.getSecureUrl(activity.video_url),
				imageUrl: AWS.getSecureUrl(activity.image_url),
				iFrameVideo: this.isIframeVideo(activity),
				flagged: activity.flagged,
				category: activity.category || Constants.ACTIVITY.CATEGORIES.ALERTS.ID,
				trendingTopics: activity.trending_topics,
				language: activity.language,
				translated: activity.translated || '',
				translatedTitle: this.getTitle(activity, "translatedTitle"),
				auditTrail: activity.auditTrail,
				auditTrailRemoved: activity.hidden_for_users,
				auditTrailSaved: activity.alert_saved,
				auditTrailShared: activity.alerts_shared,
				auditTrailRated: activity.alert_ratings,
				auditTrailViewed: activity.views,
				views: activity.views,
				postMatches: activity.post_matches || '',
				// grouped activity related
				authors: activity.authors,
				relatedActivities: activity.related_activities,
				filename: activity.filename, // current rev
				modifiedTime: activity.modifiedTime,
				snapshot: AWS.getSecureUrl(activity.snapshot), // current rev
				thumbnail: AWS.getSecureUrl(activity.thumbnail),
				revSnapshot: AWS.getSecureUrl(activity.rev_snapshot), // each revision
				patch: activity.patch, // currently gdoc specific
				// gsuite comments
				anchorText: activity.anchorText,
				priorComments: activity.priorComments,
				// twitter compliance
				twitterRedacted: activity.compliance_event
			};
		}

		return this.props.render(normalizedProps);
	}

	// video should be in iframe for periscope or non-twitter non-instagram videos
	isIframeVideo(activity) {
		if (activity.video_url) {
			let formats = ["mp4", "ogg", "webm"],
				pathname = (new URL(activity.video_url)).pathname,
				extension = pathname.substring(pathname.lastIndexOf(".")+1),
				useIframe = !formats.includes(extension);

			return useIframe;
		}
	}

	isAuthorRedacted(activity) {
		let cEvent = activity.compliance_event;
		return cEvent &&
			(cEvent === Constants.ACTIVITY.TWITTER_REDACTION.USER_DELETE ||
				cEvent === Constants.ACTIVITY.TWITTER_REDACTION.USER_PROTECT ||
				cEvent === Constants.ACTIVITY.TWITTER_REDACTION.USER_SUSPEND ||
				cEvent === Constants.ACTIVITY.TWITTER_REDACTION.USER_WITHHELD);
	}

	getAuthorName(activity) {
		let name,
			source = activity.source.name,
			anonymize = ["gmail", "exchange", "hangoutsChat", "googleDocs"];

		if (source && anonymize.includes(source) && (activity.email || activity.chat)) {
			if (this.props.authenticatedUser.ghosting) {
				name = "Anonymized";
			} else {
				let fromObj = activity.email ? activity.email : activity.chat,
					match = fromObj.from.match(Constants.ACTIVITY.EMAIL.FROM_REGEX);
				// 0 is the full string, 1 is name, 2 is email
				if (match) {
					name = match[1].trim();
				} else {
					name = fromObj.from;
				}
			}
		} else {
			if (this.isAuthorRedacted(activity)) {
				name = "Author Name no longer available";
			} else {
				name = (activity.author && activity.author.name) || 'author missing';
			}
		}

		return name;
	}

	getAuthorLink(activity) {
		if (this.isAuthorRedacted(activity)) {
			return "";
		}

		if(activity.source.name === "tips") {
			if(!activity.tip) {
				console.warn('Tip missing');
				console.warn(activity);
				return '';
			}

			// TODO - replace "anonymous" with email or phone when it's given but no name?
			if(activity.tip.identifyPhone) {
				let numbers = activity.tip.identifyPhone.replace(/[^0-9]/g, '');
				let matches = numbers.match(/^[1]?([0-9]{3})([0-9]{3})([0-9]{4})/);
				if(matches && matches.length === 4) {
					return `tel:+1-${matches[1]}-${matches[2]}-${matches[3]}`;
				}
			// TODO - should validate/confirm it's a good email before sticking in an email to  link
			} else if(activity.tip.identifyEmail) {
				return `mailto:${activity.tip.identifyEmail}`;
			}

			// Overriding default for tips
			return '';
		}

		return (activity.author && activity.author.link) || '';
	}

	getEmailBasedFrom(activity) {
		let fromObj = activity.email || activity.chat;
		if (fromObj) {
			let match = fromObj.from.match(Constants.ACTIVITY.EMAIL.FROM_REGEX);
			// 0 is the full string, 1 is name, 2 is email
			if (match) {
				return match[2];
			}
		}
	}

	getExtraHeaders(activity) {
		let headers,
			type = activity.email ? "email" : activity.chat ? "chat" : null;

		if (type) {
			headers = {
				to: activity[type].to,
				from: this.getEmailBasedFrom(activity),
				cc: activity[type].cc,
				bcc: activity[type].bcc,
				sent: activity.published_at,
				content: activity[type].content // doesn't really belong in "headers", maybe rename to "email" instead
			};
		}

		return headers;
	}

	getComments(activity) {
		return (activity && activity.comments
			&& activity.comments.map(comment => {
				let user = comment.user;
				return {
					key: comment.id,
					time: comment.created_at,
					authorName: (user && `${user.first_name} ${user.last_name}`) || 'author missing',
					avatar: user && user.avatar,
					content: comment.content
				};
			}))
			|| [];
	}

	getSourceHandle(activity) {
		if (this.isAuthorRedacted(activity)) {
			return "";
		}

		let handle,
			base;

		// TODO: other services
		if (activity && activity.author && activity.author.link && activity.source.name) {
			base = activity.author.link.split("/").pop();
			if (activity.source.name.match(/^(twitter|instagram)$/g)) {
				handle = `@${base}`;
			} else if (activity.source.name.match(/^(reddit)$/g)) {
				handle = `u/${base}`;
			}
		}

		return handle;
	}

	getSourceIcon(activity) {
		let icon, config;
		if (activity.source.name) {
			if (activity.source.name === "tips") {
				if(!activity.tip) {
					console.warn('Tip missing');
					console.warn(activity);
					config = Constants.SOURCES.ICONS.tips;
				} else {
					let {action} = activity.tip;
					const actionSource = {
						share_positive: 'tips',
						share_uncertain: 'shareitSad',
						share_negative: 'shareitBad',
						share_tip: 'shareitTip'
					};
					config = Constants.SOURCES.ICONS[actionSource[action]];
				}
			} else {
				config = Constants.SOURCES.ICONS[activity.source.name];
			}
			activity.source.name = config && config.sourceName || activity.source.name;
			if(!(config && "icon" in config && config.icon && "image" in config.icon && config.icon.image)) {
				icon = Object.assign({icon: activity.source.name}, config ? config.icon : {});
			} else {
				icon = Object.assign({}, config.icon);
			}
		}
		return icon;
	}

	getTitle(activity, key) {
		let title;
		if (activity && activity.source && activity.source.name) {
			// // TODO: gmail, what other sources?
			// if (activity.source.name === 'tips') {
			// 	if(!activity.tip) {
			// 		console.warn('Tip missing');
			// 		console.warn(activity);
			// 		return title;
			// 	}
			// 	let {action, concernOtherDescription} = activity.tip;
			// 	let about = concernOtherDescription && ' about others' || '';
			// 	const titles = {
			// 		share_positive: 'Good Shareit',
			// 		share_uncertain: 'Sad Shareit',
			// 		share_negative: 'Bad Shareit',
			// 		share_tip: 'Shareit Tip'
			// 	};
			// 	title = `${titles[action]}${about}`;

			// } else
			if (!activity.source.name.match(/^(twitter|instagram)$/g)) {
				title = key ? activity[key] : activity.title;
			}
		}
		return title;
	}
}

DeconstructActivity.propTypes = {
	activity: PropTypes.object,
	render: PropTypes.func,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <DeconstructActivity {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "DeconstructActivity";

export default React.forwardRef(dNameFunc);
