import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DialogSlideTransition from "./DialogSlideTransition";

import {
	withStyles,
	withMobileDialog,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	withTheme,
} from '@material-ui/core';

const styles = theme => ({
	appBar: {
		paddingRight: "0 !important",
		color: theme.palette.common.white,
		borderBottom: theme.palette.primary.darkGrey,
		backgroundColor: theme.palette.primary.darkGrey
	},

	content: {
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.mixins.toolbar.minHeight + theme.spacing(4)
		}
	},

	contentNoActions: {
		paddingBottom: theme.spacing(3)
	},

	closeIcon: {
		[theme.breakpoints.down('sm')]: {
			color: theme.palette.common.white
		}
	},

	titleTyp: {
		paddingRight: theme.spacing(1)
	}
});

class ResponsiveDialog extends Component {
	closeDialog = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.closeHandler();
	}

	render() {
		let {classes, theme, open, closeHandler, fullScreen,
				content, title, actions, hasCancel, ...rest} = this.props,
			closeBtn = !hasCancel ? (
				<IconButton
					aria-label="close dialog"
					onClick={this.closeDialog}>
					<FontAwesomeIcon icon={["far", "times"]} className={classes.closeIcon} title="close dialog" />
				</IconButton>
			) : null,
			titleTyp = <Typography variant="h1" color="inherit" className={classes.titleTyp}>{title}</Typography>,
			grid = (
				<Media query={{minWidth: theme.breakpoints.values.md}}>
					{matches =>
						<Grid
							container
							justify={matches ? "space-between" : "flex-start"}
							alignItems="center"
							wrap="nowrap">
							<Grid item>
								{matches ? titleTyp : closeBtn}
							</Grid>
							<Grid item>
								{matches ? closeBtn : titleTyp}
							</Grid>
						</Grid>
					}
				</Media>
			);

		return (
			<Dialog
				{...rest}
				open={open}
				fullScreen={fullScreen}
				onClose={this.closeDialog}
				TransitionComponent={DialogSlideTransition}>
				<Media query={{minWidth: theme.breakpoints.values.md}}>
					{matches =>
						!matches ?
							<AppBar className={classes.appBar}>
								<Toolbar>
									{grid}
								</Toolbar>
							</AppBar>
							:
							<DialogTitle>{grid}</DialogTitle>
					}
				</Media>
				<DialogContent className={`${classes.content} ${!actions ? classes.contentNoActions : null}`}>{content}</DialogContent>
				{actions ? <DialogActions>{actions}</DialogActions> : null}
			</Dialog>
		);
	}
}

ResponsiveDialog.defaultProps = {
	hasCancel: false
};

ResponsiveDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	fullScreen: PropTypes.bool.isRequired,
	open: PropTypes.bool,
	closeHandler: PropTypes.func,
	content: PropTypes.any,
	title: PropTypes.any,
	actions: PropTypes.object,
	hasCancel: PropTypes.bool
};

export default withStyles(styles)(withMobileDialog()(withTheme(ResponsiveDialog)));
