import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {TextBlock} from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';

import Associations from './Associations.js';
import PostMap from './PostMap.js';
import Heading from './Heading';
import Constants from "../utils/Constants";

import { withStyles, Paper } from '@material-ui/core';

const styles = theme => ({
	heading: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},

	associationContainer: {
		[theme.breakpoints.up('sm')]: {
			marginBottom: theme.spacing(3)
		}
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const placeHolder = (
	<Paper style={{padding: 10}}>
		<TextBlock rows={2} color={placeholderColor}><span></span></TextBlock>
	</Paper>
);

class AssociationsPanel extends Component {
	// constructor(props) {
	// 	super(props);
	// 	this.myMap = React.createRef();

	// 	this.handleMapScroll = this.handleMapScroll.bind(this);
	// }

	// handleMapScroll() {
	// 	let mapOffset = this.myMap.current.offsetTop;
	// 	if(mapOffset) window.scrollTo({top: mapOffset, behavior: 'smooth'});
	// }

	render() {
		let {classes, className, associations, twitterRedacted, category} = this.props,
			ready = associations ? associations.length > 0 : false;

		return (
			<div className={className}>
				{associations && associations.length && ready ?
					associations.map((association, idx) => {
						return (
							<Paper key={idx} className={classes.associationContainer}>
								<Heading title={association.title} className={classes.heading}/>
								{(association.component === 'associations') ? (
									<Associations key={idx} content={association.content} />
								) : (null)}
								{(association.component === 'postmap') ? (
									// <RootRef rootRef={this.myMap}>
									<PostMap key={idx} association={association} twitterRedacted={twitterRedacted} category={category}></PostMap>
									// </RootRef>
								) : (null)}
							</Paper>
						);
					})
					: null
				}
				<ReactPlaceholder ready={ready} customPlaceholder={placeHolder} showLoadingAnimation><span></span></ReactPlaceholder>
			</div>
		);
	}
}

AssociationsPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	associations: PropTypes.array,
	className: PropTypes.string,
	content: PropTypes.string,
	twitterRedacted: PropTypes.string,
	category: PropTypes.number
};

export default withStyles(styles)(AssociationsPanel);
