import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
	withStyles,
	withWidth,
	Paper,
	Button,
	Typography,
	Grid
} from "@material-ui/core";

import FauxTable from "../../components/FauxTable";
import ResponsiveDialog from "../../components/ResponsiveDialog";
import Constants from "../../utils/Constants";

const styles = theme => ({
	typography: {
		marginBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1)
	},

	tableBody: {
		overflowY: "auto",
		maxHeight: theme.spacing(31.25)
	},

	analystNote: {
		marginLeft: 0,
		marginRight: 0,
		margin: theme.spacing(2),
		padding: theme.spacing(1),
		borderRadius: theme.spacing(0.5),
		background: theme.palette.grey[200]
	},

	numberColumn: {
		textAlign: "end",
		paddingRight: 0
	}
});

class EventMetricsTable extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			suggestionDlgOpen: false
		};

		this.suggestChangesClicked = this.suggestChangesClicked.bind(this);
		this.suggestionDlgClosed = this.suggestionDlgClosed.bind(this);
		this.suggestChanges = this.suggestChanges.bind(this);
	}

	suggestChangesClicked() {
		this.setState({suggestionDlgOpen: true});
	}

	suggestionDlgClosed() {
		this.setState({suggestionDlgOpen: false});
	}

	suggestChanges(event) {
		// TODO: do it
		this.suggestionDlgClosed();
		this.props.suggestionHandler(event);
	}

	render() {
		let {suggestionDlgOpen} = this.state,
			{classes, width, events, fidoError, searchTermSuggestion, suggestionChange,
				hasMoreItems, isLoading, loadNextPage, pageStart} = this.props,
			columns = [{
				id: "term",
				label: (!width.includes("xs")) ? "Search Term" : "Term"
			}, {
				id: "target",
				label: "Search Target",
				formatter: data => {
					return data.charAt(0).toUpperCase() + data.slice(1);
				}
			}, {
				id: "active",
				label: "Status",
				formatter: data => {
					return (data === 1) ? "Active" : "Inactive";
				}
			}, {
				id: "associations",
				label: (!width.includes("xs")) ? "Associated Posts" : "Posts",
				headingClassName: classes.numberColumn,
				dataClassName: classes.numberColumn
			}, {
				id: "images",
				label: (!width.includes("xs")) ? "Images Scanned" : "Images",
				headingClassName: classes.numberColumn,
				dataClassName: classes.numberColumn
			}],
			columnSizing = [
				{xs: 3, sm: 3, md: 3, lg: 3, xl: 3},
				{xs: 3, sm: 3, md: 3, lg: 3, xl: 3},
				{xs: 2, sm: 2, md: 2, lg: 2, xl: 2},
				{xs: 2, sm: 2, md: 2, lg: 2, xl: 2},
				{xs: 2, sm: 2, md: 2, lg: 2, xl: 2}
			],
			dlgContent = (
				<React.Fragment>
					<Typography>Please enter any and all information about the Search Term suggested changes you may have below. Your Optimization Analyst will review and then be in touch with you shortly.</Typography>
					<ValidatorForm
						onSubmit={this.suggestChanges}
						debounceTime={500}>
						<TextValidator
							fullWidth
							multiline
							rows="10"
							id="searchTermSuggestion"
							label="Suggested Changes"
							name="searchTermSuggestion"
							placeholder="Suggest Search Term changes related to this event"
							helperText="Suggest Search Term changes related to this event"
							margin="normal"
							variant="outlined"
							value={searchTermSuggestion}
							onChange={suggestionChange}
							validators={['required']}
							errorMessages={[Constants.ERROR_MESSAGES['required']]} />
						<Typography className={classes.analystNote} name="analystNote">
							<b>Note:</b> All submissions will be reviewed by your Optimization Analyst prior to adoption. You will be contacted when your submission goes live or if there are any concerns or additional edits required prior to adoption.
						</Typography>
						<Grid container justify="flex-end">
							<Grid item style={{paddingRight: 8}}>
								<Button onClick={this.suggestionDlgClosed}>Cancel</Button>
							</Grid>
							<Grid item>
								<Button
									type="submit"
									color="primary"
									variant="contained">Submit</Button>
							</Grid>
						</Grid>
					</ValidatorForm>
				</React.Fragment>
			);

		return (
			<React.Fragment>
				<Typography
					color="secondary"
					className={classes.typography}>Want to make a change?
					<Button
						color="primary"
						onClick={this.suggestChangesClicked}>Let us know</Button>
				</Typography>
				<Paper>
					<FauxTable
						infiniteScroll={false}
						fidoError={fidoError}
						data={events}
						columns={columns}
						columnSizing={columnSizing}
						hasMoreItems={hasMoreItems}
						isLoading={isLoading}
						loadNextPage={loadNextPage}
						pageStart={pageStart}
						headClassName={classes.tableHead}
						bodyClassName={classes.tableBody}
						noResultsMsg="Reviewing search terms..."
						noResultsStyle={{paddingTop: "10%", paddingBottom: "10%"}} />
				</Paper>
				<ResponsiveDialog
					open={suggestionDlgOpen}
					title="Search Term Suggestions"
					content={dlgContent}
					hasCancel={true}
					closeHandler={this.suggestionDlgClosed} />
			</React.Fragment>
		);
	}
}

EventMetricsTable.defaultProps = {
	searchTermSuggestion: ""
};

EventMetricsTable.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.any.isRequired,
	suggestionHandler: PropTypes.func.isRequired,
	suggestionChange: PropTypes.func.isRequired,
	searchTermSuggestion: PropTypes.string,
	events: PropTypes.array,
	fidoError: PropTypes.bool,
	hasMoreItems: PropTypes.bool,
	isLoading: PropTypes.bool,
	loadNextPage: PropTypes.func,
	pageStart: PropTypes.number
};

export default withStyles(styles)(withWidth()(EventMetricsTable));
