import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Fido from '../../utils/Fido';
import AuthorizedUser from "../../utils/AuthorizedUser";
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import OverviewReportLayout from "../components/OverviewReportLayout";
import SparkChartReportItem from "../components/SparkChartReportItem";
import TabbedChartsOverTime from "./TabbedChartsOverTime";
import AssociatedDigitalPropertiesReportItem from "../components/AssociatedDigitalPropertiesReportItem";
import UserEngagementReportItem from "../components/UserEngagementReportItem";
import TrendingTopicsReportItem from "../components/TrendingTopicsReportItem";
import RecentPostsItem from "../components/RecentPostsItem";
import TeamActivityReportItem from "../components/TeamActivityReportItem";
import StudentSentimentReportItem from "../components/StudentSentimentReportItem";
import Constants from '../../utils/Constants';
import DeepMerge from "../../utils/DeepMerge";

//import {dashboardData, userEngagementData, trendingTopicItemData, sentimentData} from "../../data/reports";

class OverviewReport extends Component {
	static get defaultPrevDays() {
		return 30;
	}

	constructor(props, context) {
		super(props, context);

		this.fido = new Fido();
		this.propertiesFido = new Fido();
		this.engagementFido = new Fido();
		this.recentFido = new Fido();
		this.teamActivityFido = new Fido();
		this.topicsFido = new Fido();
		this.activityFido = new Fido();
		this.sentimentFido = new Fido();
		this.moreInfoFido = new Fido();

		let {location, authenticatedUser} = this.props,
			accountParam = Fido.getSearchParam("account", location.search);

		let filter = {
			previousDays: Number(Fido.getSearchParam("previousDays", this.props.location.search)) || OverviewReport.defaultPrevDays,
			account: accountParam ? accountParam.split(",").map(item => Number(item)) : authenticatedUser.childAccounts.map(item => item.id)
		};

		// TODO - move?
		let sfAccount = parseInt(Fido.getSearchParam("sfAccount"), 10) || null;
		this.isSalesforceRequest = !!sfAccount;
		if(this.isSalesforceRequest) {
			filter.sfAccount = sfAccount;
		}

		this.includeSentiment = true;

		this.state = {
			totalPosts: null,
			postsAmountChange: null,
			postsPercentageChange: null,

			totalImages: null,
			imagesAmountChange: null,
			imagesPercentageChange: null,
			postsAndImages: [],

			totalAlerts: null,
			alertsAmountChange: null,
			alertsPercentageChange: null,
			alerts: [],

			totalDiscussions: null,
			discussionsAmountChange: null,
			discussionsPercentageChange: null,
			discussions: [],

			totalAlertsViewed: null,
			alertsViewedAmountChange: null,
			alertsViewedPercentageChange: null,
			alertViews: [],

			alertRatio: null,
			ssiAlertRatio: null,

			fidoError: false,
			filter,
			defaultFilter: Object.assign({}, filter),

			digitalProperties: null,
			propertiesFidoError: false,

			activities: null,
			recentFidoError: false,

			engagementFidoError: false,
			engagement: {
				limit: 3,
				sortDir: "desc",
				sortBy: "activityCount",
				usersEngagement: null
			},

			topicData: null,
			topicsFidoError: false,

			discussionData: null,
			discussionFidoError: false,

			teamActivityData: null,
			teamActivityFidoError: false,

			sentimentData: null,
			sentimentFidoError: false,

			propertiesMoreInfoData: null
		};

		this.executeFilter = this.executeFilter.bind(this);
		this.drillDown = this.drillDown.bind(this);
		this.propertiesMoreInfoClicked = this.propertiesMoreInfoClicked.bind(this);
	}

	componentDidMount() {
		let {authenticatedUser} = this.props,
			isK12 = authenticatedUser.verticalId === Constants.ACCOUNT.VERTICAL.K12;

		this._isMounted = true;

		/* second check is if they only have Social Media, don't show the report as Overview is almost identical */
		this.includeSentiment = !this.isSalesforceRequest && AuthorizedUser.hasSocialMediaOnly(authenticatedUser) && isK12;

		this.fetchData();
		this.props.updateHeader({title: "overview", primary: true, hideHeaderbar: this.isSalesforceRequest});
	}

	componentWillUnmount() {
		this.dropThem();
		this.props.resetHeader();
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.search === "" && prevProps.location.search !== "") {
			let {filter} = this.state,
				{authenticatedUser} = this.props;

			filter.account = authenticatedUser.childAccounts.map(item => item.id);
			this.setState({
				filter: filter,
				defaultFilter: Object.assign({}, filter)
			});
		}
	}

	dropThem() {
		this.fido.dropIt();
		this.propertiesFido.dropIt();
		this.engagementFido.dropIt();
		this.recentFido.dropIt();
		this.teamActivityFido.dropIt();
		this.topicsFido.dropIt();
		this.activityFido.dropIt();
		this.sentimentFido.dropIt();
		this.moreInfoFido.dropIt();
	}

	fetchData(opts) {
		this.setState({
			postsAndImages: [],
			alerts: [],
			alertViews: [],
			discussions: [],
			sentimentData: null,
			topicData: null,
			discussionData: null,
			activities: null,
			engagement: Object.assign(this.state.engagement, {usersEngagement: null}),
			teamActivityData: null
		}, () => {
			// Doesn't do deep copy, so sub-objects are by ref :/
			const filter = Object.assign({}, this.state.filter);
			const engagement = this.state.engagement;

			this.fido.fetch("/reports/overview/spark", {query: filter})
				.then((data) => {
					if (data) {
						// TEMP
						//data = dashboardData;
						this.setState(data);
					}
				})
				.catch(error => {
					this.setState({fidoError: true});
					return this.props.showSnackbar(error.message);
				});

			this.propertiesFido.fetch("/reports/digital-properties", {query: filter})
				.then((data) => {
					if (data) {
						this.setState(data);
					}
				})
				.catch(error => {
					this.setState({propertiesFidoError: true});
					this.props.showSnackbar(error.message);
				});

			let engagementOpts = DeepMerge.merge({query: engagement}, {query: filter});
			delete engagementOpts.query.usersEngagement;
			this.engagementFido.fetch("/reports/engagement", engagementOpts)
				.then((data) => {
					if (data) {
						// TEMP
						//data = userEngagementData;
						this.setState({engagement: data});
					}
				})
				.catch(error => {
					this.setState({engagementFidoError: true});
					return this.props.showSnackbar(error.message);
				});

			if(!this.isSalesforceRequest) {
				this.loadTopicDiscussionData(filter);
			}

			let recentOps = DeepMerge.merge({query: {limit: 5}}, {query: filter});
			this.recentFido.fetch("/activities", recentOps)
				.then((data) => {
					if (data) {
						this.setState(data);
					}
				})
				// TODO: probably only want to show some errors as snackbars
				.catch(error => {
					this.setState({recentFidoError: true});
					return this.props.showSnackbar(error.message);
				});

			let teamActivityOps = DeepMerge.merge({query: {limit: 15}}, {query: filter});
			this.teamActivityFido.fetch("/reports/team-activity", teamActivityOps)
				.then((data) => {
					if (data) {
						this.setState({teamActivityData: data.teamActivity});
					}
				})
				// TODO: probably only want to show some errors as snackbars
				.catch(error => {
					this.setState({teamActivityFidoError: true});
					return this.props.showSnackbar(error.message);
				});

			if (this.includeSentiment) {
				this.loadSentimentData(filter);
			}
		});
	}

	async loadTopicDiscussionData(filter) {
		//if (opts.query.previousDays === 30) {
		let topicOpts = {query: Object.assign({sortBy: "posts"}, filter)},
			discussionOpts = DeepMerge.merge({
				query: {
					limit: 5,
					category: "discussions"
				}
			}, {query: filter});

		// Mixing async & promise paradigms risks hard to find bugs
		// & increases maintenance effort
		let [topics, discussionResults] = await Promise.all([
			this.topicsFido.fetch("/trending-topics", topicOpts)
				.catch(error => {
					this.setState({topicsFidoError: true});
					return this.props.showSnackbar(error.message);
				}),
			this.activityFido.fetch("/activities", discussionOpts)
				.catch(error => {
					this.setState({discussionFidoError: true});
					return this.props.showSnackbar(error.message);
				})
		]);

		if (this._isMounted === true) {
			this.setState({topicData: topics});
		}

		if (this._isMounted === true) {
			this.setState({discussionData: discussionResults ? discussionResults.activities : []});
		}
	}

	async loadSentimentData(filter) {
		let url = Constants.SERVICES.SENTIMENT.getUrl(),
			data = await this.sentimentFido.fetch(url, {cors: true, query: {previousDays: filter.previousDays}})
				.catch(error => {
					this.setState({sentimentFidoError: true});
					return this.props.showSnackbar(error.message);
				});

		if (data) {
			this.setState({sentimentData: data.data});
		}

		// TEMP
		/*let blee = await this.sentimentFido.fetch("/reports/digital-properties")
			.catch(error => {
				this.setState({sentimentFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		this.setState({sentimentData: sentimentData});*/
	}

	getFilterOpts(filterObj) {
		let opts,
			{filter} = this.state;

		filter.account = [];

		if (!filterObj.previousDays) {
			delete filter.previousDays;
		}

		Number(filter.previousDays);

		opts = DeepMerge.merge({}, filter, filterObj);

		// if all selected, don't include as url param
		if (Array.isArray(opts.account) && opts.account.length === this.props.authenticatedUser.childAccounts.length) {
			opts.account = [];
		}

		return opts;
	}

	executeFilter(filterObj) {
		let opts = this.getFilterOpts(filterObj);
		this.props.history.replace(Fido.buildPath("/overview", opts));
		this.dropThem();
		this.setState({filter: opts}, this.fetchData);
	}

	drillDown(path, unsupportedFilters) {
		unsupportedFilters = unsupportedFilters || [];
		// peristing current filters for drilldown; afaik, only account is array, so it's
		// only one that needs special handling
		let overrides = this.state.filter.account && {account: this.state.filter.account} || {};
		let options = Object.assign({}, this.state.filter, overrides);

		// By setting unsupported parameters to an empty array, fido will remove
		unsupportedFilters.map(key => options[key] = []);
		this.props.history.push(Fido.buildPath(path, this.getFilterOpts(options)));
	}

	propertiesMoreInfoClicked(key) {
		this.setState({propertiesMoreInfoData: null}, () => {
			this.moreInfoFido.fetch("/reports/digital-properties/more-info", {query: {source: key}})
				.then((data) => {
					if (data) {
						this.setState({propertiesMoreInfoData: data});
					}
				})
				.catch(error => {
					this.setState({moreInfoError: true});
					return this.props.showSnackbar(error.message);
				});
		});
	}

	render() {
		const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 2});
		let {history, updateHeader, resetHeader, showSnackbar, location, csm} = this.props,
			{filter, fidoError, engagementFidoError, topicsFidoError,
				totalPosts, postsPercentageChange, postsAmountChange,
				imagesAmountChange, imagesPercentageChange, totalImages,
				totalAlerts, alertsAmountChange, alertsPercentageChange,
				totalDiscussions, discussionsAmountChange, discussionsPercentageChange,
				totalAlertsViewed, alertsViewedPercentageChange, alertsViewedAmountChange,
				alertRatio, ssiAlertRatio,
				postsAndImages, alerts, alertViews, discussions,
				digitalProperties, propertiesFidoError,
				engagement, topicData, discussionData, discussionFidoError,
				activities, recentFidoError,
				teamActivityData, teamActivityFidoError,
				sentimentData, sentimentFidoError, defaultFilter, propertiesMoreInfoData} = this.state,
			previousDays = Number(filter.previousDays || OverviewReport.defaultPrevDays),
			recentAlertsTitle = (
				<div>
					<FontAwesomeIcon title="recent alerts" icon={["fas", "wifi"]} transform={{ rotate: 90 }} /> Most Recent Alerts
				</div>
			),
			recentDiscussionsTitle = (
				<div>
					<FontAwesomeIcon title="conversation bubbles" icon={["far", "comments-alt"]} /> Most Recent Discussions
				</div>
			),
			sparkCards = [
				<ReportDataMasseuse
					key={"spark0"}
					dataConfig={[{data: postsAndImages, key: "period", valueKey: "associations"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={fidoError}
							title="Associated Posts"
							value={totalPosts}
							data={data[0]}
							change={postsAmountChange}
							previousDays={previousDays}
							percentChange={postsPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark1"}
					dataConfig={[{data: postsAndImages, key: "period", valueKey: "images"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={fidoError}
							title="Images Scanned"
							value={totalImages}
							data={data[0]}
							change={imagesAmountChange}
							previousDays={previousDays}
							percentChange={imagesPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark2"}
					dataConfig={[{data: alerts, key: "period", valueKey: "alerts"}]}
					render={data =>
						<SparkChartReportItem
							invertChevron
							fidoError={fidoError}
							title="Alerts"
							value={totalAlerts}
							data={data[0]}
							change={alertsAmountChange}
							previousDays={previousDays}
							percentChange={alertsPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark3"}
					dataConfig={[{data: alerts, key: "period", valueKey: "alert_ratio"}]}
					render={data =>
						<SparkChartReportItem
							invertChevron
							valueIsPercentage
							excludePerDay
							fidoError={fidoError}
							title="Alert Ratio"
							value={alertRatio}
							data={data[0]}
							caption={`Benchmark: ${numFormatter.format(ssiAlertRatio)}%`}
							forceGreen={alertRatio < ssiAlertRatio} />
					} />,
				<ReportDataMasseuse
					key={"spark4"}
					dataConfig={[{data: alertViews, key: "period", valueKey: "alerts_viewed"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={fidoError}
							title="Alerts Viewed"
							value={totalAlertsViewed}
							data={data[0]}
							change={alertsViewedAmountChange}
							percentChange={alertsViewedPercentageChange}
							forceGreen={alertsViewedPercentageChange === 100}
							previousDays={previousDays} />
					} />,
				<ReportDataMasseuse
					key={"spark5"}
					dataConfig={[{data: discussions, key: "period", valueKey: "alerts"}]}
					render={data =>
						<SparkChartReportItem
							invertChevron
							fidoError={fidoError}
							title="Discussions"
							value={totalDiscussions}
							data={data[0]}
							change={discussionsAmountChange}
							percentChange={discussionsPercentageChange}
							previousDays={previousDays} />
					} />
			];

		const topicsOrRecents = (topicData && topicData.length) ?
			<TrendingTopicsReportItem
				fidoError={topicsFidoError}
				previousDays={previousDays}
				topics={topicData}
				viewAllClickHandler={() => this.drillDown("/activities?category=discussions", ["previousDays"])}
				overTimeClickHandler={() => this.drillDown("/reports/trending-topics")} />
			:
			<RecentPostsItem
				activities={discussionData}
				history={history}
				fidoError={discussionFidoError}
				title={recentDiscussionsTitle}
				itemClickPath="/activities"
				buttonText="view all discussions"
				noDataMessage="There are no recent discussions for this time period."
				buttonClickHandler={() => this.drillDown("/activities?category=discussions", ["previousDays"])} />;

		return (
			<OverviewReportLayout
				location={location}
				sparkCards={sparkCards}
				previousDays={previousDays}
				filterCallback={this.executeFilter}
				selectedAccounts={defaultFilter && defaultFilter.account}>
				<TabbedChartsOverTime
					fullWidth
					filter={filter}
					updateHeader={updateHeader}
					resetHeader={resetHeader}
					showSnackbar={showSnackbar}
					location={location} />
				{this.includeSentiment ?
					<StudentSentimentReportItem
						fullWidth
						data={sentimentData}
						previousDays={previousDays}
						fidoError={sentimentFidoError} />
					: null
				}
				<div divider="true"></div>
				{!this.isSalesforceRequest
					&& <AssociatedDigitalPropertiesReportItem
						fullWidth
						csm={csm}
						properties={digitalProperties}
						fidoError={propertiesFidoError}
						moreInfoData={propertiesMoreInfoData}
						moreInfoHandler={this.propertiesMoreInfoClicked} />
					|| null}
				{!this.isSalesforceRequest
					&& <AssociatedDigitalPropertiesReportItem
						fullWidth
						isProducts
						csm={csm}
						properties={digitalProperties}
						fidoError={propertiesFidoError}
						moreInfoData={propertiesMoreInfoData}
						moreInfoHandler={this.propertiesMoreInfoClicked} />
					|| null}
				{!this.isSalesforceRequest
					&& <div divider="true"></div>
					|| null}
				{!this.isSalesforceRequest && topicsOrRecents || null}
				{!this.isSalesforceRequest
					&& <RecentPostsItem
						activities={activities}
						history={history}
						fidoError={recentFidoError}
						title={recentAlertsTitle}
						itemClickPath="/activities"
						buttonText="view all alerts"
						noDataMessage="There are no recent alerts for this time period."
						buttonClickHandler={() => this.drillDown("/activities?category=alerts", ["previousDays"])} />
					|| null}
				<Media query={{minWidth: 992, maxWidth: 1699}}>
					{matches =>
						<UserEngagementReportItem
							fidoError={engagementFidoError}
							previousDays={previousDays}
							sortDir={engagement.sortDir}
							sortBy={engagement.sortBy}
							totalUsers={engagement.totalUsers}
							usersEngagement={engagement.usersEngagement}
							smallEngagementTable={matches}
							footerClickHandler={() => this.drillDown("/reports/user-engagement")} />
					}
				</Media>
				<TeamActivityReportItem
					activity={teamActivityData}
					fidoError={teamActivityFidoError} />
			</OverviewReportLayout>
		);
	}
}

OverviewReport.propTypes = {
	csm: PropTypes.object,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	history: PropTypes.object,
	location: PropTypes.object,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <OverviewReport {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "OverviewReport";

export default (React.forwardRef(dNameFunc));