import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Timestamp from './Timestamp.js';
import AvatarRowWithCol from './AvatarRowWithCol.js';

import { Typography, withStyles, Grid } from '@material-ui/core';

const styles = theme => ({
	avatarRow: {
		paddingTop: theme.spacing(3)
	}
});

class Comment extends Component {
	render() {
		let {classes, ready} = this.props;
		return (
			<div className={classes.avatarRow}>
				<AvatarRowWithCol
					ready={ready}
					authorName={this.props.authorName}
					authorImage={this.props.avatar}>
					<Grid container direction="column" justify="flex-start" alignItems="stretch">
						<Grid item>
							<Typography variant="body1">{this.props.authorName}</Typography>
						</Grid>
						<Grid item>
							<Typography>{this.props.content}</Typography>
						</Grid>
						<Grid item>
							<Timestamp time={this.props.time} />
						</Grid>
					</Grid>
				</AvatarRowWithCol>
			</div>
		);
	}
}

Comment.propTypes = {
	classes: PropTypes.object.isRequired,
	authorName: PropTypes.string,
	avatar: PropTypes.string,
	content: PropTypes.string,
	time: PropTypes.string,
	ready: PropTypes.bool
};

export default withStyles(styles)(Comment);
