import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Intercom from "react-intercom";
import HeaderBar from './HeaderBar';

import Fido from "../utils/Fido";
import Snackbar from './Snackbar';
import Banner from './Banner';
import IOSInstallDialog from "./IOSInstallDialog";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import Constants from '../utils/Constants';
import { withStyles, Button, withTheme } from '@material-ui/core';

const styles = theme => ({
	fullscreen: {
		// Found headerbar flexing based on media query - this mimics it so our content is always spaced ok
		marginTop: theme.spacing(7),

		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(8)
		}
	},

	fullscreenSignalsFirst: {
		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(23.5)
		},

		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(19)
		},

		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(25.375)
		}
	},

	authorized: {
		[theme.breakpoints.up('lg')]: {
			paddingLeft: theme.drawer.width
		}
	},

	accessibilityBtn: {
		zIndex: 0,
		left: -100000,
		position: "fixed"
	},

	visibleAccessibilityBtn: {
		zIndex: 5000,
		left: theme.spacing(2)
	},

	skipNavBtn: {
		top: theme.spacing(7)
		// TODO - need updated spacing when signals banner showing
		// top: theme.spacing(15)
	},

	contrastBtn: {
		minWidth: 136,
		top: theme.spacing(12)
		// TODO - need updated spacing when signals banner showing
		// top: theme.spacing(20)
	},

	fabMoveUp: {
		[theme.breakpoints.down('md')]: {
			transform: 'translate3d(0, -46px, 0) !important',
			transition: `${theme.transitions.create('transform', {
				duration: theme.transitions.duration.enteringScreen,
				easing: theme.transitions.easing.easeOut,
			})} !important`,
		}
	},

	fabMoveUpMore: {
		[theme.breakpoints.down('md')]: {
			transform: 'translate3d(0, -69px, 0) !important',
			transition: `${theme.transitions.create('transform', {
				duration: theme.transitions.duration.enteringScreen,
				easing: theme.transitions.easing.easeOut,
			})} !important`,
		},

		[theme.breakpoints.down('sm')]: {
			transform: 'translate3d(0, -75px, 0) !important',
			transition: `${theme.transitions.create('transform', {
				duration: theme.transitions.duration.enteringScreen,
				easing: theme.transitions.easing.easeOut,
			})} !important`,
		},

		[theme.breakpoints.up('xs')]: {
			transform: 'translate3d(0, -90px, 0) !important',
			transition: `${theme.transitions.create('transform', {
				duration: theme.transitions.duration.enteringScreen,
				easing: theme.transitions.easing.easeOut,
			})} !important`,
		}
	},

	fabMoveDown: {
		[theme.breakpoints.down('md')]: {
			transform: 'translate3d(0, 0, 0) !important',
			transition: `${theme.transitions.create('transform', {
				duration: theme.transitions.duration.leavingScreen,
				easing: theme.transitions.easing.sharp,
			})} !important`,
		}
	},

	nudgeBanner: {
		position: "fixed",
		top: 0,
		width: "100%",
		zIndex: 2,
		backgroundColor: "#ff961e",
		color: "#fff"
	},

	bannerWhenSignalsFirst: {
		marginTop: theme.spacing(17.25)
		//marginTop: theme.spacing(23)
	},

	nudgeBannerIconClassname: {
		color: "#fff"
	}
});

class FullScreen extends Component {
	constructor(props, context) {
		super(props, context);

		this.localStorageKey = Constants.LOCAL_STORAGE.CONTRAST;
		this.state = {
			showAccessibilityBtns: false,
			moreContrast: JSON.parse(localStorage.getItem(this.localStorageKey))
		};

		this.toggleAccessibilityBtns = this.toggleAccessibilityBtns.bind(this);
		this.toggleContrast = this.toggleContrast.bind(this);
		this.useSignals = this.useSignals.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.snackbarOpen !== this.props.snackbarOpen || prevProps.tallSnackbar !== this.props.tallSnackbar) {
			let el,
				{snackbarOpen, tallSnackbar, classes} = this.props,
				intercomFrame = document.querySelectorAll("[class*='intercom-launcher-frame']");

			if (intercomFrame && intercomFrame.length) {
				el = intercomFrame[0];
				el.classList.add(
					snackbarOpen ?
						tallSnackbar ? classes.fabMoveUpMore : classes.fabMoveUp
						: classes.fabMoveDown
				);

				if (snackbarOpen === false) {
					setTimeout(() => {
						intercomFrame[0].classList.remove(classes.fabMoveUp, classes.fabMoveUpMore, classes.fabMoveDown);
					}, this.props.theme.transitions.duration.enteringScreen - 50);
				}
			}
		}
	}

	toggleAccessibilityBtns() {
		this.setState({showAccessibilityBtns: !this.state.showAccessibilityBtns});
	}

	toggleContrast() {
		localStorage.setItem(this.localStorageKey, !this.state.moreContrast);
		setTimeout(() => window.location.reload(), 100);
	}

	useSignals() {
		let hanSolo = new Fido(),
			{authenticatedUser, showSnackbar, updateUser} = this.props;

		hanSolo.fetch(`/users/${authenticatedUser.id}/use-signals`, {
			method: "PUT",
			body: JSON.stringify({useSignals: true})
		})
			.then(() => {
				authenticatedUser.useSignals = true;
				updateUser(authenticatedUser);
			})
			.catch((error) => showSnackbar(error.message));
	}

	render() {
		let {showAccessibilityBtns, moreContrast} = this.state,
			{theme, classes, showIOSInstallDlg, iOSDlgCloseHandler, snackbarOpen, tallSnackbar, snackbarMessage, snackbarDuration,
				snackbarHandleEntering, snackbarHandleExited, snackbarHandleClick, snackbarHandleClose, snackbarAction,
				banner, authenticatedUser, hideHeaderbar, hideMargin, snackbarRef, locationPath,
				bannerClassName, bannerRef, bannerHandleEntering, bannerHandleEntered, bannerHandleExiting, bannerHandleExited, signalsBannerRef, signalsFirstEnabled, ...rest} = this.props,
			lessOrMore = moreContrast ? "Less" : "More",
			intercomInfo = {
				app_id: Constants.INTERCOM.APP_ID[!Constants.ENV.IS_LIVE ? "LOCAL" : "LIVE"],
				name: authenticatedUser.name,
				user_id: authenticatedUser.id,
				user_hash: authenticatedUser.userHash,
				email: authenticatedUser.email,
				company: {
					id: authenticatedUser.accountId,
					name: authenticatedUser.accountName
				},
				custom_launcher_selector: "#IntercomChatButton",
				action_color: moreContrast ? theme.palette.primary.main : null
			},
			signalsNudge = (
				<Banner
					show={true}
					bannerRef={signalsBannerRef}
					className={classes.nudgeBanner}
					icon={{
						icon: ["fad", "satellite-dish"],
						className: classes.nudgeBannerIconClassname
					}}
					content="On 5/3/21, you’ll automatically be upgraded to Signals, our streamlined new platform that we told you about earlier. Upgrade now?"
					actions={[{
						color: "primary",
						variant: "outlined",
						text: "Upgrade Now",
						handler: this.useSignals,
						style: {color: "#fff", border: "1px solid #fff"}
					}]} />
			);

		// DON'T MOVE SKIP NAV BUTTON - needed for proper tabbing on faux page navigation
		return (
			<React.Fragment>
				{locationPath !== "/login" ? (
					<div>
						<Button
							tabIndex="1"
							color="primary"
							variant="contained"
							href="#content_top"
							onFocus={this.toggleAccessibilityBtns}
							className={`${classes.skipNavBtn} ${classes.accessibilityBtn} ${showAccessibilityBtns ? classes.visibleAccessibilityBtn : null}`}>
							Skip Navigation
						</Button>
						<Button
							tabIndex="2"
							component="a"
							color="primary"
							variant="contained"
							onClick={this.toggleContrast}
							onBlur={this.toggleAccessibilityBtns}
							className={`${classes.contrastBtn} ${classes.accessibilityBtn} ${showAccessibilityBtns ? classes.visibleAccessibilityBtn : null}`}>
							{lessOrMore} Contrast
						</Button>
					</div>
				) : null}
				{signalsFirstEnabled ? signalsNudge : null}
				<div className={`${hideMargin ? null :
					classes.fullscreen} ${authenticatedUser && locationPath !== '/login' ? classes.authorized : null}
					${signalsFirstEnabled ? classes.fullscreenSignalsFirst: ""}`}>
					{hideHeaderbar ? null : <HeaderBar {...rest} signalsFirstEnabled={signalsFirstEnabled} />}
					<Banner
						show={!!banner}
						icon={banner ? banner.icon : null}
						content={banner ? banner.content : null}
						actions={banner ? banner.actions : null}
						className={`${bannerClassName}`}
						collapseClassName={signalsFirstEnabled ? classes.bannerWhenSignalsFirst : ""}
						bannerRef={bannerRef}
						onEntering={bannerHandleEntering}
						onEntered={bannerHandleEntered}
						onExiting={bannerHandleExiting}
						onExited={bannerHandleExited} />
					<main id="content_top">{this.props.children}</main>
					<Snackbar
						snackbarRef={snackbarRef}
						open={snackbarOpen}
						key={snackbarMessage}
						action={snackbarAction}
						message={snackbarMessage}
						autoHideDuration={snackbarDuration}
						onEntering={snackbarHandleEntering}
						onExited={snackbarHandleExited}
						onClose={snackbarHandleClose}
						onClick={snackbarHandleClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}} />
					<IOSInstallDialog showIOSInstallDlg={showIOSInstallDlg} iOSDlgCloseHandler={iOSDlgCloseHandler} />
					{authenticatedUser && !authenticatedUser.ghosting ? (
						<Intercom
							{ ...intercomInfo }
							appID={intercomInfo.app_id} />
					) : null}
				</div>
			</React.Fragment>
		);
	}
}

FullScreen.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	csm: PropTypes.object.isRequired,
	children: PropTypes.object,
	signInUrl: PropTypes.string,
	title: PropTypes.string,
	hideHeaderbar: PropTypes.bool,
	hideMargin: PropTypes.bool,
	installable: PropTypes.bool,
	showIOSInstallDlg: PropTypes.bool,
	iOSDlgCloseHandler: PropTypes.func,
	handleInstallBtn: PropTypes.func,
	snackbarRef: PropTypes.object,
	showSnackbar: PropTypes.func,
	snackbarOpen: PropTypes.bool,
	tallSnackbar: PropTypes.bool,
	snackbarAction: PropTypes.object,
	snackbarMessage: PropTypes.string,
	snackbarDuration: PropTypes.number,
	snackbarHandleEntering: PropTypes.func,
	snackbarHandleExited: PropTypes.func,
	snackbarHandleClick: PropTypes.func,
	snackbarHandleClose: PropTypes.func,
	history: PropTypes.object.isRequired,
	locationPath: PropTypes.string.isRequired,
	banner: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	bannerRef: PropTypes.object,
	bannerHeight: PropTypes.number,
	bannerHandleEntering: PropTypes.func,
	bannerHandleEntered: PropTypes.func,
	bannerHandleExiting: PropTypes.func,
	bannerHandleExited: PropTypes.func,
	bannerClassName: PropTypes.string,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	signalsBannerRef: PropTypes.object,
	signalsFirstEnabled: PropTypes.bool,
	updateUser: PropTypes.func
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <FullScreen {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "FullScreen";

export default withStyles(styles)(withTheme(React.forwardRef(dNameFunc)));
