import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import Media from 'react-media';

import ReportItemPeeps from "./ReportItemPeeps";

import { withStyles, Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';

const personColor = "#371833";
const styles = theme => ({
	table: {
		// 
	},

	cell: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},

	otherCell: {
		paddingTop: 0,
		paddingBottom: 0,
		borderBottom: "none"
	},

	personCell: {
		paddingRight: "0 !important"
	},

	topicCell: {
		paddingLeft: theme.spacing(.5),
		paddingRight: theme.spacing(.5)
	},

	topicName: {
		fontSize: "0.9rem",

		[theme.breakpoints.down('md')]: {
			fontSize: "0.8rem"
		},

		[theme.breakpoints.down('xs')]: {
			fontSize: "0.9rem"
		}
	},

	row0: {
		color: `${personColor}`
	},

	row1: {
		opacity: 0.7,
		color: `${personColor}`
	},

	row2: {
		opacity: 0.4,
		color: `${personColor}`
	},

	row3: {
		opacity: 0.2,
		color: `${personColor}`
	}
});

class TrendingTopicsTable extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			topics: [],
			moreTopicCount: null
		};

		// maybe in future allow as prop but would need to figure out CSS
		this.rowsToShow = 3;
	}

	componentDidMount() {
		this.setupTopics();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.topics !== prevProps.topics) {
			this.setupTopics();
		}
	}

	setupTopics() {
		if (this.props.topics) {
			let moreTopicCount = this.props.topics.length - this.rowsToShow,
				topics = this.props.topics.slice(0, this.rowsToShow+1),
				maxValue = Math.max(...topics.map(t => t.posts));

			topics.forEach(topic => {
				topic.proportion = (topic.posts / maxValue);
				topic.name = topic.name.replace(/\|/ig, "/");
				return topic;
			});

			this.setState({
				topics: topics,
				moreTopicCount: moreTopicCount
			});
		}
	}

	render() {
		let {topics, moreTopicCount} = this.state,
			{classes, theme} = this.props;

		return <Table className={classes.table}>
			<TableBody>
				{topics.map((topic, index) => {
					return index < this.rowsToShow ? (
						<TableRow key={`key-${topic.id}`}>
							<TableCell className={`${classes.cell} ${classes.topicCell}`} width="35%">
								<Typography variant="subtitle2" align="right" className={classes.topicName}>{topic.name}</Typography>
								<Typography variant="caption" align="right" color="textSecondary" noWrap>
									{topic.posts} posts &#8226; {topic.authors} people
								</Typography>
							</TableCell>
							<TableCell className={`${classes.cell} ${classes.personCell}`}>
								<Media query={{minWidth: theme.breakpoints.values.xl}}>
									{matches =>
										matches
											? <ReportItemPeeps
												className={classes["row"+index]}
												numberOfPeeps={Math.round(topic.proportion * 10)} />
											: <Media query={{minWidth: theme.breakpoints.values.md}}>
												{matches2 =>
													matches2
														? <ReportItemPeeps
															className={classes["row"+index]}
															numberOfPeeps={Math.round(topic.proportion * 6)} />
														: <Media query={{minWidth: theme.breakpoints.values.sm}}>
															{matches3 =>
																matches3
																	? <ReportItemPeeps
																		className={classes["row"+index]}
																		numberOfPeeps={Math.round(topic.proportion * 12)} />
																	: <Media query={{minWidth: 425}}>
																		{matches4 =>
																			matches4
																				? <ReportItemPeeps
																					className={classes["row"+index]}
																					numberOfPeeps={Math.round(topic.proportion * 5)} />
																				: <ReportItemPeeps
																					className={classes["row"+index]}
																					numberOfPeeps={Math.round(topic.proportion * 3)} />
																		}
																	</Media>
															}
														</Media>
												}
											</Media>
									}
								</Media>
							</TableCell>
						</TableRow>
					) : index === this.rowsToShow ? (
						<TableRow key={`key-${topic.id}`}>
							<TableCell className={`${classes.cell} ${classes.otherCell}`} colSpan={2}>
								<Typography variant="body1" color="textSecondary">
									There {moreTopicCount > 1 ? "Are" : "Is"} {moreTopicCount} Other Topic{moreTopicCount > 1 ? "s" : ""}
								</Typography>
							</TableCell>
						</TableRow>
					) : null;
				})}
			</TableBody>
		</Table>;
	}
}

TrendingTopicsTable.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	topics: PropTypes.array
};

export default withStyles(styles)(withTheme(TrendingTopicsTable));
