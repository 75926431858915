import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import PaddedLayout from './PaddedLayout';
import ActivitiesWrapper from "../containers/ActivitiesWrapper";
import Constants from "../utils/Constants";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import Fido from '../utils/Fido';
import PositionMemory from "../components/PositionMemory";
import Filterer from '../components/Filterer';

import { withStyles, Tabs, Tab } from '@material-ui/core';

//import {activitiesData, topicData} from "../data/activities";

const styles = theme => ({
	hidden: {
		display: "none"
	},

	tabs: {
		right: 0,
		position: "fixed",
		left: theme.drawer.width,
		marginBottom: theme.spacing(1),
		zIndex: theme.zIndex.drawer - 1,
		background: theme.palette.common.white,
		borderBottom: "1px solid #ccc",

		"@media (max-width: 1023px)": {
			left: 0,
			width: "100%"
		},

		[theme.breakpoints.down('xs')]: {
			top: theme.mixins.toolbar.minHeight
		},

		[theme.breakpoints.up('sm')]: {
			top: theme.mixins.toolbar["@media (min-width:768px)"].minHeight,
		}
	},

	signalsFirst: {
		top: theme.spacing(17),

		[theme.breakpoints.down('md')]: {
			top: theme.spacing(17.5)
		},

		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(19)
		},

		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(25.375)
		}
	},

	layoutContainer: {
		marginTop: theme.spacing(7),
	},

	containerSignalsFirst: {
		marginTop: theme.spacing(5),

		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(1)
		},

		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(5)
		},

		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(5)
		}
	},

	tab: {
		minWidth: "100px",
		textTransform: "none"
	},

	filterSelectField: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1)
	},

	marginedBanner: {
		marginTop: 113,

		[theme.breakpoints.down('xs')]: {
			marginTop: 105,
		}
	},

	marginedBannerSignalsFirst: {
		marginTop: theme.spacing(23.125),

		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(22.125)
		}
	}
});

class Activities extends Component {
	constructor(props, context) {
		super(props, context);

		this.defaultCategory = "alerts";

		this.state = {
			topics: [],
			category: Fido.getSearchParam("category", this.props.location.search) || this.defaultCategory
		};

		this.topicFido = new Fido();

		this.categoryHandler = this.categoryHandler.bind(this);
		this.loadLibraryTopics = this.loadLibraryTopics.bind(this);
		this.filter = this.filter.bind(this);
	}

	componentDidMount() {
		let {updateHeader, setBannerClassName, classes, signalsFirstEnabled} = this.props;

		this.loadLibraryTopics();
		this.setupCategory();
		setBannerClassName(`${classes.marginedBanner} ${signalsFirstEnabled ? classes.marginedBannerSignalsFirst : ""}`);
		updateHeader({title: "activity", primary: true});
	}

	componentDidUpdate(prevProps, prevState) {
		// This allows the tabs to act properly when using the drawer nav
		if(prevProps.location.search !== this.props.location.search) {
			this.setupCategory();
		}
	}

	async loadLibraryTopics() {
		let data = await this.topicFido.fetch("/activities/library-topics")
			.catch(error => {
				//this.setState({topicFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({topics: data});
		}
	}

	categoryHandler(event, cat) {
		this.props.history.replace(Fido.buildPath("/activities", {category: cat}));
		this.setState({
			category: cat
		}, () => {
			window.scrollTo({top: 0});
		});
	}

	getCategoryFromUrl() {
		let {search} = this.props.location;
		return {
			category: Fido.getSearchParam("category", search)
		};
	}

	setupCategory() {
		let filters = this.getCategoryFromUrl();
		this.setState({
			category: filters.category || this.defaultCategory
		});
	}

	componentWillUnmount() {
		this.props.resetHeader();
	}

	filter(filters) {
		this.props.history.push(
			Fido.buildPath(
				"/activities",
				Object.assign({}, {category: this.state.category}, filters)
			)
		);
	}

	render() {
		let {category, topics} = this.state,
			{classes, showSnackbar, history, location, previousLocation, authenticatedUser, signalsFirstEnabled} = this.props,
			hasMultipleSources = Constants.SOURCES.getAuthorized(authenticatedUser).length > 0,
			hasMultipleAccounts = authenticatedUser.childAccounts && authenticatedUser.childAccounts.length;

		return (
			<Media query={{maxWidth: 400}}>
				{matches =>
					<React.Fragment>
						<Tabs
							centered={matches ? false : true}
							indicatorColor="primary"
							scrollButtons="auto"
							variant={matches ? "scrollable" : null}
							value={category}
							className={`${classes.tabs} ${signalsFirstEnabled ? classes.signalsFirst : ""}`}
							onChange={this.categoryHandler}
							classes={{
								// mui-fixed so doesn't jump when menu's open https://github.com/mui-org/material-ui/issues/10000
								root: "mui-fixed"
							}}>
							<Tab label="Alerts" value="alerts" className={classes.tab} />
							{authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.DISCUSSIONS) ?
								<Tab label="Discussions" value="discussions" className={classes.tab} /> : null
							}
							<Tab label="Saved" value="saved" className={classes.tab} />
							<Tab label="Removed" value="removed" className={classes.tab} />
						</Tabs>
						<PaddedLayout
							containerClassName={signalsFirstEnabled ? classes.containerSignalsFirst : classes.layoutContainer}
							noTopPad={hasMultipleSources || hasMultipleAccounts}>
							<Filterer
								topics={topics}
								location={location}
								executeFilterHandler={this.filter}
								defaultSearchParams={{category: category}} />
							<PositionMemory
								history={history}
								location={location}
								previousLocation={previousLocation}
								offset={60}
								localStorageKey="/activities" // this is not required, only using because / & /activities are the same paths
								defaultSearchParam={`category=${category}`} // this is not required, includes this in local storage key
								render={props => {
									return (
										<ActivitiesWrapper
											category={category}
											showSnackbar={showSnackbar}
											onTrackPage={props.trackPage}
											onTrackItem={props.trackItem}
											setNumberOfPages={props.setNumberOfPages}
											restorePosition={props.restorePosition}>
										</ActivitiesWrapper>
									);
								}} />
						</PaddedLayout>
					</React.Fragment>
				}
			</Media>
		);
	}
}

Activities.propTypes = {
	classes: PropTypes.object.isRequired,
	updateHeader: PropTypes.func.isRequired,
	resetHeader: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
	setBannerClassName: PropTypes.func,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	previousLocation: PropTypes.object,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	signalsFirstEnabled: PropTypes.bool
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <Activities {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "Activities";

export default withStyles(styles)(React.forwardRef(dNameFunc));
