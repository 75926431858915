import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fido from '../utils/Fido';
import Constants from "../utils/Constants";
import IndividualActivity from "../containers/IndividualActivity";
import GroupedActivity from "../containers/GroupedActivity";

class Activity extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			data: null,
			fidoError: false
		};

		this.fido = new Fido();
		this.handleFidoError = this.handleFidoError.bind(this);
	}

	componentDidMount() {
		let data = this.props.location.state && this.props.location.state.response;
		this.props.updateHeader({title: "details"});

		// if redirected, already have data
		if(data && 'activity' in data)	{
			this.setState({data: data});
		} else {
			this.fido.fetch(this.props.location.pathname)
				.then((data) => {
					if (data) {
						if (data.user.hasSignals && data.user.useSignals) {
							this.props.redirectSignals(this.props.location);
						} else {
							this.setState({data: data});
						}
					}
				})
				.catch(this.handleFidoError);
		}
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.props.resetHeader();
		// in case permanent snackbar showing
		this.props.showSnackbar(false);
	}

	handleFidoError(error) {
		this.setState({fidoError: true});
		if(error.message === "404 Not Found") {
			this.props.showSnackbar({
				message: "Not found. Outdated?",
				duration: false
			});
		} else {
			this.props.showSnackbar(error.message);
		}
	}

	render() {
		let {data} = this.state;
		return data && 'activity' in data && Constants.ACTIVITY.GROUPED_IDS.includes(data.activity.source_id) ?
			<GroupedActivity {...this.props} data={data} /> : <IndividualActivity {...this.props} data={data} />;
	}
}

Activity.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object.isRequired,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	redirectSignals: PropTypes.func
};

export default Activity;
