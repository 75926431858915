import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PaperActivity from './PaperActivity.js';
import AssociationsPanel from './AssociationsPanel.js';
import UserActivityTrail from "./UserActivityTrail.js";
import RestoreBanner from './RestoreBanner';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

import { withStyles, Grid, RootRef, withWidth } from '@material-ui/core';

const styles = theme => ({
	responsiveGridContainer: {
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(1),
		}
	},

	responsiveGridSection: {
		[theme.breakpoints.up('sm')]: {
			margin: theme.spacing(2),
		},

		[theme.breakpoints.down('xs')]: {
			borderRadius: 0
		}
	},

	userActivityTrail: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: 0,
			marginTop: theme.spacing(2)
		}
	},

	associations: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2)
		}
	}
});

class SingleActivity extends Component {
	constructor(props, context) {
		super(props, context);

		this.userActivityRef = React.createRef();
		this.scrollTo = this.scrollTo.bind(this);
	}

	scrollTo() {
		let {historyState} = this.props;
		if (historyState && Object.keys(historyState).length && historyState.scrollTo) {
			let scrollToEl = this[historyState.scrollTo].current;
			if(scrollToEl && scrollToEl.offsetTop) {
				// 40 is heaight of section headers, might want to re-think if scrolling to something that doesn't have a header
				window.scrollTo({top: scrollToEl.offsetTop-40, behavior: 'smooth'});
				delete historyState.scrollTo;
			}
		}
	}

	render() {

		let {classes, width, auditTrail, isActivityDeleted, handleRestoreAndSave, history, ...rest} = this.props,
			showAssociations = (rest.associations && rest.associations.length) || rest.content == null;

		if (rest.content && (width.includes("xs") || width.includes("sm"))) {
			this.scrollTo();
		}

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
						<div>
							<RestoreBanner
								flagged={rest.flagged}
								show={isActivityDeleted}
								handleRestore={handleRestoreAndSave} />
							<div className={classes.responsiveGridContainer}>
								<Grid container>
									<Grid item md={showAssociations ? 8 : 12} xs={12}>
										<PaperActivity
											{...rest}
											history={history}
											numberOfViewableComments={3}
											isActivityDeleted={isActivityDeleted}
											className={classes.responsiveGridSection} />
										{authenticatedUser ?
											<RootRef rootRef={this.userActivityRef}>
												<UserActivityTrail
													userActivity={auditTrail}
													className={`${classes.responsiveGridSection} ${classes.userActivityTrail}`} />
											</RootRef> : null
										}
									</Grid>
									{showAssociations ?
										<Grid item md={4} xs={12}>
											<AssociationsPanel className={`${classes.responsiveGridSection} ${classes.associations}`} {...rest} />
										</Grid>
										: null
									}
								</Grid>
							</div>
						</div>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

SingleActivity.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	showSnackbar: PropTypes.func,
	fidoError: PropTypes.bool,
	historyState: PropTypes.object,
	addCommentHandler: PropTypes.func,
	handleTranslateClick: PropTypes.func,
	actions: PropTypes.array,
	auditTrail: PropTypes.array,
	isActivityDeleted: PropTypes.bool,
	handleRestoreAndSave: PropTypes.func,
	rateHandler: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withStyles(styles)(withWidth()(SingleActivity));
