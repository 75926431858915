import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';
import Media from 'react-media';

import PasswordRequirements from "./PasswordRequirements";

import { withStyles, InputAdornment, Button, withTheme } from '@material-ui/core';

const styles = theme => ({
	passwordField: {
		width: "100%",

		// from MUI, makes it so field doesn't resize / jump when showing / hiding password
		"& input": {
			height: "1.1875em"
		}
	},

	minHeight: {
		minHeight: theme.spacing(9)
	},

	showHideBtn: {
		fontSize: "0.8rem",
		color: theme.palette.text.secondary
	}
});

class PasswordField extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			password: "",
			showPassword: null
		};

		this.handleChange = this.handleChange.bind(this);
		this.makeInvalid = this.makeInvalid.bind(this);
		this.areRequirementsMet = this.areRequirementsMet.bind(this);
		this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
		this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
	}

	componentDidMount() {
		this.props.onRef(this);
	}
	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	areRequirementsMet(valid) {
		if (this.props.isValid) {
			this.props.isValid(valid);
		}
		this.passwordField[`make${valid ? "Valid" : "Invalid"}`]();
	}

	makeInvalid() {
		this.passwordField.makeInvalid();
	}

	handleChange(event) {
		this.setState({[event.target.name]: event.target.value});
		this.props.onChangeHandler(event);
	}

	handleMouseDownPassword(event) {
		event.preventDefault();
	}

	handleClickShowPassword(isTabletDown) {
		let showPassword = isTabletDown;

		if (this.state.showPassword === null) {
			showPassword = !showPassword;
		} else {
			showPassword = !this.state.showPassword;
		}

		this.setState({showPassword: showPassword});
	}

	render() {
		let {showPassword, password} = this.state,
			{classes, theme, id, label, value, showRequirements,
				autoFocus, helperText, FormHelperTextProps, validators, inputProps} = this.props;

		return (
			<div>
				<Media query={{maxWidth: theme.breakpoints.values.sm}}>
					{matches => (
						<TextValidator
							id={id}
							name= "password"
							label={label}
							margin="normal"
							ref={ref => (this.passwordField = ref)}
							className={`${classes.passwordField} ${showRequirements ? classes.minHeight : null}`}
							type={showPassword === null ? (matches ? 'text' : 'password') : (showPassword ? 'text' : 'password')}
							value={value}
							onChange={this.handleChange}
							errorMessages="invalid password"
							autoFocus={autoFocus}
							InputProps={{
								inputProps: Object.assign({
									autoCapitalize: "off",
									autoComplete: "current-password"
								}, inputProps),
								endAdornment: <InputAdornment position="end">
									<Button
										size="small"
										variant="text"
										aria-label={`${showPassword ? "Hide" : "Show"} password`}
										className={classes.showHideBtn}
										onClick={() => this.handleClickShowPassword(matches)}
										onMouseDown={this.handleMouseDownPassword}>
										{showPassword === null ? (matches ? 'Hide' : 'Show') : (showPassword ? 'Hide' : 'Show')}
									</Button>
								</InputAdornment>
							}}
							helperText={helperText}
							FormHelperTextProps={FormHelperTextProps}
							validators={validators} />
					)}
				</Media>
				{showRequirements ? (
					<PasswordRequirements
						id="password-requirements"
						password={password}
						isValid={this.areRequirementsMet} />
				) : null}
			</div>
		);
	}
}

PasswordField.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	id: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	showRequirements: PropTypes.bool,
	//onChangeHandler: PropTypes.func,
	isValid: PropTypes.func,
	onRef: PropTypes.func,
	autoFocus: PropTypes.bool,
	helperText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	FormHelperTextProps: PropTypes.object,
	validators: PropTypes.array,
	inputProps: PropTypes.object
};

export default withStyles(styles)(withTheme(PasswordField));
