import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Heading from "../components/Heading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ReactPlaceholder from 'react-placeholder';
import { RectShape, TextBlock, TextRow, RoundShape } from 'react-placeholder/lib/placeholders';
import { isValidNumber } from 'libphonenumber-js';
import AvatarEditor from 'react-avatar-editor';
import EXIF from 'exif-js';

import Fido from '../utils/Fido';
import Constants from "../utils/Constants";
import PhoneValidator from './PhoneValidator';
import StandardFormLayout from './StandardFormLayout';
import PaddedLayout from "../containers/PaddedLayout";
import Menu from './Menu';
import Select from "./Select";
import LabeledSwitch from "./LabeledSwitch";
import DialogSlideTransition from "./DialogSlideTransition";
import Avatar from './Avatar.js';
import AvatarCamera from "./AvatarCamera";
import LabeledRadioGroup from "./LabeledRadioGroup";
import LabeledCheckbox from "./LabeledCheckbox";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

import {
    Paper,
    Button,
    Grid,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles,
    withWidth,
    Drawer,
    MenuList,
    ListItemText,
    ListItemIcon,
    withTheme,
} from '@material-ui/core';

const styles = theme => ({
	paper: {
		padding: theme.spacing(2),
	},

	hidden: {
		display: "none"
	},

	// so they are 2 rows like middle button on md layouts
	largeBtn: {
		[theme.breakpoints.up(800)]: {
			paddingLeft: 55,
			paddingRight: 55
		},

		[theme.breakpoints.up(1135)]: {
			paddingLeft: 24,
			paddingRight: 24
		}
	},

	dialogPaper: {
		minWidth: 265,
		maxWidth: 450
	},

	avatarMenuItem: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4)
	},

	avatarMenuText: {
		paddingLeft: 0
	},

	avatarBtnContainer: {
		marginTop: 6,
		textAlign: "center"
	},

	avatarContainer: {
		paddingTop: theme.spacing(2)
	},

	// on desktop chrome was showing weird 5px yellow lines
	menuList: {
		"&:focus": {
			outline: "none"
		}
	},

	secondary: {
		color: theme.palette.text.secondary
	},

	divider: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1.25),
		marginLeft: theme.spacing(-2),
		marginRight: theme.spacing(-2)
	},

	settingsLink: {
		fontSize: "0.985rem",
		textTransform: "none"
	},

	settingsButton: {
		color: theme.palette.primary.main,
		padding: theme.spacing(.5),
		marginTop: theme.spacing(-0.5),
		minHeight: 0
	},

	roleSpacing: {
		marginBottom: theme.spacing(2)
	},

	titleSelect: {
		textAlign: "left",
		minWidth: theme.spacing(22)
	},

	titleSubHeader: {
		opacity: 1,
		backgroundColor: theme.palette.primary.tan,
		"&:focus": {
			backgroundColor: theme.palette.primary.tan
		}
	},

	titleItem: {
		paddingLeft: theme.spacing(4)
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const itemPlaceHolder = (
	<div>
		<Paper style={{padding: 10, marginTop: 10}}>
			<div style={{display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 16}}>
				<RoundShape color={placeholderColor} style={{width: Constants.USER.AVATAR.SIZE.WIDTH, height: Constants.USER.AVATAR.SIZE.HEIGHT}} />
			</div>
			<div style={{display: "flex", marginTop: 25, flexDirection: "column"}}>
				<div style={{flex: 1}}>
					<TextBlock rows={2} color={placeholderColor} style={{width: "95%"}} />
				</div>
				<div style={{flex: 1, marginTop: 10}}>
					<TextBlock rows={2} color={placeholderColor} style={{width: "100%"}} />
				</div>
			</div>
		</Paper>
		<Paper style={{padding: 10, marginTop: 10}}>
			<TextRow color={placeholderColor} style={{marginTop: 10}}><span></span></TextRow>
			<RectShape style={{height: "20rem", marginTop: 10, marginBottom: 10}} color={placeholderColor}><span></span></RectShape>
		</Paper>
	</div>
);

class UserProfileForm extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			deleteDlgOpen: false,
			usernameReady: true,
			emailReady: true,
			formSubmit: false,
			checkingEmail: false,
			checkingUsername: false,
			avatarImage: null,
			showAvatarCamera: false,
			editingAvatar: false,
			avatarMenuAnchorEl: null,
			invalidImageType: false
		};

		this.fido = new Fido();

		this.avatarVideo = null;
		this.avatarCanvas = null;
		this.avatarFileTypes = ["image/png", "image/jpeg", "image/gif"];
		this.hasCameraSupport = navigator.mediaDevices && navigator.mediaDevices.getUserMedia;

		this.handleDeleteDialog = this.handleDeleteDialog.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitClick = this.handleSubmitClick.bind(this);
		this.handleError = this.handleError.bind(this);
		this.removeAvatarPhoto = this.removeAvatarPhoto.bind(this);
		this.handleUpdateAvatarClick = this.handleUpdateAvatarClick.bind(this);
		this.doneEditingAvatar = this.doneEditingAvatar.bind(this);
		this.choosePhoto = this.choosePhoto.bind(this);
		this.photoSelected = this.photoSelected.bind(this);
		this.takePhoto = this.takePhoto.bind(this);
		this.tookPhoto = this.tookPhoto.bind(this);
		this.closeUpdateAvatarMenu = this.closeUpdateAvatarMenu.bind(this);
		this.closeInvalidImage = this.closeInvalidImage.bind(this);

		this.avatarFileForm = React.createRef();
		this.avatarFileField = React.createRef();
	}

	componentDidMount() {
		this.setupValidators();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.formValues.avatar !== prevProps.formValues.avatar) {
			this.setState({avatarImage: this.props.formValues.avatar});
		}
	}

	setupValidators() {
		ValidatorForm.addValidationRule('uniqueUsername', (value) => {
			if((this.state.formSubmit === true && this.state.usernameReady === true) ||
				(typeof this.props.formValues.existingUsername !== 'undefined' && value === this.props.formValues.existingUsername)) {
				this.setState({usernameReady: true});
				return true;
			} else {
				this.setState({checkingUsername: true});
				this.checkUnique("username", value).then(response => {
					if(response.valid === true) {
						this.username.makeValid();
						this.setState({usernameReady: true, checkingUsername: false});
					} else {
						this.username.makeInvalid();
						this.setState({usernameReady: false, checkingUsername: false});
					}
				}).catch(error => {
					console.warn(error);
				});
			}

			return false;
		});

		ValidatorForm.addValidationRule('uniqueEmail', (value) => {
			if((this.state.formSubmit === true && this.state.emailReady === true) ||
				(typeof this.props.formValues.existingEmail !== 'undefined' && value === this.props.formValues.existingEmail)) {
				this.setState({emailReady: true});
				return true;
			} else {
				this.setState({checkingEmail: true});
				this.checkUnique("email", value).then(response => {
					if(response.valid === true) {
						this.email.makeValid();
						this.setState({emailReady: true, checkingEmail: false});
					} else {
						this.email.makeInvalid();
						this.setState({emailReady: false, checkingEmail: false});
					}
				}).catch(error => {
					console.warn(error);
				});
			}

			return false;
		});

		ValidatorForm.addValidationRule('requiredMulti', (value) => {
			if(typeof value !== 'undefined' && value.length > 0) {
				return true;
			}
			return false;
		});

		ValidatorForm.addValidationRule('isValidNumber', (value) => {
			let valid = true;
			if (value && value.length > 0) {
				valid = isValidNumber(value.replace(/\D/g, ''), "US");
			}
			return valid;
		});
	}

	componentWillUnmount() {
		this.fido.dropIt();
	}

	handleDeleteDialog() {
		this.setState({deleteDlgOpen: !this.state.deleteDlgOpen});
	}

	deleteUser(userId) {
		this.handleDeleteDialog();
		this.props.deleteUserHandler(userId);
	}

	checkUnique(field, value) {
		return this.fido.fetch("/users/checkunique/", {query: {field: field, value: value}});
	}

	handleSubmitClick() {
		this.setState({formSubmit: true});
	}

	handleError(error) {
		console.log(error);
		this.setState({formSubmit: false});
	}

	removeAvatarPhoto() {
		this.closeUpdateAvatarMenu();
		this.setState({avatarImage: null});
	}

	choosePhoto() {
		this.closeUpdateAvatarMenu();

		if (this.avatarFileField.current) {
			this.avatarFileField.current.click();
		}
	}

	photoSelected() {
		let form, file;
		if (this.avatarFileField.current) {
			form = this.avatarFileForm.current;
			file = this.avatarFileField.current.files[0];

			if (form && file) {
				form.reset();
			}

			if (file && this.avatarFileTypes.includes(file.type)) {
				// TODO: once we have editability, not sure we need to resize as editing will essentially do that
				// or at least we'll need to resize client-side based on information sent back from editor
				//this.setState({avatarImage: window.URL.createObjectURL(image)});
				let reader = new FileReader();
				reader.onload = (readerEvent) => {
					let img = new Image();
					// using promise so this is still scoped to component where it needs to be
					img.onload = (imgEvent) => new Promise(resolve => {
						// read exif data to determine orientation (phone seems to rotate it)
						EXIF.getData(img, function() {
							resolve(this.exifdata.Orientation);
						});
					}).then(orientation => {
						this.setState({avatarImage: this.resizeAndOrientPhoto(img, file.type, orientation)});
					});
					img.src = readerEvent.target.result;
				};

				reader.readAsDataURL(file);
			} else {
				this.setState({invalidImageType: true});
			}
		}
	}

	closeInvalidImage() {
		this.setState({invalidImageType: false});
	}

	/*

		1        2       3      4         5            6           7          8

		888888  888888      88  88      8888888888  88                  88  8888888888
		88          88      88  88      88  88      88  88          88  88      88  88
		8888      8888    8888  8888    88          8888888888  8888888888          88
		88          88      88  88
		88          88  888888  888888

	*/
	resizeAndOrientPhoto(image, imageType, orientation) {
		let maxHeight = Constants.USER.AVATAR.SIZE.HEIGHT,
			ratio = image.width / image.height,
			resultingWidth = ratio * maxHeight,
			canvas = document.createElement("canvas"),
			ctx = canvas.getContext('2d');

		canvas.width = resultingWidth;
		canvas.height = maxHeight;

		// transform context before drawing image
		if (orientation !== 1) {
			const rotate = (degrees) => {
				canvas.width = maxHeight;
				canvas.height = resultingWidth;

				ctx.translate(canvas.width / 2, canvas.height / 2);
				ctx.rotate(degrees * Math.PI / 180);
				ctx.translate(-canvas.height / 2, -canvas.width / 2);
			};

			switch (orientation) {
				case 3:
				case 4:
					rotate(180);
					break;
				case 5:
				case 6:
					rotate(90);
					break;
				case 7:
				case 8:
					rotate(-90);
					break;
				default: break;
			}
		}

		ctx.drawImage(image, 0, 0, resultingWidth, maxHeight);
		return canvas.toDataURL(imageType);
	}

	takePhoto() {
		this.closeUpdateAvatarMenu();
		this.setState({showAvatarCamera: true});
	}

	tookPhoto(img) {
		this.setState({
			showAvatarCamera: false,
			avatarImage: img.src/*,
			editingAvatar: true*/
		});
	}

	doneEditingAvatar() {
		console.log("DONE EDITING AVATAR");
	}

	handleUpdateAvatarClick(event, justChoose) {
		if (!justChoose) {
			this.setState({avatarMenuAnchorEl: event.target});
		} else {
			this.choosePhoto();
		}
	}

	closeUpdateAvatarMenu(event) {
		this.setState({avatarMenuAnchorEl: null});
	}

	// Convert the submitted form even to an object of values instead
	handleSubmit = (event) => {
		if(this.state.usernameReady && this.state.emailReady) {
			let form = event.target;
			let values = {
				id: this.props.formValues.id
			};

			Object.keys(form.elements).map(e => {
				// Don't send if element not named
				if(form.elements[e].nodeName && form.elements[e].name) {
					if (form.elements[e].type === 'radio') {
						// If it's a radio type input, only use the checked value
						if (form.elements[e].checked === true) {
							values[form.elements[e].name] = form.elements[e].value;
						}
					} else if (form.elements[e].type === 'checkbox') {
						values[form.elements[e].name] = form.elements[e].checked;
					} else {
						values[form.elements[e].name] = form.elements[e].value;
					}
				}

				return e;
			});

			// upload if there's an avatarImage and it's not the same as the original
			if (this.state.avatarImage != null && this.props.formValues.avatar !== this.state.avatarImage) {
				this.props.uploadAvatar(this.state.avatarImage);
				values.uploadingAvatar = true;

				if (this.props.formValues.avatar) {
					values.removeAvatar = true;
				}
			}


			// remove avatar if user chose to
			if (this.state.avatarImage == null && this.props.formValues.avatar) {
				values.removeAvatar = true;
			}

			this.props.handleSubmit(values);
		} else {
			this.setState({
				formSubmit: false
			}, () => {
				this.username.validate(this.props.formValues.username);
				this.email.validate(this.props.formValues.email);
			});
		}
	}

	render() {
		let avatarMenuList,
			{avatarImage, editingAvatar, showAvatarCamera} = this.state,

			{myUser, classes, handleResetClick, handleChange, timezones, formValues,
				accountLocations, emailOnFile, userTypes, jobTitles, isLoading, dataLoading, width, authenticatedUser} = this.props,

			{id, activated, gsuite_data, exchange_data, tips_data, social_media_data, application_reports,
				email_reports, manage_user, first_name, last_name, title, email, cell_phone,
				locations, username, timezone, user_type_str, existingActivated} = formValues,

			isMyUser = myUser.id === id, // true if you are editing yourself
			editUrlBase = myUser.profileUrlBase,
			userDefined = (id) ? true : false, // bool if user id exists
			isTeamMember = myUser.user_type_str === Constants.USER.ROLES.TEAM_MEMBER.NAME,
			isUserManager = myUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.USER),
			roleIsTeamMember = user_type_str === Constants.USER.ROLES.TEAM_MEMBER.NAME,
			arePermissionsDisabled = !isUserManager || isMyUser || !roleIsTeamMember,
			isActive = typeof existingActivated !== 'undefined' && existingActivated !== false,
			buttonGridWidth = (isMyUser || !isActive) ? 6 : ((!userDefined) ? 12 : 4), // can't delete yourself so don't show button
			buttonJustify = (!isMyUser && userDefined) || (userDefined && isActive) ? "space-between" : "center",
			fullName = [formValues.first_name, formValues.last_name].join(" "),
			canManageUsers = authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.USER),

			settingsLink = (
				<span>
					<span>Automated Reports are configurable in</span>
					<Link to={`${editUrlBase}/settings`} className={classes.settingsButton}>
						<span className={classes.settingsLink}>Settings</span>
					</Link>
				</span>
			);

			// TODO: Future CSM functionality
			// isInternalUser = myUser.internalUser,
			// buttonGridWidth = ((isMyUser && !isInternalUser) || !isActive) ? 6 : ((!userDefined) ? 12 : 4),
			// buttonJustify = ((!isMyUser || isInternalUser) && userDefined) || (userDefined && isActive) ? "space-between" : "center",

		avatarMenuList = (
			<MenuList className={classes.menuList}>
				{this.hasCameraSupport && !Constants.ENV.IS_MOBILE ? <MenuItem button className={classes.avatarMenuItem} onClick={this.takePhoto}>
					<ListItemIcon><FontAwesomeIcon icon={["fal", "camera"]} fixedWidth title="camera" /></ListItemIcon>
					<ListItemText primary="Take A Photo" className={classes.avatarMenuText} primaryTypographyProps={{className: classes.secondary}} />
				</MenuItem> : null}

				<MenuItem button className={classes.avatarMenuItem} onClick={this.choosePhoto}>
					<ListItemIcon><FontAwesomeIcon icon={["fal", "images"]} fixedWidth title="photo" /></ListItemIcon>
					<ListItemText
						primary={Constants.ENV.IS_MOBILE ? (avatarImage ? "Replace Photo" : "Add A Photo") : "Choose A Photo"}
						className={classes.avatarMenuText}
						primaryTypographyProps={{className: classes.secondary}} />
				</MenuItem>

				{avatarImage ? <MenuItem button className={classes.avatarMenuItem} onClick={this.removeAvatarPhoto}>
					<ListItemIcon><FontAwesomeIcon icon={["fal", "trash-alt"]} fixedWidth title="trash" /></ListItemIcon>
					<ListItemText primary="Remove Photo" className={classes.avatarMenuText} primaryTypographyProps={{className: classes.secondary}} />
				</MenuItem>	: null}
			</MenuList>
		);

		return (
			<div>
				<form ref={this.avatarFileForm}>
					<input
						id="avatarFileField"
						type="file"
						multiple={false}
						style={{display: "none"}}
						ref={this.avatarFileField}
						onChange={this.photoSelected}
						accept={this.avatarFileTypes.join(",")} />
				</form>
				<PaddedLayout>
					{(!isLoading) ? (
						<Paper className={classes.paper}>
							<ValidatorForm
								onSubmit={this.handleSubmit}
								onError={this.handleError}
								debounceTime={500} >
								<StandardFormLayout>
									<Grid container
										justify="center"
										alignItems="center"
										direction="column"
										id="avatar"
										className={classes.avatarContainer}>
										{editingAvatar ?
											<span>
												<Grid item>
													<AvatarEditor
														width={150}
														height={150}
														borderRadius={500}
														image={avatarImage} />
												</Grid>
												<Grid item className={classes.avatarBtnContainer}>
													<Button color="primary" variant="outlined" onClick={this.doneEditingAvatar}>
														Done Editing
													</Button>
												</Grid>
											</span>
											:
											<span>
												<Grid item>
													<Avatar
														sizeFactor={3}
														authorName={fullName}
														authorImage={avatarImage}
														useIconFallback={!userDefined} />
												</Grid>
												{isMyUser ?
													<Grid item className={classes.avatarBtnContainer}>
														<Button
															color="primary"
															onClick={(e) => this.handleUpdateAvatarClick(e, (Constants.ENV.IS_MOBILE && !avatarImage))}>
															{avatarImage ? "Update" : "Add"} Photo
														</Button>
													</Grid>	: null
												}
											</span>
										}
									</Grid>
									{
										// TODO: Future CSM functionality
										// className={(isMyUser && !isInternalUser) || isTeamMember ? classes.hidden : ""}
									}
									<LabeledSwitch
										spread
										reverse
										id="activated"
										name="activated"
										label="Activation Status"
										className={isMyUser || isTeamMember ? classes.hidden : ""}
										checked={activated}
										onChange={handleChange} />
									<TextValidator
										disabled={userDefined}
										ref={r => (this.username = r)}
										fullWidth
										id="username"
										label="Username"
										name="username"
										helperText="User's username for login"
										margin="normal"
										value={username || ''}
										onChange={handleChange}
										validators={['required', 'uniqueUsername']}
										autoComplete="off"
										errorMessages={[
											Constants.ERROR_MESSAGES['required'],
											this.state.checkingUsername ? "checking..." : Constants.ERROR_MESSAGES['unique']
										]} />
									<TextValidator
										fullWidth
										id="firstName"
										label="First Name"
										name="first_name"
										helperText="User's first name"
										value={first_name || ''}
										margin="normal"
										validators={['required']}
										errorMessages={[Constants.ERROR_MESSAGES['required']]}
										onChange={handleChange}	/>
									<TextValidator
										fullWidth
										id="lastName"
										label="Last Name"
										name="last_name"
										helperText="User's last name"
										value={last_name || ''}
										margin="normal"
										validators={['required']}
										errorMessages={[Constants.ERROR_MESSAGES['required']]}
										onChange={handleChange} />
									{dataLoading || Object.keys(jobTitles).length ? (
										<Select
											fullWidth
											id="title"
											name="title"
											value={title || ''}
											isLoading={dataLoading}
											label="Job Title"
											helperText="User's job title"
											className={classes.titleSelect}
											onChange={handleChange}
											validators={['required']}
											errorMessages={[Constants.ERROR_MESSAGES['required']]}>
											{Object.keys(jobTitles).map((key, index) => {
												let items = [
													<MenuItem
														disabled
														key={index}
														className={classes.titleSubHeader}>{key}</MenuItem>
												];
												jobTitles[key].map((jobTitle, idx) => {
													return items.push(
														<MenuItem
															key={`key-${idx}`}
															value={jobTitle}
															className={classes.titleItem}>{jobTitle}</MenuItem>
													);
												});
												return items;
											})}
										</Select>
									) : (
										<TextValidator
											fullWidth
											id="title"
											label="Job Title (Optional)"
											name="title"
											helperText="User's job title"
											value={title || ''}
											margin="normal"
											onChange={handleChange} />
									)}
									<TextValidator
										fullWidth
										ref={r => (this.email = r)}
										id="email"
										label="Email"
										name="email"
										helperText="User's email address"
										value={email || ''}
										margin="normal"
										validators={['required', 'isEmail', 'uniqueEmail']}
										onChange={handleChange}
										errorMessages={[
											Constants.ERROR_MESSAGES['required'],
											Constants.ERROR_MESSAGES['isEmail'],
											this.state.checkingEmail ? "checking..." : Constants.ERROR_MESSAGES['unique']
										]} />
									<PhoneValidator
										fullWidth
										id="cellPhone"
										label="Mobile Phone"
										name="cell_phone"
										helperText="User's mobile phone number"
										value={cell_phone || ''}
										validators={[Constants.ERROR_VALIDATORS['isPhoneWithMask'], 'isValidNumber']}
										errorMessages={[Constants.ERROR_MESSAGES['isPhone'], Constants.ERROR_MESSAGES['isPhone']]}
										margin="normal"
										onChange={handleChange} />
									<Select
										fullWidth
										isLoading={dataLoading}
										id="timezone"
										name="timezone"
										label="Timezone"
										helperText="User's timezone"
										margin="normal"
										value={timezone}
										onChange={handleChange}
										validators={['required']}
										errorMessages={[Constants.ERROR_MESSAGES['required']]}>
										{timezones.map(timezone =>
											<MenuItem key={timezone} value={timezone}>{timezone}</MenuItem>
										)}
									</Select>
									<Heading id="topDivider" title="&nbsp;" className={classes.divider} />
									{
										// TODO: Future CSM functionality
										// disabled={(isTeamMember || isMyUser) && !isInternalUser}
										// bottomHelperText={(isTeamMember && !isInternalUser) ? null : "Administrators have access to all permissions. Team Member permissions are configurable."}
									}
									<LabeledRadioGroup
										disabled={(isTeamMember && !isUserManager) || isMyUser}
										isLoading={dataLoading}
										row={true}
										id="user_type_str"
										name="user_type_str"
										aria-label="User role"
										bottomHelperText={isTeamMember ? null :
											"Administrators have access to all permissions. Team Member permissions are configurable."
										}
										value={user_type_str}
										buttonColor="primary"
										buttons={userTypes}
										onChange={handleChange} />
									<div className={classes.roleSpacing}></div>
									{
										// TODO: Future CSM functionality
										// disabled={((isTeamMember && !isUserManager) && !isInternalUser) || user_type_str !== Constants.USER.ROLES.TEAM_MEMBER.NAME}
									}
									{myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SOCIAL_MEDIA) ?
										<LabeledCheckbox
											disabled={arePermissionsDisabled}
											topHelperText="Additional Permissions"
											id="social_media_data"
											name="social_media_data"
											checked={!roleIsTeamMember || social_media_data}
											label="Social Media Activity"
											checkboxColor="primary"
											onChange={handleChange}
											bottomHelperText="Give user access to social media activity" /> : null
									}
									{myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GMAIL) || myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GOOGLE_HANGOUTS) || myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GOOGLE_DRIVE) ?
										<LabeledCheckbox
											disabled={arePermissionsDisabled}
											id="gsuite_data"
											name="gsuite_data"
											checked={!roleIsTeamMember || gsuite_data}
											label="G Suite Activity"
											checkboxColor="primary"
											onChange={handleChange}
											bottomHelperText="Give user access to G Suite activity" /> : null
									}
									{myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.EXCHANGE) ?
										<LabeledCheckbox
											disabled={arePermissionsDisabled}
											id="exchange_data"
											name="exchange_data"
											checked={!roleIsTeamMember || exchange_data}
											label="Outlook Activity"
											checkboxColor="primary"
											onChange={handleChange}
											bottomHelperText="Give user access to outlook activity" /> : null
									}
									{myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.TIPS) ?
										<LabeledCheckbox
											disabled={arePermissionsDisabled}
											id="tips_data"
											name="tips_data"
											checked={!roleIsTeamMember || tips_data}
											label="Shareit Activity"
											checkboxColor="primary"
											onChange={handleChange}
											bottomHelperText="Give user access to share it activity" /> : null
									}
									{myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) ?
										<LabeledCheckbox
											disabled={arePermissionsDisabled}
											id="application_reports"
											name="application_reports"
											checked={!roleIsTeamMember || application_reports}
											label="Application Reports"
											checkboxColor="primary"
											onChange={handleChange}
											bottomHelperText="Give user access to application reports" /> : null
									}
									{myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) ?
										<LabeledCheckbox
											disabled={arePermissionsDisabled}
											id="email_reports"
											name="email_reports"
											checked={!roleIsTeamMember || email_reports}
											label="Automated Reports"
											checkboxColor="primary"
											onChange={handleChange}
											bottomHelperText={
												(isMyUser && (isTeamMember && email_reports || !isTeamMember)) ?
													settingsLink : "Give user access to automated reports"} /> : null
									}
									{myUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.ACCOUNT_CONFIG) ?
										<LabeledCheckbox
											disabled={arePermissionsDisabled}
											id="manage_user"
											name="manage_user"
											checked={!roleIsTeamMember || manage_user}
											label="User Manager"
											checkboxColor="primary"
											onChange={handleChange}
											bottomHelperText={
												(isMyUser && (isTeamMember && manage_user || !isTeamMember)) ?
													settingsLink : "Give user access to manage Team Members"} /> : null
									}
									<Select
										fullWidth
										isLoading={dataLoading}
										id="locations"
										name="locations"
										label="Locations"
										helperText="Select the locations this user belongs to"
										margin="normal"
										value={locations.length ? locations : accountLocations.map(loc => loc.id)}
										disabled={!canManageUsers && isMyUser}
										// multiple select props
										multiple
										includeSelectAll
										labelAttr="name"
										valueAttr="id"
										allOptions={accountLocations}
										onChange={handleChange}
										validators={['requiredMulti']}
										errorMessages={[Constants.ERROR_MESSAGES['required']]} />
									{
										// TODO: Future CSM functionality
										// {((!isMyUser || isInternalUser) && userDefined) ? (
									}
									<Grid id="formButtons" container justify={buttonJustify} spacing={2}>
										{(!isMyUser && userDefined) ? (
											<Grid item sm={buttonGridWidth} xs={12}>
												<Button
													fullWidth
													size="large"
													onClick={this.handleDeleteDialog}
													variant="outlined"
													classes={{sizeLarge: classes.largeBtn}}>
													Delete User</Button>
											</Grid>
										): null}
										{(userDefined && isActive) ? (
											<Grid item sm={buttonGridWidth} xs={12}>
												<Button
													fullWidth
													size="large"
													onClick={handleResetClick}
													variant="outlined"
													classes={{sizeLarge: classes.largeBtn}}
													disabled={!emailOnFile}>
													Reset Password</Button>
											</Grid>
										): null}
										<Grid item sm={buttonGridWidth} xs={12}>
											<Button
												fullWidth
												size="large"
												type="submit"
												variant="contained"
												color="primary"
												onClick={this.handleSubmitClick}
												classes={{sizeLarge: classes.largeBtn}}>{(userDefined) ? "Save Profile" : "Add User"}</Button>
										</Grid>
									</Grid>
								</StandardFormLayout>
							</ValidatorForm>
						</Paper>
					) : (
						<div>
							<ReactPlaceholder
								ready={false}
								customPlaceholder={itemPlaceHolder}
								showLoadingAnimation={!this.state.fidoError}>
								<span></span>
							</ReactPlaceholder>
						</div>
					)}
				</PaddedLayout>
				<Dialog
					fullWidth
					maxWidth={false}
					classes={{paper: classes.dialogPaper}}
					open={this.state.deleteDlgOpen}
					TransitionComponent={DialogSlideTransition}
					onClose={this.handleDeleteDialog}>
					<DialogTitle>Delete User?</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete <b>{fullName}</b>?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleDeleteDialog} color="primary">Cancel</Button>
						<Button onClick={() => this.deleteUser(id)} color="secondary">Delete User</Button>
					</DialogActions>
				</Dialog>

				{width.includes("xs") ?
					<Drawer
						anchor="bottom"
						open={Boolean(this.state.avatarMenuAnchorEl)}
						onClose={this.closeUpdateAvatarMenu}>{avatarMenuList}</Drawer>
					: <Menu
						anchorEl={this.state.avatarMenuAnchorEl}
						open={Boolean(this.state.avatarMenuAnchorEl)}
						onClose={this.closeUpdateAvatarMenu}>{avatarMenuList}</Menu>
				}

				{this.hasCameraSupport ?
					<AvatarCamera
						open={showAvatarCamera}
						doneHandler={this.tookPhoto} /> : null
				}

				<Dialog
					fullWidth
					maxWidth="xs"
					open={this.state.invalidImageType}
					onClose={this.closeInvalidImage}
					TransitionComponent={DialogSlideTransition}>
					<DialogTitle>Invalid Image Type</DialogTitle>
					<DialogContent>
						<DialogContentText>
							You have selected an invalid image type. Please choose a <i>GIF</i>, <i>JPG</i> or <i>PNG</i>.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={this.closeInvalidImage}>OK</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

UserProfileForm.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	authenticatedUser: PropTypes.object,
	userTypes: PropTypes.array.isRequired,
	accountLocations: PropTypes.array.isRequired,
	jobTitles: PropTypes.object.isRequired,
	handleResetClick: PropTypes.func,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	uploadAvatar: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func,
	deleteUserHandler: PropTypes.func,
	emailOnFile: PropTypes.bool,
	formValues: PropTypes.object.isRequired,
	timezones: PropTypes.array.isRequired,
	myUser: PropTypes.object,
	isLoading: PropTypes.bool,
	dataLoading: PropTypes.bool
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <UserProfileForm {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "UserProfileForm";

export default withStyles(styles)(withWidth()(withTheme(React.forwardRef(dNameFunc))));
