import React, { Component } from 'react';
import { withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {RectShape, TextRow} from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SparkLine from "./SparkLine";
import Constants from "../../utils/Constants";
import NumberFormatter from '../../utils/NumberFormatter';

import { withStyles, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
	root: {
		marginRight: theme.spacing(2),
		background: theme.palette.background.default
	},

	rootBorder: {
		borderRight: `2px solid ${theme.palette.grey["A100"]}`,

		[theme.breakpoints.down('lg')]: {
			borderRight: 0,
			paddingBottom: theme.spacing(1),
			marginRight: theme.spacing(10),
			marginBottom: theme.spacing(2),
			borderBottom: `2px solid ${theme.palette.grey["A100"]}`
		}
	},

	container: {
		height: theme.spacing(12.5)
	},

	chevron: {
		fontSize: "1rem",
		marginLeft: theme.spacing(1)
	},

	green: {
		color: theme.palette.primary.mediumGreen
	},

	orange: {
		color: theme.palette.secondary.orange
	},

	grey: {
		color: theme.palette.grey[500]
	},

	sparkItem: {
		paddingRight: theme.spacing(2)
	},

	strong: {
		fontSize: "1.7rem"
	},
	sectionLabel: {
		fontSize: "1rem"
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const placeHolder = (
	<div style={{height: 115}}>
		<TextRow color={placeholderColor}><span></span></TextRow>
		<RectShape style={{height: "4.5rem", marginTop: 10}} color={placeholderColor}><span></span></RectShape>
	</div>
);

class SparkChartReportItem extends Component {
	getChangeText(change) {
		return `${change > 0 ? "+" : ""}${NumberFormatter.largeNumberFormat(change)}`;
	}

	getChangeColor(positiveChange, classes, invertChevron) {
		let color;
		if (positiveChange) {
			color = invertChevron ? classes.orange : classes.green;
		} else if (positiveChange === false) {
			color = invertChevron ? classes.green : classes.orange;
		} else {
			color = classes.grey;
		}
		return color;
	}

	getPerDay(value, previousDays) {
		return NumberFormatter.largeNumberFormat((value / previousDays));
	}

	render() {
		const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 2});
		let {classes, value, theme, title, percentChange, change,
				invertChevron, forceGreen, data, chartColor, caption, perCaption,
				fidoError, valueIsPercentage, largeIcon, excludePerDay, excludeChange, previousDays} = this.props,
			positiveChange = change > 0 ? true : change < 0 ? false : null,
			changeColor = forceGreen ? classes.green : this.getChangeColor(positiveChange, classes, invertChevron),
			icon = positiveChange === null ? "minus-circle" : `chevron-circle-${positiveChange ? 'up' : 'down'}`,
			ready = data ? data.length > 0 : value !== null && typeof value !== 'undefined',

			sharedItemSizing = {xs: 8, sm: 6, md: 7, lg: 6, xl: 6},

			captionTypography = (
				<Typography variant="caption">
					<div>{!excludePerDay ? perCaption ? perCaption : `${this.getPerDay(value, previousDays)} / day` : null}</div>
					<div>{!excludeChange ? caption ? caption : `${this.getChangeText(percentChange)}% / ${this.getChangeText(change)}` : null}</div>
				</Typography>
			),

			sparkLine = (
				<SparkLine
					data={data}
					accessibility={false}
					chartColor={chartColor || theme.palette.secondary.slate} />
			),

			sparkItem = (
				<Grid item xs={4} sm={6} md={5} lg={6} xl={6} className={classes.sparkItem}>
					{largeIcon ?
						<Grid container
							direction="column"
							justify="space-between"
							wrap="nowrap"
							className={classes.container}>
							<Grid item>
								{largeIcon}
							</Grid>
							<Grid item>
								{sparkLine}
							</Grid>
						</Grid>
						:
						sparkLine
					}
				</Grid>
			),

			mainGrid = (
				<Grid container
					direction="column"
					justify="space-between"
					wrap="nowrap"
					compoonent="section"
					className={classes.container}>
					<Grid item>
						<Typography className={classes.sectionLabel} variant="h3">
							{title}
							{!excludeChange ?
								<FontAwesomeIcon
									icon={["fas", icon]}
									className={`${changeColor} ${classes.chevron}`}
									title={`${positiveChange ? "upward change" : positiveChange === false ? "downward change" : "no change"}`} />
								: null}
						</Typography>
					</Grid>
					<Grid item>
						<Typography component="strong" className={classes.strong}>{numFormatter.format(value)}{valueIsPercentage ? "%" : ""}</Typography>
					</Grid>
					{excludePerDay && excludeChange ? null : (
						<Grid item>
							{largeIcon ?
								captionTypography
								:
								<Grid container wrap="nowrap" alignItems="center">
									<Grid item {...sharedItemSizing}>
										{captionTypography}
									</Grid>
									{sparkItem}
								</Grid>
							}
						</Grid>
					)}
				</Grid>
			);

		return (
			<div
				aria-label={title}
				aria-hidden="false"
				className={`${classes.root} ${ready ? classes.rootBorder : null}`}>
				<ReactPlaceholder ready={ready} customPlaceholder={placeHolder} showLoadingAnimation={!fidoError}>
					{!largeIcon ?
						mainGrid
						:
						<Grid container wrap="nowrap" alignItems="center">
							<Grid item {...sharedItemSizing}>
								{mainGrid}
							</Grid>
							{sparkItem}
						</Grid>
					}
				</ReactPlaceholder>
			</div>
		);
	}
}

SparkChartReportItem.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title: PropTypes.string,
	change: PropTypes.number,
	percentChange: PropTypes.number,
	invertChevron: PropTypes.bool,
	forceGreen: PropTypes.bool,
	caption: PropTypes.string,
	data: PropTypes.array,
	chartColor: PropTypes.string,
	fidoError: PropTypes.bool,
	valueIsPercentage: PropTypes.bool,
	largeIcon: PropTypes.object,
	excludePerDay: PropTypes.bool,
	excludeChange: PropTypes.bool,
	perCaption: PropTypes.string,
	previousDays: PropTypes.number
};

export default withStyles(styles)(withTheme(SparkChartReportItem));
