import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';

import Constants from "../utils/Constants";
import AvatarRowWithCol from './AvatarRowWithCol';
import AuthorLink from './AuthorLink';
import AuthorInfo from './AuthorInfo';
import Timestamp from './Timestamp';
import SourceIndicator from './SourceIndicator';
import ExtraHeaderInfo from './ExtraHeaderInfo';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import MultipleAuthors from "./MultipleAuthors";

import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
	alignBtn: {
		"& > *:first-child": {
			marginLeft: theme.spacing(-1.25),
			padding: theme.spacing(1.25)
		},
	},

	authorLinkRow: {
		"& > *:first-child": {
			paddingTop: theme.spacing(.5),
			paddingBottom: theme.spacing(.5),
		},
	},

	avatarContentClassname: {
		marginTop: theme.spacing(-1),
	},

	sourceIcon: {
		opacity: "0.7",
		marginLeft: theme.spacing(1),
		color: theme.palette.text.secondary
	},

	sourceIconItem: {
		paddingLeft: theme.spacing(1)
	}
});

class ActivityInfo extends Component {
	render() {
		let {classes, fidoError, authorName, authorImage, authorLink, authorDesc, authorLoc, authorFollowers, authorFollowing,
				hideAuthorInfo, sourceName, sourceHandle, sourceIcon, sourceId, subSourceId, time, showTimestamp,
				twoLineSource, extraHeaders, authenticatedUser, twitterRedacted, authors} = this.props,
			ready = authorName != null || this.props.ready === true,
			anonymize = extraHeaders && authenticatedUser.ghosting,
			showSocialAuthorInfo = authorDesc || authorLoc || authorFollowers || authorFollowing,
			isGDoc = sourceId === Constants.SOURCES.IDS.GOOGLE_DOCS && subSourceId !== Constants.SOURCES.IDS.GSUIT_COMMENT;

		return (
			<React.Fragment>
				<AvatarRowWithCol
					useIconFallback
					ready={ready}
					fidoError={fidoError}
					authorName={authorName}
					authorImage={authorImage}
					contentClassName={classes.avatarContentClassname}>
					<Grid container direction="column" justify="flex-start" alignItems="stretch" wrap="nowrap">
						<Grid item>
							<ReactPlaceholder type='textRow' ready={ready} showLoadingAnimation={!fidoError} style={{width: "85%"}}>
								<div className={`${classes.alignBtn} ${twoLineSource ? "" : classes.authorLinkRow}`}>
									{isGDoc ? (
										<MultipleAuthors authors={authors} />
									) : (
										<AuthorLink
											name={authorName}
											link={extraHeaders ? null : authorLink}
											sourceName={sourceName}
											sourceHandle={extraHeaders && !anonymize ? extraHeaders.from : sourceHandle}
											twoLines={twoLineSource} />
									)}
								</div>
							</ReactPlaceholder>
						</Grid>
						<Grid item>
							<ReactPlaceholder type='textRow' ready={ready} showLoadingAnimation={!fidoError} style={{width: "80%"}}>
								{showTimestamp ?
									<Grid container justify="flex-start" alignItems="center">
										<Grid item>
											<Timestamp time={time} relative={false} />
										</Grid>
										{sourceIcon ?
											<Grid item className={classes.sourceIconItem}>
												<SourceIndicator {...sourceIcon} />
											</Grid>	: ""
										}
									</Grid>
									: ""
								}
							</ReactPlaceholder>
						</Grid>
					</Grid>
				</AvatarRowWithCol>
				{showSocialAuthorInfo && !hideAuthorInfo ?
					<AuthorInfo
						ready={ready}
						fidoError={fidoError}
						description={authorDesc}
						location={authorLoc}
						following={authorFollowing}
						followers={authorFollowers}
						twitterRedacted={twitterRedacted} />
					: extraHeaders && !anonymize && !hideAuthorInfo ?
						<ExtraHeaderInfo headers={extraHeaders} />
						: null
				}
			</React.Fragment>
		);
	}
}

ActivityInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	ready: PropTypes.bool,
	authors: PropTypes.string,
	authorName: PropTypes.string,
	authorImage: PropTypes.string,
	authorLink: PropTypes.string,
	authorDesc: PropTypes.string,
	authorLoc: PropTypes.string,
	authorFollowers: PropTypes.number,
	authorFollowing: PropTypes.number,
	time: PropTypes.string,
	showTimestamp: PropTypes.bool,
	sourceName: PropTypes.string,
	sourceHandle: PropTypes.string,
	sourceIcon: PropTypes.object,
	sourceId: PropTypes.number,
	subSourceId: PropTypes.string,
	twoLineSource: PropTypes.bool,
	fidoError: PropTypes.bool,
	extraHeaders: PropTypes.object,
	tip: PropTypes.object,
	hideAuthorInfo: PropTypes.bool,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	twitterRedacted: PropTypes.string
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <ActivityInfo {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "ActivityInfo";

export default withStyles(styles)(React.forwardRef(dNameFunc));
