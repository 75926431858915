import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, Grid, Button, withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		paddingTop: "25%",
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	h1Override: {
		fontSize: "1.85rem",
		fontWeight: 400
	},
	h2Override: {
		fontSize: "1.15rem",
		fontWeight: 400
	}
});

class NoMatch extends Component {
	componentDidMount() {
		let headerOptions = {
			title: 'Whoops'
		};
		this.props.updateHeader(headerOptions);

		// let {authenticatedUser} = this.props;
		// let {accountId, id} = authenticatedUser;
	}

	componentWillUnmount() {
		this.props.resetHeader();
	}

	render() {
		let {classes, headline, subheading} = this.props;
		return (
			<div className={classes.root}>
				<Grid container direction="column" alignItems="center" spacing={2}>
					<Grid item>
						<Typography className={classes.h1Override} variant="h1">{headline}</Typography>
					</Grid>
					<Grid item>
						<Typography className={classes.h2Override} variant="h2">{subheading}</Typography>
					</Grid>
					<Grid item>
						<Link className="primary" to="/activities" aria-label="go to activity">
							<Button color="primary">
								Activity
							</Button>
						</Link>
					</Grid>
				</Grid>
			</div>
		);
	}
}

NoMatch.propTypes = {
	classes: PropTypes.object.isRequired,
	resetHeader: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	headline: PropTypes.string,
	subheading: PropTypes.string
	// showSnackbar: PropTypes.func,
};

// export default React.forwardRef((props, ref) => (
// 	<AuthorizedUserContext.Consumer>
// 		{authenticatedUser => <UserProfile {...props} authenticatedUser={authenticatedUser} ref={ref} />}
// 	</AuthorizedUserContext.Consumer>
// ));

export default withStyles(styles)(NoMatch);
// export default NoMatch;
