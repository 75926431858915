import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import PaddedLayout from '../../containers/PaddedLayout';
import ActivitiesWrapper from "../../containers/ActivitiesWrapper";
import PositionMemory from "../../components/PositionMemory";

class TrendingTopicPosts extends Component {
	constructor(props, context) {
		super(props, context);
		this.updateHeader = this.updateHeader.bind(this);
	}

	componentDidMount() {
		this.props.updateHeader({title: "trending topic"});
	}

	componentWillUnmount() {
		this.props.resetHeader();
	}

	updateHeader(result) {
		this.props.updateHeader({title: result.trendingTopic.name.replace(/\|/ig, "/")});
	}

	render() {
		let {showSnackbar, previousLocation, history, location} = this.props;
		return (
			<PaddedLayout>
				<PositionMemory
					history={history}
					location={location}
					previousLocation={previousLocation}
					localStorageKey="/activities" // this is not required, only using because drilldown -> /activities
					render={props => {
						return (
							<ActivitiesWrapper
								showSnackbar={showSnackbar}
								updateHeader={this.updateHeader}
								onTrackPage={props.trackPage}
								onTrackItem={props.trackItem}
								setNumberOfPages={props.setNumberOfPages}
								restorePosition={props.restorePosition}
								fidoConfig={{
									alerts: {
										opts: null,
										url: location.pathname
									}
								}}>
							</ActivitiesWrapper>
						);
					}} />
			</PaddedLayout>
		);
	}
}

TrendingTopicPosts.propTypes = {
	updateHeader: PropTypes.func.isRequired,
	resetHeader: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	previousLocation: PropTypes.object,
	showSnackbar: PropTypes.func.isRequired
};

export default withRouter(TrendingTopicPosts);