import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Fido from '../utils/Fido';
import Branding from "../components/Branding";
import LoginForm from '../components/LoginForm';
import GetResetForm from '../components/GetResetForm';
import PaddedLayout from '../containers/PaddedLayout';
import LoginHelpButton from '../components/LoginHelpButton';
import FormattedPhone from '../components/FormattedPhone';
import Footer from '../components/Footer';
import DialogSlideTransition from "../components/DialogSlideTransition";
import Constants from '../utils/Constants';
import AuthorizedUser from "../utils/AuthorizedUser";

import {
    withStyles,
    Paper,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography,
} from '@material-ui/core';

const styles = theme => ({
	paddedLayout: {
		maxWidth: 550,
		paddingTop: "15%",

		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3),
			paddingTop: theme.spacing(5)
		}
	},

	paper: {
		padding: theme.spacing(3),
		marginBottom: "15%",

		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(10),
			paddingRight: theme.spacing(10)
		}
	},

	errorPaper: {
		padding: theme.spacing(1.5),
		marginBottom: theme.spacing(1)
	},

	longDialogTitle: {
		display: "none",

		[theme.breakpoints.up('sm')]: {
			display: "inline"
		}
	},

	icon: {
		fontSize: "1.5rem",
		marginRight: "0.3rem",
		color: theme.palette.secondary.slate
	},

	helpButton: {
		maxWidth: "100%",
		padding: theme.spacing(1),
		color: theme.palette.secondary.slate
	},

	// for some reason using Typography noWrap threw an error
	email: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	},

	description: {
		paddingBottom: theme.spacing(1)
	}
});

class Login extends Component {
	constructor(props, context) {
		super(props, context);

		let formValues = Object.assign({});

		this.state = {
			formValues: formValues,
			forgotPass: false,
			helpOpen: false,
			error: false
		};

		this.fido = new Fido();

		this.handleChange = this.handleChange.bind(this);
		this.handleResetSubmit = this.handleResetSubmit.bind(this);
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
		this.handleForgotToggle = this.handleForgotToggle.bind(this);
		this.handleHelpToggle = this.handleHelpToggle.bind(this);
	}

	componentDidMount() {
		document.body.classList.add("login");
		this.updateTitle("Login to Social Sentinel");
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.forgotPass !== this.state.forgotPass) {
			this.updateTitle("Social Sentinel Password Reset");

		// This should only happen if user already logged in, once whoami completes
		} else if (prevProps.authorized !== this.props.authorized) {
			// If authorized, don't require form submission
			this.handleRedirect('/', this.props.location.search);
		}
	}

	componentWillUnmount() {
		this.props.resetHeader();
		document.body.classList.remove("login");
	}

	updateTitle(title) {
		this.props.updateHeader({
			hideHeaderbar: true,
			hideMargin: true,
			title: title
		});
	}

	handleChange(event) {
		let formValues = this.state.formValues;
		formValues[event.target.name] = event.target.value;

		this.setState({formValues: formValues});
	}

	handleResetSubmit(formValues) {
		this.setState({error: false});
		this.fido.fetch("/forgot-pw", {
			method: "POST",
			body: JSON.stringify(formValues)
		})
			.then((data) => {
				if (data) {
					if (data.messages.error && data.messages.error.length) {
						this.setState({error: data.messages.error});
					} else {
						this.props.showSnackbar('Sending Reset Email...');
						this.handleForgotToggle();
					}
				}
			})
			.catch(error => this.props.showSnackbar(error.message));
	}

	handleLoginSubmit(formValues) {
		if(!this.props.authorized) {
			let to = Fido.getSearchParam("to", this.props.location.search);

			this.setState({error: false});
			this.fido.fetch(this.props.location.pathname, {
				method: "POST",
				body: JSON.stringify(formValues)
			})
				.then((data) => {
					if (data) {
						if (data && data.messages && data.messages.error && data.messages.error.length) {
							if (data.messages.error[0].includes('reset')) {
								this.handleForgotToggle();
							}
							this.setState({error: data.messages.error});
						} else if (!to && data && data.messages && data.messages.success
							&& data.messages.success.length && data.messages.success[0] === 3) {
							// TEMP: We return the user account version in the success msg and redirect V3
							window.location.href = '/system/accounts';
						} else {
							AuthorizedUser.load((user) => {
								this.props.loginCallback(user);
								this.handleRedirect("/", this.props.location.search);
							}).catch((error) => {
								this.props.loadUserFailed();
								this.showSnackbar(error.message);
							});
						}
					}
				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	handleForgotToggle() {
		this.setState({
			error: false,
			forgotPass: !this.state.forgotPass
		});
	}

	handleHelpToggle() {
		this.setState({helpOpen: !this.state.helpOpen});
	}

	// Override "to" param if one sent in with url params; pass along rest
	handleRedirect = (to, params) => {
		let paramsObj = Fido.getSearchParamsObject(params);

		to = paramsObj.to || to;
		delete(paramsObj.to);

		let queries = Object.keys(paramsObj).map(k => `${k}=${paramsObj[k]}`).join('&');
		let url = to + (queries && `?${queries}` || '');
		this.props.history.push(url);
	}

	render() {
		let	{classes, history} = this.props,
			{formValues, forgotPass, helpOpen, error} = this.state;

		return (
			<PaddedLayout className={classes.paddedLayout}>
				<Branding className={classes.branding} secondary light />
				{error ? <Paper className={classes.errorPaper}><Typography align="center" color="error">{error}</Typography></Paper> : null}
				<Paper className={classes.paper}>
					{forgotPass ? (
						<div>
							<Typography className={classes.description} color="inherit">Enter the email address tied to your socialsentinel.com account.</Typography>
							<GetResetForm
								formValues={formValues}
								handleChange={this.handleChange}
								handleSubmit={this.handleResetSubmit}
								handleForgotToggle={this.handleForgotToggle} />
							<LoginHelpButton handleClick={this.handleForgotToggle} centered>Back to Sign In</LoginHelpButton>
						</div>
					) : (
						<div>
							<LoginForm
								formValues={formValues}
								handleChange={this.handleChange}
								handleSubmit={this.handleLoginSubmit}
								handleForgotToggle={this.handleForgotToggle} />
							<LoginHelpButton handleClick={this.handleHelpToggle} centered>Get Help</LoginHelpButton>
						</div>
					)}
					<Dialog
						open={helpOpen}
						TransitionComponent={DialogSlideTransition}
						onClose={this.handleHelpToggle}>
						<DialogTitle>Get Help<span className={classes.longDialogTitle}> From Client Success</span></DialogTitle>
						<DialogContent>
							<DialogContentText>
								<Button className={classes.helpButton} href={`tel:${Constants.HELP.PHONE}`} style={{textTransform: "none"}}>
									<FontAwesomeIcon title="call" icon={["fas", "phone"]} className={classes.icon} />
									<FormattedPhone number={Constants.HELP.PHONE} />
								</Button><br/>
								<Button className={classes.helpButton} href={`mailto:${Constants.HELP.EMAIL}`} style={{textTransform: "none"}}>
									<FontAwesomeIcon
										title="email"
										icon={["fas", "envelope"]}
										className={classes.icon} /><span className={classes.email}>{Constants.HELP.EMAIL}</span>
								</Button>
							</DialogContentText>
						</DialogContent>
					</Dialog>
				</Paper>
				<Footer hideTwitterTos light history={history} />
			</PaddedLayout>
		);
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	resetHeader: PropTypes.func,
	updateHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	authorized: PropTypes.object,
	loginCallback: PropTypes.func.isRequired,
	loadUserFailed: PropTypes.func.isRequired
};

export default withStyles(styles)(withRouter(Login));
