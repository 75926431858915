import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles, Card, CardContent, ListItem, Grid, Typography, Button } from '@material-ui/core';

const styles = theme => ({
	root: {
		paddingTop: 0,
		paddingBottom: 0
	},

	card: {
		width: "100%",
		borderRadius: 0,

		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1.5),
			marginRight: theme.spacing(1.5),
		}
	},

	cardContent: {
		paddingBottom: `${2*theme.spacing(1)}px !important`
	},

	topicInfo: {
		paddingLeft: theme.spacing(.5)
	},

	topicName: {
		lineHeight: 1.2
	},

	chevronContainer: {
		display: "flex",
		justifyContent: "flex-end",
		paddingTop: "0.1rem"
	},

	chevron: {
		color: theme.palette.text.secondary
	},

	showMore: {
		paddingTop: 0,
		paddingBottom: `${theme.spacing(1)}px !important`
	},

	viewMoreLink: {
		marginTop: theme.spacing(1.25),
		marginLeft: theme.spacing(-1.5)
	},

	listItem: {
		cursor: "pointer"
	}
});

class TrendingTopicListItem extends Component {
	render() {
		let {classes, topic, topicNumber, className, isShowMore, showMoreHandler, clickHandler} = this.props;
		return (
			<ListItem
				disableGutters
				classes={{root: classes.root}}
				className={`${classes.listItem} ${className}`}
				onClick={!isShowMore ? () => clickHandler(topic) : null}>
				<Card className={classes.card}>
					<CardContent className={`${classes.cardContent} ${isShowMore ? classes.showMore : null}`}>
						{isShowMore ?
							<Button
								size="small"
								color="primary"
								onClick={showMoreHandler}
								className={classes.viewMoreLink}>Show More</Button>
							:
							<Grid container>
								<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
									<Typography color="textSecondary">{topicNumber}</Typography>
								</Grid>
								<Grid item xs={10} sm={10} md={10} lg={10} xl={10} className={classes.topicInfo}>
									<Typography className={classes.topicName}>{topic.name.replace(/\|/ig, "/")}</Typography>
									<Typography variant="caption" color="textSecondary">{topic.posts} posts &middot; {topic.authors} people</Typography>
								</Grid>
								<Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.chevronContainer}>
									<FontAwesomeIcon icon={["far", "angle-right"]} className={classes.chevron} title="chevron" />
								</Grid>
							</Grid>
						}
					</CardContent>
				</Card>
			</ListItem>
		);
	}
}

TrendingTopicListItem.propTypes = {
	classes: PropTypes.object.isRequired,
	topic: PropTypes.object.isRequired,
	topicNumber: PropTypes.number,
	className: PropTypes.string,
	isShowMore: PropTypes.bool,
	showMoreHandler: PropTypes.func,
	clickHandler: PropTypes.func
};

export default withStyles(styles)(TrendingTopicListItem);
