import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Highchart from "./Highchart";
import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";

import { withTheme, withStyles } from '@material-ui/core';

const styles = theme => ({
	columnChart: {
		"& .highcharts-point": {
			rx: 12,
			ry: 12,
			transform: "translate(0px, -1px)"
		}
	}
});

class EmailEngagementReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {classes, theme, emailEngagement, previousDays} = this.props;
		this.state = {
			chartConfig: HighchartsConfig.merge({
				chart: {
					height: 340,
					marginTop: 15,
					className: classes.columnChart
				},
				title: "",
				legend: {enabled: true},
				accessibility: {
					pointValueSuffix: " emails",
					description: `Average number of emails scanned by day of the week over the last ${previousDays} days.`
				},
				yAxis: {
					title: "",
					lineWidth: 0,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					labels: {
						y: 0,
						style: {
							fontSize: "15px"
						}
					}
				},
				xAxis: {
					type: "category",
					tickWidth: 0,
					lineWidth: 0,
					labels: {
						y: 30,
						align: "center",
						style: {
							fontSize: "15px"
						},
						formatter: function() {
							return this.value.charAt(0);
						}
					}
				},
				plotOptions: {
					column: {
						borderRadius: 10,
						borderColor: theme.palette.primary.mediumGreen
					}
				},
				tooltip: {
					pointFormatter: function() {
						let numFormatter = new Intl.NumberFormat();
						let header = `<span style="font-weight: bold">${this.name}</span><br/>`,
							content = `<span style="color:${this.color}">\u25CF</span> Emails: <b>${numFormatter.format(this.y)}</b><br/>`;
						return header + content;
					}
				},
				series: [{
					type: "column",
					name: "Average Emails Scanned",
					color: theme.palette.primary.mediumGreen,
					data: ReportDataMasseuse.massageData(emailEngagement, "day", "associations")
				}]
			})
		};
	}

	render() {
		let {chartConfig} = this.state;
		return (
			<Highchart chartConfig={chartConfig} />
		);
	}
}

EmailEngagementReport.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	previousDays: PropTypes.number,
	emailEngagement: PropTypes.array
};

export default withStyles(styles)(withTheme(EmailEngagementReport));
