// Import core library
import { library } from '@fortawesome/fontawesome-svg-core';

// Brands
import {
	faFacebook,
	faFlickr,
	faGoogle,
	faReddit,
	faInstagram,
	faMeetup,
	faTumblr,
	faTwitter,
	faVimeo,
	faYoutube,
	faWordpress
} from '@fortawesome/free-brands-svg-icons';

// Pro regular
import {
	faLongArrowLeft,
	faMap,
	faClock,
	faBars,
	faArrowToRight,
	faArrowToLeft,
	faArrowRight as farArrowRight,
	faPlus,
	faExternalLinkSquare,
	faTimes as faTimesRegular,
	faCircle as faCircleRegular,
	faAngleDown,
	faAngleUp,
	faSortAmountUp,
	faSortAmountDown,
	faSortAlphaUp,
	faSortAlphaDown,
	faLongArrowAltUp,
	faLongArrowAltDown,
	faAngleRight,
	faAt,
	faEnvelope as faEnvelopeRegular,
	faMapMarkerAlt as faMapMarkerAltRegular,
	faUsers,
	faUser as faUserRegular,
	faCommentsAlt,
	faSlidersH,
	faQuestionCircle,
	faCalendarStar,
	faTasks
} from '@fortawesome/pro-regular-svg-icons';

// Pro solid
import {
	faUser as faUserSolid,
	faPhone,
	faBullhorn,
	faEnvelope,
	faArrowRight,
	faArrowLeft,
	faBookmark as faBookmarkSolid,
	faCircle as faCircleSolid,
	faTrashAlt as faTrashAltSolid,
	faShare as faShareSolid,
	faComment as faCommentSolid,
	faCommentAltLines as faCommentAltLinesSolid,
	faMale as faMaleSolid,
	faEnvelope as faEmailSolid,
	faChevronCircleUp as faChevronUpSolid,
	faChevronCircleDown as faChevronDownSolid,
	faMinusCircle as faMinusCircleSolid,
	faTh,
	faWifi,
	faChevronRight,
	faSortAlt as faSortAltSolid,
	faAbacus as faAbacusSolid
} from '@fortawesome/pro-solid-svg-icons';

// Pro light
import {
	faPaperPlane,
	faSearch,
	faShare,
	faMapMarkerAlt as faMapMarkerAltLight,
	faTrashAlt,
	faEllipsisV,
	faUser as faUserLight,
	faTimes as faTimesLight,
	faBookmark as faBookmarkLight,
	faGlobe,
	faCamera,
	faImages,
	faAngleDown as faAngleDownLight,
	faSortAmountUp as faSortAmountUpLight,
	faSortAmountDown as faSortAmountDownLight,
	faSortAlphaUp as faSortAlphaUpLight,
	faSortAlphaDown as faSortAlphaDownLight,
	faLongArrowAltUp as faLongArrowAltUpLight,
	faLongArrowAltDown as faLongArrowAltDownLight,
	faEnvelope as faEmailLight,
	faCommentsAlt as faCommentsAltLight,
	faCommentAltSmile,
	faArrowUp as faArrowUpLight,
	faArrowDown as faArrowDownLight,
	faListAlt as faListAltLight,
	faFilePdf as faFilePdfLight
}  from '@fortawesome/pro-light-svg-icons';

// Duo tone
import {
	faCheckCircle,
	faSatelliteDish as faSatelliteDishDuo
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
	// Brands
	faFacebook,
	faFlickr,
	faGoogle,
	faReddit,
	faInstagram,
	faMeetup,
	faTumblr,
	faTwitter,
	faVimeo,
	faYoutube,
	faWordpress,

	// Pro regular
	faLongArrowLeft,
	faMap,
	faClock,
	faBars,
	faArrowToRight,
	faArrowToLeft,
	farArrowRight,
	faPlus,
	faExternalLinkSquare,
	faTimesRegular,
	faCircleRegular,
	faAngleDown,
	faAngleUp,
	faSortAmountUp,
	faSortAmountDown,
	faSortAlphaUp,
	faSortAlphaDown,
	faLongArrowAltUp,
	faLongArrowAltDown,
	faAngleRight,
	faAt,
	faEnvelopeRegular,
	faMapMarkerAltRegular,
	faUsers,
	faUserRegular,
	faBullhorn,
	faCommentsAlt,
	faSlidersH,
	faQuestionCircle,
	faTasks,
	faCalendarStar,

	// Pro solid
	faUserSolid,
	faPhone,
	faEnvelope,
	faArrowRight,
	faArrowLeft,
	faBookmarkSolid,
	faCircleSolid,
	faTrashAltSolid,
	faShareSolid,
	faCommentSolid,
	faMaleSolid,
	faChevronUpSolid,
	faChevronDownSolid,
	faMinusCircleSolid,
	faTh,
	faWifi,
	faChevronRight,
	faSortAltSolid,
	faAbacusSolid,

	// Pro light
	faPaperPlane,
	faSearch,
	faShare,
	faMapMarkerAltLight,
	faTrashAlt,
	faEllipsisV,
	faUserLight,
	faTimesLight,
	faBookmarkLight,
	faGlobe,
	faCamera,
	faImages,
	faAngleDownLight,
	faSortAmountUpLight,
	faSortAmountDownLight,
	faSortAlphaUpLight,
	faSortAlphaDownLight,
	faLongArrowAltUpLight,
	faLongArrowAltDownLight,
	faCommentAltLinesSolid,
	faEmailSolid,
	faEmailLight,
	faCommentsAltLight,
	faCommentAltSmile,
	faArrowUpLight,
	faArrowDownLight,
	faListAltLight,
	faFilePdfLight,

	// Duo tone
	faCheckCircle,
	faSatelliteDishDuo
);
