import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {RectShape} from 'react-placeholder/lib/placeholders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Media from 'react-media';

import ReportItemPaper from "./ReportItemPaper";
import TrendingTopicsTable from "./TrendingTopicsTable";
import Constants from "../../utils/Constants";

import { withStyles, Typography, Button, Grid } from '@material-ui/core';

const styles = theme => ({
	noActivity: {
		marginTop: "25%",
		textAlign: "center"
	},

	bold: {
		fontWeight: 700
	},

	cardWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "flex-start"
	},

	tableWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "flex-start",
		height: "100%",
		width: "100%",
		marginTop: theme.spacing(-1.5)
	},

	button: {
		marginTop: theme.spacing(.7),
		marginBottom: theme.spacing(.7)
	},

	buttonGrid: {
		bottom: 2,
		position: "absolute",
		marginTop: 8,
		width: "92%"
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const tablePlaceHolder = (
	<div style={{paddingBottom: 20, width: "100%"}}>
		<div style={{paddingTop: 20, display: "flex"}}>
			<RectShape style={{height: 50, width: 100}} color={placeholderColor}><span></span></RectShape>
			<RectShape style={{height: 50, marginRight: "0px !important"}} color={placeholderColor}><span></span></RectShape>
		</div>
		<div style={{paddingTop: 20, display: "flex"}}>
			<RectShape style={{height: 50, width: 100}} color={placeholderColor}><span></span></RectShape>
			<RectShape style={{height: 50, marginRight: "0px !important"}} color={placeholderColor}><span></span></RectShape>
		</div>
	</div>
);

const title = (
	<div>
		<FontAwesomeIcon title="conversation bubbles" icon={["far", "comments-alt"]} /> Trending Topics
	</div>
);

class TrendingTopicsReportItem extends Component {
	render() {
		let {classes, topics, /*previousDays,*/ fidoError, overTimeClickHandler, viewAllClickHandler} = this.props;
		//isThirtyDays = previousDays === 30;

		return (
			<ReportItemPaper
				//ready={topics != null || !isThirtyDays}
				ready={topics != null}
				fidoError={fidoError}
				//previousDays={previousDays}
				previousDays={30}
				placeHolder={tablePlaceHolder}
				//className={isThirtyDays && topics && topics.length ? classes.cardWrapper : null}
				className={topics && topics.length ? classes.cardWrapper : null}
				title={title}
				subtitle="Relative ranking based on proportion of all posts.">
				<Media query={{maxWidth: 375}}>
					{matches =>
						//{topics && topics.length && isThirtyDays ?
						topics && topics.length ? (
							<div className={classes.tableWrapper}>
								<TrendingTopicsTable topics={topics} />
								<Grid container justify="space-between" className={classes.buttonGrid}>
									<Grid item>
										<Button
											size="small"
											color="primary"
											className={classes.button}
											onClick={viewAllClickHandler}>{matches ? "View All" : "View All Topics"}</Button>
									</Grid>
									<Grid item>
										<Button
											size="small"
											color="primary"
											className={classes.button}
											onClick={overTimeClickHandler}>{matches ? "View Over Time" : "View Topics Over Time"}</Button>
									</Grid>
								</Grid>
							</div>
						) : (
							/*<Typography color="textSecondary" className={`${classes.noActivity} ${!isThirtyDays ? classes.bold : null}`}>
								{!isThirtyDays ? "You can only see activity for a 30 day time period." : "There is no activity for this time period."}
							</Typography>*/
							<Typography color="textSecondary" className={`${classes.noActivity}`}>
								There is no activity for this time period.
							</Typography>
						)
					}
				</Media>
			</ReportItemPaper>
		);
	}
}

TrendingTopicsReportItem.propTypes = {
	classes: PropTypes.object.isRequired,
	fidoError: PropTypes.bool,
	topics: PropTypes.array,
	previousDays: PropTypes.number,
	overTimeClickHandler: PropTypes.func.isRequired,
	viewAllClickHandler: PropTypes.func.isRequired
};

export default withStyles(styles)(TrendingTopicsReportItem);
