import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import MomentCore from 'moment';
import Highchart from "./Highchart";

import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import DetailedReport from "../containers/DetailedReport";
import { withStyles, Paper } from '@material-ui/core';

//import {dashboardData} from "../../data/reports";

const styles = theme => ({
	root: {
		minHeight: 480,
		[theme.breakpoints.down('xs')]: {
			minHeight: 450
		}
	},

	basicColumn: {
		"& .highcharts-point": {
			rx: 4,
			ry: 4,
			transform: "translate(0px, -1px)",

			"@media (min-width: 1200px)": {
				rx: 6,
				ry: 6
			},

			[theme.breakpoints.down('xs')]: {
				rx: 3,
				ry: 3
			}
		}
	},

	"7dayColumn": {
		"& .highcharts-point": {
			rx: 10,
			ry: 10,
			transform: "translate(0px, -1px)",

			"@media (min-width: 1200px)": {
				rx: 20,
				ry: 20
			},

			[theme.breakpoints.down('xs')]: {
				rx: 0,
				ry: 0
			}
		}
	},

	"30dayColumn": {
		"& .highcharts-point": {
			rx: 4,
			ry: 4,
			transform: "translate(0px, -1px)",

			"@media (min-width: 1200px)": {
				rx: 5,
				ry: 5
			},

			[theme.breakpoints.down('xs')]: {
				rx: 0,
				ry: 0
			}
		}
	}
});

class ContentAndAlertsReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {theme, chartClasses, previousDays} = this.props;
		this.state = {
			chartConfig: HighchartsConfig.merge({
				chart: {
					type: "column",
					className: chartClasses.chart,
					marginTop: theme.spacing(6)
				},
				title: {
					align: "left",
					text: "Content Scanned vs Alerts"
				},
				legend: {
					enabled: true
				},
				tooltip: {
					shared: true,
					formatter: function() {
						let date = new MomentCore(this.x);
						const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 0});
						return this.points.reduce(function (s, point) {
							return `${s}<br/><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${numFormatter.format(point.y)}</b><br/>`;
						}, `<span style='font-weight: bold'>${date.format("MMM D")}</span><br/>`);
					}
				},
				accessibility: {
					description: `The number of emails scanned per day over the last ${previousDays} days, as well as the number of alerts that were sent per day over the last ${previousDays} days.`
				},
				yAxis: [{
					title: {
						text: "# of emails, chats & doc scans",
						style: {
							fontSize: "15px"
						}
					},
					min: 0,
					startOnTick: false,
					endOnTick: false,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					labels: {
						style: {
							fontSize: "15px"
						}
					}
				},{
					title: {
						text: "# of alerts",
						style: {
							fontSize: "15px"
						}
					},
					min: 0,
					startOnTick: false,
					endOnTick: false,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					opposite: true,
					labels: {
						style: {
							fontSize: "15px"
						}
					}
				}],
				xAxis: {
					type: "datetime",
					tickWidth: 0,
					offset: 20,
					labels: {
						y: 35,
						rotation: -45,
						align: "center",
						style: {
							fontSize: "15px"
						}
					},
					style: {
						color: theme.palette.primary.darkGrey
					}
				},
				plotOptions: {
					line: {
						lineWidth: theme.spacing(1),
						softThreshold: false,
						marker: {
							radius: theme.spacing(1)
						}
					}
				},
				series: [{
					yAxis: 0,
					name: "Emails",
					color: theme.palette.primary.mediumGreen,
					borderColor: theme.palette.primary.mediumGreen
				},{
					yAxis: 0,
					name: "Chats",
					color: theme.palette.secondary.yellow,
					borderColor: theme.palette.secondary.yellow
				},{
					yAxis: 0,
					name: "Docs",
					color: theme.palette.secondary.purple,
					borderColor: theme.palette.secondary.purple
				},{
					type: "spline",
					name: "Alerts",
					yAxis: 1,
					color: theme.palette.secondary.orange
				}]
			}, {
				modifyTickInterval: true,
				previousDays: previousDays
			})
		};
	}

	componentDidMount() {
		this.setupColumns();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.alerts !== this.props.alerts || prevProps.emails !== this.props.emails || prevProps.chats !== this.props.chats || prevProps.docs !== this.props.docs || prevProps.previousDays !== this.props.previousDays) {
			this.setupColumns();
		}
	}

	setupColumns() {
		let {classes, emails, chats, docs, alerts, previousDays} = this.props,
			{chartConfig} = this.state,
			barClass = previousDays > 30 ? classes.basicColumn : `${classes[previousDays+"dayColumn"]}`;

		if (emails !== null) {
			// TEMP
			//data = dashboardData;
			ReportDataMasseuse.convertDatesToMillis(emails);
			chartConfig.series[0].data = ReportDataMasseuse.massageData(emails, "period", "associations");
			chartConfig.series[0].className = barClass;
		} else {
			chartConfig.series[0].data = null;
		}

		if (chats !== null) {
			// TEMP
			//data = dashboardData;
			ReportDataMasseuse.convertDatesToMillis(chats);
			chartConfig.series[1].data = ReportDataMasseuse.massageData(chats, "period", "associations");
			chartConfig.series[1].className = barClass;
		} else {
			chartConfig.series[1].data = null;
		}

		if (docs !== null) {
			// TEMP
			//data = dashboardData;
			ReportDataMasseuse.convertDatesToMillis(docs);
			chartConfig.series[2].data = ReportDataMasseuse.massageData(docs, "period", "associations");
			chartConfig.series[2].className = barClass;
		} else {
			chartConfig.series[2].data = null;
		}

		if (alerts !== null) {
			// TEMP
			//data = dashboardData;
			ReportDataMasseuse.convertDatesToMillis(alerts);
			chartConfig.series[3].data = ReportDataMasseuse.massageData(alerts, "period", "alerts");
			chartConfig.series[3].className = barClass;
		} else {
			chartConfig.series[3].data = null;
		}

		this.setState({chartConfig: chartConfig});
	}

	render() {
		let {chartConfig} = this.state,
			{classes, fidoError} = this.props;

		return (
			<Paper className={classes.root}>
				<DetailedReport
					fidoError={fidoError}
					ready={chartConfig.series[0].data && chartConfig.series[0].data.length > 0 ? true : false}>
					<Highchart chartConfig={chartConfig} />
				</DetailedReport>
			</Paper>
		);
	}
}

ContentAndAlertsReport.propTypes = {
	theme: PropTypes.object.isRequired,
	chartClasses: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	emails: PropTypes.array,
	chats: PropTypes.array,
	docs: PropTypes.array,
	alerts: PropTypes.array,
	previousDays: PropTypes.number,
	fidoError: PropTypes.bool
};

export default withStyles(styles)(withTheme(ContentAndAlertsReport));
