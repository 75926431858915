import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Timestamp from '../../components/Timestamp.js';
import Avatar from '../../components/Avatar.js';
import AuditTrailIcon from "../../components/AuditTrailIcon";
import UserActivityIndicators from '../../components/UserActivityIndicators.js';
import TableSortableHeader from '../../components/TableSortableHeader';
import Constants from "../../utils/Constants";

import {
    withStyles,
    withWidth,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TableFooter,
    Typography,
    Grid,
} from '@material-ui/core';

const styles = theme => ({
	inline: {
		display: "inline-block"
	},

	secondary: {
		color: theme.palette.text.secondary
	},

	activityHeader: {
		whiteSpace: "nowrap"
	},

	textCol: {
		paddingLeft: theme.spacing(2)
	},

	indicatorCell: {
		paddingBottom: theme.spacing(2)
	},

	indicators: {
		flexWrap: "wrap"
	},

	cell: {
		paddingRight: "0px !important",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},

	userCol: {
		flexWrap: "nowrap"
	},

	usernameGridItem: {
		width: "100%",
		paddingLeft: theme.spacing(2)
	},

	tableSmall: {
		marginTop: theme.spacing(2)
	},

	cellSmall: {
		paddingLeft: 0,
		paddingRight: "0px !important",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},

	lastActionSmall: {
		paddingTop: theme.spacing(1.5)
	},

	indicatorsSmall: {
		marginLeft: theme.spacing(-1)
	},

	footer: {
		position: "absolute",
		bottom: theme.spacing(.5),
		paddingLeft: 0,
		paddingRight: "0px !important",
		paddingTop: theme.spacing(2)
	},

	sortIcon: {
		fontSize: "1.3rem",
		marginLeft: theme.spacing(1)
	},

	viewActivityOnlyCell: {
		paddingTop: theme.spacing(.5),
		paddingBottom: theme.spacing(3.5)
	}
});

class UserEngagementTable extends Component {
	getText(obj) {
		let text,
			withName = obj.withName,
			isDiscussion = obj.category === Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.ID,
			isComment = obj.type === Constants.ACTIVITY.AUDIT_TRAIL.COMMENT.ID,
			isXs = this.props.width.includes("xs"),
			category = obj.category === Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.ID ?
				Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.NAME : Constants.ACTIVITY.CATEGORIES.ALERTS.NAME;

		if (isXs) {
			withName = withName ? withName.split(" ") : [];
			if (withName.length) {
				withName = withName.length > 1 ? withName[0].charAt(0) + ". " + withName[1] : obj.withName;
			}
		}

		//<b><span className={classes.capitalize}><i>Shared</i></span> an activity</b> with <b>jlascolea@socialsentinel.com</b>
		//{withName ? <br/> : ""}
		text = <div>
			<b><span style={{textTransform: "capitalize"}}><i>{obj.type}{isComment ? "ed" : "d"}</i></span>{isComment ? " on" : ""}
				{isDiscussion ? " a" : " an"} {category}{isDiscussion ? " post" : ""}</b>{withName && withName.length ? " with " : ""}<b>{withName}</b>
		</div>;

		return text;

		// Shared an alert
		// Shared an alert with
		// Saved an alert
		// Removed an alert
		// Commented on an alert

		// Shared a discussion post with
		// Shared a discussion post
		// Saved a discussion post
		// Removed a discussion post
		// Commented on a discussion post
	}

	render() {
		let {theme, classes, width, users, footer, sortHandler, sortDir, sortBy, forceSmall, largeTableSize} = this.props,
			isSmall = forceSmall || width.includes(largeTableSize),
			footerBtn = footer ? (
				<div style={{textAlign: isSmall ? "right" : "left"}}>
					<Typography color="textSecondary" variant="caption" className={classes.inline}>
						Showing {footer.limit} of {footer.total}  |
					</Typography>
					<Button
						size="small"
						color="primary"
						style={{marginTop: -4}}
						onClick={footer.clickHandler}>View All</Button>
				</div>) : null;

		return (
			<Media query={{minWidth: theme.breakpoints.values[largeTableSize]}}>
				{matches => (
					matches && !forceSmall ?
						<Table>
							{sortHandler ?
								<TableSortableHeader
									order={sortDir}
									orderBy={sortBy}
									onRequestSort={sortHandler}
									columnData={[
										{id: 'userName', label: 'User', alpha: true},
										{id: 'lastActivityDate', label: 'Last Action'},
										{id: 'activityCount', label: 'User Activity', amount: true}
									]} />
								:
								<TableHead>
									<TableRow>
										<TableCell>User</TableCell>
										<TableCell>Last Action</TableCell>
										<TableCell className={classes.activityHeader}>
											User Activity
											<FontAwesomeIcon
												title="sort indicator"
												icon={["fal", "sort-amount-down"]}
												color={theme.palette.text.secondary}
												className={classes.sortIcon} />
										</TableCell>
									</TableRow>
								</TableHead>
							}
							<TableBody>
								{users.map(user => {
									let onlyViews = !user.activities_removed && !user.activities_saved && !user.activities_shared && !user.activities_commented;

									return (
										<TableRow key={`key-${user.user_id}`}>
											<TableCell className={classes.cell}>
												<Grid container justify="flex-start" className={classes.userCol}>
													<Grid item>
														<Avatar
															authorName={user.user_name}
															authorImage={user.avatar} />
													</Grid>
													<Grid item className={classes.usernameGridItem}>
														<Typography color="textSecondary"><b>{user.user_name}</b></Typography>
														<Typography color="textSecondary">{user.title}</Typography>
													</Grid>
												</Grid>
											</TableCell>
											<TableCell className={classes.cell}>
												{user.most_recent_activity ?
													<Grid container justify="flex-start" wrap="nowrap">
														<Grid item>
															<AuditTrailIcon type={user.most_recent_activity.type} />
														</Grid>
														<Grid item className={`${classes.textCol} ${classes.secondary}`}>
															{this.getText({
																type: user.most_recent_activity.type,
																category: user.most_recent_activity.category,
																withName: user.most_recent_activity.recipient ?
																	user.most_recent_activity.recipient.name ||
																	user.most_recent_activity.recipient.phone ||
																	user.most_recent_activity.recipient.email : null
															})}
															<Timestamp time={user.most_recent_activity.date} />
														</Grid>
													</Grid> : null
												}
											</TableCell>
											<TableCell
												className={`${classes.cell} ${classes.indicatorCell} ${onlyViews ? classes.viewActivityOnlyCell : null}`}>
												<UserActivityIndicators
													tightPack={!isSmall}
													densePack={isSmall}
													disableTooltips
													className={classes.indicators}
													removed={user.activities_removed}
													saved={user.activities_saved}
													shared={user.activities_shared}
													commented={user.activities_commented}
													userViewed={user.activities_viewed}
													userRated={user.activities_rated} />
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
							{footer ?
								<TableFooter>
									<TableRow>
										<TableCell className={classes.footer} colSpan={3}>
											{footerBtn}
										</TableCell>
									</TableRow>
								</TableFooter> : null
							}
						</Table>
						:
						<Table className={classes.tableSmall}>
							<TableBody>
								{users.map(user => {
									return (
										<TableRow key={`key-${user.user_id}`}>
											<TableCell className={classes.cellSmall}>
												<Grid container direction="column">
													<Grid item>
														<Grid container className={classes.userCol}>
															<Grid item>
																<Avatar
																	authorName={user.user_name}
																	authorImage={user.avatar} />
															</Grid>
															<Grid item className={classes.usernameGridItem}>
																<Typography color="textSecondary"><b>{user.user_name}</b></Typography>
																<UserActivityIndicators
																	densePack
																	disableTooltips
																	removed={user.activities_removed}
																	saved={user.activities_saved}
																	shared={user.activities_shared}
																	commented={user.activities_commented}
																	userViewed={user.activities_viewed}
																	userRated={user.activities_rated}
																	className={classes.indicatorsSmall} />
															</Grid>
														</Grid>
													</Grid>
													{user.most_recent_activity ?
														<Grid item className={`${classes.lastActionSmall} ${classes.secondary}`}>
															{this.getText({
																type: user.most_recent_activity.type,
																category: user.most_recent_activity.category,
																withName: user.most_recent_activity.recipient ?
																	user.most_recent_activity.recipient.name ||
																	user.most_recent_activity.recipient.phone ||
																	user.most_recent_activity.recipient.email : null
															})}
															<Timestamp time={user.most_recent_activity.date} className={classes.inline} />
														</Grid> : null
													}
												</Grid>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
							{footer ?
								<TableFooter>
									<TableRow>
										<TableCell className={classes.footer} style={{right: isSmall ? 8 : null}}>
											{footerBtn}
										</TableCell>
									</TableRow>
								</TableFooter> : null
							}
						</Table>
				)}
			</Media>
		);
	}
}

UserEngagementTable.defaultProps = {
	largeTableSize: "sm"
};

UserEngagementTable.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	theme: PropTypes.object.isRequired,
	users: PropTypes.array,
	footer: PropTypes.object,
	sortHandler: PropTypes.func,
	sortDir: PropTypes.string,
	sortBy: PropTypes.string,
	forceSmall: PropTypes.bool,
	largeTableSize: PropTypes.string
};

export default withStyles(styles)(withWidth()(withTheme(UserEngagementTable)));
