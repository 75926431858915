import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {RectShape} from 'react-placeholder/lib/placeholders';

import Constants from "../../utils/Constants";

import { withStyles, Grid, Divider } from '@material-ui/core';

const styles = theme => ({
	itemRow: {
		padding: theme.spacing(1.875)
	},

	clickable: {
		cursor: 'pointer',

		"&:hover": {
			backgroundColor: theme.palette.grey[200]
		}
	},

	chevron: {
		marginTop: theme.spacing(.5),
		color: theme.palette.grey["A200"]
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
export const gridPlaceHolder = (
	<div style={{paddingBottom: 20, width: "100%"}}>
		<div style={{paddingTop: 20, display: "flex"}}>
			<RectShape style={{height: 50, width: 100}} color={placeholderColor}><span></span></RectShape>
			<RectShape style={{height: 50, marginRight: "0px !important"}} color={placeholderColor}><span></span></RectShape>
		</div>
		<div style={{paddingTop: 20, display: "flex"}}>
			<RectShape style={{height: 50, width: 100}} color={placeholderColor}><span></span></RectShape>
			<RectShape style={{height: 50, marginRight: "0px !important"}} color={placeholderColor}><span></span></RectShape>
		</div>
	</div>
);

class ReportItemGrid extends Component {
	clickHandler(itemId) {
		let itemClickPath = this.props.itemClickPath;
		if (itemClickPath) {
			this.props.history.push(`${itemClickPath}/${itemId}`);
		}
	}

	render() {
		let {classes, items, contentRenderer, itemClickPath, noDivider, centerGrid} = this.props;
		return (
			<Grid container justify="flex-start" alignItems="stretch">
				{items.map((item, idx) => {
					return (
						<Grid item xs={12} key={`key-${item.id || idx}`}>
							<Grid container
								wrap="nowrap"
								alignItems={centerGrid ? "center" : "flex-start"}
								className={`${classes.itemRow} ${itemClickPath ? classes.clickable : ""}`}
								onClick={() => this.clickHandler(item.id)}>
								<Grid item xs={itemClickPath ? 11 : 12}>
									{contentRenderer(item)}
								</Grid>
								{itemClickPath ?
									<Grid item xs={1} align="right">
										<FontAwesomeIcon title="chevron" icon={["fas", "chevron-right"]} className={classes.chevron} />
									</Grid>
									: null
								}
							</Grid>
							{!noDivider ?
								<Divider className={classes.divider}/>
								: null
							}
						</Grid>
					);
				})}
			</Grid>
		);
	}
}

ReportItemGrid.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	contentRenderer: PropTypes.func.isRequired,
	itemClickPath: PropTypes.string,
	items: PropTypes.array,
	noDivider: PropTypes.bool,
	centerGrid: PropTypes.bool
};

export default withStyles(styles)(ReportItemGrid);
