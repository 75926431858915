import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Media from 'react-media';

import { withStyles, IconButton, InputAdornment, TextField, RootRef } from '@material-ui/core';

const styles = theme => ({
	fieldRoot: {
		fontSize: "1rem"
	},

	addCommentField: {
		width: "100%",
		color: theme.palette.primary.darkGrey,
		borderRadius: theme.spacing(2.5),
		backgroundColor: theme.palette.primary.lightTan,

		"& input": {
			padding: "9px 12px"
		}
	}
});

class AddCommentForm extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			comments: '',
			isFocused: false,
			canSubmit: false
		};

		this.field = React.createRef();

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.postClicked = this.postClicked.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	handleKeyPress(event) {
		if (event.charCode === 13) {
			event.preventDefault();
			this.submitForm(this.state);
			this.field.current.querySelector("input").blur();
		}
	}

	handleClick(event) {
		// so other components click handlers don't fire
		event.stopPropagation();
	}

	handleBlur(event) {
		setTimeout(() => {
			this.setState({
				isFocused: false
			});

			if (this.props.blurHandler) {
				this.props.blurHandler();
			}
		}, 150);
	}

	handleFocus(event) {
		this.setState({
			isFocused: true
		});

		if (this.props.focusHandler) {
			this.props.focusHandler();
		}
	}

	postClicked(event) {
		// so other components click handlers don't fire
		event.stopPropagation();
		this.submitForm(this.state);
	}

	submitForm(update) {
		if(update && update.comments) {
			this.props.addCommentHandler({
				comments: update.comments
			});
			this.setState({comments: ''});
		}
	}

	/**
	 * Handles changes to any form fields & updates corresponding state. Upon submit, get form values from
	 * state to pass into addCommentHandler. Probably should also figure out consistent way to prevent exploiits
	 * as well as validate submissions before sending to backend...
	 * @param  {event} event Form control change event
	 */
	handleChange(event) {
		let update = {},
			value = event.target.value;

		update[event.target.name] = value;
		update.isFocused = true;
		update.canSubmit = value ? true : false;

		this.setState(update);
	}

	render() {
		let {classes} = this.props,
			{isFocused, canSubmit} = this.state;

		return (
			<form noValidate autoComplete="off">
				<Media query={{minWidth: 365}}>
					{matches =>
						<RootRef rootRef={this.field}>
							<TextField
								name="comments"
								placeholder={matches ? "Add a comment..." : "Add comment"}
								onClick={this.handleClick}
								onKeyPress={this.handleKeyPress}
								onChange={this.handleChange}
								onFocus={this.handleFocus}
								onBlur={this.handleBlur}
								value={this.state.comments}
								className={classes.addCommentField}
								margin="normal"
								InputProps={{
									disableUnderline: true,
									classes: {root: classes.fieldRoot},
									endAdornment: isFocused ? (
										<InputAdornment position="end">
											<IconButton
												onClick={this.postClicked}
												color="primary"
												disabled={!canSubmit}
												aria-label="Add Comment"
												tabIndex={0}>
												<FontAwesomeIcon icon={["fal", "paper-plane"]} size="xs" />
											</IconButton>
										</InputAdornment>
									) : null
								}}
							/>
						</RootRef>
					}
				</Media>
			</form>
		);
	}
}

AddCommentForm.propTypes = {
	classes: PropTypes.object.isRequired,
	addCommentHandler: PropTypes.func,
	focusHandler: PropTypes.func,
	blurHandler: PropTypes.func
};

export default withStyles(styles)(AddCommentForm);
