import React, { Component } from 'react';
import DeepMerge from "../utils/DeepMerge";

import { Menu as MUIMenu } from '@material-ui/core';

class Menu extends Component {
	render() {
		let merged = DeepMerge.merge({
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center'
			},
			transformOrigin: {
				vertical: 'top',
				horizontal: 'center'
			},
			getContentAnchorEl: null
		}, this.props);

		return (
			<MUIMenu {...merged}></MUIMenu>
		);
	}
}

export default Menu;