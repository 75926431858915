import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {RectShape, TextRow} from 'react-placeholder/lib/placeholders';

import ReportItemPaper from "./ReportItemPaper";
import GSuiteEngagementReport from "./GSuiteEngagementReport";
import Constants from "../../utils/Constants";

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const placeHolder = (
	<div style={{padding: 10}}>
		<TextRow color={placeholderColor} style={{marginTop: 0}}><span></span></TextRow>
		<RectShape style={{height: "25rem", marginTop: 10}} color={placeholderColor}><span></span></RectShape>
	</div>
);

class GSuiteEngagementReportItem extends Component {
	render() {
		let {engagement, fidoError, previousDays} = this.props;

		return (
			<ReportItemPaper
				fidoError={fidoError}
				placeHolder={placeHolder}
				title="Email, Chat & Doc Engagement"
				subtitle="The most active days and times of the week for emails, chats and document engagement."
				ready={engagement !== null}>
				<GSuiteEngagementReport
					previousDays={previousDays}
					engagement={engagement} />
			</ReportItemPaper>
		);
	}
}

GSuiteEngagementReportItem.propTypes = {
	engagement: PropTypes.object,
	fidoError: PropTypes.bool,
	previousDays: PropTypes.number
};

export default GSuiteEngagementReportItem;
