import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FullscreenMessage from '../components/FullscreenMessage';
import Fido from '../utils/Fido';

class Logout extends Component {
	constructor(props, context) {
		super(props, context);

		this.fido = new Fido();
	}

	componentDidMount() {
		this.props.updateHeader({title: "sign out", primary: true});
		this.fetchData();
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.props.resetHeader();
	}

	fetchData(opts) {
		this.fido.fetch(this.props.location.pathname, opts)
			.then((data) => {
				this.props.logoutCallback();
				this.props.history.push('/login');
			})
			.catch(error => this.props.showSnackbar(error.message));
	}

	render() {
		return (
			<FullscreenMessage message="Signing Out..." />
		);
	}
}

Logout.propTypes = {
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func,
	logoutCallback: PropTypes.func.isRequired
};

export default Logout;