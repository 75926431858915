import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper } from '@material-ui/core';

const styles = theme => ({
	paper: {
		padding: theme.spacing(2)
	},

	img: {
		width: "100%"
	}
});

class ReportScreenShot extends Component {
	render() {
		let {classes, image, noPaper} = this.props,
			img = <img src={image} className={classes.img} alt="" />;

		return (noPaper ? <div>{img}</div> : <Paper className={classes.paper}>{img}</Paper>);
	}
}

ReportScreenShot.propTypes = {
	classes: PropTypes.object.isRequired,
	image: PropTypes.string.isRequired,
	noPaper: PropTypes.bool
};

export default withStyles(styles)(ReportScreenShot);