import DeepMerge from "./DeepMerge";
import MomentCore from 'moment';
import Highcharts from 'highcharts';

Highcharts.setOptions({
	lang: {
		thousandsSep: ','
	}
});

class HighchartsConfig {
	static merge(...objects) {
		let merged,
			options = objects[1]; // you can pass in a json object of options

		const baseConfig = {
			credits: {enabled: false},
			legend: {
				enabled: false,
				itemStyle: {
					cursor: 'default'
				}
			},
			yAxis: {
				min: 0,
				minRange: 0.1,
				startOnTick: true,
				endOnTick: true,
				title: {
					style: {
						fontSize: "15px"
					}
				}
			},
			chart: {
				backgroundColor: null,
				style: {
					fontWeight: 100,
					fontFamily: "system-ui, -apple-system"
				}
			},
			plotOptions: {
				series: {
					events: {
						legendItemClick: function(e) {
							e.preventDefault();
						}
					}
				},
				column: {
					minPointLength: 3, // always show bar even when 0`
				},
				line: {
					softThreshold: false
				},
				spline: {
					softThreshold: false
				}
			},
			tooltip: {
				style: {
					color: "#2d2d2d"
				},
				backgroundColor: "#fff",
				headerFormat: "",
				pointFormatter: function() {
					let numFormatter = new Intl.NumberFormat();
					let date = new MomentCore(this.name || this.x),
						header = '<span style="font-weight: bold">' + date.format("MMM D") + '</span><br/>',
						content = `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${numFormatter.format(this.y)}</b><br/>`;
					return header + content;
				}
			}
		};

		// if multiple axis', don't merge
		if (Array.isArray(objects[0].yAxis)) {
			baseConfig.yAxis = objects.yAxis;
		}

		merged = DeepMerge.merge(baseConfig, ...objects);

		if (objects[0].xAxis.type === "datetime") {
			if (objects[0].xAxis.labels == null) {
				objects[0].xAxis.labels = {};
			}
			objects[0].xAxis.labels.format = "{value:%b %e}";

			if (options && options.modifyTickInterval && options.previousDays) {
				objects[0].xAxis.tickInterval = (24 * 3600 * 1000) * (options.previousDays >= 90 ? 8 :
					options.previousDays >= 60 ? 6 : options.previousDays >= 30 ? 3 : 1);
			}
		}

		return merged;
	}

	static dateFormatter() {
		let date = new MomentCore(this.value);
		return date.format("MMM D");
	}
}

export default HighchartsConfig;