import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReportItemPaper from "./ReportItemPaper";
import ReportItemGrid, { gridPlaceHolder } from "./ReportItemGrid";

import { withStyles, Typography, Button } from '@material-ui/core';

const styles = theme => ({
	button: {
		position: "absolute",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(.45),
		bottom: theme.spacing(.5)
	},

	noData: {
		marginTop: "15%",
		textAlign: "center"
	}
});

class ReportItemGridWrapper extends Component {
	render() {
		let {classes, items, fidoError, title, subtitle, taller, itemClickPath, history,
			contentRenderer, buttonClickHandler, buttonText, noDataMessage} = this.props;

		return (
			<ReportItemPaper
				ready={items != null}
				fidoError={fidoError}
				placeHolder={gridPlaceHolder}
				title={title}
				subtitle={subtitle}
				taller={taller}>
				{items && items.length ?
					<div>
						<ReportItemGrid
							items={items}
							history={history}
							itemClickPath={itemClickPath}
							contentRenderer={contentRenderer} />
						<Button
							size="small"
							color="primary"
							className={classes.button}
							onClick={buttonClickHandler}>{buttonText}</Button>
					</div>
					:
					<Typography color="textSecondary" className={classes.noData}>
						{noDataMessage}
					</Typography>
				}
			</ReportItemPaper>
		);
	}
}

ReportItemGridWrapper.propTypes = {
	classes: PropTypes.object.isRequired,
	contentRenderer: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	subtitle: PropTypes.string,
	taller: PropTypes.bool,
	itemClickPath: PropTypes.string,
	buttonClickHandler: PropTypes.func,
	buttonText: PropTypes.string,
	noDataMessage: PropTypes.string,
	items: PropTypes.array,
	fidoError: PropTypes.bool
};

export default withStyles(styles)(ReportItemGridWrapper);
