import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Grid,
	Paper,
	Typography,
	Link,
	withStyles,
	withWidth
} from "@material-ui/core";

import ReactPlaceholder from 'react-placeholder';

import Fido from '../utils/Fido';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import DeconstructActivity from '../utils/DeconstructActivity';
import Constants from "../utils/Constants";
import SourceIndicator from '../components/SourceIndicator.js';
import Timestamp from '../components/Timestamp.js';
import AssociationsPanel from '../components/AssociationsPanel.js';
import ViewOriginalButton from "../components/ViewOriginalButton";
import ActivityList from "../components/ActivityList";
import RestoreBanner from "../components/RestoreBanner";
import Heading from "../components/Heading";

// TEMP
//import GDocsThumbnail from "../assets/EXAMPLE-gdoc-thumbnail.png";

const styles = theme => ({
	responsiveGridContainer: {
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(1),
		}
	},

	responsiveGridSection: {
		[theme.breakpoints.up('sm')]: {
			margin: theme.spacing(2),
		},

		[theme.breakpoints.down('xs')]: {
			borderRadius: 0
		}
	},

	sourceIconItem: {
		paddingRight: theme.spacing(1)
	},

	associations: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2)
		},

		[theme.breakpoints.down('xs')]: {
			marginTop: 0
		}
	},

	viewOrigButton: {
		marginRight: theme.spacing(1.5),
		marginBottom: theme.spacing(1)
	},

	/*contentSnapshot: {
		width: 250,
		height: 200,
		overflow: "hidden",
		display: "flex",
		justifyContent: "center",
		marginTop: theme.spacing(1),
		borderRadius: theme.spacing(.5),
		border: `1px solid ${theme.palette.grey[400]}`
	},*/

	linkIcon: {
		marginLeft: theme.spacing(.5)
	},

	heading: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},

	thumbnailWrapper: {
		border: "1px solid #dadce0",
		borderRadius: 6,
		overflow: "hidden",
		minHeight: 210,
		margin: theme.spacing(1.5)
	},

	thumbnailContainer: {
		height: 120,
		overflow: "hidden",
		backgroundColor: "#f5f5f5"
	},

	thumbnail: {
		boxShadow: "0 0 6px 0 rgba(0,0,0,0.12)",
		margin: "6% 7% 0 7%",
		width: "86%",
		height: "auto"
	},

	noThumbnaill: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: theme.palette.text.secondary
	},

	titleSection: {
		minHeight: 70,
		borderTop: "1px solid #dadce0",
		padding: "13px 16px"
	},

	currentRevision: {
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(2)
		}
	}
});

class GroupedActivity extends Component {
	constructor(props, context) {
		super(props, context);
		this.backUrl = (props.history.length < 2) ? "/activities" : null;

		this.state = {
			activities: [],
			groupedActivity: null,
			isActivityDeleted: false
		};

		this.addCommentThen = this.addCommentThen.bind(this);
		this.deleteThen = this.deleteThen.bind(this);
		this.restoreMissing = this.restoreMissing.bind(this);
		this.activitiesUpdated = this.activitiesUpdated.bind(this);
		this.handleRestoreAndSave = this.handleRestoreAndSave.bind(this);
	}

	// since Activity uses IndividualActivity for placeholder, GroupedActivity only gets mounted once
	componentDidMount() {
		let {authenticatedUser, updateHeader, location, data} = this.props;

		if (authenticatedUser) {
			updateHeader({
				primary: false,
				backUrl: this.backUrl,
				title: `${Constants.SOURCES.GSUITE_DISPLAY_NAMES[data.activity.source_id]} Details`});
		} else {
			updateHeader({
				signInUrl: location.pathname.replace('/v/', `/${Constants.SPA_PATH}/activities/rewrite/`)
			});
		}

		this.groupedActivityUpdated(data);
	}

	removeSourceName(activities) {
		// so there's no icon
		activities.forEach(item => {
			item.source.name = null;
		});

		return activities;
	}

	isActivityDeleted(activity) {
		if (activity.hidden_for_users) {
			let prevRemoved,
				{authenticatedUser} = this.props,
				hiddenFor = authenticatedUser ? authenticatedUser.id : null;

			prevRemoved = activity.hidden_for_users.find((obj) => {
				return obj.user.id === hiddenFor;
			});

			return prevRemoved ? true : false;
		}
		return false;
	}

	groupedActivityUpdated(data) {
		this.setState({
			groupedActivity: data.activity,
			isActivityDeleted: this.isActivityDeleted(data.activity),
			activities: this.removeSourceName(data.activity.related_activities)
		});
	}

	activitiesUpdated(activities) {
		this.setState({activities: this.removeSourceName(activities)});
	}

	addCommentThen(data) {
		let activity = data.activity,
			activities = this.state.activities,
			index = activities.findIndex((item) => {
				return item.id === activity.id;
			});

		activities[index] = activity;
		this.activitiesUpdated(activities);
	}

	deleteThen(activities) {
		this.activitiesUpdated(activities);
		if (activities && activities.length === 0) {
			this.props.history.goBack();
		}
	}

	handleRestoreAndSave() {
		let {location, showSnackbar} = this.props,
			activityId = location.pathname.substring(location.pathname.lastIndexOf("/")+1);

		if(activityId) {
			let poodle = new Fido();
			poodle.fetch(`/activities/${activityId}/undelete`, {
				method: "PUT",
			}, 'Restoring...', 'Restored.')
				.then(data => {
					if(data && data.activity) {
						if(!data.activity.flagged) {
							data.activity.flagged = true;
							showSnackbar(false);

							let grizzly = new Fido();
							grizzly.fetch(`/activities/${activityId}/save`, {
								method: "PUT"
							}, "Saving...", "Saved.")
								.then((data) => {
									this.groupedActivityUpdated(data);
								})
								.catch(this.handleActivityUpdateError);
						}

						this.groupedActivityUpdated(data);
					}
				})
				.catch(error => showSnackbar(error.message));
		}
	}

	handleActivityUpdateError = (error) => {
		if(error.message === "404 Not Found") {
			this.props.showSnackbar({
				message: "Not found. Outdated?",
				duration: false
			});
		} else {
			this.props.showSnackbar(error.message);
		}
	}

	restoreMissing() {
		// reload data if can't find activity to restore
		/*if (Object.keys(this.state.filter).length) {
			this.setFidoConfigOpts();
		}
		this.fetchData();*/
		console.log("GroupedActivity.restoreMissing: CAN'T FIND ACTIVITY");
	}

	render() {
		let {groupedActivity, activities, isActivityDeleted} = this.state,
			{classes, width, location, history, fidoError, showSnackbar} = this.props,
			ready = groupedActivity != null,
			isGDoc = groupedActivity && groupedActivity.source.id === Constants.SOURCES.IDS.GOOGLE_DOCS;

		return (
			<DeconstructActivity
				activity={groupedActivity || null}
				render={props => {
					let {associations, modifiedTime, sourceIcon, filename, flagged, snapshot, thumbnail} = props,
						currentRevisionCard = (
							<Paper className={classes.responsiveGridSection}>
								<div className={classes.currentRevision}>
									<ReactPlaceholder type='text' rows={2} ready={ready} showLoadingAnimation={!fidoError}>
										<Heading title="Current Revision" className={classes.heading}/>
										<div className={classes.thumbnailWrapper}>
											<div className={`${classes.thumbnailContainer} ${!thumbnail ? classes.noThumbnaill : null}`}>
												{thumbnail ? (
													<Link href={snapshot} target="_blank">
														<img src={thumbnail}
															width="220" height="310"
															className={classes.thumbnail} alt="document thumbnail" />
													</Link>
												) : <Typography variant="subtitle1">Not Available</Typography>}
											</div>
											<div className={classes.titleSection}>
												<Grid container
													justify="flex-start"
													alignItems="flex-start"
													wrap="nowrap"
													className={classes.docSourceInfo}>
													<Grid item className={classes.sourceIconItem}>
														<SourceIndicator {...sourceIcon} />
													</Grid>
													<Grid item xs={12}>
														<Typography>{filename}</Typography>
													</Grid>
												</Grid>
												<div>
													<Typography color="textSecondary">Last edited on</Typography>
													<Timestamp time={modifiedTime} relative={false} reducedSize={false} />
												</div>
											</div>
										</div>
										<Grid container justify="flex-end">
											<Grid item>
												<ViewOriginalButton
													offset="none"
													postLink={snapshot}
													icon={["fal", "file-pdf"]}
													isGDoc={true}
													className={classes.viewOrigButton} />
											</Grid>
										</Grid>
									</ReactPlaceholder>
								</div>
							</Paper>
						);

					return (
						<div>
							<RestoreBanner
								flagged={flagged}
								show={isActivityDeleted}
								handleRestore={this.handleRestoreAndSave} />
							<div className={classes.responsiveGridContainer}>
								<Grid container>
									<Grid item md={8} xs={12}>
										{width.includes("sm") || width.includes("xs") ? currentRevisionCard : null}
										<ActivityList
											showCategory
											suppressNavigation
											hasMoreItems={false}
											history={history}
											location={location}
											expandContent={isGDoc}
											showSnackbar={showSnackbar}
											addCommentThen={this.addCommentThen}
											onRestoreMissing={this.restoreMissing}
											rateThen={this.activitiesUpdated}
											actionBtnThens={{
												remove: this.deleteThen,
												save: this.activitiesUpdated,
												share: this.activitiesUpdated
											}}
											allowViewMoreComments
											numberOfViewableComments={3}
											activities={activities}
											activitiesUpdated={this.activitiesUpdated}
											itemLowerButtons={[{
												key: "archive-doc-btn",
												size: "small",
												color: "primary",
												target: "_blank",
												label: "Doc Snapshot",
												hrefAttr: "revSnapshot",
												hideIfHrefAttr: revSnapshot => revSnapshot == null,
												icon: {
													title: "view doc snapshot",
													icon: ["fal", "file-pdf"],
													className: classes.linkIcon
												}
											}]} />
									</Grid>
									<Grid item md={4} xs={12}>
										{!width.includes("sm") && !width.includes("xs") ? currentRevisionCard : null}
										<AssociationsPanel
											associations={associations}
											className={`${classes.responsiveGridSection} ${classes.associations}`} />
									</Grid>
								</Grid>
							</div>
						</div>
					);
				}}/>
		);
	}
}

GroupedActivity.propTypes = {
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	classes: PropTypes.object.isRequired,
	width: PropTypes.any.isRequired,
	location: PropTypes.object,
	history: PropTypes.object,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	data: PropTypes.object,
	fidoError: PropTypes.bool,
	source_id: PropTypes.number
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <GroupedActivity {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "GroupedActivity";

export default withStyles(styles)(withWidth()(React.forwardRef(dNameFunc)));