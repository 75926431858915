import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Fido from '../../utils/Fido';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import OverviewReportLayout from "../components/OverviewReportLayout";
import SparkChartReportItem from "../components/SparkChartReportItem";
import AssociatedDigitalPropertiesReportItem from "../components/AssociatedDigitalPropertiesReportItem";
import RecentPostsItem from "../components/RecentPostsItem";
import TrendingTopicsReportItem from "../components/TrendingTopicsReportItem";
import StudentSentimentReportItem from "../components/StudentSentimentReportItem";
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";
import Constants from '../../utils/Constants';
import DeepMerge from "../../utils/DeepMerge";

//import {sentimentData} from "../../data/reports";

class SocialMediaOverviewReport extends Component {
	constructor(props, context) {
		super(props, context);

		this.sparkCardsFido = new Fido();
		this.propertiesFido = new Fido();
		this.recentFido = new Fido();
		this.topicsFido = new Fido();
		this.discussionsFido = new Fido();
		this.sentimentFido = new Fido();
		this.moreInfoFido = new Fido();

		let {location, days, authenticatedUser} = this.props,
			accountParam = Fido.getSearchParam("account", location.search),
			filter = {
				previousDays: Number(Fido.getSearchParam("previousDays", location.search)) || days,
				account: accountParam ? (accountParam.split(",")).map(item => Number(item)) : authenticatedUser.childAccounts.map(item => item.id)
			};

		this.state = {
			filter,
			defaultFilter: Object.assign({}, filter),

			totalPosts: null,
			postsAmountChange: null,
			postsPercentageChange: null,

			totalImages: null,
			imagesAmountChange: null,
			imagesPercentageChange: null,
			postsAndImages: [],

			totalAlerts: null,
			alertsAmountChange: null,
			alertsPercentageChange: null,
			alerts: [],

			totalDiscussions: null,
			discussionsAmountChange: null,
			discussionsPercentageChange: null,
			discussions: [],

			totalAlertsViewed: null,
			alertsViewedAmountChange: null,
			alertsViewedPercentageChange: null,
			alertViews: [],

			alertRatio: null,
			ssiAlertRatio: null,

			sparkCardsFidoError: false,

			digitalProperties: null,
			propertiesFidoError: false,

			activities: null,
			recentFidoError: false,

			topicData: null,
			topicsFidoError: false,

			discussionData: null,
			discussionFidoError: false,

			sentimentData: null,
			sentimentFidoError: false,

			propertiesMoreInfoData: null
		};

		this.executeFilter = this.executeFilter.bind(this);
		this.propertiesMoreInfoClicked = this.propertiesMoreInfoClicked.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.props.updateHeader({title: "social media", primary: true});
		this.loadData();
	}

	componentWillUnmount() {
		this.dropThem();
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.search === "" && prevProps.location.search !== "") {
			let {filter} = this.state,
				{authenticatedUser} = this.props;

			filter.account = authenticatedUser.childAccounts.map(item => item.id);
			this.setState({
				filter: filter,
				defaultFilter: Object.assign({}, filter)
			});
		}
	}

	dropThem() {
		this.sparkCardsFido.dropIt();
		this.propertiesFido.dropIt();
		this.recentFido.dropIt();
		this.topicsFido.dropIt();
		this.discussionsFido.dropIt();
		this.sentimentFido.dropIt();
		this.moreInfoFido.dropIt();
	}

	loadData() {
		this.setState({
			postsAndImages: [],
			alerts: [],
			alertViews: [],
			discussions: [],
			sentimentData: null,
			topicData: null,
			discussionData: null,
			activities: null
		}, () => {
			// Doesn't do deep copy, so sub-objects are by ref :/
			const filter = Object.assign({}, this.state.filter);
			this.loadSparkData(filter);
			this.loadDigitalProperties();
			this.loadRecentActivities(filter);
			this.loadTopicDiscussionData(filter);

			// only K12
			if (this.props.authenticatedUser.verticalId === Constants.ACCOUNT.VERTICAL.K12) {
				this.loadSentimentData(filter);
			}
		});
	}

	async loadSparkData(filter) {
		let data = await this.sparkCardsFido.fetch("/reports/socialmedia/spark", {query: filter})
			.catch(error => {
				this.setState({sparkCardsFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState(data);
		}
	}

	async loadDigitalProperties() {
		let data = await this.propertiesFido.fetch("/reports/digital-properties")
			.catch(error => {
				this.setState({propertiesFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState(data);
		}
	}

	async loadRecentActivities(filter) {
		let data = await this.recentFido.fetch("/activities", DeepMerge.merge({
			query: {
				limit: 5,
				source: "social_media"
			}}, {query: filter}))
			.catch(error => {
				this.setState({recentFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState(data);
		}
	}

	async loadTopicDiscussionData(filter) {
		//if (opts.query.previousDays === 30) {
		let topicOpts = {query: {
				sortBy: "posts",
				source: "social_media",
				previousDays: 30
			}},
			discussionOpts = DeepMerge.merge({
				query: {
					limit: 5,
					category: "discussions",
					source: "social_media"
				}}, {query: filter}
			);

		let [topics, discussionResults] = await Promise.all([
			this.topicsFido.fetch("/trending-topics", topicOpts)
				.catch(error => {
					this.setState({topicsFidoError: true});
					return this.props.showSnackbar(error.message);
				}),
			this.discussionsFido.fetch("/activities", discussionOpts)
				.catch(error => {
					this.setState({discussionFidoError: true});
					return this.props.showSnackbar(error.message);
				})
		]);

		if (this._isMounted === true) {
			this.setState({topicData: topics});
		}

		if (this._isMounted === true) {
			this.setState({discussionData: discussionResults ? discussionResults.activities : []});
		}
	}

	async loadSentimentData(filter) {
		let url = Constants.SERVICES.SENTIMENT.getUrl(),
			data = await this.sentimentFido.fetch(url, {cors: true, query: {previousDays: filter.previousDays}})
				.catch(error => {
					this.setState({sentimentFidoError: true});
					return this.props.showSnackbar(error.message);
				});

		if (data) {
			this.setState({sentimentData: data.data});
		}

		// TEMP
		/*let blee = await this.sentimentFido.fetch("/reports/digital-properties")
			.catch(error => {
				this.setState({sentimentFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		this.setState({sentimentData: sentimentData});*/
	}

	getFilterOpts(filterObj) {
		let opts,
			{filter} = this.state;

		filter.account = [];

		if (!filterObj.previousDays) {
			delete filter.previousDays;
		}

		Number(filter.previousDays);

		opts = DeepMerge.merge({}, filter, filterObj);

		// if all selected, don't include as url param
		if (Array.isArray(opts.account) && opts.account.length === this.props.authenticatedUser.childAccounts.length) {
			opts.account = [];
		}

		return opts;
	}

	executeFilter(filterObj) {
		let opts = this.getFilterOpts(filterObj);
		this.dropThem();
		this.props.history.replace(Fido.buildPath("/socialmedia-report", opts));
		this.setState({filter: opts}, this.loadData);
	}

	propertiesMoreInfoClicked(key) {
		this.setState({propertiesMoreInfoData: null}, () => {
			this.moreInfoFido.fetch("/reports/digital-properties/more-info", {query: {source: key}})
				.then((data) => {
					if (data) {
						this.setState({propertiesMoreInfoData: data});
					}
				})
				.catch(error => {
					this.setState({moreInfoError: true});
					return this.props.showSnackbar(error.message);
				});
		});
	}

	render() {
		const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 2});
		let {history, csm, authenticatedUser, location, days, showSnackbar} = this.props,
			{filter, sparkCardsFidoError,
				postsAndImages, alerts, alertViews, discussions,
				totalPosts, postsPercentageChange, postsAmountChange,
				imagesAmountChange, imagesPercentageChange, totalImages,
				totalAlerts, alertsAmountChange, alertsPercentageChange,
				totalDiscussions, discussionsAmountChange, discussionsPercentageChange,
				totalAlertsViewed, alertsViewedPercentageChange, alertsViewedAmountChange,
				alertRatio, ssiAlertRatio,
				digitalProperties, propertiesFidoError,
				topicData, discussionData, topicsFidoError, discussionFidoError,
				activities, recentFidoError,
				sentimentData, sentimentFidoError, defaultFilter, propertiesMoreInfoData} = this.state,
			previousDays = Number(filter.previousDays || days),
			isK12 = authenticatedUser.verticalId === Constants.ACCOUNT.VERTICAL.K12,
			recentAlertsTitle = (
				<div>
					<FontAwesomeIcon title="recent alerts" icon={["fas", "wifi"]} transform={{ rotate: 90 }} /> Most Recent Alerts
				</div>
			),
			recentDiscussionsTitle = (
				<div>
					<FontAwesomeIcon title="conversation bubbles" icon={["far", "comments-alt"]} /> Most Recent Discussions
				</div>
			),
			sparkCards = [
				<ReportDataMasseuse
					key={"spark0"}
					dataConfig={[{data: postsAndImages, key: "period", valueKey: "associations"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={sparkCardsFidoError}
							title="Associated Posts"
							value={totalPosts}
							data={data[0]}
							change={postsAmountChange}
							previousDays={previousDays}
							percentChange={postsPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark1"}
					dataConfig={[{data: postsAndImages, key: "period", valueKey: "images"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={sparkCardsFidoError}
							title="Images Scanned"
							value={totalImages}
							data={data[0]}
							change={imagesAmountChange}
							previousDays={previousDays}
							percentChange={imagesPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark2"}
					dataConfig={[{data: alerts, key: "period", valueKey: "alerts"}]}
					render={data =>
						<SparkChartReportItem
							invertChevron
							fidoError={sparkCardsFidoError}
							title="Alerts"
							value={totalAlerts}
							data={data[0]}
							change={alertsAmountChange}
							previousDays={previousDays}
							percentChange={alertsPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark3"}
					dataConfig={[{data: alerts, key: "period", valueKey: "alert_ratio"}]}
					render={data =>
						<SparkChartReportItem
							invertChevron
							valueIsPercentage
							excludePerDay
							fidoError={sparkCardsFidoError}
							title="Alert Ratio"
							value={alertRatio}
							data={data[0]}
							caption={`Benchmark: ${numFormatter.format(ssiAlertRatio)}%`}
							forceGreen={alertRatio < ssiAlertRatio} />
					} />,
				<ReportDataMasseuse
					key={"spark4"}
					dataConfig={[{data: alertViews, key: "period", valueKey: "alerts_viewed"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={sparkCardsFidoError}
							title="Alerts Viewed"
							value={totalAlertsViewed}
							data={data[0]}
							change={alertsViewedAmountChange}
							percentChange={alertsViewedPercentageChange}
							forceGreen={alertsViewedPercentageChange === 100}
							previousDays={previousDays} />
					} />,
				<ReportDataMasseuse
					key={"spark5"}
					dataConfig={[{data: discussions, key: "period", valueKey: "alerts"}]}
					render={data =>
						<SparkChartReportItem
							invertChevron
							fidoError={sparkCardsFidoError}
							title="Discussions"
							value={totalDiscussions}
							data={data[0]}
							change={discussionsAmountChange}
							percentChange={discussionsPercentageChange}
							previousDays={previousDays} />
					} />
			];

		return (
			<OverviewReportLayout
				location={location}
				sparkCards={sparkCards}
				previousDays={previousDays}
				filterCallback={this.executeFilter}
				selectedAccounts={defaultFilter && defaultFilter.account}>
				{isK12 ?
					<StudentSentimentReportItem
						fullWidth
						data={sentimentData}
						previousDays={previousDays}
						fidoError={sentimentFidoError} />
					: null
				}
				{isK12 ?
					<div divider="true"></div>
					: null
				}
				<AssociatedDigitalPropertiesReportItem
					fullWidth
					csm={csm}
					properties={digitalProperties}
					fidoError={propertiesFidoError}
					showSnackbar={showSnackbar}
					moreInfoData={propertiesMoreInfoData}
					moreInfoHandler={this.propertiesMoreInfoClicked} />
				<div divider="true"></div>
				{topicData && topicData.length ?
					<TrendingTopicsReportItem
						fidoError={topicsFidoError}
						previousDays={previousDays}
						topics={topicData}
						viewAllClickHandler={() => history.push(`/activities?category=discussions&source=${Constants.SOURCES.SOCIAL_MEDIA}`)}
						overTimeClickHandler={() =>
							history.push(`/reports/trending-topics?previousDays=${previousDays}&source=${Constants.SOURCES.SOCIAL_MEDIA}`)
						} />
					:
					<RecentPostsItem
						activities={discussionData}
						history={history}
						fidoError={discussionFidoError}
						title={recentDiscussionsTitle}
						itemClickPath="/activities"
						buttonText="view all discussions"
						noDataMessage="There are no recent discussions for this time period."
						buttonClickHandler={() =>
							history.push(`/activities?category=discussions&source=${Constants.SOURCES.SOCIAL_MEDIA}`)
						} />
				}
				<RecentPostsItem
					activities={activities}
					history={history}
					fidoError={recentFidoError}
					title={recentAlertsTitle}
					itemClickPath="/activities"
					buttonText="view all alerts"
					noDataMessage="There are no recent alerts for this time period."
					buttonClickHandler={() =>
						history.push(`/activities?category=alerts&source=${Constants.SOURCES.SOCIAL_MEDIA}`)
					} />
			</OverviewReportLayout>
		);
	}
}

SocialMediaOverviewReport.defaultProps = {
	days: 30
};

SocialMediaOverviewReport.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	days: PropTypes.number,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	csm: PropTypes.object,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <SocialMediaOverviewReport {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "SocialMediaOverviewReport";

export default (React.forwardRef(dNameFunc));