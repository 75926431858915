import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import TruncateHtml from 'react-truncate-html';
import { withStyles, Button, Typography, RootRef, Grid } from '@material-ui/core';

const styles = theme => ({
	button: {
		marginTop: -4
	},

	blockButton: {
		marginLeft: theme.spacing(-1),
		display: 'block'
	},

	toggleBtn: {
		marginTop: theme.spacing(.5)
	},

	originalContent: {
		whiteSpace: "pre-line"
	}
});

class ReadMore extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			expanded: false,
			truncated: false,
			truncationNeeded: null
		};

		this.handleTruncate = this.handleTruncate.bind(this);
		this.toggleLines = this.toggleLines.bind(this);

		this.truncateHtmlWrapper = React.createRef();
	}

	componentDidMount() {
		if (this.props.preserveHtml) {
			this.isHtmlTruncated();
		}
	}

	isHtmlTruncated() {
		if (this.truncateHtmlWrapper.current) {
			let current = this.truncateHtmlWrapper.current,
				child = current.firstChild;

			if (this.props.html !== child.innerHTML && child.innerText.match(/.*[.]{3}$/ig)) {
				this.setState({truncationNeeded: true});
				this.handleTruncate(true);
			}
		} else {
			this.setState({truncationNeeded: false});
		}
	}

	handleTruncate(truncated) {
		if (this.state.truncated !== truncated) {
			this.setState({truncated});
		}
	}

	toggleLines(event) {
		event.preventDefault();
		event.stopPropagation();

		this.setState({
			expanded: !this.state.expanded
		});
	}

	render() {
		let {expanded, truncated, truncationNeeded} = this.state,
			{classes, children, more, less, lines, showInline, ellipsis, preserveHtml, html, htmlEllipsis, originalContent} = this.props,
			btnClassNames = `${showInline ? classes.button : classes.blockButton} ${preserveHtml ? classes.toggleBtn : ""}`,
			moreButton = <Button
				size="small"
				color="primary"
				className={btnClassNames}
				onClick={this.toggleLines}>{more}</Button>,
			lessButton = <Button
				size="small"
				color="primary"
				className={btnClassNames}
				onClick={this.toggleLines}>{less}</Button>;

		return (
			<div>
				{preserveHtml ?
					<RootRef rootRef={this.truncateHtmlWrapper}>
						{!expanded ?
							<div style={{position: "relative"}}>
								<TruncateHtml
									ellipsis={htmlEllipsis}
									lines={lines}
									dangerouslySetInnerHTML={{__html: html}} />
								{truncationNeeded ?
									<Grid container justify="flex-start">
										<Grid item>{moreButton}</Grid>
									</Grid>
									:
									originalContent ?
										<Grid container justify="flex-start">
											<Grid item>...{moreButton}</Grid>
										</Grid>
										: null
								}
							</div>
							:
							truncationNeeded || originalContent ?
								<div style={{position: "relative"}}>
									<Typography>{originalContent || html}</Typography>
									<Grid container justify="flex-start">
										<Grid item>{lessButton}</Grid>
									</Grid>
								</div> : null
						}
					</RootRef>
					:
					<div>
						<Truncate
							lines={!expanded && lines}
							ellipsis={(
								<span>
									{ellipsis}
									{showInline ?
										moreButton
										:
										<Grid container justify="flex-start">
											<Grid item>{moreButton}</Grid>
										</Grid>
									}
								</span>
							)}
							onTruncate={this.handleTruncate}>
							{expanded ?
								originalContent ?
									<span className={classes.originalContent}>{originalContent}</span>
									:
									children
								: <span>{children}{originalContent ? "..." : null}</span>  // not expanded
							}
						</Truncate>
						{!expanded && originalContent && !truncated ?
							showInline ?
								moreButton
								:
								<Grid container justify="flex-start">
									<Grid item>{moreButton}</Grid>
								</Grid>
							: null
						}
						{!truncated && expanded ?
							showInline ?
								lessButton
								:
								<Grid container justify="flex-start">
									<Grid item>{lessButton}</Grid>
								</Grid>
							: null}
					</div>
				}
			</div>
		);
	}
}

ReadMore.defaultProps = {
	lines: 3,
	more: 'Read More',
	less: 'Show Less',
	ellipsis: '...',
	htmlEllipsis: "..."
};

ReadMore.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.node,
	lines: PropTypes.number,
	less: PropTypes.string,
	more: PropTypes.string,
	showInline: PropTypes.bool,
	ellipsis: PropTypes.string,
	htmlEllipsis: PropTypes.string,
	preserveHtml: PropTypes.bool,
	html: PropTypes.string,
	originalContent: PropTypes.string
};

export default withStyles(styles)(ReadMore);
