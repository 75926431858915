import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportItemGridWrapper from "./ReportItemGridWrapper";

import Constants from "../../utils/Constants";
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";
import { Typography } from '@material-ui/core';

class TopOffensivePeople extends Component {
	render() {
		let {people, nameKey, title, noDataMessage, fidoError, history, buttonClickHandler, buttonText} = this.props;
		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
						<ReportItemGridWrapper
							ready={people != null && people.length > 0}
							fidoError={fidoError}
							title={title}
							items={people}
							history={history}
							//itemClickPath="/activities"
							buttonText={buttonText}
							noDataMessage={noDataMessage}
							buttonClickHandler={buttonClickHandler}
							contentRenderer={item => {
								let display;

								if (authenticatedUser.ghosting) {
									display = "Anonymized";
								} else {
									let match = item[nameKey].match(Constants.ACTIVITY.EMAIL.FROM_REGEX);

									// 0 is the full string, 1 is name, 2 is email
									if (match) {
										display = <span><b>{match[1].trim()}</b> {match[2]}</span>;
									} else {
										display = item[nameKey];
									}
								}

								return (
									<div>
										<Typography noWrap>{display}</Typography>
										<Typography noWrap color="textSecondary">{item.alerts} alerts</Typography>
									</div>
								);
							}} />
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

TopOffensivePeople.defaultProps = {
	buttonText: "view all email alerts"
};

TopOffensivePeople.propTypes = {
	history: PropTypes.object.isRequired,
	fidoError: PropTypes.bool,
	people: PropTypes.array,
	nameKey: PropTypes.string,
	title: PropTypes.string,
	noDataMessage: PropTypes.string,
	buttonClickHandler: PropTypes.func.isRequired,
	buttonText: PropTypes.string
};

export default TopOffensivePeople;