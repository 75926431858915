import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    withStyles,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
    LinearProgress,
} from '@material-ui/core';

const styles = theme => ({
	bottomHelperText: {
		display: "block",
		fontSize: "0.985rem",
		marginBottom: theme.spacing(2)
	},

	progress: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(7)
	},

	show: {
		display: "block"
	}
});

class LabeledRadioGroup extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			selected: props.value
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value !== this.props.value) {
			this.setState({selected: this.props.value});
		}
	}

	handleChange(event) {
		this.setState({ selected: event.target.value });
	};

	render() {
		let {selected} = this.state;
		let {classes, name, isLoading, disabled, topHelperText, bottomHelperText, buttonColor, buttons, onChange, ...rest} = this.props;

		return (
			<div>
				{topHelperText ? (
					<FormHelperText
						disabled={disabled}
						className={classes.show}>{topHelperText}</FormHelperText>
				) : null}
				{(!isLoading) ? (
					<div>
						<RadioGroup
							{...rest}
							name={name}
							value={selected}
							onChange={(e, v) => {e.target.value = v; this.handleChange(e); onChange(e);}}>
							{buttons.map((button, i) =>
								<FormControlLabel
									key={i}
									disabled={disabled}
									value={button.name}
									control={
										<Radio
											key={button.id}
											color={buttonColor || 'primary'}
											inputProps={{
												'aria-label': rest.label || '',
												'aria-describedby': `${rest.id}-helper-text`}} />
									}
									label={button.label || button.name}
								/>
							)}
						</RadioGroup>
						{bottomHelperText ? (
							<FormHelperText
								id={`${rest.id}-helper-text`}
								className={classes.bottomHelperText}
								disabled={disabled}>{bottomHelperText}</FormHelperText>
						) : null}
					</div>
				) : (
					<LinearProgress className={classes.progress} />
				)}
			</div>
		);
	}
}

LabeledRadioGroup.propTypes = {
	value: PropTypes.string,
	topHelperText: PropTypes.string,
	bottomHelperText: PropTypes.string,
	buttonColor: PropTypes.string,
	buttons: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool
};

export default withStyles(styles)(LabeledRadioGroup);
