import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

import AuthorizedUser from "../utils/AuthorizedUser";
import LoadingIndicator from "./LoadingIndicator";

import { List, ListSubheader, withStyles, withTheme } from '@material-ui/core';

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.down('xs')]: {
			backgroundColor: theme.palette.grey[200],
		}
	},

	header: {
		textTransform: "uppercase",
		borderRadius: theme.spacing(.5),
		backgroundColor: theme.palette.primary.lightTan,
		color: AuthorizedUser.wantsMoreContrast() ? theme.palette.accessibilityColors.text.secondary : null
	},

	listSection: {
		backgroundColor: 'inherit',
	},

	ul: {
		backgroundColor: 'inherit',
		padding: 0,
	}
});

class GroupedList extends Component {
	render() {
		let groupedData = {},
			{classes, items, grouper, render, loadMore, hasMore, lastPage} = this.props;

		items.map(item => {
			let group = grouper(item);

			if(!(group in groupedData)) {
				groupedData[group] = [];
			}

			groupedData[group].push(item);
			return item;
		});

		// If infinite scroll doesn't start loading early enough, can adjust by adding threshold parameter; default is 250(px)
		// from the bottom it starts loading next page; might make sense since already adjusting for screen height, use that
		// to update value based on screen height. The thinking being activities take up less height on wide screens so can fit
		// more vertically & can scroll through very quickly
		return (
			<List
				subheader={<li />}
				className={classes.root}>
				<InfiniteScroll
					initialLoad={false}
					pageStart={lastPage || 1}
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<LoadingIndicator key={items.length} />}>
					{/* section headers */}
					{Object.keys(groupedData).map(group => {
						let groupKey = group.replace(' ', '-');
						return (
							<li key={`section-${groupKey}`} className={classes.listSection}>
								{/* Each group is a list */}
								<ul className={classes.ul}>
									<ListSubheader component="h3" className={classes.header}>{group}</ListSubheader>
									{/* grouped items */}
									{groupedData[group].map((item, index) => render(item, groupKey, index))}
								</ul>
							</li>
						);
					})}
				</InfiniteScroll>
			</List>
		);
	}
}

GroupedList.defaultProps = {
	loadMore: ()=>{}
};

GroupedList.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	render: PropTypes.func.isRequired,
	loadMore: PropTypes.func,
	grouper: PropTypes.func.isRequired,
	items: PropTypes.array.isRequired,
	hasMore: PropTypes.bool,
	lastPage: PropTypes.number
};

export default withStyles(styles)(withTheme(GroupedList));
