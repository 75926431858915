
class FormHelper {
	static getChanged(event) {
		let target = event.target,
			value = target.value;

		if (target.type === "checkbox" && target.ignoreCheckbox !== true) {
			value = target.checked;
		}

		return {[target.name]: value};
	}
}

export default FormHelper;