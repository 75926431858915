import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PrivacyPolicy extends Component {
	componentDidMount() {
		this.props.updateHeader({
			title: "Privacy Policy"
		});
	}

	render() {
		return (
			<div style={{padding: 32}}>
				<h2 style={{textAlign: "center"}}>Privacy Policy: Social Sentinel Website <br/><br/></h2>
<p><strong>Privacy Policy: Social Sentinel Website</strong></p>
<p><strong>Last Modified: January 6, 2020 (effective January 1, 2020)</strong></p>
<p>Welcome to the Privacy Policy for the Social Sentinel Website ("Privacy Policy")</p>
<p><strong>AGREEMENT</strong></p>
<p>This Privacy Policy applies to your use of the Social Sentinel website located at www.socialsentinel.com, including all associated web pages (together, the "Site"). The terms “User” and “you” refer to any person who accesses or uses the Site. “Use” of the Site includes any actual or attempted access to or use of the Site. The Site is owned and operated by Social Sentinel, Inc. ("Social Sentinel," "we," "us," and "our"). This Privacy Policy should be read together with, and is incorporated into, the Social Sentinel Website Terms of Use ("Terms of Use," available <a title="Website Terms of Use" href="https://www.socialsentinel.com/legal">here</a>. Please refer to the Terms of Use on all issues related to accessing, using, transmitting, posting, and sharing information through the Site. Capitalized terms not otherwise defined in this Privacy Policy have the meaning ascribed to them in the Terms of Use.</p>
<p>We are committed to the protection of your Personal Information (as defined in herein). This Privacy Policy sets forth information about our collection and use of Personal Information that we collect online through the Site.</p>
<p>We reserve the right to, at any time and in our sole discretion, add to, change, update, or modify this Privacy Policy simply by posting such addition, change, update, or modification (together, "modification") on the Privacy Policy and updating the "Last Modified" date. Any such modification will be effective immediately upon posting. Your continued use of the Site shall constitute your acceptance of such modification. Because this Privacy Policy may change from time to time, it is important that you regularly review this Privacy Policy to ensure that you are updated as to any changes. This Privacy Policy, and any posted modifications to its terms, shall remain in full force and effect while you use the Site.</p>
<p>As provided in the Terms of Use, the Site is intended for access and use by United States residents only, and it is not intended for use by children.</p>
<p>If you are using the Site to access our Solutions, Products, or Services as a Client pursuant to an agreement between you and Social Sentinel (such as a Product Agreement or Services Agreement), then your use of the Solutions, Products, or Services is governed by the applicable Products or Services Agreement(s) entered into between the Client and Social Sentinel, which includes any applicable privacy-related provisions.</p>
<p><strong>How do you consent to use of your Personal Information?</strong></p>
<p>Your use of the Site and/or provision of your Personal Information via the Site constitutes your consent to our use, storage, processing, and transfer of that information in accordance with this Privacy Policy.</p>
<p>By becoming a Subscriber, you confirm that you have read and accepted the terms of this Privacy Policy. Without prejudice to the generality of the foregoing, you expressly and unequivocally consent to: (a) the collection and processing of your Personal Information by Social Sentinel in accordance with the indicated purposes and this Privacy Policy; and (b) the collection and processing of Personal Information on your behalf, as indicated herein.</p>
<p><strong>What is "Personal Information"?</strong></p>
<p>"Personal Information" includes any information that enables us to identify you, directly or indirectly, by reference to an identifier such as your name, identification number, location data, online identifier, or one or more factors specific to you. Personal Information includes pseudonymised Personal Information but excludes anonymous information or information that has had the identity of an individual permanently removed (“deidentified”).</p>
<p><strong>What Personal Information do we collect?</strong></p>
<p>We may collect the following information, which may include Personal Information, as follows:</p>
<p><u>Website Use</u>. We collect certain information automatically when you use the Site, such as IP addresses and domain names, browser type, history of pages viewed, and other usage information about your use of the Site.</p>
<p><u>Information you give us</u>. We collect information when you provide it to us, such as when you become a Subscriber (which occurs when you request a demo or request or access certain Content from the Site, as detailed in the Terms of Use) or communicate with us through the Site or otherwise (such as by email, phone, regular mail, or via our social media pages). The type of Personal Information that we may collect could include, but is not limited to:</p>
<ul>
<li>Your first name, last name, phone number, email address</li>
<li>Information about your employment, such as your employer’s name and state, and your title</li>
<li>Preference information, such as regarding the communications you receive from us</li>
<li>Any other information relating to you (or other individuals) that you may provide to us</li>
</ul>
<p>If you are providing Personal Information to us relating to a third party, you confirm that you have the consent of the third party to share such Personal Information with us and that you have made the information in this Privacy Policy available to the third party.</p>
<p>If you choose not to provide Social Sentinel with your Personal Information, you still may visit most of the Site. Please note, however, that you may be unable to access certain Content (as defined in the Terms of Use) or receive certain offerings otherwise provided.</p>
<p><strong>How do we collect Personal Information?</strong></p>
<p>We may collect Personal Information from you in the course of your use of the Site. For example, we may collect Personal Information from you when become a Subscriber. We may collect information from you when you contact or communicate with us (including through the Site, by email, or otherwise). We may collect your Personal Information if we monitor the Site. We may gather information about you when you provide it to us or interact with us directly, for instance when engaging with our staff. We may in certain cases collect or receive information about you from other sources, such as from an administrative user in your organization in order to make you a Subscriber.</p>
<p><strong>What are "cookies" and how does the Site use them?</strong></p>
<p>We use cookies to manage the Site. A cookie is a small file containing a unique identification number that is transferred from a website to the hard drive of your computer. This unique number identifies your web browser to us whenever you visit the Site. Cookies are used to store information to manage your website experience, such as your type of web browser. A cookie also can store preference information to customize your website experience. Cookies help us understand how people use the Site so we can design better services in the future.</p>
<p>You can adjust your web browser to disable cookies. Note that we may not be able to provide you with all the service or functionality you want on the Site if you choose to disable cookies. You also may remove cookies from your browser. Please refer to your browser’s help menu for instructions.</p>
<p><strong>How do we use the Personal Information we collect?</strong></p>
<p>We use your Personal Information for business purposes according to the terms of the Privacy Policy in effect at the time of our use. We will process your Personal Information, including sharing it with third parties, only when: (1) you have provided your consent; (2) the processing is necessary for the performance of a contract to which you are a party (including the Social Sentinel Terms of Use ("Terms of Use")); (3) we are required by law; (4) processing is required to protect your vital interests or those of another person or entity; or (5) processing is necessary for the purposes of our legitimate business interests.</p>
<p>We use Personal Information for the following purposes:</p>
<p><u>Information you provide</u>: We use Personal Information that you provide to us for the following purposes:</p>
<p>1. To administer and improve the Site;</p>
<p>2. To carry out our obligations arising from any agreement entered into between you and us, including the Terms of Use;</p>
<p>3. To enroll you as a Subscriber; to administer and update your Subscriber information and preferences;</p>
<p>4. To provide you with Content, information, products, or services you request from us;</p>
<p>5. To arrange and deliver Content, information, or other materials relevant to your subjects of interest;</p>
<p>6. To send you Social Sentinel Content, publications, and other information we believe is relevant and useful to you, where you have not opted out of receiving such information;</p>
<p>7. To send you third party information that we believe is relevant and useful to you, where you have given your consent;</p>
<p>8. To notify you about changes regarding the Site and/or to ask you to provide feedback;</p>
<p>9. To enable you to participate in Social Sentinel surveys or polls;</p>
<p>10. To administer and protect our business and the Site (including but not limited to troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data);</p>
<p>11. To prevent and detect fraud;</p>
<p>12. To protect and enforce our legal rights;</p>
<p>13. To comply with all applicable laws or legal processes, including providing information on individual Users to the appropriate governmental authorities where required by law enforcement or judicial authorities.</p>
<p><u>Site Use Information</u>: We use the aggregate, anonymous data we collect for the following purposes:</p>
<p>1. For Site administration;</p>
<p>2. To perform statistical analyses of the collective characteristics and behavior of visitors to the Site;</p>
<p>3. To measure User interests regarding specific areas of the Site, including Content;</p>
<p>4. To analyze how and where best to use our resources.</p>
<p>Without such data, we would not know which parts of the Site are the most popular, and we would not be able to change and update the Site and Content appropriately.</p>
<p>We will not use the Personal Information we collect for materially different, unrelated, or incompatible purposes without providing you notice.</p>
<p><strong>With whom do we share your Personal Information?</strong></p>
<p>Personal Information collected through the Site generally is collected and maintained solely by us or our contracted vendors. We may share Personal Information in the following ways:</p>
<p><u>We reserve the right to disclose and share Personal Information</u>:</p>
<p>1. With a third party if we have reason to believe that disclosing Personal Information is necessary to identify, contact, or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) our rights or property, other Site Users, or anyone else who could be harmed by such activities.</p>
<p>2. In response to a subpoena, warrant, or other court order, or when we believe in good faith that a law, regulation, subpoena, warrant, or other court order requires it – or authorizes us to do so – or to respond to an emergency situation.</p>
<p>3. With a third party in the event of a proposed or actual purchase, sale (including a liquidation, realization, foreclosure or repossession), lease, merger, amalgamation, or any other type of acquisition, disposal, transfer, conveyance, or financing of all or any portion of Social Sentinel, or of any of the business Social Sentinel, in order for you to continue to receive the same products and services from the third party.</p>
<p>4. With business advisors, such as accountants and lawyers, who assist us in carrying out our business activities.</p>
<p>5. With Social Sentinel affiliates or third parties engaged to assist us in providing the Site, including the Content, to you or to carry out one or more of the purposes described above or in the Terms of Use. These service providers are prohibited from using your Personal Information for any purpose other than to provide this assistance and are contractually required to protect Personal Information disclosed by Social Sentinel and to comply with the general privacy principles described in this Privacy Policy. When we use service providers, we provide limited access to your information so that such service providers can perform the tasks on our behalf.</p>
<p><u>We may disclose and share Personal Information with the following entities when we have obtained your consent prior to such disclosure</u>: With a Social Sentinel affiliate or partner, such as a third party organization working with Social Sentinel in connection with providing Content for the Site.</p>
<p><u>Deidentified or Aggregated information:</u>&nbsp;We may share statistical, deidentified, or aggregated non-Personal Information about our Users with business partners, affiliates, sponsors, and/or other third parties, including but not limited to Google Analytics. Such data may be used to customize our website content to deliver a better experience to our Users. You may opt out from Google Analytics implementations and features that Social Sentinel uses by contacting us as provided below.</p>
<p><strong>How can you review and modify your Personal Information?</strong></p>
<p>To modify or delete your Personal Information, please contact us (see contact information below).</p>
<p><strong>What is the opt-out policy for the Site?</strong></p>
<p>When you become a Subscriber, you consent to receiving communications from us and our partners and affiliates, but you can opt-out of receiving such communications. If you no longer wish to receive specific communications, please contact us (see contact information below).</p>
<p><strong>What kinds of security procedures are in place to protect against the loss, misuse, or alteration of your Personal Information?</strong></p>
<p>The Site uses security measures equal to or better than those reasonably expected in the industry, to protect against the loss, misuse, and alteration of your Personal Information under our control. Personal Information is secured on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure, as well as through use of appropriate physical, technical, and organizational measures. While we cannot guarantee that loss, misuse, or alteration to data will not occur, we take commercially reasonable precautions to prevent such unfortunate occurrences.</p>
<p>Please keep in mind that you are responsible for the security of any Personal Information in your control. You should log out of your browser at the end of each computer session to ensure that others cannot access your Personal Information and correspondence, especially if you share a computer with someone else or are using a computer in a public place like a library or Internet cafe.</p>
<p><strong>How long will we keep your Personal Information?</strong></p>
<p>We will keep Personal Information of Subscribers for the period of time that you are a Subscriber and for up to 10 years after you terminate your Subscription. We will keep Personal Information provided for purposes other than becoming a Subscriber for up to 10 years.</p>
<p><strong>Links to other websites</strong></p>
<p>The Site may contain links to other sites, such as educational or safety sites. These sites are not controlled by us and we are not responsible for any content contained on any such website. We are not responsible for the privacy practices employed by other sites, and the privacy practices employed by such sites may not be the same as the Social Sentinel practices. As with the Site, you should become familiar with any external party's privacy policy before visiting and/or providing any Personal Information through any such site.</p>
<p>We may terminate access for content providers or forum participants who are found repeatedly to provide or post protected material without necessary rights and permissions.</p>
<p><strong>Information on Children</strong></p>
<p>We do not knowingly collect any information from children under the age of 13. The Site is not intended for use by children. If we learn that we have collected Personally Information from a child under the age of 13, we will delete that information where reasonably possible.</p>
<p><strong>How to Contact Us</strong></p>
<p>To change your Personal Information or preferences (such as to modify your Personal Information, opt out of or change your preferences regarding receiving communications or information, or terminate your Subscription):</p>
<p style={{marginLeft: 40}}>Privacy<br/>Social Sentinel, Inc.<br/>128 Lakeside Ave., Suite 100<br/>Burlington, VT 05401, USA<br/>privacySSI@socialsentinel.com</p>
<p>For legal matters, including notices of violation of this Privacy Policy:</p>
<p style={{marginLeft: 40}}>Legal Department<br/>Social Sentinel, Inc.<br/>128 Lakeside Ave., Suite 100<br/>Burlington, VT 05401, USA<br/>legal@socialsentinel.com</p>
<p>You are entitled to make an anonymous complaint or inquiry in relation to this Privacy Policy or your privacy rights; however, we may require you to identify yourself if required by law or it is impracticable for us to address your matter otherwise.</p>
<p><strong>Notice to European Users</strong></p>
<p>As detailed in the Terms of Use, the Site is designed to comply with the laws of the State of Vermont and the United States, and it is intended for access and use by United States residents only. Any information you enter on this Site may be transferred outside of the European Union to the United States of America, which may not offer an equivalent level of protection to that required in the European Union. In particular, you are advised that the United States of America uses a sectoral model of privacy protection that relies on a mix of legislation, governmental regulation, and self-regulation. Article 26 of the European Union’s Data Protection Directive (Directive 95/46/EC, 1995 O.J. (L 281) 31) allows for transfer of personal data from the European Union to a third country if the individual has unambiguously given his consent to the transfer of personal information, regardless of the third country’s level of protection. By using this Site, you consent to the transfer of all such information to the United States of America, which may not offer an equivalent level of protection to that required in the European Union, and to the processing of that information by Social Sentinel on its servers located in the United States of America, as described in this Privacy Policy.</p>
<p><strong>Privacy Notice for California Residents </strong></p>
<p>This Privacy Notice for California Residents (“CA Privacy Notice”) supplements the information contained in the Privacy Policy above and applies solely to California residents.</p>
<p><strong><u>California Consumer Protection Act</u></strong></p>
<p>The California Consumer Protection Act of 2018 (CCPA) provides California residents with certain rights regarding personal information collected by businesses. This CA Privacy Notice applies to California residents covered by the CCPA (“CA consumers” or “you”). Any terms defined in the CCPA have the same meaning when used in the CCPA section of this CA Privacy Notice.</p>
<p><strong><em>Social Sentinel: a Business and a Service Provider</em>.</strong> Social Sentinel may interact with personal information as a “business” at times and as a “service provider” at other times. For instance, when Social Sentinel obtains personal information through the Site, such as when a CA consumer uses the Site or becomes a Subscriber, Social Sentinel may act as a business; when Social Sentinel provides its products and services to Clients and thereby processes information (including personal information) on behalf of such Clients, Social Sentinel may act as a service provider. The CCPA section of this CA Privacy Notice provides CA consumers with notice of their rights regarding personal information collected by Social Sentinel when it is acting as a business.</p>
<p><strong><em>Personal Information</em>.</strong> The CCPA defines “personal information” (“PI”) as information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular CA consumer or household. PI includes the following categories:</p>
<table>
<tbody>
<tr>
<td>
<p><strong>Category</strong></p>
</td>
<td>
<p><strong>Examples</strong></p>
</td>
</tr>
<tr>
<td>
<p>A. Identifiers.</p>
</td>
<td>
<p>A real name, postal address, unique personal identifier, online identifier Protocol address, email address, or other similar identifiers.</p>
</td>
</tr>
<tr>
<td>
<p>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</p>
</td>
<td>
<p>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</p>
</td>
</tr>
<tr>
<td>
<p>C. Protected classification characteristics under California or federal law.</p>
</td>
<td>
<p>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</p>
</td>
</tr>
<tr>
<td>
<p>D. Commercial information.</p>
</td>
<td>
<p>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</p>
</td>
</tr>
<tr>
<td>
<p>E. Biometric information.</p>
</td>
<td>
<p>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</p>
</td>
</tr>
<tr>
<td>
<p>F. Internet or other similar network activity.</p>
</td>
<td>
<p>Browsing history, search history, information on a CA consumer's interaction with a website, application, or advertisement.</p>
</td>
</tr>
<tr>
<td>
<p>G. Geolocation data.</p>
</td>
<td>
<p>Physical location or movements.</p>
</td>
</tr>
<tr>
<td>
<p>H. Sensory data.</p>
</td>
<td>
<p>Audio, electronic, visual, thermal, olfactory, or similar information.</p>
</td>
</tr>
<tr>
<td>
<p>I. Professional or employment-related information.</p>
</td>
<td>
<p>Current or past job history or performance evaluations.</p>
</td>
</tr>
<tr>
<td>
<p>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</p>
</td>
<td>
<p>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</p>
</td>
</tr>
<tr>
<td>
<p>K. Inferences drawn from other personal information.</p>
</td>
<td>
<p>Profile reflecting a person's preferences, characteristics, psychological trends, redispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p><strong><em>Information that Is Not Personal Information</em>.</strong></p>
<ul>
<li>The CCPA does not cover personal information that is not subject to the CCPA rights identified below, such as personal information Social Sentinel collects from employees, job applicants, owners, directors, officer, or contractors of businesses in the course of Social Sentinel’s provision or receipt of business-related services.</li>
<li>Personal information does not include:
<ul style={{listStyleType: "circle"}}>
<li>Publicly available information from government records</li>
<li>Deidentified or aggregated CA consumer information</li>
<li>Information excluded from the CCPA's scope, such as: health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data; personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994</li>
</ul>
</li>
</ul>
<p><strong><em>Collection of personal information</em>.</strong> Social Sentinel may collect personal information. In particular, Social Sentinel has collected the following categories of personal information from CA consumers within the last twelve (12) months:</p>
<p>Category A: Identifiers</p>
<p>Category B: California Customer Records statute PI categories</p>
<p>Category D: Commercial information</p>
<p>Category F: Internet or other similar network activity</p>
<p>Category G: Geolocation data</p>
<p>Category I: Professional or employment-related information</p>
<p><strong><em>Use of Personal Information</em>.</strong> Social Sentinel may use or disclose the personal information collected for one or more of the business purposes provided in the Privacy Policy.</p>
<p><strong><em>Sharing Personal Information</em>.</strong> Social Sentinel may disclose personal information to third parties for a business purpose. Such third parties may include affiliates, service providers, and third parties to whom CA consumers (or their authorized agents) authorize us to disclose their PI in connection with products or services we provide to them. When we disclose personal information for a business purpose, we enter into a contract that describes the purpose for such disclosure and requires the recipient both to keep the personal information confidential and not to use it for any purpose except performing the contract. Businesses may be permitted to sell personal information to third parties for monetary or other valuable consideration.</p>
<p>1. <u>Disclosures of Personal Information for a Business Purpose</u>. In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
<p style={{paddingLeft: 30}}>Category A: Identifiers</p>
<p style={{paddingLeft: 30}}>Category B: California Customer Records statute PI categories</p>
<p style={{paddingLeft: 30}}>Category D: Commercial information</p>
<p style={{paddingLeft: 30}}>Category F: Internet or other similar network activity</p>
<p style={{paddingLeft: 30}}>Category G: Geolocation data</p>
<p style={{paddingLeft: 30}}>Category I: Professional or employment-related information</p>
<p>2. <u>Sales of Personal Information</u>. In the preceding twelve (12) months, Social Sentinel has not sold any personal information.</p>
<p><strong><em>CA Consumers’ Rights and Choices</em>.</strong> The CCPA provides CA consumers with specific rights regarding their personal information. This section describes the CCPA rights of CA consumers and explains how to exercise those rights.</p>
<p>1. <u>Rights to Know, Be Informed, Request Deletion</u>:</p>
<p>a. <em>Right to Know or Right to Be Informed. </em>CA consumers have the right to request that Social Sentinel disclose certain information to them about Social Sentinel’s collection and use of their personal information over the past 12 months, where Social Sentinel collected and used their PI in its capacity as a business. Once Social Sentinel receives and confirms a verifiable consumer request, Social Sentinel will disclose to a CA consumer:</p>
<ul>
<li>The categories of personal information we collected about the CA consumer.</li>
<li>The categories of sources for the personal information we collected about the CA consumer.</li>
<li>The business or commercial purpose for collecting or selling that personal information.</li>
<li>The categories of third parties with whom Social Sentinel shares that personal information.</li>
<li>The specific pieces of personal information Social Sentinel collected about the CA consumer.</li>
<li>If Social Sentinel sold or disclosed your personal information for a business purpose, two separate lists disclosing:
<ul style={{listStyleType: "circle"}}>
<li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
<li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
</ul>
</li>
</ul>
<p>If CA consumers contact Social Sentinel regarding their right to know or right to be informed and Social Sentinel is acting in its capacity as a service provider for one of its Clients, Social Sentinel will direct the CA consumer to contact the Client.</p>
<p>b. <em>Right to Request Deletion. </em></p>
<p>i. CA consumers have the right to request that Social Sentinel delete any of their personal information that Social Sentinel collected from them and retained in its capacity as a business, subject to certain exceptions. Once Social Sentinel receives and confirms a CA consumer’s verifiable consumer request, Social Sentinel will delete (and direct its service providers to delete) the CA consumer’s personal information from its records, unless an exception applies.</p>
<p>ii. <u>Exceptions</u>: Social Sentinel may deny a CA consumer’s deletion request if retaining the information is necessary for Social Sentinel or its service providers to:</p>
<ul>
<li>Complete the transaction for which it collected the personal information, provide a good or service that the CA consumer requested, take actions reasonably anticipated within the context of its ongoing business relationship with the CA consumer, or otherwise perform its contract with the CA consumer.</li>
<li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
<li>Debug products to identify and repair errors that impair existing intended functionality.</li>
<li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
<li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</li>
<li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</li>
<li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
<li>Comply with a legal obligation.</li>
<li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
</ul>
<p>As a service provider for Clients, Social Sentinel will delete a consumer’s personal information from our records if directed to do so by a Client that has received a verifiable consumer request, subject to the exceptions provided above.</p>
<p><em>c. </em><em>Exercising the Rights to Know, Be Informed, Request Deletion:</em></p>
<ul>
<li>&nbsp;<u>Contact Info</u>: To exercise the Rights to Know, Be Informed, or Request Deletion, please submit a verifiable consumer request to Social Sentinel by either:
<ul>
<li>Calling us at 800.628.0158.</li>
<li>Emailing us at <a href="mailto:privacySSI@socialsentinel.com">privacySSI@socialsentinel.com</a></li>
</ul>
</li>
<li><u>Who</u>: Only a CA consumer (or a person registered with the California Secretary of State that the CA consumer authorizes to act on behalf of the CA consumer) may make a verifiable consumer request related to the CA consumer’s personal information. A CA consumer also may make a verifiable consumer request on behalf of the CA consumer’s minor child.</li>
<li><u>How Often</u>: A CA consumer may make a verifiable consumer request to Know or Be Informed only twice within a 12-month period.</li>
<li><u>Verifiable consumer request</u>: We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will use personal information provided in a verifiable consumer request only to verify the requestor's identity or authority to make the request. The verifiable consumer request must:
<ul style={{listStyleType: "circle"}}>
<li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
<li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
<li>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</li>
</ul>
</li>
<li><em><u>Response Timing and Format</u></em>. Social Sentinel endeavors to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</li>
</ul>
<p>2. <u>Right to Opt Out</u>:</p>
<p>a. <em>The Right to Opt Out</em>. Under the CCPA, a CA consumer who is 16 years of age or older has the right at any time to direct that Social Sentinel, in its capacity as a business, not sell your personal information (the “right to opt-out”). Social Sentinel does not sell the personal information of consumers Social Sentinel actually knows are less than 16 years of age, unless Social Sentinel receives affirmative authorization (the “right to opt-in”) from either the consumer who is between 13 and 16 years of age, or the parent or guardian of a consumer less than 13 years of age. Consumers who opt-in to personal information sales may opt-out of future sales at any time.</p>
<p>b. <em>Exercising the Right to Opt Out</em>. Social Sentinel in its capacity as a business does not sell personal information about CA consumers. Social Sentinel respects and understands that you may still want to ensure that your personal information is not sold, however, and you may make a Do Not Sell My Personal Information request as follows.</p>
<ul>
<li>You (or a person authorized by you) can opt out of the sale of the your personal information by clicking on the “Do Not Sell My Information” page, available <a title="Do Not Sell Info" href="https://www.socialsentinel.com/do-not-sell-my-info">here</a>.</li>
<li>You are not required to create an account to direct Social Sentinel as a business not to sell your personal information.</li>
<li>Once you make an opt-out request, Social Sentinel will wait at least twelve (12) months before asking you to reauthorize personal information sales. You may change your mind and opt back in to personal information sales at any time by visiting the Site and becoming a Subscriber or emailing Social Sentinel at privacySSI@socialsentinel.com. We will only use personal information provided in an opt-out request to review and comply with the request.</li>
</ul>
<p><em>Non-Discrimination</em>. Social Sentinel will not discriminate against you for exercising any of your CCPA rights. For instance, Social Sentinel will not: deny you goods or services; charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties; provide you a different level or quality of goods or services; or suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services. Please note that if your request requires Social Sentinel to delete, restrict, or not share information, some functionality of the Site may be inhibited, and any subsequent interaction with the Site after deletion may require new requests for action on your data.</p>
<p><strong><u>Shine the Light Law (CA)</u></strong></p>
<p>Under California Civil Code Section 1798.83 (known as the "Shine the Light" law), Site Users who are California residents may request certain information about our disclosure of Personal Information during the prior calendar year to third parties for their direct marketing purposes. To make such a request, please contact us (see the contact information for legal matters herein).</p>
<p><strong><u>Google Privacy Policy & Security Setting Links</u></strong></p>
<p><a style={{color: "#20be83", textDecoration: "underline"}} href="https://policies.google.com/privacy?fg=1" rel="noopener noreferrer" target="_blank">Google Privacy Policy</a></p>
<p><a style={{color: "#20be83", textDecoration: "underline"}} href="https://security.google.com/settings/security/permissions" rel="noopener noreferrer" target="_blank">Google Security Settings</a></p>
			</div>
		);
	}
}

PrivacyPolicy.propTypes = {
	updateHeader: PropTypes.func.isRequired
};

export default PrivacyPolicy;