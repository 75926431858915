import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import regression from 'highcharts-regression';

import Highchart from "./Highchart";
import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import DetailedReport from "../containers/DetailedReport";
import { withTheme } from '@material-ui/core';

//import {imagesProcessedData} from "../data/reports";

// initialize regression plugin
//regression(Highcharts);

class ImagesProcessedReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {chartClasses, theme, filter} = props;
		this.state = {
			chartConfig: HighchartsConfig.merge({
				chart: {
					className: chartClasses.chart
				},
				title: {
					align: "left",
					text: "Images Scanned",
					style: {color: theme.palette.common.white}
				},
				accessibility: {
					pointValueSuffix: " images",
					description: `The number of images scanned per day over the last ${filter.previousDays} days.`
				},
				yAxis: {
					title: "",
					lineWidth: 0,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					labels: {
						y: 0,
						style: {
							color: theme.palette.common.white,
							fontSize: "15px"
						}
					}
				},
				xAxis: {
					type: "datetime",
					tickWidth: 0,
					offset: 20,
					labels: {
						y: 35,
						rotation: -45,
						align: "center",
						style: {
							color: theme.palette.common.white,
							fontSize: "15px"
						}
					},
					style: {
						color: theme.palette.common.white
					}
				},
				plotOptions: {
					column: {
						borderColor: theme.palette.secondary.orange
					}
				},
				series: [{
					type: "column",
					name: "Images",
					color: theme.palette.secondary.orange
				}]
			}, {
				modifyTickInterval: true,
				previousDays: filter.previousDays
			})
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			let {chartConfig} = this.state,
				{data, chartClasses, filter} = this.props;
				// TEMP
				//data = imagesProcessedData;

			if (data) {
				ReportDataMasseuse.convertDatesToMillis(data);
				chartConfig.series[0].data = ReportDataMasseuse.massageData(data, "period", "images");
				chartConfig.series[0].className = filter.previousDays > 30 ? chartClasses.basicColumn : `${chartClasses[filter.previousDays+"dayColumn"]}`;
				chartConfig.yAxis.labels.enabled = ReportDataMasseuse.getMaxValue(data, "images") !== 0;
			} else {
				chartConfig.series[0].data = null;
			}

			this.setState({chartConfig: chartConfig});
		}
	}

	render() {
		let {chartConfig} = this.state,
			{fidoError} = this.props;

		return (
			<DetailedReport
				fidoError={fidoError}
				ready={chartConfig.series[0].data && chartConfig.series[0].data.length > 0 ? true : false}>
				<Highchart
					darkTheme
					chartConfig={chartConfig} />
			</DetailedReport>
		);
	}
}

ImagesProcessedReport.propTypes = {
	theme: PropTypes.object.isRequired,
	chartClasses: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func,
	location: PropTypes.object,
	filter: PropTypes.object,
	data: PropTypes.array,
	fidoError: PropTypes.bool
};

export default withTheme(ImagesProcessedReport);