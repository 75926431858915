import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highchart from "./Highchart";

import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import DetailedReport from "../containers/DetailedReport";
import AuthorizedUser from '../../utils/AuthorizedUser';
import { withTheme } from '@material-ui/core';

class AlertsViewedReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {chartClasses, theme, filter} = props;
		const moreContrast = AuthorizedUser.wantsMoreContrast();

		this.state = {
			chartConfig: HighchartsConfig.merge({
				chart: {
					type: "bar",
					className: chartClasses.chart
				},
				accessibility: {
					pointValueSuffix: " alerts",
					description: `The number of alerts that were received per day over the last ${filter.previousDays} days.`
				},
				title: {
					align: "left",
					text: "Alerts Viewed",
					style: {color: theme.palette.common.white}
				},
				legend: {
					enabled: true,
					itemStyle: {
						color: theme.palette.common.white
					},
					itemHoverStyle: {
						color: theme.palette.common.white
					}
				},
				yAxis: {
					title: "",
					lineWidth: 0,
					gridLineWidth: 0,
					allowDecimals: false,
					labels: {
						style: {
							color: theme.palette.common.white,
							fontSize: "15px"
						}
					}
				},
				xAxis: {
					tickWidth: 0,
					type: "categories",
					labels: {
						style: {
							color: theme.palette.common.white
						}
					}
				},
				plotOptions: {
					bar: {
						grouping: false,
						borderWidth: moreContrast ? 2 : 1,
						borderColor: moreContrast ? theme.palette.secondary.slate : theme.palette.primary.mediumGreen
					}
				},
				tooltip: {
					shared: true,
					formatter: function() {
						let content,
							viewedPoint = this.points[1],
							viewed = viewedPoint.y,
							deliveredPoint = this.points[0],
							delivered = this.y,
							deliveredSeries = deliveredPoint.series,
							viewedSeries = viewedPoint.series,
							percentage = (viewed/delivered)*100,
							header = `<span style="font-weight: bold">${deliveredPoint.x}</span><br/>`;

						if (deliveredPoint.percentage === 0 || isNaN(percentage)) {
							percentage = 0;
						}

						const numFormatter = new Intl.NumberFormat("en-US",{maximumFractionDigits: 0});

						content = `<span style="color:${deliveredSeries.color}">\u25CF</span> ${deliveredSeries.name}: <b>${delivered}</b><br/><span style="color:${viewedSeries.color}">\u25CF</span> ${viewedSeries.name}: <b>${viewed} (${numFormatter.format(percentage)}%)</b><br/>`;

						return header + content;
					}
				},
				series: [{
					name: "Delivered",
					color: theme.palette.primary.mediumGreen
				},{
					name: "Viewed",
					color: theme.palette.secondary.yellow
				}]
			})
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			let barClass,
				{chartConfig} = this.state,
				{data, chartClasses} = this.props;

				// TEMP
				//data = alertsViewed;

			if (data) {
				chartConfig.xAxis.categories = ReportDataMasseuse.flatten({data: data, valueKey: "user_name"});
				chartConfig.series[0].data = ReportDataMasseuse.flatten({data: data, valueKey: "alerts_notified"});
				chartConfig.series[1].data = ReportDataMasseuse.flatten({data: data, valueKey: "alerts_viewed"});

				barClass = chartConfig.xAxis.categories.length < 10 ? chartClasses.basicBar :
					chartConfig.xAxis.categories.length < 15 ? chartClasses.tightBar : chartClasses.tighterBar;
				chartConfig.series[0].className = barClass;
				chartConfig.series[1].className = barClass;

				chartConfig.yAxis.labels.enabled = ReportDataMasseuse.getMaxValue(data, "alerts_notified") !== 0;

				if (data.length === 0) {
					chartConfig.xAxis.lineWidth = 0;
				}
			} else {
				chartConfig.series[0].data = null;
				chartConfig.series[1].data = null;
			}

			this.setState({chartConfig: chartConfig});
		}
	}

	render() {
		let {chartConfig} = this.state,
			{fidoError} = this.props;

		return (
			<DetailedReport
				fidoError={fidoError}
				ready={chartConfig.series[0].data && chartConfig.series[0].data.length > 0 ? true : false}>
				<Highchart
					darkTheme
					chartConfig={chartConfig} />
			</DetailedReport>
		);
	}
}

AlertsViewedReport.propTypes = {
	theme: PropTypes.object.isRequired,
	chartClasses: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func,
	location: PropTypes.object,
	filter: PropTypes.object,
	data: PropTypes.array,
	fidoError: PropTypes.bool
};

export default withTheme(AlertsViewedReport);
