import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PrimaryTextButton from "./PrimaryTextButton";

import { withStyles, withWidth, Grid } from '@material-ui/core';

const styles = theme => ({
	linkIcon: {
		marginLeft: theme.spacing(.5)
	}
});

class ViewOriginalButton extends Component {
	render() {
		let {classes, extraHeaders, isTip, isGDoc, postLink, width, className, label, iconTitle, offset, icon} = this.props;

		return (
			!(extraHeaders || isTip) ?
				<Grid item>
					<PrimaryTextButton
						offset={offset}
						href={postLink}
						target="_blank"
						disabled={!postLink}
						className={className}>
						<span>
							{label ? label :
								isGDoc ? width.includes("xs") ? "Current Doc" : "View Current Doc" :
									width.includes("xs") ? 'Original' : 'View Original Post'
							}
							<FontAwesomeIcon
								className={classes.linkIcon}
								icon={icon || ["far", "external-link-square"]}
								title={iconTitle ? iconTitle : isGDoc ? "view current doc" : "view original"} />
						</span>
					</PrimaryTextButton>
				</Grid> : null
		);
	}
}

ViewOriginalButton.defaultProps = {
	offset: "left"
};

ViewOriginalButton.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	extraHeaders: PropTypes.object,
	isTip: PropTypes.bool,
	isGDoc: PropTypes.bool,
	postLink: PropTypes.string,
	label: PropTypes.string,
	iconTitle: PropTypes.string,
	offset: PropTypes.string,
	icon: PropTypes.array
};

export default withStyles(styles)(withWidth()(ViewOriginalButton));