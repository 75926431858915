import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PaddedLayout from "../../containers/PaddedLayout";
// import AssociationsReport from "../components/AssociationsReport";
// import ChartClasses from "../components/ChartClasses";
import EventMetricsTable from "../components/EventMetricsTable";
import SparkChartReportItem from "../components/SparkChartReportItem";
import Fido from '../../utils/Fido';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import NumberFormatter from '../../utils/NumberFormatter';

import {
	Grid,
	withTheme,
	withStyles
} from '@material-ui/core';

const styles = theme => ({
	sparkCardGrid: {
		paddingTop: `${theme.spacing(3)}px !important`,
		marginLeft: theme.spacing(8),
		marginRight: theme.spacing(5)
	},

	metricsTable: {
		marginTop: theme.spacing(3),
		marginBottom: `${theme.spacing(3)}px !important`
	}
});

class EventMetricsReport extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			metricsData: [],
			events: null,
			fidoError: false,
			hasMoreItems: true,
			tableLoading: false,
			pageStart: 0,
			postsAndImages: null,
			alertsAndDiscussions: null,
			totalPosts: null,
			totalAlerts: null,
			totalImages: null,
			totalDiscussions: null,
			alertsPerHour: null,
			postsPerHour: null,
			imagesPerHour: null,
			discussionsPerHour: null
		};

		this.fido = new Fido();
		this.sparkFido = new Fido();
	}

	componentDidMount() {
		this.fetchData();
		this.fetchSparkData();
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.sparkFido.dropIt();
	}

	async fetchData(query) {
		this.setState({
			tableLoading: true
		});

		let data = await this.fido.fetch("/events/" + this.props.eventId + "/metrics")
			.catch(error => {
				this.setState({fidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({
				metricsData: data,
				tableLoading: false,
				hasMoreItems: false
			});
		}
	}

	async fetchSparkData() {
		let data = await this.fido.fetch("/events/" + this.props.eventId + "/spark")
			.catch(error => {
				this.setState({fidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({
				postsAndImages: data.postsAndImages,
				alertsAndDiscussions: data.alertsAndDiscussions,
				totalPosts: data.totalPosts,
				totalAlerts: data.totalAlerts,
				totalImages: data.totalImages,
				totalDiscussions: data.totalDiscussions,
				alertsPerHour: data.alertsPerHour,
				postsPerHour: data.postsPerHour,
				imagesPerHour: data.imagesPerHour,
				discussionsPerHour: data.discussionsPerHour
			});
		}
	}

	render() {
		let {metricsData, pageStart, fidoError, hasMoreItems, tableLoading, sparkCardsFidoError,
				postsAndImages, alertsAndDiscussions, totalPosts, totalAlerts, totalImages, totalDiscussions,
				alertsPerHour, postsPerHour, imagesPerHour, discussionsPerHour} = this.state,
			{classes, suggestionHandler, suggestionChange, searchTermSuggestion, previousDays} = this.props,
			fullWidthSizing = {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
			sparkCardSizing = {xs: 12, sm: 6, md: 6, lg: 3, xl: 3},
			sparkCards = [
				<ReportDataMasseuse
					key={"spark0"}
					dataConfig={[{data: alertsAndDiscussions, key: "period", valueKey: "alerts"}]}
					render={data =>
						<SparkChartReportItem
							key="spark1"
							excludeChange
							perCaption={`${NumberFormatter.largeNumberFormat(alertsPerHour)} / hr`}
							fidoError={sparkCardsFidoError}
							title="Alerts"
							data={data[0]}
							value={totalAlerts}
							previousDays={previousDays} />
					} />,
				<ReportDataMasseuse
					key={"spark1"}
					dataConfig={[{data: postsAndImages, key: "period", valueKey: "associations"}]}
					render={data =>
						<SparkChartReportItem
							key="spark2"
							excludeChange
							perCaption={`${NumberFormatter.largeNumberFormat(postsPerHour)} / hr`}
							fidoError={sparkCardsFidoError}
							title="Associations"
							data={data[0]}
							value={totalPosts}
							previousDays={previousDays} />
					} />,
				<ReportDataMasseuse
					key={"spark1"}
					dataConfig={[{data: postsAndImages, key: "period", valueKey: "images"}]}
					render={data =>
						<SparkChartReportItem
							key="spark3"
							excludeChange
							perCaption={`${NumberFormatter.largeNumberFormat(imagesPerHour)} / hr`}
							fidoError={sparkCardsFidoError}
							title="Images Scanned"
							data={data[0]}
							value={totalImages}
							previousDays={previousDays} />
					} />,
				<ReportDataMasseuse
					key={"spark3"}
					dataConfig={[{data: alertsAndDiscussions, key: "period", valueKey: "discussions"}]}
					render={data =>
						<SparkChartReportItem
							key="spark4"
							excludeChange
							perCaption={`${NumberFormatter.largeNumberFormat(discussionsPerHour)} / hr`}
							fidoError={sparkCardsFidoError}
							title="Discussions"
							data={data[0]}
							value={totalDiscussions}
							previousDays={previousDays} />
					} />
			];

		return (
			<React.Fragment>
				<Grid item {...fullWidthSizing} className={classes.sparkCardGrid}>
					<Grid container
						tabIndex={0}
						role="group"
						aria-hidden="false"
						justify="center"
						alignItems="flex-start"
						className={classes.sparkCardContainer}>
						{sparkCards.map((card, idx) => {
							return <Grid item key={`key-${idx}`} {...sparkCardSizing}>{card}</Grid>;
						})}
					</Grid>
				</Grid>
				<PaddedLayout noTopPad noBottomPad className={classes.metricsTable}>
					<EventMetricsTable
						pageStart={pageStart}
						fidoError={fidoError}
						events={metricsData.search_terms}
						hasMoreItems={hasMoreItems}
						isLoading={tableLoading}
						loadNextPage={()=>{}}
						searchTermSuggestion={searchTermSuggestion}
						suggestionChange={suggestionChange}
						suggestionHandler={suggestionHandler} />
				</PaddedLayout>
			</React.Fragment>
		);
	}
}

EventMetricsReport.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	suggestionHandler: PropTypes.func.isRequired,
	suggestionChange: PropTypes.func.isRequired,
	searchTermSuggestion: PropTypes.string,
	showSnackbar: PropTypes.func,
	location: PropTypes.object,
	eventId: PropTypes.number,
	previousDays: PropTypes.number
};

export default withStyles(styles)(withTheme(EventMetricsReport));
