import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fido from '../utils/Fido';
import FormHelper from "../utils/FormHelper";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import SettingsForm from "../components/SettingsForm";
import Constants from '../utils/Constants';

const alertDays = [
	"monday_alerts",
	"tuesday_alerts",
	"wednesday_alerts",
	"thursday_alerts",
	"friday_alerts",
	"saturday_alerts",
	"sunday_alerts"
];

const digestDays = [
	"monday_summary",
	"tuesday_summary",
	"wednesday_summary",
	"thursday_summary",
	"friday_summary",
	"saturday_summary",
	"sunday_summary"
];

class Settings extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			rtNotifications: [],
			libraryTopics: [],
			digestEnabled: false,
			settings: {
				user: {},
				times: {},
				notification: {
					text_alerts: 0,
					email_alerts: 0,
					text_gmail: 0,
					email_gmail: 0,
					text_exchange: 0,
					email_exchange: 0,
					text_shareit: 0,
					email_shareit: 0,
					text_trending_topics: 0,
					email_trending_topics: 0,
					monday_summary: 0,
					tuesday_summary: 0,
					wednesday_summary: 0,
					thursday_summary: 0,
					friday_summary: 0,
					saturday_summary: 0,
					sunday_summary: 0,
					daily_summary_time: "00:00:00",
					daily_summary_send_blank: 0,
					weekly_summary: 0,
					monthly_summary: 0,
					activeLibraryTopics: [],
					alert_schedule: 0,
					alert_schedule_type: "same",
					monday_alerts: 1,
					tuesday_alerts: 1,
					wednesday_alerts: 1,
					thursday_alerts: 1,
					friday_alerts: 1,
					saturday_alerts: 0,
					sunday_alerts: 0
				}
			}
		};

		this.fido = new Fido();
		this.libraryTopicsFido = new Fido();

		//this.digestSwitchChanged = this.digestSwitchChanged.bind(this);
		this.digestButtonToggled = this.digestButtonToggled.bind(this);
		this.alertDaysButtonToggled = this.alertDaysButtonToggled.bind(this);
		this.handleFormChange = this.handleFormChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.hasRealTimeNotification = this.hasRealTimeNotification.bind(this);
		this.openTimeSettings = this.openTimeSettings.bind(this);
	}

	componentDidMount() {
		let user = this.props.authenticatedUser,
			path = user.profileUrlBase + "/settings",
			headerOptions = {
				title: 'Settings'
			};

		this.props.updateHeader(headerOptions);
		this.buildRtArray(user);
		this.loadLibraryTopics();
		this.fido.fetch(path)
			.then((data) => {
				if (data) {
					this.setState({settings: data});
				}
			})
			// TODO: probably only want to show some errors as snackbars
			.catch(error => this.props.showSnackbar(error.message));
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.libraryTopicsFido.dropIt();
		this.props.resetHeader();
	}

	async loadLibraryTopics() {
		let data = await this.libraryTopicsFido.fetch("/activities/library-topics")
			.catch(error => {
				this.setState({fidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({libraryTopics: data});
		}
	}

	buildRtArray(user) {
		let rtArray = [],
			socialMediaAccess = user.permissions.includes(Constants.USER.PERMISSIONS.DATA.SOCIAL_MEDIA),
			gsuiteAccess = user.permissions.includes(Constants.USER.PERMISSIONS.DATA.GSUITE),
			exchangeAccess = user.permissions.includes(Constants.USER.PERMISSIONS.DATA.EXCHANGE),
			shareitAccess = user.permissions.includes(Constants.USER.PERMISSIONS.DATA.TIPS);

		if (socialMediaAccess) {
			rtArray.push("text_alerts");
			rtArray.push("email_alerts");
		}

		if (gsuiteAccess) {
			rtArray.push("text_gmail");
			rtArray.push("email_gmail");
		}

		if (exchangeAccess) {
			rtArray.push("text_exchange");
			rtArray.push("email_exchange");
		}

		if (shareitAccess) {
			rtArray.push("text_shareit");
			rtArray.push("email_shareit");
		}

		this.setState({rtNotifications: rtArray});
	}

	// TODO: we're using state.digestEnabled and days, how do we consolidate?
	/*isDigestEnabled() {
		// if any of the days are enabled
		for (var value of digestDays) {
			// TODO: logic negated for testing
			if (!this.state.settings[value]) {
				return true;
			}
		}

		return false;
	}*/

	/*digestSwitchChanged(event, checked) {
		this.setState({digestEnabled: checked});
	}*/

	digestButtonToggled(index) {
		let day = digestDays[index],
			settings = this.state.settings;
		settings.notification[day] = !settings.notification[day];
		this.setState({settings: settings});
	}

	alertDaysButtonToggled(index) {
		let day = alertDays[index],
			settings = this.state.settings;
		settings.notification[day] = !settings.notification[day];
		this.setState({settings: settings});
	}

	hasRealTimeNotification() {
		let {settings, rtNotifications} = this.state;
		return rtNotifications.some(nId => settings.notification[nId]);
	}

	handleFormChange(event, libraryTopicObject) {
		let {settings, libraryTopics, rtNotifications} = this.state,
			{name, value} = event.target,
			notification = settings.notification,
			activeLibraryTopics = notification.activeLibraryTopics,
			change = FormHelper.getChanged(event);

		// if a Library Topic notification change
		if (libraryTopicObject != null && libraryTopicObject.topic != null) {
			libraryTopicObject = libraryTopicObject.topic;

			let filteredArray,
				changeValue = change[libraryTopicObject.id];

			if (changeValue === false) {
				filteredArray = activeLibraryTopics.filter(topic => topic.id !== libraryTopicObject.id);
				notification.activeLibraryTopics = filteredArray;
			} else {
				libraryTopicObject.active = true;
				notification.activeLibraryTopics.push(libraryTopicObject);
			}

			// if all topic notifications are turned off, turn off all real time notifications
			if (notification.activeLibraryTopics.length === 0) {
				rtNotifications.forEach(nId => notification[nId] = false);
				notification.alert_schedule = false;
			}
		} else {
			notification = Object.assign(notification, change);
			// if all real time notifications off, turn off all topic notifications
			if (!this.hasRealTimeNotification()) {
				notification.activeLibraryTopics = [];
				notification.alert_schedule = false;
			} else if ((value === "true" || value === true) &&
				rtNotifications.includes(name) && !activeLibraryTopics.length) {
				notification.activeLibraryTopics = libraryTopics;
			}
		}

		this.setState({settings: settings});
	}

	submitForm() {
		let user = this.props.authenticatedUser;
		let poodle = new Fido();
		// Using poodle cause it's a throw away, like an avalanche poodle - don't want to abort call when unmounted
		poodle.fetch(user.profileUrlBase + "/settings", {
			method: "PUT",
			body: JSON.stringify(this.state.settings.notification)
		}, 'Saving Settings...', 'Saved.')
			// trying to set state when component is not mounted (backed up), causes potential memoery leak.
			// so, since we're backing up, we don't need to set the state.
			.then((data) => {})
			// TODO: probably only want to show some errors as snackbars
			.catch(error => this.props.showSnackbar(error.message));
	}

	openTimeSettings() {
		let user = this.props.authenticatedUser;
		this.props.history.push(`${user.profileUrlBase}/edit`);
	}

	render() {
		//let digestEnabled = this.isDigestEnabled();
		let {libraryTopics, rtNotifications} = this.state;

		return (
			<SettingsForm
				user={this.props.authenticatedUser}
				libraryTopics={libraryTopics}
				rtNotifications={rtNotifications}
				settings={this.state.settings}
				//digestEnabled={digestEnabled}
				//digestSwitchChanged={this.digestSwitchChanged}
				digestButtonToggled={this.digestButtonToggled}
				alertDaysButtonToggled={this.alertDaysButtonToggled}
				formChangeHandler={this.handleFormChange}
				submitHandler={this.submitForm}
				openTimeSettings={this.openTimeSettings}
				hasRealTimeNotification={this.hasRealTimeNotification()} />
		);
	}
}

Settings.propTypes = {
	updateHeader: PropTypes.func.isRequired,
	resetHeader: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func,
	authenticatedUser: PropTypes.object
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <Settings {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "Settings";

export default React.forwardRef(dNameFunc);
