import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withStyles,
	Collapse,
	RootRef,
	Button,
	Grid
} from '@material-ui/core';

const styles = theme => ({
	hidden: {
		display: "none"
	},

	button: {
		marginLeft: theme.spacing(-1)
	},

	inline: {
		right: "2.1em",
		position: "absolute",
		marginTop: theme.spacing(-3.5)
	},

	collapse: {
		marginBottom: theme.spacing(1)
	},

	truncate: {
		//position: "relative",

		/* place for '...' */
		marginRight: "-1em",
		paddingRight: "6.2em", //1em",
		//wordBreak: "break-word",

		/*"&:before": {
			content: "'...'",
			position: "absolute",
			 set position to right bottom corner of block
			right: "9em", //0,
			bottom: 3, //0
			background: theme.palette.common.white
		}*/
	}
});

class MoreOrLess extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			truncate: false,
			showMore: false
		};

		this.contentDiv = React.createRef();
		this.toggleReadMore = this.toggleReadMore.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			this.isTruncateNeeded();
		}, 0);
	}

	isTruncateNeeded() {
		let {expanded, truncateHeight, hasImage} = this.props,
			height = this.contentDiv.current && this.contentDiv.current.getBoundingClientRect().height;

		this.setState({
			showMore: expanded,
			truncate: height > truncateHeight || hasImage
		});
	}

	toggleReadMore(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({showMore: !this.state.showMore});
	}

	render() {
		let {showMore, truncate} = this.state,
			{classes, truncateHeight, content, inline, moreLabel, lessLabel, right} = this.props,
			moreBtn = (
				<Button
					size="small"
					color="primary"
					className={`${classes.button} ${showMore === true ? classes.hidden : null}`}
					onClick={this.toggleReadMore}>{moreLabel}</Button>
			),
			lessBtn = (
				<Button
					size="small"
					color="primary"
					className={`${classes.button} ${showMore === true ? null : classes.hidden}`}
					onClick={this.toggleReadMore}>{lessLabel}</Button>
			);

		return (
			<React.Fragment>
				<Collapse
					in={showMore}
					style={{height: truncateHeight}}
					collapsedHeight={`${truncateHeight}px`}
					className={`${classes.collapse} ${truncate && inline ? classes.truncate : null}`}>
					<RootRef rootRef={this.contentDiv}>
						{content}
					</RootRef>
				</Collapse>
				{truncate === true ? (
					<Grid container
						justify={right || inline ? "flex-end" : "flex-start"}
						className={inline ? classes.inline : null}>
						<Grid item>{moreBtn}</Grid>
						<Grid item>{lessBtn}</Grid>
					</Grid>
				) : null}
			</React.Fragment>
		);
	}
}

MoreOrLess.defaultProps = {
	inline: false,
	expanded: false,
	right: false,
	truncateHeight: 200,
	moreLabel: "Show More",
	lessLabel: "Show Less"
};

MoreOrLess.propTypes = {
	classes: PropTypes.object.isRequired,
	truncateHeight: PropTypes.number,
	content: PropTypes.any,
	expanded: PropTypes.bool,
	inline: PropTypes.bool,
	moreLabel: PropTypes.string,
	lessLabel: PropTypes.string,
	right: PropTypes.bool,
	hasImage: PropTypes.bool
};

export default withStyles(styles)(MoreOrLess);