import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactPlaceholder from 'react-placeholder';
import {TextBlock, RoundShape} from 'react-placeholder/lib/placeholders';

import InfiniteScroll from 'react-infinite-scroller';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Media from 'react-media';

import Fido from '../utils/Fido';
import PaddedLayout from './PaddedLayout.js';
import SearchForm from '../components/SearchForm.js';
import TableSortableHeader from '../components/TableSortableHeader';
import Timestamp from '../components/Timestamp.js';
import FullscreenMessage from '../components/FullscreenMessage';
import Avatar from "../components/Avatar";
import Constants from "../utils/Constants";
import LoadingIndicator from "../components/LoadingIndicator";

import {
	withStyles,
	withWidth,
	Fab,
	Table,
	TableCell,
	TableRow,
	Typography,
	Paper,
	Grid,
	withTheme,
} from '@material-ui/core';

const styles = theme => ({
	tableWrapper: {
		overflowX: 'auto',
	},

	userBody: {
		// The following style is taken from the class MuiTableBody-root
		// Using the TableBody material-ui component causes issues with InfiniteScroll
		// The standard <tbody> tag is currently used so we need to apply this manually
		display: "table-row-group"
	},

	userCell: {
		// The following style are taken from the class MuiTableCell-body
		// The class is rendered on all TableCell components when inside a TableBody component
		// Using the TableBody material-ui component causes issues with InfiniteScroll
		// The standard <tbody> tag is currently used so we need to apply these manually
		fontWeight: 400,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(2),

		paddingRight: 0,
		whiteSpace: "nowrap"
	},

	userRow: {
		height: theme.spacing(8),

		"&:focus": {
			backgroundColor: "rgba(0, 0, 0, 0.07)"
		}
	},

	userSecondary: {
		color: theme.palette.text.secondary
	},

	titleCol: {
		maxWidth: 170,

		[theme.breakpoints.up('sm')]: {
			maxWidth: 250
		},

		[theme.breakpoints.up('md')]: {
			maxWidth: 385
		},

		[theme.breakpoints.up('lg')]: {
			maxWidth: 175
		},

		[theme.breakpoints.up('xl')]: {
			maxWidth: 365
		}
	},

	avatarGrid: {
		flexWrap: "nowrap"
	},

	avatarGridItem: {
		paddingRight: 10
	},

	userNameGridItem: {
		maxWidth: "70%"
	},

	statusCell: {
		[theme.breakpoints.down('xs')]: {
			paddingLeft: 6
		}
	},

	statusIcon: {
		width: "0.65rem !important",
		paddingRight: theme.spacing(1)
	},

	statusActive: {
		color: theme.palette.primary.mediumGreen
	},

	statusInactive: {
		color: theme.palette.text.secondary
	},

	fab: {
		zIndex: 1,
		position: "fixed",
		left: theme.spacing(6),
		color: theme.palette.common.white,

		[theme.breakpoints.up('lg')]: {
			left: theme.spacing(34)
		},

		[theme.breakpoints.down('sm')]: {
			left: theme.spacing(1.5)
		}
	},

	fabExtendedIcon: {
		marginRight: theme.spacing(1)
	},

	paper: {
		clear: "both"
	},

	headerRow: {
		backgroundColor: theme.palette.grey[200]
	},

	headerCell: {
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2)
	},

	paddedLayout: {
		paddingBottom: theme.spacing(3)
	},

	searchForm: {
		display: "flex",
		justifyContent: "flex-end"
	},

	searchField: {
		width: "80%",

		[theme.breakpoints.down('xs')]: {
			width: "78%"
		}
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const itemPlaceHolder = (
	<Paper style={{padding: 10, marginTop: 10}}>
		<div style={{display: "flex", marginTop: 25}}>
			<div style={{marginRight: 10}}>
				<RoundShape color={placeholderColor} style={{height: 48, width: 48}} />
			</div>
			<div style={{flex: 1}}>
				<TextBlock rows={2} color={placeholderColor} />
			</div>
		</div>
	</Paper>
);

class UserManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasMoreItems: true,
			isLoading: false,
			order: 'asc',
			orderBy: 'name',
			fullUsers: [],
			users: null,
			columnData: [
				{ id: 'name', alpha: true, disablePadding: false, label: 'Name' },
				{ id: 'user_type_str', alpha: true, disablePadding: false, label: 'Role', minWidth: props.theme.breakpoints.values.sm },
				{ id: 'created_at', disablePadding: false, label: 'Created', minWidth: props.theme.breakpoints.values.sm },
				{ id: 'last_login', disablePadding: false, label: 'Last Login', minWidth: props.theme.breakpoints.values.lg },
				{ id: 'activated', disablePadding: false, label: 'Status' },
			],
			fidoError: false,
			loadPage: 2,
			selectedUser: null,
			sorted: false,
			searchActive: false,
			allUsers: false
		};

		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.searchHandler = this.searchHandler.bind(this);
		this.clearHandler = this.clearHandler.bind(this);
		this.loadItems = this.loadItems.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleExclusions = this.handleExclusions.bind(this);

		this.fido = new Fido();
	}

	componentDidMount() {
		this.props.updateHeader({title: "team members", primary: true});
		this.fetchData();
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.props.resetHeader();
	}

	componentDidUpdate(prevProps, prevState) {
		this.handleExclusions(this.props.history);

		// Edge case where the window is able to hold the entire first set of results without a scroll bar
		// This loads more pages until the area is scrollable.
		if ((document.body.clientHeight < window.innerHeight) &&
			this.state.users && this.state.users.length > 0 && this.state.fullUsers.length > 0 &&
			this.state.hasMoreItems && !this.state.isLoading) {
			let page = this.state.loadPage;
			this.loadItems(page);
			this.setState({loadPage: page + 1});
		}
	}

	fetchData(opts) {
		this.fido.fetch(this.props.location.pathname, opts)
			.then((data) => {
				if (data) {
					// Seperate state to hold the full user set for use in search
					data.fullUsers = data.users;
					this.setState(data);

					if (data.property) {
						this.handleSort(data.property);
					}
				}
			})
			.catch(this.handleUsersError);
	}

	loadItems(page) {
		// Check to make sure there is more to load
		if(this.state.hasMoreItems === true) {
			// Set a loading flag so we don't double-load
			this.setState({
				isLoading: true
			});

			// Edge case (above) causes the 'pageStart' prop to be incorrect
			if(this.state.loadPage > 2) {
				page = this.state.loadPage;
			}

			var url = this.props.location.pathname+'?page='+page;

			// Get the next page of data from the paginator
			this.fido.fetch(url)
				.then((data) => {
					if(data) {
						var users = this.state.users;
						data.users.map((user) =>
							users.push(user)
						);

						let update = {
							users: users,
							isLoading: false
						};
						if(this.state.loadPage > 2) {
							update.loadPage = page + 1;
						}
						if(!data.hasMoreItems) {
							update.hasMoreItems = false;
						}

						this.setState(update);
					}
				})
				.catch(error => this.props.showSnackbar(error.message));
		} else {
			this.setState({allUsers: true});
		}
	}

	searchHandler(formVals) {
		this.setState({searchActive: true, hasMoreItems: false});
		this.fido.fetch(this.props.location.pathname, {
			method: "POST",
			body: JSON.stringify({users: this.state.fullUsers, search: formVals.search, allUsers: this.state.allUsers})
		})
			.then(this.handleUsersUpdate)
			.catch(this.handleUsersError);
	}

	clearHandler() {
		this.setState({users: this.state.fullUsers, searchActive: false});
	}

	handleUsersUpdate = (data) => {
		if (data) {
			this.setState({users: data});
		}
	}

	handleUsersError = (error) => {
		this.setState({fidoError: true});
		this.props.showSnackbar(error.message);
	}

	handleRequestSort(event, property) {
		// We need to grab all users in one call for upon first sort
		// Subsequent sorting can use the existing data
		if (this.state.sorted === true || this.state.searchActive === true) {
			this.handleSort(property);
		} else {
			this.fetchData({query: {property: property, paginate: false}});
			this.setState({sorted: true, allUsers: true});
		}
	}

	handleSort(property) {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({order, orderBy});
	}

	getSorting(order, orderBy) {
		return order === 'desc'	? (a, b) => {
			let aa = typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy],
				bb = typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
			return aa == null ? 1 : bb == null ? -1 : bb < aa ? -1 : 1;
		} : (a, b) => {
			let aa = typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy],
				bb = typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
			return aa == null ? -1 : bb == null ? 1 : aa < bb ? -1 : 1;
		};
	}

	handleRowClick(user) {
		this.setState({selectedUser: user});
	}

	handleExclusions(history) {
		// sconsole.log(history);
		let removed;
		// defining here so don't need to pass in dependcies that are in scope in this func
		let updateRemovedParameter = function(removeIndex) {
			if(removeIndex > -1) {
				// update search param
				removed.splice(removeIndex, 1);
				let removedParams = "";
				if(removed.length) {
					removedParams = '?' + removed.join("&");
				}
				history.push({
					removed: removedParams
				});
			}
		};

		if("location" in history && history.location.removed) {
			removed = history.location.removed.slice(1).split('&');
			let excludeIndex = removed.findIndex(query => query.indexOf('exclude') > -1);
			if(excludeIndex > -1) {
				let excludedId = removed[excludeIndex].split('=')[1];
				this.filterUser(excludedId);
				updateRemovedParameter(excludeIndex, removed);
			}
		}
	}

	filterUser(userId) {
		let users = this.state.users;
		if(users) {
			/* eslint-disable eqeqeq */
			users = users.filter(user => user.id != userId);
			/* eslint-enable eqeqeq */
			this.setState({users: users});
		}
	}

	render() {
		let { order, orderBy, users, columnData, selectedUser } = this.state,
			{ classes, theme, width, banner, bannerHeight, signalsFirstEnabled /*, snackbarOpen, tallSnackbar*/ } = this.props,
			style,
			fabTop,
			fabClassName,
			fabClassNames = [classes.fab],
			firstLoaded = this.state.users !== null;

		fabTop = (banner && bannerHeight ? bannerHeight : 0) +
			(signalsFirstEnabled ? (width.includes("md") ?
				theme.spacing(15.625) : width.includes("sm") ?
					theme.spacing(11.375) : width.includes("xs") ? theme.spacing(15.875) : theme.spacing(9)) : 0) +
			((width.includes("xs") ? 8.375 : 10) * theme.spacing(1));
		style = {top: fabTop};

		fabClassName = clsx(fabClassNames);

		if (selectedUser) {
			return (
				// don't overwrite browser stack, push=true
				<Redirect push to={{
					pathname: `/accounts/${selectedUser.account_id}/users/${selectedUser.id}/edit`
				}}/>
			);
		} else {
			return (
				<PaddedLayout
					noTopPad
					isLarge
					className={classes.paddedLayout}>
					<SearchForm
						searchHandler={this.searchHandler}
						clearHandler={this.clearHandler}
						formClass={classes.searchForm}
						fieldClass={classes.searchField} />
					<Link to={`/users/add`} tabIndex={-1}>
						<Media query={{minWidth: theme.breakpoints.values.sm}}>
							{matches =>
								matches ? (
									<Fab
										variant="extended"
										color="primary"
										aria-label="Add User"
										className={fabClassName}
										style={style}>
										<FontAwesomeIcon
											title="add"
											icon={["far", "plus"]}
											className={classes.fabExtendedIcon} /> Add User
									</Fab>
								) : (
									<Fab
										color="primary"
										aria-label="Add User"
										className={fabClassName}
										style={style}>
										<FontAwesomeIcon
											title="add"
											icon={["far", "plus"]} />
									</Fab>
								)
							}
						</Media>
					</Link>
					{(firstLoaded) ? (
						(this.state.users && this.state.users.length) ? (
							<div>
								<Paper className={classes.paper}>
									<div className={classes.tableWrapper}>
										<Table>
											<TableSortableHeader
												order={order}
												orderBy={orderBy}
												onRequestSort={this.handleRequestSort}
												columnData={columnData}
												className={classes.headerRow}
												cellClassName={classes.headerCell} />
											<InfiniteScroll
												element="tbody"
												className={classes.userBody}
												initialLoad={false}
												pageStart={1}
												loader={null}
												loadMore={this.loadItems}
												hasMore={this.state.hasMoreItems && !this.state.isLoading}>

												{users.sort(this.getSorting(order, orderBy)).map(n => {
													let iconType = n.activated ? "fas" : "far",
														iconCls = n.activated ? classes.statusActive : classes.statusInactive;

													return (
														<TableRow
															key={n.id}
															hover
															className={`${classes.userRow} ${!n.activated ? classes.userSecondary : null}`}
															onClick={() => this.handleRowClick(n)}
															onKeyPress={() => this.handleRowClick(n)}
															tabIndex={0}>
															<TableCell className={classes.userCell} component="th" scope="row">
																<Grid container alignItems="center" className={classes.avatarGrid}>
																	<Grid item className={classes.avatarGridItem}>
																		<Avatar
																			authorName={n.name}
																			authorImage={n.avatar} />
																	</Grid>
																	<Grid item className={classes.userNameGridItem}>
																		<Typography
																			className={classes.titleCol}
																			color={!n.activated ? "textSecondary" : "initial"} noWrap>
																			{n.name}<br />{n.title}
																		</Typography>
																	</Grid>
																</Grid>
															</TableCell>
															<Media query={{minWidth: theme.breakpoints.values.sm}}>
																{matches =>
																	matches ? (
																		<TableCell className={classes.userCell} component="th" scope="row">{n.user_type_str}</TableCell>
																	) : (null)
																}
															</Media>
															<Media query={{minWidth: theme.breakpoints.values.sm}}>
																{matches =>
																	matches ? (
																		<TableCell className={classes.userCell} component="th" scope="row">
																			<Timestamp
																				className={null}
																				time={n.created_at}
																				format="ll" palette={!n.activated ? "secondary" : "primary"} />
																		</TableCell>
																	) : (null)
																}
															</Media>
															<Media query={{minWidth: theme.breakpoints.values.lg}}>
																{matches =>
																	matches ? (
																		<TableCell className={classes.userCell} component="th" scope="row">
																			<Timestamp time={n.last_login} format="ll" palette={!n.activated ? "secondary" : "primary"} />
																		</TableCell>
																	) : (null)
																}
															</Media>
															<TableCell className={`${classes.userCell} ${classes.statusCell}`} component="th" scope="row">
																<FontAwesomeIcon
																	title="status indicator"
																	icon={[iconType, "circle"]}
																	className={`${classes.statusIcon} ${iconCls}`} />
																{n.activated ? "Active" : "Inactive"}
															</TableCell>
														</TableRow>
													);
												})}
											</InfiniteScroll>
										</Table>
									</div>
								</Paper>
								{this.state.isLoading ?
									<LoadingIndicator /> : null
								}
							</div>
						) : (
							<FullscreenMessage message="No Results" />
						)
					) : (
						<div>
							<ReactPlaceholder
								ready={false}
								customPlaceholder={itemPlaceHolder}
								showLoadingAnimation={!this.state.fidoError}>
								<span></span>
							</ReactPlaceholder>
							<ReactPlaceholder
								ready={false}
								customPlaceholder={itemPlaceHolder}
								showLoadingAnimation={!this.state.fidoError}>
								<span></span>
							</ReactPlaceholder>
						</div>
					)}
				</PaddedLayout>
			);
		}
	}
}

UserManager.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func,
	/*snackbarOpen: PropTypes.bool,
	tallSnackbar: PropTypes.bool,*/
	resetHeader: PropTypes.func,
	updateHeader: PropTypes.func,
	banner: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	bannerHeight: PropTypes.number,
	signalsFirstEnabled: PropTypes.bool
};

export default withStyles(styles)(withWidth()(withTheme(UserManager)));
