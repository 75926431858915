import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Timestamp from "./Timestamp";
import Constants from "../utils/Constants";

import {
    withStyles,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Collapse,
    Button,
} from '@material-ui/core';

const styles = theme => ({
	root: {
		marginTop: theme.spacing(2)
	},

	row: {
		height: "auto",

		"& td": {
			borderBottom: "none",
			verticalAlign: "top",
			paddingLeft: theme.spacing(.5),

			"&:last-child": {
				width: "90%"
			},

			"& p": {
				fontSize: "0.93rem",
				color: theme.palette.text.secondary
			}
		}
	},

	button: {
		marginLeft: theme.spacing(1) / -2
	}
});

class EmailHeaderInfo extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			collapsed: true
		};
		this.toggleDetails = this.toggleDetails.bind(this);
	}

	toggleDetails() {
		this.setState({collapsed: !this.state.collapsed});
	}

	render() {
		let {collapsed} = this.state,
			{classes, headers} = this.props,
			splitAddresses = (addresses) => {
				if (addresses) {
					return addresses.replace(/,/ig, '\n').split('\n').map((item, key, array) => {
						let name,
							address,
							match = item.match(Constants.ACTIVITY.EMAIL.FROM_REGEX);

						// 0 is the full string, 1 is name, 2 is email
						if (match) {
							address = match[2];
							name = match[1].trim();
						}

						return <span key={key}><b>{match ? name : null}</b>{match ? " " + address : item}{key < array.length-1 ? "," : null}<br/></span>;
					});
				}
			};

		return (
			<div className={classes.root}>
				{headers.to ?
					<Table padding="dense">
						<TableBody>
							<TableRow className={classes.row}>
								<TableCell>
									<Typography>To:</Typography>
								</TableCell>
								<TableCell>
									<Typography>{splitAddresses(headers.to)}</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table> : null
				}
				<Collapse in={!collapsed}>
					<Table padding="dense">
						<TableBody>
							{headers.cc ?
								<TableRow className={classes.row}>
									<TableCell>
										<Typography>Cc:</Typography>
									</TableCell>
									<TableCell>
										<Typography>{splitAddresses(headers.cc)}</Typography>
									</TableCell>
								</TableRow> : null
							}
							{headers.bcc ?
								<TableRow className={classes.row}>
									<TableCell>
										<Typography>Bcc:</Typography>
									</TableCell>
									<TableCell>
										<Typography>{splitAddresses(headers.bcc)}</Typography>
									</TableCell>
								</TableRow> : null
							}
							{headers.to || headers.cc || headers.bcc ?
								<TableRow className={classes.row}>
									<TableCell>
										<Typography>Sent:</Typography>
									</TableCell>
									<TableCell>
										<Timestamp time={headers.sent} relative={false} reducedSize={false} palette="primary" />
									</TableCell>
								</TableRow> : null
							}
						</TableBody>
					</Table>
				</Collapse>
				{headers.to || headers.cc || headers.bcc ?
					<Button size="small" color="primary" className={classes.button} onClick={this.toggleDetails}>
						{collapsed ? "show details" : "hide details"}
					</Button> : null
				}
			</div>
		);
	}
}

EmailHeaderInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	headers: PropTypes.object.isRequired
};

export default withStyles(styles)(EmailHeaderInfo);
