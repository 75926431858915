import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
	heading: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		fontSize: "0.9rem",
		textTransform: "uppercase",
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.grey[200]
	}
});

class Heading extends Component {
	render() {
		let {classes, className} = this.props;
		let cls = `${classes.heading} ${className}`;
		return (
			<Typography variant="h3" className={cls}>{this.props.title}</Typography>
		);
	}
}

Heading.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string,
	className: PropTypes.string
};

export default withStyles(styles)(Heading);