import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import {TextBlock, RoundShape} from 'react-placeholder/lib/placeholders';

import Comment from './Comment.js';
import ActivityActions from './ActivityActions.js';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import Constants from "../utils/Constants";
import PrimaryTextButton from "./PrimaryTextButton";

import { withStyles, Divider, Typography, Grid } from '@material-ui/core';

const styles = theme => ({
	commentsNote: {
		marginTop: "20px"
	},

	bottomRowButton: {
		marginTop: theme.spacing(1.25),
	},

	secondary: {
		color: theme.palette.text.secondary,
		fontSize: "0.9rem",
	},

	buttonGrid: {
		paddingTop: theme.spacing(1)
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const commentPlaceholder = (
	<div style={{display: "flex", marginTop: 25}}>
		<div style={{marginRight: 10}}>
			<RoundShape color={placeholderColor} style={{height: 48, width: 48}} />
		</div>
		<div style={{flex: 1}}>
			<TextBlock rows={2} color={placeholderColor} />
		</div>
	</div>
);

class Comments extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			loadMore: false
		};
	}

	getComments(comments, numberToShow, allowViewMore, commentsNoteCls, ready) {
		let commentsArray = [],
			{classes} = this.props;

		if (comments && comments.length) {
			if (numberToShow == null || numberToShow > comments.length || this.state.loadMore) {
				numberToShow = comments.length;
			}

			// Dynamic length calls for "for" loop
			for (let i=0; i<numberToShow; i++) {
				commentsArray.push(<Comment ready={ready} key={comments[i].id} {...comments[i]} />);
			}

			if (!allowViewMore) {
				let more = comments.length-1;
				if (more > 0) {
					commentsArray.push(
						<Typography
							key="commentsCount"
							onClick={this.props.moreCommentsClickHandler} // TODO: comments should prob be refactored to break some of this logic apart
							className={`${classes.secondary} ${commentsNoteCls}`}>{more} more comments</Typography>
					);
				}
			}
		}

		if (allowViewMore) {
			let x = comments.length - commentsArray.length;
			commentsArray.push(
				<Grid
					container
					key="buttonContainer"
					justify="space-between"
					className={classes.buttonGrid}>
					<Grid item>
						{!this.state.loadMore && commentsArray.length !== comments.length && x > 0 ? (
							<PrimaryTextButton
								offset="left"
								onClick={() => this.setState({loadMore: true})}
								className={classes.bottomRowButton}>
								{x} More Comments
							</PrimaryTextButton>
						) : null}
					</Grid>
					<Grid item>
						<div className={classes.bottomRowButton}>
							{this.props.bottomRowButton}
						</div>
					</Grid>
				</Grid>
			);
		}

		return commentsArray;
	}

	render() {
		let {ready, fidoError, isActivityDeleted} = this.props;

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					let {actions, classes, className, comments, numberToShow, allowViewMore, addCommentHandler} = this.props;
					return (
						<div className={`${className}`}>
							{authenticatedUser ? (
								<div>
									<Divider />
									<ActivityActions
										actions={actions}
										ready={ready}
										fidoError={fidoError}
										addCommentHandler={addCommentHandler}
										isActivityDeleted={isActivityDeleted} />
								</div>
							) : (
								<ReactPlaceholder type='textRow' ready={ready} showLoadingAnimation={!fidoError}>
									<Divider />
									<Typography className={`${classes.secondary} ${classes.commentsNote}`}>
										Sign in for comments and actions
									</Typography>
								</ReactPlaceholder>
							)}
							{authenticatedUser && comments ?
								this.getComments(comments, numberToShow, allowViewMore, classes.commentsNote, ready)
								: <ReactPlaceholder
									ready={ready}
									customPlaceholder={commentPlaceholder}
									showLoadingAnimation={!fidoError}><span></span></ReactPlaceholder>
							}
						</div>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

Comments.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	ready: PropTypes.bool,
	actions: PropTypes.array,
	comments: PropTypes.array,
	numberToShow: PropTypes.number,
	addCommentHandler: PropTypes.func,
	allowViewMore: PropTypes.bool,
	fidoError: PropTypes.bool,
	moreCommentsClickHandler: PropTypes.func,
	bottomRowButton: PropTypes.object,
	isActivityDeleted: PropTypes.bool
};

export default withStyles(styles)(Comments);
