import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	withStyles,
	Button,
	Checkbox,
	Radio,
	Grid,
	ListItemText,
	MenuItem,
	Menu,
	Chip,
	Divider,
	Tabs,
	Tab,
	withTheme,
} from '@material-ui/core';

const styles = theme => ({
	hideItem: {
		visibility: 'hidden',
		minHeight: 0,
		height: 0,
		padding: 0
	},

	menuItem: {
		backgroundColor: 'transparent !important',
		overflow: "visible",
		whiteSpace: "inherit",
		minHeight: theme.spacing(3.5),
		minWidth: theme.spacing(25),

		"&:hover": {
			backgroundColor: 'transparent !important'
		}
	},

	singleSelectMenuItem: {
		minHeight: theme.spacing(3.5),
		minWidth: theme.spacing(18.75),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},

	menuItemFlex: {
		display: 'inline-flex',
		maxWidth: theme.spacing(12.5)
	},

	chipSelected: {
		color: "#fff",
		backgroundColor: "#707070",

		"&:focus": {
			backgroundColor: "#707070 !important"
		}
	},

	chipIcon: {
		'--fa-primary-color': theme.palette.common.white,
		'--fa-primary-opacity': 1,
		'--fa-secondary-color': theme.palette.primary.main,
		'--fa-secondary-opacity': 1
	},

	chip: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2)
	},

	checkbox: {
		padding: 0
	},

	tab: {
		minWidth: theme.spacing(9),
	},

	down: {
		paddingLeft: theme.spacing(.5)
	},

	actionButton: {
		margin: theme.spacing(1)
	},

	menu: {
		paddingBottom: 0
	},

	menuPaper: {
		maxWidth: theme.spacing(50)
	}
});

class FilterSelect extends Component {
	constructor(props, context) {
		super(props, context);

		this.initialState = {
			anchorEl: null,
			selected: [],
			allSelected: false,
			selectedCount: 0,
			alphaSelected: "abcdef",
			defaults: null
		};
		this.state = Object.assign({}, this.initialState);

		this.intializeOptions = this.intializeOptions.bind(this);
		this.isSelected = this.isSelected.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
		this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.alphaHandler = this.alphaHandler.bind(this);
	}

	componentDidMount() {
		this.intializeOptions();
	}

	componentDidUpdate(prevProps, prevState) {
		let {options, shouldReset} = this.props,
			reset = shouldReset(prevProps);

		if (reset === true ||
			prevProps.options == null || prevProps.options.length !== options.length) {
			this.setState({
				defaults: this.initialState.defaults
			}, this.intializeOptions);
		}
	}

	intializeOptions(reset, event) {
		let selected = [],
			{allSelected, defaults} = this.state,
			{options, defaultSelections, resetValue} = this.props;

		if (reset) {
			defaults.forEach(option => selected.push(option));
		}

		if (!defaults || defaults && defaults.length === 0) {
			defaultSelections = Array.isArray(defaultSelections) ? defaultSelections : defaultSelections.split(",");
			defaultSelections.forEach(option => selected.push(option));
		}

		allSelected = selected.length > 0 && options.length === selected.length;

		this.setState({
			selected: selected,
			allSelected: allSelected,
			selectedCount: selected.length,
			defaults: resetValue || options
		}, () => {
			if (reset) {
				let {selected, allSelected} = this.state;
				this.update(event, selected, allSelected);
			}
		});
	}

	isSelected(option) {
		let {selected} = this.state,
			{idAttr, labelAttr} = this.props;

		return selected.includes(option) ||
			selected.find(item => item[idAttr] === option[idAttr] || item === option[idAttr]) ? true : false ||
			selected.find(item => item[labelAttr] === option[labelAttr] || item === option[labelAttr]) ? true : false;
	}

	handleMenuClick(event) {
		this.setState({
			anchorEl: event.currentTarget
		});
	}

	handleMenuItemClick(event, option) {
		let {allSelected, selected} = this.state,
			{options, singleSelect, idAttr, labelAttr} = this.props;

		if (!singleSelect) {
			if (this.isSelected(option)) {
				let filteredSelected = selected.filter(item => {
					return item !== option &&
						item !== option[idAttr] &&
						item[idAttr] !== option[idAttr] &&
						item !== option[labelAttr] &&
						item[labelAttr] !== option[labelAttr];
				});
				selected = filteredSelected;
			} else {
				selected.push(option);
			}

			allSelected = selected.length > 0 && options.length === selected.length;
		} else {
			selected = [];
			selected.push(option);
			allSelected = false;
		}

		this.update(event.nativeEvent, selected, allSelected);
	}

	handleSelectAllClick(event) {
		let {selected, allSelected} = this.state,
			{options} = this.props;

		selected = allSelected ? [] : options.map(option => option);
		this.update(event.nativeEvent || event, selected, !allSelected);
	}

	update(nativeEvent, selected, allSelected) {
		this.setState({
			selected: selected,
			allSelected: allSelected,
			selectedCount: selected.length
		}, () => {
			// if nothing is selected, don't need to call handleUpdate
			if (this.state.selected.length) {
				this.handleUpdate(nativeEvent);
			}
		});
	}

	handleClose(event) {
		let {selected} = this.state,
			{singleSelect} = this.props;

		if (!selected.length && !singleSelect) {
			this.handleReset(event);
		}

		this.setState({anchorEl: null});
	}

	handleUpdate(event) {
		let value = [],
			{allSelected} = this.state,
			{options, singleSelect, idAttr, name} = this.props;

		if (!allSelected) {
			options.forEach(option => {
				if (this.isSelected(option)) {
					value.push(option[idAttr] || option);
				}
			});
		}

		event.target.name = name;
		event.target.value = value;

		if (singleSelect) {
			this.handleClose();
		}

		this.props.handleUpdate(event);
	}

	handleReset(event) {
		this.intializeOptions(true, event.nativeEvent);
	}

	alphaHandler(event, alphaGroup) {
		this.setState({alphaSelected: alphaGroup});
	}

	render() {
		let wrapMaxAmount = 6,
			{ classes, className, options, menuTitle, includeSelectAll, selectAllTitle, idAttr, labelAttr,
				singleSelect, useChips, includeCustomDates, customDatesTitle, useAlphaGroups, singleColumn} = this.props,
			{ anchorEl, selected, allSelected, selectedCount, alphaSelected } = this.state,
			titleDisplay = !singleSelect && selectedCount > 0 ? `${menuTitle} (${selectedCount})` : menuTitle,
			dateRangeIndex = options.length ? options.length + 1 : 0;

		let menuItems = (
			<div>
				{includeSelectAll ? (
					<div>
						<MenuItem
							className={classes.menuItem}
							key="select-all"
							selected={allSelected}
							onClick={this.handleSelectAllClick} >
							<Checkbox className={classes.checkbox} checked={allSelected} color="primary"/>
							<ListItemText>{selectAllTitle || "Select All"}</ListItemText>
						</MenuItem>
						<Divider />
					</div>
				) : null}
				{useAlphaGroups ? (
					<Tabs
						indicatorColor="primary"
						value={alphaSelected}
						onChange={this.alphaHandler}
						classes={{
							// mui-fixed so doesn't jump when menu's open https://github.com/mui-org/material-ui/issues/10000
							root: "mui-fixed",
							indicator: classes.tab
						}}>
						<Tab className={classes.tab} label="A-F" value="abcdef" />
						<Tab className={classes.tab} label="G-L" value="ghijkl" />
						<Tab className={classes.tab} label="M-R" value="mnopqr" />
						<Tab className={classes.tab} label="S-Z" value="stuvwxz" />
					</Tabs>
				) : null}
				{options.map((option, idx) => {
					let isSelected = this.isSelected(option);
					return (
						<MenuItem
							key={option[idAttr] || idx}
							selected={isSelected}
							onClick={event => this.handleMenuItemClick(event, option)}
							className={useAlphaGroups && alphaSelected.indexOf(option[labelAttr].charAt(0).toLowerCase()) === -1 ?
								classes.hideItem : !useAlphaGroups && options.length > wrapMaxAmount &&
								!singleColumn ? `${classes.menuItemFlex} ${classes.menuItem}` :
									!singleSelect ? classes.menuItem : classes.singleSelectMenuItem}>
							{!singleSelect && !useChips ?
								<Checkbox
									className={classes.checkbox}
									checked={isSelected}
									color="primary"/>
								: null}
							{useChips ?
								<Chip
									clickable
									className={
										useAlphaGroups && alphaSelected.indexOf(option[labelAttr].charAt(0).toLowerCase()) === -1 ?
											classes.hideItem : selected.includes(option) ? `${classes.chipSelected} ${classes.chip}` : classes.chip
									}
									icon={
										selected.includes(option) ? (
											<FontAwesomeIcon className={classes.chipIcon} icon={["fad", "check-circle"]} size="2x" />
										) : null
									}
									label={option[labelAttr]} />
								: <ListItemText>{option[labelAttr]}</ListItemText>
							}
						</MenuItem>
					);
				})}
				{includeCustomDates ? (
					<MenuItem
						key="custom-dates"
						selected={selected.indexOf(dateRangeIndex) !== -1}
						onClick={event => this.handleMenuItemClick(event, dateRangeIndex)} >
						<Radio checked={selected.indexOf(dateRangeIndex) !== -1} color="primary"/>
						<ListItemText>{customDatesTitle || "Custom date range"}</ListItemText>
						{
							// TODO: Date fields go here
						}
					</MenuItem>
				) : null}
				{!singleSelect ? (
					<React.Fragment>
						<Divider />
						<Grid
							container
							justify="space-between"
							alignItems="center">
							<Grid item>
								<Button
									className={classes.actionButton}
									color="secondary"
									onClick={this.handleReset}>
									Reset
								</Button>
							</Grid>
							<Grid item>
								<Button
									className={classes.actionButton}
									color="primary"
									onClick={this.handleClose}>
									Done
								</Button>
							</Grid>
						</Grid>
					</React.Fragment>
				) : null}
			</div>
		);

		return (
			<div className={className}>
				<Button
					onClick={event => this.handleMenuClick(event)} >
					<ListItemText primary={titleDisplay} />
					<FontAwesomeIcon className={classes.down} icon={["far", "angle-down"]} />
				</Button>
				<Menu
					keepMounted
					onClose={this.handleClose}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					getContentAnchorEl={null}
					MenuListProps={{
						classes: {padding: classes.menu}
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					PopoverClasses={{
						paper: classes.menuPaper
					}}>
					{menuItems}
				</Menu>
			</div>
		);
	}
}

FilterSelect.defaultProps = {
	idAttr: "id",
	labelAttr: "name",
	defaultSelections: []
};

FilterSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	options: PropTypes.array.isRequired,
	defaultSelections: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
	location: PropTypes.object.isRequired,
	shouldReset: PropTypes.func.isRequired,
	resetValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	handleUpdate: PropTypes.func.isRequired,
	menuTitle: PropTypes.string,
	includeSelectAll: PropTypes.bool,
	selectAllTitle: PropTypes.string,
	singleSelect: PropTypes.bool,
	singleColumn: PropTypes.bool,
	useChips: PropTypes.bool,
	includeCustomDates: PropTypes.bool,
	customDatesTitle: PropTypes.string,
	useAlphaGroups: PropTypes.bool,
	className: PropTypes.string,
	idAttr: PropTypes.string,
	labelAttr: PropTypes.string,
	name: PropTypes.string
};

export default withStyles(styles)(withTheme(FilterSelect));
