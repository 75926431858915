import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fido from '../utils/Fido';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import PaddedLayout from "../containers/PaddedLayout";
import SetPasswordForm from "../components/SetPasswordForm";
import Constants from '../utils/Constants';

import { withStyles, Paper } from '@material-ui/core';

const styles = theme => ({
	paper: {
		padding: theme.spacing(3),

		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(10),
			paddingRight: theme.spacing(10)
		}
	}
});

class ResetPassword extends Component {
	constructor(props, context) {
		super(props, context);
		this.submit = this.submit.bind(this);
		this.checkCode = this.checkCode.bind(this);
	}

	componentDidMount() {
		this.props.updateHeader({
			hideHeaderbar: true,
			hideMargin: true,
			title: "Set Your Social Sentinel Password"
		});

		// don't make the network call if local dev
		if (!Constants.ENV.IS_LOCAL) {
			this.checkCode();
		}
	}

	// TODO: once we support updating password from within the app
	/*componentDidUpdate(prevProps) {
		if (this.props.authenticatedUser !== prevProps.authenticatedUser && this.props.authenticatedUser) {
			this.props.updateHeader({title: "reset password"});
		}
	}*/

	componentWillUnmount() {
		//if (this.props.authenticatedUser) {
		this.props.resetHeader();
		//}
	}

	checkCode() {
		let path = this.props.location.pathname;
		let poodle = new Fido();
		poodle.fetch("/reset-check/"+path.substring(path.lastIndexOf("/")+1), {
			method: "GET"
		})
			.then((data) => {
				if (data.messages.success.length > 0) {
					// If we have a message, in this case, it means we have an issue
					this.props.showSnackbar(data.messages.success[0]);
					this.props.evictUser();
					this.props.history.push("/login");
				}
			})
			.catch(error => {
				return this.props.showSnackbar(error.message);
			});
	}

	submit(values) {
		let path = this.props.location.pathname;

		values.hash = path.substring(path.lastIndexOf("/")+1);
		values.password_confirmation = values.password; // HACK so we can re-use some legacy php for password reset

		let poodle = new Fido();
		// Using poodle cause it's a throw away, like an avalanche poodle - don't want to abort call when unmounted
		poodle.fetch("/set-password", {
			method: "PUT",
			body: JSON.stringify(values)
		}, "Setting Password...", 'Password set.')
			.then((data) => {
				if(data.messages.error.length === 0) {
					this.props.evictUser();
					this.props.history.push("/login");
				}
			})
			.catch(error => {
				return this.props.showSnackbar(error.message);
			});
	}

	render() {
		let {classes} = this.props;
		return (
			<PaddedLayout>
				<Paper className={classes.paper}>
					<SetPasswordForm title="Set New Password" submitHandler={this.submit} />
				</Paper>
			</PaddedLayout>
		);
	}
}

ResetPassword.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	updateSnackbarMessage: PropTypes.func,
	evictUser: PropTypes.func,
	authenticatedUser: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <ResetPassword {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "ResetPassword";

export default withStyles(styles)(React.forwardRef(dNameFunc));
