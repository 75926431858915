import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Constants from "../utils/Constants";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import RateAlertDialog from "./RateAlertDialog";

import { withStyles, withWidth, Tooltip, Button } from '@material-ui/core';

const styles = theme => ({
	tooltip: {
		maxWidth: 130
	}
});

class RateAlertButton extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			rateAlertOpen: false
		};

		this.toggleRateAlertDialog = this.toggleRateAlertDialog.bind(this);
	}

	toggleRateAlertDialog() {
		this.setState({rateAlertOpen: !this.state.rateAlertOpen});
	}

	render() {
		let {rateAlertOpen} = this.state,
			{classes, width, category, auditTrailRated, rateHandler, rating} = this.props;

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return authenticatedUser && category === Constants.ACTIVITY.CATEGORIES.ALERTS.ID ? (
						<div>
							<Tooltip
								placement="top"
								disableFocusListener
								title="It's very quick to rate an alert and helps us train our Sentinel Search Library to be more responsive to your community."
								classes={{tooltip: classes.tooltip}}>
								<Button
									color="primary"
									onClick={this.toggleRateAlertDialog}>
									{auditTrailRated.some(rating => rating.user.id === authenticatedUser.id) ?
										`Update Rating` : `Rate${!width.includes("xs") ? " This" : ""} Alert`
									}
								</Button>
							</Tooltip>
							<RateAlertDialog
								rating={rating}
								open={rateAlertOpen}
								rateHandler={rateHandler}
								closeHandler={this.toggleRateAlertDialog} />
						</div>) : null;
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

RateAlertButton.propTypes = {
	classes: PropTypes.object.isRequired,
	category: PropTypes.number.isRequired,
	auditTrailRated: PropTypes.array.isRequired,
	rateHandler: PropTypes.func.isRequired,
	rating: PropTypes.object,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

export default withStyles(styles)(withWidth()(RateAlertButton));