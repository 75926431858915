import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withStyles,
	Grid,
	Paper
} from '@material-ui/core';
import ReactPlaceholder from 'react-placeholder';
import { TextRow } from 'react-placeholder/lib/placeholders';
import InfiniteScroll from 'react-infinite-scroller';

import Constants from "../utils/Constants";
import FullscreenMessage from '../components/FullscreenMessage';
import LoadingIndicator from '../components/LoadingIndicator';

const styles = theme => ({
	headPaper: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,

		[theme.breakpoints.down('xs')]: {
			borderRadius: 0
		}
	},

	bodyPaper: {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,

		[theme.breakpoints.down('xs')]: {
			borderRadius: 0
		}
	},

	tableHead: {
		fontSize: "0.86rem",
		padding: theme.spacing(2),
		textTransform: "uppercase",
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.text.secondary,

		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1)
		}
	},

	row: {
		padding: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.grey[300]}`,

		"&:last-child": {
			borderBottom: "none"
		},

		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1)
		}
	},

	cell: {
		wordBreak: "break-word",
		wordWrap: "break-word",
		paddingRight: theme.spacing(2),

		"&:last-child": {
			paddingRight: 0
		},

		[theme.breakpoints.down('xs')]: {
			paddingRight: theme.spacing(1)
		}
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const placeholderRow = (
	<div>
		<TextRow color={placeholderColor} style={{width: "30%", display: "inline-block"}} />
		<TextRow color={placeholderColor} style={{width: "61%", marginLeft: "2%", display: "inline-block"}} />
		<TextRow color={placeholderColor} style={{width: "5%", marginLeft: "2%", display: "inline-block"}} />
	</div>
);
const itemPlaceHolder = (
	<div style={{marginTop: 16, padding: 16}}>
		{placeholderRow}
		{placeholderRow}
		{placeholderRow}
	</div>
);

class FauxTable extends Component {
	render() {
		let items = [],
			numHeadings,
			headItems = [],
			{classes, data, columnSizing, columns, fidoError, elevation, bodyClassName, headClassName,
				infiniteScroll, hasMoreItems, isLoading, loadNextPage, pageStart, noResultsStyle, noResultsMsg} = this.props;

		// all equal columns
		if (columnSizing == null) {
			columnSizing = [];
			numHeadings = columns.length;
			columns.forEach(column => {
				columnSizing.push({
					xs: 12/numHeadings,
					sm: 12/numHeadings,
					md: 12/numHeadings,
					lg: 12/numHeadings,
					xl: 12/numHeadings
				});
			});
		}

		let table = (
			<React.Fragment>
				<Paper className={classes.headPaper} elevation={elevation}>
					<Grid container wrap="nowrap" className={`${classes.tableHead} ${headClassName}`}>
						{columns.forEach((column, idx) => {
							headItems.push(
								<Grid item
									key={idx} {...columnSizing[idx]}
									className={column.headingClassName}>
									{column.label}
								</Grid>
							);
						})}
						{headItems}
					</Grid>
				</Paper>
				<Paper className={`${classes.bodyPaper} ${bodyClassName}`} elevation={elevation}>
					{data && data.length ? (
						data.forEach((item, idx) => {
							items.push(
								<Grid container key={idx} className={classes.row}>
									{columns.map((column, idx) => {
										return (
											<Grid item
												key={idx}
												{...columnSizing[idx]}
												className={`${classes.cell} ${column.dataClassName ? column.dataClassName : null}`}>
												{column.formatter ? column.formatter(item[column.id], item) : item[column.id]}
											</Grid>
										);
									})}
								</Grid>
							);
						})
					) : null}
					{items}
				</Paper>
				{isLoading ?
					<LoadingIndicator /> : null
				}
			</React.Fragment>
		);

		return data ? (
			data.length ?
				infiniteScroll ? (
					<InfiniteScroll
						pageStart={pageStart}
						loader={null}
						initialLoad={false}
						loadMore={loadNextPage}
						className={classes.tableBody}
						hasMore={hasMoreItems && !isLoading}>
						{table}
					</InfiniteScroll>
				) : table
				: <FullscreenMessage message={noResultsMsg} style={noResultsStyle} />
		) : (
			<ReactPlaceholder
				ready={false}
				customPlaceholder={itemPlaceHolder}
				showLoadingAnimation={!fidoError}>
				<span></span>
			</ReactPlaceholder>
		);
	}
}

FauxTable.defaultProps = {
	pageStart: 1,
	infiniteScroll: true,
	elevation: 1,
	noResultsMsg: "No Results"
};

FauxTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.array.isRequired,
	data: PropTypes.array,
	columnSizing: PropTypes.array,
	fidoError: PropTypes.bool,
	hasMoreItems: PropTypes.bool,
	isLoading: PropTypes.bool,
	loadNextPage: PropTypes.func,
	pageStart: PropTypes.number,
	infiniteScroll: PropTypes.bool,
	elevation: PropTypes.number,
	noResultsStyle: PropTypes.object,
	bodyClassName: PropTypes.string,
	headClassName: PropTypes.string,
	noResultsMsg: PropTypes.string
};

export default withStyles(styles)(FauxTable);
