import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {TextRow, RoundShape} from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';

import Fido from '../../utils/Fido';
import Constants from "../../utils/Constants";
import PaddedLayout from '../../containers/PaddedLayout.js';
import FullscreenMessage from '../../components/FullscreenMessage';
import UserEngagementTable from "./UserEngagementTable";

import { withStyles, Paper } from '@material-ui/core';

const styles = theme => ({
	root: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: theme.spacing(1.25)
		},

		[theme.breakpoints.up('xs')]: {
			paddingLeft: 10
		},

		[theme.breakpoints.up('sm')]: {
			paddingLeft: 0
		}
	},

	paddedLayout: {
		paddingBottom: 32
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const tablePlaceHolder = (
	<div style={{padding: 15}}>
		<div style={{display: "flex"}}>
			<div style={{marginRight: 10}}>
				<RoundShape color={placeholderColor} style={{height: 48, width: 48}} />
			</div>
			<div style={{flex: 1, margin: "auto"}}>
				<TextRow color={placeholderColor} style={{marginTop: 0}} />
			</div>
			<div style={{flex: 1, paddingLeft: 5, margin: "auto"}}>
				<TextRow color={placeholderColor} style={{marginTop: 0}} />
			</div>
			<div style={{flex: 1, paddingLeft: 5, margin: "auto"}}>
				<TextRow color={placeholderColor} style={{marginTop: 0}} />
			</div>
		</div>
		<div style={{display: "flex", marginTop: 15}}>
			<div style={{marginRight: 10}}>
				<RoundShape color={placeholderColor} style={{height: 48, width: 48}} />
			</div>
			<div style={{flex: 1, margin: "auto"}}>
				<TextRow color={placeholderColor} style={{marginTop: 0}} />
			</div>
			<div style={{flex: 1, paddingLeft: 5, margin: "auto"}}>
				<TextRow color={placeholderColor} style={{marginTop: 0}} />
			</div>
			<div style={{flex: 1, paddingLeft: 5, margin: "auto"}}>
				<TextRow color={placeholderColor} style={{marginTop: 0}} />
			</div>
		</div>
	</div>
);

class UserEngagementReport extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			sortDir: "desc",
			sortBy: "activityCount",
			usersEngagement: null,
			fidoError: false
		};

		this.fido = new Fido();
		this.sortHandler = this.sortHandler.bind(this);
	}

	componentDidMount() {
		let headerOptions = {
			title: 'User Engagement'
		};

		this.props.updateHeader(headerOptions);
		this.fetchData({
			query: {
				sortBy: this.state.sortBy,
				sortDir: this.state.sortDir,
				...Fido.getSearchParamsObject(this.props.location.search)
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.sortBy !== prevState.sortBy || this.state.sortDir !== prevState.sortDir) {
			this.fetchData({
				query: {
					sortBy: this.state.sortBy,
					sortDir: this.state.sortDir,
					previousDays: this.state.previousDays
				}
			});
		}
	}

	fetchData(opts) {
		this.fido.fetch("/reports/engagement", opts)
			.then((data) => {
				if (data) {
					// TEMP
					//data = userEngagementData;
					this.setState(data);
				}
			})
			// TODO: probably only want to show some errors as snackbars
			.catch(error => {
				this.setState({fidoError: true});
				return this.props.showSnackbar(error.message);
			});
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.props.resetHeader();
	}

	sortHandler(event, property) {
		let sortDir = "asc";

		if (this.state.sortBy === property) {
			sortDir = this.state.sortDir === "desc" ? "asc" : "desc";
		}

		this.setState({sortBy: property, sortDir: sortDir});
	}

	render() {
		let {classes} = this.props,
			{usersEngagement, sortBy, sortDir} = this.state;

		return (
			<PaddedLayout className={classes.paddedLayout} isLarge>
				{usersEngagement ?
					usersEngagement.length ?
						<Paper className={classes.root}>
							<UserEngagementTable
								sortDir={sortDir}
								sortBy={sortBy}
								users={usersEngagement}
								sortHandler={this.sortHandler} />
						</Paper>
						: <FullscreenMessage message="No Results" />
					: <Paper className={classes.root}>
						<ReactPlaceholder
							ready={false}
							customPlaceholder={tablePlaceHolder}
							showLoadingAnimation={!this.state.fidoError}>
							<span></span>
						</ReactPlaceholder>
					</Paper>
				}
			</PaddedLayout>
		);
	}
}

UserEngagementReport.propTypes = {
	classes: PropTypes.object.isRequired,
	resetHeader: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func,
	location: PropTypes.object
};

export default withStyles(styles)(UserEngagementReport);