import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Constants from "../utils/Constants";

import NumberFormatter from '../utils/NumberFormatter';

import { withStyles, Grid, Tooltip, Typography, withTheme } from '@material-ui/core';

const styles = theme => ({
	pointer: {
		cursor: "pointer"
	},

	container: {
		width: "auto"
	},

	item: {
		display: "flex"
	},

	circle: {
		width: 28,
		height: 28,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		//borderRadius: "50%",
		//border: "1px solid transparent",
		//backgroundColor: theme.palette.grey[200]
	},

	number: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		paddingRight: theme.spacing(2),
		color: theme.palette.secondary.slate
	},

	tightPackNumber: {
		paddingRight: theme.spacing(1)
	},

	densePackNumber: {
		paddingRight: theme.spacing(.7518796992481203)
	},

	tooltip: {
		marginLeft: -22
	},

	hidden: {
		display: "none"
	},

	textIndicator: {
		fontSize: "0.9rem",
		whiteSpace: "nowrap",
		marginLeft: theme.spacing(1)
	}
});

class UserActivityIndicators extends Component {
	getIcon(action, theme) {
		let config = Constants.ACTIVITY.AUDIT_TRAIL[action.toUpperCase()];
		return <FontAwesomeIcon icon={["fas", config.ICON]} color={theme.palette.secondary.slate} title={config.ID} />; //color={config.COLOR}
	}

	getTooltip(tip) {
		if (!this.props.disableTooltips) {
			if (Array.isArray(tip)) {
				let user,
					cnt = 1,
					max = 5,
					content = [];

				for(var item of tip) {
					if (cnt <= max) {
						user = item.user || item.sender || {name: item.user_name};
						content.push(<div key={`key-${cnt}`}>{user.name}</div>);
						cnt++;
					} else {
						content.push(<div key={`key-${cnt}`}>{tip.length - (cnt-1)} more...</div>);
						break;
					}
				}

				return <div>{content}</div>;
			} else {
				return <div>{tip}</div>;
			}
		}

		return " "; // because Tooltip requires a title
	}

	getValue(value) {
		if (Array.isArray(value)) {
			return value.length;
		}

		return value;
	}

	render() {
		let {classes, theme, clickHandler,
				removed, saved, shared, commented, viewed, rated, userViewed, userRated,
				disableTooltips, className, tightPack, densePack, noWrap} = this.props,
			numberClassName = `${classes.number} ${tightPack ? classes.tightPackNumber : densePack ? classes.densePackNumber : ''}`,
			removedValue = this.getValue(removed),
			commentedValue = this.getValue(commented),
			savedValue = this.getValue(saved),
			sharedValue = this.getValue(shared),
			viewedValue = this.getValue(viewed),
			ratedValue = this.getValue(rated),
			userViewedValue = this.getValue(userViewed),
			userRatedValue = this.getValue(userRated);

		return (
			<Grid container
				justify="flex-start"
				wrap={noWrap ? "nowrap" : "wrap"}
				onClick={clickHandler}
				className={`${classes.container} ${className} ${clickHandler ? classes.pointer : ''}`}>
				{removedValue ?
					<Tooltip
						placement="top"
						title={this.getTooltip(removed)}
						classes={{tooltip: classes.tooltip}}
						disableFocusListener={disableTooltips}
						disableHoverListener={disableTooltips}
						disableTouchListener={disableTooltips}>
						<Grid item className={classes.item}>
							<span className={classes.circle}>{this.getIcon(Constants.ACTIVITY.AUDIT_TRAIL.REMOVE.ID, theme)}</span>
							<span className={numberClassName}>{NumberFormatter.largeNumberFormat(removedValue)}</span>
						</Grid>
					</Tooltip> : null
				}
				{commentedValue ?
					<Tooltip
						placement="top"
						title={this.getTooltip(commented)}
						classes={{tooltip: classes.tooltip}}
						disableFocusListener={disableTooltips}
						disableHoverListener={disableTooltips}
						disableTouchListener={disableTooltips}>
						<Grid item className={classes.item}>
							<span className={classes.circle}>{this.getIcon(Constants.ACTIVITY.AUDIT_TRAIL.COMMENT.ID, theme)}</span>
							<span className={numberClassName}>{NumberFormatter.largeNumberFormat(commentedValue)}</span>
						</Grid>
					</Tooltip> : null
				}
				{savedValue ?
					<Tooltip
						placement="top"
						title={this.getTooltip(saved)}
						classes={{tooltip: classes.tooltip}}
						disableFocusListener={disableTooltips}
						disableHoverListener={disableTooltips}
						disableTouchListener={disableTooltips}>
						<Grid item className={classes.item}>
							<span className={classes.circle}>{this.getIcon(Constants.ACTIVITY.AUDIT_TRAIL.SAVE.ID, theme)}</span>
							<span className={numberClassName}>{NumberFormatter.largeNumberFormat(savedValue)}</span>
						</Grid>
					</Tooltip> : null
				}
				{sharedValue ?
					<Tooltip
						placement="top"
						title={this.getTooltip(shared)}
						classes={{tooltip: classes.tooltip}}
						disableFocusListener={disableTooltips}
						disableHoverListener={disableTooltips}
						disableTouchListener={disableTooltips}>
						<Grid item className={classes.item}>
							<span className={classes.circle}>{this.getIcon(Constants.ACTIVITY.AUDIT_TRAIL.SHARE.ID, theme)}</span>
							<span className={numberClassName}>{NumberFormatter.largeNumberFormat(sharedValue)}</span>
						</Grid>
					</Tooltip> : null
				}
				{ratedValue || viewedValue ?
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.textIndicator}>
							{viewedValue ? (
								<Tooltip
									placement="top"
									title={this.getTooltip(viewed)}
									classes={{tooltip: classes.tooltip}}
									disableFocusListener={disableTooltips}
									disableHoverListener={disableTooltips}
									disableTouchListener={disableTooltips}>
									<span>{viewedValue} View{viewed.length > 1 ? "s" : ""}</span>
								</Tooltip>
							) : null}
							{ratedValue ? `${viewedValue ? "  |  " : ""}${ratedValue} Rating${rated.length > 1 ? "s" : ""}` : ""}
						</Typography>
					</Grid> : null
				}
				{userViewedValue || userRatedValue ?
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.textIndicator}>
							{userViewedValue ? (
								`${userViewedValue} Alert View${userViewedValue > 1 ? "s" : ""}`
							) : null}
							{userRatedValue ? `${userViewedValue ? "  |  " : ""}${userRatedValue} Rating${userRatedValue > 1 ? "s" : ""}` : ""}
						</Typography>
					</Grid>	: null
				}
			</Grid>
		);
	}
}

UserActivityIndicators.defaultProps = {
	disableTooltips: false,
	noWrap: false
};

UserActivityIndicators.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	clickHandler: PropTypes.func,
	removed: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	saved:  PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	shared:  PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	commented:  PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	viewed:  PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	rated:  PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	userViewed:  PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	userRated:  PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	disableTooltips: PropTypes.bool,
	className: PropTypes.string,
	tightPack: PropTypes.bool,
	densePack: PropTypes.bool,
	noWrap: PropTypes.bool
};

export default withStyles(styles)(withTheme(UserActivityIndicators));
