import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress } from '@material-ui/core';

const styles = theme => ({
	loadingContainer: {
		display: "flex",
		justifyContent: "center",
		margin: theme.spacing(3)
	},

	loadingIndicator: {
		color: theme.palette.primary.main
	}
});

class LoadingIndicator extends Component {
	render() {
		let {classes, key} = this.props;
		return (
			<div key="loader" className={classes.loadingContainer}>
				<CircularProgress className={classes.loadingIndicator} key={key} />
			</div>
		);
	}
}

LoadingIndicator.propTypes = {
	classes: PropTypes.object.isRequired,
	key: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default withStyles(styles)(LoadingIndicator);
