import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from "./Avatar";
import FormattedPhone from "./FormattedPhone";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

import { Card, CardContent, CardActions, Typography, Button, withStyles } from '@material-ui/core';

const styles = theme => ({
	card: {
		minWidth: 250,
		boxShadow: "none",
		paddingBottom: theme.spacing(2),
		backgroundColor: theme.palette.common.white
	},

	title: {
		fontWeight: "bold"
	},

	content: {
		marginTop: 15,
		paddingBottom: 0
	},

	name: {
		marginTop: theme.spacing(1.25)
	},

	secondary: {
		color: theme.palette.text.secondary
	},

	jobTitle: {
		fontSize: "0.8rem"
	},

	actions: {
		justifyContent: "center",
		flexDirection: "column"
	},

	avatarWrapper: {
		display: "flex",
		justifyContent: "center"
	},

	button: {
		width: "80%",
		textTransform: "none",
		marginTop: theme.spacing(1)
	}
});

class SupportContact extends Component {
	render() {
		let {classes, csm, closeHandler} = this.props,
			{name, title, landline, email, avatar} = csm,
			phone = landline;

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
						<div>
							{csm ?
								<Card className={`${classes.card}`}>
									<CardContent className={classes.content}>
										<React.Fragment>
											<div className={classes.avatarWrapper}>
												<Avatar
													sizeFactor={2}
													authorName={name}
													authorImage={avatar} />
											</div>
											<Typography
												align="center"
												className={`${classes.secondary} ${classes.name}`}>{name}</Typography>
											<Typography
												align="center"
												className={`${classes.secondary} ${classes.jobTitle}`}>{title || "Client Support"}</Typography>
										</React.Fragment>
									</CardContent>
									<CardActions className={classes.actions}>
										{email ?
											<Button
												variant="contained"
												color="secondary"
												href={`mailto:${email}`}
												onClick={closeHandler}
												className={classes.button}>Send An Email</Button> : null
										}
										{phone ?
											<Button
												variant="contained"
												color="secondary"
												href={`tel:${phone}`}
												onClick={closeHandler}
												className={classes.button}>
												Call&nbsp;<FormattedPhone number={phone} />
											</Button> : null
										}
										{authenticatedUser && !authenticatedUser.ghosting ? (
											<Button
												id="IntercomChatButton"
												variant="contained"
												color="secondary"
												onClick={closeHandler}
												className={classes.button}>Chat Now</Button>
										) : null}
									</CardActions>
								</Card>
								: null
							}
						</div>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

SupportContact.propTypes = {
	csm: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	closeHandler: PropTypes.func
};

export default withStyles(styles)(SupportContact);
