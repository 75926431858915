import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Logo from "../assets/ss-logo.png";
import LogoPrimary from "../assets/ss-logo-primary.png";
import LogoSecondary from "../assets/ss-logo-secondary.png";
import LogoSecondaryLight from "../assets/ss-logo-secondary-light.png";

import { withStyles, withTheme } from '@material-ui/core';

const styles = theme => ({
	root: {
		maxHeight: theme.spacing(8)
	},

	logoOnly: {
		marginLeft: theme.spacing(-1.5)
	}
});

class Branding extends Component {
	render() {
		let {classes, className, logoOnly, secondary, light} = this.props;

		return (
			<div className={`${classes.root} ${className} ${logoOnly ? classes.logoOnly : null}`}>
				{logoOnly ?
					<img src={Logo} width="55" height="55" alt="" />
					:
					secondary ?
						light ?
							<img src={LogoSecondaryLight} width="175" height="47" alt="" />
							:
							<img src={LogoSecondary} width="240" height="65" alt="" />
						:
						<img src={LogoPrimary} width="175" height="86" alt="" />
				}
			</div>
		);
	}
}

Branding.defaultProps = {
	light: false,
	secondary: false
};

Branding.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	light: PropTypes.bool, // currently only pertains to secondary
	secondary: PropTypes.bool,
	logoOnly: PropTypes.bool
};

export default withStyles(styles)(withTheme(Branding));
