import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Heading from './Heading';
import ActivityAuditTrail from "./ActivityAuditTrail";

import { withStyles, Paper } from '@material-ui/core';

const styles = theme => ({
	heading: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	}
});

class UserActivityTrail extends Component {
	render() {
		let {classes, className, fidoError, userActivity} = this.props;
		return (
			<Paper className={className}>
				{userActivity != null ? (
					<Heading
						title="Team Actions"
						className={classes.heading} />
				) : null}
				<ActivityAuditTrail
					maxActivitiesShown={5}
					userActivity={userActivity}
					fidoError={fidoError} />
			</Paper>
		);
	}
}

UserActivityTrail.propTypes = {
	classes: PropTypes.object.isRequired,
	userActivity: PropTypes.array,
	fidoError: PropTypes.bool,
	className: PropTypes.string
};

export default withStyles(styles)(UserActivityTrail);
