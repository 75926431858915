import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import {RectShape, TextBlock, TextRow, RoundShape} from 'react-placeholder/lib/placeholders';
import { withRouter } from 'react-router-dom';

import Fido from '../utils/Fido';
import Constants from "../utils/Constants";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import ActivityList from "../components/ActivityList";

import { withStyles, Paper, Button } from '@material-ui/core';

const styles = theme => ({
	activityListItem: {
		width: "100%",
		'&:nth-child(n+2)': {
			marginTop: theme.spacing(2),
		}
	},

	placeholderItem: {
		paddingBottom: theme.spacing(3)
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const itemPlaceHolder = (
	<div>
		<RectShape color={placeholderColor} style={{height: 130, borderRadius: 0}} />
		<div style={{padding: "10px 20px 0 20px"}}>
			<TextBlock rows={2} color={placeholderColor} />
			<div style={{display: "flex", marginTop: 25}}>
				<div style={{marginRight: 10}}>
					<RoundShape color={placeholderColor} style={{height: 48, width: 48}} />
				</div>
				<div style={{flex: 1}}>
					<TextBlock rows={2} color={placeholderColor} />
				</div>
			</div>
			<TextRow color={placeholderColor} style={{height: 35, marginTop: 25}} />
		</div>
	</div>
);

class ActivitiesWrapper extends Component {
	constructor(props, context) {
		super(props, context);

		this.intervalFired = false;
		this.fido = new Fido();
		this.trendingTopics = [];
		this.maxTopicsDefault = 4;
		this.categoryParam = Fido.getSearchParam("category", this.props.location.search);

		this.state = {
			activities: null,
			hasMoreItems: false,
			lastPage: null,
			filter: this.buildFilterObj(),
			category: this.categoryParam || props.category,
			newCategory: this.categoryParam || props.category,
			fidoError: false,
			maxTopics: this.maxTopicsDefault
		};

		// Not carefully cleaning up when deleted activities are not undone b/c assuming component will remount, trashing all of it
		// before it becomes a memory leak issue. Can reevaluate a timed clean up if needed... in which case it would be better
		// to use actually caching infrastructure because it can age based & max-count queue (first in, first out) remove items
		this.recentlyRemoved = {};

		this.itemClicked = this.itemClicked.bind(this);
		this.addCommentThen = this.addCommentThen.bind(this);
		this.deleteThen = this.deleteThen.bind(this);
		this.restoreThen = this.restoreThen.bind(this);
		this.saveThen = this.saveThen.bind(this);
		this.shareThen = this.shareThen.bind(this);
		this.rateThen = this.rateThen.bind(this);
		this.loadItems = this.loadItems.bind(this);
		this.showMoreTopics = this.showMoreTopics.bind(this);
		this.viewTopicCluster = this.viewTopicCluster.bind(this);
		this.filter = this.filter.bind(this);
		this.restoreMissing = this.restoreMissing.bind(this);
		this.activitiesUpdated = this.activitiesUpdated.bind(this);

		this.fidoConfig = props.fidoConfig || {
			alerts: {
				opts: null,
				url: "/activities"
			},
			discussions: {
				opts: null,
				url: "/trending-topics"
			}
		};
	}

	buildFilterObj() {
		let obj = Fido.getSearchParamsObject();

		// since can be passed into the component
		if (obj.category === null) {
			obj.category = this.props.category;
		}

		return obj;
	}

	setFidoConfigOpts() {
		this.fidoConfig.alerts.opts = {query: {...this.state.filter}};
		this.fidoConfig.discussions.opts = {query: {...this.state.filter}};
		delete this.fidoConfig.discussions.opts.query.category; // don't want / need category
	}

	componentDidMount() {
		if (Object.keys(this.state.filter).length) {
			this.setFidoConfigOpts();
		}
		this.fetchData();

		if(this.isAutoReloading(true)) {
			// TODO - constants?
			let periodLength = 30000;
			this.intervalId = setInterval(() => {
				this.fetchData(true);
			}, periodLength);
		}
	}

	componentWillUnmount() {
		this.dropThem();
		if(this.isAutoReloading()) {
			clearInterval(this.intervalId);
		}
	}

	dropThem() {
		this.fido.dropIt();
	}

	componentDidUpdate(prevProps, prevState) {
		// This switches the query when the back/forward browser button is used
		let filterObj = this.buildFilterObj(),
			{filter} = this.state;

		if (JSON.stringify(filter) !== JSON.stringify(filterObj)) {
			this.filter(filterObj);
		}

		// If change in data during auto-reload, call attention to it
		if(this.isAutoReloading() && this.intervalFired) {
			this.intervalFired = false;
			const {activities} = this.state;
			const prevActivities = prevState.activities;
			// hasChanged if
			//   arrays dif length
			//   or if any one new activity doesn't have a corresponding old one
			//   or if any one new activity's ID doesn't equal the old one
			if (prevActivities) {
				let hasChanged = activities.length !== prevActivities.length || activities.some((activity, idx) => !(prevActivities[idx] && "id" in prevActivities[idx]) || activity.id !== prevState.activities[idx].id);

				if(hasChanged) {
					this.props.showSnackbar({
						message: "Activity list updated.",
						duration: 10000,
						action: (<Button size="small" onClick={() => {
							this.props.showSnackbar(false);
							window.scrollTo({top: 0});
						}} color="primary">Scroll to Top</Button>)
					});
				}
			}
		}
	}

	isAutoReloading = skipIntervalChk => this.props.authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.ACTIVITIES_RELOAD) && (skipIntervalChk || this.intervalId)

	// normal data load
	fetchData(intervalFired) {
		intervalFired = !!intervalFired;
		let opts,
			alertsConfig = this.fidoConfig.alerts,
			discussionsConfig = this.fidoConfig.discussions,
			fidoUrls = [alertsConfig.url];

		// so PositionMemory and InfiniteScroll play nicely
		if (this.props.setNumberOfPages) {
			alertsConfig.opts = this.props.setNumberOfPages(alertsConfig.opts);
		}

		if (this.state.category === "discussions" && this.fidoConfig.discussions) {
			fidoUrls.push(this.fidoConfig.discussions.url);
		}

		// create array of fido promises based on multiple calls we want to make
		let results = fidoUrls.map((url, index) => {
			// if activities, get the query opts
			if (index === 0) {
				opts = alertsConfig.opts;
			} else {
				opts = discussionsConfig.opts;
			}
			return this.fido.fetch(url, opts)
				.catch(error => {
					this.setState({fidoError: true});
					this.props.showSnackbar(error.message);
					return [];
				});
		});

		// only do this once all fido calls have returned
		Promise.all(results)
			.then(promiseResults =>
				promiseResults.reduce((resultingObj, apiResult, index) => {
					if (index === 0) {
						// newCategory ensures we only show category once the tab has switched
						resultingObj = Object.assign(resultingObj, apiResult, {newCategory: this.state.category});
					} else {
						// trending topics
						this.trendingTopics = apiResult;
						apiResult.map(topic => topic.isTopic = true);
						if (resultingObj.activities) {
							resultingObj.activities.unshift(...apiResult);
						}
					}

					if (this.props.updateHeader) {
						this.props.updateHeader(resultingObj);
					}

					return resultingObj;
				}, {})
			)
			.then(data => {
				if (data && data.activities) {
					this.intervalFired = intervalFired;
					this.setState(data, () => {
						if(!intervalFired) {
							this.props.restorePosition();
						}
					});
				}
			})
			.catch(error => console.log(error.message));
	}

	// infinite scroll paging
	loadItems(page) {
		// Check to make sure there is more to load
		if(this.state.hasMoreItems === true) {
			let url = `${this.fidoConfig.alerts.url}?page=${page}`;

			for (var key in this.state.filter) {
				url = `${url}&${key}=${this.state.filter[key]}`;
			}

			if (this.props.onTrackPage) {
				this.props.onTrackPage(page);
			}

			// Get the next page of data from the paginator
			this.fido.fetch(url)
				.then((data) => {
					if(data) {
						// Push the retrived activities into our activities array
						var activities = this.state.activities;
						data.activities.map((activity) =>
							activities.push(activity)
						);

						let update = {
							activities: activities
						};

						if(!data.hasMoreItems) {
							update.hasMoreItems = false;
						} else {
							update.hasMoreItems = true;
						}

						this.setState(update);
					}
				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	filter(filterObj) {
		this.setState({
			filter: filterObj,
			category: filterObj.category,
			activities: null,
			maxTopics: this.maxTopicsDefault
		}, () => {
			this.dropThem();
			this.setFidoConfigOpts();
			this.fetchData();
		});
	}

	viewTopicCluster(topic) {
		this.props.history.push(`/topics/${topic.id}`);
	}

	itemClicked(ev, id) {
		if (this.props.onTrackItem) {
			this.props.onTrackItem(id);
		}
	}

	activitiesUpdated(activities) {
		this.setState({activities: activities});
	}

	addCommentThen(data) {
		let activity = data.activity,
			activities = this.state.activities,
			index = activities.findIndex((item) => {
				return item.id === activity.id;
			});

		activities[index] = activity;
		this.activitiesUpdated(activities);
	}

	deleteThen(activities) {
		this.activitiesUpdated(activities);
	}

	restoreThen(activities) {
		this.activitiesUpdated(activities);
	}

	saveThen(activities) {
		this.activitiesUpdated(activities);
	}

	shareThen(activities) {
		this.activitiesUpdated(activities);
	}

	rateThen(activities) {
		this.activitiesUpdated(activities);
	}

	restoreMissing() {
		// reload data if can't find activity to restore
		if (Object.keys(this.state.filter).length) {
			this.setFidoConfigOpts();
		}
		this.fetchData();
	}

	showMoreTopics() {
		this.setState({maxTopics: this.trendingTopics.length});
	}

	render() {
		let {classes, history, location, showSnackbar, children, listClasses, placeHolderClassName} = this.props,
			{category, newCategory, activities, hasMoreItems, fidoError, maxTopics, lastPage} = this.state,
			// because infinite scroll wants to load & update without interrupting UI,
			// this flag is really only first load. To trigger placeholder again, need to
			// set activities to null;
			firstLoaded = activities !== null;

		return (
			<div>
				{children}
				{firstLoaded ? (
					<ActivityList
						maxTopics={maxTopics}
						clickHandler={this.itemClicked}
						moreTopicsHandler={this.showMoreTopics}
						topicClickHandler={this.viewTopicCluster}
						addCommentThen={this.addCommentThen}
						onRestoreMissing={this.restoreMissing}
						rateThen={this.rateThen}
						actionBtnThens={{
							remove: this.deleteThen,
							restore: this.restoreThen,
							save: this.saveThen,
							share: this.shareThen
						}}
						activities={activities}
						activitiesUpdated={this.activitiesUpdated}
						hasMoreItems={hasMoreItems}
						history={history}
						location={location}
						category={category}
						newCategory={newCategory}
						loadMore={this.loadItems}
						lastPage={lastPage}
						listClasses={listClasses}
						showSnackbar={showSnackbar} />
				) : (
					<div className={placeHolderClassName}>
						<Paper className={`${classes.placeholderItem} ${classes.activityListItem}`}>
							<ReactPlaceholder
								ready={false}
								customPlaceholder={itemPlaceHolder}
								showLoadingAnimation={!fidoError}>
								<span></span>
							</ReactPlaceholder>
						</Paper>
						<Paper className={`${classes.placeholderItem} ${classes.activityListItem}`}>
							<ReactPlaceholder
								ready={false}
								customPlaceholder={itemPlaceHolder}
								showLoadingAnimation={!fidoError}>
								<span></span>
							</ReactPlaceholder>
						</Paper>
					</div>
				)}
			</div>
		);
	}
}

ActivitiesWrapper.defaultProps = {
	category: 'alerts'
};

ActivitiesWrapper.propTypes = {
	classes: PropTypes.object.isRequired,
	listClasses: PropTypes.object,
	placeHolderClassName: PropTypes.string,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	showSnackbar: PropTypes.func.isRequired,
	updateHeader: PropTypes.func,
	onTrackPage: PropTypes.func,
	onTrackItem: PropTypes.func,
	setNumberOfPages: PropTypes.func,
	restorePosition: PropTypes.func,
	fidoConfig: PropTypes.object,
	category: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <ActivitiesWrapper {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "ActivitiesWrapper";

export default withStyles(styles)(withRouter(React.forwardRef(dNameFunc)));
