import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	person: {
		paddingRight: theme.spacing(1),

		[theme.breakpoints.down('md')]: {
			fontSize: "3.5rem"
		},
	}
});

class ReportItemPeeps extends Component {
	render() {
		let {classes, numberOfPeeps, className} = this.props;
		return [...Array(Math.max(numberOfPeeps, 1))].map((value, idx) => {
			return <FontAwesomeIcon
				size="4x"
				key={`key-${idx}`}
				icon={["fas", "male"]}
				title="person"
				className={`${classes.person} ${className}`} />;
		});
	}
}

ReportItemPeeps.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	numberOfPeeps: PropTypes.number
};

export default withStyles(styles)(ReportItemPeeps);