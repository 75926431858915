import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
	topicContainer: {
		fontSize: "0.9rem",
		paddingBottom: theme.spacing(1)
	},

	topic: {
		fontWeight: 500
	}
});

class ActivityTopics extends Component {
	render() {
		let {classes, matches} = this.props;
		let allTopics = [];
		if (matches && matches.length) {
			matches.map(function(match) {
				if (match.library_topic && match.library_topic.name && !allTopics.includes(match.library_topic.name)) {
					allTopics.push(match.library_topic.name);
				}
				return allTopics;
			});
		}
		let multipleTopics = allTopics.length > 1 ? true : false;

		return (
			allTopics.length > 0 ?
				<Typography className={classes.topicContainer}>
					<span>{multipleTopics ? "Topics: " : "Topic: "}</span>
					{allTopics.map((topic, i) =>
						<span className={classes.topic} key={`key-${topic}`}>{topic}{(allTopics.length !== i + 1) ? ", " : null}</span>
					)}
				</Typography> : null
		);
	}
}

ActivityTopics.propTypes = {
	classes: PropTypes.object.isRequired,
	matches: PropTypes.array
};

export default withStyles(styles)(ActivityTopics);
