import React, { Component } from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import YouTube from "react-youtube";
import { withStyles, withTheme } from '@material-ui/core';

import Thumbnail from './Thumbnail.js';

const maxHeight = 400;
const minHeight = 200;
const styles = theme => ({
	embedResponsive: {
		position: "relative",
		display: "block",
		height: "100%",
		padding: "0",
		overflow: "hidden"
	},

	ratio16by9: {
		//paddingBottom: "28.6%"
	},

	video: {
		position: "absolute",
		top: "0",
		bottom: "0",
		left: "0",
		width: "100%",
		height: "100%",
		border: "0",
		maxHeight: minHeight,

		[theme.breakpoints.up('sm')]: {
			maxHeight: maxHeight
		}
	}
});

class YoutubePostVideo extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleError = this.handleError.bind(this);
		this.state = {
			brokenVideo: false
		};
	}

	handleError(event) {
		this.setState({brokenVideo: true});
	}

	render() {
		let {classes, theme, video, onPlay, onStop, onReady, uuid} = this.props,
			videoId = video.substring(video.lastIndexOf("/")+1);

		return (
			<div style={{height: "100%"}}>
				{!this.state.brokenVideo ? (
					<div className={`${classes.embedResponsive} ${classes.ratio16by9}`}>
						<YouTube
							videoId={videoId}
							onError={this.handleError}
							onPlay={() => onPlay(uuid)}
							onPause={() => onStop(uuid)}
							onEnd={() => onStop(uuid)}
							onReady={(event) => onReady(uuid, event)}
							className={`${classes.video}`}>
						</YouTube>
					</div>
				) : (
					<Media query={{minWidth: theme.breakpoints.values.sm}}>
						{matches => (
							<Thumbnail image="" center fitToHeight={matches ? maxHeight : minHeight} />
						)}
					</Media>
				)}
			</div>
		);
	}
}

YoutubePostVideo.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	useIframeVideo: PropTypes.bool,
	video: PropTypes.string,
	onPlay: PropTypes.func,
	onStop: PropTypes.func,
	onReady: PropTypes.func,
	uuid: PropTypes.number
};

export default withStyles(styles)(withTheme(YoutubePostVideo));