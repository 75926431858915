import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	withStyles,
	Card,
	CardContent,
	CardActions,
	Grid,
	Collapse,
	Button,
	RootRef,
	withTheme,
} from '@material-ui/core';

const styles = theme => ({
	icon: {
		fontSize: "1.5rem",
		color: "rgba(0, 0, 0, 0.35)",
		marginRight: theme.spacing(1.5)
	},

	content: {
		display: "flex",
		alignItems: "center",

		"&:last-child": {
			[theme.breakpoints.down('xs')]: {
				paddingBottom: 16
			},

			[theme.breakpoints.up('sm')]: {
				paddingTop: 24
			}
		}
	},

	actions: {
		justifyContent: "flex-end",
		paddingRight: theme.spacing(2),

		[theme.breakpoints.up('sm')]: {
			paddingTop: 16
		}
	}
});

class Banner extends Component {
	render() {
		let {show, icon, content, actions, classes, className, collapseClassName,
			bannerRef, onEntering, onEntered, onExiting, onExited} = this.props;

		return (
			<RootRef rootRef={bannerRef}>
				<Collapse
					in={show}
					onEntering={onEntering}
					onEntered={onEntered}
					onExiting={onExiting}
					onExited={onExited}
					className={collapseClassName}>
					<Card square={true} className={className}>
						<Grid container alignItems="center">
							<Grid item xs={12} sm={9}>
								<CardContent classes={{root: classes.content}}>
									{icon ? (
										<FontAwesomeIcon
											icon={icon.icon}
											size={icon.size}
											title={icon.title}
											className={`${classes.icon} ${icon.className}`} />)
										: null
									}
									<span>{content}</span>
								</CardContent>
							</Grid>
							<Grid item xs={12} sm={3}>
								<CardActions classes={{root: classes.actions}}>
									{actions ? actions.map(action =>
										<Button
											key={action.text}
											size="small"
											color={action.color}
											style={action.style}
											variant={action.variant}
											onClick={action.handler}>{action.text}</Button>
									) : null}
								</CardActions>
							</Grid>
						</Grid>
					</Card>
				</Collapse>
			</RootRef>
		);
	}
}

Banner.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	show: PropTypes.bool,
	icon: PropTypes.object,
	content: PropTypes.string,
	actions: PropTypes.array,
	className: PropTypes.string,
	collapseClassName: PropTypes.string,
	bannerRef: PropTypes.object.isRequired,
	onEntering: PropTypes.func,
	onEntered: PropTypes.func,
	onExiting: PropTypes.func,
	onExited: PropTypes.func
};

export default withStyles(styles)(withTheme(Banner));
