import Fido from './Fido';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import Constants from './Constants';

class AuthorizedUser {
	static isInternalUser(user) {
		return Object.assign({}, {
			internalUser: user.ghosting || user.accountId === 1 || user.isDemo === true
		}, user);
	}

	static isAdmin(user) {
		return user.user_type_str === Constants.USER.ROLES.ADMINISTRATOR.NAME;
	}

	static addProfileUrlBase(user) {
		const {accountId, id} = user;
		if(accountId && id) {
			return Object.assign({}, {profileUrlBase: `/accounts/${accountId}/users/${id}`}, user);
		} else {
			return user;
		}
	}

	// TODO: don't think this method is used anymore??
	/*static save(values, successFn, errorFn) {
		if(values && Object.keys(values).length) {
			let poundDog = new Fido();
			poundDog.fetch("/users/" + values.id, {
				method: "PUT",
				body: JSON.stringify(values)
			}, 'Saving Profile...', 'Saved.')
				.then((data) => {
					if(data && Object.keys(data).length) {
						data = AuthorizedUser.addProfileUrlBase(data);
						successFn(data);
					}
				})
				.catch(error => errorFn(error.message));
		}
	}*/

	static load(successFn) {
		let fido = new Fido();
		return fido.fetch('/users/whoami')
			.then((user) => {
				if(user) {
					user = AuthorizedUser.isInternalUser(user);
					AuthorizedUser.trackInternalUsers(user);
					user = AuthorizedUser.addProfileUrlBase(user);
					user.childAccounts = AuthorizedUser.stripGroupAccountPrefixes(user.childAccounts);
					successFn(user);
				}
			})
			.catch(error => {
				console.log(error.message);
			});
	}

	static stripGroupAccountPrefixes(accounts) {
		// NOTE: child accounts are setup with the same prefixes, so determine it and pluck it off
		if (accounts && accounts.length > 1) {
			let prefix = AuthorizedUser.findCommonPrefix(accounts);
			if(prefix) {
				accounts.forEach(item => item.name = item.name.slice(prefix.length));
			}
		}

		return accounts;
	}

	static findCommonPrefix(list) {
		let prefix = '';
		if(list && list.constructor == Array) {
			const regex = /^([^:-]+[:-]\s{0,})/;
			for(let i = 0, len = list.length; i < len; i++) {
				// if i = 0, get prefix
				if(i == 0) {
					const match = list[i].name.match(regex);
					prefix = match && match[0] || '';
				} else if(!prefix) {
					break;
				} else if(list[i].name.indexOf(prefix) !== 0) {
					prefix = '';
					break;
				}
			}
		} else {
			console.warn('Expecting an array, recieved: ', list);
		}
		return prefix;
	}

	static trackInternalUsers(user) {
		if(user.internalUser) {
			ReactGA.ga("set", "dimension4", "true");
		} else {
			ReactGA.ga("set", "dimension4", "false");

			// initialize hotjar for external users only
			if (Constants.ENV.IS_STAGING) {
				hotjar.initialize(1035656, 6);
			} else if (Constants.ENV.IS_LIVE) {
				hotjar.initialize(1035877, 6);
			}
		}
	}

	static hasSocialMedia(user) {
		return user.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SOCIAL_MEDIA) &&
				user.permissions.includes(Constants.USER.PERMISSIONS.DATA.SOCIAL_MEDIA);
	}

	static hasSocialMediaOnly(user) {
		return AuthorizedUser.hasSocialMedia(user) &&
				!AuthorizedUser.hasProductOtherThanSocialMedia(user);
	}

	static hasProductOtherThanSocialMedia(user) {
		return (user.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GMAIL) &&
				user.permissions.includes(Constants.USER.PERMISSIONS.DATA.GSUITE)) ||

				(user.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GOOGLE_HANGOUTS) &&
				user.permissions.includes(Constants.USER.PERMISSIONS.DATA.GSUITE)) ||

				(user.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GOOGLE_DRIVE) &&
				user.permissions.includes(Constants.USER.PERMISSIONS.DATA.GSUITE)) ||

				(user.accountFeatures.includes(Constants.ACCOUNT.FEATURES.EXCHANGE) &&
				user.permissions.includes(Constants.USER.PERMISSIONS.DATA.EXCHANGE)) ||

				(user.accountFeatures.includes(Constants.ACCOUNT.FEATURES.TIPS) &&
				user.permissions.includes(Constants.USER.PERMISSIONS.DATA.TIPS));
	}

	static wantsMoreContrast() {
		return JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE.CONTRAST));
	}
}

export default AuthorizedUser;
