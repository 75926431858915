import React, { Component } from 'react';
import { Slide } from '@material-ui/core';

class DialogSlideTransition extends Component {
	render() {
		return (
			<Slide direction="up" {...this.props} />
		);
	}
}

export default DialogSlideTransition;