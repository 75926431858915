import { Component } from 'react';
import PropTypes from 'prop-types';
import MomentCore from 'moment';

class ReportDataMasseuse extends Component {
	// asObject can be boolean or an array of object attributes to preserve when massaging
	static massageData(data, key, valueKey, asObject) {
		let processedData;

		if (data) {
			processedData = [];
			data.map((obj) => {
				if (asObject) {
					let point = {x: obj[key], y: obj[valueKey]};
					if (Array.isArray(asObject)) {
						asObject.map(attr => {
							return point[attr] = obj[attr];
						});
					}
					return processedData.push(point);
				} else {
					return processedData.push([obj[key], obj[valueKey]]);
				}
			});
		}

		return processedData;
	}

	static flatten(config) {
		let flat = [];

		config.data.map((obj) => {
			return flat.push(obj[config.valueKey]);
		});

		return flat;
	}

	render() {
		let massaged = [],
			{dataConfig} = this.props;

		dataConfig.forEach((config) => {
			// if true, data returned as single, flat, array
			if (config.flatten === true) {
				massaged.push(ReportDataMasseuse.flatten(config));
			} else {
				massaged.push(ReportDataMasseuse.massageData(config.data, config.key, config.valueKey, config.asObject));
			}
		});

		return this.props.render(massaged);
	}

	static convertDatesToMillis(data) {
		if (data) {
			data.forEach(point => {
				if (point && point.period) {
					point.period = (new MomentCore(point.period)).valueOf();
				}
			});
		}
	}

	static getMaxValue(data, valueKey) {
		let allVals = data.reduce((a, v, i) => {
			a.push(v[valueKey]);
			return a;
		}, []);

		return Math.abs(Math.max(...allVals));
	}
}

ReportDataMasseuse.propTypes = {
	render: PropTypes.func.isRequired,
	// dataConfig = {data: data, valueKey: "somekey"} or array of those objects
	dataConfig: PropTypes.array.isRequired
};

export default ReportDataMasseuse;
