import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ReactPlaceholder from 'react-placeholder';
import { RectShape, TextBlock, TextRow } from 'react-placeholder/lib/placeholders';

import Fido from '../utils/Fido';
import Constants from "../utils/Constants";
import StandardFormLayout from './StandardFormLayout';
import Heading from "./Heading";
import Select from "./Select";
import LabeledCheckbox from "./LabeledCheckbox";
import DatePickerValidator from "./DatePickerValidator";
import DialogSlideTransition from "./DialogSlideTransition";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

import {
	Paper,
	Button,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	withStyles,
	withWidth,
	withTheme,
	Typography
} from '@material-ui/core';

const styles = theme => ({
	// so they are 2 rows like middle button on md layouts
	largeBtn: {
		[theme.breakpoints.up(800)]: {
			paddingLeft: 55,
			paddingRight: 55
		},

		[theme.breakpoints.up(1135)]: {
			paddingLeft: 24,
			paddingRight: 24
		}
	},

	paddedRow: {
		paddingLeft: "3%",
		paddingRight: "3%",
	},

	rowWidth: {
		width: "94%",
	},

	checkbox: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},

	dateGrid: {
		paddingBottom: theme.spacing(2)
	},

	dialogPaper: {
		minWidth: 265,
		maxWidth: 450
	},

	consentCheckbox: {
		paddingTop: theme.spacing(1)
	},

	analystNote: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		padding: theme.spacing(1),
		borderRadius: theme.spacing(0.5),
		background: theme.palette.grey[200]
	},

	show: {
		display: "block"
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const itemPlaceHolder = (
	<div>
		<Paper style={{padding: 10, marginTop: 10}}>
			<div style={{display: "flex", marginTop: 25, flexDirection: "column"}}>
				<div style={{flex: 1}}>
					<TextBlock rows={2} color={placeholderColor} style={{width: "95%"}} />
				</div>
				<div style={{flex: 1, marginTop: 10}}>
					<TextBlock rows={2} color={placeholderColor} style={{width: "100%"}} />
				</div>
			</div>
		</Paper>
		<Paper style={{padding: 10, marginTop: 10}}>
			<TextRow color={placeholderColor} style={{marginTop: 10}}><span></span></TextRow>
			<RectShape style={{height: "20rem", marginTop: 10, marginBottom: 10}} color={placeholderColor}><span></span></RectShape>
		</Paper>
	</div>
);

class EventDetailsForm extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			deleteDlgOpen: false,
			formSubmit: false,
			paths: [],
			showAllUsers: false
		};

		this.fido = new Fido();

		this.handleDeleteDialog = this.handleDeleteDialog.bind(this);
		this.deleteEvent = this.deleteEvent.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitClick = this.handleSubmitClick.bind(this);
		this.handleError = this.handleError.bind(this);
	}

	componentDidMount() {
		this.setupValidators();
	}

	componentWillUnmount() {
		this.fido.dropIt();
	}

	handleDeleteDialog() {
		this.setState({deleteDlgOpen: !this.state.deleteDlgOpen});
	}

	deleteEvent(eventId) {
		this.handleDeleteDialog();
		this.props.deleteEventHandler(eventId);
	}

	handleSubmitClick() {
		this.setState({formSubmit: true});
	}

	handleError() {
		this.setState({formSubmit: false});
	}

	setupValidators() {
		ValidatorForm.addValidationRule('requiredMulti', (value) => {
			if(typeof value !== 'undefined' && value.length > 0) {
				return true;
			}
			return false;
		});

		ValidatorForm.addValidationRule('isTruthy', value => value);
	}

	// Convert the submitted form even to an object of values instead
	handleSubmit = (event) => {
		let form = event.target;
		let values = {
			id: this.props.formValues.id
		};

		Object.keys(form.elements).map(e => {
			// Don't send if element not named
			if(form.elements[e].nodeName && form.elements[e].name) {
				if (form.elements[e].type === 'radio') {
					// If it's a radio type input, only use the checked value
					if (form.elements[e].checked === true) {
						values[form.elements[e].name] = form.elements[e].value;
					}
				} else if (form.elements[e].type === 'checkbox') {
					values[form.elements[e].name] = form.elements[e].checked;
				} else {
					values[form.elements[e].name] = form.elements[e].value;
				}
			}

			return e;
		});

		this.props.handleSubmit(values);
	}

	render() {
		let {classes, handleChange, handleKeyDown, formValues, isLoading, dataLoading, accountLocations} = this.props,
			{fidoError, deleteDlgOpen} = this.state,
			{id, name, description, consent, start_date, end_date, locations} = formValues,
			eventDefined = id ? true : false, // bool if event id exists
			paddedRowCls = `${classes.paddedRow} ${classes.rowWidth}`,
			buttonGridWidth = eventDefined ? 6 : 12,
			buttonJustify = eventDefined ? "space-between" : "center";

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
						<React.Fragment>
							{!isLoading ? (
								<Paper>
									<ValidatorForm
										onSubmit={this.handleSubmit}
										onError={this.handleError}
										debounceTime={500} >
										<StandardFormLayout>
											<Heading id="eventAddressHeading" title="Event Details" formKey="eventDetailsHeader" className={paddedRowCls}/>
											<div className={paddedRowCls} name="primaryPaddedRow">
												<TextValidator
													fullWidth
													id="name"
													label="Event Title"
													name="name"
													helperText="Title of the event"
													margin="normal"
													value={name || ''}
													onChange={handleChange}
													validators={['required', 'minStringLength:3', 'maxStringLength:100']}
													autoComplete="off"
													errorMessages={[Constants.ERROR_MESSAGES['required'], 'Event name must be at least 3 characters long', 'Event name is too long']} />
												<TextValidator
													fullWidth
													multiline
													rows="10"
													id="description"
													label="Description"
													name="description"
													placeholder="Describe your event and any words, phrases, people, #hashtags, etc. that may be used on social media to refer to this event"
													helperText="Describe your event and any words, phrases, people, #hashtags, etc. that may be used on social media to refer to this event"
													disabled={eventDefined}
													value={description || ''}
													margin="normal"
													variant="outlined"
													validators={['required']}
													errorMessages={[Constants.ERROR_MESSAGES['required']]}
													onChange={handleChange}
													onKeyDown={handleKeyDown}
													FormHelperTextProps={{
														classes: {
															root: classes.show
														}
													}} />
												<LabeledCheckbox
													id="consent"
													name="consent"
													checked={consent}
													disabled={eventDefined}
													className={classes.consentCheckbox}
													label="All persons mentioned above have given consent to use their name for the means of protection."
													checkboxColor="primary"
													onChange={handleChange}
													value={consent}
													validators={['isTruthy']}
													errorMessages={[Constants.ERROR_MESSAGES['required']]} />
												<Grid className={classes.dateGrid} container spacing={2}>
													<Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
														<DatePickerValidator
															margin="normal"
															id="start_date"
															name="start_date"
															label="Start Date"
															format="MM/DD/YYYY"
															variant="inline"
															value={start_date || null}
															maxDate={end_date}
															onChange={handleChange}
															validators={['required']}
															ref={(r) => { this.input = r; }}
															errorMessages={[Constants.ERROR_MESSAGES['required']]} />
													</Grid>
													<Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
														<DatePickerValidator
															margin="normal"
															id="end_date"
															name="end_date"
															label="End Date"
															format="MM/DD/YYYY"
															variant="inline"
															minDate={start_date}
															value={end_date || null}
															onChange={handleChange}
															validators={['required']}
															ref={(r) => { this.input = r; }}
															errorMessages={[Constants.ERROR_MESSAGES['required']]} />
													</Grid>
												</Grid>
											</div>
											<React.Fragment>
												<Heading id="assocLocations" title="Associated Locations" formKey="assocLocations" className={paddedRowCls}/>
												<div className={paddedRowCls} name="eventLocPaddedRow">
													<Select
														fullWidth
														isLoading={dataLoading}
														id="locations"
														name="locations"
														label="Locations"
														helperText="Select the locations this event belongs to"
														margin="normal"
														value={locations.length ? locations : accountLocations.map(loc => loc.id)}
														// multiple select props
														multiple
														includeSelectAll
														labelAttr="name"
														valueAttr="id"
														allOptions={accountLocations}
														onChange={handleChange}
														validators={['requiredMulti']}
														errorMessages={[Constants.ERROR_MESSAGES['required']]} />
												</div>
											</React.Fragment>
											{!eventDefined ? (
												<Typography className={classes.analystNote} name="analystNote">
													<b>Note:</b> All submissions will be reviewed by your Optimization Analyst prior to adoption. You will be contacted when your submission goes live or if there are any concerns or additional edits required prior to adoption.
												</Typography>
											) : null}
											<div className={paddedRowCls} name="submitPaddedRow">
												<Grid id="formButtons" container justify={buttonJustify} spacing={2}>
													{eventDefined ? (
														<Grid item sm={buttonGridWidth} xs={12}>
															<Button
																fullWidth
																size="large"
																onClick={this.handleDeleteDialog}
																variant="outlined"
																classes={{sizeLarge: classes.largeBtn}}>
																Delete Event
															</Button>
														</Grid>
													): null}
													<Grid item sm={buttonGridWidth} xs={12}>
														<Button
															fullWidth
															size="large"
															type="submit"
															variant="contained"
															color="primary"
															onClick={this.handleSubmitClick}
															classes={{sizeLarge: classes.largeBtn}}>
															{(eventDefined) ? "Save Event" : "Add Event"}</Button>
													</Grid>
												</Grid>
											</div>
										</StandardFormLayout>
									</ValidatorForm>
								</Paper>
							) : (
								<div>
									<ReactPlaceholder
										ready={false}
										customPlaceholder={itemPlaceHolder}
										showLoadingAnimation={!fidoError}>
										<span></span>
									</ReactPlaceholder>
								</div>
							)}
							<Dialog
								fullWidth
								maxWidth={false}
								classes={{paper: classes.dialogPaper}}
								open={deleteDlgOpen}
								TransitionComponent={DialogSlideTransition}
								onClose={this.handleDeleteDialog}>
								<DialogTitle>Delete Event?</DialogTitle>
								<DialogContent>
									<DialogContentText>
										Are you sure you want to delete <b>{name}</b>?
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={this.handleDeleteDialog} color="primary">Cancel</Button>
									<Button onClick={() => this.deleteEvent(id)} color="secondary">Delete Event</Button>
								</DialogActions>
							</Dialog>
						</React.Fragment>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

EventDetailsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func,
	deleteEventHandler: PropTypes.func,
	formValues: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	dataLoading: PropTypes.bool,
	handleKeyDown: PropTypes.func,
	accountLocations: PropTypes.array
};

export default (withStyles(styles)(withWidth()(withTheme(EventDetailsForm))));
