import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Typography,
	Link,
	Grid
} from '@material-ui/core';

import Highchart from "./Highchart";

class SentimentChart extends Component {
	render() {
		let {chartConfig, clusterName, linkUrl, linkTitle, clickPointText} = this.props;
		return (
			<React.Fragment>
				<Highchart chartConfig={chartConfig} />
				<Grid container direction="column" alignItems="center" style={{height: 40}}>
					<Grid item>
						<Typography align="center">{clusterName}</Typography>
					</Grid>
					<Grid item>
						{linkUrl && linkTitle ? (
							<Link
								rel="noopener noreferrer"
								target="_blank"
								underline="none"
								align="center"
								href={linkUrl}>{linkTitle}</Link>
							: null
						) : null}
						{!clusterName ? (
							<Typography align="center" color="textSecondary" style={{marginTop: 12}}>{clickPointText}</Typography>
						) : null}
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

SentimentChart.propTypes = {
	chartConfig: PropTypes.object,
	clusterName: PropTypes.string,
	linkUrl: PropTypes.string,
	linkTitle: PropTypes.string,
	clickPointText: PropTypes.string
};

export default SentimentChart;
