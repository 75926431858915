import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Media from 'react-media';

import NavigationDrawer from "./NavigationDrawer";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import BackButton from "./BackButton";
import Branding from "./Branding";
import Avatar from "./Avatar";
import ProfileCard from './ProfileCard';
import SupportContact from './SupportContact';

import {
	withStyles,
	AppBar,
	Toolbar,
	Typography,
	Button,
	IconButton,
	Grid,
	Popover,
	Divider,
	MenuItem,
	withTheme,
} from '@material-ui/core';

const styles = theme => ({
	root: {
		flexGrow: 1
	},

	flex: {
		flex: 1,
	},

	appBarSize: {
		// TODO - need updated spacing when signals banner showing
		// top: theme.spacing(8),
		[theme.breakpoints.up('lg')]: {
			width: `calc(100% - ${theme.drawer.width}px)`
		}
	},

	signalsFirst: {
		top: theme.spacing(9),

		[theme.breakpoints.down('md')]: {
			top: theme.spacing(9.375)
		},

		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(10.875)
		},

		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(18)
		}
	},

	toolBar: {
		justifyContent: "space-between"
	},

	unauthenticated: {
		backgroundColor: theme.palette.primary.tan
	},

	button: {
		marginRight: theme.spacing(-1)
	},

	hamburger: {
		color: theme.palette.common.white
	},

	title: {
		fontSize: "1.45rem",
		fontWeight: 300,
		position: "relative",
		textTransform: "capitalize",
		color: theme.palette.common.white,
		[theme.breakpoints.up('lg')]: {
			color: theme.palette.text.primary,
		}
	},

	signIn: {
		marginTop: theme.spacing(.5)
	},

	helpProfileGrid: {
		width: "auto",
		float: "right",
		position: "absolute",
		right: theme.spacing(3)
	},

	pipe: {
		width: 1,
		content: '""',
		height: theme.spacing(3),
		backgroundColor: theme.palette.grey[300]
	},

	downArrow: {
		fontSize: "1.3rem",
		marginLeft: theme.spacing(1.25)
	},

	avatar: {
		marginRight: theme.spacing(.5)
	},

	link: {
		display: "flex",
		alignItems: "center",
		fontSize: "1rem"
	}
});

class HeaderBar extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			tempDrawerOpen: false,
			anchorEl: null,
			helpOpen: false,
			profileOpen: false
		};

		this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
		this.helpClickHandler = this.helpClickHandler.bind(this);
		this.profileClickHandler = this.profileClickHandler.bind(this);
		this.closeHelpHandler = this.closeHelpHandler.bind(this);
		this.closeProfileHandler = this.closeProfileHandler.bind(this);
		this.handleLegacyLink = this.handleLegacyLink.bind(this);
	}

	handleDrawerToggle() {
		this.setState({ tempDrawerOpen: !this.state.tempDrawerOpen });
	}

	helpClickHandler(event) {
		this.setState({
			helpOpen: true,
			anchorEl: event.target
		});
	}

	profileClickHandler(event) {
		this.setState({
			profileOpen: true,
			anchorEl: event.target
		});
	}

	closeHelpHandler() {
		this.setState({
			helpOpen: false,
			anchorEl: null
		});
	}

	closeProfileHandler() {
		this.setState({
			profileOpen: false,
			anchorEl: null
		});
	}

	handleLegacyLink(url) {
		this.closeProfileHandler();
		window.location.replace(url);
	}

	render() {
		let {title, signalsFirstEnabled} = this.props;

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					let navigationBtn = null,
						{anchorEl, helpOpen, profileOpen} = this.state,
						{classes, signInUrl, showSnackbar, installable,
							handleInstallBtn, theme, backUrl, primary, csm, history} = this.props;

					if(!authenticatedUser) {
						navigationBtn = <Button color="primary" className={`${classes.button} ${classes.signIn}`} href={signInUrl || "/login"}>Sign In</Button>;
					} else {
						navigationBtn = <Media query={{minWidth: theme.breakpoints.values.lg}}>
							{matches =>
								!matches ?
									<IconButton
										onClick={this.handleDrawerToggle}
										className={`${classes.button} ${classes.hamburger}`}
										aria-label="navigation menu">
										<FontAwesomeIcon icon={["far", "bars"]}	title="navigation menu" />
									</IconButton>
									:
									<Grid container
										justify="flex-end"
										alignItems="center"
										spacing={1}
										className={classes.helpProfileGrid}>
										<Grid item>
											<Button
												underline="none"
												className={classes.link}
												onClick={this.helpClickHandler}>Help
												<FontAwesomeIcon icon={["fal", "angle-down"]} className={classes.downArrow} title="help" />
											</Button>
										</Grid>
										<Grid item>
											<div className={classes.pipe}></div>
										</Grid>
										<Grid item style={{display: "flex"}}>
											<Button
												underline="none"
												className={classes.link}
												onClick={this.profileClickHandler}>
												<Avatar
													sizeFactor={-1}
													className={classes.avatar}
													authorName={authenticatedUser.name}
													authorImage={authenticatedUser.avatar} />
												{authenticatedUser.name}
												<FontAwesomeIcon icon={["fal", "angle-down"]} className={classes.downArrow} title="user menu" />
											</Button>
										</Grid>
									</Grid>
							}
						</Media>;
					}

					return (
						<div className={classes.root}>
							{authenticatedUser ?
								<NavigationDrawer
									csm={csm}
									history={history}
									showSnackbar={showSnackbar}
									installable={installable}
									signalsFirstEnabled={signalsFirstEnabled}
									handleInstallBtn={handleInstallBtn}
									handleDrawerToggle={this.handleDrawerToggle}
									tempDrawerOpen={this.state.tempDrawerOpen} />
								: null
							}
							<Media query={{minWidth: theme.breakpoints.values.lg}}>
								{matches =>
									<AppBar
										elevation={0}
										color={matches ? "primary" : "secondary"}
										className={`${authenticatedUser ? classes.appBarSize : ""} ${signalsFirstEnabled ? classes.signalsFirst : ""}`}>
										<Toolbar className={`${classes.toolBar} ${!authenticatedUser ? classes.unauthenticated : ""}`}>
											{authenticatedUser && !primary ? <BackButton backUrl={backUrl} /> : null}
											{!authenticatedUser || primary ?
												<Media query={{minWidth: 375}}>
													{matches2 =>
														!matches || !authenticatedUser ?
															<Branding
																light={!matches}
																secondary={matches2}
																logoOnly={!matches2 || authenticatedUser !== false} />
															: null
													}
												</Media>
												: null}
											{title ?
												<Typography
													noWrap
													variant="h1"
													className={`${classes.flex} ${classes.title}`}>{title}</Typography>
												:null}
											{navigationBtn}
										</Toolbar>
									</AppBar>
								}
							</Media>
							<Media query={{minWidth: theme.breakpoints.values.lg}}>
								{matches =>
									<div>
										<Popover
											open={helpOpen}
											anchorEl={anchorEl}
											onClose={this.closeHelpHandler}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}>
											<SupportContact
												csm={csm}
												closeHandler={this.closeHelpHandler} />
										</Popover>
										<Popover
											open={profileOpen}
											anchorEl={anchorEl}
											onClose={this.closeProfileHandler}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}>
											<div>
												<ProfileCard
													history={history}
													closeHandler={this.closeProfileHandler}
													typographyColor="initial" />
												<Divider />
												<MenuItem onClick={this.closeProfileHandler} component={Link} to={`/settings`} tabIndex={0}>Settings</MenuItem>
												<Divider />
												<MenuItem onClick={this.closeProfileHandler} component={Link} to={`/logout`} tabIndex={0}>Sign Out</MenuItem>
												{authenticatedUser.ghosting ? (
													<div>
														<Divider />
														<MenuItem component={'a'} onClick={() => this.handleLegacyLink(`/accounts/users/unghost`)} tabIndex={0}>Unghost</MenuItem>
													</div>
												) : null}
											</div>
										</Popover>
									</div>
								}
							</Media>
						</div>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

HeaderBar.propTypes = {
	classes: PropTypes.object.isRequired,
	csm: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	title: PropTypes.string,
	signInUrl: PropTypes.string,
	showSnackbar: PropTypes.func,
	handleInstallBtn: PropTypes.func,
	installable: PropTypes.bool,
	backUrl: PropTypes.string,
	primary: PropTypes.bool,
	signalsFirstEnabled: PropTypes.bool
};

export default withRouter(withStyles(styles)(withTheme(HeaderBar)));
