import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FormattedPhone extends Component {
	format(num) {
		if (num) {
			num = num.replace(/[^0-9]/g, '').replace(/^1/, '');
			if (num.length >= 10) {
				return num.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
			}
			if (num.length === 7) {
				return num.replace(/(\d{3})(\d{4})/, "$1-$2");
			}
		}
		// If it's not 7 or 10 or greater  return empty and let validation deal with it.
		return "";
	}

	render() {
		return (
			<span>{this.format(this.props.number)}</span>
		);
	}
}

FormattedPhone.propTypes = {
	number: PropTypes.string.isRequired
};

export default FormattedPhone;