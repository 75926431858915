import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fido from '../utils/Fido';

import SingleActivity from '../components/SingleActivity';
import DeconstructActivity from '../utils/DeconstructActivity';
import ShareActivityForm from '../components/ShareActivityForm.js';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import Constants from "../utils/Constants";
import { Button } from '@material-ui/core';

/**
 * Activity container for single activity view. Because of redirect from ActivityRewrite,
 * could have activity data already. Otherwise fetch activity data
 */
class IndividualActivity extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			activity: null,
			activityForUser: null,
			fidoError: false,
			shareOpen: false,
			shareActivity: null,
			activityDeleted: false
		};

		this.fido = new Fido();
		this.performAction = this.performAction.bind(this);
		this.handleTranslateClick = this.handleTranslateClick.bind(this);

		this.addCommentHandler = this.addCommentHandler.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleShareClick = this.handleShareClick.bind(this);
		this.handleShareClose = this.handleShareClose.bind(this);
		this.handleShareSubmit = this.handleShareSubmit.bind(this);
		this.handleRestoreAndSave = this.handleRestoreAndSave.bind(this);
		this.handleRateSubmit = this.handleRateSubmit.bind(this);

		this.backUrl = (props.history.length < 2) ? "/activities" : null;
	}

	componentDidUpdate(prevProps, prevState) {
		let {data} = this.props;
		if (this.state.activity == null && data && 'activity' in data) {
			this.handleActivityUpdate(data);
		}

		if (this.state.activity !== prevState.activity) {
			let activityType = Constants.ACTIVITY.CATEGORIES.ALERTS.NAME;
			if (this.state.activity.category && this.state.activity.category === Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.ID) {
				activityType = Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.NAME;
			}

			this.updateHeader(activityType);
		}
	}

	componentWillUnmount() {
		this.props.resetHeader();
		// in case permanent snackbar showing
		this.props.showSnackbar(false);
	}

	updateHeader(type) {
		if (this.props.authenticatedUser) {
			this.props.updateHeader({title: `${type} details`, backUrl: this.backUrl, primary: false});
		} else {
			this.props.updateHeader({
				signInUrl: this.props.location.pathname.replace('/v/', `/${Constants.SPA_PATH}/activities/rewrite/`)
			});
		}
	}

	performAction(action, msg, successMsg, values) {
		let activityId = this.state.activity && this.state.activity.id;
		if(activityId && action) {
			this.fido.fetch(`/activities/${activityId}/${action}`, {
				method: "PUT",
				body: JSON.stringify(values)
			}, msg, successMsg)
				.then((data) => {
					this.handleActivityUpdate(data);
				})
				.catch(this.handleActivityUpdateError);
		}
	}

	addCommentHandler(formVals) {
		this.fido.fetch(this.props.location.pathname, {
			method: "POST",
			body: JSON.stringify({activity: formVals})
		}, 'Posting Comment...', 'Comment Posted.')
			.then(this.handleActivityUpdate)
			.catch(this.handleActivityUpdateError);
	}

	isActivityDeleted(activity) {
		if (activity.hidden_for_users) {
			let prevRemoved,
				hiddenFor = this.props.authenticatedUser ? this.props.authenticatedUser.id : null;

			prevRemoved = activity.hidden_for_users.find((obj) => {
				return obj.user.id === hiddenFor;
			});

			return prevRemoved ? true : false;
		}
		return false;
	}

	handleActivityUpdate = (data) => {
		if (data && data.activity) {
			let isActivityDeleted = this.isActivityDeleted(data.activity);
			this.setState({
				activity: data.activity,
				activityDeleted: isActivityDeleted
			});
		}
	}

	handleRestoreAndSave() {
		let activityId = this.state.activity && this.state.activity.id;
		if(activityId) {
			let poodle = new Fido();
			poodle.fetch(`/activities/${activityId}/undelete`, {
				method: "PUT",
			}, 'Restoring...', 'Restored.')
				.then(data => {
					if(!this.state.activity.flagged) {
						data.activity.flagged = true;
						this.props.showSnackbar(false);
						this.performAction('save', 'Saving...', 'Saved.');
					}
					this.handleActivityUpdate(data);
				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	handleActivityUpdateError = (error) => {
		this.setState({fidoError: true});
		if(error.message === "404 Not Found") {
			this.props.showSnackbar({
				message: "Not found. Outdated?",
				duration: false
			});
		} else {
			this.props.showSnackbar(error.message);
		}
	}

	handleSaveClick() {
		let act = this.state.activity;
		if(!act.flagged) {
			if (this.state.activityDeleted) {
				this.handleRestoreAndSave();
			} else {
				this.performAction('save', 'Saving...', 'Saved.');
				act.flagged = !act.flagged;
				this.setState({activity: act});
			}
		} else {
			this.performAction('unsave', 'Removing from Saved...', 'Removed from Saved.');
			act.flagged = !act.flagged;
			this.setState({activity: act});
		}
	}

	handleDeleteClick() {
		let activityId = this.state.activity && this.state.activity.id;
		if(activityId) {
			let poodle = new Fido();
			poodle.fetch("/activities/" + activityId + "/delete", {
				method: "PUT",
			}, {
				message: 'Removing...',
				action: (<Button size="small" onClick={event => this.handleUndoClick(event, activityId)} color="primary">Undo</Button>)
			}, 'Removed.')
				.then(result => {
					let history = this.props.history;
					if (history.location.pathname === "/" || history.location.pathname === "/activities") {
						// need to include pathname and search, otherwise removes search param and loses what tab user is on
						let location = history.location;
						history.replace({
							pathname: location.pathname,
							search: location.search,
							state: {excludeId: `${result}`}
						});
					} else if (history.location.pathname.match(/activities\/\d+/)) {
						// sometimes they refresh or have been on a certain activity multiple times
						// in that case, where pathname is the activity url, force back to activities
						this.props.history.push("/activities");
					}
				})
				.catch(this.handleActivityUpdateError);

			this.props.history.goBack();
		}
	}

	handleUndoClick(event) {
		this.props.showSnackbar(false);

		let activity = this.state.activity,
			activityId = this.state.activity && this.state.activity.id;

		if(activityId) {
			let poodle = new Fido();
			poodle.fetch("/activities/" + activityId + "/undelete", {
				method: "PUT",
			}, 'Restoring...', 'Restored.')
				.then(data => {
					let history = this.props.history;
					if (history.location.pathname === "/activities") {
						// need to include pathname and search, otherwise removes search param and loses what tab user is on
						let location = history.location;
						history.replace({
							pathname: location.pathname,
							search: location.search,
							state: {includeId: `${activityId}`, recentlyRemoved: activity}
						});
					}
				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	handleShareClick(event, activityId) {
		this.setState({
			shareOpen: true,
			shareActivity: activityId
		});
	}

	handleShareClose(event) {
		this.setState({shareOpen: false});
	}

	handleShareSubmit(activityId, values) {
		this.handleShareClose();
		this.performAction('share', 'Sharing...', 'Shared.', values);
	}

	handleRateSubmit(rating) {
		if(rating && Object.keys(rating).length) {
			this.performAction('rate', 'Rating...', 'Rated.', rating);
		}
	}

	handleTranslateClick(){
		if (this.props.authenticatedUser) {
			this.performAction('translate');
		} else {
			this.fido.fetch(`${this.props.location.pathname}/translate`, {
				method: "PUT"
			}, null, null)
				.then((data) => {
					this.handleActivityUpdate(data);
				})
				.catch(this.handleActivityUpdateError);
		}
	}

	render() {
		let { showSnackbar, location, authenticatedUser, history } = this.props,
			{ activity, fidoError } = this.state,
			actionBtns = Constants.ACTIVITY.ACTION_BUTTONS;

		return (
			<div>
				{authenticatedUser ?
					<ShareActivityForm
						open={this.state.shareOpen}
						activityId={this.state.shareActivity}
						handleClose={(event) => this.handleShareClose(event)}
						handleSubmit={(activityId, values) => this.handleShareSubmit(activityId, values)} />
					: null
				}
				<DeconstructActivity
					activity={activity}
					render={props =>
						<SingleActivity
							{...props}
							history={history}
							showSnackbar={showSnackbar}
							fidoError={fidoError}
							handleTranslateClick={this.handleTranslateClick}
							historyState={authenticatedUser ? location.state : null}
							addCommentHandler={authenticatedUser ? this.addCommentHandler : null}
							isActivityDeleted={this.state.activityDeleted}
							handleRestoreAndSave={this.handleRestoreAndSave}
							rateHandler={this.handleRateSubmit}
							actions={authenticatedUser ? [
								actionBtns.getRemove({
									handleClick: this.handleDeleteClick,
									disabled: this.state.activityDeleted
								}),
								actionBtns.getSave({
									flagged: activity && activity.flagged,
									handleClick: this.handleSaveClick,
									disabled: this.state.activityDeleted
								}),
								actionBtns.getShare({
									handleClick: this.handleShareClick,
									disabled: this.state.activityDeleted
								})
							] : null} />
					}/>
			</div>
		);
	}
}

IndividualActivity.propTypes = {
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	location: PropTypes.object,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	history: PropTypes.object.isRequired,
	data: PropTypes.object
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <IndividualActivity {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "IndividualActivity";

export default React.forwardRef(dNameFunc);