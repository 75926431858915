import React, { Component } from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';

import { withStyles, Grid, withTheme } from '@material-ui/core';

import Thumbnail from './Thumbnail.js';
import PostVideo from './PostVideo.js';
import Constants from "../utils/Constants";
import YoutubePostVideo from "./YoutubePostVideo";

const maxHeight = 400;
const minHeight = 200;
const styles = theme => ({
	postMedia: {
		padding: 0,
		height: minHeight,
		overflow: "hidden",

		[theme.breakpoints.up('sm')]: {
			height: maxHeight
		}
	},

	thumbImage: {
		height: minHeight,

		[theme.breakpoints.up('sm')]: {
			height: maxHeight
		}
	},

	video: {
		height: "100%"
	}
});

class PostMedia extends Component {
	render() {
		let mediaCell,
			{classes, theme, videoUrl, imageUrl, className, sourceId,
				activityId, onYoutubePlay, onYoutubeStop, onYoutubeReady} = this.props;

		// TODO: if it's a video, imageUrl includes a screenshot image of the video so we could use that with a mask circle / play button, then user taps to play (might just look better)
		if(videoUrl) {
			// Broken video test
			//videoUrl = "http://blizblazz.net/media.mp4";
			mediaCell = sourceId === Constants.SOURCES.IDS.YOUTUBE ?
				<YoutubePostVideo
					video={videoUrl}
					uuid={activityId}
					onPlay={onYoutubePlay}
					onPause={onYoutubeStop}
					onStop={onYoutubeStop}
					onReady={onYoutubeReady} /> :
				<PostVideo video={videoUrl} useIframeVideo={this.props.useIframeVideo} />;
		} else if(imageUrl) {
			// Wide test image */}
			//imageUrl = "https://pbs.twimg.com/media/DPxt1GYXUAA4l2w.jpg";
			// Tall test image
			//imageUrl = "https://pbs.twimg.com/media/DPxN2-_WsAAAACf.jpg";
			// Broken test image
			//imageUrl = "https://pbs.twimg.com/media/DPxuGfaXcAAbbLr.jpg";
			mediaCell = <Media query={{minWidth: theme.breakpoints.values.sm}}>
				{matches => (
					<Thumbnail className={classes.thumbImage} image={imageUrl} center fitToHeight={matches ? maxHeight : minHeight} />
				)}
			</Media>;
		}

		return mediaCell ? (
			<Grid container
				justify="center"
				alignItems="stretch"
				direction="column"
				className={`${classes.postMedia} ${className}`}>
				<Grid item
					className={videoUrl ? classes.video : null}>
					{mediaCell}
				</Grid>
			</Grid>
		) : null;
	}
}

PostMedia.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object,
	className: PropTypes.string,
	imageUrl: PropTypes.string,
	videoUrl: PropTypes.string,
	useIframeVideo: PropTypes.bool,
	sourceId: PropTypes.number,
	onYoutubePlay: PropTypes.func,
	onYoutubeStop: PropTypes.func,
	onYoutubeReady: PropTypes.func,
	activityId: PropTypes.number
};

export default withStyles(styles)(withTheme(PostMedia));
