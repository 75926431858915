import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BrokenImage from "../assets/broken-image.png";
import { withStyles } from '@material-ui/core';

const styles = {
	center: {
		display: "block",
		marginRight: "auto",
		marginLeft: "auto"
	},

	responsive: {
		maxWidth: "100%",
		height: "auto"
	},

	brokenImage: {
		margin: "auto",
		marginTop: "35px",
		marginBottom: "35px",
		opacity: "0.4"
	}
};

class Thumbnail extends Component {
	constructor(props, context) {
		super(props, context);
		this.imageLoaded = this.imageLoaded.bind(this);
		this.handleError = this.handleError.bind(this);
	}

	imageLoaded(event) {
		let img = event.target;
		if (this.props.fitToHeight && img.src !== BrokenImage) {
			let maxWidth,
				ratio,
				imgH = img.height,
				imgW = img.width,
				fitTo = this.props.fitToHeight;

			//if (imgH >= imgW) {
				ratio = fitTo / imgH;
				maxWidth = ratio * imgW;
			//}

			img.width = maxWidth;
		}
	}

	handleError(event) {
		let img = event.target;
		img.src = BrokenImage;
		img.className += ` ${this.props.classes.brokenImage}`;
	}

	render() {
		let {classes} = this.props,
			className = this.props.center ? classes.center : null;

		return (
			<img src={this.props.image} onLoad={this.imageLoaded} onError={this.handleError}
				className={`${classes.responsive} ${className}`} alt=""/>
		);
	}
}

Thumbnail.propTypes = {
	classes: PropTypes.object.isRequired,
	image: PropTypes.string,
	center: PropTypes.bool,
	fitToHeight: PropTypes.number
};

export default withStyles(styles)(Thumbnail);