import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_accessibility from 'highcharts/modules/accessibility';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import HC_noData from 'highcharts/modules/no-data-to-display';
import { withStyles, withTheme } from '@material-ui/core';

HC_accessibility(Highcharts);
HC_exporting(Highcharts);
HC_exportData(Highcharts);
HC_noData(Highcharts);

const styles = {
	h4hack: {
		position: "absolute"
	}
};

class Highchart extends Component {
	constructor(props, context) {
		super(props, context);

		let {theme, exporting, accessibility, noDataMsg, darkTheme, chartConfig} = this.props;

		chartConfig.accessibility = Object.assign({}, {
			enabled: accessibility || false,
			keyboardNavigation: {
				order: ["chartMenu", "series", "legend"]
			}
		}, chartConfig.accessibility);

		chartConfig.exporting = Object.assign({}, {
			enabled: exporting || accessibility || false,
			accessibility: {
				enabled: accessibility || false
			},
			// TODO: when we support exporting, either remove this all together or add in what we want to support
			// https://stackoverflow.com/questions/51130861/how-do-i-get-remove-of-data-table-option-from-high-chart-export
			buttons: {
				contextButton: {
					symbol: "menuball",
					menuItems: ["viewData"],
					className: `highcharts-contextbutton${darkTheme === true ? " highchart-dark-theme" : ""}`
				}
			}
		}, chartConfig.exporting);

		if (noDataMsg) {
			chartConfig.lang = Object.assign({}, {
				noData: noDataMsg,
			}, chartConfig.lang);

			chartConfig.noData = Object.assign({}, {
				style: {
					color: theme.palette.text.primary,
					fontSize: "1rem",
					fontWeight: 400
				}
			}, chartConfig.noData);
		}
	}

	render() {
		let {chartConfig, classes} = this.props;

		return (
			<div>
				{/* because highcharts starts with h5, so for heiarchy, need hack here */}
				<h4 className={classes.h4hack}>&nbsp;</h4>
				<HighchartsReact
					options={chartConfig}
					highcharts={Highcharts} />
			</div>
		);
	}
}

Highchart.defaultProps = {
	accessibility: true,
	exporting: false,
	darkTheme: false
};

Highchart.propTypes = {
	chartConfig: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	exporting: PropTypes.bool,
	accessibility: PropTypes.bool,
	darkTheme: PropTypes.bool
};

export default withStyles(styles)(withTheme(Highchart));
