import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Paper,
	withStyles,
	Grid
} from "@material-ui/core";

import Constants from "../utils/Constants";
import Timestamp from  "./Timestamp";
import AvatarRowWithCol from "./AvatarRowWithCol";
import ActivityTopics from "./ActivityTopics";
import PrimaryTextButton from "./PrimaryTextButton";
import MoreOrLess from "./MoreOrLess";
import SourceIndicator from "./SourceIndicator";

const styles = theme => ({
	anchorTextLabel: {
		textTransform: "uppercase",
		fontSize: "0.9rem"
	},

	anchorTextWrapper: {
		display: "flex",
		alignItems: "stretch",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2)
	},

	anchorTextIndicator: {
		width: theme.spacing(0.5),
		backgroundColor: theme.palette.primary.mediumGreen
	},

	anchorText: {
		fontStyle: "italic",
		paddingLeft: theme.spacing(1)
	},

	commentPaper: {
		width: 350,
		padding: theme.spacing(2),
		marginTop: theme.spacing(1),
		borderRadius: 20,
		border: `1px solid ${theme.palette.grey[400]}`,

		"@media (max-width: 425px)": {
			width: 295
		},

		"@media (max-width: 320px)": {
			width: 240
		}
	},

	alertComment: {
		borderColor: theme.palette.error.main
	},

	author: {
		fontSize: "0.9rem",
		color: theme.palette.text.secondary
	},

	content: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},

	viewMoreButton: {
		marginTop: theme.spacing(1.25)
	},

	sourceIconItem: {
		paddingLeft: theme.spacing(1)
	}
});

/************************************************************************/
/* VERY IMPORTANT
/*    SINCE THIS COMPONENT USES dangerouslySetInnerHTML MAKE SURE THE
/*    anchorText THAT'S SENT IN IS EITHER SAFE AND / OR SANITIZED
/************************************************************************/

class GSuiteComments extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			loadMore: false
		};
	}

	render() {
		let {loadMore} = this.state,
			{classes, anchorText, comments, authorName, content, time, libraryTopics, maxComments, hasImage, sourceIcon} = this.props,
			xMore = (comments && comments.length) ? (comments.length+1) - maxComments : 0,
			activityMatch = authorName.match(Constants.ACTIVITY.EMAIL.FROM_REGEX),
			getPaper = (match, content, time, isAlert, idx) => {
				return (
					<Paper
						key={idx}
						elevation={0}
						className={`${classes.commentPaper} ${isAlert ? classes.alertComment : null}`}>
						<AvatarRowWithCol
							ready
							useIconFallback>
							{match ? (
								<div className={classes.author}><b>{match[1]}</b>{match[2] ?
									<React.Fragment><br />{match[2]}</React.Fragment>: null}
								</div>
							) : null}
						</AvatarRowWithCol>
						<div className={classes.content}>{content}</div>
						<Grid container justify="flex-start" alignItems="center">
							<Grid item>
								<Timestamp time={time} relative={false} />
							</Grid>
							<Grid item className={classes.sourceIconItem}>
								{isAlert ? <SourceIndicator {...sourceIcon} /> : null}
							</Grid>
						</Grid>
					</Paper>
				);
			};

		return (
			<React.Fragment>
				<div className={classes.anchorTextLabel}>selected content:</div>
				<MoreOrLess
					right
					hasImage={hasImage}
					truncateHeight={75}
					content={
						<div className={classes.anchorTextWrapper}>
							<div className={classes.anchorTextIndicator}>&nbsp;</div>
							<div className={classes.anchorText} dangerouslySetInnerHTML={{__html: `"${anchorText}"`}}></div>
						</div>
					} />
				<ActivityTopics matches={libraryTopics} />
				{getPaper(activityMatch, content, time, true)}
				{comments ? comments.map((comment, idx) => {
					// 0 is the full string, 1 is name, 2 is email
					// sometimes there isn't an email address so match is null
					let match = comment.author.name.match(Constants.ACTIVITY.EMAIL.FROM_REGEX) || Array(2).fill(comment.author.name);
					// maxComments-1 because the comment that caused the alert is top level, not part of comments array
					return loadMore === true || idx  < maxComments-1 ? getPaper(match, comment.plainContent, comment.time, false, idx) : null;
				}) : null}
				{xMore > 0 && loadMore === false ? (
					<PrimaryTextButton
						offset="left"
						onClick={() => this.setState({loadMore: true})}
						className={classes.viewMoreButton}>
						{xMore} More Comment{xMore > 1 ? "s" : ""}
					</PrimaryTextButton>
				) : null}
			</React.Fragment>
		);
	}
}

GSuiteComments.defaultProps = {
	maxComments: 3
};

GSuiteComments.propTypes = {
	classes: PropTypes.object.isRequired,
	anchorText: PropTypes.string,
	comments: PropTypes.array,
	content: PropTypes.string,
	time: PropTypes.string,
	authorName: PropTypes.string,
	libraryTopics: PropTypes.array,
	maxComments: PropTypes.number,
	hasImage: PropTypes.bool,
	sourceIcon: PropTypes.object
};

export default withStyles(styles)(GSuiteComments);
