class AWS {
	// Checks for s3:// urls for sources using secure images; this piece shouldn't
	// neec to check access, it's just swapping urls so a special api handler fields
	// the request
	static getSecureUrl(url) {
		if(url) {
			let matches = url.match(/^[sS]3:\/\/([^/]*)\/(.*)$/);
			if(matches && matches.length === 3) {
				return `/api/v1/next/secure/${matches[1]}/${matches[2]}`;
			}
		}

		return url;
	}
}

export default AWS;