import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';

const styles = {
	button: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.8rem',
		marginLeft: -8
	},

	buttonContainer: {
		textAlign: 'center'
	}
};

class LoginHelpButton extends Component {

	render() {
		let {children, classes, centered, handleClick} = this.props;

		return (
			<div className={(centered) ? classes.buttonContainer : null}>
				<Button className={classes.button} onClick={handleClick} size="small" variant="text">
					{children}
				</Button>
			</div>
		);
	}
}

LoginHelpButton.propTypes = {
	centered: PropTypes.bool,
	classes: PropTypes.object.isRequired,
	handleClick: PropTypes.func,
	children: PropTypes.string
};

export default withStyles(styles)(LoginHelpButton);