import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {RectShape, TextRow} from 'react-placeholder/lib/placeholders';

import ReportItemPaper from "./ReportItemPaper";
import EmailEngagementReport from "./EmailEngagementReport";
import Constants from "../../utils/Constants";

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const placeHolder = (
	<div style={{padding: 10}}>
		<TextRow color={placeholderColor} style={{marginTop: 0}}><span></span></TextRow>
		<RectShape style={{height: "25rem", marginTop: 10}} color={placeholderColor}><span></span></RectShape>
	</div>
);

class EmailEngagementReportItem extends Component {
	render() {
		let {emailEngagement, fidoError, previousDays} = this.props;

		return (
			<ReportItemPaper
				fidoError={fidoError}
				placeHolder={placeHolder}
				title="Student Email Engagement"
				subtitle="The most active days and times of the week for sending and receiving emails through the school email management system."
				ready={emailEngagement && emailEngagement.length > 0 ? true : false}>
				<EmailEngagementReport
					previousDays={previousDays}
					emailEngagement={emailEngagement} />
			</ReportItemPaper>
		);
	}
}

EmailEngagementReportItem.propTypes = {
	emailEngagement: PropTypes.array,
	fidoError: PropTypes.bool,
	previousDays: PropTypes.number
};

export default EmailEngagementReportItem;