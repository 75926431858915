import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPlaceholder from 'react-placeholder';
import { TextRow } from 'react-placeholder/lib/placeholders';
import MomentCore from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import Constants from "../../utils/Constants";
import Diff from "../../utils/Diff";
import DeconstructActivity from '../../utils/DeconstructActivity';
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";
import Timestamp from '../../components/Timestamp';
import FullscreenMessage from '../../components/FullscreenMessage';
import PostMedia from '../../components/PostMedia.js';
import SourceIndicator from '../../components/SourceIndicator.js';
import ViewOriginalButton from '../../components/ViewOriginalButton';
import ActivityTitle from '../../components/ActivityTitle';
import LoadingIndicator from '../../components/LoadingIndicator';
import Avatar from '../../components/Avatar';
import AuthorLink from '../../components/AuthorLink';
import ActivityContent from "../../components/ActivityContent";

import {
	withStyles,
	withWidth,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Typography,
	Grid,
	IconButton,
	Collapse,
} from '@material-ui/core';

const styles = theme => ({
	hidden: {
		display: "none"
	},

	tablePaper: {
		marginTop: theme.spacing(2)
	},

	tableHead: {
		backgroundColor: theme.palette.grey[200],

		"& > tr > *": {
			verticalAlign: "middle",
			overflow: "visible",
			whiteSpace: "normal",
			textOverflow: "unset"
		}
	},

	tableBody: {
		// The following style is taken from the class MuiTableBody-root
		// Using the TableBody material-ui component causes issues with InfiniteScroll
		// The standard <tbody> tag is currently used so we need to apply this manually
		display: "table-row-group"
	},

	row: {
		"& > *": {
			paddingTop: theme.spacing(1.75),
			paddingBottom: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			maxWidth: 100,
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			verticalAlign: "top",

			"&:last-child": {
				width:  theme.spacing(2), // for toggle arrow
				paddingRight: theme.spacing(2)
			},

			"@media (max-width: 570px)": {
				width: 70
			},

			"@media (max-width: 485px)": {
				width: 50
			},

			"@media (max-width: 410px)": {
				width: 30
			}
		}
	},

	userViewsCell: {
		whiteSpace: "normal"
	},

	userViewsWrapper: {
		marginBottom: theme.spacing(.5)
	},

	centeredCell: {
		display: "flex",
		alignItems: "center"
	},

	expandableRow: {
		height: 0,
		backgroundColor: theme.palette.grey[100],
		transition: `height ${theme.transitions.duration.standard}ms`,

		"& > td:last-child": {
			paddingTop: 0,
			paddingBottom: 0,
			borderBottomWidth: 0,
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5)
		}
	},

	expandRow: {
		height: "auto"
	},

	collapseRow: {
		height: 0
	},

	expandedContent: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},

	publishedCell: {
		width: theme.spacing(7.5),
		paddingTop: theme.spacing(3)
	},

	contentCell: {
		overflow: "visible",
		whiteSpace: "normal",
		textOverflow: "unset"
	},

	authorInfo: {
		alignItems: "flex-start"
	},

	sourceIconItem: {
		paddingLeft: theme.spacing(1)
	},

	sourceGrid: {
		paddingBottom: theme.spacing(1)
	},

	postMedia: {
		paddingTop: theme.spacing(1)
	},

	expandedUsers: {
		paddingTop: theme.spacing(3)
	},

	unviewed: {
		paddingTop: theme.spacing(1)
	},

	avatar: {
		fontSize: "2.3rem"
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const placeholderRow = (
	<div>
		<TextRow color={placeholderColor} style={{width: "30%", display: "inline-block"}} />
		<TextRow color={placeholderColor} style={{width: "61%", marginLeft: "2%", display: "inline-block"}} />
		<TextRow color={placeholderColor} style={{width: "5%", marginLeft: "2%", display: "inline-block"}} />
	</div>
);
const itemPlaceHolder = (
	<div style={{marginTop: 16}}>
		{placeholderRow}
		{placeholderRow}
		{placeholderRow}
	</div>
);

class AlertSummaryTable extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			expandedRows: []
		};

		this.toggleRow = this.toggleRow.bind(this);
	}

	toggleRow(id) {
		let {expandedRows} = this.state;

		if (expandedRows.includes(id)) {
			expandedRows = expandedRows.filter((item) => item !== id);
		} else {
			expandedRows.push(id);
		}

		this.setState({expandedRows: expandedRows});
	}

	render() {
		let {expandedRows} = this.state,
			{classes, activities, isLoading, fidoError, hasMoreItems, authenticatedUser, width, loadNextPage,
				onYoutubePlay, onYoutubeStop, onYoutubeReady} = this.props;

		return (
			activities ? (
				activities.length ? (
					<div>
						<Paper className={classes.tablePaper}>
							<Table>
								<TableHead className={classes.tableHead}>
									<TableRow className={classes.row}>
										<TableCell>Published</TableCell>
										{!width.includes("xs") ? <TableCell>Viewed</TableCell> : null}
										<TableCell>Author</TableCell>
										<TableCell>Content</TableCell>
										<TableCell>&nbsp;</TableCell>
									</TableRow>
								</TableHead>
								<InfiniteScroll
									element="tbody"
									pageStart={1}
									loader={null}
									initialLoad={false}
									loadMore={loadNextPage}
									className={classes.tableBody}
									hasMore={hasMoreItems && !isLoading}>
									{activities && activities.map(activity => {
										let items = [];
										items.push(
											<DeconstructActivity
												activity={activity}
												key={`key-${activity.activityId}`}
												render={props => {
													let {activityId, time,
															authorName, authorImage, authorLink,
															title, content, patch,
															imageUrl, videoUrl, iFrameVideo,
															sourceId, subSourceId, sourceName, sourceIcon, sourceHandle,
															postLink, revSnapshot, extraHeaders, views} = props,
														isExpanded = expandedRows.includes(activityId),
														isTip = sourceName === 'tips',
														isGDoc = sourceId === Constants.SOURCES.IDS.GOOGLE_DOCS,
														isGSuitComment = subSourceId === Constants.SOURCES.IDS.GSUIT_COMMENT,
														anonymize = extraHeaders && authenticatedUser.ghosting,
														maxUsers = 4;

													return [
														<TableRow
															id={activityId}
															key={`key-${activityId}`}
															className={classes.row}>
															<TableCell className={classes.publishedCell}>
																<Timestamp time={time} format="MM/DD/YY" relative={false} />
															</TableCell>
															{!width.includes("xs") ?
																<TableCell className={classes.userViewsCell}>
																	{views && views.length ? views.reduce((result, person, idx) => {
																		if (idx < maxUsers) {
																			result.push(
																				<div
																					key={`$user-${idx}`}
																					className={`${classes.centeredCell} ${classes.userViewsWrapper}`}>
																					<Avatar
																						sizeFactor={-1}
																						authorName={person.user_name}
																						authorImage={person.avatar} />&nbsp;
																					<Timestamp time={person.first_viewed} format="MM/DD/YY" relative={false} />
																					{idx === (maxUsers-1) && views.length-1 > idx ? "..." : ""}
																				</div>
																			);
																		}
																		return result;
																	}, []) : <i><Typography color="textSecondary" className={classes.unviewed}>Unviewed</Typography></i>}
																</TableCell> : null
															}
															<TableCell>
																<div className={`${classes.authorInfo} ${classes.centeredCell}`}>
																	{!width.includes("xs") ? (
																		<Avatar
																			sizeFactor={-1}
																			authorName={authorName}
																			authorImage={authorImage}
																			useIconFallback={true}
																			className={classes.avatar}
																			style={{display: "inline-block"}} />
																	) : null}
																	<AuthorLink
																		name={authorName}
																		link={extraHeaders || isGDoc ? null : authorLink}
																		sourceName={sourceName}
																		sourceHandle={extraHeaders && !anonymize ? extraHeaders.from : sourceHandle} />
																</div>
															</TableCell>
															<TableCell className={classes.contentCell}>
																<div>{content.length > 60 ? `${content.substring(0, 60)}...` : content}</div>
															</TableCell>
															<TableCell>
																<IconButton onClick={() => this.toggleRow(activityId)}>
																	<FontAwesomeIcon icon={["far", `angle-${isExpanded ? "up" : "down"}`]} />
																</IconButton>
															</TableCell>
														</TableRow>,
														<TableRow
															key={`key2-${activityId}`}
															className={`${classes.expandableRow} ${isExpanded ? classes.expandRow : classes.collapseRow}`}>
															<TableCell colSpan={!width.includes("xs") ? 5 : 4}>
																<Collapse in={isExpanded}>
																	<div className={classes.expandedContent}>
																		<Grid
																			container
																			justify="space-between"
																			alignItems="center"
																			className={classes.sourceGrid}>
																			<Grid item>
																				<Grid container justify="flex-start" alignItems="center">
																					<Grid item>
																						<Timestamp time={time} relative={false} />
																					</Grid>
																					{sourceIcon ?
																						<Grid item className={classes.sourceIconItem}>
																							<SourceIndicator {...sourceIcon} />
																						</Grid>	: null
																					}
																				</Grid>
																			</Grid>
																			<Grid item>
																				{!isGSuitComment ? (
																					<ViewOriginalButton
																						offset="none"
																						isTip={isTip}
																						isGDoc={isGDoc}
																						postLink={postLink || revSnapshot}
																						extraHeaders={extraHeaders}
																						icon={isGDoc ? ["fal", "file-pdf"] : null}
																						label={isGDoc ? "Doc Snapshot" : null}
																						iconTitle={isGDoc ? "view doc snapshot" : null} />
																				) : null}
																			</Grid>
																		</Grid>
																		<ActivityTitle title={title} />
																		{patch ? (
																			<ActivityContent
																				patch={patch}
																				sourceId={sourceId}
																				subSourceId={subSourceId}
																				patchRender={(patch)=><Diff patch={patch} wordBreak="break-all" />} />
																		) : (
																			<Typography
																				component="div"
																				style={{fontWeight: 300, overflowWrap: "break-word", wordBreak: "break-all"}}>
																				{content || (extraHeaders ? extraHeaders.content : null)}
																			</Typography>
																		)}
																		{!isGDoc ? (
																			<PostMedia
																				imageUrl={imageUrl}
																				videoUrl={videoUrl}
																				sourceId={sourceId}
																				activityId={activityId}
																				onYoutubePlay={onYoutubePlay}
																				onYoutubeStop={onYoutubeStop}
																				onYoutubeReady={onYoutubeReady}
																				useIframeVideo={iFrameVideo}
																				className={classes.postMedia}>
																			</PostMedia>
																		) : null}
																		<div className={classes.expandedUsers}>
																			<div style={{fontWeight: "bold"}}>User Views</div>
																			{views && views.length ? views.map((person, idx) => {
																				return <span key={`viewed-${idx}`}>{person.user_name}: {new MomentCore.utc(person.first_viewed).local().format("MM/DD/YY @ h:mma")}{idx < views.length-1 ? ", " : ""}</span>;
																			}) : <i><Typography color="textSecondary">Unviewed</Typography></i>}
																		</div>
																	</div>
																</Collapse>
															</TableCell>
														</TableRow>
													];
												}}
											/>
										);

										return items;
									})}
								</InfiniteScroll>
							</Table>
						</Paper>
						{isLoading ?
							<LoadingIndicator /> : null
						}
					</div>
				) : <FullscreenMessage message="No Results" />
			) : (
				<ReactPlaceholder
					ready={false}
					customPlaceholder={itemPlaceHolder}
					showLoadingAnimation={!fidoError}>
					<span></span>
				</ReactPlaceholder>
			)
		);
	}
}

AlertSummaryTable.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	activities: PropTypes.array,
	isLoading: PropTypes.bool,
	hasMoreItems: PropTypes.bool,
	loadNextPage: PropTypes.func.isRequired,
	fidoError: PropTypes.bool,
	onYoutubePlay: PropTypes.func,
	onYoutubeStop: PropTypes.func,
	onYoutubeReady: PropTypes.func,
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <AlertSummaryTable {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "AlertSummaryTable";

export default withStyles(styles)(withWidth()(React.forwardRef(dNameFunc)));
