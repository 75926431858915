import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPlaceholder from 'react-placeholder';

import ReadMore from '../utils/ReadMore';
import Constants from "../utils/Constants";
import NumberFormatter from '../utils/NumberFormatter';

import { Typography, Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		flexGrow: 1,
		paddingTop: theme.spacing(2),
	},

	markerIcon: {
		opacity: "0.7",
		marginRight: theme.spacing(1),
		color: theme.palette.text.secondary
	},

	bold: {
		fontWeight: 500
	},

	rightSpace: {
		paddingRight: theme.spacing(1)
	},

	bottomSpace: {
		paddingBottom: theme.spacing(1.5)
	}
});

class AuthorInfo extends Component {
	render() {
		let {classes, ready, description, location, followers, following, fidoError, twitterRedacted} = this.props,
			followingFormatted = (following) ? NumberFormatter.largeNumberFormat(following) : 0,
			followersFormatted = (followers) ? NumberFormatter.largeNumberFormat(followers) : 0;

		return (
			<div className={classes.root}>
				<ReactPlaceholder type='textRow' ready={ready} showLoadingAnimation={!fidoError} style={{width: "85%"}}>
					{!twitterRedacted ||
						twitterRedacted === Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO ||
						twitterRedacted === Constants.ACTIVITY.TWITTER_REDACTION.DELETE ? (
							<div>
								{(description) ? (
									<Typography color="textSecondary" className={classes.bottomSpace} component="div">
										<ReadMore lines={2} showInline>{description}</ReadMore>
									</Typography>
								) : (null)}
								{(location) ? (
									<Grid className={classes.bottomSpace} container justify="flex-start">
										<Grid item>
											<FontAwesomeIcon icon={["fal", "map-marker-alt"]} className={classes.markerIcon} title="Location Pin on Map" />
										</Grid>
										<Grid item>
											<Typography color="textSecondary">{location}</Typography>
										</Grid>
									</Grid>
								) : (null)}
								<Grid container justify="flex-start">
									<Grid item xs={6} sm={3}>
										<Grid container justify="flex-start">
											<Grid>
												<Typography className={`${classes.bold} ${classes.rightSpace}`}>{followingFormatted}</Typography>
											</Grid>
											<Grid>
												<Typography color="textSecondary">Following</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6} sm={3}>
										<Grid container justify="flex-start">
											<Grid>
												<Typography className={`${classes.bold} ${classes.rightSpace}`}>{followersFormatted}</Typography>
											</Grid>
											<Grid>
												<Typography color="textSecondary">Followers</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</div>
						) : <span></span>}
				</ReactPlaceholder>
			</div>
		);
	}
}

AuthorInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	ready: PropTypes.bool,
	description: PropTypes.string,
	location: PropTypes.string,
	followers: PropTypes.number,
	following: PropTypes.number,
	fidoError: PropTypes.bool,
	twitterRedacted: PropTypes.string
};

export default withStyles(styles)(AuthorInfo);
