
import Sniffr from "sniffr";
import ShareitGood from "../assets/shareit-good.png";
import ShareitBad from "../assets/shareit-bad.png";
import ShareitSad from "../assets/shareit-sad.png";
import ShareitTip from "../assets/shareit-tip.png";
import OutlookIcon from "../assets/outlook-blue.png";
import GSuiteIcon from "../assets/gsuite.png";
import HangoutsIcon from "../assets/hangouts.png";
import GDocsIcon from "../assets/gdocs.png";
import GSheetsIcon from "../assets/gsheets.png";
import GSlidesIcon from "../assets/gslides.png";

const _schnoz = new Sniffr();
if (process.browser) {
	_schnoz.sniff(navigator.userAgent);
}

class Constants {
	// private
	static get _features() {
		return {
			V3: "V3",
			GMAIL: "Gmail",
			GOOGLE_HANGOUTS: "Google Hangouts",
			GOOGLE_DRIVE: "Google Drive",
			EXCHANGE: "Exchange",
			TIPS: "Tips",
			SOCIAL_MEDIA: "Social Media",
			MONTHLY_REPORTING: "MonthlyReporting",
			ACTIVITIES_RELOAD: "ActivitiesReload",
			DISCUSSIONS: "Discussions",
			REPORTING: "Reporting",
			ACCOUNT_CONFIG: "Account Config",
			EVENTS: "Events",
			EMAIL_SENTIMENT: "EmailSentiment",
			SIGNALS_FIRST: "Signals First"
		};
	}

	// private
	static get _permissions() {
		return {
			REPORTS: {
				APPLICATION: "reports.application",
				EMAIL: "reports.email"
			},

			DATA: {
				SOCIAL_MEDIA: "data.social_media",
				GSUITE: "data.gmail",
				EXCHANGE: "data.exchange",
				TIPS: "data.tips"
			},

			MANAGE: {
				USER: "manage.user",
				LOCAL: "manage.local",
				LOCATIONS: "manage.locations",
				NOTIFICATIONS: "manage.notifications"
			},

			WRITE: {
				CORP: "write.corp"
			}
		};
	}

	// private
	static get _sourceIds() {
		return {
			FACEBOOK: 1,
			INSTAGRAM: 4,
			TWITTER: 7,
			YOUTUBE: 9,
			REDDIT: 13,
			GMAIL: 14,
			SHAREIT: 15,
			EXCHANGE: 16,
			HANGOUTS_CHAT: 17,
			GOOGLE_DOCS: 18,
			GOOGLE_SHEETS: 19,
			GOOGLE_SLIDES: 20,
			GSUIT_COMMENT: "comment",
			GOOGLE_CHAT: 23
		};
	}

	static get _gsuiteSourceNames() {
		let obj = {};
		obj[Constants._sourceIds.GOOGLE_DOCS] = "Google Doc";
		obj[Constants._sourceIds.GOOGLE_SHEETS] = "Google Sheet";
		obj[Constants._sourceIds.GOOGLE_SLIDES] = "Google Slide";
		return obj;
	}

	static get _entityIds() {
		return {
			EVENT: 1,
			PERSON: 2,
			PLACE: 3
		};
	}

	// private
	static get _palette() {
		return {
			PRIMARY: {
				MAIN: "#20be83",
				TAN: "#e8e5d6",
				LIGHT_TAN: "#efeee8", // not in brand guidelines
				BRIGHT_GREEN: "#99ec64",
				MEDIUM_GREEN: "#20be83",
				DARK_GREEN: "#008a5f",
				DARK_GREY: "#2d2d2d"
			},

			SECONDARY: {
				MAIN: "#34505E",
				BLUE: "#78efe5",
				YELLOW: "#e1d132",
				ORANGE: "#f9894b",
				PURPLE: "#504b8c",
				SLATE: "#34505E"
			},

			ACCESSIBILITY: {
				MAIN: "#007550",
				SECONDARY_TEXT: "#636363",
				FORM_LABELS: "#757575"
			},

			PLACEHOLDER_BG: "#cdcdcd"
		};
	}

	static get SPA_PATH(){
		return 'next';
	}

	static get SIGNALS_PATH(){
		return 'signals';
	}

	static get PAGE_TITLE() {
		return 'Social Sentinel';
	}

	static get SCHNOZ() {
		return _schnoz;
	}

	static get COLORS() {
		return Constants._palette;
	}

	static get LOCAL_STORAGE() {
		return {
			CONTRAST: "more-contrast"
		};
	}

	static get ERROR_VALIDATORS() {
		return {
			isPhone: 'matchRegexp:^[-() 0-9]*$',
			// When saving validation is rechecked with unmasked value, which is 10 digits so
			// regex needs formatting chars to be optional
			isPhoneWithMask: 'matchRegexp:^\\(?[1-9][0-9][0-9]\\)? ?[1-9][0-9][0-9]-?[0-9][0-9][0-9][0-9]$',
		};
	}

	static get ERROR_MESSAGES() {
		return {
			required: 'field is required',
			isEmail: 'must be valid email',
			isPhone: 'must be valid 10 digit phone number',
			unique: 'already exists'
		};
	}

	static get ENV() {
		return {
			IS_LIVE: process.env.REACT_APP_DEV_ENV === "prod",
			IS_STAGING: process.env.REACT_APP_DEV_ENV === "staging",
			IS_LOCAL: process.env.REACT_APP_DEV_ENV === "local",
			IS_MOBILE: process.browser && /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()),
			IS_IOS: process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent),
			IS_IOS_LT113: process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent) && Constants.SCHNOZ.browser.version[0] < 11.3,
			IS_SAFARI: process.browser && /^((?!chrome|crios|firefox|android).)*safari/i.test(navigator.userAgent),
			IS_EDGE: process.browser && /edge/i.test(navigator.userAgent),
			IS_IE11: process.browser && /ie/i.test(Constants.SCHNOZ.browser.name) && Constants.SCHNOZ.browser.version[0] === 11
		};
	}

	static get ACCOUNT() {
		return {
			VERTICAL: {
				K12: 1,
				HED: 2,
				PUBLIC_SAFETY: 3,
				OTHER: 4,
				ARENA: 5
			},

			FEATURES: Constants._features
		};
	}

	static get USER() {
		return {
			ROLES: {
				TEAM_MEMBER: {
					ID: 5,
					NAME: "Team Member"
				},

				ADMINISTRATOR: {
					ID: 3,
					NAME: "Administrator"
				}
			},

			AVATAR: {
				SIZE: {
					WIDTH: 144,
					HEIGHT: 144
				}
			},

			PERMISSIONS: Constants._permissions
		};
	}

	static get HELP() {
		return {
			PHONE: '3306610106',
			EMAIL: 'clientsuccess@socialsentinel.com'
		};
	}

	static get ENTITIES() {
		return {
			IDS: Constants._entityIds,
		};
	}

	static get SOURCES() {
		return {
			WORDPRESS: "automattic",
			GMAIL: "gmail",
			GSUITE: "gsuite",
			EXCHANGE: "exchange",
			SHAREIT: "tips",
			TWITTER: "twitter",
			INSTAGRAM: "instagram",
			REDDIT: "reddit",
			YOUTUBE: "youtube",
			FACEBOOK: "facebook",
			SOCIAL_MEDIA: "social_media",


			// Currently, all source names match up with fontawesome icon names - except wordpress
			ICONS: {
				automattic: {
					sourceName: 'wordpress',
					icon: {icon: 'wordpress', color: '#238fbd'}
				},

				gsuite: {
					altName: "G Suite",
					icon: {image: GSuiteIcon, altText: "G Suite"}
				},

				gmail: {
					icon: {icon: 'envelope', type: 'fal', color: '#c71610'}
				},

				hangoutsChat: {
					altName: "Hangouts Chat",
					icon: {image: HangoutsIcon, altText: "Hangouts Chat"}
				},

				googleDocs: {
					altName: `${Constants._gsuiteSourceNames[Constants._sourceIds.GOOGLE_DOCS]}s`,
					icon: {image: GDocsIcon, altText: `${Constants._gsuiteSourceNames[Constants._sourceIds.GOOGLE_DOCS]}s`}
				},

				googlePresentation: {
					altName: `${Constants._gsuiteSourceNames[Constants._sourceIds.GOOGLE_SLIDES]}s`,
					icon: {image: GSlidesIcon, altText: `${Constants._gsuiteSourceNames[Constants._sourceIds.GOOGLE_SLIDES]}s`}
				},

				googleSpreadsheet: {
					altName: `${Constants._gsuiteSourceNames[Constants._sourceIds.GOOGLE_SHEETS]}s`,
					icon: {image: GSheetsIcon, altText: `${Constants._gsuiteSourceNames[Constants._sourceIds.GOOGLE_SHEETS]}s`}
				},

				exchange: {
					altName: "Outlook",
					icon: {image: OutlookIcon, altText: 'Outlook'}
				},

				tips: {
					altName: "Shareit",
					icon: {image: ShareitGood, backgroundColor: '#FFC107', altText: 'Shareit - Good'}
				},

				shareitSad: {
					altName: "Shareit",
					icon: {image: ShareitSad, backgroundColor: '#57BCFA', altText: 'Shareit - Sad'}
				},

				shareitBad: {
					altName: "Shareit",
					icon: {image: ShareitBad, backgroundColor: '#ED412D', altText: 'Shareit - Bad'}
				},

				shareitTip: {
					altName: "Shareit",
					icon: {image: ShareitTip, backgroundColor: '#00CDB8', altText: 'Shareit - Tip'}
				},

				twitter: {
					icon: {color: '#38A1F3'}
				},

				instagram: {
					icon: {color: '#0000000'}
				},

				reddit: {
					icon: {color: '#fd4314'}
				},

				youtube: {
					icon: {color: '#ff0000'}
				},

				facebook: {
					icon: {color: "#3C5A99"}
				}
			},

			IDS: Constants._sourceIds,

			DISPLAY_NAMES: {
				automattic: "Wordpress",
				gmail: "Gmail",
				gsuite: "G Suite",
				exchange: "Outlook",
				tips: "Shareit",
				twitter: "Twitter",
				instagram: "Instagram",
				reddit: "Reddit",
				youtube: "YouTube",
				facebook: "Facebook"
			},

			GSUITE_DISPLAY_NAMES: Constants._gsuiteSourceNames,

			FILTER_MAP: [{
				id: "social_media",
				name: "Social Media",
				permission: Constants._permissions.DATA.SOCIAL_MEDIA
			},{
				id: "gsuite",
				name: "G Suite",
				features: [Constants._features.GMAIL, Constants._features.GOOGLE_HANGOUTS, Constants._features.GOOGLE_DRIVE],
				permission: Constants._permissions.DATA.GSUITE
			},{
				id: "exchange",
				name: "Outlook",
				features: [Constants._features.EXCHANGE],
				permission: Constants._permissions.DATA.EXCHANGE
			},{
				id: "tips",
				name: "Shareit",
				features: [Constants._features.TIPS],
				permission: Constants._permissions.DATA.TIPS
			}],

			getAuthorized(authenticatedUser) {
				return Constants.SOURCES.FILTER_MAP.reduce((sources, source) => {
					if (authenticatedUser && authenticatedUser.permissions.includes(source.permission)) {
						if (!source.features) {
							sources.push(source);
						} else {
							if (source.features.some(feature => authenticatedUser.accountFeatures.includes(feature))) {
								sources.push(source);
							}
						}
					}
					return sources;
				}, []);
			}
		};
	}

	static get LOCATION() {
		return {
			STATUSES: [{
				id: "active",
				name: "Active",
			},{
				id: "inactive",
				name: "Inactive"
			}],

			RESTRICTIONS: [
				'church',
				'mosque',
				'islamic',
				'temple',
				'synagogue'
			]
		};
	}

	static get REPORT() {
		return {
			DAYS: [{
				id: 7,
				name: "7 Days",
			},{
				id: 30,
				name: "30 Days",
			},{
				id: 60,
				name: "60 Days",
			},{
				id: 90,
				name: "90 Days",
			},{
				id: 180,
				name: "180 Days",
			},{
				id: 360,
				name: "360 Days",
			}],
		};
	}

	static get ACTIVITY() {
		return {
			CATEGORIES: {
				ALERTS: {
					ID: 1,
					NAME: 'alert'
				},

				DISCUSSIONS: {
					ID: 2,
					NAME: 'discussion'
				}
			},

			EMAIL: {
				FROM_REGEX: new RegExp(/^([^<]*)(<[^>]*>)$/)
			},

			AUDIT_TRAIL: {
				SHARE: {
					ID: "share",
					ICON: "share",
					COLOR: Constants._palette.PRIMARY.BRIGHT_GREEN
				},

				REMOVE: {
					ID: "remove",
					ICON: "trash-alt",
					COLOR: Constants._palette.SECONDARY.PURPLE
				},

				SAVE: {
					ID: "save",
					ICON: "bookmark",
					COLOR: Constants._palette.SECONDARY.YELLOW
				},

				COMMENT: {
					ID: "comment",
					ICON: "comment-alt-lines",
					COLOR: "#2069be"
				},

				RATE: {
					ID: "rate",
					ICON: "abacus",
					COLOR: Constants._palette.SECONDARY.ORANGE
				}
			},

			RATINGS: {
				NO: 1,
				YES: 2,
				MAYBE: 3
			},

			GROUPED_IDS: [
				Constants._sourceIds.GOOGLE_DOCS,
				Constants._sourceIds.GOOGLE_SHEETS,
				Constants._sourceIds.GOOGLE_SLIDES
			],

			TWITTER_REDACTION: {
				DELETE: "status_delete",
				WITHHELD: "status_withheld",
				USER_DELETE: "user_delete",
				USER_PROTECT: "user_protect",
				USER_SUSPEND: "user_suspend",
				USER_WITHHELD: "user_withheld",
				SCRUB_GEO: "scrub_geo",
				getMessage(reason) {
					let msgs = {};
					msgs[Constants.ACTIVITY.TWITTER_REDACTION.DELETE] = "This content is no longer available because the author deleted the post.";
					msgs[Constants.ACTIVITY.TWITTER_REDACTION.WITHHELD] = "This content is not available because it is being withheld due to country of origin.";
					msgs[Constants.ACTIVITY.TWITTER_REDACTION.USER_DELETE] = "This content is no longer available because the author deleted their account.";
					msgs[Constants.ACTIVITY.TWITTER_REDACTION.USER_PROTECT] = "This content is no longer available because the author has protected their account.";
					msgs[Constants.ACTIVITY.TWITTER_REDACTION.USER_SUSPEND] = "This content is no longer available because the author's account was suspended.";
					msgs[Constants.ACTIVITY.TWITTER_REDACTION.USER_WITHHELD] = "This content is no longer available because it is being withheld due to the author's identification in a specific country.";
					msgs[Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO] = "Geodata is no longer available because the author has disabled location services.";
					return msgs[reason];
				}
			},

			ACTION_BUTTONS: {
				getRemove(obj) {
					return Object.assign({
						label: "Remove",
						icon: ["fal", "trash-alt"],
						primary: true,
						color: "primary"
					}, obj);
				},

				getSave(obj) {
					return Object.assign({
						label: "Save",
						icon: [obj.flagged ? "fas" : "fal", "bookmark"],
						primary: true,
						color: "primary"
					}, obj);
				},

				getShare(obj) {
					return Object.assign({
						label: "Share",
						icon: ["fal", "share"],
						primary: true,
						color: "primary"
					}, obj);
				}
			}
		};
	}

	static get EVENT() {
		return {
			STATUSES: [{
				id: "past",
				name: "Past",
			},{
				id: "active",
				name: "Active"
			},{
				id: "future",
				name: "Future"
			}]
		}
	}

	static get SERVICES() {
		return {
			SENTIMENT: {
				LIVE: "https://4z9rn7nemg.execute-api.us-east-1.amazonaws.com/v1/student-sentiment",
				STAGING: "https://aahhbighx3.execute-api.us-east-1.amazonaws.com/v1/student-sentiment",
				//LOCAL: "https://y2vu1h1zib.execute-api.us-east-1.amazonaws.com/v1/student-sentiment",
				getUrl() {return !Constants.ENV.IS_LIVE ? Constants.SERVICES.SENTIMENT.STAGING : Constants.SERVICES.SENTIMENT.LIVE;}
			}
		};
	}

	static get INTERCOM() {
		return {
			APP_ID: {
				LOCAL: "p1gs8non",
				LIVE: "zen3orcw"
			}
		};
	}
}

export default Constants;
