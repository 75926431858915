import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Constants from "../utils/Constants";
import ResponsiveDialog from "./ResponsiveDialog";

import {
	withStyles,
	withWidth,
	Grid,
	Typography,
	Button,
	TextField,
	Divider,
	RootRef,
} from '@material-ui/core';

const styles = theme => ({
	grid: {
		minHeight: theme.spacing(43.75)
	},

	button: {
		"@media (hover: none)": {
			"&:hover": {
				backgroundColor: theme.palette.primary.main
			}
		}
	},

	buttonGrid: {
		marginTop: theme.spacing(3)
	},

	noteFieldRoot: {
		fontSize: "1rem"
	},

	noteField: {
		width: "100%",
		color: theme.palette.primary.darkGrey,
		borderRadius: theme.spacing(2.5),
		backgroundColor: theme.palette.primary.lightTan,

		"& input": {
			padding: "9px 12px"
		}
	},

	saveBtn: {
		whiteSpace: "nowrap"
	},

	cancelBtnItem: {
		paddingRight: theme.spacing(1)
	}
});

class RateAlertDialog extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = this.initialState;
		this.dialog = React.createRef();

		this.rateAssociation = this.rateAssociation.bind(this);
		this.rateThreat = this.rateThreat.bind(this);
		this.noteChanged = this.noteChanged.bind(this);
		this.saveClicked = this.saveClicked.bind(this);
		this.onEntering = this.onEntering.bind(this);
	}

	get initialState() {
		return {
			associated: null,
			threat: null,
			comments: null
		};
	}

	componentDidMount() {
		this.setState(this.props.rating);
	}

	onEntering() {
		let {rating} = this.props;

		if (rating.id == null) {
			Object.assign(rating, this.initialState);
		}

		this.setState(rating);
	}

	rateAssociation(rating) {
		this.setState({associated: rating});
	}

	rateThreat(rating) {
		this.setState({threat: rating});
	}

	noteChanged(ev) {
		this.setState({comments: ev.target.value});
	}

	saveClicked() {
		this.props.rateHandler(this.state);
		this.props.closeHandler();
	}

	render() {
		let {associated, threat, comments} = this.state,
			{classes, open, width, closeHandler} = this.props,
			smaller = width.includes("sm") || width.includes("xs"),
			content = (
				<RootRef rootRef={this.dialog}>
					<div>
						<Grid container direction="column" justify="space-between" className={classes.grid}>
							<Grid item>
								<Typography className={classes.question}>1. Relevant to my institution or community?</Typography>
								<Grid container justify="space-around" className={classes.buttonGrid}>
									<Grid item>
										<Button
											onClick={() => this.rateAssociation(Constants.ACTIVITY.RATINGS.YES)}
											className={classes.button}
											variant={associated === Constants.ACTIVITY.RATINGS.YES ? "contained" : "outlined"}
											color={associated === Constants.ACTIVITY.RATINGS.YES ? "primary" : "default"}>
											Yes
										</Button>
									</Grid>
									<Grid item>
										<Button
											onClick={() => this.rateAssociation(Constants.ACTIVITY.RATINGS.NO)}
											className={classes.button}
											variant={associated === Constants.ACTIVITY.RATINGS.NO ? "contained" : "outlined"}
											color={associated === Constants.ACTIVITY.RATINGS.NO ? "primary" : "default"}>
											No
										</Button>
									</Grid>
									<Grid item>
										<Button
											onClick={() => this.rateAssociation(Constants.ACTIVITY.RATINGS.MAYBE)}
											className={classes.button}
											variant={associated === Constants.ACTIVITY.RATINGS.MAYBE ? "contained" : "outlined"}
											color={associated === Constants.ACTIVITY.RATINGS.MAYBE ? "primary" : "default"}>
											Unsure
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Typography className={classes.question}>2. Actionable alert related to safety or security?</Typography>
								<Grid container justify="space-around" className={classes.buttonGrid}>
									<Grid item>
										<Button
											onClick={() => this.rateThreat(Constants.ACTIVITY.RATINGS.YES)}
											className={classes.button}
											variant={threat === Constants.ACTIVITY.RATINGS.YES ? "contained" : "outlined"}
											color={threat === Constants.ACTIVITY.RATINGS.YES ? "primary" : "default"}>
											Yes
										</Button>
									</Grid>
									<Grid item>
										<Button
											onClick={() => this.rateThreat(Constants.ACTIVITY.RATINGS.NO)}
											className={classes.button}
											variant={threat === Constants.ACTIVITY.RATINGS.NO ? "contained" : "outlined"}
											color={threat === Constants.ACTIVITY.RATINGS.NO ? "primary" : "default"}>
											No
										</Button>
									</Grid>
									<Grid item>
										<Button
											onClick={() => this.rateThreat(Constants.ACTIVITY.RATINGS.MAYBE)}
											className={classes.button}
											variant={threat === Constants.ACTIVITY.RATINGS.MAYBE ? "contained" : "outlined"}
											color={threat === Constants.ACTIVITY.RATINGS.MAYBE ? "primary" : "default"}>
											Unsure
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Divider />
								<TextField
									name="note"
									margin="normal"
									placeholder={"Add optional note..."}
									value={comments || ""}
									onChange={this.noteChanged}
									className={classes.noteField}
									InputProps={{
										disableUnderline: true,
										classes: {root: classes.noteFieldRoot}
									}} />
								<Grid container justify="flex-end" alignItems="center" className={classes.buttonGrid}>
									<Grid item className={classes.cancelBtnItem}>
										{!smaller ?
											<Button onClick={closeHandler}>
												Cancel
											</Button> : null
										}
									</Grid>
									<Grid item>
										<Button
											color="primary"
											variant="contained"
											className={classes.saveBtn}
											disabled={associated == null || threat == null}
											onClick={this.saveClicked}>
											Rate Alert
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</RootRef>
			);

		return (
			<ResponsiveDialog
				open={open}
				title="Rate Alert"
				content={content}
				hasCancel={!smaller}
				onEntering={this.onEntering}
				closeHandler={closeHandler} />
		);
	}
}

RateAlertDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	closeHandler: PropTypes.func.isRequired,
	open: PropTypes.bool,
	rating: PropTypes.object,
	rateHandler: PropTypes.func.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

export default withStyles(styles)(withWidth()(RateAlertDialog));
