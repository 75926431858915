import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from "../utils/Constants";

import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
	activityType: {
		paddingBottom: 10,
		fontSize: "0.9rem",
		textTransform: "uppercase"
	}
});

class ActivityCategory extends Component {
	render() {
		let {classes, category, plural} = this.props,
			activityType = (category === Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.ID) ?
				Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.NAME : Constants.ACTIVITY.CATEGORIES.ALERTS.NAME,
			activityTypeColor = (category === Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.ID) ? 'textSecondary' : 'error';

		return (
			<Typography color={activityTypeColor} className={classes.activityType}>{`${activityType}${plural ? "s" : ""}`}</Typography>
		);
	}
}

ActivityCategory.defaultProps = {
	plural: false
};

ActivityCategory.propTypes = {
	classes: PropTypes.object.isRequired,
	category: PropTypes.number.isRequired,
	plural: PropTypes.bool
};

export default withStyles(styles)(ActivityCategory);
