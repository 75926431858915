import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActivityContent from './ActivityContent';

import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
	tipRoot: {
		marginTop: theme.spacing(2)
	}
});

class TipInfo extends Component {
	render() {
		let {classes, concernOtherDescription, history} = this.props;

		return (
			<div className={classes.tipRoot}>
				<Typography variant="caption">Concern for Someone Else</Typography>
				<ActivityContent content={concernOtherDescription} history={history} />
			</div>
		);
	}
}

TipInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	concernOtherDescription: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired
	// authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

export default withStyles(styles)(TipInfo);

// export default withStyles(styles)(React.forwardRef((props, ref) => (
// 	<AuthorizedUserContext.Consumer>
// 		{authenticatedUser => <TipInfo {...props} authenticatedUser={authenticatedUser} ref={ref} />}
// 	</AuthorizedUserContext.Consumer>
// )));
