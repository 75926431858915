import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles, IconButton, InputAdornment, TextField } from '@material-ui/core';

const styles = theme => ({
	searchForm: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2)
	},

	searchField: {
		width: "100%",
		borderRadius: "20px",
		backgroundColor: theme.palette.grey[200],

		"& input": {
			padding: "8px 10px 6px"
		},
	}
});

class SearchForm extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			search: '',
			isFocused: false,
			canClear: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.postClicked = this.postClicked.bind(this);
		this.clearClicked = this.clearClicked.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.timeout = null;
	}

	handleKeyPress(event) {
		if (event.charCode === 13) {
			event.preventDefault();
			this.submitForm(this.state);
		}
	}

	handleClick(event) {
		// so other components click handlers don't fire
		event.stopPropagation();
	}

	handleBlur(event) {
		this.setState({
			isFocused: false
		});
	}

	handleFocus(event) {
		this.setState({
			isFocused: true
		});
	}

	postClicked(event) {
		// so other components click handlers don't fire
		event.stopPropagation();
		this.submitForm(this.state);
	}

	clearClicked(event) {
		// so other components click handlers don't fire
		event.stopPropagation();
		this.props.clearHandler();
		this.setState({
			search: '',
			isFocused: false,
			canClear: false
		});
	}

	submitForm(update) {
		if(update) {
			this.props.searchHandler({
				search: update.search
			});
			this.setState({
				isFocused: false,
				canClear: (update.search === '') ? false : true
			});
		}
	}

	handleChange(event) {
		let update = {},
			value = event.target.value;
		update[event.target.name] = value;
		update.isFocused = true;
		this.setState(update, () => {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => this.submitForm(this.state), 300);
		});
	}

	render() {
		let {classes, formClass, fieldClass} = this.props;
		let buttonColor = this.state.isFocused ? "primary" : "default";
		return (
			<form
				noValidate
				autoComplete="off"
				className={`${classes.searchForm} ${formClass}`}>
				<TextField
					name="search"
					placeholder="Search"
					onClick={this.handleClick}
					onKeyPress={this.handleKeyPress}
					onChange={this.handleChange}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					value={this.state.search}
					className={`${classes.searchField} ${fieldClass}`}
					margin="normal"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{(this.state.canClear === false) ? (
									<IconButton	aria-label="search" onClick={this.postClicked} color={buttonColor}>
										<FontAwesomeIcon icon={["fal", "search"]} size="xs" title="search" />
									</IconButton>
								) : (
									<IconButton aria-label="clear" onClick={this.clearClicked}>
										<FontAwesomeIcon icon={["fal", "times"]} size="xs" title="clear" />
									</IconButton>
								)}
							</InputAdornment>
						),
						disableUnderline: true
					}}
				/>
			</form>
		);
	}
}

SearchForm.propTypes = {
	classes: PropTypes.object.isRequired,
	searchHandler: PropTypes.func,
	formClass: PropTypes.string,
	fieldClass: PropTypes.string
};

export default withStyles(styles)(SearchForm);
