import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {RectShape, TextRow} from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';

import Constants from "../../utils/Constants";

import { withStyles, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
	item: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),

		[theme.breakpoints.up('xs')]: {
			marginTop: theme.spacing(4)
		},

		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(3)
		},

		[theme.breakpoints.up('lg')]: {
			marginTop: theme.spacing(6)
		},
	},

	subtitle: {
		marginBottom: theme.spacing(.5)
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const graphPlaceHolder = (
	<div style={{padding: 10}}>
		<TextRow color={placeholderColor} style={{marginTop: 0}}><span></span></TextRow>
		<RectShape style={{height: "25rem", marginTop: 10}} color={placeholderColor}><span></span></RectShape>
	</div>
);

const graphPlaceHolderSmall = (
	<div style={{padding: 10}}>
		<TextRow color={placeholderColor} style={{marginTop: 0}}><span></span></TextRow>
		<RectShape style={{height: "8rem", marginTop: 10}} color={placeholderColor}><span></span></RectShape>
	</div>
);

class DetailedReport extends Component {
	render() {
		let {classes, children, ready, fidoError, sizing, ignoreSpacing, smallGraph, title, subtitle, gridClassName} = this.props;

		return (
			<Grid container justify="center" alignItems="stretch" className={gridClassName}>
				<Grid item className={!ignoreSpacing? classes.item : null} {...sizing}>
					<ReactPlaceholder
						ready={ready}
						customPlaceholder={smallGraph? graphPlaceHolderSmall : graphPlaceHolder}
						showLoadingAnimation={!fidoError}>
						<Typography variant="subtitle1">{title}</Typography>
						<Typography variant="body1" color="textSecondary" className={classes.subtitle}>{subtitle}</Typography>
						{children}
					</ReactPlaceholder>
				</Grid>
			</Grid>
		);
	}
}

DetailedReport.defaultProps = {
	sizing: {
		xs: 12,
		sm: 12,
		md: 12,
		lg: 12,
		xl: 12
	}
};

DetailedReport.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	ready: PropTypes.bool,
	fidoError: PropTypes.bool,
	sizing: PropTypes.object,
	ignoreSpacing: PropTypes.bool,
	smallGraph: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	subtitle: PropTypes.string,
	gridClassName: PropTypes.string
};

export default withStyles(styles)(DetailedReport);