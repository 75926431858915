import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Highchart from "./Highchart";
import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";

import { withTheme, withStyles } from '@material-ui/core';

// TEMP
//import {gsuiteOverviewData} from "../../data/reports.js";

const styles = theme => ({
	columnChart: {
		"& .highcharts-point": {
			rx: 4,
			ry: 4,
			transform: "translate(0px, -1px)"
		},

		"& .highcharts-legend": {
			"& .highcharts-point": {
				rx: 12,
				ry: 12
			}
		}
	}
});

class GSuiteEngagementReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {classes, theme, engagement, previousDays} = this.props,
			massagedEmail = ReportDataMasseuse.massageData(engagement.email.associations, "day", "associations"),
			massagedChats = ReportDataMasseuse.massageData(engagement.chats.associations, "day", "associations"),
			massagedDocs = ReportDataMasseuse.massageData(engagement.docs.associations, "day", "associations");

		this.state = {
			chartConfig: HighchartsConfig.merge({
				chart: {
					type: 'column',
					height: 340,
					marginTop: 15,
					className: classes.columnChart
				},
				title: "",
				legend: {enabled: true},
				accessibility: {
					pointValueSuffix: " emails",
					description: `Average number of emails, chats and docs scanned by day of the week over the last ${previousDays} days.`
				},
				yAxis: {
					title: "",
					lineWidth: 0,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					labels: {
						y: 0,
						style: {
							fontSize: "15px"
						}
					}
				},
				xAxis: {
					type: "category",
					tickWidth: 0,
					lineWidth: 0,
					labels: {
						y: 30,
						align: "center",
						style: {
							fontSize: "15px"
						},
						formatter: function() {
							return this.value.charAt(0);
						}
					},
					categories: this.getDataPoints(massagedEmail, 0)
				},
				tooltip: {
					shared: true,
					formatter: function() {
						console.log(this);
						const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 0});
						return this.points.reduce(function (s, point) {
							return `${s}<br/><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${numFormatter.format(point.y)}</b><br/>`;
						}, `<span style='font-weight: bold'>${this.x}</span><br/>`);
					}
				},
				series: [{
					name: "Emails",
					color: theme.palette.primary.mediumGreen,
					data: this.getDataPoints(massagedEmail, 1)
				},{
					name: "Chats",
					color: theme.palette.secondary.yellow,
					data: this.getDataPoints(massagedChats, 1)
				},{
					name: "Docs",
					color: theme.palette.secondary.purple,
					data: this.getDataPoints(massagedDocs, 1)
				}]
			})
		};
	}

	getDataPoints(massaged, key) {
		let dataArray = [];
		massaged.map(function(point) {
			dataArray.push(point[key]);
			return dataArray;
		});
		return dataArray;
	}

	render() {
		let {chartConfig} = this.state;
		return (
			<Highchart chartConfig={chartConfig} />
		);
	}
}

GSuiteEngagementReport.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	previousDays: PropTypes.number,
	engagement: PropTypes.object
};

export default withStyles(styles)(withTheme(GSuiteEngagementReport));
