import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	image: {
		width: "1.2rem"
	}
});

class SourceImage extends Component {
	render() {
		let {classes, image, altText, backgroundColor} = this.props;
		return (
			<img src={image}
				alt={altText ? altText : 'Data source icon'}
				style={{backgroundColor: backgroundColor}}
				className={classes.image} />
		);
	}
}

SourceImage.propTypes = {
	classes: PropTypes.object.isRequired,
	image: PropTypes.string,
	backgroundColor: PropTypes.string,
	altText: PropTypes.string
};

export default withStyles(styles)(SourceImage);