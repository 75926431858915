import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles, Button } from '@material-ui/core';

const styles = theme => ({
	button: {
		textAlign: "left",
		marginLeft: theme.spacing(-1),
		marginRight: theme.spacing(2)
	}
});

class TrendingTopicLinks extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e, topic) {
		e.preventDefault();
		e.stopPropagation();
		this.props.history.push(`/topics/${topic.trending_topic.id}`);
	}

	render() {
		let {classes, topics} = this.props;
		return (
			topics && topics.length ?
				<div>
					{topics.map(topic =>
						<Button
							size="small"
							color="primary"
							className={classes.button}
							key={`key-${topic.id}`}
							onClick={(e) => {this.handleClick(e, topic);}}>
							{topic.trending_topic.name.replace(/\|/ig, "/")}
						</Button>
					)}
				</div> : null
		);
	}
}

TrendingTopicLinks.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	topics: PropTypes.array
};

export default withStyles(styles)(withRouter(TrendingTopicLinks));
