import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles, withTheme } from '@material-ui/core';

const styles = theme => ({
	iconWrapper: {
		display: 'block',
		position: 'relative'
	},

	iconOpaque: {
		opacity: 0.3
	},

	iconOuter: {
		display: 'block',
		position: 'absolute',
		top: 0,
		height: theme.spacing(8),
		width: theme.spacing(8)
	},

	iconInner: {
		display: 'flex',
		position: 'absolute',
		top: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},

	innerRegular: {
		height: theme.spacing(6),
		width: theme.spacing(6),
		margin: theme.spacing(1)
	},

	innerLarge: {
		height: theme.spacing(8),
		width: theme.spacing(8),
	}
});

class BorderIcon extends Component {
	render() {
		let {classes, backgroundColor, icon, hideInner, solidOuter} = this.props;

		return (
			<span className={classes.iconWrapper}>
				<span className={`${classes.iconOuter} ${solidOuter ? null : classes.iconOpaque}`} style={{ backgroundColor }}></span>
				<span className={`${classes.iconInner} ${solidOuter? classes.innerLarge : classes.innerRegular}`} style={hideInner ? null : { backgroundColor }}>
					{icon}
				</span>
			</span>
		);
	}
}

BorderIcon.propTypes = {
	classes: PropTypes.object.isRequired,
	backgroundColor: PropTypes.string,
	icon: PropTypes.object,
	hideInner: PropTypes.bool
};

export default withStyles(styles)(withTheme(BorderIcon));