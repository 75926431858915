import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import ReportItemGrid from "./ReportItemGrid";
import FillBar from "./FillBar";

import { withStyles, Typography, Grid } from '@material-ui/core';

const styles = theme => ({
	topGrid: {
		paddingBottom: theme.spacing(1)
	},

	barItemText: {
		color: "#666666"
	}
});

class ReportBarsItem extends Component {
	render() {
		let {classes, barColors, data, fidoError, history, noDataMessage} = this.props;

		return (			
			<ReportItemGrid
				noDivider
				fidoError={fidoError}
				items={data}
				history={history}
				noDataMessage={noDataMessage}
				contentRenderer={item => {
					let itemDivisibleTotal = (item.total > 0) ? item.total : 1;
					let itemWidth = ((item.count / itemDivisibleTotal) * 100) + "%";
					let itemBackground = (barColors[item.id] !== 'undefined') ? barColors[item.id] : null;
					return (
						<Grid container alignItems="stretch">
							<Grid item xs={12}>
								<Grid container justify="space-between" className={classes.topGrid}>
									<Grid item>
										<Typography className={classes.barItemText} noWrap>{item.name}</Typography>
									</Grid>
									<Grid item>
										<Typography className={classes.barItemText} noWrap>{item.count} of {item.total} {item.type}</Typography>
									</Grid>
								</Grid>
								<FillBar backgroundColor={itemBackground} width={itemWidth} />
							</Grid>
						</Grid>
					);
			}} />
		);
	}
}

ReportBarsItem.propTypes = {
	history: PropTypes.object.isRequired,
	fidoError: PropTypes.bool,
	data: PropTypes.array,
	barColors: PropTypes.array,
	noDataMessage: PropTypes.string
};

export default withStyles(styles)(withTheme(ReportBarsItem));