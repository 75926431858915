import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	chart: {
		"& .highcharts-title": {
			[theme.breakpoints.down('sm')]: {
				display: "none"
			}
		}
	},

	basicBar: {
		"& .highcharts-point": {
			rx: 10,
			ry: 10,
			transform: "translate(0px, -5px)"
		}
	},

	tightBar: {
		"& .highcharts-point": {
			rx: 8,
			ry: 8,
			transform: "translate(0px, -5px)"
		},

		"&.highcharts-legend-item": {
			"& .highcharts-point": {
				transform: "translate(0px, -0px)"
			}
		}
	},

	tighterBar: {
		"& .highcharts-point": {
			rx: 5,
			ry: 5,
			transform: "translate(0px, -5px)"
		}
	},

	basicColumn: {
		"& .highcharts-point": {
			rx: 5,
			ry: 5,
			transform: "translate(0px, -1px)",

			"@media (min-width: 1200px)": {
				rx: 8,
				ry: 8
			},

			[theme.breakpoints.down('xs')]: {
				rx: 3,
				ry: 3
			}
		}
	},

	"7dayColumn": {
		"& .highcharts-point": {
			rx: 25,
			ry: 25,
			transform: "translate(0px, -1px)",

			"@media (min-width: 1200px)": {
				rx: 35,
				ry: 35
			},

			[theme.breakpoints.down('xs')]: {
				rx: 10,
				ry: 10
			}
		}
	},

	"30dayColumn": {
		"& .highcharts-point": {
			rx: 8,
			ry: 8,
			transform: "translate(0px, -1px)",

			"@media (min-width: 1200px)": {
				rx: 10,
				ry: 10
			},

			[theme.breakpoints.down('xs')]: {
				rx: 5,
				ry: 5
			}
		}
	}
});

class ChartClasses extends Component {
	render() {
		let {classes, render} = this.props;
		return render({chartClasses: classes});
	}
}

ChartClasses.propTypes = {
	classes: PropTypes.object.isRequired,
	render: PropTypes.func.isRequired
};

export default withStyles(styles)(ChartClasses);