import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AuthorizedUser from "../utils/AuthorizedUser";

import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
	root: {
		position: 'relative',
		height: theme.spacing(6)
	},

	anchor: {
		bottom: 0,
		width: "100%",
		position: "absolute"
	},

	light: {
		color: `${theme.palette.background.default} !important`
	},

	moreContrast: {
		color: theme.palette.accessibilityColors.text.secondary
	},

	caption: {
		fontSize: "0.75rem",

		[theme.breakpoints.down('md')]: {
			fontSize: "0.65rem",
		}
	},

	link: {
		cursor: "pointer",
		color: theme.palette.text.secondary,
		// Overriding links style - probably want to do globably - unrelated to material UI
		textDecoration: 'none',
		'&:focus': {
			backgroundColor: 'rgba(0, 0, 0, 0.08)',
			outlineWidth: '0px'
		}
	}
});

class Footer extends Component {
	go = (path) => {
		let {history, closeHandler} = this.props;
		history.push(path);
		if (closeHandler) {
			closeHandler();
		}
	}

	render() {
		let year = new Date().getFullYear(),
			{classes, light, hideTwitterTos, anchor} = this.props,
			moreContrast = AuthorizedUser.wantsMoreContrast();

		return (
			<footer className={`${classes.root} ${anchor ? classes.anchor : null}`}>
				<Typography
					align="center"
					color="textSecondary"
					variant="caption"
					classes={{caption: classes.caption}}
					className={`${light ? classes.light : null}`}>
					<a href="https://detect.navigate360.com/terms"
						rel="noopener noreferrer"
						target="_blank"
						className={`${!moreContrast ? classes.link : null} ${light ? classes.light : null}`}>Terms of Service</a>
					&nbsp;|&nbsp;
					<a onClick={() => this.go("/privacy")}
						className={`${!moreContrast ? classes.link : null} ${light ? classes.light : null}`}>Privacy</a>
					{hideTwitterTos ? (null) : (
						<span className={light ? classes.light : null}>
							&nbsp;|&nbsp;
							<a href="https://twitter.com/tos"
								target="_blank"
								rel="noopener noreferrer"
								className={`${!moreContrast ? classes.link : null} ${light ? classes.light : null}`}>Twitter TOS</a>
						</span>
					)}
					<br/>
					<span className={`${moreContrast ? classes.moreContrast : light ? classes.light : null}`}>&copy;&nbsp;{year} Social Sentinel Inc.</span>
				</Typography>
			</footer>
		);
	}
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	closeHandler: PropTypes.func,
	light: PropTypes.bool,
	hideTwitterTos: PropTypes.bool,
	anchor: PropTypes.bool
};

export default withStyles(styles)(Footer);
