import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {TextBlock, RoundShape} from 'react-placeholder/lib/placeholders';

import Constants from "../../utils/Constants";
import ReportItemPaper from "./ReportItemPaper";
import UserEngagementTable from "./UserEngagementTable";

import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
	noActivity: {
		marginTop: "45%",
		textAlign: "center"
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const tablePlaceHolder = (
	<div style={{paddingTop: 20, paddingBottom: 20}}>
		<div style={{display: "flex"}}>
			<div style={{marginRight: 10}}>
				<RoundShape color={placeholderColor} style={{height: 48, width: 48}} />
			</div>
			<div style={{flex: 1, margin: "auto"}}>
				<TextBlock rows={2} color={placeholderColor}></TextBlock>
			</div>
		</div>
		<div style={{display: "flex", marginTop: 15}}>
			<div style={{marginRight: 10}}>
				<RoundShape color={placeholderColor} style={{height: 48, width: 48}} />
			</div>
			<div style={{flex: 1, margin: "auto"}}>
				<TextBlock rows={2} color={placeholderColor}></TextBlock>
			</div>
		</div>
	</div>
);

class UserEngagementReportItem extends Component {
	render() {
		let {classes, fidoError, usersEngagement,
			sortDir, sortBy, totalUsers, footerClickHandler, smallEngagementTable} = this.props;

		return (
			<ReportItemPaper
				taller
				ready={usersEngagement != null}
				fidoError={fidoError}
				placeHolder={tablePlaceHolder}
				title="User Engagement">
				{usersEngagement && usersEngagement.length ?
					<UserEngagementTable
						forceSmall={smallEngagementTable}
						users={usersEngagement}
						sortDir={sortDir}
						sortBy={sortBy}
						footer={{
							total: totalUsers,
							limit: usersEngagement ? usersEngagement.length : 0,
							clickHandler: footerClickHandler
						}} />
					: <Typography color="textSecondary" className={classes.noActivity}>There is no activity for this time period.</Typography>
				}
			</ReportItemPaper>
		);
	}
}

UserEngagementReportItem.defaultProps = {
	forceSmall: false
};

UserEngagementReportItem.propTypes = {
	classes: PropTypes.object.isRequired,
	fidoError: PropTypes.bool,
	usersEngagement:PropTypes.array,
	sortBy: PropTypes.string,
	sortDir: PropTypes.string,
	totalUsers: PropTypes.number,
	footerClickHandler: PropTypes.func,
	smallEngagementTable: PropTypes.bool
};

export default withStyles(styles)(UserEngagementReportItem);