import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Grid } from '@material-ui/core';

const styles = theme => ({
	container: {
		paddingTop: "50%",
		[theme.breakpoints.up('sm')]: {
			paddingTop: "40%",
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: "35%",
		},
	},
});

class FullscreenMessage extends Component {
	render() {
		let { classes, message, style } = this.props;

		return (
			<Grid className={classes.container} container direction="column" alignItems="center" justify="center" style={style}>
				<Grid item>
					<Typography variant="h5" color="textSecondary">{message}</Typography>
				</Grid>
			</Grid>
		);
	}
}

FullscreenMessage.propTypes = {
	message: PropTypes.string,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullscreenMessage);
