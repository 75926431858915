import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	icon: {
		fontSize: "1.2rem"
	}
});

class SourceIcon extends Component {
	render() {
		let {classes, icon, type, color} = this.props;

		return (
			<FontAwesomeIcon
				color={color}
				className={`${classes.icon}`}
				title={`${icon || "source"} icon`}
				icon={[type ? type : "fab", icon]} />
		);
	}
}

SourceIcon.defaultProps ={
	color: "#9f9f9f"
};

SourceIcon.propTypes = {
	classes: PropTypes.object.isRequired,
	icon: PropTypes.string,
	type: PropTypes.string,
	color: PropTypes.string
};

export default withStyles(styles)(SourceIcon);