import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import {TextRow, RoundShape} from 'react-placeholder/lib/placeholders';
import MomentCore from 'moment';
import Moment from "react-moment";

import AuditTrailIcon from "./AuditTrailIcon";
import Constants from "../utils/Constants";
import Timestamp from './Timestamp.js';
import PrimaryTextButton from "./PrimaryTextButton";

import { withStyles, withWidth, Typography, Grid, Divider } from '@material-ui/core';

// this needs to match AuditTrailIcon size, which can be passed into that component but 28 is the default
const iconSize = 28;
const rowHeight = 85;

const styles = theme => ({
	content: {
		padding: theme.spacing(3),
	},

	dateHeading: {
		marginBottom: theme.spacing(1.5)
	},

	row: {
		height: rowHeight,
		maxHeight: rowHeight,
		position: "relative"

		/*"&:before": {
			height: 54,
			content: "no-close-quote",
			border: "1px solid red",
			position: "absolute",
			marginTop: rowHeight - iconSize, //57,
			marginLeft: iconSize - 32 //-4,
		}*/
	},

	iconCol: {
		paddingLeft: theme.spacing(1)
	},

	textCol: {
		paddingLeft: theme.spacing(3),
		width: `calc(100% - ${2 * theme.spacing(3)}px)`,

		// all for multi-line ellipsis
		"& > p:first-child": {
			position: "relative",
			overflow: "hidden",
			maxHeight: "26%",

			[theme.breakpoints.up('lg')]: {
				maxHeight: "63%",
			},

			/* fix problem when last visible word doesn't adjoin right side  */
			//textAlign: "justify",
			/* place for '...' */
			marginRight: "-1em",
			paddingRight: "1em",
			wordBreak: "break-word",

			"&:before": {
				content: "'...'",
				position: "absolute",
				/* set position to right bottom corner of block */
				right: 0,
				bottom: 0
			},

			/* hide ... if we have text, which is less than or equal to max lines */
			"&:after": {
				/* points in the end */
				content: "''",
				/* absolute position */
				position: "absolute",
				/* set position to right bottom corner of text */
				right: 0,
				/* set width and height */
				width: "1em",
				height: "1em",
				marginTop: "0.2em",
				/* bg color = bg color under block */
				background: "white"
			}
		}
	},

	divider: {
		position: "absolute",
		width: rowHeight - iconSize - 3, //54,
		marginTop: rowHeight - iconSize, //57,
		marginLeft: iconSize - 32, //-4,
		transform: "rotate(90deg)",
	},

	viewAllUserActivity: {
		marginLeft: theme.spacing(-1.5)
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
export const placeHolder = (
	<div style={{display: "flex", padding: 24}}>
		<div style={{marginRight: 10}}>
			<RoundShape color={placeholderColor} style={{height: iconSize, width: iconSize}} />
		</div>
		<div style={{flex: 1}}>
			<TextRow style={{width: "60%", marginTop: 0}} color={placeholderColor} />
			<TextRow style={{width: "40%"}} color={placeholderColor} />
		</div>
	</div>
);

class ActivityAuditTrail extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			showAllUserActivity: false
		};
	}

	getText(obj) {
		let text,
			prefix,
			whoName = obj.whoName,
			withName = obj.withName,
			isXs = this.props.width.includes("xs"),
			useVerbose = this.props.useVerbose,
			activityText = "this";

		if (useVerbose) {
			activityText = `${obj.action === Constants.ACTIVITY.AUDIT_TRAIL.COMMENT.ID ? "on" : ""}
				${Constants.ACTIVITY.CATEGORIES.DISCUSSIONS.ID === obj.category ? "a discussion" : "an alert"}`;
		}

		if (isXs) {
			whoName = obj.whoName ? obj.whoName.split(" ") : [];
			withName = withName ? withName.split(" ") : [];

			whoName = whoName.length > 1 ? whoName[0].charAt(0) + ". " + whoName[1] : obj.whoName;
			if (withName.length) {
				withName = withName.length > 1 ? withName[0].charAt(0) + ". " + withName[1] : obj.withName;
			}
		}

		prefix = <span><b>{whoName}</b> <i>{obj.action}{obj.action === Constants.ACTIVITY.AUDIT_TRAIL.COMMENT.ID ? "ed" : "d"}</i></span>;

		switch (obj.action) {
			case Constants.ACTIVITY.AUDIT_TRAIL.SHARE.ID:
				text = <span>{prefix} {!isXs || useVerbose ? activityText : ""} with <b>{withName}</b></span>;
				break;

			default:
				text = <span>{prefix} {activityText}</span>;
		}

		return <Typography color="textSecondary">{text}</Typography>;
	}

	render() {
		let currDate,
			{showAllUserActivity} = this.state,
			{classes, fidoError, userActivity, maxActivitiesShown, className} = this.props,
			ready = userActivity != null,
			isSameDay = (momentDate1, momentDate2) => {
				if (momentDate1 == null || momentDate2 == null) {
					return false;
				}
				return momentDate1.isSame(momentDate2, "day");
			};

		return (
			<ReactPlaceholder customPlaceholder={placeHolder} ready={ready} showLoadingAnimation={!fidoError}>
				<div className={`${classes.content} ${className}`}>
					{ready &&  userActivity && userActivity.length ?
						userActivity.map((activity, idx) => {
							if (maxActivitiesShown != null && idx >= maxActivitiesShown && !showAllUserActivity) {
								if (idx > maxActivitiesShown) {
									return null;
								}

								return <PrimaryTextButton key={`key-${activity.date}-${idx}`}
									onClick={() => this.setState({showAllUserActivity: true})}
									className={classes.viewAllUserActivity}>View all user activity</PrimaryTextButton>;
							}

							let dateHeading,
								activityDate = new MomentCore.utc(activity.date).local(),
								nextActivity = userActivity[idx+1],
								nextActivityDate = nextActivity ? new MomentCore.utc(nextActivity.date).local() : null,
								needsDivider = idx+1 !== userActivity.length && isSameDay(activityDate, nextActivityDate);

							if (!isSameDay(activityDate, currDate) || currDate == null) {
								currDate = activityDate;
								dateHeading = <Typography color="textPrimary" className={classes.dateHeading}>
									<Moment format={"MMMM Do"}>{currDate}</Moment>
								</Typography>;
							}

							return <div key={`key-${activity.date}-${idx}`}>
								{dateHeading}
								<Grid container justify="flex-start" className={classes.row}>
									{(needsDivider && idx+1 !== maxActivitiesShown) || (showAllUserActivity && needsDivider) ?
										<Divider className={classes.divider} /> : null
									}
									<Grid item className={classes.iconCol}>
										<AuditTrailIcon type={activity.type} />
									</Grid>
									<Grid item className={classes.textCol}>
										{this.getText({
											category: activity.category,
											whoName: activity.user.name,
											action: activity.type,
											withName: activity.recipient ? activity.recipient.name || activity.recipient.phone || activity.recipient.email : null
										})}
										<Timestamp time={activity.date} />
									</Grid>
								</Grid>
							</div>;
						})
						:
						<Typography color="textSecondary" className={classes.noData}>
							No recent activity.
						</Typography>
					}
				</div>
			</ReactPlaceholder>
		);
	}
}

ActivityAuditTrail.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	className: PropTypes.string,
	userActivity: PropTypes.array,
	maxActivitiesShown: PropTypes.number,
	fidoError: PropTypes.bool,
	useVerbose: PropTypes.bool
};

export default withStyles(styles)(withWidth()(ActivityAuditTrail));
