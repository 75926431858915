import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Media from 'react-media';

import {
    withStyles,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    ButtonBase,
    withTheme,
} from '@material-ui/core';

const styles = theme => ({
	sortIcon: {
		fontSize: "1.3rem",
		marginLeft: theme.spacing(1)
	},

	label: {
		textTransform: "uppercase",
    	fontWeight: 500,
    	fontSize: theme.spacing(1) + 4
	}
});

class TableSortableHeader extends Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		let { classes, theme, order, orderBy, columnData, className, cellClassName, onRequestSort } = this.props;

		return (
			<TableHead>
				<TableRow className={`${className}`}>
					{columnData.map(column => {
						return (
							<Media key={column.id} query={{minWidth: column.minWidth ? column.minWidth : 0}}>
								{matches =>
									matches ? (
										<TableCell
											align={column.numeric ? "right" : "inherit"}
											padding={column.disablePadding ? 'none' : 'default'}
											sortDirection={orderBy === column.id ? order : false}
											className={cellClassName}>
											<ButtonBase className={classes.selectButton} tabIndex={-1} disableTouchRipple focusRipple>
												<TableSortLabel
													hideSortIcon
													active={orderBy === column.id}
													direction={order}
													className={classes.label}
													IconComponent={() => <FontAwesomeIcon
														title="sort"
														color={theme.palette.text.secondary}
														className={classes.sortIcon}
														icon={["far", column.numeric || column.amount ?
															(order === "asc" ? "sort-amount-up" : "sort-amount-down") :
															(column.alpha ? (order === "asc" ? "sort-alpha-up" : "sort-alpha-down") :
																(order === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down"))]} />
													}
													onClick={onRequestSort ? this.createSortHandler(column.id) : null}>
													{column.label}
												</TableSortLabel>
											</ButtonBase>
										</TableCell>
									) : (null)
								}
							</Media>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

TableSortableHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	columnData: PropTypes.array.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	className: PropTypes.string,
	cellClassName: PropTypes.string
};

export default withStyles(styles)(withTheme(TableSortableHeader));