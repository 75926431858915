import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Constants from "../utils/Constants";
import MoreInfo from "./MoreInfo";
import TwitterRedactionExplanation from "./TwitterRedactionExplanation";

import { withStyles, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
	redactedPaper: {
		padding: theme.spacing(1.5),
		backgroundColor: theme.palette.grey[100]
	}
});

class TwitterRedaction extends Component {
	render() {
		let {classes, twitterRedacted, history, className, isAlert} = this.props;
		return (
			<Paper elevation={0} className={`${classes.redactedPaper} ${className}`}>
				<Typography color="textSecondary">
					{Constants.ACTIVITY.TWITTER_REDACTION.getMessage(twitterRedacted)}&nbsp;
					<MoreInfo
						maxWidth="xs"
						textToUse="Learn More"
						title="Why is this information no longer available?"
						content={<TwitterRedactionExplanation history={history} isAlert={isAlert} />} />
				</Typography>
			</Paper>
		);
	}
}

TwitterRedaction.propTypes = {
	classes: PropTypes.object.isRequired,
	twitterRedacted: PropTypes.string,
	history: PropTypes.object.isRequired,
	className: PropTypes.string,
	isAlert: PropTypes.bool
};

export default withStyles(styles)(TwitterRedaction);
