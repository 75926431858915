/* eslint-disable */
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextField = require('@material-ui/core/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _TextMask = require('react-text-mask');

var _TextMask2 = _interopRequireDefault(_TextMask);

var _reactFormValidatorCore = require('react-form-validator-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _textMaskCustom(props) {
	const { inputRef, ...other } = props;

	return _react2.default.createElement(_TextMask2.default, _extends({}, other, {
		showMask: true,
		ref: ref => {
			inputRef(ref ? ref.inputElement : null);
		},
		guide: false,
		placeholderChar: '\u2000',
		mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
	}));
}

var PhoneValidator = function (_ValidatorComponent) {
	_inherits(PhoneValidator, _ValidatorComponent);

	function PhoneValidator() {
		_classCallCheck(this, PhoneValidator);

		return _possibleConstructorReturn(this, (PhoneValidator.__proto__ || Object.getPrototypeOf(PhoneValidator)).apply(this, arguments));
	}

	_createClass(PhoneValidator, [{
		key: 'render',
		value: function render() {
			// eslint-disable-next-line
			var _props = this.props,
				errorMessages = _props.errorMessages,
				validators = _props.validators,
				requiredError = _props.requiredError,
				helperText = _props.helperText,
				validatorListener = _props.validatorListener,
				rest = _objectWithoutProperties(_props, ['errorMessages', 'validators', 'requiredError', 'helperText', 'validatorListener']);

			var isValid = this.state.isValid;

			return _react2.default.createElement(_TextField2.default, _extends({}, rest, {
				type: 'tel',
				error: !isValid,
				helperText: !isValid && this.getErrorMessage() || helperText,
				InputProps: {
					inputComponent: _textMaskCustom,
				},
                inputRef: (r) => { this.input = r; },
			}));
		}
	}]);

	return PhoneValidator;
}(_reactFormValidatorCore.ValidatorComponent);

export default PhoneValidator;
/* eslint-enable */
