import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import MomentCore from 'moment';

import Highchart from "./Highchart";
import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import DetailedReport from "../containers/DetailedReport";
import ReportBarsItem from "./ReportBarsItem";

import { withStyles, Paper, Grid } from '@material-ui/core';

const styles = theme => ({
	root: {
		minHeight: 480,
		[theme.breakpoints.down('xs')]: {
			minHeight: 450
		}
	},

	barGrid: {
		[theme.breakpoints.up('md')]: {
			marginTop: theme.spacing(6),
		},
		marginBottom: theme.spacing(1)
	}
});

class ResponseBreakdownReport extends Component {
	constructor(props, context) {
		super(props, context);

		this.barColors = [
			'#FFC107',
			'#57BCFA',
			'#ED412D',
			'#00CDB8'
		];

		let {theme, previousDays} = this.props;
		this.state = {
			chartConfig: HighchartsConfig.merge({
				title: {
					align: "left",
					text: "Response Breakdown"
				},
				legend: {
					enabled: true
				},
				tooltip: {
					shared: true,
					style: {
						color: "#ffffff"
					},
					backgroundColor: "#202632",
					headerFormat: "",
					pointFormatter: function() {
						let numFormatter = new Intl.NumberFormat();
						let date = new MomentCore(this.x),
							header = "",
							content = `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${numFormatter.format(this.y)}</b><br/>`;

						if (this.series.name === "Good") {
							header = `<span style='font-weight: bold'>${date.format("MMM D")}</span><br/>`;
						}

						return header + content;
					}
				},
				accessibility: {
					description: `Number of good, sad, bad and tip submissions sent per day over the last ${previousDays} days.`
				},
				yAxis: {
					title: {
						text: undefined
					},
					min: 0,
					startOnTick: false,
					endOnTick: false,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					labels: {
						y: 0,
						style: {
							fontSize: "15px"
						}
					}
				},
				xAxis: {
					type: "datetime",
					tickWidth: 0,
					offset: 20,
					labels: {
						y: 35,
						rotation: -45,
						align: "center",
						style: {
							fontSize: "15px"
						}
					},
					style: {
						color: theme.palette.primary.darkGrey
					}
				},
				chart: {
					marginTop: theme.spacing(6)
				},
				plotOptions: {
					series: {
						marker: {
							enabled: false
						}
					},
					line: {
						softThreshold: false
					}
				},
				series: [{
					type: "spline",
					name: "Good",
					color: this.barColors[0]
				},{
					type: "spline",
					name: "Sad",
					color: this.barColors[1]
				},{
					type: "spline",
					name: "Bad",
					color: this.barColors[2]
				},{
					type: "spline",
					name: "Tip",
					color: this.barColors[3]
				}]
			}, {
				modifyTickInterval: true,
				previousDays: previousDays
			})
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.graphData !== this.props.graphData) {
			let {chartConfig} = this.state,
				{graphData} = this.props;

			if (graphData !== null) {
				ReportDataMasseuse.convertDatesToMillis(this.props.graphData);
				chartConfig.series[0].data = ReportDataMasseuse.massageData(graphData, "period", "good");
				chartConfig.series[1].data = ReportDataMasseuse.massageData(graphData, "period", "sad");
				chartConfig.series[2].data = ReportDataMasseuse.massageData(graphData, "period", "bad");
				chartConfig.series[3].data = ReportDataMasseuse.massageData(graphData, "period", "tip");
			} else {
				chartConfig.series[0].data = null;
				chartConfig.series[1].data = null;
				chartConfig.series[2].data = null;
				chartConfig.series[3].data = null;
			}

			this.setState({chartConfig: chartConfig});
		}
	}

	render() {
		let {chartConfig} = this.state,
			{classes, graphBreakdown, fidoError, history} = this.props;

		return (
			<Paper className={classes.root}>
				<Grid container justify="space-between" alignItems="stretch">
					<Grid item xs={12} md={8}>
						<DetailedReport
							fidoError={fidoError}
							ready={chartConfig.series[0].data && chartConfig.series[0].data.length > 0 ? true : false}>
							<Highchart chartConfig={chartConfig} />
						</DetailedReport>
					</Grid>
					<Grid item xs={12} md={4}>
						<DetailedReport
							smallGraph
							fidoError={fidoError}
							ready={graphBreakdown && graphBreakdown.length > 0 ? true : false}>
							<Grid container alignItems="flex-end" direction="column" className={classes.barGrid}>
								<Grid item>
									<ReportBarsItem
										history={history}
										data={graphBreakdown}
										barColors={this.barColors}
									/>
								</Grid>
							</Grid>
						</DetailedReport>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

ResponseBreakdownReport.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	graphData: PropTypes.array,
	previousDays: PropTypes.number,
	fidoError: PropTypes.bool,
	graphBreakdown: PropTypes.array,
	history: PropTypes.object.isRequired
};

export default withStyles(styles)(withTheme(ResponseBreakdownReport));
