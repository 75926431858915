import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fido from '../../utils/Fido';
import OverviewReportLayout from "../components/OverviewReportLayout";
import Constants from '../../utils/Constants';
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";
import ReportScreenShot from "../components/ReportScreenShot";
import SparkCards from "../../assets/screen-shots/gsuite-spark-cards.png";
import ScannedVsAlerts from "../../assets/screen-shots/gsuite-scanned-vs-alerts.png";
import TopSenders from "../../assets/screen-shots/gsuite-top-senders.png";
import TopReceivers from "../../assets/screen-shots/gsuite-top-receivers.png";
import MostRecetAlerts from "../../assets/screen-shots/gsuite-recent-alerts.png";
import EmailEngagement from "../../assets/screen-shots/gsuite-engagement.png";
import EmailSentiment from "../../assets/screen-shots/email-sentiment.png";

class StaticGsuiteOverviewReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {location, days, source, authenticatedUser} = this.props,
			accountParam = Fido.getSearchParam("account", location.search);

		this.state = {
			filter: {
				account: accountParam ? (accountParam.split(",")).map(item => Number(item)) : authenticatedUser.childAccounts.map(item => item.id),
				previousDays: Number(Fido.getSearchParam("previousDays", location.search)) || days,
				source: Fido.getSearchParam("source", location.search) || source
			}
		};
	}

	componentDidMount() {
		this.setupReport();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.source !== this.props.source) {
			this.setState({
				filter: {
					account: this.state.filter.account,
					previousDays: this.state.filter.previousDays,
					source: this.props.source,
				}
			}, () => {
				this.setupReport();
			});
		}
	}

	setupReport() {
		this.props.updateHeader({title: Constants.SOURCES.DISPLAY_NAMES[this.state.filter.source], primary: true});
	}

	render() {
		let {filter} = this.state,
			previousDays = filter.previousDays;

		return (
			<OverviewReportLayout
				location={this.props.location}
				previousDays={previousDays}
				filterCallback={() => {}}
				selectedAccounts={filter && filter.account}>
				<ReportScreenShot noPaper fullWidth key="sskey1" image={SparkCards} />
				<ReportScreenShot fullWidth key="sskey2" image={ScannedVsAlerts} />
				<ReportScreenShot fullWidth key="sskey7" image={EmailSentiment} />
				<ReportScreenShot key="sskey3" image={TopSenders} />
				<ReportScreenShot key="sskey4" image={TopReceivers} />
				<ReportScreenShot key="sskey5" image={MostRecetAlerts} />
				<ReportScreenShot key="sskey6" image={EmailEngagement} />
			</OverviewReportLayout>
		);
	}
}

StaticGsuiteOverviewReport.defaultProps = {
	days: 30
};

StaticGsuiteOverviewReport.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	days: PropTypes.number,
	source: PropTypes.string,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <StaticGsuiteOverviewReport {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "StaticGsuiteOverviewReport";

export default (React.forwardRef(dNameFunc));