import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withStyles, withWidth} from "@material-ui/core";

import FauxTable from "../../components/FauxTable";
import Timestamp from "../../components/Timestamp";

const styles = theme => ({
	timestamp: {
		"& p": {
			lineHeight: "normal",

			"& > time": {
				fontSize: "1rem",
				lineHeight: "normal"
			}
		}
	}
});

class AccountAuditTrailTable extends Component {
	render() {
		let {classes, width, events, fidoError, hasMoreItems, isLoading, loadNextPage, pageStart} = this.props,
			columns = [{
				id: "category",
				label: "Category"
			}, {
				id: "description",
				label: "Details"
			}, {
				id: "name",
				label: "User"
			}, {
				id: "date",
				label: "Date",
				dataClassName: classes.timestamp,
				formatter: data => <Timestamp time={data} reducedSize={false} format={`${width.includes("xs") ? "M/D/YY h:mma" : "MMM Do @ h:mma"}`} />
			}],
			columnSizing = [
				{xs: 3, sm: 3, md: 3, lg: 3, xl: 3},
				{xs: 4, sm: 5, md: 5, lg: 5, xl: 5},
				{xs: 2, sm: 2, md: 2, lg: 2, xl: 2},
				{xs: 3, sm: 2, md: 2, lg: 2, xl: 2}
			];

		return (
			<FauxTable
				fidoError={fidoError}
				data={events}
				columns={columns}
				columnSizing={columnSizing}
				hasMoreItems={hasMoreItems}
				isLoading={isLoading}
				loadNextPage={loadNextPage}
				pageStart={pageStart} />
		);
	}
}

AccountAuditTrailTable.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.any.isRequired,
	events: PropTypes.array,
	fidoError: PropTypes.bool,
	hasMoreItems: PropTypes.bool,
	isLoading: PropTypes.bool,
	loadNextPage: PropTypes.func,
	pageStart: PropTypes.number
};

export default withStyles(styles)(withWidth()(AccountAuditTrailTable));