import React, { Component } from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import Thumbnail from './Thumbnail.js';

import { withStyles, withTheme } from '@material-ui/core';

const maxHeight = 400;
const minHeight = 200;
const styles = theme => ({
	embedResponsive: {
		position: "relative",
		display: "block",
		height: "100%",
		padding: "0",
		overflow: "hidden"
	},

	ratio16by9: {
		//paddingBottom: "28.6%"
	},

	video: {
		position: "absolute",
		top: "0",
		bottom: "0",
		left: "0",
		width: "100%",
		height: "100%",
		border: "0",
		maxHeight: minHeight,

		[theme.breakpoints.up('sm')]: {
			maxHeight: maxHeight
		}
	}
});

class PostVideo extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleError = this.handleError.bind(this);
		this.state = {
			brokenVideo: false
		};
	}

	handleError(event) {
		this.setState({brokenVideo: true});
	}

	render() {
		let {classes, theme} = this.props;
		return (
			<div style={{height: "100%"}}>
				{!this.state.brokenVideo ? (
					<div className={`${classes.embedResponsive} ${classes.ratio16by9}`}>
						{!this.props.useIframeVideo ? (
							<video onError={this.handleError} className={`${classes.video}`} controls>
								<source src={this.props.video} />
							</video>
						) : (
							<iframe
								title="video"
								src={this.props.video}
								className={`${classes.video}`}
								allowFullScreen={true}
								webkitallowfullscreen="true"
								mozallowfullscreen="true"></iframe>
						)}
					</div>
				) : (
					<Media query={{minWidth: theme.breakpoints.values.sm}}>
						{matches => (
							<Thumbnail image="" center fitToHeight={matches ? maxHeight : minHeight} />
						)}
					</Media>
				)
				}
			</div>
		);
	}
}

PostVideo.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	useIframeVideo: PropTypes.bool,
	video: PropTypes.string
};

export default withStyles(styles)(withTheme(PostVideo));