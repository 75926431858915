import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import FilterSelect from "../components/FilterSelect";

import { withStyles } from '@material-ui/core';

const styles = theme => ({
	hidden: {
		display: "none"
	}
});

class AccountFilterField extends Component {
	render() {
		let {classes, onChange, className, name, defaultSelections, shouldReset, location} = this.props;

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					let hasMultipleAccounts = authenticatedUser.childAccounts.length;
					return (
						<FilterSelect
							singleColumn
							includeSelectAll
							name={name}
							menuTitle="Accounts"
							selectAllTitle="All accounts"
							handleUpdate={onChange}
							location={location}
							shouldReset={shouldReset}
							defaultSelections={defaultSelections}
							options={authenticatedUser.childAccounts}
							className={`${className} ${hasMultipleAccounts ? "" : classes.hidden}`} />
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

AccountFilterField.propTypes = {
	classes: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	className: PropTypes.string,
	name: PropTypes.string,
	defaultSelections: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	shouldReset: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountFilterField);
