import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RootRef, Snackbar as MUISnackbar } from '@material-ui/core';

class Snackbar extends Component {
	render() {
		let {snackbarRef, ...rest} = this.props;
		return (
			<RootRef rootRef={snackbarRef}>
				<MUISnackbar {...rest} />
			</RootRef>
		);
	}
}

Snackbar.propTypes = {
	snackbarRef: PropTypes.object
};

export default Snackbar;