import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Button, Typography } from '@material-ui/core';

const styles = theme => ({
	buttonContainer: {
		"& button": {
			width: "45px",
			[theme.breakpoints.down(365)]: {
				width: "38px"
			},
			minWidth: 0,
			minHeight: 0,
			padding: "5px",
			fontSize: "0.9rem",
			textTransform: "none",
			marginRight: "5px",
			marginBottom: "9px",
			boxShadow: "none",
			borderRadius: 0,

			"&:active": {
				boxShadow: "none"
			}
		}
	},

	label: {
		marginBottom: "5px",
		fontSize: "0.85rem",
		color: theme.palette.text.secondary
	}
});

class WeekToggleButtons extends Component {
	render() {
		let {classes, style, disabled, values, buttonToggled, direction} = this.props;
		return (
			<div className={this.props.className} style={style}>
				<Typography className={classes.label}>Days</Typography>
				<Grid container direction={direction || "row"} className={classes.buttonContainer} wrap="nowrap">
					<Grid item>
						<Button
							variant="contained"
							disabled={disabled}
							color={values[0] ? "secondary" : "default"}
							onClick={() => {buttonToggled(0);}}>Mon</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							disabled={disabled}
							color={values[1] ? "secondary" : "default"}
							onClick={() => {buttonToggled(1);}}>Tue</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							disabled={disabled}
							color={values[2] ? "secondary" : "default"}
							onClick={() => {buttonToggled(2);}}>Wed</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							disabled={disabled}
							color={values[3] ? "secondary" : "default"}
							onClick={() => {buttonToggled(3);}}>Thu</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							disabled={disabled}
							color={values[4] ? "secondary" : "default"}
							onClick={() => {buttonToggled(4);}}>Fri</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							disabled={disabled}
							color={values[5] ? "secondary" : "default"}
							onClick={() => {buttonToggled(5);}}>Sat</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							disabled={disabled}
							color={values[6] ? "secondary" : "default"}
							onClick={() => {buttonToggled(6);}}>Sun</Button>
					</Grid>
				</Grid>
			</div>
		);
	}
}

WeekToggleButtons.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.array,
	buttonToggled: PropTypes.func,
	direction: PropTypes.string
};

export default withStyles(styles)(WeekToggleButtons);
