import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
	root: {
		padding: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		backgroundColor: theme.palette.primary.tan
	}
});

class AccountInfoBar extends Component {
	render() {
		let {classes, accountName, accountId} = this.props;

		return (
			<Paper className={classes.root} elevation={0} square>
				<Typography>{accountName}{accountId ? ` (ID: ${accountId})` : null}</Typography>
			</Paper>
		);
	}
}

AccountInfoBar.propTypes = {
	classes: PropTypes.object.isRequired,
	accountName: PropTypes.string.isRequired,
	accountId: PropTypes.number
};

export default withStyles(styles)(AccountInfoBar);
