import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fido from '../utils/Fido';
import Constants from "../utils/Constants";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import AccountFilterField from "./AccountFilterField";
import FilterSelect from "./FilterSelect";

import {
	withStyles,
	withWidth,
	Grid
} from '@material-ui/core';

const styles = theme => ({
	hidden: {
		display: "none"
	},

	filterGrid: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),

		[theme.breakpoints.down('xs')]: {
			flexDirection: "column",
			alignItems: "center"
		}
	},

	filterSelectField: {
		marginLeft: theme.spacing(1)
	},

	accountField: {
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1)
		}
	},

	topicField: {
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1)
		}
	}
});

class Filterer extends Component {
	constructor(props, context) {
		super(props, context);

		let defaultFilter = {
			selectedSources: [],
			selectedAccounts: [],
			selectedTopics: []
		};

		this.state = {
			sources: null,
			accounts: null,
			topics: null,
			filter: defaultFilter,
			defaultFilter: Object.assign({}, defaultFilter)
		};

		this.executeFilter = this.executeFilter.bind(this);
		this.shouldReset = this.shouldReset.bind(this);
	}

	componentDidMount() {
		this.setupFilters();
	}

	componentDidUpdate(prevProps, prevState) {
		let {topics} = this.props;
		if (prevProps.topics !== topics) {
			this.setState({topics: topics}, this.setupFilters);
		}
	}

	shouldReset(prevProps) {
		let {location} = this.props,
			search = location.search,
			searchObj = Fido.getSearchParamsObject(search);

		if (prevProps && prevProps.location) {
			let prevSearchObj = Fido.getSearchParamsObject(prevProps.location.search);
			if (search === "" && prevProps.location.search !== "" ||
				!searchObj.source && !searchObj.topic && !searchObj.account &&
					(prevSearchObj.source || prevSearchObj.topic || prevSearchObj.account)) {
				this.setupFilters();
				return true;
			}
		}

		return false;
	}

	setupFilters() {
		let {topics} = this.state,
			filters = this.getFiltersFromUrl(),
			authenticatedUser = this.props.authenticatedUser,
			accounts = authenticatedUser.childAccounts,
			sources = Constants.SOURCES.getAuthorized(authenticatedUser),
			filter = {
				selectedSources: filters.source ? filters.source.split(",") : sources.map(item => item.id),
				selectedAccounts: filters.account ? filters.account.split(",").map(item => Number(item)) : accounts.map(item => item.id),
				selectedTopics: filters.topic ? filters.topic.split(",").map(item => Number(item)) :
					topics && topics.length ? topics.map(item => item.id) : []
			};

		this.setState({
			sources: sources,
			accounts: accounts,
			topics: topics,
			filter: filter,
			defaultFilter: Object.assign({}, filter)
		});
	}

	getFiltersFromUrl() {
		let {search} = this.props.location;
		return {
			source: Fido.getSearchParam("source", search),
			account: Fido.getSearchParam("account", search),
			topic: Fido.getSearchParam("topic", search)
		};
	}

	filter(filterKey, stateKey, value) {
		let obj = {};
		obj[filterKey] = value.length ? value : this.state[stateKey].map(item => item.id);

		this.setState({
			filter: Object.assign({}, this.state.filter, obj)
		}, this.executeFilter);
	}

	buildQueryString(selected, set) {
		let outputString = null;

		if (selected && selected.length) {
			if (Array.isArray(selected) && selected.length !== set.length) {
				outputString = selected.join(",");
			} else if (!Array.isArray(selected)) {
				outputString = selected;
			}
		}
		return outputString;
	}

	executeFilter() {
		let query = {},
			{executeFilterHandler} = this.props,
			{filter, sources, accounts, topics} = this.state,
			sourceString = this.buildQueryString(filter.selectedSources, sources),
			accountString = this.buildQueryString(filter.selectedAccounts, accounts),
			topicString = this.buildQueryString(filter.selectedTopics, topics);

		if (sourceString) {
			query.source = sourceString;
		}

		if (accountString) {
			query.account = accountString;
		}

		if (topicString) {
			query.topic = topicString;
		}

		executeFilterHandler(query);
	}

	render() {
		let {classes, width, location} = this.props,
			{sources, accounts, topics, defaultFilter} = this.state,
			hasMultipleSources = sources && sources.length > 1 ? true : false,
			hasMultipleAccounts = accounts && accounts.length > 1 ? true : false;

		return (
			<Grid
				container
				className={classes.filterGrid}
				justify={width.includes("xs") ? "center" : "flex-end"}>
				{hasMultipleSources ?
					<Grid item>
						<FilterSelect
							options={sources}
							menuTitle="Sources"
							includeSelectAll
							selectAllTitle="All sources"
							location={location}
							shouldReset={this.shouldReset}
							defaultSelections={defaultFilter && defaultFilter.selectedSources}
							handleUpdate={(e) => this.filter("selectedSources", "sources", e.target.value)} />
					</Grid> : null}
				<Grid item>
					<FilterSelect
						options={topics || []}
						menuTitle="Topics"
						includeSelectAll
						selectAllTitle="All topics"
						location={location}
						shouldReset={this.shouldReset}
						defaultSelections={defaultFilter && defaultFilter.selectedTopics}
						handleUpdate={(e) => this.filter("selectedTopics", "topics", e.target.value)} />
				</Grid>
				{hasMultipleAccounts ?
					<Grid item>
						<AccountFilterField
							location={location}
							shouldReset={this.shouldReset}
							defaultSelections={defaultFilter && defaultFilter.selectedAccounts}
							onChange={(e) => this.filter("selectedAccounts", "accounts", e.target.value)} />
					</Grid> : null}
			</Grid>
		);
	}
}

Filterer.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	executeFilterHandler: PropTypes.func.isRequired,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	topics: PropTypes.array,
	defaultSearchParams: PropTypes.object
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <Filterer {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "Filterer";

export default withStyles(styles)(withWidth()(React.forwardRef(dNameFunc)));
