import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import SingleActivity from '../components/SingleActivity';
import Fido from '../utils/Fido';

class ActivityRewrite extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			activity: null
		};

		this.fido = new Fido();
	}

	componentDidMount() {
		this.props.updateHeader({title: 'details'});

		let path = this.props.location.pathname.replace('activities/rewrite', 'v');
		this.fido.fetch(path)
			.then((data) => {
				if (data) {
					if (data.user.hasSignals && data.user.useSignals) {
						this.props.redirectSignals(this.props.location);
					} else {
						this.setState(data);
					}
				}
			})
			// TODO: probably only want to show some errors as snackbars
			.catch(error => this.props.showSnackbar(error.message));
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.props.resetHeader();
	}

	render() {
		if(this.state.activity) {
			//let activityId = data.activity && data.activity.id;
			return (
				<Redirect to={{
					pathname: `/activities/${this.state.activity.id}`,
					state: { response: this.state }
				}}/>
			);
		} else {
			// empty activity skeleton until data loads
			return <SingleActivity />;
		}
	}
}

ActivityRewrite.propTypes = {
	location: PropTypes.object,
	resetHeader: PropTypes.func,
	updateHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	redirectSignals: PropTypes.func
};

export default ActivityRewrite;
