import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Fido from '../../utils/Fido';
import AuthorizedUser from "../../utils/AuthorizedUser";
import Highchart from "./Highchart";
import HighchartsConfig from '../../utils/HighchartsConfig';
import NumberFormatter from '../../utils/NumberFormatter';
import DetailedReport from "../containers/DetailedReport";

import { withStyles, Typography, Grid } from '@material-ui/core';

const styles = theme => ({
	chevron: {
		fontSize: "0.9rem",
		marginRight: theme.spacing(.5)
	},

	green: {
		color: theme.palette.primary.mediumGreen
	},

	orange: {
		color: theme.palette.secondary.orange
	},

	grey: {
		color: AuthorizedUser.wantsMoreContrast() ?
			theme.palette.accessibilityColors.text.secondary : theme.palette.grey[500]
	},

	columnChart: {
		"& .highcharts-point": {
			rx: 8,
			ry: 8,
			transform: "translate(0px, -1px)"
		}
	}
});

class NavigationReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {theme, classes} = props;
		this.state = {
			alerts: [],
			days: null,
			fidoError: false,
			chartConfig: HighchartsConfig.merge({
				chart: {
					height: 160,
					className: classes.columnChart
				},
				title: {
					text: ""
				},
				accessibility: {
					pointValueSuffix: " alerts",
					description: "The number of alerts that were received per day over the last seven days."
				},
				yAxis: {
					visible: false,
					minRange : 0.1
				},
				xAxis: {
					type: "category",
					tickWidth: 0,
					labels: {
						y: 20,
						align: "center",
						style: {
							color: AuthorizedUser.wantsMoreContrast() ?
								theme.palette.accessibilityColors.text.secondary : theme.palette.primary.darkGrey,
							fontSize: "8px"
						}
					},
					lineColor: theme.palette.primary.tan
				},
				plotOptions: {
					column: {
						borderColor: theme.palette.primary.mediumGreen
					}
				},
				tooltip: {
					style: {
						color: "#ffffff"
					},
					backgroundColor: "#202632",
					headerFormat: "",
					pointFormatter: function() {
						let date = this.name,
							formatter = new Intl.NumberFormat(),
							header = `<span style="font-weight: bold">${date}</span><br/>`,
							content = `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${formatter.format(this.y)}</b><br/>`;
						return header + content;
					}
				},
				series: [{
					name: "Alerts",
					type: "column",
					color: theme.palette.primary.mediumGreen
				}]
			})
		};

		this.fido = new Fido();
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData(opts) {
		this.fido.fetch("/reports/sidebar-stats")
			.then((data) => {
				if (data) {
					let chartConfig = this.state.chartConfig;
					chartConfig.xAxis.categories = data.days;
					chartConfig.series[0].data = data.alerts;
					data.chartConfig = chartConfig;
					this.setState(data);
				}
			})
			// TODO: probably only want to show some errors as snackbars
			.catch(error => {
				this.setState({fidoError: true});
				this.props.showSnackbar(error.message);
			});
	}

	componentWillUnmount() {
		this.fido.dropIt();
	}

	render() {
		let {theme, classes} = this.props,
			{alerts, alertsReceived, alertsPercentageChange, chartConfig} = this.state,
			positiveChange = alertsPercentageChange > 0 ? false : alertsPercentageChange < 0 ? true : null,
			changeColor = positiveChange ? classes.green : positiveChange === false ? classes.orange : classes.grey;

		return (
			<DetailedReport ready={alerts.length > 0} fidoError={this.state.fidoError} ignoreSpacing smallGraph>
				<Media query={{minWidth: theme.breakpoints.values.sm}}>
					{matches =>
						<Highchart chartConfig={chartConfig} />
					}
				</Media>
				<Grid container justify="space-between">
					<Grid item style={{paddingLeft: 8}}>
						<FontAwesomeIcon
							className={`${changeColor} ${classes.chevron}`}
							title={`${positiveChange ? "increase in alerts" : positiveChange === false ? "decrease in alerts" : "no change"}`}
							icon={["fas", `${positiveChange ? "chevron-circle-down" : positiveChange === false ? "chevron-circle-up" : "minus-circle"}`]} />
						<Typography display="inline" variant="caption">{NumberFormatter.largeNumberFormat(Math.abs(alertsPercentageChange))}%</Typography>
					</Grid>
					<Grid item style={{paddingRight: 8}}>
						<Typography display="inline" variant="caption">{NumberFormatter.largeNumberFormat(alertsReceived)} alerts received</Typography>
					</Grid>
				</Grid>
			</DetailedReport>
		);
	}
}

NavigationReport.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func,
	previousDays: PropTypes.number
};

export default withStyles(styles)(withTheme(NavigationReport));
