import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Constants from "../utils/Constants";
import DialogSlideTransition from "./DialogSlideTransition";

import {
    withStyles,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    DialogContent,
    Grid,
    withTheme,
} from '@material-ui/core';

const size = {width: 170, height: 170, safariWidth: 230};
const avatarSize = {width: Constants.USER.AVATAR.SIZE.WIDTH, height: Constants.USER.AVATAR.SIZE.HEIGHT};
const styles = theme => ({
	content: {
		paddingTop: 75,
		minHeight: 250,
		display: "flex",
		flexDirection: "column"
	},

	grid: {
		flex: 1
	},

	videoContainer: {
		display: "flex",
		overflow: "hidden",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		minHeight: `${size.height}px`,
		maxWidth: Constants.ENV.IS_SAFARI ? size.width : ""
	},

	button: {
		color: theme.palette.common.white
	},

	video: {
		width: Constants.ENV.IS_SAFARI ? size.safariWidth : "",
		maxWidth: Constants.ENV.IS_SAFARI ? "" : "100%",
		minHeight: `${size.height}px`
	},

	canvas: {
		height: avatarSize.height
	},

	shutterButton: {
		fontSize: 40,
		border: `3px solid ${theme.palette.primary.main}`
	},

	mask: {
		top: 0,
		overflow: "hidden",
		position: "absolute",
		width: `${size.width}px`,
		height: `${size.height}px`,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",

		"&:after": {
			top: 13,
			left: 13,
			width: `${size.width-26}px`,
			height: `${size.height-26}px`,
			borderRadius: "100%",
			position: "absolute",
			content: "no-close-quote",
			boxShadow: "0 0 0 2000px rgb(0,0,0,0.6)"
		}
	}
});

class AvatarCamera extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			canPlay: false,
			photoTaken: false
		};

		this.avatarVideoStream = null;
		this.avatarCanvas = React.createRef();
		this.avatarVideo = React.createRef();

		this.takePhoto = this.takePhoto.bind(this);
		this.onEntered = this.onEntered.bind(this);
		this.canPlayHandler = this.canPlayHandler.bind(this);
		this.done = this.done.bind(this);
	}

	onEntered() {
		this.setState({
			canPlay: false,
			photoTaken: false
		});

		let safariConstraints = true,
			constraints = {
				width: {exact: size.width},
				height: {exact: size.height}
			};

		navigator.mediaDevices.getUserMedia({
			audio: false,
			video: Constants.ENV.IS_SAFARI ? safariConstraints : constraints
		}).then((stream) => {
			this.avatarVideoStream = stream;
			this.avatarVideo.current.srcObject = this.avatarVideoStream;
		}).catch(error => {
			console.log(error);
		});
	}

	canPlayHandler() {
		this.setState({canPlay: true});
	}

	takePhoto() {
		this.setState({photoTaken: true});

		let canvas = this.avatarCanvas.current,
			photo = this.avatarVideo.current,
			img = document.createElement("img");

		canvas.width = avatarSize.width;
		canvas.height = avatarSize.height;

		if (Constants.ENV.IS_SAFARI) {
			let maxHeight = avatarSize.height,
				ratio = photo.width / photo.height,
				resultingWidth = ratio * maxHeight;
			canvas.getContext('2d').drawImage(photo, -(size.safariWidth-size.width)/2, 0, resultingWidth, avatarSize.height);
		} else {
			canvas.getContext('2d').drawImage(photo, 0, 0, size.width, size.height, 0, 0, avatarSize.width, avatarSize.height);
		}

		img.src = canvas.toDataURL('image/png');

		this.stopVideo();
		this.done(img);
	}

	stopVideo() {
		this.avatarVideoStream.getTracks()[0].stop();
	}

	done(img) {
		this.stopVideo();
		setTimeout(() => {this.props.doneHandler(img);}, 300);
	}

	render() {
		let {canPlay, photoTaken} = this.state,
			{classes, open, theme} = this.props;

		return (
			<Media query={{minWidth: theme.breakpoints.values.sm}}>
				{matches =>
					<Dialog
						open={open}
						onEntered={this.onEntered}
						fullScreen={!matches}
						maxWidth="xs"
						fullWidth={true}
						onClose={this.done}
						TransitionComponent={DialogSlideTransition}>
						{matches ? null :
							<AppBar className={classes.appBar}>
								<Toolbar>
									<IconButton
										onClick={this.done}
										className={classes.button}
										aria-label="close dialog">
										<FontAwesomeIcon icon={["far", "times"]} />
									</IconButton>
									<Typography variant="h6" color="inherit">Take Photo</Typography>
								</Toolbar>
							</AppBar>
						}
						<DialogContent className={classes.content}>
							<Grid container alignItems="center" justify="space-between" direction="column" className={classes.grid}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.videoContainer}>
									{open ?
										<video
											muted
											autoPlay
											playsInline
											height={size.height}
											width={Constants.ENV.IS_SAFARI ? size.safariWidth : size.width}
											ref={this.avatarVideo}
											className={classes.video}
											onCanPlay={this.canPlayHandler}
											style={{display: photoTaken ? "none" : "block"}} />
										: null
									}
									<canvas
										width={size.width}
										height={size.height}
										ref={this.avatarCanvas}
										className={classes.canvas}
										style={{display: photoTaken ? "block" : "none"}} />
									<div className={classes.mask}>
										{!canPlay ? <Typography>Loading...</Typography> : null}
									</div>
								</Grid>
								<Grid item>
									<Typography variant="caption">Make sure the browser has camera access</Typography>
								</Grid>
								<Grid item style={matches ? {paddingTop: 25} : {}}>
									<IconButton
										onClick={this.takePhoto}
										color="primary"
										className={classes.shutterButton}
										aria-label="take photo">
										<FontAwesomeIcon icon={["fas", "circle"]} />
									</IconButton>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				}
			</Media>
		);
	}
}

AvatarCamera.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	doneHandler: PropTypes.func
};

export default withStyles(styles)(withTheme(AvatarCamera));