import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import { withStyles } from '@material-ui/core';

const styles = theme => ({
	progressBar: {
		borderRadius: theme.spacing(8),
		overflow: "hidden",
		width: "100%"
	},

	bar: {
		display: "block",
		background: "rgba(0,0,0,0.075)"
	},

	progress: {
		color: theme.palette.common.white,
		display: "block",
		padding: theme.spacing(.5),
	}
});

class FillBar extends Component {
	render() {
		let {classes, backgroundColor, width} = this.props;

		return (
			<div className={classes.progressBar}>
				<span className={classes.bar}>
					<span className={classes.progress} style={{ backgroundColor, width }}></span>
				</span>
			</div>
		);
	}
}

FillBar.propTypes = {
	classes: PropTypes.object.isRequired,
	backgroundColor: PropTypes.string,
	width: PropTypes.string
};

export default withStyles(styles)(withTheme(FillBar));