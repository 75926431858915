import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withStyles, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
	container: {
		paddingLeft: theme.spacing(1)
	},

	icon: {
		width: "0.65rem !important",
		paddingRight: theme.spacing(1),
		marginBottom: theme.spacing(-.13)
	},

	text: {
		display: "inline"
	},

	met: {
		color: theme.palette.primary.mediumGreen
	},

	unmet: {
		color: theme.palette.text.secondary
	}
});

class PasswordRequirements extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			minLength: {
				icon: "far",
				cls: "unmet"
			},
			oneLetter:  {
				icon: "far",
				cls: "unmet"
			},
			oneNumber:  {
				icon: "far",
				cls: "unmet"
			},
			oneSpecial:  {
				icon: "far",
				cls: "unmet"
			}
		};
	}

	componentDidUpdate(prevProps, prevState) {
		let {password} = this.props;

		if (password !== prevProps.password) {
			let	met = {icon: "fas", cls: "met"},
				unmet = {icon: "far", cls: "unmet"},
				minLength, oneLetter, oneNumber, oneSpecial;

			minLength = password.length >= 8 ? met : unmet;
			oneLetter = password.match(/[a-z]+/i) != null ? met : unmet;
			oneNumber = password.match(/[0-9]+/) != null ? met : unmet;
			oneSpecial = password.match(/\W+/) != null ? met : unmet;

			this.setState({minLength, oneLetter, oneNumber, oneSpecial});
			this.props.isValid(minLength.cls === "met" && oneLetter.cls === "met" && oneNumber.cls === "met" && oneSpecial.cls === "met");
		}
	}

	render() {
		let {classes} = this.props;

		return (
			<Grid container direction="column" className={classes.container}>
				<Grid item>
					<FontAwesomeIcon
						icon={[this.state.minLength.icon, "circle"]}
						title={`8 character minimum requirement ${this.state.minLength.cls}`}
						className={`${classes.icon} ${classes[this.state.minLength.cls]}`} />
					<Typography variant="caption" className={classes.text}>8 Character Minimum</Typography>
				</Grid>
				<Grid item>
					<FontAwesomeIcon
						icon={[this.state.oneLetter.icon, "circle"]}
						title={`1 letter requirement ${this.state.oneLetter.cls}`}
						className={`${classes.icon} ${classes[this.state.oneLetter.cls]}`} />
					<Typography variant="caption" className={classes.text}>1 Letter</Typography>
				</Grid>
				<Grid item>
					<FontAwesomeIcon
						icon={[this.state.oneNumber.icon, "circle"]}
						title={`1 number requirement ${this.state.oneNumber.cls}`}
						className={`${classes.icon} ${classes[this.state.oneNumber.cls]}`} />
					<Typography variant="caption" className={classes.text}>1 Number</Typography>
				</Grid>
				<Grid item>
					<FontAwesomeIcon
						icon={[this.state.oneSpecial.icon, "circle"]}
						title={`1 special character requirement ${this.state.oneSpecial.cls}`}
						className={`${classes.icon} ${classes[this.state.oneSpecial.cls]}`} />
					<Typography variant="caption" className={classes.text}>1 Special Character (like @!%#$^)</Typography>
				</Grid>
			</Grid>
		);
	}
}

PasswordRequirements.propTypes = {
	classes: PropTypes.object.isRequired,
	password: PropTypes.string.isRequired,
	isValid: PropTypes.func.isRequired
};

export default withStyles(styles)(PasswordRequirements);
