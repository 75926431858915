import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';

const styles = theme => ({
	offsetLeft: {
		marginLeft: theme.spacing(-1)
	},

	offsetRight: {
		marginRight: theme.spacing(-1)
	}
});

class PrimaryTextButton extends Component {
	render() {
		let {classes, className, children, href, target, onClick, disabled, offset} = this.props,
			offsetLeft = (offset === 'left') ? true : false,
			offsetRight = (offset === 'right') ? true : false;

		return (
			<Button
				onClick={onClick}
				href={href}
				target={target}
				size="small"
				color="primary"
				disabled={disabled}
				className={`${className} ${offsetLeft ? classes.offsetLeft : null} ${offsetRight ? classes.offsetRight : null}`}>
				{children}
			</Button>
		);
	}
}

PrimaryTextButton.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
	href: PropTypes.string,
	target: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	offset: PropTypes.string
};

export default withStyles(styles)(PrimaryTextButton);
