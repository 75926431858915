import React, { Component } from 'react';
import { Typography, Link } from '@material-ui/core';

class EmailSentimentBehindNumbers extends Component {
	render() {
		return (
			<Typography>
				Emails authored by students are consolidated in order to analyze in an aggregated form. These emails are subjected to rigorous pre-processing to remove as much noise as possible.<br/><br/>Using proprietary and licensed lexicons, these emails are aggregated by week and scored for sentiment in two different ways: Happiness, and Emotional Distress. All emails containing Emotional Distress signals are available for review if desired.<br/><br/>

				<b>Happiness Score</b> is calculated using Language Assessment by Mechanical Turk 1.0 (labMT 1.0), a robust lexicon developed by P. Dodds, K. Harris, I. Kloumann, C. Bliss,and C. Danforth. See <Link rel="noopener noreferrer" target="_blank" href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0026752">Temporal Patterns of Happiness and Information in a Global Social Network: Hedonometrics and Twitter.</Link> labMT 1.0 is licensed under <Link rel="noopener noreferrer" target="_blank" href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0.</Link><br/><br/>
				<b>Emotional Distress Score</b> is calculated using a unique lexicon developed using excerpts from Social Sentinel’s proprietary Sentinel Search Library.
			</Typography>
		);
	}
}

export default EmailSentimentBehindNumbers;