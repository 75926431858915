import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { withStyles, Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';

const styles = theme => ({
	topHelperText: {
		marginBottom: theme.spacing(1)
	},

	bottomHelperText: {
		fontSize: "0.985rem",
		marginBottom: theme.spacing(3)
	},

	checkboxError: {
		color: '#f44336'
	},

	checkboxErrorMsg: {
		color: '#f44336',
		display: 'block',
		marginTop: 0
	}
});

class LabeledCheckbox extends ValidatorComponent {
	render() {
		let {isValid} = this.state,
			{classes, disabled, onChange, checked, checkboxColor, topHelperText, bottomHelperText,
				useIdForValue, errorMessages, validators, validatorListener, requiredError, value, labelClasses, ...rest} = this.props;

		// in case it comes in as a string
		if (typeof checked === "string") {
			checked = checked === "true";
		}

		return (
			<div>
				{topHelperText ? (
					<FormHelperText
						className={classes.topHelperText}
						disabled={disabled}>{topHelperText}</FormHelperText>
				) : null}
				<FormControlLabel
					{...rest}
					disabled={disabled}
					checked={!!checked}
					classes={labelClasses}
					control={
						<Checkbox
							ref={(r) => { this.input = r; }}
							color={checkboxColor || 'primary'}
							className={isValid ? "" : classes.checkboxError}
							onChange={(e) => {
								if (useIdForValue) {
									e.target.value = e.target.id;
									e.target.ignoreCheckbox = useIdForValue;
								} else {
									e.target.value = e.target.checked;
								}

								onChange(e);
							}}
							inputProps={{
								id: rest.id,
								'aria-label': rest.label || '',
								'aria-describedby': `${rest.id}-helper-text`
							}} />
					} />
				{!isValid ? (
					<FormHelperText className={classes.checkboxErrorMsg}>
						{this.getErrorMessage()}
					</FormHelperText>
				) : null}
				{bottomHelperText ? (
					<FormHelperText
						id={`${rest.id}-helper-text`}
						className={classes.bottomHelperText}
						disabled={disabled}>{bottomHelperText}</FormHelperText>
				) : null}
			</div>
		);
	}
}

LabeledCheckbox.propTypes = {
	classes: PropTypes.object.isRequired,
	checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
	checkboxColor: PropTypes.string,
	helperText: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	useIdForValue: PropTypes.bool,
	labelClasses: PropTypes.object
};

export default withStyles(styles)(LabeledCheckbox);
