import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MomentCore from 'moment';

import Fido from '../../utils/Fido';
import HighchartsConfig from '../../utils/HighchartsConfig';
import DetailedReport from "../containers/DetailedReport";

import {
    withStyles,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Typography,
    Button,
} from '@material-ui/core';

//import {imagesProcessedData, topicBubbleData} from "../data/reports";

const styles = theme => ({
	checkboxRoot: {
		color: theme.palette.grey.A200,
		"&$checked": {
			color: theme.palette.primary.main,
		}
	},
	checked: {},

	seriesIcon: {
		width: "0.65rem !important",
		paddingRight: theme.spacing(1)
	},

	nameCell: {
		paddingLeft: 0,
		paddingRight: theme.spacing(1),
		whiteSpace: "nowrap"
	},

	topicName: {
		display: "inline-block",
		whiteSpace: "normal"
	},

	tableGridItem: {
		overflow: "hidden"
	},

	postsCell: {
		textAlign: "right",
		paddingRight: `${theme.spacing(4)}px !important`
	},

	chartGridItem: {
		"& > div": {
			height: "100%",

			"& > div": {
				height: "100%",

				"& > svg": {
					height: "100%"
				}
			}
		}
	},

	padRight: {
		paddingRight: theme.spacing(2)
	},

	padBottom: {
		paddingBottom: theme.spacing(3)
	},

	button: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginBottom: theme.spacing(1)
	}
});

// need to initialize module
HC_more(Highcharts);

class TrendingTopicsReport extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			topics: [],
			selected: [],
			fidoError: false
		};

		this.fido = new Fido();
		this.checkboxClicked = this.checkboxClicked.bind(this);
		this.selectAllClicked = this.selectAllClicked.bind(this);
	}

	componentDidMount() {
		let headerOptions = {
			title: 'Trending Topics'
		};
		this.props.updateHeader(headerOptions);
		let query = Fido.getSearchParamsObject(this.props.location.search);
		// unset any excluded parameters
		this.fetchData({query});
	}

	fetchData(opts) {
		this.fido.fetch(this.props.location.pathname, opts)
			.then((data) => {
				if (data) {
					// TEMP
					//data = topicBubbleData;
					let topics = this.processData(data, true),
						numOfSeries = Math.min(this.props.defaultNumberOfSeries, data.length),
						selected = [...Array(numOfSeries)].map((value, idx) => {
							return topics[idx];
						});

					this.setState({
						topics: topics,
						selected: selected
					});
				}
			})
			// TODO: probably only want to show some errors as snackbars
			.catch(error => {
				this.setState({fidoError: true});
				this.props.showSnackbar(error.message);
			});
	}

	processData(topics) {
		let newTopic,
			series = [],
			colorIndex = 0,
			jitterMin = 20,
			jitterMax = 80,
			bubbleColors = this.props.bubbleColors;

		topics.forEach(topic => {
			if (colorIndex > bubbleColors.length-1) {
				colorIndex = 0;
			}

			newTopic = {
				id: topic.id,
				data: [],
				name: topic.name.replace(/\|/ig, "/"),
				color: bubbleColors[colorIndex++],
				totalPosts: topic.totalPosts
			};

			topic.data.forEach(point => {
				newTopic.data.push({
					x: (new MomentCore(point.period)).valueOf(),
					y: Math.floor(Math.random() * (jitterMax - jitterMin + 1)) + jitterMin,
					z: point.posts
				});
			});

			series.push(newTopic);
		});

		return series;
	}

	checkboxClicked(topic, checked) {
		let selected = this.state.selected;

		if (checked) {
			selected.push(topic);
		} else {
			selected = selected.filter(t => t !== topic);
		}

		this.setState({selected: selected});
	}

	selectAllClicked(e, checked) {
		if (checked) {
			let selected = [];

			this.state.topics.forEach(topic => {
				selected.push(topic);
			});

			this.setState({selected: selected});
		} else {
			this.setState({selected: []});
		}
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.props.resetHeader();
	}

	render() {
		let {topics, selected} = this.state,
			{theme, classes, history} = this.props,

			chart = <HighchartsReact
				highcharts={Highcharts}
				options={HighchartsConfig.merge({
					chart: {
						type: "bubble",
						marginTop: 30,
						marginRight: 30,
						marginBottom: 20
					},
					title: {
						text: undefined
					},
					legend: {enabled: false},
					yAxis: {
						title: {
							text: ""
						},
						lineWidth: 1,
						//allowDecimals: false,
						labels: {enabled: false},
						gridLineColor: "transparent",
						min: 0,
						max: 100
					},
					xAxis: {
						type: "datetime"
					},
					plotOptions: {
						bubble: {
							minSize: 20,
							maxSize: "30%",
							marker: {
								lineWidth: 0
							}
						},
						series: {
							events: {
								legendItemClick: () => {
									return false;
								}
							}
						}
					},
					tooltip: {
						followTouchMove: false,
						pointFormatter: function() {
							let numFormatter = new Intl.NumberFormat();
							let date = new MomentCore(this.x),
								header = `<span style="font-weight: bold">${date.format("MMM D")}</span><br/>`,
								content = `<span>${numFormatter.format(this.z)} posts</span><br/><span style="color:${this.color}">\u25CF</span> ${this.series.name}<br/>`;
							return header + content;
						}
					},
					series: selected
				})}
			/>,

			table = <Table>
				<TableHead>
					<TableRow>
						<TableCell padding="checkbox">
							<Checkbox
								indeterminate={selected.length && selected.length !== topics.length ? true : false}
								checked={selected.length === topics.length}
								onChange={(e, checked) => this.selectAllClicked(e, checked)}
								classes={{
									root: classes.checkboxRoot,
									checked: classes.checked
								}} />
						</TableCell>
						<TableCell>Topic</TableCell>
						<TableCell className={classes.postsCell}>Posts</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{topics && topics.length ?
						topics.map(topic => {
							let activeSeries = selected.includes(topic),
								iconType = activeSeries ? "fas" : "far";

							return <TableRow key={`topic-key-${topic.id}`} selected={activeSeries}>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={activeSeries}
										onChange={(e, checked) => this.checkboxClicked(topic, checked)} />
								</TableCell>
								<TableCell className={classes.nameCell}>
									<Grid container justify="flex-start" alignItems="center" wrap="nowrap">
										<Grid item>
											<FontAwesomeIcon
												title="data color"
												icon={[iconType, "circle"]}
												color={activeSeries ? topic.color : theme.palette.text.secondary}
												className={`${classes.seriesIcon}`} />
										</Grid>
										<Grid item>
											<Typography className={classes.topicName}>{topic.name}</Typography>
										</Grid>
									</Grid>
								</TableCell>
								<TableCell className={classes.postsCell}>{topic.totalPosts}</TableCell>
							</TableRow>;
						})
						: null
					}
				</TableBody>
			</Table>;

		return (
			<DetailedReport
				ready={topics.length > 0}
				fidoError={this.state.fidoError}
				sizing={{xs: 12, sm: 11, md: 11, lg: 11, xl: 10}}>
				<Grid container direction="column">
					<Grid item
						className={`${classes.chartGridItem} ${classes.padBottom}`}>
						{chart}
					</Grid>
					<Grid item
						className={classes.tableGridItem}>
						{table}
					</Grid>
				</Grid>
				<Button
					size="small"
					color="primary"
					className={classes.button}
					onClick={()=>history.push("/activities?category=discussions")}>View Topic Activity</Button>
			</DetailedReport>
		);
	}
}

TrendingTopicsReport.defaultProps = {
	defaultNumberOfSeries: 4,
	bubbleColors: [
		"#69B7FF",
		"#FF8000",
		"#1D9253",
		"#272064",
		"#FFCB00",
		"#F0177B",
		"#B8D54A",
		"#5AC1BF",
		"#0089A3",
		"#EF5236",
		"#0088FF",
		"#5A4C8D"
	]
};

TrendingTopicsReport.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	resetHeader: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func,
	location: PropTypes.object,
	history: PropTypes.object,
	bubbleColors: PropTypes.array,
	defaultNumberOfSeries: PropTypes.number
};

export default withStyles(styles)(withTheme(TrendingTopicsReport));
