import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import Fido from '../../utils/Fido';
import PaddedLayout from '../../containers/PaddedLayout.js';
import AccountAuditTrailTable from "../components/AccountAuditTrailTable";

const styles = theme => ({
	paddedLayout: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	}
});

class AccountAuditTrail extends Component {
	constructor(props, context) {
		super(props, context);

		this.fido = new Fido();

		this.state = {
			events: null,
			fidoError: false,
			hasMoreItems: true,
			isLoading: false,
			pageStart: 1,
			numberOfPages: 2
		};

		this.loadNextPage = this.loadNextPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
		this.props.updateHeader({title: "Account Change Audit Trail", primary: true});
	}

	async fetchData(query) {
		this.setState({
			isLoading: true
		});

		let data = await this.fido.fetch(`/reports${this.props.location.pathname}`, {
			query: Object.assign(
				{},
				query,
				{numberOfPages: this.state.numberOfPages},
				Fido.getSearchParamsObject(this.state.currentSearch, ["page"])
			)
		}).catch(error => {
			this.setState({fidoError: true});
			return this.props.showSnackbar(error.message);
		});

		if (data) {
			let {events} = this.state;
			events = events && events.length ? events.concat(data.items) : data.items;
			this.setState(Object.assign({}, {events: events}, {isLoading: false, hasMoreItems: data.hasMoreItems}));
		}
	}

	loadNextPage() {
		let {hasMoreItems, isLoading, pageStart} = this.state;

		// Check to make sure there is more to load
		if(hasMoreItems === true && !isLoading) {
			this.setState({pageStart: pageStart+1}, () => {
				this.fetchData({page: this.state.pageStart});
			});
		}
	}

	render() {
		let {classes} = this.props,
			{fidoError, events, hasMoreItems, isLoading, pageStart} = this.state;

		return (
			<PaddedLayout
				noTopPad
				isLarge
				className={classes.paddedLayout}>
				<AccountAuditTrailTable
					pageStart={pageStart}
					fidoError={fidoError}
					events={events}
					hasMoreItems={hasMoreItems}
					isLoading={isLoading}
					loadNextPage={this.loadNextPage} />
			</PaddedLayout>
		);
	}
}

AccountAuditTrail.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateHeader: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(AccountAuditTrail);
