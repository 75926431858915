import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highchart from "./Highchart";

class SparkLine extends Component {
	render() {
		let {data, chartColor, accessibility} = this.props,
			plotColor = chartColor || "";

		return (
			<Highchart
				accessibility={accessibility}
				noDataMsg={" "}
				chartConfig={{
					title: {
						text: ""
					},
					credits: {
						enabled: false
					},
					tooltip: {
						enabled: false
					},
					legend: {
						enabled: false
					},
					chart: {
						type: "spline",
						backgroundColor: null,
						borderWidth: 0,
						margin: [0,0,0,0],
						spacing: [0,0,0,0],
						height: 24
					},
					plotOptions: {
						spline: {
							color: `${plotColor}`,
							marker: {
								enabled: false
							}
						},
						series: {
							states: {
								hover: {
									enabled: false
								}
							}
						}
					},
					xAxis: {
						visible: false
					},
					yAxis: {
						visible: false
					},
					series: [{data: data}]
				}}
			/>
		);
	}
}

SparkLine.propTypes = {
	data: PropTypes.array,
	chartColor: PropTypes.string,
	accessibility: PropTypes.bool
};

export default SparkLine;
