import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import { withStyles, Paper, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
	root: {
		display: "flex",
		height: 458,
		flexDirection: "column",

		[theme.breakpoints.down('md')]: {
			height: 493,
		}
	},

	taller: {
		height: 508,

		[theme.breakpoints.down('xs')]: {
			height: 550,
		}
	},

	paper: {
		flex: 2,
		position: "relative",
		overflow: "hidden",
		padding: "5px 15px",
		paddingTop: theme.spacing(1.875)
	},

	subtitle: {
		marginTop: theme.spacing(.5),
		marginBottom: theme.spacing(.5)
	}
});

class ReportItemPaper extends Component {
	render() {
		let {classes, fidoError, previousDays, taller,
			placeHolder, ready, title, subtitle, children, className} = this.props;

		return (
			<div className={`${classes.root} ${taller ? classes.taller : null}`}>
				<Paper className={`${classes.paper} ${className}`}>
					<ReactPlaceholder
						ready={ready}
						customPlaceholder={placeHolder}
						showLoadingAnimation={!fidoError}>
						<Grid
							container
							justify={"space-between"}
							alignItems={"baseline"}>
							{title ?
								<Grid item>
									<Typography variant="h3">{title}</Typography>
								</Grid> : null
							}
							{previousDays ?
								<Grid item>
									<Typography variant="caption" align="right">Prior {previousDays} Days</Typography>
								</Grid> : null
							}
						</Grid>
						<Typography variant="body1" color="textSecondary" className={classes.subtitle}>{subtitle}</Typography>
						{children}
					</ReactPlaceholder>
				</Paper>
			</div>
		);
	}
}

ReportItemPaper.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	ready: PropTypes.bool.isRequired,
	fidoError: PropTypes.bool,
	previousDays: PropTypes.number,
	placeHolder: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	subtitle: PropTypes.string,
	className: PropTypes.string,
	taller: PropTypes.bool
};

export default withStyles(styles)(ReportItemPaper);
