import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';

const styles = theme => ({
	topPad: {
		[theme.breakpoints.up('sm')]: {
			paddingTop: theme.spacing(8)
		}
	},

	bottomPad: {
		[theme.breakpoints.up('sm')]: {
			paddingBottom: theme.spacing(8)
		}
	},

	item: {
		width: "100%",

		[theme.breakpoints.up('sm')]: {
			width: "70%",
		},

		[theme.breakpoints.up('xl')]: {
			width: "60%",
		}
	},

	itemLarge: {
		width: "100%",

		[theme.breakpoints.up('sm')]: {
			width: "90%",
		},

		[theme.breakpoints.up('md')]: {
			width: "85%",
		},

		[theme.breakpoints.up('lg')]: {
			width: "90%",
		},
	}
});

class PaddedLayout extends Component {
	render() {
		let {classes, isLarge, containerClassName, className, noTopPad, noBottomPad} = this.props,
			itemClassName = `${className} ${isLarge ? classes.itemLarge : classes.item}`;

		return (
			<Grid container className={containerClassName} direction="column" alignItems="center">
				<Grid item
					className={`${itemClassName} ${noTopPad === true ? "" : classes.topPad} ${noBottomPad === true ? "" : classes.bottomPad}`}>
					{this.props.children}
				</Grid>
			</Grid>
		);
	}
}

PaddedLayout.propTypes = {
	classes: PropTypes.object.isRequired,
	containerClassName: PropTypes.string,
	className: PropTypes.string,
	isLarge: PropTypes.bool,
	noTopPad: PropTypes.bool,
	noBottomPad: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default withStyles(styles)(PaddedLayout);
