import React from 'react';
import PropTypes from 'prop-types';
import MomentCore from 'moment';
import MomentUtils from "@date-io/moment";
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles, FormHelperText } from '@material-ui/core';

const styles = theme => ({
	dateError: {
		'& label': {
			color: '#f44336'
		},

		'& div': {
			'&:after': {
				transform: 'scaleX(1)',
				borderBottomColor: '#f44336'
			}
		}
	},

	dateErrorMsg: {
		color: '#f44336',
	    display: 'block',
		marginTop: 0
	}
});

class DatePickerValidator extends ValidatorComponent {
	render() {
		let {classes, onChange, name, format, value, errorMessages, validators, validatorListener, requiredError, ...rest} = this.props,
			{isValid} = this.state;

		return (
			<div>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<DatePicker
						{...rest}
						className={isValid ? null : classes.dateError}
						name={name}
						format={format}
						value={value}
						initialFocusedDate={new MomentCore()}
						onChange={(e) => {
							if (e) {
								e.target = {};
								e.target.value = MomentCore(e._d).format(format);
								e.target.name = name;
								onChange(e);
							}
						}}
					/>
				</MuiPickersUtilsProvider>
				{!isValid ? (
					<FormHelperText className={classes.dateErrorMsg}>
						{this.getErrorMessage()}
					</FormHelperText>
				) : null}
			</div>
		);
	}
}

DatePickerValidator.propTypes = {
	name: PropTypes.string,
	format: PropTypes.string,
	onChange: PropTypes.func,
};

export default withStyles(styles)(DatePickerValidator);
