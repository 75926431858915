import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { ValidatorForm } from 'react-material-ui-form-validator';
import PhoneValidator from '../components/PhoneValidator';

import StandardFormLayout from '../components/StandardFormLayout';
import LabeledSwitch from "../components/LabeledSwitch";
import Heading from "../components/Heading";
import WeekToggleButtons from "../components/WeekToggleButtons";
import PaddedLayout from "../containers/PaddedLayout";
import LabeledRadioGroup from "../components/LabeledRadioGroup";
import TimeSelect from "../components/TimeSelect";
import Constants from '../utils/Constants';

import {
	withStyles,
	Collapse,
	Paper,
	TextField,
	Button,
	Typography,
	Grid,
	LinearProgress,
	Tooltip
} from '@material-ui/core';

const styles = theme => ({
	paddedRow: {
		paddingLeft: "3%",
		paddingRight: "3%",
	},

	absoluteRow: {
		left: "3%",
		right: "3%",
	},

	rowWidth: {
		width: "94%",
	},

	select: {
		marginTop: "0 !important",
		marginBottom: "16px"
	},

	selectLabel: {
		position: "relative"
	},

	topicLinearProgress: {
		marginTop: 3 * theme.spacing(1),
		marginBottom: 3 * theme.spacing(1)
	},

	timeButton: {
		marginLeft: "2%",
		marginRight: "2%",
		paddingLeft: "1%",
		paddingRight: "1%"
	},

	timeIcon: {
		paddingRight: theme.spacing(.5)
	},

	scheduleWrapper: {
		paddingBottom: theme.spacing(1)
	},

	tooltip: {
		maxWidth: 130,
		marginBottom: theme.spacing(-0.5)
	}
});

class SettingsForm extends Component {
	render() {
		const startValue = '12:00:00',
			endValue = '21:00:00',
			alertSchedule = {same: "same", diff: "different"};

		let {classes, settings, libraryTopics, hasRealTimeNotification,
				/*digestEnabled,*/ digestButtonToggled/*, digestSwitchChanged*/, alertDaysButtonToggled, user, openTimeSettings, rtNotifications} = this.props,
			notificationConfig = settings.notification,
			activeLibraryTopics = notificationConfig.activeLibraryTopics,
			formChangeHandler = this.props.formChangeHandler,
			paddedRowCls = `${classes.paddedRow} ${classes.rowWidth}`,
			absoluteRowCls = `${classes.absoluteRow} ${classes.rowWidth}`,
			showSchedule = (notificationConfig.alert_schedule) ? true : false,

			timezoneSettingsButton = (
				<Button
					color="primary"
					className={classes.timeButton}
					onClick={openTimeSettings}>
					<FontAwesomeIcon className={classes.timeIcon} icon={["fal", "globe"]} title="Timezone and date settings" />
					Timezone and date settings ({notificationConfig.timezone})
				</Button>
			);

		let scheduleDays = [{
			name: "monday",
			dayValue: notificationConfig.monday_alerts,
			startName: "daily_alert_start_time_monday",
			startValue: notificationConfig.daily_alert_start_time_monday || startValue,
			endName: "daily_alert_end_time_monday",
			endValue: notificationConfig.daily_alert_end_time_monday || endValue
		},{
			name: "tuesday",
			dayValue: notificationConfig.tuesday_alerts,
			startName: "daily_alert_start_time_tuesday",
			startValue: notificationConfig.daily_alert_start_time_tuesday || startValue,
			endName: "daily_alert_end_time_tuesday",
			endValue: notificationConfig.daily_alert_end_time_tuesday || endValue
		},{
			name: "wednesday",
			dayValue: notificationConfig.wednesday_alerts,
			startName: "daily_alert_start_time_wednesday",
			startValue: notificationConfig.daily_alert_start_time_wednesday || startValue,
			endName: "daily_alert_end_time_wednesday",
			endValue: notificationConfig.daily_alert_end_time_wednesday || endValue
		},{
			name: "thursday",
			dayValue: notificationConfig.thursday_alerts,
			startName: "daily_alert_start_time_thursday",
			startValue: notificationConfig.daily_alert_start_time_thursday || startValue,
			endName: "daily_alert_end_time_thursday",
			endValue: notificationConfig.daily_alert_end_time_thursday || endValue
		},{
			name: "friday",
			dayValue: notificationConfig.friday_alerts,
			startName: "daily_alert_start_time_friday",
			startValue: notificationConfig.daily_alert_start_time_friday || startValue,
			endName: "daily_alert_end_time_friday",
			endValue: notificationConfig.daily_alert_end_time_friday || endValue
		},{
			name: "saturday",
			dayValue: notificationConfig.saturday_alerts,
			startName: "daily_alert_start_time_saturday",
			startValue: notificationConfig.daily_alert_start_time_saturday || startValue,
			endName: "daily_alert_end_time_saturday",
			endValue: notificationConfig.daily_alert_end_time_saturday || endValue
		},{
			name: "sunday",
			dayValue: notificationConfig.sunday_alerts,
			startName: "daily_alert_start_time_sunday",
			startValue: notificationConfig.daily_alert_start_time_sunday || startValue,
			endName: "daily_alert_end_time_sunday",
			endValue: notificationConfig.daily_alert_end_time_sunday || endValue
		}];

		return (
			<PaddedLayout>
				<Paper>
					<ValidatorForm
						debounceTime={500}
						onSubmit={this.props.submitHandler}
						onError={errors => console.error(errors)}>
						<StandardFormLayout>
							{/* everything in here needs at least one of these attributes:
								- name
								- id
								- formKey

								Form fields/controls should have both id & name!
							*/}
							<Heading
								id="deliveryMethodHeading"
								title="Near Real-time Messaging"
								formKey="admHeader"
								className={paddedRowCls} />
							<div
								id="deliveryMethodWrapper"
								role="group"
								tabIndex={0}
								aria-label="Near Real-time Messaging">
								{ rtNotifications.includes("email_alerts") ? (
									<LabeledSwitch
										id="email_alerts"
										name="email_alerts"
										label="Receive email for each Social Media alert"
										className={paddedRowCls}
										checked={notificationConfig.email_alerts}
										onChange={formChangeHandler}
										reverse />
								) :
									null
								}
								{ rtNotifications.includes("text_alerts") ? (
									<LabeledSwitch
										id="text_alerts"
										name="text_alerts"
										label="Receive text message for each Social Media alert"
										className={paddedRowCls}
										checked={user.cell_phone ? notificationConfig.text_alerts : false}
										disabled={!user.cell_phone}
										onChange={formChangeHandler}
										reverse />
								) :
									null
								}
								{ rtNotifications.includes("email_gmail") ? (
									<LabeledSwitch
										id="email_gmail"
										name="email_gmail"
										label="Receive email for each G Suite alert"
										className={paddedRowCls}
										checked={notificationConfig.email_gmail}
										onChange={formChangeHandler}
										reverse />
								) :
									null
								}
								{ rtNotifications.includes("text_gmail") ? (
									<LabeledSwitch
										id="text_gmail"
										name="text_gmail"
										label="Receive text message for each G Suite alert"
										className={paddedRowCls}
										checked={user.cell_phone ? notificationConfig.text_gmail : false}
										disabled={!user.cell_phone}
										onChange={formChangeHandler}
										reverse />
								) :
									null
								}
								{ rtNotifications.includes("email_exchange") ? (
									<LabeledSwitch
										id="email_exchange"
										name="email_exchange"
										label="Receive email for each Outlook alert"
										className={paddedRowCls}
										checked={notificationConfig.email_exchange}
										onChange={formChangeHandler}
										reverse />
								) :
									null
								}
								{ rtNotifications.includes("text_exchange") ? (
									<LabeledSwitch
										id="text_exchange"
										name="text_exchange"
										label="Receive text message for each Outlook alert"
										className={paddedRowCls}
										checked={user.cell_phone ? notificationConfig.text_exchange : false}
										disabled={!user.cell_phone}
										onChange={formChangeHandler}
										reverse />
								) :
									null
								}
								{ rtNotifications.includes("email_shareit") ? (
									<LabeledSwitch
										id="email_shareit"
										name="email_shareit"
										label="Receive email for each Shareit submissions"
										className={paddedRowCls}
										checked={notificationConfig.email_shareit}
										onChange={formChangeHandler}
										reverse />
								) :
									null
								}
								{ rtNotifications.includes("text_shareit") ? (
									<LabeledSwitch
										id="text_shareit"
										name="text_shareit"
										label="Receive text message for each Shareit submissions"
										className={paddedRowCls}
										checked={user.cell_phone ? notificationConfig.text_shareit : false}
										disabled={!user.cell_phone}
										onChange={formChangeHandler}
										reverse />
								) :
									null
								}
							</div>
							<Heading
								id="topicMethodHeading"
								title="Near Real-time Messaging by Topic"
								formKey="topicHeader"
								className={paddedRowCls} />
							<div
								id="topicMethodWrapper"
								role="group"
								tabIndex={0}
								aria-label="Near Real-time Messaging by Topic">
								<Typography id="topicMethodSettingsMessage" className={paddedRowCls} style={{marginTop: 10, marginBottom: 10}} variant="caption">
									You will receive an email or text message for topics you have enabled, based on what you’ve selected in the “Near Real-Time” Messaging section above.
								</Typography>
								{libraryTopics.length ? (
									libraryTopics.map((item, idx) => {
										return (
											<Tooltip
												key={idx}
												placement="top-start"
												disableFocusListener
												title={item.description}
												classes={{tooltip: classes.tooltip}}>
												<LabeledSwitch
													reverse
													id={item.id}
													name={`${item.id}`} // FormControlLabel expects a string, doing this to simplify / re-use stuff in container
													label={item.name}
													className={paddedRowCls}
													onChange={(e) => formChangeHandler(e, {topic: item})}
													disabled={activeLibraryTopics.length === 0 && !hasRealTimeNotification}
													checked={activeLibraryTopics.find(topic => topic.id === item.id) != null} />
											</Tooltip>
										);
									})
								) : <LinearProgress className={classes.topicLinearProgress} />}
							</div>
							{user.accountFeatures.includes(Constants.ACCOUNT.FEATURES.DISCUSSIONS) ?
								<React.Fragment>
									<Heading
										id="trendingTopicHeading"
										title="Notifications For Trending Topics"
										formKey="ttHeader"
										className={paddedRowCls} />
									<div
										id="trendingTopicWrapper"
										role="group"
										tabIndex={0}
										aria-label="Notifications For Trending Topics">
										<Typography id="trendingTopicsMessage" className={paddedRowCls} style={{marginTop: 10, marginBottom: 10}} variant="caption">
											You will receive an email or text message when a safety and security discussion topic is trending on social media, based on what you’ve selected above. These notifications will follow the same schedule you have configured below in the Notification Schedule section.
										</Typography>
										<LabeledSwitch
											reverse
											id="email_trending_topics"
											name="email_trending_topics"
											className={paddedRowCls}
											checked={notificationConfig.email_trending_topics}
											onChange={formChangeHandler}
											label="Receive email for each trending topic" />
										<LabeledSwitch
											reverse
											id="text_trending_topics"
											name="text_trending_topics"
											className={paddedRowCls}
											checked={user.cell_phone ? notificationConfig.text_trending_topics : false}
											disabled={!user.cell_phone}
											onChange={formChangeHandler}
											label="Receive text message for each trending topic" />
									</div>
								</React.Fragment> : null
							}

							<Heading
								id="scheduleHeading"
								title="Near Real-time Messaging Schedule"
								formKey="dedHeader"
								className={paddedRowCls} />
							<div
								id="scheduleWrapper"
								role="group"
								tabIndex={0}
								className={classes.scheduleWrapper}
								aria-label="Near Real-time Messaging Schedule">
								<LabeledSwitch
									id="alert_schedule"
									name="alert_schedule"
									label="Alert Schedule"
									className={paddedRowCls}
									checked={notificationConfig.alert_schedule}
									onChange={formChangeHandler}
									disabled={!hasRealTimeNotification}
									reverse />
								<Typography id="summarySettingsMessage" style={{marginBottom: "10px"}} variant="caption" className={paddedRowCls}>
									Set a delivery schedule for your alerts. {hasRealTimeNotification ? "If a schedule is not set you will receive alert notifications 24 hours, 7 days a week." : null}
								</Typography>
								<Collapse in={showSchedule}>
									<LabeledRadioGroup
										row={true}
										id="alert_schedule_type"
										name="alert_schedule_type"
										aria-label="Alert Schedule Type"
										className={paddedRowCls}
										style={{marginLeft: '-12px'}}
										value={notificationConfig.alert_schedule_type || alertSchedule.same}
										buttonColor="primary"
										buttons={[{
											name: alertSchedule.same,
											label: "Same time for all days"
										},{
											name: alertSchedule.diff,
											label: "Allow different times for each day"
										}]}
										onChange={formChangeHandler} />
									<Collapse in={typeof notificationConfig.alert_schedule_type === 'undefined' || notificationConfig.alert_schedule_type === alertSchedule.same}>
										<TimeSelect
											allDay
											controlId="startFormControl"
											style={{marginTop: "10px"}}
											className={classes.paddedRow}
											ariaId="alert-start-helper-text"
											ariaText="Select the time of day when alert notifications should start"
											selectName="alertStartTimeSelect"
											id="alertStartTime"
											name="daily_alert_start_time"
											value={notificationConfig.daily_alert_start_time || startValue}
											label="Start"
											onChange={formChangeHandler}
											settings={settings} />
										<TimeSelect
											controlId="endFormControl"
											disabled={!notificationConfig.daily_alert_start_time}
											style={notificationConfig.daily_alert_start_time === '24:00:00' ? {display: 'none'} : {marginTop: "12px"}}
											className={classes.paddedRow}
											ariaId="alert-end-helper-text"
											ariaText="Select the time of day when alert notifications should end"
											selectName="alertEndTimeSelect"
											id="alertEndTime"
											name="daily_alert_end_time"
											value={notificationConfig.daily_alert_end_time || endValue}
											label="End"
											onChange={formChangeHandler}
											settings={settings}
											start={notificationConfig.daily_alert_start_time || startValue} />
										<WeekToggleButtons
											id="alertWeekTogglers"
											style={{marginTop: "10px", marginBottom: "10px"}}
											className={paddedRowCls}
											buttonToggled={alertDaysButtonToggled}
											values={[
												notificationConfig.monday_alerts,
												notificationConfig.tuesday_alerts,
												notificationConfig.wednesday_alerts,
												notificationConfig.thursday_alerts,
												notificationConfig.friday_alerts,
												notificationConfig.saturday_alerts,
												notificationConfig.sunday_alerts,
											]} />
									</Collapse>
									<Collapse in={notificationConfig.alert_schedule_type === alertSchedule.diff}>
										<Grid container className={paddedRowCls}>
											<Grid item xs={2} xl={1}>
												<WeekToggleButtons
													id="alertWeekTogglers"
													style={{marginTop: "10px", marginBottom: "10px"}}
													className={paddedRowCls}
													buttonToggled={alertDaysButtonToggled}
													direction="column"
													values={[
														notificationConfig.monday_alerts,
														notificationConfig.tuesday_alerts,
														notificationConfig.wednesday_alerts,
														notificationConfig.thursday_alerts,
														notificationConfig.friday_alerts,
														notificationConfig.saturday_alerts,
														notificationConfig.sunday_alerts,
													]} />
											</Grid>
											<Grid item xs={10} xl={11}>
												{scheduleDays.length ? (
													scheduleDays.map((item, idx) => {
														return (
															<div key={idx}>
																<TimeSelect
																	allDay
																	controlId={"startFormControl-"+idx}
																	className={classes.paddedRow}
																	disabled={item.dayValue? false : true}
																	style={idx === 0 ? {marginTop: "12px"} : null}
																	ariaId={"alert-start-"+idx+"-helper-text"}
																	ariaText={"Select the time of day when alert notifications should start on "+item.name}
																	selectName={"alertStartTimeSelect-"+idx}
																	id={"alertStartTime-"+idx}
																	name={item.startName}
																	value={item.startValue}
																	label={idx === 0 ? "Start" : null}
																	onChange={formChangeHandler}
																	settings={settings} />
																<TimeSelect
																	controlId={"endFormControl-"+idx}
																	className={classes.paddedRow}
																	disabled={item.dayValue ? (item.startValue ? false : true) : true}
																	style={item.startValue === '24:00:00' ? {display: 'none'} : idx === 0 ? {marginTop: "12px"} : null}
																	ariaId={"alert-end-"+idx+"-helper-text"}
																	ariaText={"Select the time of day when alert notifications should end on "+item.name}
																	selectName={"alertEndTimeSelect-"+idx}
																	id={"alertEndTime-"+idx}
																	name={item.endName}
																	value={item.endValue}
																	label={idx === 0 ? "End" : null}
																	onChange={formChangeHandler}
																	settings={settings}
																	start={item.startValue} />
															</div>
														);
													})
												) : null}
											</Grid>
										</Grid>
									</Collapse>
									{notificationConfig.timezone ? timezoneSettingsButton : null}
								</Collapse>
							</div>
							<Heading
								id="digestHeading"
								title="Daily Summary of Alerts"
								formKey="dedHeader"
								className={paddedRowCls} />
							<div
								id="digestWrapper"
								role="group"
								tabIndex={0}
								aria-label="Daily Summary of Alerts">
								{/*<LabeledSwitch
									id="digestAlerts"
									label="Email digest of alerts"
									className={paddedRowCls}
									onChange={digestSwitchChanged}
									checked={digestEnabled}
									reverse/>*/}
								<WeekToggleButtons
									id="weekTogglers"
									style={{marginTop: "10px"}}
									className={paddedRowCls}
									//disabled={!digestEnabled}
									buttonToggled={digestButtonToggled}
									values={[
										notificationConfig.monday_summary,
										notificationConfig.tuesday_summary,
										notificationConfig.wednesday_summary,
										notificationConfig.thursday_summary,
										notificationConfig.friday_summary,
										notificationConfig.saturday_summary,
										notificationConfig.sunday_summary,
									]} />
								<TimeSelect
									controlId="digestFormControl"
									style={{marginTop: "10px"}}
									className={`${paddedRowCls}`}
									ariaId="digest-helper-text"
									ariaText="Select the time of day you'd like to receive a summary of alerts"
									selectName="timeOfDaySelect"
									id="timeOfDay"
									name="daily_summary_time"
									value={notificationConfig.daily_summary_time}
									label="Time of Day"
									onChange={formChangeHandler}
									settings={settings} />
								{notificationConfig.timezone ? timezoneSettingsButton : null}
								<LabeledSwitch
									id="daily_summary_send_blank"
									name="daily_summary_send_blank"
									label="Receive even if no new alerts"
									className={paddedRowCls}
									//disabled={!digestEnabled}
									checked={notificationConfig.daily_summary_send_blank}
									onChange={formChangeHandler}
									reverse />
							</div>
							{user.permissions.includes(Constants.USER.PERMISSIONS.REPORTS.EMAIL) &&
								user.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) ? (
									<div id="perfWrap">
										<Heading
											id="performanceReportsHeading"
											title="Performance Report Emails"
											formKey="perfHeader"
											className={paddedRowCls}/>
										<div
											id="performanceReportsWrapper"
											role="group"
											tabIndex={0}
											aria-label="Performance Report Emails">
											<LabeledSwitch
												name="weekly_summary"
												label="Weekly Summary Report"
												className={paddedRowCls}
												checked={notificationConfig.weekly_summary}
												onChange={formChangeHandler}
												reverse />
											<Typography id="summarySettingsMessage" style={{marginBottom: "10px"}} variant="caption" className={paddedRowCls}>
												An email summary of system performance delivered Monday mornings
											</Typography>
											<LabeledSwitch
												name="monthly_summary"
												label="Monthly Summary Report"
												className={paddedRowCls}
												checked={notificationConfig.monthly_summary}
												onChange={formChangeHandler}
												reverse />
											<Typography id="summarySettingsMessage" style={{marginBottom: "10px"}} variant="caption" className={paddedRowCls}>
												An email summary of system performance delivered on the 1st of every month
											</Typography>
										</div>
									</div>
								) : <div id="perfWrap"></div>}
							<Heading
								id="deliverySettingsHeading"
								title="Delivery Settings"
								formKey="dsHeader"
								className={paddedRowCls} />
							<div
								id="deliverySettingsWrapper"
								role="group"
								tabIndex={0}
								aria-label="Delivery Settings">
								<Typography id="deliverySettingsMessage" className={paddedRowCls} style={{marginTop: "10px"}} variant="caption">
									<i>Delivery Settings</i> can be changed on your <i>Profile</i>. Changes above should be saved first.
								</Typography>
								<TextField className={absoluteRowCls}
									disabled
									fullWidth
									margin="normal"
									id="email"
									name="email"
									label="Email"
									helperText="Your email address"
									value={user.email || ""} />
								<PhoneValidator
									fullWidth
									disabled
									className={absoluteRowCls}
									margin="normal"
									id="cellPhone"
									name="cell_phone"
									label={user.cell_phone && "Mobile Phone" || "Mobile Phone Number Needed"}
									helperText="Your mobile phone number"
									value={user.cell_phone} />
								<TextField className={absoluteRowCls}
									fullWidth
									disabled
									margin="normal"
									id="timezone"
									name="timezone"
									label="Timezone"
									helperText="Your timezone"
									value={user.timezone || ""} />
							</div>
							{/* limitation (in material-ui's docs) with using spacing, this is recommended fix*/}
							<div className={classes.paddedRow}>
								<Grid container name="buttonsGrid" className={classes.paddedRow} spacing={2}>
									<Grid item sm={6} xs={12}>
										<Link to={`${user.profileUrlBase}/edit`}>
											<Button fullWidth variant="outlined" disabled={!user.profileUrlBase}>
												Edit Profile
											</Button>
										</Link>
									</Grid>
									<Grid item sm={6} xs={12}>
										<Button
											id="saveSettingsButton"
											type="submit"
											variant="contained"
											color="primary"
											fullWidth>Save Settings</Button>
									</Grid>
								</Grid>
							</div>
						</StandardFormLayout>
					</ValidatorForm>
				</Paper>
			</PaddedLayout>
		);
	}
}

SettingsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	libraryTopics: PropTypes.array.isRequired,
	rtNotifications: PropTypes.array.isRequired,
	//digestEnabled: PropTypes.bool,
	//digestSwitchChanged: PropTypes.func,
	digestButtonToggled: PropTypes.func,
	formChangeHandler: PropTypes.func,
	submitHandler: PropTypes.func,
	hasRealTimeNotification: PropTypes.bool,
	alertDaysButtonToggled: PropTypes.func,
	openTimeSettings: PropTypes.func
};

export default withStyles(styles)(SettingsForm);
