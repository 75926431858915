import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

import StandardFormLayout from './StandardFormLayout';
import PasswordField from "./PasswordField";
import FormHelper from "../utils/FormHelper";

import { Typography, Button, FormHelperText } from '@material-ui/core';

class SetPasswordForm extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			password: "",
			valid: false
		};

		this.isValid = this.isValid.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		this.setState(FormHelper.getChanged(event));
	}

	isValid(valid) {
		this.setState({valid});
	}

	handleSubmit() {
		if (this.state.valid) {
			this.props.submitHandler(this.state);
		} else {
			this.passwordField.makeInvalid();
		}
	}

	render() {
		let {title} = this.props;
		return (
			<ValidatorForm
				debounceTime={500}
				onSubmit={this.handleSubmit}>
				<StandardFormLayout>
					<Typography id="title">{title}</Typography>
					<PasswordField
						autoFocus
						showRequirements
						id="newPasswordField"
						onRef={ref => (this.passwordField = ref)}
						label="New Password"
						helperText="Enter your new password"
						inputProps={{
							autoComplete: "new-password",
							'aria-describedby': "password-field-helper-text"
						}}
						isValid={this.isValid}
						onChangeHandler={this.handleChange} />
					<div id="saveButtonWrapper" style={{display: "flex", justifyContent: "center"}}>
						<Button
							fullWidth
							id="save-btn"
							type="submit"
							variant="contained"
							color="primary"
							style={{maxWidth: "65%"}}>Save New Password</Button>
					</div>
				</StandardFormLayout>
				<FormHelperText id="password-field-helper-text">Set new password for login</FormHelperText>
			</ValidatorForm>
		);
	}
}

SetPasswordForm.propTypes = {
	title: PropTypes.string.isRequired,
	submitHandler: PropTypes.func.isRequired
};

export default SetPasswordForm;