import React, { Component } from 'react';
import PropTypes from 'prop-types';

import logo from "../assets/ss-loading-logo.png";

import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		paddingTop: "20%",
		[theme.breakpoints.down('md')]: {
			paddingTop: "30%",
		},
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},

	logo: {
		[theme.breakpoints.down('xs')]: {
			transform: "scale(0.75, 0.75)",
		}
	}
});

class NoMatchAnonymous extends Component {
	componentDidMount() {
		this.props.updateHeader({hideHeaderbar: true});
	}

	componentWillUnmount() {
		this.props.resetHeader();
	}

	render() {
		let {classes} = this.props;
		return (
			<div className={classes.root}>
				<Grid container direction="column" alignItems="center" spacing={2}>
					<Grid item>
						<img className={classes.logo} src={logo} alt="dark logo"/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

NoMatchAnonymous.propTypes = {
	classes: PropTypes.object.isRequired,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func
};

export default withStyles(styles)(NoMatchAnonymous);

