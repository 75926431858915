import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Fido from '../utils/Fido';
import EventDetailsForm from '../components/EventDetailsForm';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import FormHelper from "../utils/FormHelper";
import EventMetricsReport from "../reports/containers/EventMetricsReport";
import Constants from "../utils/Constants";
import PaddedLayout from "../containers/PaddedLayout";

class EventDetails extends Component {
	constructor(props, context) {
		super(props, context);

		let formValues = Object.assign({}, {locations: []});

		let urlParams = (this.props.location.pathname).split('/');

		this.state = {
			formValues: formValues,
			restricted: false,
			accountLocations: [],
			editingEvent: (urlParams[4]) ? Number(urlParams[4]) : null,
			inAccount: (urlParams[2]) ? Number(urlParams[2]) : null,
			addingEvent: (this.props.location.pathname === '/events/add') ? true : false,
			isLoading: true,
			dataLoading: true,
			searchTermSuggestion: ""
		};

		this.sharedFido = new Fido();
		this.accountLocationsFido = new Fido();

		this.setEvent = this.setEvent.bind(this);
		this.setupEvent = this.setupEvent.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dismissBanner = this.dismissBanner.bind(this);
		this.deleteEventHandler = this.deleteEventHandler.bind(this);
		this.getAccountLocations = this.getAccountLocations.bind(this);
		this.searchTermSuggestionChange = this.searchTermSuggestionChange.bind(this);
		this.searchTermSuggestionHandler = this.searchTermSuggestionHandler.bind(this);
	}

	setEvent() {
		if (this.state.addingEvent) {
			this.setupEvent();
		} else {
			this.sharedFido.fetch(this.props.location.pathname)
				.then((data) => {
					if (data) {
						this.event = data;
						this.setupEvent();
					}
				})
				.catch(error => {
					this.setState({fidoError: true});
					return this.props.showSnackbar(error.message);
				});
		}
	}

	setupEvent() {
		let formValues = this.state.formValues;

		if (!this.state.addingEvent) {
			formValues = Object.assign({}, formValues, this.event);
		}

		this.getAccountLocations(this.props.authenticatedUser.account_id);
		this.setState({formValues: formValues, isLoading: false});
		this.updateHeader();
	}

	updateHeader() {
		let headerOptions = {
			title: (this.state.addingEvent) ? 'Add Event' : 'Event Details'
		};
		this.props.updateHeader(headerOptions);
	}

	getAccountLocations(accountId) {
		if (accountId) {
			this.accountLocationsFido.fetch(`/accounts/${accountId}/locations?active=1`)
				.then((data) => {
					if (data) {
						this.setState({accountLocations: data});
						this.setState({dataLoading: false});
					}
				})
				.catch(error => {
					this.setState({fidoError: true});
					return this.props.showSnackbar(error.message);
				});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// if previous id is diff, reload data
		if (this.props.match.params.eventId !== prevProps.match.params.eventId) {
			this.setState({isLoading: true});
			this.setEvent();
		}
	}

	componentDidMount() {
		this.updateHeader();

		// Permission check
		if ((this.props.authenticatedUser.account_id === this.state.inAccount) || (this.props.authenticatedUser.account_id !== this.state.inAccount && this.props.authenticatedUser.permissions.indexOf('write.corp'))) {
			// Check 1: Account ids match
			this.setEvent();
		} else {
			this.setState({restricted: true});
		}
	}

	componentWillUnmount() {
		this.sharedFido.dropIt();
		this.accountLocationsFido.dropIt();
		this.props.resetHeader();
	}

	dismissBanner() {
		this.props.showBanner(false);
	}

	handleChange(event) {
		let formValues = Object.assign(this.state.formValues, FormHelper.getChanged(event));
		this.setState({formValues: formValues});
	}

	handleKeyDown(event) {
		// This allows new lines to be made in the multiline textarea
		if (event.keyCode === 13) {
			if (event.target && event.target.value) {
				event.target.value = event.target.value + '\n';
				this.handleChange(event);
			}
		}
	}

	handleSubmit(formValues) {
		let successMsg = (this.state.addingEvent) ? "Event Added." : "Saved.",
			msg = (this.state.addingEvent) ? 'Adding Event...' : 'Saving Event...',
			putUrl = (this.state.addingEvent) ? '/events/add' : '/events/' + formValues.id;

		formValues.locations = formValues.locations && formValues.locations.split(',') || null;
		formValues.type = Constants.ENTITIES.IDS.EVENT;

		let poodle = new Fido();
		// Using poodle cause it's a throw away, like an avalanche poodle - don't want to abort call when unmounted
		poodle.fetch(putUrl, {
			method: (this.state.addingEvent) ? "POST" : "PUT",
			body: JSON.stringify(formValues)
		}, msg, successMsg)
			// trying to set state when component is not mounted (backed up), causes potential memory leak.
			// so, since we're backing up, we don't need to set the state.
			.then((data) => {})
			.catch(error => this.props.showSnackbar(error.message));

		this.props.showBanner({
			content: this.state.addingEvent ?
				"New events may take a few moments to be fully created." :
				"It may take a few moments for changes to take effect.",
			actions: [
				{text: "Dismiss", handler: () => this.dismissBanner()}
			]
		});

		this.props.history.goBack();
	}

	deleteEventHandler(eventId) {
		let poodle = new Fido();
		// Using poodle cause it's a throw away, like an avalanche poodle - don't want to abort call when unmounted
		poodle.fetch("/events/" + eventId + "/delete", {
			method: "DELETE"
		}, "Deleting Event...", 'Event Deleted.')
			// trying to set state when component is not mounted (backed up), causes potential memoery leak.
			// so, since we're backing up, we don't need to set the state.
			.then((data) => {
				this.props.history.push({removed: `?exclude=${data.eventId}`});
			})
			.catch(error => this.props.showSnackbar(error.message));

		this.props.history.goBack();
	}

	searchTermSuggestionChange(event) {
		this.setState({searchTermSuggestion: event.target.value});
	}

	searchTermSuggestionHandler() {
		let simon = new Fido(),
			{searchTermSuggestion, formValues} = this.state;

		simon.fetch(`/events/${formValues.id}/search-terms-suggestion`, {
			method: "POST",
			body: JSON.stringify(Object.assign({}, formValues, {searchTermSuggestion: searchTermSuggestion}))
		}, "Submitting Suggestions...", "Suggestions Submitted.")
			.catch(error => this.props.showSnackbar(error.message));
	}

	render() {
		let {location, accountLocations, formValues, restricted, isLoading, dataLoading, searchTermSuggestion} =  this.state,
			{showSnackbar} = this.props;

		if (restricted) {
			return (
				// Permission denied
				<Redirect to={{
					pathname: "/access"
				}}/>
			);
		} else {
			return (
				<React.Fragment>
					{formValues.id ? (
						<EventMetricsReport
							showSnackbar={showSnackbar}
							location={location}
							eventId={formValues.id}
							searchTermSuggestion={searchTermSuggestion}
							suggestionChange={this.searchTermSuggestionChange}
							suggestionHandler={this.searchTermSuggestionHandler} />
					) : null}
					<PaddedLayout noTopPad={formValues.id ? true : false}>
						<EventDetailsForm
							isLoading={isLoading}
							dataLoading={dataLoading}
							accountLocations={accountLocations}
							formValues={formValues}
							showSnackbar={showSnackbar}
							deleteEventHandler={this.deleteEventHandler}
							handleChange={this.handleChange}
							handleKeyDown={this.handleKeyDown}
							handleSubmit={this.handleSubmit} />
					</PaddedLayout>
				</React.Fragment>
			);
		}
	}
}

EventDetails.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object,
	resetHeader: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func,
	authenticatedUser: PropTypes.object,
	showBanner: PropTypes.func
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <EventDetails {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "EventDetails";

export default React.forwardRef(dNameFunc);