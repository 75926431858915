import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';

import Constants from "../utils/Constants";
import ActivityInfo from './ActivityInfo.js';
import ActivityCategory from './ActivityCategory.js';
import ActivityTitle from './ActivityTitle';
import ActivityContent from './ActivityContent';
import ActivityTopics from './ActivityTopics';
import PostMedia from './PostMedia.js';
import ViewOriginalButton from "./ViewOriginalButton";
import Comments from './Comments.js';
import GoogleLogo from "../assets/google-logo.svg";
import LoadingTextButton from './LoadingTextButton';
import TrendingTopicLinks from "./TrendingTopicLinks";
import Timestamp from './Timestamp.js';
import SourceIndicator from './SourceIndicator.js';
import TipInfo from './TipInfo.js';
import RateAlertButton from "./RateAlertButton";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

import { withStyles, withWidth, Typography, Paper, Grid } from '@material-ui/core';

const styles = theme => ({
	upperContentPad: {
		padding: theme.spacing(3)
	},

	upperWithMedia: {
		paddingBottom: theme.spacing(2)
	},

	lowerContentPad: {
		padding: theme.spacing(3)
	},

	lowerNoMedia: {
		paddingTop: 0
	},

	translateButton: {
		marginLeft: theme.spacing(-1)
	},

	translatedWrapper: {
		paddingTop: theme.spacing(2)
	},

	gLogo: {
		height: '1rem',
		marginLeft: theme.spacing(1),
		marginBottom: theme.spacing(-.25)
	},

	gText: {
		textAlign: 'left'
	},

	gIndicator: {
		top: theme.spacing(.625),
		right: theme.spacing(.45454545454545453)
	},

	sourceIconItem: {
		paddingLeft: theme.spacing(1)
	},

	comments: {
		marginTop: theme.spacing(2.5)
	},

	rateButton: {
		marginRight: theme.spacing(-1)
	},

	viewOrigButton: {
		marginRight: theme.spacing(-1)
	},

	translationGrid: {
		marginTop: theme.spacing(1)
	}
});

class PaperActivity extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			translationOpen: false
		};

		this.showTranslation = this.showTranslation.bind(this);
	}

	showTranslation() {
		this.setState({translationOpen: !this.state.translationOpen}, () => {
			if(this.state.translationOpen && !this.props.translated) {
				// Only do the translation when toggling to show
				// and if we don't already have the translation
				this.props.handleTranslateClick();
			}
		});
	}

	render() {
		let topicsToBold = [],
			{addCommentHandler,
				authorImage, authorLink, authorName, authorDesc, authorLoc, authorFollowing, authorFollowers,
				classes, className, comments, title, content, patch, iFrameVideo, imageUrl, numberOfViewableComments,
				sourceId, subSourceId, sourceHandle, sourceIcon, sourceName,
				time, videoUrl, category, actions, fidoError, postLink, language,
				translated, translatedTitle, errorMsg, width, trendingTopics, extraHeaders, tip,
				auditTrailRated, rateHandler, activityId, isActivityDeleted, twitterRedacted, history, postMatches} = this.props,
			{translationOpen} = this.state,
			ready = content != null,
			translateReady = (language !== null && language !== '' && language !== 'en') ? true : false;

		let isTip = sourceName === 'tips';
		let hasTipConcern = isTip && "concernOtherDescription" in tip && !!(tip.concernOtherDescription);

		if (trendingTopics) {
			topicsToBold = trendingTopics.reduce((result, topic) => {
				return topic.trending_topic.name.split(" | ");
			}, topicsToBold);
		}

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					let myRating;
					if (auditTrailRated) {
						myRating = auditTrailRated.find(rating => rating.user.id === authenticatedUser.id) || {alert_id: activityId};
					}

					let rateButton = (
						<RateAlertButton
							category={category}
							rating={myRating}
							rateHandler={rateHandler}
							auditTrailRated={auditTrailRated} />
					);

					return (
						<div>
							<Paper className={className}>
								<div className={`${classes.upperContentPad} ${imageUrl || videoUrl || !ready ? classes.upperWithMedia : ""}`}>
									<ReactPlaceholder type='text' rows={2} ready={ready} showLoadingAnimation={!fidoError}>
										<Grid container justify="space-between">
											<Grid item>
												<ActivityCategory category={category} />
											</Grid>
											<Grid item>
												<Grid container justify="flex-start" alignItems="center">
													<Grid item>
														<Timestamp time={time} relative={false} />
													</Grid>
													{sourceIcon ?
														<Grid item className={classes.sourceIconItem}>
															<SourceIndicator {...sourceIcon} />
														</Grid>	: ""
													}
												</Grid>
											</Grid>
										</Grid>
										<TrendingTopicLinks topics={trendingTopics} />
										<ActivityTitle title={title} twitterRedacted={twitterRedacted} />
										<ActivityContent
											patch={patch}
											sourceId={sourceId}
											sourceIcon={sourceIcon}
											history={history}
											content={content}
											category={category}
											boldWords={topicsToBold}
											twitterRedacted={twitterRedacted}
											originalContent={extraHeaders ? extraHeaders.content : null} />
										<ActivityTopics matches={postMatches} />
										{translationOpen && (!twitterRedacted || twitterRedacted === Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO) ? (
											<Grid container justify="space-between" className={classes.translationGrid}>
												{translated !== '' ?
													<div className={classes.translatedWrapper}>
														{translatedTitle ? (
															<ActivityTitle color="textSecondary" title={translatedTitle} twitterRedacted={twitterRedacted} />
														) : null}
														<ActivityContent
															history={history}
															color="textSecondary"
															content={translated}
															category={category}
															twitterRedacted={twitterRedacted} />
													</div>
													: (errorMsg === null) ? null : <Typography color="textSecondary">{errorMsg}</Typography>
												}
											</Grid>
										) : null}
										{hasTipConcern ? <TipInfo {...tip} /> : null}
										<Grid container justify={(translateReady) ? "space-between" : "flex-end"} className={classes.translationGrid}>
											{translateReady && (!twitterRedacted || twitterRedacted === Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO) ? (
												<Grid item>
													<LoadingTextButton
														onClick={this.showTranslation}
														className={classes.translateButton}
														indicatorClassName={classes.gIndicator}
														loading={translationOpen && !translated}>
														{!translationOpen ? (width.includes("xs") ? 'Translate' : 'Show Translation') : (
															<span className={classes.gText}>
																{width.includes("xs") ? 'Translation by' : 'Translation by Google'}
																<img src={GoogleLogo} alt="Google" className={classes.gLogo} />
															</span>
														)}
													</LoadingTextButton>
												</Grid>
											) : null}
											{!twitterRedacted || twitterRedacted === Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO ? (
												<ViewOriginalButton
													isTip={isTip}
													postLink={postLink}
													extraHeaders={extraHeaders}
													className={classes.viewOrigButton} />
											) : null}
										</Grid>
									</ReactPlaceholder>
								</div>
								<ReactPlaceholder type='rect' ready={ready} showLoadingAnimation={!fidoError} style={{height: 150, borderRadius: 0}}>
									<PostMedia imageUrl={imageUrl} videoUrl={videoUrl} useIframeVideo={iFrameVideo}></PostMedia>
								</ReactPlaceholder>
								<div className={`${classes.lowerContentPad} ${imageUrl || videoUrl || !ready ? "" : classes.lowerNoMedia}`}>
									<ActivityInfo
										authorName={authorName}
										authorLink={authorLink}
										authorImage={authorImage}
										authorDesc={authorDesc}
										authorLoc={authorLoc}
										authorFollowing={authorFollowing}
										authorFollowers={authorFollowers}
										extraHeaders={extraHeaders}
										time={time}
										twoLineSource
										fidoError={fidoError}
										sourceName={sourceName}
										sourceHandle={sourceHandle}
										sourceIcon={sourceIcon}
										subSourceId={subSourceId}
										twitterRedacted={twitterRedacted} />
									<Comments
										ready={ready}
										fidoError={fidoError}
										comments={comments}
										allowViewMore
										numberToShow={numberOfViewableComments || null}
										actions={actions}
										className={classes.comments}
										isActivityDeleted={isActivityDeleted}
										addCommentHandler={addCommentHandler}
										bottomRowButton={rateButton} />
								</div>
							</Paper>
						</div>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

PaperActivity.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	className: PropTypes.string,
	activityId: PropTypes.number,
	title: PropTypes.string,
	content: PropTypes.string,
	patch: PropTypes.string,
	category: PropTypes.number,
	imageUrl: PropTypes.string,
	videoUrl: PropTypes.string,
	postLink: PropTypes.string,
	iFrameVideo: PropTypes.bool,
	authorName: PropTypes.string,
	authorLink: PropTypes.string,
	authorImage: PropTypes.string,
	authorDesc: PropTypes.string,
	authorLoc: PropTypes.string,
	authorFollowing: PropTypes.number,
	authorFollowers: PropTypes.number,
	sourceId: PropTypes.number,
	subSourceId: PropTypes.string,
	sourceName: PropTypes.string,
	sourceHandle: PropTypes.string,
	sourceIcon: PropTypes.object,
	time: PropTypes.string,
	comments: PropTypes.array,
	addCommentHandler: PropTypes.func,
	numberOfViewableComments: PropTypes.number,
	actions: PropTypes.array,
	showSnackbar: PropTypes.func,
	fidoError: PropTypes.bool,
	errorMsg: PropTypes.string,
	language: PropTypes.string,
	translated: PropTypes.string,
	translatedTitle: PropTypes.string,
	handleTranslateClick: PropTypes.func,
	trendingTopics: PropTypes.array,
	extraHeaders: PropTypes.object,
	auditTrailRated: PropTypes.array,
	rateHandler: PropTypes.func.isRequired,
	isActivityDeleted: PropTypes.bool,
	twitterRedacted: PropTypes.string,
	postMatches: PropTypes.array,
	tip: PropTypes.object
};

export default withStyles(styles)(withWidth()(PaperActivity));
