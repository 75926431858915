import React, { Component } from 'react';
import Moment from 'react-moment';
import MomentCore from 'moment';
import PropTypes from 'prop-types';

import AuthorizedUser from "../utils/AuthorizedUser";

import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
	timestamp: {
		color: theme.palette.text.secondary
	},

	timestampPrimary: {
		color: theme.palette.text.primary
	},

	reducedSize: {
		fontSize: "0.9rem"
	},

	moreContrast: {
		color: theme.palette.accessibilityColors.text.secondary
	}
});

class Timestamp extends Component {
	getMoment() {
		let {time, relative, format} = this.props;

		if (!time) {
			return null;
		}

		// assuming all times are UTC
		let now = new MomentCore(),
			postTime = new MomentCore.utc(time).local(),
			within3days = postTime.isAfter(now.subtract(4, "days"));

		// relative within 3 days
		if (within3days && relative !== false) {
			return (
				<Moment fromNow>{postTime}</Moment>
			);
		}

		// Month Day, Year h:mma
		return (
			<Moment format={format ? format : "ddd MMM Do @ h:mma"}>{postTime}</Moment>
		);
	}

	render() {
		let {classes, palette, className, reducedSize} = this.props,
			colorClass = AuthorizedUser.wantsMoreContrast() ? classes.moreContrast : palette === "primary" ? classes.timestampPrimary : classes.timestamp;

		return (
			<Typography
				className={`${colorClass} ${reducedSize ? classes.reducedSize : ""} ${className}`}>
				{this.getMoment()}
			</Typography>
		);
	}
}

Timestamp.defaultProps = {
	reducedSize: true
};

Timestamp.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	time: PropTypes.string,
	format: PropTypes.string,
	palette: PropTypes.string,
	relative: PropTypes.bool, // default is true
	reducedSize: PropTypes.bool
};

export default withStyles(styles)(Timestamp);
