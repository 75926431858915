class HTMLEntities {
	static decode(str) {
		if (str) {
			let txt = document.createElement('textarea');
			txt.innerHTML = str;
			return txt.value;
		}
	}
}

export default HTMLEntities;