import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Link,
	Typography
} from "@material-ui/core";

import AuthorizedUser from "../utils/AuthorizedUser";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

class TwitterRedactionExplanation extends Component {
	goto = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.history.push("/alert-summary-report");
	}

	render() {
		let {isAlert} = this.props;
		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
						<React.Fragment>
							<Typography>
								When a post is modified or deleted at its source, it is similarly modified or deleted in our platform.
							</Typography><br/><br/>
							{authenticatedUser && AuthorizedUser.isAdmin(authenticatedUser) && isAlert ? (
								<div style={{paddingBottom: 24}}>
									However, the alert as originally provided in the system may be preserved here in the&nbsp;
									<Link
										onClick={this.goto}
										style={{cursor: "pointer"}}
										aria-label="view original alert in summary report">Engagement & Alert Summary Report</Link>.
								</div>
							) : null}
						</React.Fragment>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

TwitterRedactionExplanation.propTypes = {
	history: PropTypes.object.isRequired,
	isAlert: PropTypes.bool
};

export default TwitterRedactionExplanation;