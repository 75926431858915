import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import ReadMore from '../utils/ReadMore';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

import {
	withStyles,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	Divider,
} from '@material-ui/core';

const styles = theme => ({
	expansionPanel: {
		backgroundColor: 'transparent',
		boxShadow: 'none',
		'&:before': {
			opacity: 0
		},
		'&:last-child': {
			borderBottom: 'none',
			backgroundColor: 'transparent'
		}
	},

	expansionPanelSummary: {
		'&$disabled': {
			opacity: 1
		}
	},
	disabled: {}, // Needs to be here for above style to work

	expansionPanelSummaryContent: {
		display: 'block',
		width: '100%'
	},

	expansionPanelDetails: {
		display: 'block',
		flexGrow: 1,
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		color: theme.palette.text.secondary
	},

	detail: {
		whiteSpace: 'normal',
		wordWrap: 'break-word'
	},

	matched: {
		marginBottom: theme.spacing(1)
	},

	oddEntry: {
		backgroundColor: theme.palette.grey[200]
	},

	divider: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3)
	},

	mapButton: {
		padding: 0,
		minHeight: 0,
		marginBottom: theme.spacing(1)
	},

	mapIcon: {
		marginRight: theme.spacing(1)
	},

	methodTyp: {
		paddingRight: theme.spacing(4)
	},

	expandIcon: {
		top: "20%"
	}
});

class Associations extends Component {
	render() {
		let { classes, content } = this.props,
			panelCount = (content) ? content.length : 0;

		content.map((panel, i) => {
			if(typeof panel.details === 'object') {
				panel.details.map((panelDetail, i) => {
					// Fix commas without a space after
					let re = /(,(?=[\S\n]))/;
					let detailContent = panelDetail.detail;
					panelDetail.detail = detailContent.replace(re, '$1 ');

					// Locations to string
					if(typeof panelDetail.locations === 'object' && panelDetail.locations.length > 0) {
						//panelDetail.locations = panelDetail.locations.join('\n');
						panel.panelExpand = true;
					}

					return panel;
				});
			}
			if(typeof panel.panelExpand === 'undefined') {
				panel.panelExpand = false;
			}
			content[i] = panel;
			return content;
		});

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
						<div>
							{content.map((panel, i) =>
								<div key={i}>
									<ExpansionPanel
										id="associations-expansion-panel"
										aria-controls="associations-expansion-summary"
										disabled={(panel.panelExpand) ? false : true}
										className={classes.expansionPanel}>
										<ExpansionPanelSummary
											id="associations-expansion-summary"
											aria-labelledby="associations-expansion-panel"
											className={classes.expansionPanelSummary}
											classes={{
												disabled: classes.disabled,
												content: classes.expansionPanelSummaryContent,
												expandIcon: classes.expandIcon
											}}
											expandIcon={panel.panelExpand ? (
												<FontAwesomeIcon icon={["far", "angle-down"]} />
											) : null}>
											<Typography className={classes.methodTyp}>{panel.method}</Typography>
											<Typography color="textSecondary" className={classes.detail}>
												{panel.details.map((panelDetail, i) =>
													<span key={i}>
														{panelDetail.detail}
														{(panel.details.length === i + 1) ? (null) : (
															<span>{', '}</span>
														)}
													</span>
												)}
											</Typography>
										</ExpansionPanelSummary>
										{panel.panelExpand ? (
											<ExpansionPanelDetails className={classes.expansionPanelDetails}>
												<div className={classes.matched}>
													<Typography>Locations Matched</Typography>
													<Divider />
												</div>
												{panel.details.map((panelDetail, i) => {
													return (
														<div key={i} className={`${classes.matched} ${(i % 2 === 1) ? classes.oddEntry : null}`}>
															{panelDetail.locations.map((line, i, arr) => {
																if (authenticatedUser.childAccounts && authenticatedUser.childAccounts.length) {
																	let strippedAccnt = authenticatedUser.childAccounts.find(
																		item => item.id === line.accountId
																	);
																	if (strippedAccnt) {
																		line.name = line.name.replace(/\([^)]+\)/ig, `(${strippedAccnt.name})`);
																	}
																}

																line = <span key={i}>{line.name}</span>;
																return line;
																/*if (i === arr.length - 1) {
																	return line;
																} else {
																	return [line, <br key={i + 'br'} />, <br key={i + 'brbr'} />];
																}*/
															})}
														</div>
													);
												})}
											</ExpansionPanelDetails>
										) : null}
									</ExpansionPanel>
									{panelCount === i + 1 ? null : (
										<Divider className={classes.divider} />
									)}
								</div>
							)}
						</div>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

// Scoll to map on mobile
// <Media query={{maxWidth: theme.breakpoints.values.md}}>
//	{matches => (
//		matches ? (
//			(panel.method === 'Post Location') ? (
//				<Button className={classes.mapButton} onClick={handleMapScroll}><FontAwesomeIcon icon={["far", "map"]} className={classes.mapIcon} />
//						{panelDetail.detail}
//				</Button>
//			) : (panelDetail.detail)
//		) : (panelDetail.detail)
//	)}
// </Media>

Associations.propTypes = {
	key: PropTypes.number,
	content: PropTypes.array,
	classes: PropTypes.object.isRequired
	// handleMapScroll: PropTypes.func
};

export default withStyles(styles)(Associations);
