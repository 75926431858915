import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Fido from '../utils/Fido';
import UserProfileForm from '../components/UserProfileForm';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import PasswordResetConfirmation from '../components/PasswordResetConfirmation';
import Constants from "../utils/Constants";
import FormHelper from "../utils/FormHelper";

class UserProfile extends Component {
	constructor(props, context) {
		super(props, context);

		let formValues = Object.assign({}, {locations: [], user_type_str: Constants.USER.ROLES.TEAM_MEMBER.NAME, timezone: "America/New_York"});

		let urlParams = (this.props.location.pathname).split('/');

		this.state = {
			timezones: ["America/New_York"],
			userTypes: [{id: Constants.USER.ROLES.TEAM_MEMBER.ID, name: Constants.USER.ROLES.TEAM_MEMBER.NAME}],
			accountLocations: [],
			jobTitles: {},
			formValues: formValues,
			resetPasswordDialogOpen: false,
			restricted: false,
			editingUser: (urlParams[4]) ? Number(urlParams[4]) : null,
			inAccount: (urlParams[2]) ? Number(urlParams[2]) : null,
			addingUser: (this.props.location.pathname === '/users/add') ? true : false,
			isLoading: true,
			dataLoading: true
		};

		this.sharedFido = new Fido();
		this.userTypesFido = new Fido();
		this.jobTitlesFido = new Fido();
		this.userLocationsFido = new Fido();
		this.accountLocationsFido = new Fido();

		this.setUser = this.setUser.bind(this);
		this.setupUser = this.setupUser.bind(this);
		this.loadData = this.loadData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.uploadAvatar = this.uploadAvatar.bind(this);
		this.deleteUserHandler = this.deleteUserHandler.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.handlePasswordResetDialogCancel = this.handlePasswordResetDialogCancel.bind(this);
		this.handlePasswordResetDialogReset = this.handlePasswordResetDialogReset.bind(this);
		this.dismissBanner = this.dismissBanner.bind(this);
	}

	setUser() {
		if (this.state.addingUser) {
			this.user = this.props.authenticatedUser;
			this.setupUser();
		} else {
			this.fido = new Fido();
			this.fido.fetch(this.props.location.pathname)
				.then((data) => {
					if (data) {
						this.user = data;
						this.setupUser();
					}
				})
				.catch(error => {
					this.setState({fidoError: true});
					return this.props.showSnackbar(error.message);
				});
		}
	}

	setupUser() {
		let formValues = this.state.formValues;
		let {accountId, account_id, id} = this.user;

		if(this.state.addingUser) {
			// If we are adding a user this sets the default activation toggle based on ghosting
			formValues.activated = (!this.user.ghosting) ? true : false;
			formValues.gsuite_data = false;
			formValues.exchange_data = false;
			formValues.tips_data = false;
			formValues.social_media_data = true;
			formValues.application_reports = false;
			formValues.email_reports = false;
			formValues.manage_user = false;
			formValues = Object.assign({}, formValues, {existingUsername: '', existingEmail: '', existingActivated: ''});
		} else {
			formValues = Object.assign({}, formValues, this.user);
		}

		this.setState({formValues: formValues, isLoading: false});
		this.userActivated = this.user.activated;
		this.loadData(account_id || accountId, id);
		this.updateHeader();
	}

	updateHeader() {
		let isMyUser = Number(this.props.match.params.userId) === this.props.authenticatedUser.id;
		let headerOptions = {
			title: (this.state.addingUser) ? 'Add User' : `${(isMyUser ? 'My ' : 'User ')} Profile`
		};
		this.props.updateHeader(headerOptions);
	}

	componentDidUpdate(prevProps, prevState) {
		// if previous id is diff, reload data (ie, editing another user from list, then click Profile to edit yourself)
		if (this.props.match.params.userId !== prevProps.match.params.userId) {
			this.setState({isLoading: true});
			this.setUser();
		}
	}

	componentDidMount() {
		this.updateHeader();

		// Permission check
		// Check 1: User ids match: Editing ourself
		// Check 2: Account ids match and we have permission to manage users: Adding/editing another user
		// Check 3: Account ids don't match but we have corporate write permissions
		if((this.props.authenticatedUser.id === this.state.editingUser) ||
			(this.state.inAccount !== null || !isNaN(this.state.inAccount) &&
				this.props.authenticatedUser.account_id === this.state.inAccount &&
				(this.props.authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.USER))) ||
			(this.props.authenticatedUser.account_id !== this.state.inAccount &&
				this.props.authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.WRITE.CORP))) {

			this.setUser();
		} else {
			this.setState({restricted: true});
		}
	}

	componentWillUnmount() {
		this.sharedFido.dropIt();
		this.userTypesFido.dropIt();
		this.jobTitlesFido.dropIt();
		this.userLocationsFido.dropIt();
		this.accountLocationsFido.dropIt();
		this.props.resetHeader();
	}

	dismissBanner() {
		this.props.showBanner(false);
	}

	loadData(accountId, userId) {
		let {addingUser} = this.state,
			timeZoneFetch = this.sharedFido.fetch('/config/timezones'),
			locationsFetch = this.accountLocationsFido.fetch(`/accounts/${accountId}/locations`),
			userLocationsFetch = this.userLocationsFido.fetch(`/users/${userId}/locations`),
			userTypesFetch = this.userTypesFido.fetch(`/users/${userId}/types`),
			jobTitlesFetch = this.userTypesFido.fetch(`/users/${userId}/titles`),
			promises = addingUser ?
				[timeZoneFetch, locationsFetch, userTypesFetch, jobTitlesFetch] :
				[timeZoneFetch, locationsFetch, userTypesFetch, userLocationsFetch, jobTitlesFetch],
			titlesIndex = addingUser ? 3 : 4;

		Promise.all(promises)
			.then(responses => {
				let update = {};

				if(responses[0]) {
					update.timezones = responses[0];
				}

				if(responses[1]) {
					update.accountLocations = responses[1];
				}

				if(responses[2] && responses[2].length) {
					update.userTypes = responses[2];
				}

				if(!addingUser && responses[3] && responses[3].length) {
					let formValues = this.state.formValues;
					formValues['locations'] = responses[3];
					update.formValues = formValues;
				}

				if(responses[titlesIndex] && Object.keys(responses[titlesIndex]).length) {
					update.jobTitles = responses[titlesIndex];
				}

				if (Object.keys(update).length) {
					this.setState(update);
					this.setState({dataLoading: false});
				}
			})
			.catch(error => {
				console.warn(error);
				// this.props.showSnackbar(error.message);
				this.props.showSnackbar('Unexpected server response. Please try later.');
				return false;
			});
	}

	handleResetClick(event) {
		this.setState({resetPasswordDialogOpen: true});
	}

	handlePasswordResetDialogReset(event) {
		let {id, account_id} = this.user;

		this.setState({resetPasswordDialogOpen: false});

		let poodle = new Fido();
		poodle.fetch(`/accounts/${account_id}/users/${id}/reset-pw`, {
			method: "POST"
		}, 'Resetting...', 'Reset link sent.').then((data) => {
		}).catch(error => this.props.showSnackbar(error.message));
	}

	handlePasswordResetDialogCancel(event) {
		this.setState({resetPasswordDialogOpen: false});
	}

	handleChange(event) {
		let formValues = Object.assign(this.state.formValues, FormHelper.getChanged(event));

		// Adjustments for form defaults
		if (event.target.name === 'user_type_str') {
			// Switching to administrator - all locations selected
			if (formValues.user_type_str === Constants.USER.ROLES.ADMINISTRATOR.NAME &&
				((this.state.addingUser === false && this.user.user_type_str !== Constants.USER.ROLES.ADMINISTRATOR.NAME) || this.state.addingUser === true)) {
				let accountLocationIds = this.state.accountLocations.map((location) => {
					return location.id;
				});
				formValues.locations = accountLocationIds;
			}

			// Switching to team member - uncheck the extra permissions
			if (formValues.user_type_str === Constants.USER.ROLES.TEAM_MEMBER.NAME) {
				formValues.gsuite_data = false;
				formValues.exchange_data = false;
				formValues.tips_data = false;
				formValues.social_media_data = true;
				formValues.application_reports = false;
				formValues.email_reports = false;
				formValues.manage_user = false;
			}
		}

		this.setState({formValues: formValues});
	}

	deleteUserHandler(userId) {
		let poodle = new Fido();
		// Using poodle cause it's a throw away, like an avalanche poodle - don't want to abort call when unmounted
		poodle.fetch("/users/" + userId + "/delete", {
			method: "DELETE"
		}, "Deleting User...", 'User Deleted.')
			// trying to set state when component is not mounted (backed up), causes potential memoery leak.
			// so, since we're backing up, we don't need to set the state.
			.then((data) => {
				this.props.history.push({removed: `?exclude=${data.userId}`});
			})
			.catch(error => this.props.showSnackbar(error.message));

		this.props.history.goBack();
	}

	uploadAvatar(avatarImage) {
		let originalAvatar,
			poodle = new Fido(),
			authenticatedUser = this.props.authenticatedUser,
			isMyUser = Number(this.props.match.params.userId) === this.props.authenticatedUser.id;

		// reload the user when profile pic is updated
		if (isMyUser) {
			originalAvatar = authenticatedUser.avatar;
			authenticatedUser.avatar = avatarImage;
			this.props.updateUser(authenticatedUser);
		}

		// Using poodle cause it's a throw away, like an avalanche poodle - don't want to abort call when unmounted
		poodle.fetch(`/users/${this.user.id}/upload-avatar`, {
			method: "PUT",
			body: JSON.stringify({image: avatarImage})
		}, null, "Saved.")
			// trying to set state when component is not mounted (backed up), causes potential memoery leak.
			// so, since we're backing up, we don't need to set the state.
			.then((data) => {})
			.catch(error => {
				// reload the user when profile pic upload fails
				if (isMyUser) {
					authenticatedUser.avatar = originalAvatar;
					this.props.updateUser(this.props.authenticatedUser);
				}
				this.props.showSnackbar(error.message);
			});
	}

	handleSubmit(formValues) {
		let {accountId, account_id} = this.user,
			successMsg = (this.state.addingUser) ? "User Added." : "Saved.",
			msg = (this.state.addingUser) ? 'Adding User...' : 'Saving Profile...',
			putUrl = (this.state.addingUser) ? '/users/add' : '/users/' + formValues.id;

		formValues.locations = formValues.locations && formValues.locations.split(',') || null;
		formValues.landline = formValues.landline && formValues.landline.replace(/[^0-9]/g, '') || null;
		formValues.cell_phone = formValues.cell_phone && formValues.cell_phone.replace(/[^0-9]/g, '') || null;
		formValues.account = account_id || accountId || null;
		formValues.user_type_str = this.state.formValues.user_type_str;
		formValues.gsuite_data = this.state.formValues.gsuite_data;
		formValues.exchange_data = this.state.formValues.exchange_data;
		formValues.tips_data = this.state.formValues.tips_data;
		formValues.social_media_data = this.state.formValues.social_media_data;
		formValues.application_reports = this.state.formValues.application_reports;
		formValues.manage_user = this.state.formValues.manage_user;
		formValues.email_reports = this.state.formValues.email_reports;

		// User update activation/deactivation check
		/* eslint-disable eqeqeq */
		if (this.state.addingUser === false && this.userActivated != this.state.formValues.activated) {
			if (!this.state.formValues.activated) {
				msg = 'Saving & Deactivating (deactivation may take a few moments)...';
				successMsg = 'User Deactivated.';
				formValues.activateUrl = 'deactivate';
			} else {
				msg = 'Saving & Activating (password setup link emailed to user)...';
				successMsg = 'User Activated.';
				formValues.activateUrl = 'activate';
			}
		}
		/* eslint-enable eqeqeq */

		// User add activation check
		if (this.state.addingUser === true && this.state.formValues.activated) {
			msg = 'Adding & Activating (password setup link emailed to user)...';
			successMsg = 'User Added.';
			formValues.activateUrl = 'activate';
		}

		let poodle = new Fido();
		// Using poodle cause it's a throw away, like an avalanche poodle - don't want to abort call when unmounted
		poodle.fetch(putUrl, {
			method: (this.state.addingUser) ? "POST" : "PUT",
			body: JSON.stringify(formValues)
		}, msg, successMsg)
			// trying to set state when component is not mounted (backed up), causes potential memoery leak.
			// so, since we're backing up, we don't need to set the state.
			.then((data) => {})
			.catch(error => this.props.showSnackbar(error.message));

		this.props.showBanner({
			content: this.state.addingUser ?
				"New users may take a few moments to be fully created." :
				"It may take a few moments for changes to take effect.",
			actions: [
				{text: "Dismiss", handler: () => this.dismissBanner()}
			]
		});

		// if editing another user, go back
		if (this.props.authenticatedUser.id !== this.user.id || this.state.addingUser) {
			this.props.history.goBack();
		} else {
			window.scrollTo({top: 0});
		}
	}

	render() {
		let { authenticatedUser } = this.props,
			{formValues, timezones, resetPasswordDialogOpen,
				accountLocations, userTypes, jobTitles, restricted, isLoading, dataLoading} =  this.state;

		if (restricted) {
			return (
				// Permission denied
				<Redirect to={{
					pathname: "/access"
				}}/>
			);
		} else {
			return (
				<div>
					<UserProfileForm
						isLoading={isLoading}
						dataLoading={dataLoading}
						formValues={formValues}
						timezones={timezones}
						myUser={authenticatedUser}
						emailOnFile={(this.user) ? !!this.user.email : false}
						userTypes={userTypes}
						accountLocations={accountLocations}
						jobTitles={jobTitles}
						handleResetClick={this.handleResetClick}
						handleChange={this.handleChange}
						handleSubmit={this.handleSubmit}
						uploadAvatar={this.uploadAvatar}
						deleteUserHandler={this.deleteUserHandler} />
					<PasswordResetConfirmation
						open={resetPasswordDialogOpen}
						userEmail={(this.user) ? this.user.email : ''}
						handleCancel={this.handlePasswordResetDialogCancel}
						handleReset={this.handlePasswordResetDialogReset} />
				</div>
			);
		}
	}
}

UserProfile.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object,
	resetHeader: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func,
	authenticatedUser: PropTypes.object,
	showBanner: PropTypes.func,
	evictUser: PropTypes.func
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <UserProfile {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "UserProfile";

export default React.forwardRef(dNameFunc);
