import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectValidator } from 'react-material-ui-form-validator';

import DeepMerge from "../utils/DeepMerge";

import {
    withStyles,
    withWidth,
    Select as MUISelect,
    Checkbox,
    ListItemText,
    MenuItem,
    Chip,
    Typography,
    FormHelperText,
    LinearProgress,
    ButtonBase,
    withTheme,
} from '@material-ui/core';

const styles = theme => ({
	// MUISelect complains about the only classes options to pass are root,select,selectMenu,disabled and icon
	// not sure why the merge internally doesn't work correctly, but once we added withStyles it had issues on noValidator selects
	select: {},

	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},

	chip: {
		margin: theme.spacing(.25),
	},

	chipLabel: {
		[theme.breakpoints.up('xs')]: {
			maxWidth: 200
		},

		[theme.breakpoints.up('sm')]: {
			maxWidth: 300
		},

		[theme.breakpoints.up('lg')]: {
			maxWidth: 350
		}
	},

	selectAllItem: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`
	},

	progress: {
		marginTop: theme.spacing(2.3),
		marginBottom: theme.spacing(2.4)
	},

	progressMultiple: {
		marginTop: theme.spacing(2.3),
		marginBottom: theme.spacing(4.6)
	},

	progressWrap: {
		marginTop: theme.spacing(2.2),
		marginBottom: theme.spacing(2.3)
	},

	pill: {
		color: theme.palette.common.white,
		borderRadius: theme.spacing(2),
		paddingLeft: theme.spacing(1),
		background: theme.palette.secondary.slate
	},

	pillIcon: {
		color: theme.palette.common.white
	},

	selectOverride: {
		paddingLeft: theme.spacing(1)
	},

	pillSelect: {
		"&:focus": {
			backgroundColor: "transparent"
		}
	},

	selectButton: {
		justifyContent: "normal",
		maxWidth: "fit-content"
	}
});

class Select extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			selectAll: false
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		if (this.props.multiple && this.props.includeSelectAll) {
			let value = event.target.value,
				index = value.indexOf("select-all"),
				valueAttr = this.props.valueAttr;

			// select all clicked
			if (index !== -1) {
				// OR case, on first load, all are selected but state doesn't reflect it
				if (this.state.selectAll || this.props.allOptions.length === event.target.value.length-1) {
					event.target.value = [];
					this.setState({selectAll: false});
				} else {
					event.target.value = this.props.allOptions.map(opt => {
						return valueAttr ? opt[valueAttr] : opt;
					});
					this.setState({selectAll: true});
				}
			} else {
				this.setState({selectAll: this.props.allOptions.length === event.target.value.length});
			}
		}

		this.props.onChange(event);
	}

	displaySelected(selected) {
		let chips = [],
			{allOptions, valueAttr, labelAttr, label, maxChips, classes, width, useChips, displayNumSelected} = this.props;

		maxChips = width.includes("xs") ? 3 : maxChips; // < sm, 3

		selected.forEach((value, index) => {
			let label,
				currOpt = allOptions.find(opt => {
					let optValue = valueAttr ? opt[valueAttr] : opt;
					return value === optValue;
				});

			if (currOpt) {
				label = labelAttr ? currOpt[labelAttr] : currOpt;

				if (index === maxChips-1) {
					label = `${(selected.length-maxChips)+1} More`;
					chips.push(useChips ? <Chip key={`"trunc"${index}`} className={classes.chip} label={label} /> : label);
				} else if (index < maxChips) {
					chips.push(useChips ? <Chip
						key={value}
						className={classes.chip}
						label={<Typography noWrap classes={{root: classes.chipLabel}}>{label}</Typography>} />
						: label
					);
				}
			}
		});

		return useChips ? <div className={classes.chips}>{chips}</div> :
			displayNumSelected ? `${selected.length} ${label}`: chips.join(", ");
	}

	getMultiChildren(merged) {
		let children = [], // fix warning about children being required, on first couple renders it's undefined
			{allOptions, valueAttr, labelAttr, classes, includeSelectAll, allDisplayValue} = this.props,
			allSelected = /*this.state.selectAll || */allOptions.length === merged.value.length,
			renderValFunc = (selected) => {
				if (allDisplayValue && allSelected) {
					return allDisplayValue;
				}

				return this.displaySelected(selected);
			};

		if (allOptions && allOptions.length) {
			if (this.props.noValidator) {
				merged.renderValue = renderValFunc;
			} else {
				merged.SelectProps.renderValue = renderValFunc;
			}

			// if you are using multi-select, children are overwritten / generated here
			children = allOptions.map(opt => {
				let label = labelAttr ? opt[labelAttr] : opt,
					value = valueAttr ? opt[valueAttr] : opt;

				return (
					<MenuItem button disableTouchRipple focusRipple tabIndex={0} key={value} value={value} className={classes.menuItem}>
						<Checkbox checked={merged.value.includes(value)} color="primary"/>
						<ListItemText primary={label} />
					</MenuItem>
				);
			});

			if (includeSelectAll && children.length) {
				children.unshift(
					<MenuItem button disableTouchRipple focusRipple tabIndex={0} key={"select-all"} value={"select-all"} className={classes.selectAllItem}>
						<Checkbox
							color="primary"
							checked={allSelected} />
						<ListItemText primary={"Select All"} />
					</MenuItem>
				);

				merged.onChange = this.handleChange;
			}
		}

		return children;
	}

	render() {
		let {noValidator, classes, width, children, multiple, includeSelectAll, allOptions, labelAttr, valueAttr,
				useChips, maxChips, isLoading, pill, theme, allDisplayValue, displayNumSelected, ...rest} = this.props,
			baseMenuProps = {
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'center'
				},
				transformOrigin: {
					vertical: 'top',
					horizontal: 'center'
				},
				getContentAnchorEl: null  // otherwise throws an error and doesn't work
			},
			classOverrides = {},
			merged = noValidator ?
				DeepMerge.merge({MenuProps: baseMenuProps, multiple: multiple}, rest) :
				DeepMerge.merge({SelectProps: {MenuProps: baseMenuProps, multiple: multiple}}, rest);

		// NOTE: value property on component must be an Array when multiple select
		if (multiple) {
			children = this.getMultiChildren(merged);
		}

		if (rest.startAdornment) {
			classOverrides.select = `${classes.selectOverride} ${pill === true ? classes.pillSelect : ""}`;
		}

		if (pill === true) {
			classOverrides.icon = classes.pillIcon;
		}

		return (
			<div>
				{!isLoading ?
					<ButtonBase className={classes.selectButton} tabIndex={-1} disableTouchRipple focusRipple>
						{noValidator ?
							<MUISelect
								{...merged}
								autoFocus={false}
								classes={classOverrides}
								className={`${merged.className} ${pill ? classes.pill : null}`}>{children}</MUISelect>
							:
							<SelectValidator {...merged}>{children}</SelectValidator>
						}
					</ButtonBase>
					:
					<div className={classes.progressWrap}>
						{rest.label ?
							<FormHelperText>{rest.label}</FormHelperText>
							: null}
						<LinearProgress className={multiple ? classes.progressMultiple : classes.progress} />
					</div>
				}
			</div>
		);
	}
}

Select.defaultProps = {
	maxChips: 5,
	useChips: true,
	valueAttr: "id",
	labelAttr: "name"
};

Select.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	noValidator: PropTypes.bool,
	onChange: PropTypes.func,
	// multiple select properties
	multiple: PropTypes.bool,
	includeSelectAll: PropTypes.bool,
	label: PropTypes.string,
	allDisplayValue: PropTypes.string,
	allOptions: PropTypes.array,
	labelAttr: PropTypes.string,
	valueAttr: PropTypes.string,
	maxChips: PropTypes.number,
	useChips: PropTypes.bool,
	isLoading: PropTypes.bool,
	pill: PropTypes.bool,
	displayNumSelected: PropTypes.bool
};

export default withStyles(styles)(withWidth()(withTheme(Select)));
