import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		textTransform: "none",
		color: theme.palette.text.secondary,
		"&$disabled": {
			paddingBottom: "0px !important"
		},
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		display: "inline-block",
		justifyContent: "flex-start",
		width: '100%'
	},
	disabled: {},

	label: {
		textAlign: "left"
	},

	name: {
		whiteSpace: 'normal',
		wordWrap: 'break-word'
	}
});

class AuthorLink extends Component {
	render() {
		let {classes, name, sourceHandle, twoLines, link} = this.props,
			isCall = link && link.indexOf("tel:") === 0,
			isEmail = link && link.indexOf("mailto:") === 0,
			target = !(isEmail || isCall) ? "_blank" : "",
			suffix = isCall ? <span><br/>{link.substring(4)}</span> : isEmail ? <span><br/>{link.substring(7)}</span> : null;

		return (
			<Button
				size="small"
				fullWidth={!twoLines}
				href={link}
				target={target}
				disabled={!link}
				classes={{
					root: classes.root,
					disabled: classes.disabled,
					label: classes.label
				}}>
				<b className={classes.name}>{name}</b>{suffix}{twoLines ? <br/> : " "}{sourceHandle}
			</Button>
		);
	}
}


AuthorLink.propTypes = {
	classes: PropTypes.object.isRequired,
	name: PropTypes.string,
	link: PropTypes.string,
	sourceHandle: PropTypes.string,
	twoLines: PropTypes.bool
};

export default withStyles(styles)(AuthorLink);
