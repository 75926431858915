import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import HTMLEntities from "../../utils/HTMLEntities";
import Constants from "../../utils/Constants";
import Timestamp from '../../components/Timestamp';
import ReportItemGridWrapper from "./ReportItemGridWrapper";

class RecentPostsItem extends Component {
	render() {
		let {activities, fidoError, history, buttonClickHandler,
				title, subtitle, taller, itemClickPath, buttonText, noDataMessage} = this.props;

		return (
			<ReportItemGridWrapper
				ready={activities != null}
				fidoError={fidoError}
				title={title}
				subtitle={subtitle}
				taller={taller}
				items={activities}
				history={history}
				itemClickPath={itemClickPath}
				buttonText={buttonText}
				noDataMessage={noDataMessage}
				buttonClickHandler={buttonClickHandler}
				contentRenderer={item => {
					let twitterRedacted = item.compliance_event && item.compliance_event !== Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO;
					return (
						<div>
							{item.content !== "" || twitterRedacted ?
								<Typography noWrap>
									{twitterRedacted ?
										Constants.ACTIVITY.TWITTER_REDACTION.getMessage(item.compliance_event) : HTMLEntities.decode(item.content)}
								</Typography>
								:
								<Typography noWrap style={{fontStyle: "italic"}}>(no content)</Typography>
							}
							<Timestamp time={item.published_at} relative={false} reducedSize={false} />
						</div>
					);
				}} />
		);
	}
}

RecentPostsItem.propTypes = {
	history: PropTypes.object.isRequired,
	fidoError: PropTypes.bool,
	activities: PropTypes.array,
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	subtitle: PropTypes.string,
	taller: PropTypes.bool,
	itemClickPath: PropTypes.string,
	buttonText: PropTypes.string,
	noDataMessage: PropTypes.string,
	buttonClickHandler: PropTypes.func.isRequired
};

export default RecentPostsItem;