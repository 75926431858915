import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from "./Avatar";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import AccountInfoBar from "./AccountInfoBar";

import { withStyles, Card, CardContent, CardActions, Typography, Button, Grid } from '@material-ui/core';

const styles = theme => ({
	card: {
		borderRadius: 0,
		minWidth: 250,
		maxWidth: 250,
		backgroundColor: theme.palette.primary.darkGrey,
		[theme.breakpoints.up('lg')]: {
			backgroundColor: theme.palette.common.white
		}
	},

	avatarWrapper: {
		display: "flex",
		justifyContent: "center"
	},

	typography: {
		color: theme.palette.primary.tan
	},

	typographyWrapper: {
		paddingTop: theme.spacing(2)
	},

	actions: {
		display: "flex",
		paddingTop: 0,
		justifyContent: "center"
	},

	content: {
		paddingBottom: `0px !important`
	},

	button: {
		textTransform: "none"
	},

	infoGridItem: {
		maxWidth: 175
	}
});

class ProfileCard extends Component {
	constructor(props, context) {
		super(props, context);
		this.profileClickHandler = this.profileClickHandler.bind(this);
	}

	profileClickHandler(basePath) {
		if (this.props.closeHandler) {
			this.props.closeHandler();
		}
		this.props.history.push(`${basePath}/edit`);
	}

	render() {
		let {classes, typographyColor, avatarSizeFactor} = this.props,
			isDefaultTypography = typographyColor === "initial";

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
						<Card elevation={0} className={`${classes.card}`}>
							<CardContent className={classes.content}>
								<Grid
									container
									wrap="nowrap"
									justify="flex-start"
									alignItems="center"
									direction={"column"}>
									<Grid item className={classes.avatarWrapper}>
										<Avatar
											sizeFactor={avatarSizeFactor || 2}
											authorName={authenticatedUser.name}
											authorImage={authenticatedUser.avatar} />
									</Grid>
									<Grid item className={`${classes.infoGridItem} ${classes.typographyWrapper}`}>
										<Typography
											align="center"
											className={isDefaultTypography ? null : classes.typography}>{authenticatedUser.name}</Typography>
										<Typography
											noWrap
											align="center"
											variant="caption"
											className={isDefaultTypography ? null : classes.typography}>{authenticatedUser.title}</Typography>
										<CardActions className={classes.actions}>
											<Button
												color="primary"
												className={classes.button}
												onClick={() => this.profileClickHandler(authenticatedUser.profileUrlBase)}>Manage Your Profile</Button>
										</CardActions>
									</Grid>
								</Grid>
							</CardContent>
							<AccountInfoBar
								accountName={authenticatedUser.accountName}
								accountId={authenticatedUser.ghosting ? authenticatedUser.accountId : null} />
						</Card>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

ProfileCard.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	closeHandler: PropTypes.func,
	typographyColor: PropTypes.string,
	avatarSizeFactor: PropTypes.number
};

export default withStyles(styles)(ProfileCard);
