import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import FilterSelect from "../components/FilterSelect";
import Moment from 'react-moment';
import ReactPlaceholder from 'react-placeholder';
import {TextBlock} from 'react-placeholder/lib/placeholders';

import InfiniteScroll from 'react-infinite-scroller';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Media from 'react-media';

import Fido from '../utils/Fido';
import PaddedLayout from './PaddedLayout.js';
import SearchForm from '../components/SearchForm.js';
import FullscreenMessage from '../components/FullscreenMessage';
import Constants from "../utils/Constants";
import LoadingIndicator from "../components/LoadingIndicator";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

import {
	withStyles,
	withWidth,
	Fab,
	Card,
	CardActionArea,
	CardContent,
	Paper,
	Grid,
	Typography,
	withTheme,
} from '@material-ui/core';

const styles = theme => ({
	eventGridContainer: {
		// The following style is taken from the class MuiTGrid-container
		// Also adding spacing={2} which comes from MuiGrid-spacing
		display: "flex",
		flexWrap: "wrap",
		boxSizing: "border-box",
		width: "calc(100% + 16px)",
		margin: theme.spacing(-1)
	},

	eventGridItem: {
		// The following styles are taken from the class MuiGrid-spacing > MuiGrid-item
		padding: theme.spacing(1)
	},

	title: {
		fontSize: "1.5em",
		fontWeight: "bold",
		marginTop: 0,
		lineHeight: "1.75em",
		paddingBottom: theme.spacing(.5)
	},

	map: {
		maxWidth: "100%",
		height: "auto",
		display: "block"
	},

	card: {
		maxWidth: 610,
		margin: "0 auto"
	},

	cardAction: {
		maxWidth: 610
	},

	cardContent: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},

	cardDates: {
		fontSize: "1rem",
		paddingBottom: theme.spacing(.5)
	},

	statusCell: {
		[theme.breakpoints.down('xs')]: {
			paddingLeft: 6
		}
	},

	statusIcon: {
		width: "0.55rem !important",
		paddingRight: theme.spacing(1)
	},

	statusActive: {
		color: theme.palette.primary.mediumGreen
	},

	statusInactive: {
		color: theme.palette.text.secondary
	},

	fab: {
		zIndex: 1,
		position: "fixed",
		left: theme.spacing(34),
		color: theme.palette.common.white,

		[theme.breakpoints.down('sm')]: {
			left: theme.spacing(1.5)
		}
	},

	fabExtendedIcon: {
		marginRight: theme.spacing(1)
	},

	paddedLayout: {
		paddingBottom: theme.spacing(3)
	},

	searchForm: {
		paddingRight: theme.spacing(2)
	},

	statusFilter: {
		marginTop: theme.spacing(2.5),
		marginRight: theme.spacing(2)
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const itemPlaceHolder = (
	<div>
		<Paper style={{padding: 10, marginTop: 10, maxWidth: 610, margin: "0 auto"}}>
			<div style={{display: "flex", marginTop: 25, flexDirection: "column"}}>
				<div style={{flex: 1}}>
					<TextBlock rows={2} color={placeholderColor} style={{width: "95%"}} />
				</div>
				<div style={{flex: 1, marginTop: 10}}>
					<TextBlock rows={2} color={placeholderColor} style={{width: "100%"}} />
				</div>
			</div>
		</Paper>
	</div>
);

class Events extends Component {
	constructor(props) {
		super(props);

		this.defaultStatuses = [{id: "active", name: "Active"}, {id: "future", name: "Future"}];
		let status = Fido.getSearchParam("status", this.props.location.search);

		this.state = {
			hasMoreItems: true,
			isLoading: false,
			order: 'asc',
			orderBy: 'name',
			fullEvents: [],
			entities: null,
			fidoError: false,
			loadPage: 2,
			selectedEvent: null,
			searchActive: false,
			allEvents: false,
			filter: {
				selectedStatuses: status ? status.split(",").map(item => {
					return {id: item, name: item.charAt(0).toUpperCase()};
				}) : this.defaultStatuses
			},
			statuses: Constants.EVENT.STATUSES
		};

		this.searchHandler = this.searchHandler.bind(this);
		this.clearHandler = this.clearHandler.bind(this);
		this.loadItems = this.loadItems.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleExclusions = this.handleExclusions.bind(this);
		this.filterEventStatus = this.filterEventStatus.bind(this);
		this.shouldReset = this.shouldReset.bind(this);

		this.fido = new Fido();
	}

	componentDidMount() {
		this.setupFilters();
		this.props.updateHeader({title: "events", primary: true});
	}

	componentWillUnmount() {
		this.fido.dropIt();
		this.props.resetHeader();
	}

	componentDidUpdate(prevProps, prevState) {
		this.handleExclusions(this.props.history);

		// This allows the tabs to act properly when using the drawer nav
		if(prevProps.location.search !== this.props.location.search) {
			this.setupFilters();
		}

		// Edge case where the window is able to hold the entire first set of results without a scroll bar
		// This loads more pages until the area is scrollable.
		if ((document.body.clientHeight < window.innerHeight) && this.state.entities && this.state.entities.length > 0 && this.state.fullEvents.length > 0 && this.state.hasMoreItems && !this.state.isLoading) {
			let page = this.state.loadPage;
			this.loadItems(page);
			this.setState({loadPage: page + 1});
		}
	}

	shouldReset(prevProps) {
		if (this.props.location.search === "" && prevProps.location.search !== "") {
			this.setupFilters();
			return true;
		}

		return false;
	}

	fetchData(opts) {
		let {filter} = this.state,
			url = `/events?type=1`;

		if (filter.selectedStatuses && filter.selectedStatuses.length) {
			url = `${url}&status=${filter.selectedStatuses.join(",")}`;
		}

		this.fido.fetch(url, opts)
			.then((data) => {
				if (data) {
					// Separate state to hold the full event set for use in search
					data.fullEvents = data.entities;
					this.setState(data);
				}
			})
			.catch(this.handleEventsError);
	}

	loadItems(page) {
		// Check to make sure there is more to load
		if(this.state.hasMoreItems === true) {
			// Set a loading flag so we don't double-load
			this.setState({
				isLoading: true
			});

			// Edge case (above) causes the 'pageStart' prop to be incorrect
			if(this.state.loadPage > 2) {
				page = this.state.loadPage;
			}

			let {filter} = this.state,
				url = `/events?type=1&page=${page}`;

			if (filter.selectedStatuses && filter.selectedStatuses.length) {
				url = `${url}&status=${filter.selectedStatuses.join(",")}`;
			}

			// Get the next page of data from the paginator
			this.fido.fetch(url)
				.then((data) => {
					if(data) {
						var entities = this.state.entities;
						data.entities.map((event) =>
							entities.push(event)
						);

						let update = {
							entities: entities,
							isLoading: false
						};
						if(this.state.loadPage > 2) {
							update.loadPage = page + 1;
						}
						if(!data.hasMoreItems) {
							update.hasMoreItems = false;
						}

						this.setState(update);
					}
				})
				.catch(error => this.props.showSnackbar(error.message));
		} else {
			this.setState({allEvents: true});
		}
	}

	searchHandler(formVals) {
		let {fullEvents, allEvents, filter} = this.state;

		this.setState({searchActive: true, hasMoreItems: false});
		this.fido.fetch(this.props.location.pathname, {
			method: "POST",
			body: JSON.stringify({
				type: Constants.ENTITIES.IDS.EVENT,
				events: fullEvents,
				search: formVals.search,
				allEvents: allEvents,
				status: Array.isArray(filter.selectedStatuses) ? filter.selectedStatuses.join(",") : filter.selectedStatuses
			})
		})
			.then(this.handleEventsUpdate)
			.catch(this.handleEventsError);
	}

	clearHandler() {
		this.setState({entities: this.state.fullEvents, searchActive: false});
	}

	handleEventsUpdate = (data) => {
		if (data) {
			this.setState({entities: data});
		}
	}

	handleEventsError = (error) => {
		this.setState({fidoError: true});
		this.props.showSnackbar(error.message);
	}

	handleRowClick(event) {
		this.setState({selectedEvent: event});
	}

	handleExclusions(history) {
		let removed;
		// defining here so don't need to pass in dependencies that are in scope in this func
		let updateRemovedParameter = function(removeIndex) {
			if(removeIndex > -1) {
				// update search param
				removed.splice(removeIndex, 1);
				let removedParams = "";
				if(removed.length) {
					removedParams = '?' + removed.join("&");
				}
				history.push({
					removed: removedParams
				});
			}
		};

		if("event" in history && history.event.removed) {
			removed = history.event.removed.slice(1).split('&');
			let excludeIndex = removed.findIndex(query => query.indexOf('exclude') > -1);
			if(excludeIndex > -1) {
				let excludedId = removed[excludeIndex].split('=')[1];
				this.filterEvent(excludedId);
				updateRemovedParameter(excludeIndex, removed);
			}
		}
	}

	filterEvent(eventId) {
		let entities = this.state.entities;
		if(entities) {
			/* eslint-disable eqeqeq */
			entities = entities.filter(event => event.id != eventId);
			/* eslint-enable eqeqeq */
			this.setState({entities: entities});
		}
	}

	getFiltersFromUrl() {
		return {
			status: Fido.getSearchParam("status", this.props.location.search)
		};
	}

	setupFilters() {
		let filters = this.getFiltersFromUrl(),
			filtersStatus = filters.status || this.defaultStatuses.map(s => s.id).join(",");

		this.setState({
			filter: {
				selectedStatuses: filtersStatus.split(",")
			}
		}, () => {
			this.fetchData();
		});
	}

	filterEventStatus(event) {
		this.setState({
			statusFilterOpen: false,
			filter: {
				selectedStatuses: event.target.value.length ? event.target.value : this.state.statuses.map(item => item.id)
			}
		}, () => {
			let url = "",
				{filter} = this.state,
				status = Array.isArray(filter.selectedStatuses) ? filter.selectedStatuses.join(",") : filter.selectedStatuses;

			if (filter.selectedStatuses && filter.selectedStatuses.length) {
				url = `/events?type=${Constants.ENTITIES.IDS.EVENT}&status=${status}`;
			}

			this.props.history.push(url);
		});
	}

	render() {
		let { entities, selectedEvent, statuses, hasMoreItems, isLoading, fidoError, filter } = this.state;
		let { classes, theme, width, banner, bannerHeight, location, signalsFirstEnabled } = this.props,
			style,
			fabTop,
			fabClassName,
			fabClassNames = [classes.fab],
			firstLoaded = this.state.entities !== null,
			cardSizing = {xs:12, sm:4, md:4, lg:4, xl:3};

		fabTop = (banner && bannerHeight ? bannerHeight : 0) +
			(signalsFirstEnabled ? (width.includes("md") ?
				theme.spacing(15.625) : width.includes("sm") ?
					theme.spacing(11.375) : width.includes("xs") ? theme.spacing(15.875) : theme.spacing(9)) : 0) +
			((width.includes("xs") ? 8.375 : 10) * theme.spacing(1));
		style = {top: fabTop};

		fabClassName = clsx(fabClassNames);

		if (selectedEvent) {
			return (
				// don't overwrite browser stack, push=true
				<Redirect push to={{
					pathname: `/events/${selectedEvent.id}/edit`
				}}/>
			);
		} else {
			return (
				<AuthorizedUserContext.Consumer>
					{authenticatedUser => {
						return (
							<PaddedLayout
								noTopPad
								isLarge
								className={classes.paddedLayout}>
								<Grid container justify="flex-start">
									<Grid item>
										<Link to={`/events/add`} tabIndex={-1}>
											<Media query={{minWidth: theme.breakpoints.values.sm}}>
												{matches =>
													matches ? (
														<Fab
															variant="extended"
															color="primary"
															aria-label="Add Event"
															className={fabClassName}
															style={style}>
															<FontAwesomeIcon
																title="add"
																icon={["far", "plus"]}
																className={classes.fabExtendedIcon} /> Add Event
														</Fab>
													) : (
														<Fab
															color="primary"
															aria-label="Add Event"
															className={fabClassName}
															style={style}>
															<FontAwesomeIcon title="add" icon={["far", "plus"]} />
														</Fab>
													)
												}
											</Media>
										</Link>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container justify="flex-end">
											<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>&nbsp;</Grid>
											<Grid item xs={5}>
												<SearchForm
													formClass={classes.searchForm}
													searchHandler={this.searchHandler}
													clearHandler={this.clearHandler} />
											</Grid>
											<Grid item>
												<FilterSelect
													includeSelectAll
													menuTitle="Status"
													selectAllTitle="All Statuses"
													options={statuses}
													location={location}
													shouldReset={this.shouldReset}
													resetValue={this.defaultStatuses}
													defaultSelections={filter.selectedStatuses}
													className={classes.statusFilter}
													handleUpdate={this.filterEventStatus} />
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								{firstLoaded ? (
									entities && entities.length ? (
										<React.Fragment>
											<InfiniteScroll
												// Classes added on this mock that of a Grid container
												className={classes.eventGridContainer}
												initialLoad={false}
												pageStart={1}
												loader={null}
												loadMore={this.loadItems}
												hasMore={hasMoreItems && !isLoading}>
												{entities.map(n => {
													let oneDay = (n.start_date === n.end_date) ? true : false,
														firstLocation = (n.locations.length > 0) ? n.locations[0] : null,
														twoLocations = (firstLocation && n.locations.length === 2) ? 1 : null,
														moreLocations = (firstLocation && n.locations.length > 2) ? n.locations.length - 1 : null;

													return (
														<Grid className={classes.eventGridItem} key={n.id} item {...cardSizing}>
															<Card className={classes.card}>
																<CardActionArea className={classes.cardAction} onClick={() => this.handleRowClick(n)}>
																	<CardContent className={classes.cardContent}>
																		<Typography className={classes.cardDates} variant="body1">
																			<Moment format={"MMM Do"}>{n.start_date}</Moment>
																			{!oneDay ? (
																				<span>
																					&nbsp;-&nbsp;
																					<Moment format={"MMM Do"}>{n.end_date}</Moment>
																				</span>
																			) : null}
																		</Typography>
																		<Typography variant="h2" noWrap className={classes.title}>
																			{n.name}
																		</Typography>
																		<Typography noWrap color="textSecondary" variant="caption" component="p">
																			{firstLocation}
																		</Typography>
																		{!twoLocations && !moreLocations ? (
																			<Typography noWrap color="textSecondary" variant="caption">&nbsp;</Typography>
																		) : null}
																		{twoLocations ? (
																			<Typography noWrap color="textSecondary" variant="caption">{twoLocations} additonal location
																			</Typography>
																		) : null}
																		{moreLocations ? (
																			<Typography noWrap color="textSecondary" variant="caption">{moreLocations} additional locations
																			</Typography>
																		) : null}
																	</CardContent>
																</CardActionArea>
															</Card>
														</Grid>
													);
												})}
											</InfiniteScroll>
											{isLoading ?
												<LoadingIndicator /> : null
											}
										</React.Fragment>
									) : (
										<FullscreenMessage message="No Results" />
									)
								) : (
									<div className={classes.eventGridContainer}>
										<Grid className={classes.eventGridItem} item {...cardSizing}>
											<ReactPlaceholder
												ready={false}
												customPlaceholder={itemPlaceHolder}
												showLoadingAnimation={!fidoError}>
												<span></span>
											</ReactPlaceholder>
										</Grid>
										<Grid className={classes.eventGridItem} item {...cardSizing}>
											<ReactPlaceholder
												ready={false}
												customPlaceholder={itemPlaceHolder}
												showLoadingAnimation={!fidoError}>
												<span></span>
											</ReactPlaceholder>
										</Grid>
										<Grid className={classes.eventGridItem} item {...cardSizing}>
											<ReactPlaceholder
												ready={false}
												customPlaceholder={itemPlaceHolder}
												showLoadingAnimation={!fidoError}>
												<span></span>
											</ReactPlaceholder>
										</Grid>
									</div>
								)}
							</PaddedLayout>
						);
					}}
				</AuthorizedUserContext.Consumer>
			);
		}
	}
}

Events.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	snackbarOpen: PropTypes.bool,
	showSnackbar: PropTypes.func,
	resetHeader: PropTypes.func,
	updateHeader: PropTypes.func,
	banner: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	bannerHeight: PropTypes.number,
	signalsFirstEnabled: PropTypes.bool
};

export default withStyles(styles)(withWidth()(withTheme(Events)));
