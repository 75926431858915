import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import BackMap from "./assets/us-map.svg";

import 'system-font-css/system-font.css';
import "react-placeholder/lib/reactPlaceholder.css";
import './index.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './utils/GoogleAnalyticsSetup';
import Constants from "./utils/Constants";
import AuthorizedUser from "./utils/AuthorizedUser";

import { MuiThemeProvider, withStyles, createMuiTheme } from '@material-ui/core';

// If access to ga() is required elsewhere, use this version and access as window.spa.ga()
// import ReactGA from './utils/GoogleAnalyticsSetup';
// window.spa.ga = ReactGA.ga();

const moreContrast = AuthorizedUser.wantsMoreContrast();
const theme = createMuiTheme({
	breakpoints: {
		values: {
			"xs": 0,
			"sm": 768, // Portrait Tablet (iPad)
			"md": 992,
			"lg": 1024, // Landscape Tablet (iPad) / Laptop
			"xl": 1440, // Desktop+
		}
	},

	typography: {
		fontSize: 16,
		// Fantasy font for finding text not handled by mui
		// "fontFamily": "fantasy",

		// "system-ui" didn't work here because relies on postcss to process the attached css file & substitute in the
		// font listing which doesn't happen in JSS. Simple hack is to put in separate css, import, & let postcss handle.
		// Need to specify inherit here so MUI picks up system-ui from index.css. Benefit of the complexity is don't
		// need to maintain the families - should update as part of npm updates
		fontFamily: "inherit",
		// Hardcodable equivalent to what system-ui currently does
		// "fontFamily": "-apple-system, BlinkMacSystemFont, \“Segoe UI\”, \“Roboto\”, \“Oxygen\”, \“Ubuntu\”, \“Cantarell\”, \“Fira Sans\”, \“Droid Sans\”, \“Helvetica Neue\”, sans-serif;"
	},

	palette:{
		// SSI Brand Color Palette
		primary: {
			main: moreContrast ? Constants.COLORS.ACCESSIBILITY.MAIN : Constants.COLORS.PRIMARY.MAIN,
			tan: Constants.COLORS.PRIMARY.TAN,
			lightTan: "#efeee8", // not in brand guidelines
			brightGreen: Constants.COLORS.PRIMARY.BRIGHT_GREEN,
			mediumGreen: Constants.COLORS.PRIMARY.MEDIUM_GREEN,
			darkGreen: Constants.COLORS.PRIMARY.DARK_GREEN,
			darkGrey: Constants.COLORS.PRIMARY.DARK_GREY
		},

		secondary: {
			main: Constants.COLORS.SECONDARY.SLATE,
			blue: Constants.COLORS.SECONDARY.BLUE,
			yellow: Constants.COLORS.SECONDARY.YELLOW,
			orange: Constants.COLORS.SECONDARY.ORANGE,
			purple: Constants.COLORS.SECONDARY.PURPLE,
			slate: Constants.COLORS.SECONDARY.SLATE
		},

		text: {
			primary: Constants.COLORS.PRIMARY.DARK_GREY
		},

		accessibilityColors: {
			text: {
				secondary: Constants.COLORS.ACCESSIBILITY.SECONDARY_TEXT
			}
		}
	},

	overrides: {
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: "#fff",
				borderBottom: "1px solid #ccc"
			},

			colorSecondary: {
				backgroundColor: Constants.COLORS.PRIMARY.DARK_GREY,
				borderBottom: `1px solid ${Constants.COLORS.PRIMARY.DARK_GREY}`
			}
		},

		MuiDrawer: {
			paper: {
				backgroundColor: "#fff"
			}
		},

		MuiMenuItem: {
			root: {
				color: Constants.COLORS.PRIMARY.DARK_GREY,
				minHeight: "48px !important"
			}
		},

		MuiListItem: {
			root: {
				"&$disabled": {
					opacity: 1
				}
			}
		},

		MuiButton: {
			root: {
				fontSize: "0.9rem",
				fontWeight: 350,

				"&$disabled": {
					color: "rgba(0, 0, 0, 0.54)" // TODO: how do we use the default theme values?
				}
			},

			outlined: {
				borderRadius: 24
			},

			contained: {
				borderRadius: 24,
				boxShadow: "none"
			},

			containedPrimary: {
				color: "#fff"
			},

			text: {
				textTransform: "capitalize"
			}
		},

		MuiIconButton: {
			root: {
				// TEMP: component library changed padding to 12 for some reason
				// https://github.com/mui-org/material-ui/issues/13385
				width: 48,
				height: 48,
				padding: 0
			}
		},

		MuiCheckbox: {
			root: {
				width: "initial",
				height: "initial",
				marginRight: 8
			}
		},

		// due to the MuiIconButton width / height fix above
		MuiSwitch: {
			switchBase: {
				width: "initial",
				height: "initial"
			}
		},

		MuiTooltip: {
			tooltip: {
				color: "#fff",
				padding: 10,
				borderRadius: 3,
				position: "relative",
				backgroundColor: Constants.COLORS.SECONDARY.SLATE,

				// arrow
				"&:after": {
					content: "no-close-quote",
					position: "absolute",
					bottom: 0, /* At the bottom of the tooltip */
					left: "45%",
					marginBottom: "-0.75rem",
					borderWidth: 6,
					borderStyle: "solid",
					borderColor: `${Constants.COLORS.SECONDARY.SLATE} transparent transparent transparent`
				}
			}
		},

		MuiLink: {
			underlineNone: {
				textDecoration: `${moreContrast ? "underline" : "none"}`
			},

			underlineHover: {
				textDecoration: `${moreContrast ? "underline" : "none"}`,

				"&:hover": {
					textDecoration: "underline"
				}
			}
		},

		// Overrides not needed for all & not all of these are used; in cases where local styling applies
		// these will not be used. Currently only know of h3 that falls back on these for overview sections
		MuiTypography: {
			h1: {
				lineHeight: 1.2,
				fontSize: "1.45rem"
			},

			h2: {
				fontSize: "1.30rem"
			},

			h3: {
				fontSize: "1.15rem"
			},

			h4: {
				fontSize: "1rem",
				fontWeight: 500
			},

			caption: {
				display: 'block'
			},

			body1: {
				fontSize: "1rem"
			}
		},

		/*
			This is for accessibility, so it works off the shelf without having to actually show helperText (which we rarely use)
			If you want helperText to show on a field in a form, you need to do this:
			FormHelperTextProps={{
				classes: {
					root: classes.show
				}
			}}
			Or, apply an appropriate class to the appropriate element to show it
		*/
		MuiFormHelperText: {
			root: {
				display: "none",
				"&$error": {
					display: "block"
				}
			}
		},

		// Darken the placeholder text when in high contrast mode
		MuiInputBase: {
			input: {
				"&$disabled": moreContrast ? {
					color: Constants.COLORS.ACCESSIBILITY.FORM_LABELS
				} : {},

				"&::placeholder": moreContrast ? {
					opacity: 1,
					color: Constants.COLORS.ACCESSIBILITY.SECONDARY_TEXT
				} : {},

				/*
					Internet Explorer 10–11
					Internet Explorer Mobile 10-11
				*/
				"&:ms-input-placeholder": moreContrast ? {
					opacity: 1,
					color: Constants.COLORS.ACCESSIBILITY.SECONDARY_TEXT
				} : {},

				/* Edge (also supports ::-webkit-input-placeholder) */
				"&::ms-input-placeholder": moreContrast ? {
					opacity: 1,
					color: Constants.COLORS.ACCESSIBILITY.SECONDARY_TEXT
				} : {}
			}
		},

		MuiFormLabel: {
			root: moreContrast ? {
				"&$disabled": {
					color: Constants.COLORS.ACCESSIBILITY.FORM_LABELS
				}
			} : {}
		},

		MuiTableHead: {
			root: {
				textTransform: "uppercase"
			}
		}
	},

	drawer: {
		width: 250
	}
});

// global styles with unmutating names; please limit usage
// TODO - goal is that this never get too big to fit in here... ;/ but separate when it does as a JSON object!
const styles = {
	'@global': {
		html: {
			fontSize: theme.typography.fontSize,
			[theme.breakpoints.up('lg')]: {
				/* base font for "desktop" / landscape tablets */
				fontSize: (0.875 * theme.typography.fontSize),
			}
		},

		body: {
			margin: 0,
			padding: 0,
			color: theme.palette.text.primary,
			fontSize: "1rem",
			backgroundColor: theme.palette.background.default,

			"&.login": {
				"&:after": {
					content: "no-close-quote",
					background: 'url("'+BackMap+'") no-repeat center',
					backgroundSize: 'cover',
					[theme.breakpoints.up('sm')]: {
						backgroundSize: '120%'
					},
					[theme.breakpoints.up('md')]: {
						backgroundSize: '110%'
					},
					[theme.breakpoints.up('lg')]: {
						backgroundSize: '98%'
					},
					opacity: 0.1,
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "fixed",
					zIndex: -1
				},
				background: `linear-gradient(90deg, ${Constants.COLORS.PRIMARY.TAN} 0%, #D5D2C4 100%) no-repeat fixed`,
				backgroundSize: "cover"
			}
		},

		a: {
			color: theme.palette.text.primary,
			textDecoration: `${moreContrast ? "underline" : "none"}`,

			'&.primary': {
				color: theme.palette.primary.main,
			},

			'&.secondary': {
				color: theme.palette.secondary.main,
			}
		},

		// react-placeholder overrides
		".rect-shape, .text-row": {
			borderRadius: "3px"
		},

		".show-loading-animation.rect-shape, .show-loading-animation.round-shape, .show-loading-animation.text-row, .show-loading-animation .rect-shape, .show-loading-animation .round-shape, .show-loading-animation .text-row": {
			animationName: "phaseAnimation",
			animationIterationCount: "infinite",
			backgroundPosition: "0 0",
			backgroundRepeat: "no-repeat",
			//animationDuration: "1s",
			//backgroundSize: "200% 100%",
			//background: "gradient(linear,left top,right top,from(#eee),to(#cdcdcd),color-stop(.33,#eee))"
			animationTimingFunction: "linear",
			backgroundSize: "500% 100%",
			animationDuration: "1.5s",
			background: "linear-gradient(to right, #ddd 8%, #ccc 18%, #ddd 33%)"
		},

		"@keyframes phaseAnimation": {
			"0%": {
				backgroundPosition: "top right"
			},

			"100%": {
				backgroundPosition: "top left"
			}
		},

		// exporting module / accessibility
		".highcharts-contextbutton": {
			"& > .highcharts-button-box": {
				fill: "transparent",
				/*rx: 15,
				ry: 15,
				x: -0.8,
				y: -0.8,
				width: 27,
				height: 27,
				stroke: theme.palette.common.white,*/
				background: theme.palette.primary.darkGrey
			},

			"& > .highcharts-button-symbol": {
				strokeWidth: 2,
				stroke: theme.palette.primary.darkGrey
			},

			"&.highchart-dark-theme": {
				"& > .highcharts-button-box": {
					background: theme.palette.common.white
				},

				"& > .highcharts-button-symbol": {
					stroke: theme.palette.common.white
				}
			}
		},

		".highcharts-data-table": {
			width: "100%",
			paddingTop: theme.spacing(1),
			backgroundColor: theme.palette.grey[400],

			"& .highcharts-table-caption": {
				fontWeight: "bold",
				fontSize: "1.2rem",
				paddingBottom: theme.spacing(1)
			},

			"& > table": {
				width: "100%",
				textAlign: "left",
				backgroundColor: theme.palette.background.default,

				"& > tbody": {
					"& > tr": {
						"&  > th": {
							fontWeight: "normal"
						}
					}
				}
			}
		}

		// commenting out b/c only used in one component, but leaving as an example
		// ".responsiveGridContainer": {
		// 	[theme.breakpoints.up('sm')]: {
		// 		padding: theme.spacing(1),
		// 	},
		// },
	}
};

const AppRoot = withStyles(styles)((props) => {
	return (
		<MuiThemeProvider theme={theme}>
			<BrowserRouter basename={`/${Constants.SPA_PATH}`}>
				<App />
			</BrowserRouter>
		</MuiThemeProvider>
	);
});

ReactDOM.render(<AppRoot />, document.getElementById('root'));

registerServiceWorker();
