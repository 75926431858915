import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, withStyles } from "@material-ui/core";

import Constants from "../utils/Constants";

const styles = theme => ({
	author: {
		fontSize: "0.9rem",
		color: theme.palette.text.secondary
	}
});

class MultipleAuthors extends Component {
	getTooltip() {
		let authorArray = this.props.authors.split(",");
		return <div>{authorArray.map((author, idx) => <div key={idx}>{author}</div>)}</div>;
	}

	render() {
		let xMore,
			numberToShow = 2,
			{classes, authors} = this.props,
			authorArray = authors.split(",");

		return (
			<Tooltip
				placement="top-start"
				title={this.getTooltip()}>
				<div style={{lineHeight: 1.5}}>
					{authorArray.map((author, idx) => {
						if (idx < numberToShow) {
							let match = author.match(Constants.ACTIVITY.EMAIL.FROM_REGEX);
							// 0 is the full string, 1 is name, 2 is email
							return <div key={idx} className={classes.author}><b>{match[1]}</b>&nbsp;{match[2]}</div>;
						} else if (idx === numberToShow) {
							xMore = authorArray.length - numberToShow;
							return <div key={idx} className={classes.author}>{xMore} more author{xMore > 1 ? "s" : ""}</div>;
						}

						return "";
					})}
				</div>
			</Tooltip>
		);
	}
}

MultipleAuthors.propTypes = {
	classes: PropTypes.object.isRequired,
	authors: PropTypes.string
};

export default withStyles(styles)(MultipleAuthors);