import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import MomentCore from 'moment';
import Highchart from "./Highchart";

import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import DetailedReport from "../containers/DetailedReport";
import { withStyles, Paper } from '@material-ui/core';

//import {dashboardData} from "../../data/reports";

const styles = theme => ({
	root: {
		minHeight: 480,
		[theme.breakpoints.down('xs')]: {
			minHeight: 450
		}
	}
});

class EmailsAndAlertsReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {theme, chartClasses, previousDays} = this.props;
		this.state = {
			chartConfig: HighchartsConfig.merge({
				chart: {
					className: chartClasses.chart,
					marginTop: theme.spacing(6)
				},
				title: {
					align: "left",
					text: "Emails Scanned vs Alerts"
				},
				legend: {
					enabled: true
				},
				tooltip: {
					shared: true,
					formatter: function() {
						let date = new MomentCore(this.x);
						const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 0});
						return this.points.reduce(function (s, point) {
							return `${s}<br/><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${numFormatter.format(point.y)}</b><br/>`;
						}, `<span style='font-weight: bold'>${date.format("MMM D")}</span><br/>`);
					},
				},
				accessibility: {
					description: `The number of emails scanned per day over the last ${previousDays} days, as well as the number of alerts that were sent per day over the last ${previousDays} days.`
				},
				yAxis: [{
					title: {
						text: "# of emails",
						style: {
							fontSize: "15px"
						}
					},
					min: 0,
					startOnTick: false,
					endOnTick: false,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					labels: {
						style: {
							fontSize: "15px"
						}
					}
				},{
					title: {
						text: "# of alerts",
						style: {
							fontSize: "15px"
						}
					},
					min: 0,
					startOnTick: false,
					endOnTick: false,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					opposite: true,
					labels: {
						style: {
							fontSize: "15px"
						}
					}
				}],
				xAxis: {
					type: "datetime",
					tickWidth: 0,
					offset: 20,
					labels: {
						y: 35,
						rotation: -45,
						align: "center",
						style: {
							fontSize: "15px"
						}
					},
					style: {
						color: theme.palette.primary.darkGrey
					}
				},
				plotOptions: {
					line: {
						lineWidth: theme.spacing(1),
						softThreshold: false,
						marker: {
							radius: theme.spacing(1)
						}
					},
					column: {
						borderColor: theme.palette.secondary.yellow
					}
				},
				series: [{
					type: "column",
					name: "Emails",
					color: theme.palette.secondary.yellow
				},{
					type: "spline",
					name: "Alerts",
					yAxis: 1,
					color: theme.palette.secondary.slate
				}]
			}, {
				modifyTickInterval: true,
				previousDays: previousDays
			})
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.emails !== this.props.emails) {
			let barClass,
				{chartConfig} = this.state,
				{emails, alerts, chartClasses, previousDays} = this.props;

			if (emails !== null) {
				// TEMP
				//data = dashboardData;
				ReportDataMasseuse.convertDatesToMillis(emails);
				ReportDataMasseuse.convertDatesToMillis(alerts);

				chartConfig.series[0].data = ReportDataMasseuse.massageData(emails, "period", "associations");
				chartConfig.series[1].data = ReportDataMasseuse.massageData(alerts, "period", "alerts");

				barClass = previousDays > 30 ? chartClasses.basicColumn : `${chartClasses[previousDays+"dayColumn"]}`;
				chartConfig.series[0].className = barClass;
				chartConfig.series[1].className = barClass;
			} else {
				chartConfig.series[0].data = null;
				chartConfig.series[1].data = null;
			}

			this.setState({chartConfig: chartConfig});
		}
	}

	render() {
		let {chartConfig} = this.state,
			{classes, fidoError} = this.props;

		return (
			<Paper className={classes.root}>
				<DetailedReport
					fidoError={fidoError}
					ready={chartConfig.series[0].data && chartConfig.series[0].data.length > 0 ? true : false}>
					<Highchart chartConfig={chartConfig} />
				</DetailedReport>
			</Paper>
		);
	}
}

EmailsAndAlertsReport.propTypes = {
	theme: PropTypes.object.isRequired,
	chartClasses: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	emails: PropTypes.array,
	alerts: PropTypes.array,
	previousDays: PropTypes.number,
	fidoError: PropTypes.bool
};

export default withStyles(styles)(withTheme(EmailsAndAlertsReport));
