import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportItemPaper from "./ReportItemPaper";

import ActivityAuditTrail, {placeHolder} from "../../components/ActivityAuditTrail";
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	auditTrail: {
		maxHeight: 400,
		overflow: "auto",

		[theme.breakpoints.down('md')]: {
			maxHeight: 395
		},

		[theme.breakpoints.down('xs')]: {
			maxHeight: 440
		}
	}
});

class TeamActivityReportItem extends Component {
	render() {
		let {classes, activity, fidoError} = this.props;
		return (
			<ReportItemPaper
				taller
				ready={activity != null}
				fidoError={fidoError}
				placeHolder={placeHolder}
				title="Team Actions">
				<ActivityAuditTrail
					useVerbose
					className={classes.auditTrail}
					userActivity={activity}
					fidoError={fidoError} />
			</ReportItemPaper>
		);
	}
}

TeamActivityReportItem.propTypes = {
	classes: PropTypes.object.isRequired,
	activity: PropTypes.array,
	fidoError: PropTypes.bool
};

export default withStyles(styles)(TeamActivityReportItem);