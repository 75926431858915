import React, { Component } from 'react';
import { Typography, Link } from '@material-ui/core';

class SentimentBehindNumbers extends Component {
	render() {
		return (
			<Typography>
				A sample of public social media posts authored by students was gathered by querying those who self-identify as students using acronyms in their profile (for example, CCHS or BHS ‘21). These posts are subjected to rigorous pre-processing to remove as many non-student accounts as possible.<br/><br/>
				These social media posts are aggregated by day and scored for sentiment in four different ways, which correspond to the four different emotions.<br/><br/>
				<b>Happiness Score</b> is calculated using Language Assessment by Mechanical Turk 1.0 (labMT 1.0), a robust lexicon developed by P. Dodds, K. Harris, I. Kloumann, C. Bliss,and C. Danforth. See <Link rel="noopener noreferrer" target="_blank" href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0026752">Temporal Patterns of Happiness and Information in a Global Social Network: Hedonometrics and Twitter.</Link> labMT 1.0 is licensed under <Link rel="noopener noreferrer" target="_blank" href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0.</Link><br/><br/>
				<b>Harm Score</b> is calculated using a proprietary lexicon developed using excerpts from Social Sentinel’s proprietary Sentinel Search™ Library. The intensity scores associated with each term are calculated using historical risk of harm, based on Social Sentinel’s proprietary post-classification machine learning models.<br/><br/>
				<b>Fear and Anger Scores</b> are calculated using the <Link rel="noopener noreferrer" target="_blank" href="http://saifmohammad.com/WebPages/lexicons.html">Emotion and Sentiment Lexicons</Link> created by the National Research Council Canada. These lexicons are novel in that they provide a mechanism for scoring many emotions rather than traditional binary sentiment. These lexicons are licensed under a Single Product End-User License Agreement with NRC.<br/><br/>
				These aggregations are converted into an index value where 100 is the historical average for that emotion. Scores above 100 represent more intensity for that emotion, and scores below 100 represent less.<br/><br/>
				Trending Topics are extracted using a proprietary clustering algorithm developed by Social Sentinel.
			</Typography>
		);
	}
}

export default SentimentBehindNumbers;