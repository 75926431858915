import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HTMLEntities from "../utils/HTMLEntities";

import Constants from "../utils/Constants";
import { Typography } from '@material-ui/core';

class ActivityTitle extends Component {
	// return null rather than empty typography component when no title
	render() {
		let {title, twitterRedacted} = this.props;
		return title && (!twitterRedacted || twitterRedacted === Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO) ? (
			<Typography color={this.props.color} variant="h4">{HTMLEntities.decode(this.props.title)}</Typography>
		) : null;
	}
}

ActivityTitle.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string,
	twitterRedacted: PropTypes.string
};

export default ActivityTitle;