import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MomentCore from 'moment';

import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import LessIntenseImage from "../../assets/less-intense.png";
import MoreIntenseImage from "../../assets/more-intense.png";
import MoreInfo from "../../components/MoreInfo";
import DetailedReport from '../containers/DetailedReport';

import {
	withTheme,
	withStyles,
	Paper
} from '@material-ui/core';

const styles = theme => ({
	root: {
		minHeight: 585
	}
});

class SentimentReport extends Component {
	constructor(props, context) {
		super(props, context);

		this.onClick = this.onClick.bind(this);

		let click = this.onClick,
			{theme, subtitle, seriesConfig} = props;

		this.state = {
			linkTitle: null,
			linkUrl: null,
			clusterName: null,
			chartConfig: HighchartsConfig.merge({
				title: "",
				legend: {
					enabled: true,
					verticalAlign: "top",
					itemStyle: {
						cursor: 'pointer'
					}
				},
				accessibility: {
					description: subtitle
				},
				xAxis: {
					type: "datetime",
					tickWidth: 0,
					gridLineWidth: 1,
					gridLineColor: theme.palette.grey[300],
					crosshair: {
						width: 5
					},
					labels: {
						y: 20,
						style: {
							align: "center",
							fontSize: "15px",
							color: theme.palette.primary.darkGrey
						}
					},
					lineColor: theme.palette.grey[300]
				},
				yAxis: {
					title: {text: ""},
					tickWidth: 0,
					labels: {
						useHTML: true,
						formatter: function() {
							let style = "position: absolute;left: -16px;max-height: 145px;";
							if (this.value === this.axis.min) {
								return `<img src='${LessIntenseImage}' style='${style}top:-145px;' alt='' />`;
							} else if (this.value === this.axis.max) {
								return `<img src='${MoreIntenseImage}' style='${style}top:0px;' alt='' />`;
							}
						}
					},
					gridLineWidth: 1,
					gridLineColor: theme.palette.grey[300],
					plotLines: [{
						width: 2,
						value: 100,
						dashStyle: "Dash",
						color: theme.palette.primary.darkGrey
					}]
				},
				tooltip: {
					shared: true,
					formatter: function() {
						let date = new MomentCore(this.x);
						return this.points.reduce(function (s, point) {
							return `${s}<br/><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b><br/>`;
						}, `<span style='font-weight: bold'>${date.format("MMM D")}</span><br/>`);
					},
					positioner: function(labelWidth, labelHeight, point) {
						let x = point.plotX;

						if (x < 100) {
							x = 65;
						} else if (x > 650) {
							x -= 20;
						}

						return {x: x, y: 300};
					}
				},
				chart: {
					marginLeft: 50,
					marginRight: 50
				},
				plotOptions: {
					series: {
						cursor: "pointer",
						allowPointSelect: true,
						marker: {
							states: {
								select: {
									radius: 10,
									lineWidth: 3,
									lineColor: theme.palette.grey[300],
									fillColor: theme.palette.primary.mediumGreen
								}
							}
						},
						point: {
							events: {
								click: function() {
									click({
										clusterName: this.clusterName,
										linkTitle: this.linkTitle,
										linkUrl: this.linkUrl
									});
								}
							}
						},
						events: {
							legendItemClick: function(e) {
								return e;
							}
						}/*,
						dataLabels: {
							shape: 'callout',
							backgroundColor: 'transparent',
							borderColor: theme.palette.secondary.slate,
							borderWidth: 1,
							borderRadius: 2,
							style: {
								textOutline: 'none',
								color: theme.palette.secondary.slate
							}
						}*/
					},
					spline: {
						marker: {
							enabled: false
						}
					}
				},
				series: seriesConfig
			})
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.dataConfig[0].data !== this.props.dataConfig[0].data) {
			// TODO: iteration, move some biz logic to server, return 4 separate arrays etc...
			let /*numCallouts = 0,
				maxCallouts = 4,*/
				massaged = [],
				{theme, previousDays, dataConfig} = this.props,
				chartConfig = this.state.chartConfig;

			if (this.props.dataConfig[0].data !== null && this.props.dataConfig[0].data.length) {
				const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 0});
				dataConfig.forEach((config, idx) => {
					ReportDataMasseuse.convertDatesToMillis(config.data);

					massaged.push(ReportDataMasseuse.massageData(
						config.data, config.key, config.valueKey, config.asObject
					));

					if (massaged[idx]) {
						chartConfig.series[idx].data = massaged[idx].map(point => {
							point.y = Number(numFormatter.format(point.y));
							return point;
						});
					}
				});

				if (massaged[0]) {
					let min,
						max,
						allVals = massaged[0].reduce((a, v, i) => {
							a.push(v.y);
							for (var j=1; j<massaged.length; j++) {
								a.push(massaged[j][i].y);
							}
							return a;
						}, []);

					min = Math.abs(Math.min(...allVals));
					max = Math.abs(Math.max(...allVals));

					let maxD = Math.abs(100 - max),
						minD = Math.abs(100 - min),
						delta = maxD > minD ? maxD : minD,
						rangeBottom = 100 - delta,
						rangeTop = 100 + delta;

					chartConfig.yAxis.min = rangeBottom;
					chartConfig.yAxis.max = rangeTop;

					if (chartConfig.series[0] && chartConfig.series[0].data) {
						chartConfig.xAxis.labels.x = previousDays === 7 ? 25 : null;
						chartConfig.xAxis.labels.rotation = previousDays === 7 ? null : -45;
						chartConfig.xAxis.max = chartConfig.series[0].data[chartConfig.series[0].data.length-1].period;

						chartConfig.series[0].data.forEach((point, idx) => {
							let largestClusterPoint = {y: 0};
							// only show 4 callouts
							if (/*numCallouts < maxCallouts &&*/ point.clusterName) {
								largestClusterPoint = chartConfig.series.reduce((a, v, i) => {
									if (v.data[idx].y > a.y) {
										return v.data[idx];
									} else {
										return a;
									}
								}, largestClusterPoint);

								// fairly arbitrary number until DS comes up with solution as to when to show a callout
								//if (largestClusterPoint.y >= 115) {
								if (largestClusterPoint.clusterName) {
									/*largestClusterPoint.dataLabels = {
										enabled: true,
										format: `${largestClusterPoint.clusterName}`,
										align: 'left',
										verticalAlign: 'middle',
										x: 15,
										crop: false,
										overflow: "allow"
									};*/
									largestClusterPoint.marker = {
										radius: 5,
										lineWidth: 3,
										enabled: true,
										symbol: "circle",
										lineColor: theme.palette.grey[300],
										fillColor: theme.palette.secondary.slate
									};
									//numCallouts++;
								}
							}
						});
					} else {
						chartConfig.series.forEach(series => series.data = []);
					}
				} else {
					chartConfig.series.forEach(series => series.data = []);
				}
			} else {
				chartConfig.series.forEach(series => series.data = []);
			}

			this.setState({
				linkTitle: null,
				linkUrl: null,
				clusterName: null,
				chartConfig: chartConfig
			});
		}
	}

	onClick(point) {
		let that = this;
		setTimeout(() => {
			that.setState({
				clusterName: point.clusterName,
				linkTitle: point.linkTitle,
				linkUrl: point.linkUrl
			});
		}, 100);
	}

	render() {
		let {chartConfig, clusterName, linkTitle, linkUrl} = this.state,
			{classes, render, subtitle, fidoError, reportTitle, moreInfoComponent} = this.props;

		return (
			<Paper className={classes.root}>
				<DetailedReport
					fidoError={fidoError}
					title={
						<div>
							{reportTitle}
							<MoreInfo
								title="Behind the Numbers"
								content={moreInfoComponent} />
						</div>
					}
					subtitle={subtitle}
					ready={chartConfig.series[0].data ? true : false}>
					{render({
						linkUrl: linkUrl,
						linkTitle: linkTitle,
						clusterName: clusterName,
						chartConfig: chartConfig
					})}
				</DetailedReport>
			</Paper>
		);
	}
}

SentimentReport.defaultProps = {
	subtitle: "These aggregations are converted into an index value where 100 is the historical average for that emotion. Scores above 100 represent more intensity for that emotion, and scores below 100 represent less."
};

SentimentReport.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	dataConfig: PropTypes.array.isRequired,
	previousDays: PropTypes.number.isRequired,
	seriesConfig: PropTypes.array.isRequired,
	subtitle: PropTypes.string.isRequired,
	render: PropTypes.func,
	fidoError: PropTypes.bool,
	reportTitle: PropTypes.string,
	moreInfoComponent: PropTypes.any
};

export default withStyles(styles)(withTheme(SentimentReport));
