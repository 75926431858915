import React from 'react';
import PropTypes from 'prop-types';

import DialogSlideTransition from "./DialogSlideTransition";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

class PasswordResetConfirmation extends React.Component {
	render() {
		let {handleCancel, handleReset, userEmail, open} = this.props;

		return (
			<Dialog open={open} keepMounted onClose={handleCancel} TransitionComponent={DialogSlideTransition}>
				<DialogTitle>Send Password Reset Link</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{`A password reset link will be emailed to ${userEmail ? userEmail : 'the email on file'}.`}<br /><br />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel}>Cancel</Button>
					<Button onClick={handleReset} color="secondary">Reset Password</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

PasswordResetConfirmation.propTypes = {
	open: PropTypes.bool,
	handleReset: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
	userEmail: PropTypes.string
};

export default PasswordResetConfirmation;
