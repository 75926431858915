import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles, IconButton, withTheme } from '@material-ui/core';

const styles = theme => ({
	button: {
		color: theme.palette.common.white,
		"& a": {
			color: theme.palette.common.white,
		},

		[theme.breakpoints.up('lg')]: {
			color: theme.palette.primary.darkGrey,
			"& a": {
				color: theme.palette.primary.darkGrey,
			}
		}

	}
});

class HeaderIconButton extends Component {

	render() {
		let {children, classes, handleClick} = this.props;

		return (
			<IconButton
				className={classes.button}
				onClick={handleClick}>
				{children}
			</IconButton>
		);
	}
}

HeaderIconButton.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
	handleClick: PropTypes.func
};

export default withStyles(styles)(withTheme(withRouter(HeaderIconButton)));