import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Fido from '../../utils/Fido';
import AssociationsReport from "../components/AssociationsReport";
import ImagesProcessedReport from "../components/ImagesProcessedReport";
import AlertsReport from "../components/AlertsReport";
import AlertsViewedReport from "../components/AlertsViewedReport";
import DiscussionPostsReport from "../components/DiscussionPostsReport";
import ChartClasses from "../components/ChartClasses";

import { withStyles, Paper, Tabs, Tab } from '@material-ui/core';

const styles = theme => ({
	root: {
		minHeight: 530,
		paddingTop: theme.spacing(4),
		backgroundColor: theme.palette.secondary.slate,

		[theme.breakpoints.down('xs')]: {
			minHeight: 500
		}
	},

	tab: {
		fontWeight: 200,
		color: theme.palette.common.white,

		[theme.breakpoints.up('xs')]: {
			width: 130,
			minWidth: 130
		},

		[theme.breakpoints.up('md')]: {
			width: 165,
			minWidth: 165
		},

		[theme.breakpoints.up('lg')]: {
			width: 142,
			minWidth: 142
		},

		[theme.breakpoints.up('xl')]: {
			width: 180,
			minWidth: 180
		}
	},

	tabLabelContainer: {
		paddingLeft: theme.spacing(.75),
		paddingRight: theme.spacing(.75)
	},

	selectedTab: {
		fontWeight: 500
	}
});

class TabbedChartsOverTime extends Component {
	constructor(props, context) {
		super(props, context);

		this.associatedPostsFido = new Fido();
		this.imagesFido = new Fido();
		this.alertsReceivedFido = new Fido();
		this.alertsViewedFido = new Fido();
		this.discussionPostsFido = new Fido();

		this.tabValues = {
			alertsReceived: "alerts_received",
			associatedPosts: "associations",
			imagesScanned: "images_scanned",
			alertsViewed: "alerts_viewed",
			discussionPosts: "discussion_posts"
		};

		this.state = {
			tabValue: this.tabValues.alertsReceived,

			alertsReceived: null,
			alertsReceivedFidoError: false,

			associatedPosts: null,
			associatedPostsFidoError: false,

			imagesScanned: null,
			imagesScannedFidoError: false,

			alertsViewed: null,
			alertsViewedFidoError: false,

			discussionPosts: null,
			discussionPostsFidoError: false
		};

		this.tabChangeHandler = this.tabChangeHandler.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.tabValue !== this.state.tabValue ||
			prevProps.filter !== this.props.filter) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		this.associatedPostsFido.dropIt();
		this.imagesFido.dropIt();
		this.alertsReceivedFido.dropIt();
		this.alertsViewedFido.dropIt();
		this.discussionPostsFido.dropIt();
	}

	tabChangeHandler(event, value) {
		this.setState({tabValue: value});
	}

	fetchData() {
		let {tabValue} = this.state;
		switch (tabValue) {
			case this.tabValues.associatedPosts: {
				this.loadAssociatedPostsData();
				break;
			}

			case this.tabValues.imagesScanned: {
				this.loadImagesScannedData();
				break;
			}

			case this.tabValues.alertsReceived: {
				this.loadAlertsReceivedData();
				break;
			}

			case this.tabValues.alertsViewed: {
				this.loadAlertsViewedData();
				break;
			}

			case this.tabValues.discussionPosts: {
				this.loadDiscussionPostsData();
				break;
			}

			default: break;
		}
	}

	async loadAlertsReceivedData() {
		this.setState({
			alertsReceived: null,
			alertsReceivedFidoError: false
		});

		let data = await this.alertsReceivedFido.fetch("/reports/alerts", {query: this.props.filter})
			.catch(error => {
				this.setState({alertsReceivedFidoError: true});
				this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({alertsReceived: data.alerts});
		}
	}

	async loadAssociatedPostsData() {
		this.setState({
			associatedPosts: null,
			associatedPostsFidoError: false
		});

		let data = await this.associatedPostsFido.fetch("/reports/associations", {query: this.props.filter})
			.catch(error => {
				this.setState({associatedPostsFidoError: true});
				this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({associatedPosts: data.associations});
		}
	}

	async loadImagesScannedData() {
		this.setState({
			imagesScanned: null,
			imagesScannedFidoError: false
		});

		let data = await this.imagesFido.fetch("/reports/images-processed", {query: this.props.filter})
			.catch(error => {
				this.setState({imagesScannedFidoError: true});
				this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({imagesScanned: data.images});
		}
	}

	async loadAlertsViewedData() {
		this.setState({
			alertsViewed: null,
			alertsViewedFidoError: false
		});

		let data = await this.alertsViewedFido.fetch("/reports/alerts-viewed", {query: this.props.filter})
			.catch(error => {
				this.setState({alertsViewedFidoError: true});
				this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({alertsViewed: data.usersAlertsViewed});
		}
	}

	async loadDiscussionPostsData() {
		this.setState({
			discussionPosts: null,
			discussionPostsFidoError: false
		});

		let data = await this.discussionPostsFido.fetch("/reports/discussions", {query: this.props.filter})
			.catch(error => {
				this.setState({discussionPostsFidoError: true});
				this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({discussionPosts: data.discussions});
		}
	}


	render() {
		let {classes, showSnackbar, location, filter} = this.props,
			{tabValue,
				alertsReceived, alertsReceivedFidoError,
				associatedPosts, associatedPostsFidoError,
				imagesScanned, imagesScannedFidoError,
				alertsViewed, alertsViewedFidoError,
				discussionPosts, discussionPostsFidoError} = this.state;

		return (
			<Paper className={classes.root}>
				<Media query={{maxWidth: 725}}>
					{matches =>
						<Tabs
							value={tabValue}
							indicatorColor="primary"
							scrollButtons="auto"
							centered={matches ? false : true}
							variant={matches ? "scrollable" : null}
							onChange={this.tabChangeHandler}>
							<Tab
								label="Associated Posts"
								value={this.tabValues.associatedPosts}
								classes={{root: classes.tabLabelContainer}}
								className={`${classes.tab} ${tabValue === "associations" ? classes.selectedTab : ""}`} />
							<Tab
								label="Images Scanned"
								value={this.tabValues.imagesScanned}
								classes={{root: classes.tabLabelContainer}}
								className={`${classes.tab} ${tabValue === "images_scanned" ? classes.selectedTab : ""}`} />
							<Tab
								label="Alerts Received"
								value={this.tabValues.alertsReceived}
								classes={{root: classes.tabLabelContainer}}
								className={`${classes.tab} ${tabValue === "alerts_received" ? classes.selectedTab : ""}`} />
							<Tab
								label="Alerts Viewed"
								value={this.tabValues.alertsViewed}
								classes={{root: classes.tabLabelContainer}}
								className={`${classes.tab} ${tabValue === "alerts_viewed" ? classes.selectedTab : ""}`} />
							<Tab
								label="Discussion Posts"
								value={this.tabValues.discussionPosts}
								classes={{root: classes.tabLabelContainer}}
								className={`${classes.tab} ${tabValue === "discussion_posts" ? classes.selectedTab : ""}`} />
						</Tabs>
					}
				</Media>
				{tabValue === this.tabValues.associatedPosts ?
					<ChartClasses
						render={props =>
							<AssociationsReport
								{...props}
								filter={filter}
								showSnackbar={showSnackbar}
								location={location}
								data={associatedPosts}
								fidoError={associatedPostsFidoError} />
						}/>
					: null
				}
				{tabValue === this.tabValues.imagesScanned ?
					<ChartClasses
						render={props =>
							<ImagesProcessedReport
								{...props}
								filter={filter}
								showSnackbar={showSnackbar}
								location={location}
								data={imagesScanned}
								fidoError={imagesScannedFidoError} />
						}/>
					: null
				}
				{tabValue === this.tabValues.alertsReceived ?
					<ChartClasses
						render={props =>
							<AlertsReport
								{...props}
								filter={filter}
								showSnackbar={showSnackbar}
								location={location}
								data={alertsReceived}
								fidoError={alertsReceivedFidoError} />
						}/>
					: null
				}
				{tabValue === this.tabValues.alertsViewed ?
					<ChartClasses
						render={props =>
							<AlertsViewedReport
								{...props}
								filter={filter}
								showSnackbar={this.showSnackbar}
								location={location}
								data={alertsViewed}
								fidoError={alertsViewedFidoError} />
						}/>
					: null
				}
				{tabValue === this.tabValues.discussionPosts ?
					<ChartClasses
						render={props =>
							<DiscussionPostsReport
								{...props}
								filter={filter}
								showSnackbar={this.showSnackbar}
								location={location}
								data={discussionPosts}
								fidoError={discussionPostsFidoError} />
						}/>
					: null
				}
			</Paper>
		);
	}
}

TabbedChartsOverTime.propTypes = {
	classes: PropTypes.object.isRequired,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	location: PropTypes.object,
	filter: PropTypes.object
};

export default withStyles(styles)(TabbedChartsOverTime);
