import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fido from '../../utils/Fido';
import DeepMerge from "../../utils/DeepMerge";
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";
import OverviewReportLayout from "../components/OverviewReportLayout";
import SparkChartReportItem from "../components/SparkChartReportItem";
import RecentPostsItem from "../components/RecentPostsItem";
import ResponseBreakdownReport from "../components/ResponseBreakdownReport";
import BorderIcon from "../components/BorderIcon";
import ShareitGood from '../../assets/shareit-good.png';
import ShareitSad from '../../assets/shareit-sad.png';
import ShareitBad from '../../assets/shareit-bad.png';
import ShareitTip from '../../assets/shareit-tip.png';

import { withStyles, withTheme } from '@material-ui/core';

const styles = theme => ({
	largeIcon: {
		height: theme.spacing(8),
		width: theme.spacing(8),
	},

	tipIcon: {
		height: theme.spacing(5),
		width: theme.spacing(5),
	}
});

class ShareitOverviewReport extends Component {
	constructor(props, context) {
		super(props, context);

		this.sparkCardsFido = new Fido();
		this.graphFido = new Fido();
		this.goodActivityFido = new Fido();
		this.otherActivityFido = new Fido();

		let {location, days, authenticatedUser} = this.props,
			accountParam = Fido.getSearchParam("account", location.search),
			filter = {
				source: "tips",
				previousDays: Number(Fido.getSearchParam("previousDays", location.search)) || days,
				account: accountParam ? (accountParam.split(",")).map(item => Number(item)) : authenticatedUser.childAccounts.map(item => item.id)
			};

		this.state = {
			filter,
			defaultFilter: Object.assign({}, filter),

			totalSubmissions: null,
			submissionsAmountChange: null,
			submissionsPercentageChange: null,

			totalImages: null,
			imagesAmountChange: null,
			imagesPercentageChange: null,

			totalGood: null,
			goodAmountChange: null,
			goodPercentageChange: null,

			totalSad: null,
			sadAmountChange: null,
			sadPercentageChange: null,

			totalBad: null,
			badAmountChange: null,
			badPercentageChange: null,

			totalTips: null,
			tipsAmountChange: null,
			tipsPercentageChange: null,

			sparkCardsFidoError: false,

			submissions: null,
			breakdown: null,
			graphDataFidoError: false,

			goodActivities: null,
			goodActivitiesFidoError: false,

			otherActivities: null,
			otherActivitiesFidoError: false,
		};

		this.executeFilter = this.executeFilter.bind(this);
	}

	componentDidMount() {
		this.props.updateHeader({title: "shareit", primary: true});
		this.loadData();
	}

	componentWillUnmount() {
		this.dropThem();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.search === "" && prevProps.location.search !== "") {
			let {filter} = this.state,
				{authenticatedUser} = this.props;

			filter.account = authenticatedUser.childAccounts.map(item => item.id);
			this.setState({
				filter: filter,
				defaultFilter: Object.assign({}, filter)
			});
		}
	}

	dropThem() {
		this.sparkCardsFido.dropIt();
		this.graphFido.dropIt();
		this.goodActivityFido.dropIt();
		this.otherActivityFido.dropIt();
	}

	loadData() {
		this.setState({
			totalSubmissions: null,
			totalImages: null,
			totalGood: null,
			totalBad: null,
			totalSad: null,
			totalTips: null,
			submissions: null,
			breakdown: null,
			goodActivities: null,
			otherActivities: null
		}, () => {
			this.loadSparkData();
			this.loadGraphData();
			this.loadGoodActivityData();
			this.loadOtherActivityData();
		});
	}

	async loadSparkData() {
		let data = await this.sparkCardsFido.fetch("/reports/tips/spark", {query: this.state.filter})
			.catch(error => {
				this.setState({sparkCardsFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState(data);
		}
	}

	async loadGraphData() {
		let data = await this.graphFido.fetch("/reports/tips/graph", {query: this.state.filter})
			.catch(error => {
				this.setState({graphDataFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState(data);
		}
	}

	async loadGoodActivityData() {
		let data = await this.goodActivityFido.fetch("/activities", DeepMerge.merge({
			query: {
				limit: 5,
				category: "discussions"
			}}, {query: this.state.filter}))
			.catch(error => {
				this.setState({goodActivitiesFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({
				goodActivities: data.activities
			});
		}
	}

	async loadOtherActivityData() {
		let data = await this.otherActivityFido.fetch("/activities", DeepMerge.merge({
			query: {
				limit: 5,
				category: "alerts"
			}}, {query: this.state.filter}))
			.catch(error => {
				this.setState({otherActivitiesFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({
				otherActivities: data.activities
			});
		}
	}

	getFilterOpts(filterObj) {
		let opts,
			{filter} = this.state;

		filter.account = [];

		if (!filterObj.previousDays) {
			delete filter.previousDays;
		}

		Number(filter.previousDays);

		opts = DeepMerge.merge(filter, filterObj);

		// if all selected, don't include as url param
		if (Array.isArray(opts.account) && opts.account.length === this.props.authenticatedUser.childAccounts.length) {
			opts.account = [];
		}

		return opts;
	}

	executeFilter(filterObj) {
		let opts = this.getFilterOpts(filterObj);
		this.dropThem();
		delete opts.source;
		this.props.history.replace(Fido.buildPath("/shareit-report", opts));
		this.setState({filter: opts}, this.loadData);
	}

	render() {
		let {classes, history, location, days} = this.props,
			{filter,
				totalSubmissions, submissionsAmountChange, submissionsPercentageChange,
				totalImages, imagesAmountChange, imagesPercentageChange,
				totalGood, goodAmountChange, goodPercentageChange,
				totalSad, sadAmountChange, sadPercentageChange,
				totalBad, badAmountChange, badPercentageChange,
				totalTips, tipsAmountChange, tipsPercentageChange,
				sparkCardsFidoError,
				submissions, breakdown, graphDataFidoError,
				goodActivities, goodActivitiesFidoError,
				otherActivities, otherActivitiesFidoError, defaultFilter} = this.state,
			previousDays = Number(filter.previousDays || days),
			goodIcon =
				<BorderIcon
					hideInner
					solidOuter
					backgroundColor='#FFC107'
					icon={
						<img alt="Good Icon" src={ShareitGood} className={classes.largeIcon} />
					}
				/>
			,
			sadIcon =
				<BorderIcon
					hideInner
					solidOuter
					backgroundColor='#57BCFA'
					icon={
						<img alt="Sad Icon" src={ShareitSad} className={classes.largeIcon} />
					}
				/>
			,
			badIcon =
				<BorderIcon
					hideInner
					solidOuter
					backgroundColor='#ED412D'
					icon={
						<img alt="Bad Icon" src={ShareitBad} className={classes.largeIcon} />
					}
				/>
			,
			tipIcon =
				<BorderIcon
					hideInner
					solidOuter
					backgroundColor='#00CDB8'
					icon={
						<img alt="Tip Icon" src={ShareitTip} className={classes.tipIcon} />
					}
				/>
			,
			sparkCards = [
				<SparkChartReportItem
					key={"spark0"}
					fidoError={sparkCardsFidoError}
					title="Good"
					value={totalGood}
					change={goodAmountChange}
					percentChange={goodPercentageChange}
					largeIcon={goodIcon}
					previousDays={previousDays} />,
				<SparkChartReportItem
					key={"spark1"}
					invertChevron
					fidoError={sparkCardsFidoError}
					title="Sad"
					value={totalSad}
					change={sadAmountChange}
					percentChange={sadPercentageChange}
					largeIcon={sadIcon}
					previousDays={previousDays} />,
				<SparkChartReportItem
					key={"spark2"}
					invertChevron
					fidoError={sparkCardsFidoError}
					title="Bad"
					value={totalBad}
					change={badAmountChange}
					percentChange={badPercentageChange}
					largeIcon={badIcon}
					previousDays={previousDays} />,
				<SparkChartReportItem
					key={"spark3"}
					fidoError={sparkCardsFidoError}
					title="Tips"
					value={totalTips}
					change={tipsAmountChange}
					percentChange={tipsPercentageChange}
					largeIcon={tipIcon}
					previousDays={previousDays} />,
				<SparkChartReportItem
					key={"spark4"}
					fidoError={sparkCardsFidoError}
					title="Total Responses"
					value={totalSubmissions}
					change={submissionsAmountChange}
					percentChange={submissionsPercentageChange}
					previousDays={previousDays} />,
				<SparkChartReportItem
					key={"spark5"}
					fidoError={sparkCardsFidoError}
					title="Images Scanned"
					value={totalImages}
					change={imagesAmountChange}
					percentChange={imagesPercentageChange}
					previousDays={previousDays} />
			];

		return (
			<OverviewReportLayout
				location={location}
				sparkCards={sparkCards}
				previousDays={previousDays}
				filterCallback={this.executeFilter}
				selectedAccounts={defaultFilter && defaultFilter.account}>
				<ResponseBreakdownReport
					fullWidth
					history={history}
					previousDays={previousDays}
					graphData={submissions}
					graphBreakdown={breakdown}
					fidoError={graphDataFidoError} />
				<RecentPostsItem
					taller
					activities={goodActivities}
					history={history}
					fidoError={goodActivitiesFidoError}
					title="Shareit Discussion Posts"
					subtitle="Shareit submissions (Good) marked as discussion."
					itemClickPath="/activities"
					buttonText="view more"
					noDataMessage="There are no recent posts for this time period."
					buttonClickHandler={() => history.push("/activities?category=discussions&source=tips")} />
				<RecentPostsItem
					taller
					activities={otherActivities}
					history={history}
					fidoError={otherActivitiesFidoError}
					title="Shareit Alerts"
					subtitle="Shareit submissions (Good/Sad/Bad/Tips) marked as alerts."
					itemClickPath="/activities"
					buttonText="view more"
					noDataMessage="There are no recent alerts for this time period."
					buttonClickHandler={() => history.push("/activities?category=alerts&source=tips")} />
			</OverviewReportLayout>
		);
	}
}

ShareitOverviewReport.defaultProps = {
	days: 30
};

ShareitOverviewReport.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	days: PropTypes.number,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <ShareitOverviewReport {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "ShareitOverviewReport";

export default withStyles(styles)(withTheme(React.forwardRef(dNameFunc)));