import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from "../components/Avatar";
import ReportItemGrid from "../reports/components/ReportItemGrid";

import { Typography, Button, Grid, withStyles, withTheme } from '@material-ui/core';

const styles = theme => ({
	root: {
		padding: theme.spacing(.5)
	},

	button: {
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(1)
	}
});

class UserItemListing extends Component {
	render() {
		let {classes, items, history, itemClickPath, buttonDisabled, buttonText, buttonClickHandler} = this.props;

		return (
            <div>
				<ReportItemGrid
					centerGrid
					items={items}
					history={history}
					itemClickPath={itemClickPath}
					contentRenderer={item => {
						return (
                            <div className={classes.root}>
								<Grid container alignItems="center" spacing={1}>
									<Grid item>
										<Avatar
											authorName={item.name}
											authorImage={item.avatar} />
									</Grid>
									<Grid item>
										<Typography
											color="initial" noWrap>
											{item.name}
										</Typography>
									</Grid>
								</Grid>
							</div>
                        );
					}} />
				{!buttonDisabled ?
					<Button
						size="small"
						color="primary"
						className={classes.button}
						onClick={buttonClickHandler}>{buttonText}</Button>
				: null}
			</div>
        );
	}
}

UserItemListing.propTypes = {
	history: PropTypes.object.isRequired,
	items: PropTypes.array,
	itemClickPath: PropTypes.string,
	buttonClickHandler: PropTypes.func,
	buttonText: PropTypes.string,
	buttonDisabled: PropTypes.bool
};

export default withStyles(styles)(withTheme(UserItemListing));
