import URLSearchParams from "url-search-params";
import Constants from './Constants';
const AbortController = window.AbortController;

class Fido {
	//controller = new AbortController();
	messages = {
		signIn: "Sign In Required...",
		notActivated: "Not activated, please check email...",
		offline: "Network offline, please try again later..."
	}

	/**
	 * Use this component for all ajax calls to SSI API; not necessary for third party APIs, but
	 * should work without problem
	 * @param  {[type]} url     ajax url path, relative to apiStub; expected to match the browser url
	 * @param  {[type]} options additional header or other fetch options configured
	 * @param  {[type]} initiationMsg Snackbar message on successful network call initiated
	 * @return {[type]}         [description]
	 */
	fetch(path, options, initiationMsg, successMsg) {
		this.controller = new AbortController();

		let resp,
			successMsgObj = {id: null, msg: successMsg},
			fetchOpts = this.getFetchOptions(options),
			fetchUrl = this.getFetchUrl(path, fetchOpts);

		// for browsers that support navigator.onLine, don't bother trying to put,
		// post, or delete when offline
		if('method' in fetchOpts && fetchOpts.method !== "get" && 'onLine' in navigator && !navigator.onLine) {
			this.showSnackbar(this.messages.offline);
			return new Promise();
		} else if(initiationMsg) {
			// Defferring to avoid issues with snackbar during goBack
			setTimeout(() => {
				successMsgObj.id = this.showSnackbar(initiationMsg);
			}, 100);
		}

		return fetch(fetchUrl, fetchOpts)
			.then(response => {
				resp = response;
				// *********************
				// TODO: when we re-write php, let's design a consistent approach to error handling and messages to the client
				// currently some controllers respond with a 200 but have messages.errors in it's payload as opposed
				// to returning the appropriate HTML error code with a message available somehow.
				// this may require re-architecting Fido (throwing errors etc....)
				// *********************
				if(!response.ok) {
					throw new Error(`${response.status} ${response.statusText}`);
				} else if (response.ok) {
					return response.json().then(data => {
						let hasSuccessMsg = successMsgObj && successMsgObj.msg;

						if (data.messages && data.messages.error && data.messages.error.length) {
							if (hasSuccessMsg) {
								successMsgObj.msg = data.messages.error[0];
								this.updateSnackbarMessage(successMsgObj);
							} else {
								this.showSnackbar(data.messages.error[0]);
							}
						} else if (hasSuccessMsg) {
							this.updateSnackbarMessage(successMsgObj);
						}

						return data;
					});
				} else {
					throw new Error(`Not Sure: ${response.status} ${response.statusText}`);
				}
			})
			.catch(error => {
				// Swallow code 20 (user aborted) but propogate other errors up
				if(!("code" in error || error.code === 20)) {
					switch(error.message) {
						case '403 Forbidden':
						case '401 Unauthorized': {
							// Redirect auth-restricted pages
							// TODO - need better way for non-auth-restricted urls
							// TODO - remove hardcoded SPA_PATH ref
							let exceptions = [`/${Constants.SPA_PATH}/v/`, `/${Constants.SPA_PATH}/reset-password`, `/${Constants.SPA_PATH}/login`];
							let notException = !exceptions.find((path) => window.location.pathname.indexOf(path) !== -1);

							if(process.env.NODE_ENV !== 'development') {
								if (notException) {
									this.showSnackbar(error.message === "403 Forbidden" ? this.messages.notActivated : this.messages.signIn);

									let spaPath = `${Constants.SPA_PATH}/(.*)`,
										matches = new RegExp(spaPath, "g").exec(window.location.href),
										to = matches && matches.length && matches[1] ? "?to=/" + matches[1] : "";

									setTimeout(() => {
										window.appRoot.evictUser();
										window.appRoot.spaRedirect(`/login${to}`);
									}, 750);
								} else if (error.message === "403 Forbidden" && resp.url.indexOf("whoami") === -1) {
									// if it's a path in the exceptions array, it's not 'whoami' call and acount is not activated
									this.showSnackbar(this.messages.notActivated);
								}
							}
							break;
						}

						case "404 Not Found": {
							window.appRoot.spaRedirect("/notfound");
							this.showSnackbar("Error: Cannot be found.");
							break;
						}

						case 'Failed to fetch': {
							// Browsers that don't support navigator.online will be
							// caught here when offline
							this.showSnackbar(this.messages.offline);
							break;
						}

						// when response isn't JSON-able
						case 'Unexpected end of JSON input': {
							console.warn(error);

							// if local dev, whomai call and url is not hashed url
							if(process.env.NODE_ENV === 'development' && resp.url.indexOf("whoami") !== -1) {
								let rewrite = window.location.href.indexOf("/rewrite/") !== -1,
									hashed = window.location.href.indexOf("/sms/") !== -1 && !rewrite;

								if (!hashed || rewrite) {
									let spaPath = `${Constants.SPA_PATH}/(.*)`,
										matches = new RegExp(spaPath, "g").exec(window.location.href),
										to = matches && matches.length && matches[1] ? "?to=/" + matches[1] : "";

									setTimeout(() => {
										window.appRoot.evictUser();
										window.appRoot.spaRedirect(`/login${to}`);
									}, 750);
								}
							}

							break;
						}

						default: {
							console.warn(error);
							throw new Error(error);
						}
					}
				}
			});
	}

	// encapsulating snackbar shortcut
	showSnackbar(msgObject) {
		if (window.appRoot) {
			return window.appRoot.showSnackbar(msgObject);
		}
	}

	updateSnackbarMessage(msgObject) {
		if (window.appRoot) {
			window.appRoot.updateSnackbarMessage(msgObject);
		}
	}

	getFetchOptions(options) {
		const baseHeaders = {
			// Required for laravel to detect as ajax call
			'X-Requested-With': 'XMLHttpRequest',
			'Accept': 'application/json'
		};

		let baseOptions = {
			credentials: "same-origin"
		};

		let headerOverrides = options && 'headers' in options && options.headers || {};
		if(options) {
			if ('method' in options && (options.method === 'POST' || options.method === 'PUT')) {
				headerOverrides['content-type'] = 'application/json';
			}

			if ('cors' in options && options.cors) {
				// don't think we need
				//baseOptions.mode = "cors";
				// TODO: might want credentials for other calls in the future
				delete baseOptions.credentials;
			}
		}

		let combinedHeader = Object.assign({}, baseHeaders, headerOverrides);
		return Object.assign({}, baseOptions, {signal: this.controller.signal}, options, {"headers": combinedHeader});
	}

	getFetchUrl(path, options) {
		let url,
			query = options ? options.query : null;

		if(options && options.method !== "POST" && options.method !== "PUT" && query != null && typeof query === "object") {
			path = Fido.buildPath(path, query);
		}

		if (options && 'cors' in options && options.cors) {
			url = path;
		} else {
			url = `/api/v1/${Constants.SPA_PATH}${path}`;
		}

		return url;
	}

	dropIt() {
		if (this.controller) {
			this.controller.abort();
		}
	}

	static getUSP(searchStr) {
		return new URLSearchParams(searchStr || window.location.search);
	}

	static getSearchParam(param, searchStr) {
		return (Fido.getUSP(searchStr)).get(param);
	}

	static getSearchParamsObject(searchStr, exclusions) {
		let obj = {},
			usp = Fido.getUSP(searchStr);

		for(var pair of usp.entries()) {
			if (!exclusions || !exclusions.includes(pair[0])) {
				obj[pair[0]] = pair[1];
			}
		}

		return obj;
	}

	static buildPath(path, query) {
		let value,
			append = false,
			usp = new URLSearchParams();

		if (query) {
			Object.keys(query).forEach(k => {
				value = query[k];
				if (value) {
					if (Array.isArray(value)) {
						append = value.length ? true : false;
					} else {
						append = true;
					}
				}

				if (append === true) {
					usp.append(k, query[k]);
				}
			});
		}

		// decoding because for certain param values we want real commas (ie account=1234,5678)
		return `${path}${path.indexOf("?") > -1 ? "&" : "?"}${decodeURIComponent(usp.toString())}`;
	}
}

export default Fido;