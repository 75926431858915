import { Component } from 'react';
import PropTypes from 'prop-types';

import Constants from '../utils/Constants';

import { withStyles, withTheme } from '@material-ui/core';

const styles = theme => ({
	"@keyframes highlightFade": {
		from: {background: theme.palette.primary.darkGreen},
		to: {background: "transparent"}
	},

	highlight: {
		borderRadius: 4,
		animation: "$highlightFade 1.5s"
	}
});

class PositionMemory extends Component {
	constructor(props, context) {
		super(props, context);

		this.storageKey = "positionMemory";

		this.onScrollTimeoutId = null;
		this.pauseScrollTracking = false;
		this.onScroll = this.onScroll.bind(this);

		this.trackItem = this.trackItem.bind(this);
		this.trackPage = this.trackPage.bind(this);
		this.setNumberOfPages = this.setNumberOfPages.bind(this);
		this.restorePosition = this.restorePosition.bind(this);
	}

	componentDidMount() {
		// TODO: HACKY! if the placeholders overflow the height of the screen, the window scrolls to fit content
		window.document.body.style.overflowY = "hidden";
		setTimeout(() => {
			//window.scrollTo(0, 0);
			window.document.body.style.overflowY = "auto";
			window.addEventListener('scroll', this.onScroll);
		}, 100);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			let memory = this.getMemory(),
				path = this.getPath(),
				{history, previousLocation, defaultSearchParam} = this.props;

			if (previousLocation) {
				for (var key in memory) {
					if (history.action !== "POP" &&
						history.action !== "REPLACE" &&
						(previousLocation.pathname.indexOf(key) === -1 ||
						(defaultSearchParam && `${previousLocation.pathname}${previousLocation.search}`.indexOf(path) === -1))) {
						this.forget();
					}
				}
			} else if (previousLocation === null) {
				// first time / reload
				this.forget();
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll);
	}

	onScroll(ev) {
		if (this.onScrollTimeoutId) {
			clearTimeout(this.onScrollTimeoutId);
		}

		if (this.pauseScrollTracking === false) {
			this.onScrollTimeoutId = setTimeout(() => {
				this.updatePositionInfo({scrollPosition: this.getWindowScrollPosition()});
			}, 150);
		}
	}

	getWindowScrollPosition() {
		return window.scrollY;
	}

	getMemory() {
		return JSON.parse(localStorage.getItem(this.storageKey)) || {};
	}

	getPath() {
		let {localStorageKey, defaultSearchParam, location} = this.props,
			path = localStorageKey || location.pathname;

		if (defaultSearchParam) {
			path = `${path}?${defaultSearchParam}`;
		}

		return path;
	}

	getPositionInfo() {
		return this.getMemory()[this.getPath()];
	}

	trackItem(id) {
		this.updatePositionInfo({itemId: id});
	}

	trackPage(page) {
		this.updatePositionInfo({page: page});
	}

	updatePositionInfo(positionInfo) {
		let memory = this.getMemory(),
			memoryPosInfo = this.getPositionInfo() || {};

		memory[this.getPath()] = Object.assign(memoryPosInfo, positionInfo);
		localStorage.setItem(this.storageKey, JSON.stringify(memory));
	}

	setNumberOfPages(fidoOpts) {
		let positionInfo = this.getPositionInfo();

		if (positionInfo && positionInfo.page) {
			if (fidoOpts == null) {
				fidoOpts = {query: {}};
			}
			fidoOpts.query.numberOfPages = positionInfo.page;
		}

		return fidoOpts;
	}

	forget(attr) {
		let memory = this.getMemory(),
			path = this.getPath();

		try {
			if (attr) {
				delete memory[path][attr];
			} else {
				delete memory[path];
			}
		} catch (e) {
			console.warn(e);
		}

		localStorage.setItem(this.storageKey, JSON.stringify(memory));
	}

	restorePosition() {
		let positionInfo = this.getPositionInfo() || {},
			// TODO: figure out how to use Ref's somehow... I think it finds item in shadow dom before new data rendered
			item = document.querySelector(`[id='${positionInfo.itemId}']`),
			{scrollPosition/*, page*/} = positionInfo,
			{classes, offset, theme} = this.props,
			itemOffset = theme.mixins.toolbar["@media (min-width:768px)"].minHeight + (offset || 0);

		if (positionInfo.itemId && !item) {
			console.warn(`Cannot find the item whose dom id=${positionInfo.itemId}`);
		}

		if (positionInfo && Object.keys(positionInfo).length) {
			this.pauseScrollTracking = true;

			let delay = (time) => {
				return new Promise((resolve) => {
					setTimeout(resolve, time);
				});
			};

			delay(250)
				.then(() => {
					// TODO: figure out how to use Ref's somehow... I think it finds item in shadow dom before new data rendered
					let verifyItem = document.querySelector(`[id='${positionInfo.itemId}']`);
					if (item && verifyItem) {
						item.scrollIntoView();
					} else {
						window.scrollTo(0, scrollPosition);
					}

					this.forget("itemId");
					this.pauseScrollTracking = false;

					return item && verifyItem ? delay(350) : null;
				})
				.then(() => {
					// TODO: figure out how to use Ref's somehow... I think it finds item in shadow dom before new data rendered
					let verifyItem = document.querySelector(`[id='${positionInfo.itemId}']`);
					if (verifyItem) {
						// slightly fix location so it's more accurate, and highlight item
						if (Constants.ENV.IS_EDGE || Constants.ENV.IS_IE11) {
							window.scrollTo(0, (Constants.ENV.IS_IE11 ? window.pageYOffset : window.scrollY) - itemOffset);
						} else {
							window.scrollTo({
								left: 0,
								top: window.scrollY - itemOffset,
								behavior: "smooth"
							});
						}

						// highlight item
						verifyItem.classList.add(classes.highlight);
					}
				});
		}
	}

	render() {
		let {render} = this.props,
			positionInfo = this.getPositionInfo(),
			props = {
				positionInfo: positionInfo,
				trackItem: this.trackItem,
				trackPage: this.trackPage,
				setNumberOfPages: this.setNumberOfPages,
				restorePosition: this.restorePosition
			};

		return render(props);
	}
}

PositionMemory.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	render: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	previousLocation: PropTypes.object,
	offset: PropTypes.number,
	localStorageKey: PropTypes.string, // this is not required, only using because / & /activities are the same paths
	defaultSearchParam: PropTypes.string // this is not required, includes this in local storage key
};

export default withTheme(withStyles(styles)(PositionMemory));
