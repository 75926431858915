import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, CircularProgress } from '@material-ui/core';

const styles = theme => ({
	circleIndicator: {
		color: "#4CAF50", // core/colors/green[500]
		position: 'absolute'
	}
});

class LoadingTextButton extends Component {
	render() {
		let {classes, indicatorSize, loading, color, size, onClick, children, className, indicatorClassName} = this.props;
		return (
			<Button
				onClick={onClick}
				size={size || "small"}
				color={color || "primary"}
				className={className}
				disabled={loading}>
				{children}
				{loading ?
					<CircularProgress
						size={indicatorSize || "1.58rem"}
						className={`${classes.circleIndicator} ${indicatorClassName}`} />
					: null
				}
			</Button>
		);
	}
}

LoadingTextButton.propTypes = {
	classes: PropTypes.object.isRequired,
	indicatorSize: PropTypes.number,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	color: PropTypes.string,
	size: PropTypes.string,
	className: PropTypes.string,
	indicatorClassName: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired
};

export default withStyles(styles)(LoadingTextButton);