// react-ga for base GA setup
import ReactGA from 'react-ga';
// autotrack for majority of tracking responsibilities - don't add here - see readme
// for more details
// import 'autotrack/lib/plugins/url-change-tracker';
// import 'autotrack/lib/plugins/media-query-tracker';
// import 'autotrack/lib/plugins/page-visibility-tracker';

ReactGA.initialize(process.env.REACT_APP_GA_PROP_ID);
// console.info("GA Property ID", process.env.REACT_APP_GA_PROP_ID);
// ReactGA.pageview(window.location.pathname);		// skip if sendInitialPageview is set below

// Configure GA autotrack plugins
ReactGA.ga('require', 'pageVisibilityTracker', {
	"sendInitialPageview": true,
	"visibleMetricIndex": 1
});
ReactGA.ga('require', 'urlChangeTracker');

ReactGA.ga('require', 'mediaQueryTracker', {
	definitions: [
		{
			name: 'Breakpoint',
			dimensionIndex: 1,
			items: [
				{name: 'desktop', media: 'all'},
				{name: 'sm', media: '(max-width: 320px)'},
				{name: 'md', media: '(max-width: 375px)'},
				{name: 'lg', media: '(max-width: 425px)'},
				{name: 'tablet', media: '(max-width: 768px)'},
				{name: 'laptop', media: '(max-width: 1024px)'},
				{name: 'laptop-L', media: '(max-width: 1440px)'}
			]
		},
		{
			name: 'Pixel Density',
			dimensionIndex: 2,
			items: [
				{name: '1x',   media: 'all'},
				{name: '1.25x', media: '(min-resolution: 120dpi)'},
				{name: '1.3x', media: '(min-resolution: 124.8dpi)'},
				{name: '1.5x', media: '(min-resolution: 144dpi)'},
				{name: '2x',   media: '(min-resolution: 192dpi)'}
			]
		},
		{
			name: 'Orientation',
			dimensionIndex: 3,
			items: [
				{name: 'landscape', media: '(orientation: landscape)'},
				{name: 'portrait',  media: '(orientation: portrait)'}
			]
		}
	]
});

export default ReactGA;
