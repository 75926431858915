import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    MenuItem,
    Divider,
    Drawer,
    SwipeableDrawer,
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    withStyles,
    withTheme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Media from 'react-media';

import Branding from "./Branding";
import ProfileCard from './ProfileCard';
import SupportContact from "./SupportContact";
import NavigationReport from "../reports/components/NavigationReport";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import Footer from './Footer';
import AuthorizedUser from "../utils/AuthorizedUser";
import ShareitLogo from '../assets/shareit-black.svg';
import ShareitLogoGreen from '../assets/shareit-green.svg';
import ShareitLogoDarkGreen from '../assets/shareit-dark-green.svg';
import GSuiteIcon from '../assets/gsuite-black.svg';
import GSuiteIconGreen from '../assets/gsuite-green.svg';
import GSuiteIconDarkGreen from '../assets/gsuite-dark-green.svg';
import OutlookIcon from '../assets/outlook-black.svg';
import OutlookIconGreen from '../assets/outlook-green.svg';
import OutlookIconDarkGreen from '../assets/outlook-dark-green.svg';
import Constants from '../utils/Constants';

const styles = theme => ({
	button: {
		color: theme.palette.common.white,
	},

	menuItem: {
		borderLeftWidth: theme.spacing(.5),
		borderLeftColor: 'transparent',
		borderLeftStyle: 'solid',

		[theme.breakpoints.up('lg')]: {
			paddingLeft: theme.spacing(3)
		},

		"@media (max-width: 1023px)": {
			fontSize: "1rem"
		},

		// Overriding links style - probably want to do globably - unrelated to material UI
		textDecoration: 'none',
		'&:focus': {
			outlineWidth: '0px'
		}
	},

	drawerPaper: {
		width: theme.drawer.width,
		[theme.breakpoints.up('lg')]: {
			backgroundColor: theme.palette.primary.tan
		}
	},

	signalsFirst: {
		top: theme.spacing(9)
	},

	logo: {
		textAlign: 'center',
		paddingTop: theme.spacing(1),
		[theme.breakpoints.up('lg')]: {
			paddingTop: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(6)
		}
	},

	darkLogoBg: {
		backgroundColor: theme.palette.primary.darkGrey
	},

	menuItemActive: {
		borderLeftWidth: theme.spacing(.5),
		borderLeftColor: theme.palette.primary.main,
		borderLeftStyle: 'solid'
	},

	menuIconActive: {
		color: theme.palette.primary.main
	},

	sectionTitle: {
		fontSize: '0.9rem',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		letterSpacing: theme.spacing(.5),
		marginLeft: theme.spacing(3),
		marginBlockStart: "1em",
		marginBlockEnd: "1em",
		marginInlineStart: theme.spacing(3),
		marginInlineEnd: "0px"
	},

	sectionSeparator: {
		backgroundColor: theme.palette.primary.darkGrey,
		height: '1px',
		width: theme.spacing(6),
		marginTop: theme.spacing(.8),
		marginBottom: theme.spacing(3),
		marginLeft: theme.spacing(3)
	},

	iconContainer: {
		width: theme.spacing(4)
	},

	shareitLogo: {
		width: "1em",
		height: "1em",
		verticalAlign: "-0.2rem"
	},

	customIcon: {
		width: "1.3rem",
		height: "1.3rem",
		verticalAlign: "-0.2rem"
	},

	navWrapper: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(4)
	},

	morePanel: {
		background: "transparent"
	},

	morePanelRoot: {
		"&:before": {
			background: "transparent"
		}
	},

	morePanelExpanded: {
		margin: 0,
		marginTop: theme.spacing(1.5)
	},

	morePanelExpandIcon: {
		position: "absolute",
		left: theme.spacing(2),
		height: `${theme.spacing(3)}px !important`
	},

	morePanelSummaryExpanded: {
		alignItems: "center",
		minHeight: "0px !important",
		margin: "0px !important"
	},

	morePanelTyp: {
		fontSize: "1.14rem",
		paddingLeft: theme.spacing(4.5)
	}
});

class NavigationDrawer extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			moreOpen: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState === this.state &&
			(this.isActive('alert-summary-report') || this.isActive('account-audit-trail'))) {
			this.setState({moreOpen: true});
		}
	}

	handleLegacyLink(url) {
		this.props.handleDrawerToggle();
		window.location.replace(url);
	}

	isActive(matcher) {
		let matches = false,
			isRegEx = matcher instanceof RegExp,
			fullPath = `${this.props.location.pathname}${this.props.location.search}`;

		if (isRegEx) {
			matches = fullPath.match(matcher);
		} else {
			matches = fullPath.indexOf(matcher) > -1;
		}

		return matches;
	}

	render() {
		let hasReportsSection = false,
			{moreOpen} = this.state,
			{classes, theme, authenticatedUser, handleDrawerToggle, tempDrawerOpen,
				installable, handleInstallBtn, csm, history, showSnackbar, signalsFirstEnabled} = this.props,
			currentPath = window.location.pathname+window.location.search,
			exchangeReportPath = authenticatedUser.isDemo ? "/static-exchange-report" : "/exchange-report",
			gsuiteReportPath = authenticatedUser.isDemo ? "/static-gsuite-report" : "/gsuite-report",
			moreContrast = AuthorizedUser.wantsMoreContrast();

		hasReportsSection = AuthorizedUser.isAdmin(authenticatedUser) ||
							(authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) &&
								AuthorizedUser.hasProductOtherThanSocialMedia(authenticatedUser) &&
								authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.REPORTS.APPLICATION));

		const drawerContent = (
			<div>
				<Media query={{minWidth: theme.breakpoints.values.lg}}>
					{matches => {
						return (
							<div>
								<Branding className={`${classes.logo} ${!matches ? classes.darkLogoBg : null}`} secondary={!matches} light={!matches} />
								{!matches ? <ProfileCard history={history} closeHandler={handleDrawerToggle} /> : null}
							</div>
						);
					}}
				</Media>
				{authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.REPORTS.APPLICATION) &&
					authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) ?
					<div>
						<MenuItem className={`${this.isActive('overview') ? classes.menuItemActive : ''} ${classes.menuItem}`}
							component={Link} to={`/overview`} onClick={handleDrawerToggle} tabIndex={0}>
							<span className={classes.iconContainer}>
								<FontAwesomeIcon icon={["fas", "th"]}
									title="overview"
									className={this.isActive('overview') ? classes.menuIconActive : ''} />
							</span> Overview
						</MenuItem>
						<Media query={{minWidth: theme.breakpoints.values.lg}}>
							{matches => !matches ?
								<Divider />
								:
								<div className={classes.sectionSeparator}></div>
							}
						</Media>
					</div> : null
				}
				<Typography variant="h2" className={classes.sectionTitle}>Activity</Typography>
				<MenuItem className={`${this.isActive(/^\/$|activities$|activities\?category=(alerts|saved)/) ? classes.menuItemActive : ''} ${classes.menuItem}`}
					component={Link} to={`/activities?category=alerts`} onClick={handleDrawerToggle} tabIndex={0}>
					<span className={classes.iconContainer}>
						<FontAwesomeIcon
							icon={["fas", "wifi"]}
							transform={{ rotate: 90 }}
							title="alerts"
							className={this.isActive(/^\/$|activities$|activities\?category=(alerts|saved)/) ? classes.menuIconActive : ''} />
					</span> Alerts
				</MenuItem>

				{authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.DISCUSSIONS) ?
					<MenuItem className={`${this.isActive('activities?category=discussions') ? classes.menuItemActive : ''} ${classes.menuItem}`}
						component={Link} to={`/activities?category=discussions`} onClick={handleDrawerToggle} tabIndex={0}>
						<span className={classes.iconContainer}>
							<FontAwesomeIcon
								title="discussions"
								icon={["fal", "comments-alt"]}
								className={this.isActive('activities?category=discussions') ? classes.menuIconActive : ''} />
						</span> Discussions
					</MenuItem> : null
				}

				{hasReportsSection ?
					<div>
						<Media query={{minWidth: theme.breakpoints.values.lg}}>
							{matches => !matches ?
								<Divider />
								:
								<div className={classes.sectionSeparator}></div>
							}
						</Media>
						<Typography variant="h2"  className={classes.sectionTitle}>Reports</Typography>
						{AuthorizedUser.hasProductOtherThanSocialMedia(authenticatedUser) &&
							authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SOCIAL_MEDIA) &&
							authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.DATA.SOCIAL_MEDIA) &&
							authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) &&
							authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.REPORTS.APPLICATION) ?
							<MenuItem className={`${this.isActive('socialmedia-report') ? classes.menuItemActive : ''} ${classes.menuItem}`}
								component={Link} to={`/socialmedia-report`} onClick={handleDrawerToggle} tabIndex={0}>
								<span className={classes.iconContainer}>
									<FontAwesomeIcon
										icon={["far", "at"]}
										title="social media"
										className={this.isActive('socialmedia-report') ? classes.menuIconActive : ''} />
								</span> Social Media
							</MenuItem> : null
						}
						{(authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GMAIL) ||
							authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GOOGLE_HANGOUTS) ||
							authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.GOOGLE_DRIVE)) &&
							authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.DATA.GSUITE) &&
							authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) &&
							authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.REPORTS.APPLICATION) ?
							<MenuItem className={`${this.isActive(gsuiteReportPath) ? classes.menuItemActive : ''} ${classes.menuItem}`}
								component={Link} to={gsuiteReportPath} onClick={handleDrawerToggle} tabIndex={0}>
								<span className={classes.iconContainer}>
									<img
										alt="G Suite"
										className={classes.customIcon}
										src={this.isActive(gsuiteReportPath) ? moreContrast ? GSuiteIconDarkGreen : GSuiteIconGreen : GSuiteIcon} />
								</span> G Suite
							</MenuItem> : null
						}
						{authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.EXCHANGE) &&
							authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.DATA.EXCHANGE) &&
							authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) &&
							authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.REPORTS.APPLICATION) ?
							<MenuItem className={`${this.isActive(exchangeReportPath) ? classes.menuItemActive : ''} ${classes.menuItem}`}
								component={Link} to={exchangeReportPath} onClick={handleDrawerToggle} tabIndex={0}>
								<span className={classes.iconContainer}>
									<img
										alt="Outlook"
										className={classes.customIcon}
										src={this.isActive(exchangeReportPath) ? moreContrast ? OutlookIconDarkGreen : OutlookIconGreen : OutlookIcon} />
								</span> Outlook
							</MenuItem> : null
						}
						{authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.TIPS) &&
							authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.DATA.TIPS) &&
							authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) &&
							authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.REPORTS.APPLICATION) ?
							<MenuItem className={`${this.isActive('shareit-report') ? classes.menuItemActive : ''} ${classes.menuItem}`}
								component={Link} to={`/shareit-report`} onClick={handleDrawerToggle} tabIndex={0}>
								<span className={classes.iconContainer}>
									<img
										alt="Shareit"
										className={classes.shareitLogo}
										src={this.isActive('shareit-report') ? moreContrast ? ShareitLogoDarkGreen : ShareitLogoGreen : ShareitLogo} />
								</span> Shareit
							</MenuItem> : null
						}
						{AuthorizedUser.isAdmin(authenticatedUser) ?
							<ExpansionPanel
								elevation={0}
								className={classes.morePanel}
								classes={{
									root: classes.morePanelRoot,
									expanded: classes.morePanelExpanded
								}}
								expanded={moreOpen}
								onChange={(e, expanded) => this.setState({moreOpen: expanded})}>
								<ExpansionPanelSummary
									classes={{
										expandIcon: classes.morePanelExpandIcon,
										expanded: classes.morePanelSummaryExpanded
									}}
									expandIcon={<FontAwesomeIcon icon={["far", "angle-down"]} />}>
									<Typography className={classes.morePanelTyp}>{moreOpen ? "Less" : "More..."}</Typography>
								</ExpansionPanelSummary>
								<MenuItem className={`${this.isActive('account-audit-trail') ? classes.menuItemActive : ''} ${classes.menuItem}`}
									component={Link} to={"/account-audit-trail"} onClick={handleDrawerToggle} tabIndex={0}>
									<span className={classes.iconContainer}>
										<FontAwesomeIcon
											title="account change audit trail"
											icon={["far", "tasks"]}
											className={this.isActive('account-audit-trail') ? classes.menuIconActive : ''} />
									</span> Audit Trail
								</MenuItem>
								<MenuItem className={`${this.isActive('alert-summary-report') ? classes.menuItemActive : ''} ${classes.menuItem}`}
									component={Link} to={"/alert-summary-report"} onClick={handleDrawerToggle} tabIndex={0}>
									<span className={classes.iconContainer}>
										<FontAwesomeIcon
											title="alert summary report"
											icon={["fal", "list-alt"]}
											className={this.isActive('alert-summary-report') ? classes.menuIconActive : ''} />
									</span> Alert Summary
								</MenuItem>
							</ExpansionPanel> : null
						}
					</div> : null
				}

				{authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.ACCOUNT_CONFIG) &&
					(authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.LOCATIONS) ||
					authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.LOCAL) ||
					authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.USER)) ?
					<div>
						<Media query={{minWidth: theme.breakpoints.values.lg}}>
							{matches => !matches ?
								<Divider />
								:
								<div className={classes.sectionSeparator}></div>
							}
						</Media>
						<Typography variant="h2" className={classes.sectionTitle}>Account</Typography>
					</div> : null
				}

				{authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.LOCATIONS) &&
					authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.ACCOUNT_CONFIG) ?
					<MenuItem className={`${this.isActive('/locations') ? classes.menuItemActive : ''} ${classes.menuItem}`}
						component={Link} to={`/locations`} onClick={handleDrawerToggle} tabIndex={0}>
						<span className={classes.iconContainer}>
							<FontAwesomeIcon
								title="locations"
								icon={["far", "map-marker-alt"]}
								className={this.isActive('locations') ? classes.menuIconActive : ''} />
						</span> Locations
					</MenuItem> : null
				}

				{/*authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.LOCAL) &&
					authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.ACCOUNT_CONFIG) &&
					authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.EVENTS) ?
					<MenuItem className={`${this.isActive('/events') ? classes.menuItemActive : ''} ${classes.menuItem}`}
						component={Link} to={`/events`} onClick={handleDrawerToggle} tabIndex={0}>
						<span className={classes.iconContainer}>
							<FontAwesomeIcon
								title="events"
								icon={["far", "calendar-star"]}
								className={this.isActive('events') ? classes.menuIconActive : ''} />
						</span> Events+
					</MenuItem> : null
				*/}

				{authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.LOCAL) &&
					authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.ACCOUNT_CONFIG) &&
					authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SOCIAL_MEDIA) &&
					authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.DATA.SOCIAL_MEDIA) ?
					<MenuItem className={classes.menuItem} component={'a'} onClick={() => this.handleLegacyLink(`/local?return=`+currentPath)} tabIndex={0}>
						<span className={classes.iconContainer}>
							<FontAwesomeIcon icon={["far", "users"]} title="people, places, events" />
						</span> People, Places, Events
					</MenuItem> : null
				}

				{authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.MANAGE.USER) &&
					authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.ACCOUNT_CONFIG) ?
					<MenuItem className={`${this.isActive(/\/users$/) ? classes.menuItemActive : ''} ${classes.menuItem}`}
						component={Link} to={`/users`} onClick={handleDrawerToggle} tabIndex={0}>
						<span className={classes.iconContainer}>
							<FontAwesomeIcon
								title="team members"
								icon={["far", "user"]}
								className={this.isActive(/\/users$/) ? classes.menuIconActive : ''} />
						</span> Team Members
					</MenuItem> : null
				}

				<Media query={{minWidth: theme.breakpoints.values.lg}}>
					{matches => !matches ?
						<Divider />
						:
						<div className={classes.sectionSeparator}></div>
					}
				</Media>
				<Media query={{minWidth: theme.breakpoints.values.lg}}>
					{matches => !matches ?
						<div>
							{installable &&
								<MenuItem
									className={classes.menuItem}
									onClick={() => {handleDrawerToggle(); handleInstallBtn();}}>Install App</MenuItem> || null}
							<MenuItem
								className={`${this.isActive('settings') ? classes.menuItemActive : "" } ${classes.menuItem}`}
								component={Link} to={`/settings`}
								onClick={handleDrawerToggle}>
								Settings
							</MenuItem>
							<MenuItem className={classes.menuItem} component={Link} to={`/logout`} onClick={handleDrawerToggle}>
								Sign Out
							</MenuItem>
							{authenticatedUser.ghosting ?
								<MenuItem className={classes.menuItem} component={'a'} onClick={() => this.handleLegacyLink(`/accounts/users/unghost`)}>
									Unghost
								</MenuItem> : null
							}
							<Divider />
							<SupportContact csm={csm} />
							<Footer
								history={history}
								closeHandler={handleDrawerToggle} />
						</div>
						:
						<React.Fragment>
							<div>
								{authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.REPORTS.APPLICATION) &&
									authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.REPORTING) ?
									<aside>
										<Typography variant="h3" className={classes.sectionTitle} style={{letterSpacing: "inherit"}}>
											Over Past 7 Days<br />
											<span style={{fontWeight: "normal"}}>Alerts Received</span>
										</Typography>
										<div className={classes.navWrapper}>
											<NavigationReport showSnackbar={showSnackbar} />
										</div>
										<div className={classes.sectionSeparator} style={{marginTop: 18}}></div>
									</aside> : null
								}
							</div>
							<Media query={{minHeight: '1098px'}}>
								{matches =>
									<div style={{position: "relative", height: 50}}>
										<Footer history={history} anchor={matches} />
									</div>
								}
							</Media>
						</React.Fragment>
					}
				</Media>
			</div>
		);

		return (
			<Media query={{minWidth: theme.breakpoints.values.lg}}>
				{matches =>
					matches ? (
						<Drawer
							open
							anchor="left"
							variant="permanent"
							classes={{
								paper: `${classes.drawerPaper} ${signalsFirstEnabled ? classes.signalsFirst : ""}`
							}}>
							{drawerContent}
						</Drawer>
					) : (
						<SwipeableDrawer
							variant="temporary"
							anchor="right"
							open={tempDrawerOpen}
							onOpen={handleDrawerToggle}
							onClose={handleDrawerToggle}
							/* disableBackdropTransition={!iOS} /* for lower end devices */
							/* disableDiscovery={iOS} /* this disables swipe to go back, maybe can get around it? */
							classes={{
								paper: classes.drawerPaper,
							}}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}>
							{drawerContent}
						</SwipeableDrawer>
					)
				}
			</Media>
		);
	}
}

NavigationDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func,
	authenticatedUser: PropTypes.object.isRequired,
	handleDrawerToggle: PropTypes.func.isRequired,
	tempDrawerOpen: PropTypes.bool,
	installable: PropTypes.bool,
	handleInstallBtn: PropTypes.func,
	csm: PropTypes.object,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	signalsFirstEnabled: PropTypes.bool
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => {/*console.log("NAVIGATION"); */return <NavigationDrawer {...props} authenticatedUser={authenticatedUser} ref={ref} />}}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "NavigationDrawer";

export default withRouter((withStyles(styles)(withTheme((React.forwardRef(dNameFunc))))));
