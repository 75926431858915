import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Diff from "../utils/Diff";
import ReadMore from '../utils/ReadMore';
import AWS from '../utils/AWS';
import Constants from '../utils/Constants';
import HTMLEntities from "../utils/HTMLEntities";
import TwitterRedaction from "./TwitterRedaction";
import GSuiteComments from "./GSuiteComments";
import MoreOrLess from "./MoreOrLess";

import {
	Typography,
	withStyles
} from '@material-ui/core';

const styles = theme => ({
	contentWrap: {
		fontWeight: 300,
		overflowWrap: "break-word",
		marginBottom: theme.spacing(1)
	},

	redactionMsg: {
		marginBottom: theme.spacing(1)
	}
});

const imgSrc = /\${img[^}]*src="([^"]*)"[^}]*}/g;
const templateStr = /\${(img[^}]*)}/g;
const styleSizes = /width: [^;]+; height: [^;]+;/g;

class ActivityContent extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			hasImage: false
		};
	}

	componentDidMount() {
		let {patch, anchorText} = this.props,
			str = patch ? patch : anchorText,
			hasImage = imgSrc.exec(str);

		if (hasImage && hasImage.length) {
			this.setState({hasImage: true});
		}
	}

	makeBold(content, words) {
		if (content && words && words.length) {
			content = words.reduce((result, theWords) => {
				let regex = new RegExp(`\\b(${theWords})\\b`, "ig");
				return result.replace(regex, "<b>$1</b>");
			}, content);
		}

		return content;
	}

	replaceImageTags(str) {
		let result,
			srcs = {};

		/* eslint-disable no-cond-assign */
		while(result = imgSrc.exec(str)) {
			if(result && result.length) {
				srcs[result[1]] = AWS.getSecureUrl(result[1]);
			}
		}
		/* eslint-disable no-cond-assign */

		Object.keys(srcs).forEach(s3Url => {
			str = str.replace(s3Url, srcs[s3Url]);
		});

		if (typeof str !== 'undefined') {
			str = str.replace(templateStr, "<$1>").replace(styleSizes, "width: 100%; height: auto;");
		}

		return str;
	}

	render() {
		let {hasImage} = this.state,
			{classes, content, originalContent, patch, patchRender, color,
				comments, anchorText, time, authorName, libraryTopics, inlineReadMore, rightReadMore,
				sourceId, subSourceId, sourceIcon, boldWords, readMoreLines, twitterRedacted, history, expanded, category} = this.props,
			hasBoldWords = boldWords && boldWords.length ? true : false,
			isGDoc = sourceId === Constants.SOURCES.IDS.GOOGLE_DOCS,
			isGSuiteComment = subSourceId === Constants.SOURCES.IDS.GSUIT_COMMENT;

		if (patch == null) {
			content = HTMLEntities.decode(content);
			originalContent = HTMLEntities.decode(originalContent);
		} else if (isGDoc) {
			patch = this.replaceImageTags(patch);
		}

		if (isGSuiteComment) {
			anchorText = this.replaceImageTags(HTMLEntities.decode(anchorText));
		}

		/***********************************************************************
			TODO: Temporarily only preserveHtml when there are words to bold
				to fix the browser freeze bug. Need to figure out a
				better way to truncate and also allow html. Current
				idea is to use CSS, determine if there is overflow,
				use xss to sanitize the html and use dangerouslySetInnerHTML
		***********************************************************************/

		return twitterRedacted && twitterRedacted !== Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO ? (
			<TwitterRedaction
				className={classes.redactionMsg}
				twitterRedacted={twitterRedacted}
				history={history}
				isAlert={category === Constants.ACTIVITY.CATEGORIES.ALERTS.ID} />
		) : (
			<Typography color={color} component="div" className={classes.contentWrap}>
				{isGSuiteComment ? (
					<GSuiteComments
						hasImage={hasImage}
						content={content}
						time={time}
						authorName={authorName}
						comments={comments}
						anchorText={anchorText}
						sourceIcon={sourceIcon}
						libraryTopics={libraryTopics} />
				) : patch ? patchRender ? patchRender(patch) : (
					<MoreOrLess
						hasImage={hasImage}
						expanded={expanded}
						inline={inlineReadMore}
						right={rightReadMore}
						content={<Diff patch={patch} />} />
				) : hasBoldWords ? (
					<ReadMore
						showInline
						lines={readMoreLines}
						preserveHtml={hasBoldWords}
						html={this.makeBold(content, boldWords)}
						originalContent={this.makeBold(originalContent, boldWords)} />
				) : (
					<ReadMore
						showInline
						lines={readMoreLines}
						originalContent={originalContent}>
						{content}
					</ReadMore>
				)}
			</Typography>
		);
	}
}

ActivityContent.defaultProps = {
	readMoreLines: 7
};

ActivityContent.propTypes = {
	classes: PropTypes.object.isRequired,
	content: PropTypes.string,
	patch: PropTypes.string,
	patchRender: PropTypes.func,
	originalContent: PropTypes.string,
	color: PropTypes.string,
	boldWords: PropTypes.array,
	readMoreLines: PropTypes.number,
	twitterRedacted: PropTypes.string,
	history: PropTypes.object,
	category: PropTypes.number,
	sourceId: PropTypes.number,
	subSourceId: PropTypes.string,
	sourceIcon: PropTypes.object,
	libraryTopics: PropTypes.array,
	comments: PropTypes.array,
	anchorText: PropTypes.string,
	time: PropTypes.string,
	authorName: PropTypes.string,
	expanded: PropTypes.bool,
	inlineReadMore: PropTypes.bool,
	rightReadMore: PropTypes.bool
};

export default withStyles(styles)(ActivityContent);
