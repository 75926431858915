import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
	fieldRow: {
		width: "100%",

		// expected last one to be button / row of buttons
		'&:last-child': {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(4)
		},
	},
});

class StandardFormLayout extends Component {
	constructor(props) {
		super(props);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	handleKeyPress(event) {
		// This prevents form submission on hitting the "enter/return" key
		// Add the prop "submitOnKeyPress" to override
		if (event.charCode === 13 && !this.props.submitOnKeyPress) {
			event.preventDefault();
		}
	}

	render() {
		let { children, classes } = this.props;
		let fullChildren = [];
		let filteredChildren;

		children.map((rowContent) => {
			if (Array.isArray(rowContent)) {
				rowContent.map((rowChild) => {
					return (
						fullChildren.push(rowChild)
					);
				});
			}

			return (
				fullChildren.push(rowContent)
			);
		});

		filteredChildren = fullChildren.filter(child => !Array.isArray(child));

		return (
			<Grid container direction="column" alignItems="center" onKeyPress={this.handleKeyPress}>
				{filteredChildren.map((rowContent) => {
					if (rowContent) {
						let key = "stdfrm_" + (rowContent.props.id || rowContent.props.name || rowContent.props.formKey);
						return (
							<Grid key={key} item className={classes.fieldRow}>
								{rowContent}
							</Grid>
						);
					}
					return null;
				})}
			</Grid>
		);
	}
}

StandardFormLayout.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	submitOnKeyPress: PropTypes.bool
};

export default withStyles(styles)(StandardFormLayout);
