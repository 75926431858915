import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SourceIcon from './SourceIcon';
import SourceImage from './SourceImage';

class SourceIndicator extends Component {
	render() {
		let {icon, image} = this.props;

		return icon ? <SourceIcon {...this.props} />
			: image ? <SourceImage {...this.props} />
				: null;
	}
}

SourceIndicator.propTypes = {
	icon: PropTypes.string,
	image: PropTypes.string
};

export default SourceIndicator;