import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Constants from "../utils/Constants";

import { withStyles } from '@material-ui/core';

const styles = theme => ({
	circle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50%",
		border: "1px solid transparent",
		backgroundColor: theme.palette.grey[200]
	}
});

class AuditTrailIcon extends Component {
	render() {
		let {classes, type, iconSize} = this.props,
			config = Constants.ACTIVITY.AUDIT_TRAIL[type.toUpperCase()];

		return config ? (
			<span className={classes.circle} style={{width: iconSize, height: iconSize}}>
				<FontAwesomeIcon icon={["fas", config.ICON]} color={config.COLOR} title={config.ID} />
			</span>
		) : null;
	}
}

AuditTrailIcon.defaultProps = {
	iconSize: 28
};

AuditTrailIcon.propTypes = {
	classes: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	iconSize: PropTypes.number
};

export default withStyles(styles)(AuditTrailIcon);