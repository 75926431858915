import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Fido from '../../utils/Fido';
import Constants from '../../utils/Constants';
import DeepMerge from "../../utils/DeepMerge";
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import OverviewReportLayout from "../components/OverviewReportLayout";
import SparkChartReportItem from "../components/SparkChartReportItem";
import EmailsAndAlertsReport from "../components/EmailsAndAlertsReport";
import RecentPostsItem from "../components/RecentPostsItem";
import TopOffensivePeople from "../components/TopOffensivePeople";
import EmailEngagementReportItem from "../components/EmailEngagementReportItem";
import ChartClasses from "../components/ChartClasses";
import EmailSentimentReportItem from "../components/EmailSentimentReportItem";

//import {emailOverviewData} from "../../data/reports";

class EmailOverviewReport extends Component {
	constructor(props, context) {
		super(props, context);

		this.sparkCardsFido = new Fido();
		this.graphFido = new Fido();
		this.activityFido = new Fido();
		this.engagementFido = new Fido();
		this.senderFido = new Fido();
		this.recipientFido = new Fido();
		this.sentimentFido = new Fido();

		let {location, days, authenticatedUser} = this.props,
			accountParam = Fido.getSearchParam("account", location.search),
			filter = {
				source: "exchange",
				previousDays: Number(Fido.getSearchParam("previousDays", location.search)) || days,
				account: accountParam ? (accountParam.split(",")).map(item => Number(item)) : authenticatedUser.childAccounts.map(item => item.id)
			};

		this.state = {
			filter,
			defaultFilter: Object.assign({}, filter),

			totalPosts: null,
			postsAmountChange: null,
			postsPercentageChange: null,

			totalImages: null,
			imagesAmountChange: null,
			imagesPercentageChange: null,
			postsAndImages: [],

			totalAlerts: null,
			alertsAmountChange: null,
			alertsPercentageChange: null,
			alerts: [],

			totalDiscussions: null,
			discussionsAmountChange: null,
			discussionsPercentageChange: null,
			discussions: [],

			alertRatio: null,
			ssiAlertRatio: null,
			alertRatioOverTime: [],

			authors: null,
			authorsAmountChange: null,
			authorsPercentageChange: null,
			authorsGrouped: [],

			sparkCardsFidoError: false,

			splolumnEmails: null,
			splolumnAlerts: null,
			splolumnFidoError: false,

			activities: null,
			recentFidoError: false,

			topSenders: null,
			sendersFidoError: false,

			topReceivers: null,
			recipientsFidoError: false,

			emailEngagement: null,
			engagementFidoError: false,

			sentimentData: null,
			sentimentFidoError: false
		};

		this.executeFilter = this.executeFilter.bind(this);
	}

	componentDidMount() {
		this.setupReport();
	}

	componentWillUnmount() {
		this.dropThem();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.search === "" && prevProps.location.search !== "") {
			let {filter} = this.state,
				{authenticatedUser} = this.props;

			filter.account = authenticatedUser.childAccounts.map(item => item.id);
			this.setState({
				filter: filter,
				defaultFilter: Object.assign({}, filter)
			});
		}
	}

	dropThem() {
		this.sparkCardsFido.dropIt();
		this.graphFido.dropIt();
		this.activityFido.dropIt();
		this.engagementFido.dropIt();
		this.senderFido.dropIt();
		this.recipientFido.dropIt();
		this.sentimentFido.dropIt();
	}

	setupReport() {
		this.props.updateHeader({title: "Outlook", primary: true});
		this.loadData();
	}

	loadData() {
		this.setState({
			postsAndImages: [],
			alerts: [],
			discussions: [],
			authorsGrouped: [],
			splolumnEmails: null,
			activities: null,
			emailEngagement: null,
			topReceivers: null,
			topSenders: null,
			sentimentData: null
		}, () => {
			this.loadSparkData();
			this.loadEmailsAndAlertsData();
			this.loadRecentActivityData();
			this.loadSenderOffensiveData();
			this.loadRecipientOffensiveData();
			this.loadEngagementData();
			this.loadSentimentData();
		});
	}

	async loadSparkData() {
		let data = await this.sparkCardsFido.fetch("/reports/exchange/spark", {query: this.state.filter})
			.catch(error => {
				this.setState({sparkCardsFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState(data);
		}
	}

	async loadEmailsAndAlertsData(query) {
		let data = await this.graphFido.fetch("/reports/exchange/alerts-associations", {query: this.state.filter})
			.catch(error => {
				this.setState({splolumnFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			// TEMP
			/*this.setState({
				splolumnEmails: alertsAndEmails.associations,
				splolumnAlerts: alertsAndEmails.alerts
			});*/
			this.setState({
				splolumnEmails: data.emailAssociations,
				splolumnAlerts: data.alerts
			});
		}
	}

	async loadRecentActivityData() {
		let data = await this.activityFido.fetch("/activities", DeepMerge.merge(
			{query: {limit: 5}},
			{query: this.state.filter}
		))
			.catch(error => {
				this.setState({recentFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState(data);
		}
	}

	async loadSenderOffensiveData() {
		let data = await this.senderFido.fetch("/reports/exchange/top-senders", DeepMerge.merge(
			{query: {limit: 5}},
			{query: this.state.filter}
		))
			.catch(error => {
				this.setState({sendersFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			// TEMP
			//this.setState({topSenders: emailOverviewData.topSenders});
			this.setState(data);
		}
	}

	async loadRecipientOffensiveData() {
		let data = await this.recipientFido.fetch("/reports/exchange/top-receivers", DeepMerge.merge(
			{query: {limit: 5}},
			{query: this.state.filter}
		))
			.catch(error => {
				this.setState({recipientsFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			// TEMP
			//this.setState({topReceivers: emailOverviewData.topReceivers});
			this.setState(data);
		}
	}

	async loadEngagementData() {
		let data = await this.engagementFido.fetch("/reports/exchange/associations-by-day-of-week", {query: this.state.filter})
			.catch(error => {
				this.setState({engagementFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({emailEngagement: data.associations});
		}
	}

	async loadSentimentData() {
		let data = await this.engagementFido.fetch("/reports/email-sentiment", DeepMerge.merge(
			{query: {sourceId: Constants.SOURCES.IDS.EXCHANGE}},
			{query: this.state.filter}
		))
			.catch(error => {
				this.setState({sentimentFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({sentimentData: data});
		}
	}

	getFilterOpts(filterObj) {
		let opts,
			{filter} = this.state;

		filter.account = [];

		if (!filterObj.previousDays) {
			delete filter.previousDays;
		}

		Number(filter.previousDays);

		opts = DeepMerge.merge(filter, filterObj);

		// if all selected, don't include as url param
		if (Array.isArray(opts.account) && opts.account.length === this.props.authenticatedUser.childAccounts.length) {
			opts.account = [];
		}

		return opts;
	}

	executeFilter(filterObj) {
		let opts = this.getFilterOpts(filterObj);
		this.dropThem();
		delete opts.source;
		this.props.history.replace(Fido.buildPath(`/exchange-report`, opts));
		this.setState({filter: opts}, this.loadData);
	}

	render() {
		const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 2});
		let {history, location, days, authenticatedUser} = this.props,
			{filter,
				totalPosts, postsAmountChange, postsPercentageChange, postsAndImages,
				totalImages, imagesAmountChange, imagesPercentageChange,
				totalAlerts, alertsAmountChange, alertsPercentageChange, alerts,
				totalDiscussions, discussionsAmountChange, discussionsPercentageChange, discussions,
				alertRatio, ssiAlertRatio,
				authors, authorsGrouped, authorsPercentageChange, authorsAmountChange,
				sparkCardsFidoError,
				splolumnEmails, splolumnAlerts, splolumnFidoError,
				activities, recentFidoError,
				topSenders, sendersFidoError,
				topReceivers, recipientsFidoError,
				sentimentData, sentimentFidoError,
				emailEngagement, engagementFidoError, defaultFilter} = this.state,
			source = filter.source,
			previousDays = Number(filter.previousDays || days),
			recentAlertsTitle = (
				<div>
					<FontAwesomeIcon title="recent alerts" icon={["fas", "wifi"]} transform={{ rotate: 90 }} /> Most Recent Alerts
				</div>
			),
			sparkCards = [
				<ReportDataMasseuse
					key={"spark0"}
					dataConfig={[{data: postsAndImages, key: "period", valueKey: "associations"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={sparkCardsFidoError}
							title="Emails Scanned"
							value={totalPosts}
							data={data[0]}
							change={postsAmountChange}
							previousDays={previousDays}
							percentChange={postsPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark1"}
					dataConfig={[{data: postsAndImages, key: "period", valueKey: "images"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={sparkCardsFidoError}
							title="Images Scanned"
							value={totalImages}
							data={data[0]}
							change={imagesAmountChange}
							previousDays={previousDays}
							percentChange={imagesPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark2"}
					dataConfig={[{data: alerts, key: "period", valueKey: "alerts"}]}
					render={data =>
						<SparkChartReportItem
							invertChevron
							fidoError={sparkCardsFidoError}
							title="Alerts"
							value={totalAlerts}
							data={data[0]}
							change={alertsAmountChange}
							previousDays={previousDays}
							percentChange={alertsPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark4"}
					dataConfig={[{data: alerts, key: "period", valueKey: "alert_ratio"}]}
					render={data =>
						<SparkChartReportItem
							invertChevron
							valueIsPercentage
							excludePerDay
							fidoError={sparkCardsFidoError}
							title="Alert Ratio"
							value={alertRatio}
							data={data[0]}
							caption={`Benchmark: ${numFormatter.format(ssiAlertRatio)}%`}
							forceGreen={alertRatio < ssiAlertRatio} />
					} />,
				<ReportDataMasseuse
					key={"spark5"}
					dataConfig={[{data: authorsGrouped, key: "period", valueKey: "distinct_authors"}]}
					render={data =>
						<SparkChartReportItem
							excludePerDay
							fidoError={sparkCardsFidoError}
							title="Alert Senders"
							value={authors}
							data={data[0]}
							change={authorsAmountChange}
							previousDays={previousDays}
							percentChange={authorsPercentageChange} />
					} />,
				<ReportDataMasseuse
					key={"spark3"}
					dataConfig={[{data: discussions, key: "period", valueKey: "alerts"}]}
					render={data =>
						<SparkChartReportItem
							fidoError={sparkCardsFidoError}
							title="Discussions"
							value={totalDiscussions}
							data={data[0]}
							change={discussionsAmountChange}
							previousDays={previousDays}
							percentChange={discussionsPercentageChange} />
					} />
			];

		return (
			<OverviewReportLayout
				location={location}
				sparkCards={sparkCards}
				previousDays={previousDays}
				filterCallback={this.executeFilter}
				selectedAccounts={defaultFilter && defaultFilter.account}>
				<ChartClasses
					fullWidth
					render={props =>
						<EmailsAndAlertsReport
							{...props}
							emails={splolumnEmails}
							alerts={splolumnAlerts}
							previousDays={previousDays}
							fidoError={splolumnFidoError} />
					}/>
				{/*authenticatedUser.ghosting ||
					authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.EMAIL_SENTIMENT) ?
					<EmailSentimentReportItem
						fullWidth
						data={sentimentData}
						previousDays={previousDays}
						fidoError={sentimentFidoError} />
					: null*/}
				<TopOffensivePeople
					history={history}
					people={topSenders}
					nameKey="user"
					fidoError={sendersFidoError}
					title="Top Senders of Email Alerts"
					noDataMessage="There are no senders for this time period."
					buttonClickHandler={() => history.push(`/activities?category=alerts&source=${source}`)} />
				<TopOffensivePeople
					history={history}
					people={topReceivers}
					nameKey="user"
					fidoError={recipientsFidoError}
					title="Top Recipients of Email Alerts"
					noDataMessage="There are no recipients for this time period."
					buttonClickHandler={() => history.push(`/activities?category=alerts&source=${source}`)} />
				<RecentPostsItem
					activities={activities}
					history={history}
					fidoError={recentFidoError}
					title={recentAlertsTitle}
					itemClickPath="/activities"
					buttonText="view all alerts"
					noDataMessage="There are no recent alerts for this time period."
					buttonClickHandler={() => history.push(`/activities?category=alerts&source=${source}`)} />
				<EmailEngagementReportItem
					previousDays={previousDays}
					fidoError={engagementFidoError}
					emailEngagement={emailEngagement} />
			</OverviewReportLayout>
		);
	}
}

EmailOverviewReport.defaultProps = {
	days: 30
};

EmailOverviewReport.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	days: PropTypes.number,
	updateHeader: PropTypes.func,
	resetHeader: PropTypes.func,
	showSnackbar: PropTypes.func,
	authenticatedUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <EmailOverviewReport {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "EmailOverviewReport";

export default (React.forwardRef(dNameFunc));
