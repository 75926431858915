import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fido from '../../utils/Fido';
import Constants from "../../utils/Constants";
import FilterSelect from "../../components/FilterSelect";
import AccountFilterField from "../../components/AccountFilterField";

import { withStyles, Grid, Divider, withWidth } from '@material-ui/core';

const styles = theme => ({
	root: {
		padding: theme.spacing(2),

		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(4)
		},

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(6),
			paddingLeft: theme.spacing(8),
			paddingRight: theme.spacing(8)
		},

		// added previousDays into chart cards so needs less padding
		// in between certain breakpoints so text / chart doesn't break out of cards
		[theme.breakpoints.up('lg')]: {
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3)
		},

		[theme.breakpoints.up(1070)]: {
			padding: theme.spacing(6)
		},

		[theme.breakpoints.up('xl')]: {
			padding: theme.spacing(6),
			paddingLeft: theme.spacing(10),
			paddingRight: theme.spacing(10)
		}
	},

	sparkCardGridItem: {
		paddingTop: `${theme.spacing(3)}px !important`
	},

	sparkCardContainer: {
		"& div:last-child": {
			"& div": {
				borderRight: 0
			}
		}
	},

	dividerItem: {
		paddingBottom: 0
	},

	divider: {
		height: 2
	},

	clock: {
		marginRight: 0
	},

	accountSelectField: {
		marginLeft: theme.spacing(1)
	}
});

class OverviewReportLayout extends Component {
	static get defaultPrevDays() {
		return 30;
	}

	constructor(props, context) {
		super(props, context);

		let filter = this.getFilterObj();
		this.state = {
			filter,
			days: Constants.REPORT.DAYS
		};

		this.filterChanged = this.filterChanged.bind(this);
		this.shouldReset = this.shouldReset.bind(this);
	}

	shouldReset(prevProps) {
		if (this.props.location.search === "" && prevProps.location.search !== "") {
			this.setState({
				filter: this.getFilterObj()
			}, () => this.filter(this.state.filter), true);

			return true;
		}

		return false;
	}

	getFilterObj() {
		let filter = Fido.getSearchParamsObject();

		if("account" in filter && !Array.isArray(filter.account)) {
			filter.account = [parseInt(filter.account, 10)];
		}

		return filter;
	}

	clearDataTables() {
		document.querySelectorAll("[class='highcharts-data-table']").forEach(el => {el.remove();});
	}

	filterChanged(event) {
		let {filter} = this.state;

		if (event.target.name === "previousDays") {
			event.target.value = event.target.value[0] || event.target.value;
		}

		filter[event.target.name] = event.target.value;
		this.filter(filter);
	}

	filter(filterObj) {
		this.clearDataTables();
		this.setState({
			filter: filterObj
		}, () => this.props.filterCallback(this.state.filter));
	}

	render() {
		let fullWidthSizing = {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
			biColumnSizing = {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
			sparkCardSizing = {xs: 12, sm: 4, md: 4, lg: 4, xl: 2},
			{classes, previousDays, sparkCards, children, width, selectedAccounts, location} = this.props,
			{days} = this.state;

		return (
			<div className={classes.root}>
				<Grid container spacing={4} alignItems="flex-start">
					<Grid item {...fullWidthSizing}>
						<Grid container justify={width.includes("xs") ? "center" : "flex-end"}>
							<Grid item>
								<FilterSelect
									singleSelect
									name="previousDays"
									options={days}
									location={location}
									shouldReset={this.shouldReset}
									defaultSelections={[previousDays || OverviewReportLayout.defaultPrevDays]}
									menuTitle={`${previousDays} Days`}
									handleUpdate={this.filterChanged} />
							</Grid>
							<Grid item>
								<AccountFilterField
									name="account"
									location={location}
									shouldReset={this.shouldReset}
									onChange={this.filterChanged}
									defaultSelections={selectedAccounts}
									className={classes.accountSelectField} />
							</Grid>
						</Grid>
					</Grid>
					{sparkCards && sparkCards.length ?
						<Grid item {...fullWidthSizing} className={classes.sparkCardGridItem}>
							<Grid container
								tabIndex={0}
								role="group"
								aria-hidden="false"
								justify="center"
								alignItems="flex-start"
								className={classes.sparkCardContainer}>
								{sparkCards.map((card, idx) => {
									return <Grid item key={`key-${idx}`} {...sparkCardSizing}>{card}</Grid>;
								})}
							</Grid>
						</Grid> : null
					}
					{children.map((child, idx) => {
						return child ?
							child.props.divider ?
								<Grid item key={`key-${idx}`} {...fullWidthSizing} className={classes.dividerItem}>
									<Divider classes={{root: classes.divider}} />
								</Grid>
								:
								<Grid item key={`key-${idx}`} {...(child.props.fullWidth ? fullWidthSizing : biColumnSizing)}>
									{child}
								</Grid>
							: null;
					})}
				</Grid>
			</div>
		);
	}
}

OverviewReportLayout.propTypes = {
	classes: PropTypes.object.isRequired,
	previousDays: PropTypes.number.isRequired,
	selectedAccounts: PropTypes.array,
	filter: PropTypes.func,
	children: PropTypes.array.isRequired,
	sparkCards: PropTypes.array,
	filterCallback: PropTypes.func,
	location: PropTypes.object,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default withStyles(styles)(withWidth()(OverviewReportLayout));
