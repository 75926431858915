import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AvatarPin from "../assets/avatar-pin.png";
import Constants from "../utils/Constants";
import TwitterRedaction from "./TwitterRedaction";

import { withStyles, withWidth, Typography } from '@material-ui/core';

const styles = theme => ({
	container: {
		height: '40vh',
		width: '100%',
		position: 'relative'
	},

	details: {
		padding: theme.spacing(3),
	},

	secondary: {
		color: theme.palette.text.secondary
	},

	geoFenceIcon: {
		fontSize: "1.1em",
		marginRight: theme.spacing(.75)
	}
});

class PostMap extends Component {
	getGeoFenceText(association) {
		let text = "within geo-fence",
			distance = association["nearest_distance"],
			location = association["nearest_location"];

		if (distance !== 0.0 && location !== "") {
			let numFormatter = new Intl.NumberFormat();
			text = `${numFormatter.format(distance)} mi from ${location}`;
		}

		return text;
	}

	render() {
		let { classes, google, association, width, twitterRedacted, history, category} = this.props,
			content = association ? association.content : null,
			center = {lat: content.latitude, lng: content.longitude},
			zoom = 11;

		return twitterRedacted && twitterRedacted === Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO ? (
			<TwitterRedaction
				twitterRedacted={twitterRedacted}
				history={history}
				isAlert={category === Constants.ACTIVITY.CATEGORIES.ALERTS.ID} />
		): (
			<div>
				{/* Important! Always set the map container height explicitly */}
				<div className={classes.container}>
					<Map google={google}
						zoom={zoom}
						initialCenter={center} >
						<Marker
							name={'Your position'}
							position={center}
							icon={{
								url: AvatarPin
							}} />
					</Map>
				</div>
				<div className={classes.details}>
					<Typography>{width.includes("md") || width.includes("lg") ? "Lat/Long" : "Latitude/Longitude"} of Post Location</Typography>
					<Typography className={classes.secondary}>{content.latitude}{', '}{content.longitude}</Typography>
					<Typography className={classes.secondary}>
						<FontAwesomeIcon
							title="map marker"
							icon={["fal", "map-marker-alt"]}
							className={classes.geoFenceIcon} /><i>{this.getGeoFenceText(association)}</i>
					</Typography>
				</div>
			</div>
		);
	}
}

PostMap.propTypes = {
	classes: PropTypes.object.isRequired,
	key: PropTypes.number,
	association: PropTypes.object,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	twitterRedacted: PropTypes.string,
	google: PropTypes.object,
	history: PropTypes.object.isRequired,
	category: PropTypes.number
};

export default GoogleApiWrapper(
	Constants.ENV.IS_LOCAL ? (
		{apiKey: process.env.REACT_APP_MAPS_KEY}
	) : (
		{apiKey: '', client: process.env.REACT_APP_MAPS_CLIENT_ID}
	)
)(withStyles(styles)(withWidth()(PostMap)));
