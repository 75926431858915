import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { IntercomAPI } from "react-intercom";
import { Button } from '@material-ui/core';
/* eslint-disable no-unused-vars */
import FontAwesomeSetup from './utils/FontAwesomeSetup';
import Fido from './utils/Fido';
/* eslint-enable no-unused-vars */
import ActivityRewrite from './containers/ActivityRewrite';
import Activity from './containers/Activity';
import LocationDetails from './containers/LocationDetails';
import Locations from './containers/Locations';
import EventDetails from './containers/EventDetails';
import Events from './containers/Events';
import UserProfile from './containers/UserProfile';
import UserManager from './containers/UserManager';
import FullScreen from './components/FullScreen';
import PrivacyPolicy from './components/PrivacyPolicy';
import Activities from "./containers/Activities";
import NoMatch from "./components/NoMatch";
import NoMatchAnonymous from "./components/NoMatchAnonymous";
import Settings from "./containers/Settings";
import ResetPassword from "./containers/ResetPassword";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import AuthorizedUserContext from "./utils/AuthorizedUserContext";
import AuthorizedUser from "./utils/AuthorizedUser";
import Constants from './utils/Constants';
import IdGenerator from './utils/IdGenerator';
import OverviewReport from "./reports/containers/OverviewReport";
import GSuiteOverviewReport from "./reports/containers/GSuiteOverviewReport";
import ExchangeOverviewReport from "./reports/containers/ExchangeOverviewReport";
import StaticEmailOverviewReport from "./reports/containers/StaticEmailOverviewReport";
import StaticGsuiteOverviewReport from "./reports/containers/StaticGsuiteOverviewReport";
import ShareitOverviewReport from "./reports/containers/ShareitOverviewReport";
import SocialMediaOverviewReport from "./reports/containers/SocialMediaOverviewReport";
import TrendingTopicPosts from './reports/containers/TrendingTopicPosts';
// TODO: shouldn't these be in containers/?
import UserEngagementReport from "./reports/components/UserEngagementReport";
import TrendingTopicsReport from "./reports/components/TrendingTopicsReport";
import AlertSummary from "./reports/containers/AlertSummary";
import AccountAuditTrail from "./reports/containers/AccountAuditTrail";

class App extends Component {
	constructor(props) {
		super(props);

		this.messages = {
			accessDenied: "Access Denied",
			noAccess: "You do not have access to this page.",
			appUpdateAvailable: "App update available, please refresh.",
			networkOffline: "Your network is offline, cached data is being used.",
			somethingWrong: "Something went wrong",
			pageNotFound: "The page cannot be found."
		};

		// this used to be a ref in index.js, so by doing it this way we might lose some "component" features but currently not needed.
		// we changed it to this because using withRouter doesn't really work when using the ref, you lose the ref
		window.appRoot = this;

		this.bannerRef = React.createRef();
		this.signalsBannerRef = React.createRef();
		this.snackbarInfo = {
			queue: [],
			queueProcessing: false,
			defaultDuration: 5000,
			currentConfig: null,
			snackbarRef: React.createRef()
		};

		this.updateUser = this.updateUser.bind(this);
		this.evictUser = this.evictUser.bind(this);
		this.loadUserSuccessful = this.loadUserSuccessful.bind(this);
		this.loadUserFailed = this.loadUserFailed.bind(this);
		this.logoutCallback = this.logoutCallback.bind(this);

		this.resetHeader = this.resetHeader.bind(this);
		this.updateHeader = this.updateHeader.bind(this);

		this.showSnackbar = this.showSnackbar.bind(this);
		this.updateSnackbarMessage = this.updateSnackbarMessage.bind(this);
		this.snackbarHandleEntering = this.snackbarHandleEntering.bind(this);
		this.snackbarHandleExited = this.snackbarHandleExited.bind(this);
		this.snackbarHandleClose = this.snackbarHandleClose.bind(this);

		this.showBanner = this.showBanner.bind(this);
		this.setBannerClassName = this.setBannerClassName.bind(this);
		this.bannerHandleEntering = this.bannerHandleEntering.bind(this);
		this.bannerHandleEntered = this.bannerHandleEntered.bind(this);
		this.bannerHandleExiting = this.bannerHandleExiting.bind(this);
		this.bannerHandleExited = this.bannerHandleExited.bind(this);

		this.updateNetworkStatus = this.updateNetworkStatus.bind(this);

		this.handleInstallBtn = this.handleInstallBtn.bind(this);
		this.handleInstallPrompt = this.handleInstallPrompt.bind(this);
		this.appRefreshSnackbar = this.appRefreshSnackbar.bind(this);
		this.iOSDlgCloseHandler = this.iOSDlgCloseHandler.bind(this);

		this.shouldRedirectSignals = this.shouldRedirectSignals.bind(this);
		this.redirectSignals = this.redirectSignals.bind(this);

		this.state = {
			csm: {},
			signInUrl: null,
			backUrl: null,
			title: null,
			pageTitle: Constants.PAGE_TITLE,
			primary: null,
			snackbarOpen: false,
			snackbarAction: null,
			snackbarMessage: null,
			snackbarDuration: null,
			banner: null,
			authorized: false,
			hideHeaderbar: false,
			hideMargin: false,
			installable: false,
			showIOSInstallDlg: false,
			tallSnackbar: false,
			bannerClassName: null,
			bannerHeight: 0,
			previousLocation: null
		};
	}

	componentDidMount() {
		if(!this.state.authorized) {
			AuthorizedUser.load((user)=>{
				this.loadUserSuccessful(user);
			}).catch((error) => {
				this.loadUserFailed();
				this.showSnackbar(error.message);
			});

		}

		window.addEventListener('online',  this.updateNetworkStatus);
		window.addEventListener('offline', this.updateNetworkStatus);
		window.addEventListener('beforeinstallprompt', this.handleInstallPrompt);
	}

	componentWillUnmount() {
		window.removeEventListener('online',  this.updateNetworkStatus);
		window.removeEventListener('offline', this.updateNetworkStatus);
		window.removeEventListener('beforeinstallprompt', this.handleInstallPrompt);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// iOS only, initial load when user has to be authorized
		if (Constants.ENV.IS_IOS && this.state.authorized !== prevState.authorized && !window.navigator.standalone) {
			this.setState({showIOSInstallDlg: this.shouldShowIOSInstallDlg(), installable: true});
		}

		if (prevProps.location !== this.props.location) {
			this.onRouteChanged(prevProps);

			if (this.props.history.action !== "POP" && this.props.history.action !== "REPLACE") {
				this.resetScrollPosition();
			}
		}
	}

	onRouteChanged(prevProps) {
		this.setState({previousLocation: prevProps.location});
		if(this.isFocusResetNeeded(prevProps.location, this.props.location)) {
			this.resetFocus();
		}
	}

	isFocusResetNeeded = (prevLocation, location) => {
		// don't really need to consider search params if path changes
		if(location.pathname === prevLocation.pathname) {
			// Add other search parameters whose change should be included in page navigation calculation
			let whiteList = ['category'];
			let prevSearchParams = new URLSearchParams(prevLocation.search);
			let searchParams = new URLSearchParams(location.search);

			// Using for so can abort
			for(let i=0; i<whiteList.length; i++) {
				let paramKey = whiteList[i];
				if(searchParams.get(paramKey) !== prevSearchParams.get(paramKey)) {
					return true;
				}
			}
		} else {
			return true;
		}

		return false;
	}

	resetFocus = () => {
		document.body.focus();
	}

	resetScrollPosition() {
		window.scrollTo({top: 0});
	}

	loadUserSuccessful(user) {
		this.updateUser(user);
		this.loadCsm(user);
		this.removeTanBackground();
	}

	loadUserFailed() {
		this.removeTanBackground();
	}

	loadCsm(user) {
		let poodle = new Fido();
		poodle.fetch(`/accounts/${user.accountId}/csm`)
			.then((data) => {
				if (data) {
					// TODO: in future, refactor to do as a context since it's used in multiple places?
					this.setState({csm: data});
				}
			})
			// TODO: probably only want to show some errors as snackbars
			.catch((error) => this.showSnackbar(error.message));
	}

	iOSDlgCloseHandler() {
		this.setState({showIOSInstallDlg: false});
	}

	// only platforms that support beforeinstallprompt
	handleInstallPrompt(event) {
		/*console.info('handleInstallPrompt');
		if(window.matchMedia('(display-mode: standalone)').matches) {
			console.info('chrome: (display-mode: standalone) test is true');
		}*/

		// Prevent Chrome <= 67 from automatically showing the prompt
		event.preventDefault();
		// Stash the event so it can be triggered later.
		this.installPromptEvent = event;

		// Update the install UI to notify the user app can be installed
		this.setState({'installable': true});
	}

	handleInstallBtn(event) {
		// only platforms that support beforeinstallprompt
		if(this.installPromptEvent) {
			// Show the modal add to home screen dialog
			this.installPromptEvent.prompt();
			// Wait for the user to respond to the prompt
			this.installPromptEvent.userChoice.then((choice) => {
				/*if (choice.outcome === 'accepted') {
					console.log('User accepted the A2HS prompt');
				} else {
					console.log('User dismissed the A2HS prompt');
				}*/
				// Clear the saved prompt since it can't be used again
				this.installPromptEvent = null;
				this.setState({'installable': false});
			});
		// platforms that do NOT support beforeinstallprompt (currently iOS < 11.3)
		} else if(Constants.ENV.IS_IOS && !window.navigator.standalone) {
			this.setState({showIOSInstallDlg: true});
		}
	}

	updateNetworkStatus() {
		if('onLine' in navigator) {
			if(!navigator.onLine) {
				this.showBanner({content: this.messages.networkOffline});
			} else if(navigator.onLine) {
				this.showBanner(false);
			}
		}
	}

	updateUser (user) {
		if ("name" in user && user.name === "Error" && user.message.indexOf('401 Unauthorized') > -1) {
			user = false;
		}
		this.setState({authorized: user});
	}

	removeTanBackground() {
		document.getElementById("backgroundMask").style.display = "none";
	}

	logoutCallback() {
		this.showBanner(false);
		this.evictUser();
	}

	evictUser() {
		let {authorized} = this.state;

		if (authorized && !authorized.ghosting) {
			IntercomAPI("shutdown");
		}

		this.setState({authorized: false});
	}

	updatePageTitle(title) {
		return title && typeof title === 'string' && (window.document.title = this.titleCase(title));
	}

	titleCase(text) {
		return text.split(' ').map(word => {
			// Pretty rough, but not sure it'll matter - if word is less than 3 chars, don't upper case - a, of, etc;
			// might be better to switch to < 4, but not sure anyone will ever notice
			if(word.length < 3) {
				return word;
			} else {
				return word[0].toUpperCase() + word.slice(1);
			}
		}).join(' ');
	}

	/**
	 * Callback used to override header links, title, & back button. Any component that sets them
	 * is responsible for reseting them in `componentWillUnmount`.
	 * NOTE: make sure to call resetHeader (in componentWillUnmount) in any component that calls updateHeader
	 * @param  {Object} options Options used to update HeaderBar
	 */
	updateHeader (options) {
		if (options && options.title) {
			this.updatePageTitle(options.title);
		}

		this.setState(options);
	}

	/**
	 * Callback used to reset headerbar values to standard values
	 * NOTE: make sure to call this (in componentWillUnmount) in any component that calls updateHeader
	 */
	resetHeader () {
		this.updateHeader({
			signInUrl: null,
			backUrl: null,
			title: null,
			pageTitle: Constants.PAGE_TITLE,
			primary: null,
			hideHeaderbar: false,
			hideMargin: false
		});
	}

	/**
	 * Can pass in message string or json object with message, action, duration values
	 */
	showSnackbar(message) {
		let action, duration;
		if(typeof message === "object") {
			action = message.action || null;
			duration = message.duration || null;
			message = message.message;
		}

		// if duration false, snackbar remains until manually closed
		if(duration !== false) {
			duration = duration || this.snackbarInfo.defaultDuration;
		} else {
			duration = null;
		}

		if(message) {
			let id = this.generateSnackbarId();
			this.addToQueue({
				id: id,
				snackbarMessage: message,
				snackbarDuration: duration,
				snackbarAction: action,
				snackbarOpen: true
			});

			this.processQueue();
			return id;
		// if message is false, snackbar will be closed
		} else {
			this.setState({snackbarOpen: false});
			this.snackbarInfo.queueProcessing = false;
		}
	}

	generateSnackbarId() {
		return IdGenerator.getId();
	}

	// msgObj is an object {id: <string>, msg: <string>}
	updateSnackbarMessage(msgObj) {
		if (this.snackbarInfo.currentConfig && this.snackbarInfo.currentConfig.id === msgObj.id) {
			let sb = this.snackbarInfo.snackbarRef.current,
				sbInnerEl = sb ? (sb.querySelector("div")).querySelector("div") : null;

			if (sbInnerEl) {
				sbInnerEl.innerText = msgObj.msg;
			}
		}
	}

	addToQueue(config) {
		if(typeof config === "object") {
			this.snackbarInfo.queue.push(config);
		}
	}

	processQueue() {
		if(this.snackbarInfo.queue.length && !this.snackbarInfo.queueProcessing) {
			this.snackbarInfo.currentConfig = this.snackbarInfo.queue.shift();
			this.snackbarInfo.queueProcessing = this.snackbarInfo.currentConfig.snackbarOpen;
			this.setState(this.snackbarInfo.currentConfig);
		}
	}

	snackbarHandleExited () {
		// if more in queue, send to snackbar
		this.processQueue();
	}

	snackbarHandleEntering () {
		if (this.snackbarInfo.snackbarRef.current && this.snackbarInfo.snackbarRef.current.offsetHeight > 55) {
			this.setState({tallSnackbar: true});
			//this.setState({tallSnackbar: this.snackbarInfo.snackbarRef.current.offsetHeight});
		}
	}

	snackbarHandleClose (event, reason) {
		// other option is "timeout" (autoHideDuration expired)
		if (reason !== "clickaway" && this.snackbarInfo.currentConfig != null && this.snackbarInfo.currentConfig.disableClickAway !== true) {
			this.snackbarInfo.queueProcessing = false;
			this.setState({snackbarOpen: false});
			this.snackbarInfo.currentConfig = null;
		}
	}

	appRefreshSnackbar() {
		this.showSnackbar({
			duration: 15000,
			disableClickAway: true,
			message: this.messages.appUpdateAvailable,
			action: (<Button size="small" onClick={(event) => {this.showSnackbar(false);window.location.reload();}} color="primary">Refresh</Button>)
		});
	}

	// only iOS
	shouldShowIOSInstallDlg() {
		let installableCount = localStorage.getItem("installable-count") || 0;
		if (this.state.authorized) {
			if (!localStorage.getItem("installable-dialog-shown")) {
				if (installableCount < 2) {
					localStorage.setItem("installable-count", ++installableCount);
				} else {
					localStorage.setItem("installable-dialog-shown", true);
					return true;
				}
			}
		}
		return false;
	}

	// config = {icon: {icon:[], size: "", className: "", title: ""}, content: "", actions (optional): []}
	// if config is false, banner disappears
	showBanner(config) {
		this.setState({
			banner: config
		});
	}

	setBannerClassName(classname) {
		this.setState({
			bannerClassName: classname
		});
	}

	bannerHandleEntering () {
		if (this.bannerRef.current) {
			this.setState({
				bannerHeight: 1
			});
		}
	}

	bannerHandleEntered () {
		if (this.bannerRef.current) {
			this.setState({
				bannerHeight: this.bannerRef.current.offsetHeight
			});
		}
	}

	bannerHandleExiting () {
		if (this.bannerRef.current) {
			this.setState({
				bannerHeight: this.state.bannerHeight
			});
		}
	}

	bannerHandleExited () {
		if (this.bannerRef.current) {
			this.setState({
				bannerHeight: 0
			});
		}
	}

	spaRedirect(path) {
		this.props.history.push(path);
	}

	shouldRedirectSignals(authorized) {
		// Must have signals first feature on account...
		// and never set useSignals setting
		// or has selected signals
		return authorized && authorized.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SIGNALS_FIRST) &&
			(authorized.useSignals === null || authorized.useSignals);
	}

	redirectSignals(location) {
		const {pathname, search} = location,
			pieces = pathname.split('/'),
			targetPath = pieces[1],
			base = `/${Constants.SIGNALS_PATH}`;
		let signalsPath = base;

		switch(targetPath) {
			case 'v':
				signalsPath = `${base}${pathname}`;
				break;
			case 'activities':
				if(search.includes('category=discussions')) {
					signalsPath = `${base}/trends/topics`;

				// If activity ID follows activities, redirect there
				} else if(parseInt(pieces[2])) {
					signalsPath = `${base}/inbox/${pieces[2]}`;
				}
				break;
			case 'overview':
				signalsPath = `${base}/reports/overview`;
				break;
			default: break;
			// no fallback/default needed b/c started with default path
		}

		//console.info(`Signals First - redirecting "/next${pathname}" to "${signalsPath}"`);
		window.location.replace(signalsPath);
		return null;
	}

	render() {
		return this.redirectSignals(this.props.location);
	}
}

export default withRouter(App);
