import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPlaceholder from 'react-placeholder';

import Menu from './Menu';
import AddCommentForm from './AddCommentForm.js';
import CircledIconButton from "./CircledIconButton";

import { MenuItem, Grid, withStyles, withWidth, Typography } from '@material-ui/core';

const transitionDuration = 250;
const styles = theme => ({
	buttonContainer: {
		textAlign: "end",
	},

	menuIcon: {
		marginRight: theme.spacing(1.25)
	},

	commentForm: {
		transition: `max-width ${transitionDuration/1000}s ease, flex-basis 0.5s ease`
	},

	growCommentForm: {
		[theme.breakpoints.down('sm')]: {
			maxWidth: "100%",
			flexBasis: "100%"
		}
	},

	actionIcon: {
		marginTop: theme.spacing(1)
	},

	rightMargin: {
		marginRight: theme.spacing(1.5)
	}
});

class ActivityActions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null,
			commentFieldFocused: false,
			commentFieldBlurred: false
		};

		this.menuClick = this.menuClick.bind(this);
		this.menuClose = this.menuClose.bind(this);
		this.shouldAnimate = this.shouldAnimate.bind(this);
		this.commentFieldFocused = this.commentFieldFocused.bind(this);
		this.commentFieldBlurred = this.commentFieldBlurred.bind(this);
	}

	menuClick(event) {
		this.setState({ anchorEl: event.target });
	}

	menuClose(event) {
		this.setState({ anchorEl: null });
	}

	shouldAnimate() {
		let {width} = this.props;
		return width.includes("xs") || width.includes("sm");
	}

	commentFieldFocused() {
		if (this.shouldAnimate()) {
			this.setState({commentFieldFocused: true});
		}
	}

	commentFieldBlurred() {
		if (this.shouldAnimate()) {
			this.setState({commentFieldBlurred: true});
			setTimeout(() => {
				this.setState({commentFieldFocused: false, commentFieldBlurred: false});
			}, transitionDuration);
		}
	}

	render() {
		let {anchorEl, commentFieldFocused, commentFieldBlurred} = this.state,
			{actions, addCommentHandler, classes, ready, fidoError, isActivityDeleted} = this.props;

		if(actions && actions.length) {
			let menuActions = [];
			let primaryActions = [];

			actions.map((action, index) => (
				((action.primary === true) ? (
					primaryActions.push(
						<CircledIconButton
							key={action.label}
							text={action.label}
							color={action.color}
							disabled={action.disabled}
							onClick={action.handleClick}
							aria-label={`${action.label} Activity`}
							className={`${classes.actionIcon} ${index+1 !== actions.length ? classes.rightMargin : null}`}>
							<FontAwesomeIcon icon={action.icon} rotation={action.iconRotation} size="xs" title={`${action.label} Activity`} />
						</CircledIconButton>
					)
				) : (
					menuActions.push(
						<MenuItem key={action.label} onClick={(event) => {action.handleClick(); this.menuClose();}}>
							<FontAwesomeIcon
								className={classes.menuIcon}
								icon={action.icon}
								rotation={action.iconRotation}
								size="xs"
								title={`${action.label} Activity`} /> {action.label}
						</MenuItem>
					)
				))
			));

			return (
				<div>
					<ReactPlaceholder type='textRow' ready={ready} style={{height: 35}} showLoadingAnimation={!fidoError}>
						<Grid container alignItems="center" justify={commentFieldFocused ? "flex-start" : "center"}>
							<Grid item
								xs={6} sm={7} md={6} lg={8}
								className={`${classes.commentForm} ${commentFieldFocused && !commentFieldBlurred ? classes.growCommentForm : ""}`}>
								{isActivityDeleted ? (
									<Typography>Comments:</Typography>
								) : (
									<AddCommentForm
										addCommentHandler={addCommentHandler}
										focusHandler={this.commentFieldFocused}
										blurHandler={this.commentFieldBlurred} />
								)}
							</Grid>
							{!commentFieldFocused && !commentFieldBlurred ? (
								<Grid xs item className={classes.buttonContainer}>
									{primaryActions}
									{menuActions.length ? (
										<div>
											<CircledIconButton
												key="More"
												style={{marginTop: 8}}
												className={classes.actionIcon}
												onClick={this.menuClick}
												aria-label="More Options">
												<FontAwesomeIcon icon={["fal", "ellipsis-v"]} size="xs" title="More Options" />
											</CircledIconButton>
											<Menu
												id="long-menu"
												anchorEl={anchorEl}
												open={Boolean(anchorEl)}
												onClose={this.menuClose}>
												{menuActions}
											</Menu>
										</div>
									) : null }
								</Grid>
							) : null}
						</Grid>
					</ReactPlaceholder>
				</div>
			);
		} else {
			return null;
		}
	}
}

ActivityActions.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	actions: PropTypes.array,
	ready: PropTypes.bool,
	fidoError: PropTypes.bool,
	addCommentHandler: PropTypes.func,
	isActivityDeleted: PropTypes.bool
};

export default withStyles(styles)(withWidth()(ActivityActions));
