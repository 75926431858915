import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmailSentimentBehindNumbers from "./EmailSentimentBehindNumbers";
import FauxTable from "../../components/FauxTable";
import Timestamp from "../../components/Timestamp";
import SentimentReport from "./SentimentReport";
import SentimentChart from "./SentimentChart";
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";

import {
	withStyles,
	withTheme,
	withWidth,
	Tabs,
	Tab,
} from '@material-ui/core';

const minHeight = 625;
const styles = theme => ({
	root: {
		minHeight: minHeight,

		/*[theme.breakpoints.down('xs')]: {
			minHeight: 625
		}*/
	},

	tab: {
		fontWeight: 200
	},

	tabLabelContainer: {
		paddingLeft: theme.spacing(.75),
		paddingRight: theme.spacing(.75)
	},

	selectedTab: {
		fontWeight: 500
	},

	detailedReportGrid: {
		height: minHeight,
		overflow: "hidden"
	},

	emailTable: {
		height: 400,
		overflow: "scroll",
		paddingTop: theme.spacing(2)
	}
});

const tabValues = {chart: "chart", table: "table"};

class EmailSentimentReportItem extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			tabValue: tabValues.chart
		};
		this.tabChangeHandler = this.tabChangeHandler.bind(this);
	}

	tabChangeHandler(event, value) {
		this.setState({tabValue: value});
	}

	render() {
		let {tabValue} = this.state,
			{classes, theme, fidoError, width, data, previousDays} = this.props,
			columns = [{
				id: "author",
				label: "Author",
				formatter: data => data
			}, {
				id: "content",
				label: width.includes("xs") ? "Content" : "Email Content",
				formatter: (data, all) => {
					return (
						<React.Fragment>
							<Timestamp time={all.date} reducedSize={false} format={`${width.includes("xs") ? "M/D/YY h:mma" : "MMM Do @ h:mma"}`} /><br/>
							{data}
						</React.Fragment>
					);
				}
			}],
			columnSizing = [
				{xs: 3, sm: 3, md: 3, lg: 3, xl: 3},
				{xs: 9, sm: 9, md: 9, lg: 9, xl: 9}
			];

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					if (data && data.emails && authenticatedUser.ghosting) {
						data.emails.map((email) => {
							email.author = "Anonymized";
							return email;
						});
					}

					return (
						<SentimentReport
							fullWidth
							fidoError={fidoError}
							previousDays={previousDays}
							reportTitle="Email Sentiment"
							moreInfoComponent={<EmailSentimentBehindNumbers />}
							seriesConfig={[{
								name: "Happiness",
								type: "spline",
								color: theme.palette.secondary.blue
							},{
								name: "Distress",
								type: "spline",
								color: theme.palette.secondary.orange
							}]}
							dataConfig={[
								{data: data && data.sentiment, key: "period", valueKey: "happScore", asObject: ["clusterName", "linkTitle", "linkUrl"]},
								{data: data && data.sentiment, key: "period", valueKey: "distressScore", asObject: ["clusterName", "linkTitle", "linkUrl"]}
							]}
							render={(chartInfo) => {
								let {chartConfig, clusterName, linkTitle, linkUrl} = chartInfo;
								return (
									<React.Fragment>
										<Tabs
											centered
											value={tabValue}
											indicatorColor="primary"
											onChange={this.tabChangeHandler}>
											<Tab
												label="Sentiment"
												value={tabValues.chart}
												classes={{root: classes.tabLabelContainer}}
												className={`${classes.tab} ${tabValue === tabValues.chart ? classes.selectedTab : ""}`} />
											<Tab
												label="Distress Emails"
												value={tabValues.table}
												classes={{root: classes.tabLabelContainer}}
												className={`${classes.tab} ${tabValue === tabValues.table ? classes.selectedTab : ""}`} />
										</Tabs>
										{tabValue === tabValues.chart ? (
											<SentimentChart
												linkUrl={linkUrl}
												linkTitle={linkTitle}
												chartConfig={chartConfig}
												clusterName={clusterName}
												clickPointText="Click a point on the chart to see top distress words..." />
										) : tabValue === tabValues.table ? (
											<div className={classes.emailTable}>
												<FauxTable
													fidoError={fidoError}
													infiniteScroll={false}
													elevation={0}
													data={data? data.emails : []}
													columns={columns}
													columnSizing={columnSizing}
													noResultsStyle={{paddingTop: "10%"}} />
											</div>
										) : null}
									</React.Fragment>
								);
							}} />
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

EmailSentimentReportItem.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	width: PropTypes.any.isRequired,
	fidoError: PropTypes.bool,
	data: PropTypes.object,
	previousDays: PropTypes.number.isRequired
};

export default withStyles(styles)(withWidth()(withTheme(EmailSentimentReportItem)));
