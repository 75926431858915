import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Typography,
	Grid,
	Button
} from "@material-ui/core";

import PaddedLayout from '../../containers/PaddedLayout.js';
import Fido from '../../utils/Fido';
import Filterer from '../../components/Filterer';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import AlertSummaryTable from '../components/AlertSummaryTable';

const styles = theme => ({
	paddedLayout: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	},

	description: {
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	}
});

class AlertSummary extends Component {
	constructor(props, context) {
		super(props, context);

		this.fido = new Fido();
		this.topicFido = new Fido();
		this.exportFido = new Fido();

		this.state = {
			topics: [],
			filter: {
				category: "log"
			},
			activities: null,
			fidoError: false,
			hasMoreItems: true,
			isLoading: false,
			currentSearch: this.props.location.search,
			exportOpen: false,

			youtube: {
				videos: [],
				currentlyPlaying: null
			}
		};

		this.loadNextPage = this.loadNextPage.bind(this);
		this.filter = this.filter.bind(this);
		this.toggleExportOpen = this.toggleExportOpen.bind(this);

		this.onYoutubePlay = this.onYoutubePlay.bind(this);
		this.onYoutubeStop = this.onYoutubeStop.bind(this);
		this.onYoutubeReady = this.onYoutubeReady.bind(this);
	}

	componentDidMount() {
		let {updateHeader} = this.props;
		this.loadLibraryTopics();
		this.fetchData();
		updateHeader({title: "Engagement & Alert Summary Report", primary: true});
	}

	componentDidUpdate(prevProps, prevState) {
		let {search} = this.props.location;
		if (prevProps.location.search !== search) {
			this.setState({
				activities: null,
				currentSearch: search,
				youtube: {
					videos: [],
					currentlyPlaying: null
				}
			}, () => {
				this.fetchData();
			});
		}
	}

	componentWillUnmount() {
		this.dropThem();
		this.props.resetHeader();
	}

	dropThem() {
		this.fido.dropIt();
		this.topicFido.dropIt();
		this.exportFido.dropIt();
	}

	async loadLibraryTopics() {
		let data = await this.topicFido.fetch("/activities/library-topics")
			.catch(error => {
				//this.setState({topicFidoError: true});
				return this.props.showSnackbar(error.message);
			});

		if (data) {
			this.setState({topics: data});
		}
	}

	async fetchData(query) {
		this.setState({
			isLoading: true
		});

		let data = await this.fido.fetch("/activities", {
			query: Object.assign(
				{},
				query,
				this.state.filter,
				Fido.getSearchParamsObject(this.state.currentSearch, ["page"])
			)
		}).catch(error => {
			this.setState({fidoError: true});
			return this.props.showSnackbar(error.message);
		});

		if (data) {
			let {activities} = this.state;
			if (activities && activities.length) {
				data.activities = activities.concat(data.activities);
			}
			this.setState(Object.assign({}, data, {isLoading: false}));
		}
	}

	loadNextPage(page) {
		let {hasMoreItems, isLoading} = this.state;

		// Check to make sure there is more to load
		if(hasMoreItems === true && !isLoading) {
			this.fetchData({page: page});
		}
	}

	filter(filters) {
		this.dropThem();
		this.props.history.push(
			Fido.buildPath(
				"/alert-summary-report",
				Object.assign({}, this.state.filter, filters)
			)
		);
	}

	toggleExportOpen() {
		this.setState({exportOpen: !this.state.exportOpen});
	}

	onYoutubePlay(id) {
		let playing,
			{youtube} = this.state;

		if (youtube.currentlyPlaying) {
			playing = youtube.videos.find((vid) => {
				return vid.id === youtube.currentlyPlaying;
			});

			if (playing) {
				playing.player.stopVideo();
			}
		}

		this.setState({
			youtube: {
				currentlyPlaying: id,
				videos: this.state.youtube.videos
			}
		});
	}

	onYoutubeStop(id) {
		let {youtube} = this.state;

		if (youtube.currentlyPlaying === id) {
			this.setState({
				youtube: {
					currentlyPlaying: null,
					videos: this.state.youtube.videos
				}
			});
		}
	}

	onYoutubeReady(id, event) {
		let {youtube} = this.state,
			vids = this.state.youtube.videos;
		vids.push({id: id, player: event.target});
		this.setState({
			youtube: {
				videos: vids,
				currentlyPlaying: youtube.currentlyPlaying
			}});
	}

	render() {
		let {classes, location, history} = this.props,
			{fidoError, activities, hasMoreItems, isLoading, topics, exportOpen} = this.state;

		return (
			<div>
				<PaddedLayout
					noTopPad
					isLarge
					className={classes.paddedLayout}>
					<Typography className={classes.description}>
						The Engagement & Alert Summary Report includes previously delivered alerts in their original format.
					</Typography>
					<Grid container justify="space-between" alignItems="center">
						<Grid item>
							<Button
								color="primary"
								variant="outlined"
								onClick={this.toggleExportOpen}>Export</Button>
						</Grid>
						<Grid item>
							<Filterer
								topics={topics}
								location={location}
								executeFilterHandler={this.filter} />
						</Grid>
					</Grid>
					<AlertSummaryTable
						history={history}
						fidoError={fidoError}
						activities={activities}
						hasMoreItems={hasMoreItems}
						isLoading={isLoading}
						loadNextPage={this.loadNextPage}
						onYoutubePlay={this.onYoutubePlay}
						onYoutubeStop={this.onYoutubeStop}
						onYoutubeReady={this.onYoutubeReady} />
				</PaddedLayout>
				<ResponsiveDialog
					hasCancel
					maxWidth="xs"
					open={exportOpen}
					title="30 Day Export"
					content="Click 'Export' to download a PDF containing original alert content and engagement info from the last 30 days."
					actions={
						<div>
							<Button onClick={this.toggleExportOpen} color="secondary">Cancel</Button>
							<Button
								color="primary"
								target="_blank"
								href="/api/v1/next/reports/alert-summary-pdf"
								onClick={this.toggleExportOpen}>Export</Button>
						</div>
					}
					closeHandler={this.toggleExportOpen} />
			</div>
		);
	}
}

AlertSummary.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateHeader: PropTypes.func.isRequired,
	resetHeader: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withStyles(styles)(AlertSummary);