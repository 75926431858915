import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderIconButton from "./HeaderIconButton";

import { withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		position: "relative",
		"& > button": {
			posiition: "absolute",
			left: theme.spacing(-1.5),
		}
	},

	icon: {
		width: "1em !important"
	}
});

class BackButton extends Component {
	constructor(props, context) {
		super(props, context);

		this.onClick = this.onClick.bind(this);
	}

	onClick(event) {
		if(!this.props.backUrl) {
			this.props.history.goBack();
		} else {
			this.props.history.push(this.props.backUrl);
		}
	}

	render() {
		let {backUrl, classes} = this.props;
		let icon = <FontAwesomeIcon icon={["far", "long-arrow-left"]} className={classes.icon} title="back button" />;

		return (
			<div className={classes.root}>
				<HeaderIconButton handleClick={this.onClick}>
					{backUrl ? (
						<Link to={backUrl} replace arial-label="back button">{icon}</Link>
					):
						icon
					}
				</HeaderIconButton>
			</div>
		);
	}
}

BackButton.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	backUrl: PropTypes.string
};

export default withStyles(styles)(withRouter(BackButton));
