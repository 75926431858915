import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import {RectShape, TextRow} from 'react-placeholder/lib/placeholders';

import Constants from "../../utils/Constants";
import NumberFormatter from '../../utils/NumberFormatter';
import SourceIndicator from "../../components/SourceIndicator";
import AuthorizedUserContext from "../../utils/AuthorizedUserContext";
import AssociatedDigitalPropertyInfo from "./AssociatedDigitalPropertyInfo";

import {
	withStyles,
	withWidth,
	Paper,
	Grid,
	Typography,
	Card,
	CardContent,
	CardActions,
	Button,
	Link,
} from '@material-ui/core';

const styles = theme => ({
	paper: {
		backgroundColor: theme.palette.background.default
	},

	cardContainer: {
		paddingTop: theme.spacing(4)
	},

	propertyName: {
		marginLeft: theme.spacing(1)
	},

	sourceContainer: {
		paddingBottom: theme.spacing(1)
	},

	letUsKnow: {
		fontSize: "1rem",
		paddingTop: theme.spacing(.375)
	},

	link: {
		fontSize: "1rem",
		marginBottom: 3
	},

	card: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},

	cardConnected: {
		minHeight: theme.spacing(17.125),

		[theme.breakpoints.up('lg')]: {
			minHeight: theme.spacing(16.125)
		},

		"@media (min-width: 1026px)": {
			minHeight: theme.spacing(15.5)
		},
	},

	cardNotConnected: {
		minHeight: theme.spacing(16.375),

		[theme.breakpoints.up('lg')]: {
			minHeight: theme.spacing(15.375)
		},

		"@media (min-width: 1026px)": {
			minHeight: theme.spacing(14.75)
		},

		border: `${theme.spacing(.375)}px solid ${theme.palette.primary.main}`
	},

	productCardNotConnected: {
		border: `${theme.spacing(.375)}px solid ${theme.palette.secondary.orange}`
	},

	cardContent: {
		paddingBottom: 0
	},

	cardActions: {
		paddingTop: 0
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const placeHolder = (
	<div>
		<TextRow color={placeholderColor}><span></span></TextRow>
		<RectShape style={{height: "20rem", marginTop: 10}} color={placeholderColor}><span></span></RectShape>
	</div>
);

class AssociatedDigitalPropertiesReportItem extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			moreInfoTitle: null,
			showMoreInfo: false,
			moreInfoError: false
		};

		this.closeMoreInfo = this.closeMoreInfo.bind(this);
	}

	moreInfoClicked(key) {
		let {classes, moreInfoHandler} = this.props;

		this.setState({
			showMoreInfo: true,
			moreInfoTitle: (
				<Grid container wrap="nowrap" alignItems="center">
					<Grid item>
						<SourceIndicator {...this.getSourceIcon(key)} />
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography noWrap
							classes={{root: classes.propertyName}}>{Constants.SOURCES.DISPLAY_NAMES[key]}</Typography>
					</Grid>
				</Grid>
			)
		});

		moreInfoHandler(key);
	}

	closeMoreInfo() {
		this.setState({showMoreInfo: false});
	}

	getSourceIcon(name) {
		let icon, config;
		if (name) {
			config = Constants.SOURCES.ICONS[name];
			if(!(config && "icon" in config && config.icon && "image" in config.icon && config.icon.image)) {
				icon = Object.assign({icon: name}, config ? config.icon : {});
			} else {
				icon = Object.assign({}, config.icon);
			}
		}
		return icon;
	}

	isConnected(source) {
		let connected = 0;
		Object.keys(source).map((key) => {
			return key === "product" ? null : connected += source[key];
		});
		return connected;
	}

	render() {
		let {showMoreInfo, moreInfoTitle, moreInfoError} = this.state,
			cardGridSizing = {xs: 6, sm: 4, md: 3, lg: 3, xl: 3},
			{classes, properties, fidoError, csm, isProducts, width, moreInfoData} = this.props,
			ready = properties && Object.keys(properties).length > 0 ? true : false,
			letUsKnowHref = `mailto:${csm.email}?subject=${encodeURI("Social Media Integrations Feedback")}`,
			letUsKnowLink = (
				<Link
					href={letUsKnowHref}
					className={classes.link}
					aria-label="let us know about additional integrations">let us know</Link>
			),
			contactUsLink = (
				<Link
					aria-label="contact us to sign up"
					href={`mailto:${csm.email}?subject=${encodeURI("Product Interest")}`}
					className={classes.link}>Contact us</Link>
			),

			// TEMP - hack for lack of configuration
			connectedProductsHack = ["exchange", "tips"];
			// TEMP - hack for lack of configuration

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
						<React.Fragment>
							<Paper elevation={0} classes={{root: classes.paper}}>
								<ReactPlaceholder ready={ready} customPlaceholder={placeHolder} showLoadingAnimation={!fidoError}>
									<Grid container justify="space-between" wrap="nowrap">
										<Grid item>
											<Typography variant="h3">
												{isProducts ? "Additional Products" : "Social Media Integrations"}
											</Typography>
										</Grid>
										{!isProducts ?
											<Grid item style={{textAlign: "right"}}>
												<Typography variant="h3" display="inline" noWrap>Missing Anything?</Typography>
												<Button
													variant="text"
													color="primary"
													href={letUsKnowHref}
													aria-label="Let us know what integrations we're missing"
													className={classes.letUsKnow}>Let Us Know</Button>
											</Grid>: null
										}
									</Grid>
									<Typography variant="body1" color="textSecondary">
										{!isProducts ?
											authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SOCIAL_MEDIA) &&
											authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.DATA.SOCIAL_MEDIA) ?
												<span>Social Sentinel has direct access to scan public social media across many social media platforms. We scan and associate the content surfaced from these searches. However, adding your known social media accounts and digital properties helps to supplement the data reach that we have on your behalf. If there are additional integrations we should be aware of, please {letUsKnowLink}.</span>
												:
												<span>Social Media integration will provide a more holistic view of the digital conversations happening within your community. {contactUsLink} to sign up.</span>
											:
											<span>Additional product integrations will provide a more holistic view of the digital conversations happening within your community. {contactUsLink} to sign up.</span>
										}
									</Typography>
									<Grid container spacing={2} classes={{container: classes.cardContainer}}>
										{properties != null ? Object.keys(properties).map((key) => {
											let value = properties[key],
												connected = this.isConnected(value),
												addFeatureText = {
													subject: `Interested in ${Constants.SOURCES.DISPLAY_NAMES[key]}`,
													body: "I am interested in learning more about integrating this source."
												},
												hasFeature = authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES[key.toUpperCase()]),
												gridItem = <Grid key={key} item {...cardGridSizing}>
													<Card className={`${classes.card} ${!connected && (!connectedProductsHack.includes(key) || !hasFeature) ?
														isProducts ? classes.cardNotConnected+" "+classes.productCardNotConnected : classes.cardNotConnected : classes.cardConnected}`}>
														<CardContent classes={{root: classes.cardContent}}>
															<Grid container wrap="nowrap" alignItems="center" className={classes.sourceContainer}>
																<Grid item>
																	<SourceIndicator {...this.getSourceIcon(key)} />
																</Grid>
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																	<Typography noWrap
																		classes={{root: classes.propertyName}}>{Constants.SOURCES.DISPLAY_NAMES[key]}</Typography>
																</Grid>
															</Grid>
															{connected ? Object.keys(value).map((key2) => {
																let value2 = value[key2];
																return key2 === "product" ? null : (
																	<Typography key={key2} variant="caption" color="textSecondary">
																		{NumberFormatter.largeNumberFormat(value2)} {key2}
																	</Typography>
																);
															}) : <Typography variant="caption" color="textSecondary" style={{fontStyle: "italic"}}>
																{(isProducts || !authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SOCIAL_MEDIA)) &&
																	!hasFeature ? "Want this product?" : connectedProductsHack.includes(key) ? "Connected" : `${!width.includes("xs") ? "Account not" : "Not"} connected`}
															</Typography>}
														</CardContent>
														{(connectedProductsHack.includes(key) && !hasFeature) ||
															(!connected && !connectedProductsHack.includes(key)) ?
															<CardActions className={classes.cardActions}>
																<Button
																	variant="text"
																	color="primary"
																	aria-label={`Learn more about integrating ${Constants.SOURCES.DISPLAY_NAMES[key]}`}
																	href={`mailto:${csm.email}?subject=${encodeURI(addFeatureText.subject)}&body=${encodeURI(addFeatureText.body)}`}
																	className={classes.letUsKnow}>Learn More!</Button>
															</CardActions>
															: !connectedProductsHack.includes(key) ? (
																<CardActions className={classes.cardActions}>
																	<Button
																		variant="text"
																		color="primary"
																		onClick={() => this.moreInfoClicked(key)}
																		aria-label={`More information about ${Constants.SOURCES.DISPLAY_NAMES[key]}`}
																		className={classes.letUsKnow}>More Info</Button>
																</CardActions>
															) : null
														}
													</Card>
												</Grid>;

											return isProducts && value.product ? gridItem : !isProducts && !value.product ? gridItem : null;
										}) : null}
										{!isProducts && authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SOCIAL_MEDIA) ?
											<Grid item {...cardGridSizing}>
												<Card className={`${classes.card} ${classes.cardConnected}`}>
													<CardContent>
														<Grid container wrap="nowrap" alignItems="center" className={classes.sourceContainer}>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<Typography noWrap>Public Content</Typography>
															</Grid>
														</Grid>
														<Typography variant="caption" color="textSecondary">
															Scanning all social media partners
														</Typography>
													</CardContent>
												</Card>
											</Grid> : null
										}
									</Grid>
								</ReactPlaceholder>
							</Paper>
							<AssociatedDigitalPropertyInfo
								title={moreInfoTitle}
								open={showMoreInfo}
								data={moreInfoData}
								fidoError={moreInfoError}
								closeHandler={this.closeMoreInfo} />
						</React.Fragment>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

AssociatedDigitalPropertiesReportItem.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	csm: PropTypes.object,
	properties: PropTypes.object,
	isProducts: PropTypes.bool,
	fidoError: PropTypes.bool,
	moreInfoData: PropTypes.array,
	moreInfoHandler: PropTypes.func
};

export default withStyles(styles)(withWidth()(AssociatedDigitalPropertiesReportItem));
