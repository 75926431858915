import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from "./Select";
import { withStyles, FormControl, InputLabel, FormHelperText, MenuItem } from '@material-ui/core';

const styles = theme => ({
	select: {
		marginTop: "0 !important",
		marginBottom: "11px"
	},

	selectLabel: {
		position: "relative"
	}
});

class TimeSelect extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			value: props.value
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value !== this.props.value) {
			this.setState({value: this.props.value});
		}

		if (this.props.start) {
			let {settings, start, allDay, name} = this.props,
				{ value } = this.state,
				notificationConfig = settings.notification,
				configLength = Object.keys(notificationConfig).length

			if (configLength && start && !allDay) {
				let times = Object.keys(settings.times),
					soa = times.indexOf(start),
					voa = times.indexOf(value);

				if (soa > voa && voa !== -1) {
					this.setState({value: times[soa + 1]}, () => {
						this.props.onChange({
							target: {
								value: value,
								name: name
							}
						})
					});
				}
			}
		}
	}

	handleChange(e) {
		this.setState({value: e.target.value});
		this.props.onChange(e);
	}

	render() {
		let {classes, className, style, controlId, ariaId, ariaText, selectName, id, name, label, settings, disabled, allDay, start} = this.props,
			{ value } = this.state,
			notificationConfig = settings.notification,
			configLength = Object.keys(notificationConfig).length,
			times = configLength ? Object.keys(settings.times) : [];

		if (configLength && start && !allDay) {
			let soa = times.indexOf(start);
			times =  times.splice(soa + 1);
		}

		if (allDay)
			times.splice(-1,1);

		return (
			<FormControl
				id={controlId}
				disabled={disabled}
				style={style}
				className={className}>
				{label ?
					<InputLabel htmlFor={selectName} className={classes.selectLabel}>{label}</InputLabel>
				: null}
				<Select
					noValidator
					name={selectName}
					disabled={disabled}
					value={value || ""}
					inputProps={{
						id: id,
						name: name,
						'aria-describedby': ariaId
					}}
					className={classes.select}
					onChange={this.handleChange}>
					{allDay?
						<MenuItem key="allDay" value="24:00:00">All Day</MenuItem>
					: null}
					{configLength ?
						times.map((time, idx) => (
							<MenuItem key={idx} value={time}>{settings.times[time]}</MenuItem>
						)) : null
					}
				</Select>
				<FormHelperText id={ariaId} disabled={disabled}>{ariaText}</FormHelperText>
			</FormControl>
		);
	}
}

TimeSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
	controlId: PropTypes.string.isRequired,
	aridId: PropTypes.string,
	ariaText: PropTypes.string,
	selectName: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	settings: PropTypes.object,
	disabled: PropTypes.bool,
	allDay: PropTypes.bool,
	start: PropTypes.string
};

export default withStyles(styles)(TimeSelect);
