import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardContent,
	Typography,
	ListItem,
	Grid,
	withStyles,
	Button
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Waypoint } from "react-waypoint";
import MomentCore from 'moment';

import ActivityInfo from './ActivityInfo.js';
import ActivityCategory from './ActivityCategory.js';
import ActivityTitle from './ActivityTitle';
import ActivityContent from './ActivityContent';
import ActivityTopics from './ActivityTopics';
import PostMedia from './PostMedia.js';
import Comments from './Comments.js';
import UserActivityIndicators from './UserActivityIndicators.js';
import TrendingTopicLinks from './TrendingTopicLinks';
import TipInfo from './TipInfo.js';
import RateAlertButton from "./RateAlertButton";
import Constants from "../utils/Constants";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

const styles = theme => ({
	card: {
		width: "100%",

		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1.5),
			marginRight: theme.spacing(1.5),
		},

		[theme.breakpoints.down('xs')]: {
			borderRadius: 0
		}
	},

	cardContent: {
		paddingTop: 0
	},

	withMedia: {
		paddingTop: theme.spacing(3)
	},

	cardPadding: {
		padding: theme.spacing(3)
	},

	translate: {
		paddingTop: theme.spacing(1),
		fontStyle: 'italic'
	},

	translateIcon: {
		paddingRight: theme.spacing(.5)
	},

	pointer: {
		cursor: "pointer"
	},

	activityIndicators: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(-1) // take out if we add circle background on the indicators back in
	},

	rateGridItem: {
		paddingTop: theme.spacing(2)
	},

	tooltip: {
		maxWidth: 150
	},

	comments: {
		marginTop: theme.spacing(2.5)
	},

	indicatorGrid: {
		paddingTop: theme.spacing(1)
	},

	categoryAndAccountsRow: {
		paddingBottom: theme.spacing(1)
	}
});

class ActivityListItem extends Component {
	constructor(props, context) {
		super(props, context);

		this.trackViewMillis = 2000;
		this.trackViewInfo = null;

		this.userActivityClickHandler = this.userActivityClickHandler.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.waypointEntered = this.waypointEntered.bind(this);
		this.waypointLeft = this.waypointLeft.bind(this);
		this.clearViewTracking = this.clearViewTracking.bind(this);
		this.canTrackViews = this.canTrackViews.bind(this);
	}

	componentWillUnmount() {
		this.clearViewTracking();
	}

	handleClick(ev, activityId) {
		let {clickHandler} = this.props;
		if (clickHandler) {
			clickHandler(ev, activityId);
		}
	}

	activityClickHandler(ev, activityId) {
		this.handleClick(ev, activityId);
		if (this.props.suppressNavigation === false) {
			this.props.history.push("/activities/" + activityId);
		}
	}

	userActivityClickHandler(ev, activityId) {
		this.handleClick(ev, activityId);
		if (this.props.suppressNavigation === false) {
			this.props.history.push({
				pathname: "/activities/" + activityId,
				state: {scrollTo: "userActivityRef"}
			});
		}
	}

	canTrackViews() {
		let {activityId, trackViews, trackViewHandler} = this.props;
		return activityId && trackViews && trackViewHandler;
	}

	waypointEntered(waypointInfo) {
		let {activityId, trackViewHandler} = this.props;
		if (this.canTrackViews()) {
			this.trackViewInfo = {
				entered: new MomentCore(),
				timeoutId: setTimeout(() => trackViewHandler(activityId), this.trackViewMillis)
			};
		}
	}

	waypointLeft(waypointInfo) {
		let now = new MomentCore();
		if (this.trackViewInfo && now.subtract(this.trackViewMillis/1000, "seconds") <= this.trackViewInfo.entered) {
			this.clearViewTracking();
		}
	}

	clearViewTracking() {
		if (this.canTrackViews() && this.trackViewInfo != null) {
			//console.log(`CLEARING TIMEOUT ${this.props.activityId}`);
			clearTimeout(this.trackViewInfo.timeoutId);
			this.trackViewInfo = null;
		}
	}

	render() {
		let topicsToBold = [],
			{addCommentHandler, authorImage, authorName, comments, title, content, patch, actions,
				iFrameVideo, imageUrl, sourceId, sourceHandle, sourceIcon, sourceName, subSourceId, time, videoUrl,
				activityId, accounts, itemKey, classes, category, newCategory, language, extraHeaders,
				auditTrailRemoved, auditTrailSaved, auditTrailShared, auditTrailViewed, auditTrailRated, rateHandler,
				trendingTopics, tip, trackViews, numberOfViewableComments, authors, suppressNavigation,
				allowViewMoreComments, showCategory, clickHandler, twitterRedacted, history, postMatches, lowerButtons,
				priorComments, anchorText, expandContent,
				onYoutubePlay, onYoutubeStop, onYoutubeReady} = this.props,
			ready = activityId != null,
			hasTipConcern = sourceName === Constants.SOURCES.SHAREIT && "concernOtherDescription" in tip && !!(tip.concernOtherDescription),
			isGDoc = sourceId === Constants.SOURCES.IDS.GOOGLE_DOCS,
			isGSuitComment = subSourceId === Constants.SOURCES.IDS.GSUIT_COMMENT;

		if (trendingTopics) {
			topicsToBold = trendingTopics.reduce((result, topic) => {
				return topic.trending_topic.name.split(" | ");
			}, topicsToBold);
		}

		return (
			<AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					let isRemoved = false;
					if (auditTrailRemoved) {
						isRemoved = auditTrailRemoved.find((item) => {
							return item.user.id === authenticatedUser.id;
						}) ? true : false;
					}

					if (isRemoved && actions && actions.length) {
						actions.forEach(action => {
							action.disabled = true;
						});
					}

					return (
						<React.Fragment>
							<ListItem disableGutters key={itemKey}>
								<Card className={classes.card} style={{zIndex: 1}}>
									<div
										onClick={(ev) => this.activityClickHandler(ev, activityId)}
										className={clickHandler || suppressNavigation === false ? classes.pointer : null}>
										<div className={classes.cardPadding}>
											<Grid container justify="space-between" className={classes.categoryAndAccountsRow}>
												<Grid item>
													{newCategory === 'saved' || newCategory === 'removed' || showCategory ?
														<ActivityCategory category={category} /> : null
													}
												</Grid>
												<Grid item>
													{authenticatedUser.childAccounts.length ? (
														<Typography color="textSecondary" variant="caption">
															{accounts ? accounts.reduce((acc, curr, index) => {
																if (index < 2) {
																	let strippedAccnt = authenticatedUser.childAccounts.find(item => item.id === curr.id);
																	if (strippedAccnt) {
																		acc.push(strippedAccnt.name);
																	}
																} else if (index === 2) {
																	acc.push(`${accounts.length-2} More...`);
																}
																return acc;
															}, []).join(", ") : null}
														</Typography>
													) : null}
												</Grid>
											</Grid>
											{newCategory === 'discussions' || newCategory === 'saved' ?
												<TrendingTopicLinks topics={trendingTopics} /> : null
											}
											<ActivityTitle title={title} twitterRedacted={twitterRedacted} />
											<ActivityContent
												patch={patch}
												history={history}
												content={content}
												category={category}
												expanded={expandContent}
												sourceId={sourceId}
												subSourceId={subSourceId}
												sourceIcon={sourceIcon}
												libraryTopics={postMatches}
												comments={priorComments}
												anchorText={anchorText}
												time={time}
												authorName={authorName}
												boldWords={topicsToBold}
												twitterRedacted={twitterRedacted}
												rightReadMore={isGDoc}
												originalContent={extraHeaders ? extraHeaders.content : null} />
											{!isGSuitComment ? <ActivityTopics matches={postMatches} /> : null}
											{language !== null && language !== '' && language !== 'en' &&
												(!twitterRedacted || twitterRedacted === Constants.ACTIVITY.TWITTER_REDACTION.SCRUB_GEO) ? (
													<Typography className={classes.translate} color="textSecondary" variant="caption" aria-label="Translation Available">
														<FontAwesomeIcon className={classes.translateIcon} icon={["fal", "globe"]} title="Translation Available" />
														Translation Available
													</Typography>
												) : null}
											{hasTipConcern ? <TipInfo {...tip} /> : null}
										</div>
										{!isGDoc ? (
											<PostMedia
												imageUrl={imageUrl}
												videoUrl={videoUrl}
												sourceId={sourceId}
												activityId={activityId}
												onYoutubePlay={onYoutubePlay}
												onYoutubeStop={onYoutubeStop}
												onYoutubeReady={onYoutubeReady}
												useIframeVideo={iFrameVideo}></PostMedia>
										) : null}
									</div>
									<CardContent className={`${classes.cardContent} ${imageUrl || videoUrl ? classes.withMedia : ""}`}>
										{!isGSuitComment ? (
											<div
												onClick={(ev) => this.activityClickHandler(ev, activityId)}
												className={clickHandler || suppressNavigation === false ? classes.pointer : null}>
												<ActivityInfo
													ready={ready}
													showTimestamp
													time={time}
													hideAuthorInfo={true}
													extraHeaders={extraHeaders}
													authorName={authorName}
													authorImage={authorImage}
													sourceName={sourceName}
													sourceHandle={sourceHandle}
													sourceIcon={sourceIcon}
													sourceId={sourceId}
													subSourceId={subSourceId}
													authors={authors} />
											</div>
										) : null}
										{lowerButtons ? (
											<Grid container justify="flex-end">
												{lowerButtons.map((cfg, idx) => {
													let href = this.props[cfg.hrefAttr],
														btn = (
															<Button
																key={cfg.key}
																size={cfg.size || "small"}
																color={cfg.color || "primary"}
																disabled={this.props[cfg.hrefAttr] === "null"}
																target={cfg.target || "_blank"}
																href={href}>
																<span>
																	{cfg.label}
																	<FontAwesomeIcon
																		title={cfg.icon.title}
																		icon={cfg.icon.icon}
																		className={cfg.icon.className} />
																</span>
															</Button>
														);

													return (
														<Grid item key={idx}>
															{cfg.hideIfHrefAttr && cfg.hideIfHrefAttr(href) ? null : btn}
														</Grid>
													);
												})}
											</Grid>
										) : null}

										{trackViews ? (
											<Waypoint
												topOffset={115} // header and tab bar
												bottomOffset={"10%"}
												fireOnRapidScroll={false}
												scrollableAncestor={window}
												onEnter={this.waypointEntered}
												onLeave={this.waypointLeft} />
										) : null}
										<Comments
											ready={ready}
											actions={actions}
											comments={comments}
											numberToShow={numberOfViewableComments}
											allowViewMore={allowViewMoreComments}
											isActivityDeleted={isRemoved}
											className={classes.comments}
											addCommentHandler={addCommentHandler}
											moreCommentsClickHandler={(ev) => this.activityClickHandler(ev, activityId)}/>
										<Grid
											container
											justify="space-between"
											alignItems="center"
											className={classes.indicatorGrid}>
											<Grid item>
												<UserActivityIndicators
													removed={auditTrailRemoved}
													saved={auditTrailSaved}
													shared={auditTrailShared}
													rated={auditTrailRated}
													viewed={auditTrailViewed}
													className={classes.activityIndicators}
													clickHandler={(ev) => this.userActivityClickHandler(ev, activityId)} />
											</Grid>
											<Grid item className={classes.rateGridItem}>
												<RateAlertButton
													category={category}
													rating={auditTrailRated.find(rating => rating.user.id === authenticatedUser.id) || {alert_id: activityId}}
													rateHandler={rateHandler}
													auditTrailRated={auditTrailRated} />
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</ListItem>
						</React.Fragment>
					);
				}}
			</AuthorizedUserContext.Consumer>
		);
	}
}

ActivityListItem.defaultProps = {
	trackViews: true,
	allowViewMoreComments: false,
	numberOfViewableComments: 1,
	suppressNavigation: false
};

ActivityListItem.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	activityId: PropTypes.number,
	title: PropTypes.string,
	content: PropTypes.string,
	patch: PropTypes.string,
	category: PropTypes.number,
	imageUrl: PropTypes.string,
	itemKey: PropTypes.string,
	videoUrl: PropTypes.string,
	iFrameVideo: PropTypes.bool,
	authors: PropTypes.string,
	authorName: PropTypes.string,
	authorImage: PropTypes.string,
	sourceName: PropTypes.string,
	sourceHandle: PropTypes.string,
	sourceIcon: PropTypes.object,
	time: PropTypes.string,
	actions: PropTypes.array,
	comments: PropTypes.array,
	addCommentHandler: PropTypes.func,
	newCategory: PropTypes.string,
	language: PropTypes.string,
	auditTrailShared: PropTypes.array,
	auditTrailSaved: PropTypes.array,
	auditTrailRemoved: PropTypes.array,
	auditTrailRated: PropTypes.array,
	auditTrailViewed: PropTypes.array,
	trendingTopics: PropTypes.array,
	topicLinkClickHandler: PropTypes.func,
	clickHandler: PropTypes.func,
	rateHandler: PropTypes.func.isRequired,
	extraHeaders: PropTypes.object,
	tip: PropTypes.object,
	trackViews: PropTypes.bool,
	trackViewHandler: PropTypes.func,
	allowViewMoreComments: PropTypes.bool,
	numberOfViewableComments: PropTypes.number,
	showCategory: PropTypes.bool,
	lowerButtons: PropTypes.array,
	twitterRedacted: PropTypes.string,
	sourceId: PropTypes.number,
	subSourceId: PropTypes.string,
	priorComments: PropTypes.array,
	anchorText: PropTypes.string,
	accounts: PropTypes.array,
	postMatches: PropTypes.array,
	suppressNavigation: PropTypes.bool,
	expandContent: PropTypes.bool,
	youtubeUuid: PropTypes.string,
	onYoutubePlay: PropTypes.func,
	onYoutubeStop: PropTypes.func,
	onYoutubeReady: PropTypes.func,
};

export default withStyles(styles)(ActivityListItem);
