import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Fido from '../utils/Fido';
import LocationDetailsForm from '../components/LocationDetailsForm';
import AuthorizedUserContext from "../utils/AuthorizedUserContext";
import FormHelper from "../utils/FormHelper";

class LocationDetails extends Component {
	constructor(props, context) {
		super(props, context);

		let formValues = Object.assign({}, {active: 1});

		let urlParams = (this.props.location.pathname).split('/');

		this.state = {
			formValues: formValues,
			restricted: false,
			editingLocation: (urlParams[4]) ? Number(urlParams[4]) : null,
			inAccount: (urlParams[2]) ? Number(urlParams[2]) : null,
			addingLocation: (this.props.location.pathname === '/locations/add') ? true : false,
			isLoading: true,
			dataLoading: true,
			geoRadius: null,
			geoChange: false
		};

		this.sharedFido = new Fido();

		this.setLocation = this.setLocation.bind(this);
		this.setupLocation = this.setupLocation.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleRadius = this.handleRadius.bind(this);
		this.handleGeoChange = this.handleGeoChange.bind(this);
		this.dismissBanner = this.dismissBanner.bind(this);
	}

	setLocation() {
		if (this.state.addingLocation) {
			this.setupLocation();
		} else {
			this.fido = new Fido();
			this.fido.fetch(this.props.location.pathname)
				.then((data) => {
					if (data) {
						this.location = data;
						this.setupLocation();
					}
				})
				.catch(error => {
					this.setState({fidoError: true});
					return this.props.showSnackbar(error.message);
				});
		}
	}

	setupLocation() {
		let formValues = this.state.formValues;

		if (!this.state.addingLocation) {
			formValues = Object.assign({}, formValues, this.location);
		}

		this.setState({formValues: formValues, isLoading: false});
		this.updateHeader();
	}

	updateHeader() {
		let headerOptions = {
			title: (this.state.addingLocation) ? 'Add Location' : 'Location Details'
		};
		this.props.updateHeader(headerOptions);
	}

	componentDidUpdate(prevProps, prevState) {
		// if previous id is diff, reload data
		if (this.props.match.params.locationId !== prevProps.match.params.locationId) {
			this.setState({isLoading: true});
			this.setLocation();
		}
	}

	componentDidMount() {
		this.updateHeader();

		// Permission check
		if((this.props.authenticatedUser.account_id === this.state.inAccount && this.props.authenticatedUser.permissions.indexOf('manage.locations') !== -1) || (this.props.authenticatedUser.account_id !== this.state.inAccount && this.props.authenticatedUser.permissions.indexOf('write.corp'))) {

			// Check 2: Account ids match and we have permission to manage locations
			// Check 3: Account ids do not match but we have corporate write permissions
			this.setLocation();
		} else {
			this.setState({restricted: true});
		}
	}

	componentWillUnmount() {
		this.sharedFido.dropIt();
		this.props.resetHeader();
	}

	dismissBanner() {
		this.props.showBanner(false);
	}

	handleChange(event) {
		let formValues = Object.assign(this.state.formValues, FormHelper.getChanged(event));
		this.setState({formValues: formValues});
	}

	handleSubmit(formValues) {
		let successMsg = (this.state.addingLocation) ? "Location Added." : "Saved.",
			msg = (this.state.addingLocation) ? 'Adding Location...' : 'Saving Location...',
			putUrl = (this.state.addingLocation) ? '/locations/add' : '/locations/' + formValues.id;

		formValues.radius = this.state.geoRadius;
		formValues.geochange = (this.state.addingLocation) ? true : this.state.geoChange;

		let poodle = new Fido();
		// Using poodle cause it's a throw away, like an avalanche poodle - don't want to abort call when unmounted
		poodle.fetch(putUrl, {
			method: (this.state.addingLocation) ? "POST" : "PUT",
			body: JSON.stringify(formValues)
		}, msg, successMsg)
			// trying to set state when component is not mounted (backed up), causes potential memory leak.
			// so, since we're backing up, we don't need to set the state.
			.then((data) => {})
			.catch(error => this.props.showSnackbar(error.message));

		this.props.showBanner({
			content: this.state.addingLocation ?
				"New locations may take a few moments to be fully created." :
				"It may take a few moments for changes to take effect.",
			actions: [
				{text: "Dismiss", handler: () => this.dismissBanner()}
			]
		});

		this.props.history.goBack();
	}

	handleRadius(value) {
		this.setState({geoRadius: value});
	}

	handleGeoChange() {
		this.setState({geoChange: true});
	}

	render() {
		let {formValues, restricted, isLoading, dataLoading} =  this.state,
			{showSnackbar, history} = this.props;

		if (restricted) {
			return (
				// Permission denied
				<Redirect to={{
					pathname: "/access"
				}}/>
			);
		} else {
			return (
				<div>
					<LocationDetailsForm
						isLoading={isLoading}
						dataLoading={dataLoading}
						formValues={formValues}
						history={history}
						showSnackbar={showSnackbar}
						handleChange={this.handleChange}
						handleSubmit={this.handleSubmit}
						handleRadius={this.handleRadius}
						handleGeoChange={this.handleGeoChange} />
				</div>
			);
		}
	}
}

LocationDetails.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object,
	resetHeader: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func,
	authenticatedUser: PropTypes.object,
	showBanner: PropTypes.func
};

// eslint error
const dNameFunc = (props, ref) => (
	<AuthorizedUserContext.Consumer>
		{authenticatedUser => <LocationDetails {...props} authenticatedUser={authenticatedUser} ref={ref} />}
	</AuthorizedUserContext.Consumer>
);
dNameFunc.displayName = "LocationDetails";

export default React.forwardRef(dNameFunc);
