import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MomentCore from 'moment';

import DeconstructActivity from '../utils/DeconstructActivity';
import ActivityListItem from '../components/ActivityListItem';
import TrendingTopicListItem from '../components/TrendingTopicListItem';
import GroupedList from '../components/GroupedList';
import FullscreenMessage from '../components/FullscreenMessage';
import ShareActivityForm from '../components/ShareActivityForm.js';
import Constants from "../utils/Constants";
import Fido from '../utils/Fido';

import { withStyles, Collapse, Button } from '@material-ui/core';

const styles = theme => ({
	topicItem: {
		"&:last-child": {
			paddingBottom: theme.spacing(1.4)
		}
	}
});

class ActivityList extends Component {
	constructor(props, context) {
		super(props, context);

		this.fido = new Fido();

		this.state = {
			collapseItem: null,
			collapsingItems: [],
			shareOpen: false,
			shareActivity: null,
			youtube: {
				videos: [],
				currentlyPlaying: null
			}
		};

		// Not carefully cleaning up when deleted activities are not undone b/c assuming component will remount, trashing all of it
		// before it becomes a memory leak issue. Can reevaluate a timed clean up if needed... in which case it would be better
		// to use actually caching infrastructure because it can age based & max-count queue (first in, first out) remove items
		this.recentlyRemoved = {};

		this.addComment = this.addComment.bind(this);
		this.trackView = this.trackView.bind(this);
		this.remove = this.remove.bind(this);
		this.undo = this.undo.bind(this);
		this.save = this.save.bind(this);
		this.share = this.share.bind(this);
		this.shareClicked = this.shareClicked.bind(this);
		this.shareClosed = this.shareClosed.bind(this);
		this.rate = this.rate.bind(this);
		this.handleExclusions = this.handleExclusions.bind(this);
		this.restoreActivity = this.restoreActivity.bind(this);
		this.collapseExited = this.collapseExited.bind(this);

		this.onYoutubePlay = this.onYoutubePlay.bind(this);
		this.onYoutubeStop = this.onYoutubeStop.bind(this);
		this.onYoutubeReady = this.onYoutubeReady.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		let {newCategory, location, history, activities} = this.props;

		// in the case of Undo'ing a delete from the details view, depending on timing
		// the activities data may have already been loaded, therefore handleExclusions has been called prematurely
		if (location.state && Object.keys(location.state).length) {
			this.handleExclusions(history);
		}

		if (prevProps.activities !== activities) {
			this.handleExclusions(history);
		}

		// delete from alerts, discussion or saved, then goto removed
		// it wasn't showing up because of all the fancy hiding logic in here
		// https://socialsentinel.atlassian.net/browse/CLIENT-543
		if (newCategory !== prevProps.newCategory && (newCategory === "removed" || prevProps.newCategory === "removed")) {
			this.setState({
				collapseItem: null,
				collapsingItems: []
			});
		}
	}

	addComment(formVals, activityId) {
		this.fido.fetch("/activities/" + activityId, {
			method: "POST",
			body: JSON.stringify({activity: formVals})
		}, 'Posting Comment...', 'Comment Posted.')
			.then((data) => {
				if (data) {
					this.props.addCommentThen(data);
				}
			})
			.catch(error => this.props.showSnackbar(error.message));
	}

	trackView(activityId) {
		//console.log(`TRACKING VIEW: ${activityId}`);
		if(activityId) {
			this.fido.fetch(`/activities/${activityId}/listing-view`, {
				method: "PUT",
			})
				.then((data) => {
					if (data && this.props.trackViewThen) {
						this.props.trackViewThen(data);
					}
				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	remove(event, activityId) {
		let collapseItem = this.state.collapseItem;
		if (collapseItem === null) {
			this.state.collapsingItems.push(activityId);
			this.setState({
				collapseItem: {
					onExitedHandler: this.remove,
					activityId: activityId
				},
				collapsingItems: this.state.collapsingItems
			});
		} else if(collapseItem) {
			activityId = collapseItem.activityId;
			this.setState({collapseItem: null}, () => {
				this.fido.fetch("/activities/" + activityId + "/delete", {
					method: "PUT",
				}, {
					message: 'Removing...',
					action: (<Button size="small" onClick={event => this.undo(event, activityId)} color="primary">Undo</Button>)
				}, 'Removed.')
					.then(activityId => {
						if (activityId) {
							let collapsingItems = this.state.collapsingItems.filter(id => id !== activityId);
							this.setState({collapsingItems: collapsingItems});

							if (this.props.actionBtnThens.remove) {
								this.props.actionBtnThens.remove(this.filterDeletedActivity(activityId));
							}
						}
					})
					.catch(error => this.props.showSnackbar(error.message));
			});
		}
	}

	undo(event, activityId) {
		this.props.showSnackbar(false);

		if(activityId) {
			this.fido.fetch("/activities/" + activityId + "/undelete", {
				method: "PUT",
			}, 'Restoring...', 'Restored.')
				.then(data => {
					// optionally updating our local copy with what's returned before exposing
					this.recentlyRemoved[data.activity.id] = data.activity;
					this.restoreActivity(data.activity.id);

					let collapsingItems = this.state.collapsingItems.filter(id => id !== data.activity.id);
					this.setState({collapsingItems: collapsingItems});
				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	restoreActivity(activityId) {
		let {activities, actionBtnThens, onRestoreMissing} = this.props;
		if(activities) {
			let missingActivity = activityId in this.recentlyRemoved && this.recentlyRemoved[activityId];
			if(missingActivity) {
				let insertIndex = activities.findIndex(activity => {
					return new MomentCore.utc(activity.published_at).local().valueOf() <= new MomentCore.utc(missingActivity.published_at).local().valueOf();
				});

				activities.splice(insertIndex, 0, missingActivity);
				delete this.recentlyRemoved[activityId];
				if (actionBtnThens.restore) {
					actionBtnThens.restore(activities);
				}
			} else {
				if (onRestoreMissing) {
					onRestoreMissing();
				}
			}
		}
	}

	save(event, activityId, activityFlagged) {
		let {category, activities, actionBtnThens} = this.props,
			{collapseItem, collapsingItems} = this.state,
			isSavedList = category === "saved";

		if (collapseItem === null && isSavedList) {
			collapsingItems.push(activityId);
			this.setState({
				collapseItem: {
					onExitedHandler: this.save,
					activityId: activityId,
					activityFlagged: activityFlagged
				},
				collapsingItems: collapsingItems
			});
		} else {
			let action = 'unsave',
				message = 'Removing from Saved...',
				successMsg = 'Removed from Saved.';

			activityId = collapseItem ? collapseItem.activityId : activityId;
			activityFlagged = collapseItem ? collapseItem.activityFlagged : activityFlagged;

			if(!activityFlagged) {
				action = "save";
				message = 'Saving...';
				successMsg = "Saved.";
			}

			// toggle icon instantly
			let activity,
				/* eslint-disable eqeqeq */
				activityIndex = activities.findIndex(a => a.id == activityId);
				/* eslint-enable eqeqeq */

			if (activityIndex !== -1) {
				activity = activities[activityIndex];
				activity.flagged = !activity.flagged;
				activities[activityIndex] = activity;
			}

			this.setState({collapseItem: null});

			this.fido.fetch(`/activities/${activityId}/${action}`, {
				method: "PUT",
			}, message, successMsg)
				.then(data => {
					if(data && 'activity' in data) {
						let {category, activities} = this.props,
							collapsingItems = this.state.collapsingItems.filter(id => id !== data.activity.id);

						this.setState({collapsingItems: collapsingItems});

						if(category === 'alerts' || !activityFlagged || category == null) { // category is null in GroupedActivity
							/* eslint-disable eqeqeq */
							let activityIndex = activities.findIndex(activity => activity.id == data.activity.id);
							/* eslint-enable eqeqeq */

							if(activityIndex > -1) {
								activities[activityIndex] = data.activity;
							} else {
								console.warn('Could not update post: ', data.activity.id);
							}

						} else if(category === 'saved') {
							/* eslint-disable eqeqeq */
							activities = activities.filter(activity => activity.id != data.activity.id);
							/* eslint-enable eqeqeq */
						}

						if (actionBtnThens.save) {
							actionBtnThens.save(activities);
						}
					}

				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	shareClicked(event, activityId) {
		this.setState({
			shareOpen: (this.state.shareOpen ? false : true),
			shareActivity: activityId
		});
	}

	shareClosed(event) {
		this.setState({
			shareOpen: (this.state.shareOpen ? false : true)
		});
	}

	share(activityId, values) {
		if(activityId && values && Object.keys(values).length) {
			this.shareClosed();
			this.fido.fetch("/activities/" + activityId + "/share", {
				method: "PUT",
				body: JSON.stringify(values)
			}, 'Sharing...', 'Shared.')
				.then((data) => {
					if (data && data.activity) {
						let {activities, actionBtnThens} = this.props,
							/* eslint-disable eqeqeq */
							activityIndex = activities.findIndex(a => a.id == activityId);
							/* eslint-enable eqeqeq */

						if(activityIndex > -1) {
							activities[activityIndex] = data.activity;
							if (actionBtnThens.share) {
								actionBtnThens.share(activities);
							}
						} else {
							console.warn('Could not update post: ', data.activity.id);
						}
					}
				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	rate(rating) {
		if(rating && Object.keys(rating).length) {
			let activityId = rating.alert_id;
			this.fido.fetch(`/activities/${activityId}/rate`, {
				method: "PUT",
				body: JSON.stringify(rating)
			}, 'Rating...', 'Rated.')
				.then((data) => {
					if (data && data.activity) {
						let {activities, rateThen} = this.props,
							/* eslint-disable eqeqeq */
							activityIndex = activities.findIndex(a => a.id == activityId);
							/* eslint-enable eqeqeq */

						if(activityIndex > -1) {
							activities[activityIndex] = data.activity;
							if (rateThen) {
								rateThen(activities);
							}
						} else {
							console.warn('Could not rate alert: ', data.activity.id);
						}
					}
				})
				.catch(error => this.props.showSnackbar(error.message));
		}
	}

	handleExclusions(history) {
		let {activities, activitiesUpdated} = this.props;
		if(activities && "location" in history && history.location.state && Object.keys(history.location.state).length &&
			("excludeId" in history.location.state || "includeId" in history.location.state || "recentlyRemoved" in history.location.state)) {
			let state = history.location.state,
				excludeId = state.excludeId,
				includeId = state.includeId;

			if (excludeId) {
				activities = this.filterDeletedActivity(excludeId);
				delete history.location.state.excludeId;
			}

			if (includeId) {
				/* eslint-disable eqeqeq */
				let recentlyRemoved = activities.find(activity => activity.id == includeId);
				/* eslint-enable eqeqeq */
				if (!recentlyRemoved) {
					this.recentlyRemoved[includeId] = history.location.state.recentlyRemoved;
					this.restoreActivity(includeId);
				}

				delete history.location.state.includeId;
				delete history.location.state.recentlyRemoved;
			}

			activitiesUpdated(activities);
		}
	}

	filterDeletedActivity(activityId) {
		let {activities} = this.props;

		if(activities) {
			/* eslint-disable eqeqeq */
			// save in case of undo
			this.recentlyRemoved[activityId] = activities.find(activity => activity.id == activityId);
			activities = activities.filter(activity => activity.id != activityId);
			/* eslint-enable eqeqeq */
		}

		return activities;
	}

	collapseExited() {
		let {collapseItem} = this.state;
		if (collapseItem) {
			collapseItem.onExitedHandler();
		}
	}

	onYoutubePlay(id) {
		let playing,
			{youtube} = this.state;

		if (youtube.currentlyPlaying) {
			playing = youtube.videos.find((vid) => {
				return vid.id === youtube.currentlyPlaying;
			});

			if (playing) {
				playing.player.stopVideo();
			}
		}

		this.setState({
			youtube: {
				currentlyPlaying: id,
				videos: this.state.youtube.videos
			}
		});
	}

	onYoutubeStop(id) {
		let {youtube} = this.state;

		if (youtube.currentlyPlaying === id) {
			this.setState({
				youtube: {
					currentlyPlaying: null,
					videos: this.state.youtube.videos
				}
			});
		}
	}

	onYoutubeReady(id, event) {
		let {youtube} = this.state,
			vids = this.state.youtube.videos;

		vids.push({
			id: id,
			player: event.target
		});

		this.setState({
			youtube: {
				videos: vids,
				currentlyPlaying: youtube.currentlyPlaying
			}
		});
	}

	render() {
		let actionBtns = Constants.ACTIVITY.ACTION_BUTTONS,
			{collapsingItems, shareOpen, shareActivity} = this.state,
			{classes, activities, hasMoreItems, showSnackbar,
				moreTopicsHandler, trackViews, history, newCategory,
				allowViewMoreComments, numberOfViewableComments, loadMore, suppressNavigation, expandContent,
				maxTopics, topicClickHandler, listClasses, clickHandler, lastPage, showCategory, itemLowerButtons} = this.props;

		return (
			(activities && activities.length) ? (
				<div>
					<GroupedList
						items={activities}
						lastPage={lastPage}
						loadMore={loadMore}
						hasMore={hasMoreItems}
						classes={listClasses}
						render={(activity, groupKey, index) => {
							return activity.isTopic ?
								index <= maxTopics ?
									<TrendingTopicListItem
										key={`key-${activity.id}`}
										topic={activity}
										topicNumber={++index}
										className={classes.topicItem}
										isShowMore={index > maxTopics}
										showMoreHandler={moreTopicsHandler}
										clickHandler={topicClickHandler} /> : null
								:
								<DeconstructActivity
									activity={activity}
									key={`key-${index}`}
									render={props => {
										let {activityId} = props,
											open = collapsingItems.indexOf(activityId) === -1;

										return (
											<Collapse
												in={open}
												id={activityId}
												onExited={this.collapseExited}>
												<ActivityListItem
													ready
													postOnly
													{...props}
													history={history}
													expandContent={expandContent}
													showCategory={showCategory}
													suppressNavigation={suppressNavigation}
													itemKey={`item-${groupKey}-${activityId}`}
													trackViews={trackViews}
													trackViewHandler={this.trackView}
													newCategory={newCategory}
													showSnackbar={showSnackbar}
													clickHandler={clickHandler}
													rateHandler={this.rate}
													addCommentHandler={(formVals) => this.addComment(formVals, activityId)}
													allowViewMoreComments={allowViewMoreComments}
													numberOfViewableComments={numberOfViewableComments}
													activityId={activityId}
													onYoutubePlay={this.onYoutubePlay}
													onYoutubeStop={this.onYoutubeStop}
													onYoutubeReady={this.onYoutubeReady}
													actions={[
														actionBtns.getRemove({
															handleClick: (event) => this.remove(event, activityId)
														}),
														actionBtns.getSave({
															flagged: props.flagged,
															handleClick: (event) => this.save(event, activityId, props.flagged)
														}),
														actionBtns.getShare({
															handleClick: (event) => this.shareClicked(event, activityId)
														})
													]}
													lowerButtons={itemLowerButtons}
												/>
											</Collapse>
										);
									}}/>;
						}}
						grouper={activity => {
							if (activity.isTopic) {
								// it's a trending topic
								return "Trending";
							} else {
								// activity not desconstructed yet, so using published_at iso time; also note create moment
								// as UTC (from server) so can show in local date
								let moment = new MomentCore.utc(activity.published_at);
								return moment.local().format('MMM Do');
							}
						}}
					/>
					<ShareActivityForm
						open={shareOpen}
						activityId={shareActivity}
						handleClose={(event) => this.shareClosed(event)}
						handleSubmit={(activityId, values) => this.share(activityId, values)} />
				</div>
			) : (
				<FullscreenMessage message="No Results" />
			)
		);
	}
}

ActivityList.defaultProps = {
	trackViews: true,
	suppressNavigation: false
};

ActivityList.propTypes = {
	classes: PropTypes.object.isRequired,
	listClasses: PropTypes.object,
	activities: PropTypes.array,
	showSnackbar: PropTypes.func,
	history: PropTypes.object,
	location: PropTypes.object,
	hasMoreItems: PropTypes.bool,
	actionBtnThens: PropTypes.object,
	rateThen: PropTypes.func,
	addCommentThen: PropTypes.func,
	category: PropTypes.string,
	newCategory: PropTypes.string,
	loadMore: PropTypes.func,
	lastPage: PropTypes.number,
	maxTopics: PropTypes.number,
	moreTopicsHandler: PropTypes.func,
	topicClickHandler: PropTypes.func,
	clickHandler: PropTypes.func,
	trackViewThen: PropTypes.func,
	onRestoreMissing: PropTypes.func,
	activitiesUpdated: PropTypes.func,
	allowViewMoreComments: PropTypes.bool,
	numberOfViewableComments: PropTypes.number,
	showCategory: PropTypes.bool,
	trackViews: PropTypes.bool,
	itemLowerButtons: PropTypes.array,
	suppressNavigation: PropTypes.bool,
	expandContent: PropTypes.bool
};

export default withStyles(styles)(ActivityList);
