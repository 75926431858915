import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Constants from "../utils/Constants";
import AuthorizedUser from "../utils/AuthorizedUser";
import { withStyles, Avatar as MUIAvatar, withTheme } from '@material-ui/core';

const styles = theme => ({
	avatarSmaller: {
		width: 38,
		height: 38
	},

	avatar: {
		width: 48,
		height: 48
	},

	avatarBigger: {
		width: 96,
		height: 96
	},

	avatarBiggest: {
		width: Constants.USER.AVATAR.SIZE.WIDTH,
		height: Constants.USER.AVATAR.SIZE.HEIGHT
	},

	circle: {
		borderRadius: "50%"
	},

	avatarInitials: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		textAlign: "center",
		position: "absolute"
	},

	iconFallbackContainer: {
		display: "flex",
		justifyContent: "center"
	},

	iconFallback: {
		position: "absolute",
		fontSize: "2.8rem",
		width: "0.65em !important"
	},

	primaryTextColor: {
		color: theme.palette.primary.main
	},

	defaultTextColor: {
		color: "#7f7f7f"
	},

	primaryCircleColor: {
		backgroundColor: theme.palette.primary.main
	},

	defaultCircleColor: {
		//backgroundColor: "#e5e5e5"
		backgroundColor: "#efeee8"
	},

	moreContrastText: {
		color: theme.palette.accessibilityColors.text.secondary
	}
});

class Avatar extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleImageError = this.handleImageError.bind(this);
		this.state = {
			brokenImage: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.authorImage !== prevProps.authorImage) {
			this.setState({brokenImage: false});
		}
	}

	getInitials() {
		if (this.props.authorName && !this.props.initials) {
			let name = this.props.authorName,
				names = name.split(' '),
				initials = names[0].substring(0, 1).toUpperCase();

			if (names.length > 1) {
				initials += names[names.length - 1].substring(0, 1).toUpperCase();
			}

			return initials;
		} else if (this.props.initials) {
			let initials = this.props.initials;

			return initials;
		}
	}

	handleImageError() {
		this.setState({brokenImage: true});
	}

	getAuthorImage(avatar) {
		if (avatar && !avatar.match(/data:image/ig)) {
			if (avatar.match(/http:\/\/|https:\/\//ig)) {
				return avatar;
			} else {
				return `/api/v1/next/avatar/${avatar}`;
			}
		}

		return avatar;
	}

	render() {
		let {classes, useIconFallback, primary, authorImage, sizeFactor, className} = this.props,
			initials = this.getInitials(),
			circleColorClass = primary ? classes.primaryCircleColor : classes.defaultCircleColor,
			textColorClass = AuthorizedUser.wantsMoreContrast() ? classes.moreContrastText : primary ? classes.primaryTextColor : classes.defaultTextColor,
			avatarSizeClass = sizeFactor > 1 ?
				sizeFactor === 2 ? classes.avatarBigger : classes.avatarBiggest
				: sizeFactor < 1 ? classes.avatarSmaller : classes.avatar;

		authorImage = this.getAuthorImage(authorImage);

		return !authorImage || this.state.brokenImage || this.props.forceInitials ? (
			<div className={`${className} ${useIconFallback ? classes.iconFallbackContainer : ""}`}>
				{useIconFallback ? (
					<FontAwesomeIcon
						color="#bdbdbd" // if we revert defaultCircelColor to grey, change this back to white
						icon={["fal", "user"]}
						size={sizeFactor > 0 ? `${sizeFactor * 3}x` : null}
						title="missing profile photo"
						className={`${className} ${classes.iconFallback} ${avatarSizeClass}`} />
				) : (
					<span
						style={{fontSize: `${sizeFactor}rem`}}
						className={`${className} ${classes.avatarInitials} ${avatarSizeClass} ${textColorClass}`}>{initials}</span>
				)}
				<div className={`${className} ${classes.circle} ${avatarSizeClass} ${circleColorClass}`}></div>
			</div>
		) : (
			<MUIAvatar
				src={authorImage}
				onError={this.handleImageError}
				imgProps={{"alt": "user profile image"}}
				className={`${className} ${avatarSizeClass}`} />
		);
	}
}

Avatar.defaultProps = {
	sizeFactor: 1
};

Avatar.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	authorName: PropTypes.string,
	authorImage: PropTypes.string,
	initials: PropTypes.string,
	primary: PropTypes.bool,
	forceInitials: PropTypes.bool,
	useIconFallback: PropTypes.bool,
	sizeFactor: PropTypes.number
};

export default withStyles(styles)(withTheme(Avatar));