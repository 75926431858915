import React, { Component } from 'react';
import PropTypes from 'prop-types';

import logo from "../assets/ss-loading-logo.png";
import iosShare from "../assets/ios-share.png";
import Constants from '../utils/Constants';
import DialogSlideTransition from "./DialogSlideTransition";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';

const styles = theme => ({
	dialogPaper: {
		minWidth: 290,
		minHeight: 420
	},

	center: {
		textAlign: "center"
	},

	content: {
		paddingLeft: 17,
		paddingRight: 17
	},

	centeredImg: {
		display: "block",
		marginRight: "auto",
		marginLeft: "auto"
	},

	homeScreenMsg: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		background: theme.palette.grey[200]
	},

	logo: {
		width: "50%",
		height: "50%",
		marginTop: theme.spacing(4)
	},

	shareImg: {
		width: 25,
		paddingLeft: 2,
		paddingRight: 2,
		paddingBottom: 2
	}
});

class IOSInstallDialog extends Component {
	render() {
		let {classes, showIOSInstallDlg, iOSDlgCloseHandler} = this.props;
		return (
			<Dialog
				open={showIOSInstallDlg}
				onClose={iOSDlgCloseHandler}
				classes={{paper: classes.dialogPaper}}
				TransitionComponent={DialogSlideTransition}>
				<Grid container justify="center" alignItems="center">
					<Grid item><img className={`${classes.logo} ${classes.centeredImg}`} src={logo} alt="dark logo"/></Grid>
				</Grid>
				<DialogTitle className={classes.center}>Install SocialSentinel</DialogTitle>
				<DialogContent className={`${classes.center} ${classes.content}`}>
					<DialogContentText>
						Install the SocialSentinel application on your home screen for quick and easy access when you're on the go!
						{!Constants.ENV.IS_SAFARI ? <span><br/><br/><Typography variant="caption">* Note: You must be using <b>Safari</b></Typography></span> : null}
					</DialogContentText>
				</DialogContent>
				<Grid container justify="center" alignItems="center" className={classes.homeScreenMsg}>
					<Grid item><Typography variant="caption">Just tap</Typography></Grid>
					<Grid item><img src={iosShare} className={`${classes.shareImg}`} alt="add to home screen"/></Grid>
					<Grid item><Typography variant="caption">then <b>'Add to Home Screen'</b></Typography></Grid>
				</Grid>
			</Dialog>
		);
	}
}

IOSInstallDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	showIOSInstallDlg: PropTypes.bool,
	iOSDlgCloseHandler: PropTypes.func
};

export default withStyles(styles)(IOSInstallDialog);
