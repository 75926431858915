import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Switch, FormControlLabel } from '@material-ui/core';

const styles = {
	root: {
		marginLeft: 0,
		marginRight: 0,
		justifyContent: 'space-between'
	},

	reverseDirection: {
		flexDirection: "row-reverse",
	},

	spread: {
		width: "100%"
	}
};

class LabeledSwitch extends Component {
	render() {
		let cls,
			{classes, className, reverse, spread, onChange, checked, color, ...rest} = this.props;

		reverse = reverse && classes.reverseDirection || null;
		spread = spread && classes.spread || null;
		cls = `${classes.root} ${className} ${reverse} ${spread}`;

		// in case it comes in as a string
		if (typeof checked === "string") {
			checked = checked === "true";
		}

		return (
			<FormControlLabel
				{...rest}
				checked={!!checked}
				className={cls}
				classes={{label: classes.root}}
				control={
					<Switch
						color={color || "primary"}
						inputProps={{'aria-label': rest.label || ''}}
						onChange={(e) => {e.target.value = e.target.checked; onChange(e);}} />
				}
			/>
		);
	}
}

LabeledSwitch.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	color: PropTypes.string,
	reverse: PropTypes.bool,
	spread: PropTypes.bool,
	checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func
};

export default withStyles(styles)(LabeledSwitch);
