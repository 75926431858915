import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ResponsiveDialog from "./ResponsiveDialog";
import Constants from "../utils/Constants";

import { withStyles, Popover, Typography, Link } from '@material-ui/core';

const styles = theme => ({
	icon: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		color: theme.palette.primary.main
	},

	pointer: {
		cursor: "pointer"
	},

	dialogPaper: {
		minWidth: 290,
		minHeight: 420
	},

	popover: {
		pointerEvents: "none"
	},

	popoverPaper: {
		width: 300,
		padding: theme.spacing(2)
	}
});

class MoreInfo extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			anchorEl: null,
			popupOpen: false,
			popoverOpen: false
		};

		this.clickHandler = this.clickHandler.bind(this);
		this.keyDownHandler = this.keyDownHandler.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.showPopover = this.showPopover.bind(this);
		this.closePopover = this.closePopover.bind(this);
	}

	clickHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({popupOpen: true});
	}

	keyDownHandler(ev) {
		if (ev.keyCode === 32 || ev.keyCode === 13) { // space or enter
			if (this.props.content) {
				this.clickHandler();
			} else if (this.props.hover) {
				this.showPopover();
			}
		} else if (ev.keyCode === 27) { // esc
			if (this.props.content) {
				this.clickHandler();
			} else if (this.props.hover) {
				this.closePopover();
			}
		}
	}

	showPopover(event) {
		this.setState({
			popoverOpen: true,
			anchorEl: event.target
		});
	}

	closePopover() {
		this.setState({
			anchorEl: null,
			popoverOpen: false
		});
	}

	closePopup(e) {
		this.setState({popupOpen: false});
	}

	render() {
		let {anchorEl, popupOpen, popoverOpen} = this.state,
			{classes, textToUse, content, title, hover, hoverTitle, hoverContent, maxWidth} = this.props,
			isMobile = Constants.ENV.IS_MOBILE,
			hTitle = hoverTitle || title,
			hContent = hoverContent || content;

		return (
			<span>
				<span
					onMouseEnter={hover ? this.showPopover : null}
					onMouseLeave={hover ? this.closePopover : null}
					onMouseDown={isMobile && hover ? this.showPopover : null}
					onMouseUp={isMobile && hover ? this.closePopover : null}
					onClick={content && !textToUse ? this.clickHandler : null}
					tabIndex={0}
					role="button"
					aria-label={hoverTitle || title}
					onKeyDown={content ? this.keyDownHandler : null}>
					{textToUse ? (
						<Link
							onClick={this.clickHandler}
							className={classes.pointer}
							aria-label={`learn more about ${title}`}>{textToUse}</Link>
					) : (
						<FontAwesomeIcon
							icon={["far", "question-circle"]}
							title={hoverTitle || title}
							className={`${classes.icon} ${content ? classes.pointer : ""}`} />
					)}
				</span>
				<ResponsiveDialog
					open={popupOpen}
					title={title}
					content={content}
					maxWidth={maxWidth}
					closeHandler={this.closePopup} />
				{hover ?
					<Popover
						open={popoverOpen}
						anchorEl={anchorEl}
						onClose={this.closePopover}
						className={classes.popover}
						classes={{paper: classes.popoverPaper}}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}>
						{hTitle ?
							typeof hTitle === "string" ?
								<Typography variant="h1">{hTitle}</Typography>
								:
								hTitle
							: null
						}
						{hContent ?
							typeof hContent === "string" ?
								<Typography>{hContent}</Typography>
								:
								hTitle
							: null
						}
					</Popover>
					: null
				}
			</span>
		);
	}
}

MoreInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	textToUse: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	hover: PropTypes.bool,
	hoverTitle: PropTypes.string,
	hoverContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	maxWidth: PropTypes.string
};

export default withStyles(styles)(MoreInfo);
