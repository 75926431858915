import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar.js';
import ReactPlaceholder from 'react-placeholder';

import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
	root: {
		flexGrow: 1
	},

	avatar: {
		flexBasis: 0,
	},

	content: {
		paddingLeft: theme.spacing(2),
		width: `calc(100% - ${theme.spacing(2)}px)`
	}
});

class AvatarRowWithCol extends Component {
	render() {
		let {classes, authorName, fidoError, contentClassName, useIconFallback, ready} = this.props;
		return (
			<div className={classes.root}>
				<Grid container justify="flex-start">
					<Grid className={classes.avatar} item xs={3} >
						<ReactPlaceholder type='round' ready={ready} style={{width: 48, height: 48}} showLoadingAnimation={!fidoError}>
							<Avatar
								authorName={authorName}
								authorImage={this.props.authorImage}
								useIconFallback={useIconFallback}
								primary={this.props.primary}
								forceInitials={this.props.forceInitials} />
						</ReactPlaceholder>
					</Grid>
					<Grid item xs={9} className={classes.content}>
						<div className={contentClassName}>
							{this.props.children}
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

AvatarRowWithCol.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	contentClassName: PropTypes.string,
	authorName: PropTypes.string,
	authorImage: PropTypes.string,
	primary: PropTypes.bool,
	forceInitials: PropTypes.bool,
	fidoError: PropTypes.bool,
	useIconFallback: PropTypes.bool,
	ready: PropTypes.bool
};

export default withStyles(styles)(AvatarRowWithCol);
