import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Constants from "../utils/Constants";

import StandardFormLayout from './StandardFormLayout';
import { Button, withStyles, withTheme } from '@material-ui/core';

const styles = theme => ({
	longButtonLabel: {
		display: "none",

		[theme.breakpoints.up(375)]: {
			display: "inline"
		}
	},

	submitBtnWrapper: {
		display: "flex",
		justifyContent: "center"
	},

	submitBtn: {
		maxWidth: "65%"
	}
});

class GetResetForm extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			submitEnabled: true
		}

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		// Here we reset the submitEnabled state if a form error is returned
		if(this.state.submitEnabled === false && prevState.submitEnabled === false) {
			this.setState({submitEnabled: true});
		}
	}

	// Convert the submitted form even to an object of values instead
	handleSubmit = (event) => {
		// Since we need to check for the email server side before success
		// It's better to disable subsequent submissions while we process
		this.setState({submitEnabled: false});

		let form = event.target;
		let values = {
			id: this.props.formValues.id,
		};

		Object.keys(form.elements).map(e => {
			// Don't send if element not named
			if(form.elements[e].nodeName && form.elements[e].name) {
				values[form.elements[e].name] = form.elements[e].value;
			}

			return e;
		});

		this.props.handleSubmit(values);
	}

	render() {
		let { classes, handleChange, formValues } = this.props,
			{ email } = formValues;

		return (
			<div>
				<ValidatorForm
					debounceTime={500}
					onSubmit={this.handleSubmit}>
					<StandardFormLayout>
						<TextValidator
							fullWidth
							ref={r => (this.email = r)}
							id="email"
							label="Email"
							name="email"
							helperText="Email address to send reset link to"
							value={email || ''}
							margin="normal"
							validators={['required', 'isEmail']}
							errorMessages={[Constants.ERROR_MESSAGES['required'], Constants.ERROR_MESSAGES['isEmail']]}
							onChange={handleChange} />
						<div className={classes.submitBtnWrapper}>
							<Button
								fullWidth
								id="submit"
								type="submit"
								variant="contained"
								disabled={!this.state.submitEnabled}
								className={classes.submitBtn}
								color="primary"><span>Get<span className={classes.longButtonLabel}> Password</span> Reset Link</span></Button>
						</div>
					</StandardFormLayout>
				</ValidatorForm>
			</div>
		);
	}
}

GetResetForm.propTypes = {
	classes: PropTypes.object,
	theme: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	formValues: PropTypes.object.isRequired
};

export default withStyles(styles)(withTheme(GetResetForm));
