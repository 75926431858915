import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Banner from './Banner';

class RestoreBanner extends Component {
	constructor(props, context) {
		super(props, context);
		this.bannerRef = React.createRef();
	}

	render() {
		let {flagged, show, handleRestore} = this.props;
		return (
			<Banner
				bannerRef={this.bannerRef}
				show={show}
				icon={{icon: ["fas", "trash-alt"]}}
				content="This post was previously removed."
				actions={[{text: flagged ? "Restore" : "Restore & Save", color: "primary", handler: () => handleRestore()}]} />
		);
	}
}

RestoreBanner.propTypes = {
	show: PropTypes.bool,
	flagged: PropTypes.number,
	handleRestore: PropTypes.func
};

export default RestoreBanner;