import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import Constants from "../utils/Constants";
import StandardFormLayout from './StandardFormLayout';
import PasswordField from "./PasswordField";
import LoginHelpButton from "./LoginHelpButton";

import { withStyles, Button, FormHelperText, withTheme } from '@material-ui/core';

const styles = theme => ({
	submitBtnWrapper: {
		display: "flex",
		justifyContent: "center"
	},

	submitBtn: {
		maxWidth: "65%"
	},

	helperText: {
		display: "block"
	}
});

class LoginForm extends Component {
	constructor(props, context) {
		super(props, context);

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// Convert the submitted form even to an object of values instead
	handleSubmit = (event) => {
		let form = event.target;
		let values = {
			id: this.props.formValues.id,
		};

		Object.keys(form.elements).map(e => {
			// Don't send if element not named
			if(form.elements[e].nodeName && form.elements[e].name) {
				values[form.elements[e].name] = form.elements[e].value;
			}

			return e;
		});

		this.props.handleSubmit(values);
	}

	render() {
		let { classes, handleChange, handleForgotToggle, formValues } = this.props,
			{ email, password } = formValues;

		return (
			<ValidatorForm
				method="post"
				action="./"
				debounceTime={500}
				onSubmit={this.handleSubmit}>
				<StandardFormLayout submitOnKeyPress>
					<TextValidator
						ref={r => (this.username = r)}
						fullWidth
						autoFocus
						id="email"
						label="Enter username or email"
						name="email"
						helperText="Email address or username for login"
						margin="normal"
						value={email || ''}
						inputProps={{
							autoCapitalize: "off",
							autoComplete: "username"
						}}
						onChange={handleChange}
						validators={['required']}
						errorMessages={[Constants.ERROR_MESSAGES['required']]} />
					<PasswordField
						onRef={ref => (this.passwordField = ref)}
						fullWidth
						id="password"
						label="Enter password"
						name="password"
						margin="normal"
						inputProps={{
							'aria-describedby': "password-field-helper-text"
						}}
						value={password || ''}
						helperText={
							<LoginHelpButton
								handleClick={handleForgotToggle}>Forgot Password?</LoginHelpButton>
						}
						FormHelperTextProps={{
							className: classes.helperText,
							component: 'div'
						}}
						onChangeHandler={handleChange}
						validators={['required']} />
					<div className={classes.submitBtnWrapper}>
						<Button
							fullWidth
							id="submit"
							type="submit"
							variant="contained"
							className={classes.submitBtn}
							color="primary">Sign In</Button>
					</div>
				</StandardFormLayout>
				<FormHelperText id="password-field-helper-text">Password for login</FormHelperText>
			</ValidatorForm>
		);
	}
}

LoginForm.propTypes = {
	classes: PropTypes.object,
	theme: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleForgotToggle: PropTypes.func,
	formValues: PropTypes.object.isRequired
};

export default withStyles(styles)(withTheme(LoginForm));
