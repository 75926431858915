import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highchart from "./Highchart";
import MomentCore from 'moment';

import HighchartsConfig from '../../utils/HighchartsConfig';
import ReportDataMasseuse from "../utils/ReportDataMasseuse";
import DetailedReport from "../containers/DetailedReport";
import { withTheme } from '@material-ui/core';

//import {associationsData} from "../data/reports";

class AssociationsReport extends Component {
	constructor(props, context) {
		super(props, context);

		let {theme, chartClasses, filter, textColor, byHour} = this.props;
		this.state = {
			chartConfig: HighchartsConfig.merge({
				chart: {
					className: chartClasses.chart
				},
				title: {
					align: "left",
					text: ("Associated Posts"),
					style: {color: textColor ? textColor : theme.palette.common.white}
				},
				accessibility: {
					pointValueSuffix: " posts",
					description: `The number of posts associated with your account per day over the last ${filter.previousDays} days.`
				},
				yAxis: {
					title: "",
					lineWidth: 0,
					gridLineWidth: 0,
					minRange : 0.1,
					allowDecimals: false,
					labels: {
						style: {
							color: textColor ? textColor : theme.palette.common.white,
							fontSize: "15px"
						}
					}
				},
				xAxis: {
					type: "datetime",
					tickWidth: 0,
					offset: 20,
					labels: {
						y: 35,
						rotation: -45,
						align: "center",
						style: {
							color: textColor ? textColor : theme.palette.common.white,
							fontSize: "15px"
						}
					},
					style: {
						color: textColor ? textColor : theme.palette.common.white
					}
				},
				tooltip: {
					pointFormatter: function() {
						let numFormatter = new Intl.NumberFormat();
						let date = new MomentCore(this.name || this.x),
							header = (byHour) ? '<span style="font-weight: bold">' + date.format("MMM D h:mmA") + '</span><br/>' : '<span style="font-weight: bold">' + date.format("MMM D") + '</span><br/>',
							content = `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${numFormatter.format(this.y)}</b><br/>`;
						return header + content;
					}
				},
				plotOptions: {
					line: {
						lineWidth: theme.spacing(1),
						softThreshold: false,
						marker: {
							radius: theme.spacing(1)
						}
					}
				},
				series: [{
					type: "line",
					name: "Posts",
					color: theme.palette.secondary.blue
				}]
			}, {
				modifyTickInterval: true,
				previousDays: filter.previousDays
			})
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			let {chartConfig} = this.state,
				{data} = this.props;

			// TEMP
			//data = associationsData;

			if (data) {
				ReportDataMasseuse.convertDatesToMillis(data);
				chartConfig.series[0].data = ReportDataMasseuse.massageData(data, "period", "associations");
				chartConfig.yAxis.labels.enabled = ReportDataMasseuse.getMaxValue(data, "associations") !== 0;
			} else {
				chartConfig.series[0].data = null;
			}

			this.setState({chartConfig: chartConfig});
		}
	}

	render() {
		let {chartConfig} = this.state,
			{fidoError} = this.props;

		return (
			<DetailedReport
				fidoError={fidoError}
				ready={chartConfig.series[0].data ? true : false}>
				<Highchart
					darkTheme
					noDataMsg={"No Recent Associations"}
					chartConfig={chartConfig} />
			</DetailedReport>
		);
	}
}

AssociationsReport.propTypes = {
	theme: PropTypes.object.isRequired,
	chartClasses: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func,
	location: PropTypes.object,
	filter: PropTypes.object,
	data: PropTypes.array,
	fidoError: PropTypes.bool
};

export default withTheme(AssociationsReport);
