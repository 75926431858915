import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResponsiveDialog from "./ResponsiveDialog";

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ReactPlaceholder from 'react-placeholder';
import { RectShape, TextBlock, TextRow } from 'react-placeholder/lib/placeholders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Map, Polygon, Circle, GoogleApiWrapper} from 'google-maps-react';

import Fido from '../utils/Fido';
import Constants from "../utils/Constants";
import StandardFormLayout from './StandardFormLayout';
import PaddedLayout from "../containers/PaddedLayout";
import LabeledSwitch from "./LabeledSwitch";
import Heading from "./Heading";
import UserItemListing from "./UserItemListing";
import AuthorizedUserContext from "../utils/AuthorizedUserContext";

import {
    Paper,
    Button,
    Grid,
    Fab,
    FormLabel,
    Typography,
	Slider,
    withStyles,
    withWidth,
    withTheme,
} from '@material-ui/core';

const styles = theme => ({
	paper: {
		padding: theme.spacing(2),
	},

	// so they are 2 rows like middle button on md layouts
	largeBtn: {
		[theme.breakpoints.up(800)]: {
			paddingLeft: 55,
			paddingRight: 55
		},

		[theme.breakpoints.up(1135)]: {
			paddingLeft: 24,
			paddingRight: 24
		}
	},

	dialogPaper: {
		minWidth: 265,
		maxWidth: 450
	},

	secondary: {
		color: theme.palette.text.secondary
	},

	mapContainer: {
		height: "40vh",
		width: "100%",
		position: "relative",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},

	slider: {
		paddingTop: theme.spacing(1) + 2
	},

	sliderLabel: {
		color: theme.palette.text.primary
	},

	sliderValue: {
		lineHeight: "1.25"
	},

	sliderContainer: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},

	paddedUsers: {
		paddingTop: theme.spacing(1),
		paddingLeft: "1.5%",
		paddingRight: "1.5%"
	},

	paddedRow: {
		paddingLeft: "3%",
		paddingRight: "3%",
	},

	rowWidth: {
		width: "94%",
	},

	checkbox: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},

	fabExtendedIcon: {
		marginRight: theme.spacing(1)
	},

	fab: {
		position: "absolute",
		bottom: theme.spacing(3),
		left: theme.spacing(1),
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,

		"&:hover": {
			color: theme.palette.primary.main
		}
	},

	draw: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},

	descriptiveLabel: {
		marginTop: theme.spacing(1) + 2,
		marginBottom: theme.spacing(1) + 2
	}
});

const placeholderColor = Constants.COLORS.PLACEHOLDER_BG;
const itemPlaceHolder = (
	<div>
		<Paper style={{padding: 10, marginTop: 10}}>
			<div style={{display: "flex", marginTop: 25, flexDirection: "column"}}>
				<div style={{flex: 1}}>
					<TextBlock rows={2} color={placeholderColor} style={{width: "95%"}} />
				</div>
				<div style={{flex: 1, marginTop: 10}}>
					<TextBlock rows={2} color={placeholderColor} style={{width: "100%"}} />
				</div>
			</div>
		</Paper>
		<Paper style={{padding: 10, marginTop: 10}}>
			<TextRow color={placeholderColor} style={{marginTop: 10}}><span></span></TextRow>
			<RectShape style={{height: "20rem", marginTop: 10, marginBottom: 10}} color={placeholderColor}><span></span></RectShape>
		</Paper>
	</div>
);

const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 1});

class LocationDetailsForm extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			geoDlgOpen: false,
			formSubmit: false,
			radius: 0,
			slidingRadius: 0,
			paths: [],
			mapCenter: null,
			showAllUsers: false,
			mapColor: "#20be83"
		};

		this.fido = new Fido();

		this.handleUsers = this.handleUsers.bind(this);
		this.handleRedraw = this.handleRedraw.bind(this);
		this.handleGeoDialog = this.handleGeoDialog.bind(this);
		this.handleGeoAddress = this.handleGeoAddress.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitClick = this.handleSubmitClick.bind(this);
		this.handleError = this.handleError.bind(this);
		this.handleSlide = this.handleSlide.bind(this);
		this.handleSlideEnd = this.handleSlideEnd.bind(this);
		this.setupMap = this.setupMap.bind(this);
	}

	componentDidMount() {
		this.setupValidators();
	}

	componentDidUpdate(prevProps, prevState) {
		// Once we have the formValues, setup the map
		// Using 'geofence' here since it's not being updated in the render
		if (this.props.formValues.geofence && this.props.formValues.geofence !== prevProps.formValues.geofence) {
			this.setupMap();
		}
	}

	setupMap() {
		let radius = 0, paths = [], mapCenter = null, mapColor = "#20be83";
		if (this.props.formValues.geofence) {
			let geofence = JSON.parse(this.props.formValues.geofence);
			if (geofence.radius) {
				radius = parseFloat(geofence.radius);
			} else {
				geofence.coordinates.map(coordinate => {
					paths.push({lat: parseFloat(coordinate[0]), lng: parseFloat(coordinate[1])});
					return coordinate;
				});
			}
		}
		if (this.props.formValues.longitude && this.props.formValues.latitude) {
			mapCenter = {lat: this.props.formValues.latitude, lng: this.props.formValues.longitude};
		}
		if (this.props.formValues && this.props.formValues.active === 0) {
			mapColor = "#757575";
		}
		this.setState({radius: radius, slidingRadius: parseFloat(numFormatter.format(radius)), paths: paths, mapCenter: mapCenter, mapColor: mapColor});
	}

	setupValidators() {
		ValidatorForm.addValidationRule('isValidName', (value) => {
			var restrictions = Constants.LOCATION.RESTRICTIONS;
			var valueArr = value.split(" ");
			var isValid = true;

			valueArr.map(value => {
				if(restrictions.indexOf(value) > -1) {
					isValid = false;
				}
				return isValid;
			})

			return isValid;
		});
	}

	componentWillUnmount() {
		this.fido.dropIt();
	}

	handleSlide(event, value) {
		if (typeof event.persist === "function") { event.persist(); }
		this.setState({
			slidingRadius: parseFloat(numFormatter.format(value))
		}, () => {
			if (event.type && event.type === "click") {
				this.handleSlideEnd();
			}
		});
	}

	handleSlideEnd() {
		this.setState({
			radius: this.state.slidingRadius
		}, () => {
			this.props.handleRadius(this.state.slidingRadius);
			this.props.handleGeoChange();
		});
	}

	handleGeoAddress() {
		this.fido.fetch('/locations/address', {
			method: "POST",
			body: JSON.stringify(this.props.formValues)
		})
			.then((data) => {
				this.setState({
					radius: 0.5,
					slidingRadius: 0.5,
					mapCenter: {lat: data.lat, lng: data.lng}
				}, () => {
					this.props.handleGeoChange();
				});
			})
			.catch(error => this.props.showSnackbar(error.message));
	}

	handleSubmitClick() {
		this.setState({formSubmit: true});
	}

	handleError() {
		this.setState({formSubmit: false});
	}

	handleGeoDialog() {
		this.setState({geoDlgOpen: !this.state.geoDlgOpen});
	}

	handleRedraw() {
		this.handleGeoDialog();
		this.handleGeoAddress();
	}

	handleUsers() {
		this.setState({showAllUsers: !this.state.showAllUsers});
	}

	// Convert the submitted form even to an object of values instead
	handleSubmit = (event) => {
		let form = event.target;
		let values = {
			id: this.props.formValues.id
		};

		Object.keys(form.elements).map(e => {
			// Don't send if element not named
			if(form.elements[e].nodeName && form.elements[e].name) {
				if (form.elements[e].type === 'radio') {
					// If it's a radio type input, only use the checked value
					if (form.elements[e].checked === true) {
						values[form.elements[e].name] = form.elements[e].value;
					}
				} else if (form.elements[e].type === 'checkbox') {
					values[form.elements[e].name] = form.elements[e].checked;
				} else {
					values[form.elements[e].name] = form.elements[e].value;
				}
			}

			return e;
		});

		this.props.handleSubmit(values);
	}

	render() {
		let {classes, theme, google, history, handleChange, formValues, isLoading} = this.props,
			{radius, slidingRadius, paths, mapCenter, showAllUsers, geoDlgOpen, fidoError, mapColor} = this.state,
			{id, active, name, address, city, state, zip, use_profile_locations, users, account_id} = formValues,

			mapStyle = [
				{
					featureType: 'road',
					elementType: 'geometry',
					stylers: [
						{color: '#ffffff'},
						{visibility: 'simplified'}
					]
				}
			],

			locationDefined = (id) ? true : false, // bool if location id exists
			paddedRowCls = `${classes.paddedRow} ${classes.rowWidth}`,
			isCircle = (radius && radius > 0) ? true : false,
			tmTitle = (locationDefined) ? "Team Members ("+users.length+")" : "Team Members",
			clickPath = (locationDefined) ? "/accounts/"+account_id+"/users" : null;
		return (
            <AuthorizedUserContext.Consumer>
				{authenticatedUser => {
					return (
                        <div>
							<PaddedLayout>
								{(!isLoading) ? (
									<Paper>
										<ValidatorForm
											onSubmit={this.handleSubmit}
											onError={this.handleError}
											debounceTime={500} >
											<StandardFormLayout>
												<div className={paddedRowCls} name="statusPaddedRow">
													<Grid container justify="space-between" alignItems="center">
														<Grid item>
															<Typography>Location Status</Typography>
														</Grid>
														<Grid item>
															<LabeledSwitch
																id="active"
																name="active"
																label={active ? "Active" : "Inactive"}
																labelPlacement="start"
																className={paddedRowCls}
																checked={active}
																onChange={handleChange} />
														</Grid>
													</Grid>
												</div>
												<Heading id="locationAddressHeading" title="Address and Geofence" formKey="locAddressHeader" className={paddedRowCls}/>
												<div className={paddedRowCls} name="primaryPaddedRow">
													<TextValidator
														fullWidth
														id="name"
														label="Location Name"
														name="name"
														helperText="Name of the location"
														margin="normal"
														value={name || ''}
														onChange={handleChange}
														validators={['required', 'minStringLength:3', 'maxStringLength:100', 'isValidName']}
														autoComplete="off"
														errorMessages={[Constants.ERROR_MESSAGES['required'], 'Location name must be at least 3 characters long', 'Location name is too long', 'Religious institutions are not permissible to geofence']} />
													<TextValidator
														fullWidth
														id="address"
														label="Street Address"
														name="address"
														helperText="Address for the location"
														value={address || ''}
														margin="normal"
														validators={['required', 'minStringLength:3', 'maxStringLength:100']}
														errorMessages={[Constants.ERROR_MESSAGES['required'], 'Street address must be at least 3 characters long', 'Street address is too long']}
														onChange={handleChange}	/>
													<Grid container spacing={2}>
														<Grid xs={12} md={6} item>
															<TextValidator
																fullWidth
																id="city"
																label="City"
																name="city"
																value={city || ''}
																helperText="City where location resides"
																margin="normal"
																validators={['required', 'minStringLength:3', 'maxStringLength:64']}
																errorMessages={[Constants.ERROR_MESSAGES['required'], 'City must be at least 3 characters long', 'City name is too long']}
																onChange={handleChange} />
														</Grid>
														<Grid xs={12} md={3} item>
															<TextValidator
																fullWidth
																id="state"
																label="State"
																name="state"
																value={state || ''}
																helperText="State where location resides"
																margin="normal"
																validators={['required', 'minStringLength:2', 'maxStringLength:64', 'matchRegexp:^[A-Za-z]+$']}
																errorMessages={[Constants.ERROR_MESSAGES['required'], 'State must be at least 2 characters long', 'Location name is too long', 'Not a valid state entry']}
																onChange={handleChange} />
														</Grid>
														<Grid xs={12} md={3} item>
															<TextValidator
																fullWidth
																id="zip"
																label="ZIP Code"
																name="zip"
																helperText="Zip code for the location"
																value={zip || ''}
																margin="normal"
																onChange={handleChange} />
														</Grid>
													</Grid>
													{mapCenter ?
														<div className={classes.mapContainer} name="geofenceMap">
															<Map
																google={google}
																style={{ height: '40vh', width: '100%', position: 'relative' }}
																styles={mapStyle}
																initialCenter={mapCenter}
																center={mapCenter}
																zoom={(radius > 3) ? 10 : 14}>
																{isCircle ?
																	<Circle
																		radius={radius * 1609.344}
																		center={mapCenter}
																		strokeColor="transparent"
																		strokeOpacity={0}
																		fillColor={mapColor}
																		fillOpacity={0.3} />
																	:
																	<Polygon
																		paths={paths}
																		strokeColor="transparent"
																		strokeOpacity={0}
																		fillColor={mapColor}
																		fillOpacity={0.3} />
																}
															</Map>
															<Fab
																variant="extended"
																aria-label="Redraw From Address"
																className={classes.fab}
																onClick={this.handleGeoDialog}>
																<FontAwesomeIcon
																	icon={["far", "map"]}
																	title="redraw from address"
																	className={classes.fabExtendedIcon} /> Redraw From Address
															</Fab>
														</div>
														:
														<Button
															size="large"
															variant="contained"
															onClick={this.handleGeoAddress}
															className={classes.draw}
															classes={{sizeLarge: classes.largeBtn}}>Draw Geofence From Address</Button>
													}
													{isCircle ?
														<Grid container className={classes.sliderContainer} name="geofence-slider" spacing={theme.spacing(1)}>
															<Grid item xs={3}>
																<FormLabel component="legend" id="label" className={classes.sliderLabel}>Geofence Diameter</FormLabel>
															</Grid>
															<Grid item xs={7}>
																<Slider
																	className={classes.slider}
																	value={slidingRadius || ''}
																	max={3}
																	min={0.1}
																	step={0.1}
																	aria-labelledby="label"
																	onChange={this.handleSlide}
																	onChangeCommitted={this.handleSlideEnd}
																/>
															</Grid>
															<Grid item xs={2}>
																<Typography name="slidingRadius" className={classes.sliderValue}>{slidingRadius} miles</Typography>
															</Grid>
														</Grid> : null
													}
												</div>
												{locationDefined &&
												authenticatedUser.accountFeatures.includes(Constants.ACCOUNT.FEATURES.SOCIAL_MEDIA) &&
												authenticatedUser.permissions.includes(Constants.USER.PERMISSIONS.DATA.SOCIAL_MEDIA) ?
													<div>
														<Heading id="locationMatching" title="Geofence Matching" formKey="locMatching" className={paddedRowCls}/>
														<div className={paddedRowCls} name="locBioPaddedRow">
															<Grid container justify="space-between" alignItems="center">
																<Grid item xs={9} className={classes.descriptiveLabel}>
																	<Typography>Author Profile Location</Typography>
																	<Typography variant="caption" color="textSecondary">
																		Associate authors who identify their hometown or current location is within this location's geofence.
																	</Typography>
																</Grid>
																<Grid item>
																	<FormLabel>{use_profile_locations ? "On" : "Off"}</FormLabel>
																</Grid>
															</Grid>
														</div>
														<div className={paddedRowCls} name="locContentPaddedRow">
															<Grid container justify="space-between" alignItems="center">
																<Grid item xs={9} className={classes.descriptiveLabel}>
																	<Typography>Geotagged Post Location</Typography>
																	<Typography variant="caption" color="textSecondary">
																		Associate content that is geotagged within this location's geofence.
																	</Typography>
																</Grid>
																<Grid item>
																	<FormLabel>On</FormLabel>
																</Grid>
															</Grid>
														</div>
													</div> : null
												}
												{locationDefined ?
													<div name="userListing">
														<Heading id="locationBioHeading" title={tmTitle} formKey="locBioHeader" className={paddedRowCls}/>
														<div className={classes.paddedUsers} name="paddedUserListing">
															<UserItemListing
																items={showAllUsers ? users : users.slice(0,5)}
																history={history}
																itemClickPath={clickPath}
																buttonDisabled={users.length < 6}
																buttonText={showAllUsers ? "Show Less" : "View All Team Members"}
																buttonClickHandler={this.handleUsers} />
														</div>
													</div> : null
												}
												<Grid id="formButtons" container justify="center">
													<Grid item xs={12}>
														<div className={paddedRowCls} name="submitPaddedRow">
															<Button
																fullWidth
																size="large"
																type="submit"
																variant="contained"
																color="primary"
																onClick={this.handleSubmitClick}
																classes={{sizeLarge: classes.largeBtn}}>{(locationDefined) ? "Save Location" : "Add Location"}</Button>
														</div>
													</Grid>
												</Grid>
											</StandardFormLayout>
										</ValidatorForm>
									</Paper>
								) : (
									<div>
										<ReactPlaceholder
											ready={false}
											customPlaceholder={itemPlaceHolder}
											showLoadingAnimation={!fidoError}>
											<span></span>
										</ReactPlaceholder>
									</div>
								)}
							</PaddedLayout>
							<ResponsiveDialog
								open={geoDlgOpen}
								title="Redraw Geofence"
								content={
									<Typography>Please note: Redrawing the map may override a custom geofence from your Social Sentinel Account Manager.</Typography>
								}
								actions={
									<div>
										<Button onClick={this.handleGeoDialog} color="secondary">Cancel</Button>
										<Button onClick={this.handleRedraw} color="primary">Redraw Geofence</Button>
									</div>
								}
								closeHandler={this.handleGeoDialog} />
						</div>
                    );
				}}
			</AuthorizedUserContext.Consumer>
        );
	}
}

LocationDetailsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleRadius: PropTypes.func.isRequired,
	handleGeoChange: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func,
	formValues: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	dataLoading: PropTypes.bool
};

export default GoogleApiWrapper(
	Constants.ENV.IS_LOCAL ? (
		{apiKey: process.env.REACT_APP_MAPS_KEY}
	) : (
		{apiKey: '', client: process.env.REACT_APP_MAPS_CLIENT_ID}
	)
)(withStyles(styles)(withWidth()(withTheme(LocationDetailsForm))));
