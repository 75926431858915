
class NumberFormatter {
	// formats numbers > 1,000 to be 1k, larger than 1,000,000 to 1M
	static largeNumberFormat(number) {
		let decimals;
		const thousand = 1000,
			million = 1000000;

		if (number >= million || number <= -million) {
			decimals = number.toString().length < 9 ? 1 : 0;
			number = Math.round(number / 100000) / 10;
			number = `${number.toFixed(decimals)}M`;

		} else if (number >= thousand || number <= -thousand) {
			decimals = number.toString().length < 6 ? 1 : 0;
			number = Math.round(number / 100) / 10;
			number = `${number.toFixed(decimals)}k`;

		} else {
			const numFormatter = new Intl.NumberFormat("en-US", {maximumFractionDigits: 2});
			number = numFormatter.format(number);
		}

		return number;
	}
}

export default NumberFormatter;