import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, IconButton } from '@material-ui/core';

const styles = theme => ({
	root: {
		width: 36,
		height: 36,
		padding: 0,
		// theme.palette.primary.main if we wanted primary color
		border: "1px solid rgba(0, 0, 0, 0.23)", // taken from @material-ui's Button class for outlined buttons

		"& .fa-xs": {
			fontSize: "0.7em"
		}
	},

	label: {
		position: "relative",
		flexDirection: "column"
	},

	text: {
		fontSize: "0.5em",
		position: "absolute",
		top: theme.spacing(3.5)
	},

	primaryContained: {
		backgroundColor: theme.palette.primary.main
	},

	secondaryContained: {
		backgroundColor: theme.palette.secondary.main
	}
});

class CircledIconButton extends Component {
	render() {
		let {classes, children, color, text, contained, textClassName, labelClassName, ...rest} = this.props;

		return (
			<IconButton
				{...rest}
				color={color}
				classes={{
					label: `${classes.label} ${labelClassName || ""}`,
					root: `${classes.root} ${contained === true ? color === "primary" ? classes.primaryContained : classes.secondaryContained : ""}`
				}}>
				{children}
				{text ? <div className={`${classes.text} ${textClassName}`}>{text}</div> : null}
			</IconButton>
		);
	}
}

CircledIconButton.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.string,
	text: PropTypes.string,
	contained: PropTypes.bool,
	labelClassName: PropTypes.string,
	textClassName: PropTypes.string
};

export default withStyles(styles)(CircledIconButton);
