import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withTheme
} from '@material-ui/core';

import SentimentReport from "./SentimentReport";
import SentimentChart from "./SentimentChart";
import SentimentBehindNumbers from "./SentimentBehindNumbers";

class StudentSentimentReportItem extends Component {
	render() {
		let {fidoError, data, previousDays, theme} = this.props;

		return (
			<SentimentReport
				fullWidth
				fidoError={fidoError}
				previousDays={previousDays}
				reportTitle="U.S. Student Sentiment"
				moreInfoComponent={<SentimentBehindNumbers />}
				seriesConfig={[{
					name: "Happiness",
					type: "spline",
					color: theme.palette.secondary.blue
				},{
					name: "Harm",
					type: "spline",
					color: theme.palette.secondary.yellow
				},{
					name: "Fear",
					type: "spline",
					color: theme.palette.secondary.purple
				},{
					name: "Anger",
					type: "spline",
					color: theme.palette.secondary.orange
				}]}
				dataConfig={[
					{data: data, key: "period", valueKey: "happScore", asObject: ["clusterName", "linkTitle", "linkUrl"]},
					{data: data, key: "period", valueKey: "harmScore", asObject: ["clusterName", "linkTitle", "linkUrl"]},
					{data: data, key: "period", valueKey: "fearScore", asObject: ["clusterName", "linkTitle", "linkUrl"]},
					{data: data, key: "period", valueKey: "angerScore", asObject: ["clusterName", "linkTitle", "linkUrl"]}
				]}
				render={(chartInfo) => {
					let {chartConfig, clusterName, linkTitle, linkUrl} = chartInfo;
					return (
						<SentimentChart
							linkUrl={linkUrl}
							linkTitle={linkTitle}
							chartConfig={chartConfig}
							clusterName={clusterName}
							clickPointText="Click a point on the chart to see if there's trending topics..." />
					);
				}} />
		);
	}
}

StudentSentimentReportItem.propTypes = {
	theme: PropTypes.object.isRequired,
	fidoError: PropTypes.bool,
	data: PropTypes.array,
	previousDays: PropTypes.number.isRequired
};

export default withTheme(StudentSentimentReportItem);
